export const LOGIN = 'LOGIN';
export const TOGGLE_MENU = 'TOGGLE_MENU';
//export const MASTERDATA_SELECT = 'MASTERDATA_SELECT';
export const SORT_DATA = "SORT_DATA";
export const SEARCH_DATA = "SEARCH_DATA";
export const UPDATE_LANG="UPDATE_LANG";
export const POST_CRUD = "POST_CRUD";
export const SEARCH_FIELD_DATA = "SEARCH_FIELD_DATA";
export const FILE_UPLOAD = "FILE_UPLOAD";
export const DETAIL_DATA = "DETAIL_DATA";
export const DEFAULT_RETURN = "DEFAULT_RETURN";
export const LOGIN_STATUS = "LOGIN_STATUS";
export const LOGOUT = "LOGOUT";
export const DEFAULT_SITE_CHANGE ="DEFAULT_SITE_CHANGE";
export const REQUEST_FAILURE = "REQUEST_FAILURE";

