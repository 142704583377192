import React, { Component, Suspense } from "react"; //lazy,
import { Redirect } from "react-router";
import { withTranslation } from "react-multi-lang";
// import request from "superagent";
import {
  userdetails,
  Silentauditobject,
  AuditTrailValidation,
  Manualauditobject,
  Servicecalls,
  Servicecallsforfiles
} from "./../custom_functions/Functions.jsx";
// import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
// import CustomGridView from "./../controls/CustomGridView.jsx";
// import KendoGridDefault from "./../controls/KendoGridDefault.jsx";
// import { confirmAlert } from "react-confirm-alert";
import { ControlRights } from "../custom_functions/Functions";
import { ModalDocxDirectory } from "./../controls/ModalKendoSpred.jsx"; //, DocxDirDesign , AddDirectory
import { withAlert } from "react-alert";
import { AuditModuleNameEnum } from "../custom_functions/AuditActionEnum";

// const OnlyOfficeDocs = lazy(() => import("./../controls/OnlyOfficeDocs.jsx"));
import store from "./../redux_store/store/index";
import $ from "jquery";
import Select from "react-select";
import { isMultitenant } from "../custom_functions/Constance.jsx";
// import OnlyOfficeDocs from "./../controls/OnlyOfficeDocs.jsx";
import Service from "./general/Service.jsx";

const OnlyOfficeDocs = React.lazy(() =>
  import("./../controls/OnlyOfficeDocs.jsx")
);
const KendoGridView = React.lazy(() =>
  import("./../controls/KendoGridView.jsx")
);
const Audit = React.lazy(() => import("./../custom_modules/Audit.jsx"));
const KendoDateTimepicker = React.lazy(() =>
  import("./../controls/KendoDateTimepicker.jsx")
);

class DownloadReport extends Component {
  constructor(props) {
    super(props);
    this.ELNurl = document.getElementById("ELNname").value;
    this.docxFileUploadRef = React.createRef();
    this.contentHeight =
      document.getElementsByTagName("html")[0].offsetHeight -
      (document.getElementById("top-menu-item-container") !== null
        ? document.getElementById("top-menu-item-container").offsetHeight + 100
        : 190) +
      "px";
    this.onlyofficeFocus = undefined;
    this.state = {
      response: {},
      shouldSaveDocx: false,
      GenertateReportTemplateModalStatus: false,
      CreateNewTemplateModalStatus: false,
      DocxDirModalvisible: false,
      saveAction: false,
      configModal: false,
      validurl: true,
    };
  }

  docxFileUpload = () => {
    this.docxFileUploadRef.current.click();
  };

  uploadFileonChange = (e) => {
    if (e.currentTarget.files) {
      const file = e.currentTarget.files[0];
      var serviceObj = {
        ...userdetails(),
        objsilentaudit: Silentauditobject(
          "upload",
          "reports",
          this.props.t("Reports.IDS_AUDIT_NEWDOCUMENTUPLOADED")
        ),
      };
      const data = new FormData();
      data.append("file", file);
      data.append("serviceObj", JSON.stringify(serviceObj));

      Servicecallsforfiles(
        this.ELNurl + "/reports/uploadDocxFile",
        data,
        (res) => {
          this.setState({
            response: { fileRes: res },
            shouldSaveDocx: true,
          });
        },
        (res) => { }
      );

      // request
      //   .post(this.ELNurl + "/reports/uploadDocxFile")
      //   .set({
      //     Authorization: $.session.get("Token"),
      //     "X-TenantID": $.session.get("tenantid"),
      //     Accept: "application/json",
      //   })
      //   .send(data)
      //   .then((res) => {
      //     this.setState({
      //       response: { fileRes: JSON.parse(res.text) },
      //       shouldSaveDocx: true,
      //     });
      //   })
      //   .catch((res) => {
      //     // alert(res);
      //   });
    }
  };

  newReportDocx = () => {
    const passObjDet = {
      ...userdetails(),
      type: 0,
      objsilentaudit: Silentauditobject(
        "insert",
        "reports",
        this.props.t("Reports.IDS_AUDIT_NEWDOCUMENTCREATED")
      ),
      // Lsusermaster: store.getState().Loggeduser,
      lssitecode: store.getState().Loggeduser.lssitemaster.sitecode,
    };

    Servicecalls(
      this.ELNurl + "/reports/createNewReportDocx",
      passObjDet,
      (res) => {
        this.setState({
          response: { fileRes: res },
          shouldSaveDocx: true,
        });
      },
      (res) => {
        // console.error("createNewDocxserror: ", res.Error);
      }
    );
  };

  OnNewTemplate = (response) => {
    let passObjDetAudit = store.getState().Loggeduser;
    passObjDetAudit.objsilentaudit = Silentauditobject(
      "insert",
      "reports",
      this.props.t("Reports.IDS_AUDIT_NEWTEMPLATECREATED", {
      })
    );

    Servicecalls(
      "" + this.ELNurl + "/AuditTrail/silentandmanualRecordHandler",
      passObjDetAudit,
      (res) => { },
      (res) => { }
    );
    let resp = response.objResponse;
    if (resp !== undefined) {
      this.props.alert.error(
        this.props.t("Common.IDS_MSG_VALIDATION", { param: "" })
      );
    } else {
      this.props.alert.success(
        this.props.t(
          "Reports.IDS_MSG_NEWTEMPLATEHASBEENCREATEDFORSELECTEDSHEETS"
        )
      );
      let temp = {
        response: { fileRes: response },
        shouldSaveDocx: true,
        CreateNewTemplateModalStatus: !this.state.CreateNewTemplateModalStatus,
      };
      this.openDirectory("office", temp);
    }
  };

  onCreateNewTemplate = (SheetInfo) => {
    let FileCodeString = "";
    if (SheetInfo.length > 1) {
      let FileCodeArray = [];
      SheetInfo.map((item) => FileCodeArray.push(item.filecode));
      FileCodeArray.sort((itemA, itemB) => itemA - itemB);
      FileCodeString = FileCodeArray.join(", ");
    } else {
      FileCodeString = SheetInfo[0].filecode.toString();
    }
    const passObjDet = {
      ...userdetails(),
      type: 1,
      FileCode: FileCodeString,
      // objsilentaudit: Silentauditobject(
      //   "insert",
      //   "reports",
      //   this.props.t("compareObjs.IDS_NEWTEMPLATECREATED")
      // ),
      // Lsusermaster: store.getState().Loggeduser
      lssitecode: store.getState().Loggeduser.lssitemaster.sitecode,
    };
    let Audit = AuditTrailValidation("IDS_MDL_REPORTS", "IDS_SCN_REPORTS", "IDS_TSK_NEWTEMP");

    if (Audit) {
      $("#downloadReportsTemplateAuditId").modal("show");
      passObjDet.objmanualaudit = Manualauditobject("insert", "reports");

      this.setState({
        AuditUrl: this.ELNurl + "/reports/createNewReportDocx",
        AuditObject: passObjDet,
        AuditPassfunction: this.OnNewTemplate,
        AuditFailfunction: () => console.error(),
      });
    } else {
      Servicecalls(
        this.ELNurl + "/reports/createNewReportDocx",
        passObjDet,
        this.OnNewTemplate,
        (res) => {
          // console.error("createNewDocxserror: ", res.Error);
        }
      );
    }
  };

  toggleDialog = (type, modalScreen) => {
    if (modalScreen === "GenertateReportTemplate") {
      this.setState({
        GenertateReportTemplateModalStatus:
          !this.state.GenertateReportTemplateModalStatus,
      });
    } else if (modalScreen === "CreateNewTemplate") {
      this.setState({
        CreateNewTemplateModalStatus: !this.state.CreateNewTemplateModalStatus,
      });
    } else if (modalScreen === "docxdir") {
      if (
        !this.state.shouldSaveDocx &&
        !this.state.DocxDirModalvisible &&
        type === "saveFile"
      ) {
        if (this.state.response.fileRes) {
          this.props.alert.success(
            this.props.t("Reports.IDS_MSG_SAVEDSUCCESSFULLY")
          );

        } else {
          this.props.alert.error(
            this.props.t("Reports.IDS_MSG_OPENNEWFILEBEFORESAVING")
          );
          let passObject = {
            objsilentaudit: Silentauditobject(
              "warning",
              "reports",
              this.props.t("Reports.IDS_MSG_OPENNEWFILEBEFORESAVING")
            ),
          };
          Servicecalls(
            "" + this.ELNurl + "/AuditTrail/AuditConfigurationrecord",
            passObject,
            (res) => { },
            (res) => { }
          );
        }
      } else {
        if (type === "saveFile" || type === "saveAs") {
          if (this.state.response.fileRes) {
            let stateUpdate = { DocxDirModalType: type };
            // this.setState({ DocxDirModalvisible: !this.state.DocxDirModalvisible, DocxDirModalType: type });
            this.openDirectory("directory", stateUpdate);
          } else {
            this.props.alert.error(
              this.props.t("Reports.IDS_MSG_OPENNEWFILEBEFORESAVING")
            );
            let passObject = {
              objsilentaudit: Silentauditobject(
                "warning",
                "reports",
                this.props.t("Reports.IDS_MSG_OPENNEWFILEBEFORESAVING")
              ),
            };
            Servicecalls(
              "" + this.ELNurl + "/AuditTrail/AuditConfigurationrecord",
              passObject,
              (res) => { },
              (res) => { }
            );
          }
        } else {
          let stateUpdate = { DocxDirModalType: type };
          //if(this.state.displayType === "directory"){
          this.openDirectory("office", stateUpdate);
          // }else{
          //   this.openDirectory("directory", stateUpdate);
          // }
        }
      }
    }
  };

  addDirectoryModal = (type, selectedDirData) => {
    this.setState({
      AddDirectoryModal: !this.state.AddDirectoryModal,
      AddDirectorytype: type,
      selectedDirData,
    });
  };

  setOpenDocxReport = (selectedDirData) => {
    let canProceed = true;
    if (
      this.state.response.fileRes !== undefined &&
      this.state.response.fileRes !== {}
    ) {
      if (
        selectedDirData.fileName ===
        this.state.response.fileRes.fileName.replace(".docx", "")
      ) {
        canProceed = false;
      }
    }
    if (canProceed) {
      let passObjDet = {
        ...userdetails(),
        ...selectedDirData,
        // objsilentaudit: Silentauditobject(
        //   "view",
        //   "reports",
        //   this.props.t("compareObjs.IDS_REQUESTOOPENFILE", {
        //     fileName: selectedDirData.fileName,
        //   })
        // ),
        Lsusermaster: store.getState().Loggeduser,
      };

      let updateUrl = "";
      if (isMultitenant === 1) {
        updateUrl = this.ELNurl + "/reports/getCloudReportDocxInfo";
      } else {
        updateUrl = this.ELNurl + "/reports/getReportDocxInfo";
      }

      Servicecalls(
        updateUrl,
        passObjDet,
        (res) => {
          const response = res;
          if (response.status === "success") {
            let shouldSaveDocx = false;
            if (response.isdraft === 1) {
              shouldSaveDocx = true;
            }
            let temp = {
              response: { fileRes: response },
              shouldSaveDocx,
              versionNo: response.LSdocreportsversionhistorylst.length - 1,
            };
            this.openDirectory("office", temp);
          } else {
            this.props.alert.error(
              this.props.t("Reports." + response.status)
            );

            let passObject = {
              objsilentaudit: Silentauditobject(
                "warning",
                "reports",
                this.props.t("Reports." + response.status)
              ),
            };
            Servicecalls(
              "" + this.ELNurl + "/AuditTrail/AuditConfigurationrecord",
              passObject,
              (res) => { },
              (res) => { }
            );
          }
        },
        (res) => {
          // console.error(res);
        }
      );

      // request
      //   .post(updateUrl)
      //   .set({
      //     Authorization: $.session.get("Token"),
      //     "X-TenantID": $.session.get("tenantid"),
      //     Accept: "application/json",
      //   })
      //   .send(passObjDet)
      //   .then((res) => {
      //     const response = JSON.parse(res.text);
      //     if (response.status === "success") {
      //       let shouldSaveDocx = false;
      //       if (response.isdraft === 1) {
      //         shouldSaveDocx = true;
      //       }
      //       let temp = {
      //         response: { fileRes: response },
      //         shouldSaveDocx,
      //         versionNo: response.LSdocreportsversionhistorylst.length - 1,
      //       };
      //       this.openDirectory("office", temp);
      //       // this.setState({
      //       //   response: { fileRes: response }, shouldSaveDocx, versionNo: response.LSdocreportsversionhistorylst.length - 1
      //       // });
      //     } else {
      //       this.props.alert.error(
      //         this.props.t("DownloadReport." + response.status)
      //       );
      //       // this.setState({ DocxDirModalvisible: !this.state.DocxDirModalvisible });
      //       let passObject = {
      //         objsilentaudit: Silentauditobject(
      //           "warning",
      //           "reports",
      //           this.props.t("DownloadReport." + response.status)
      //         ),
      //       };
      //       Servicecalls(
      //         "" + this.ELNurl + "/AuditTrail/AuditConfigurationrecord",
      //         passObject,
      //         this.onAuditValidateError.bind(this),
      //         this.onAuditValidateFailure.bind(this)
      //       );
      //     }
      //   })
      //   .catch((res) => console.error(res));
    } else {
      this.props.alert.error(this.props.t("Reports.IDS_LBL_ALREADYOPEN"));
      let passObject = {
        objsilentaudit: Silentauditobject(
          "warning",
          "reports",
          this.props.t("Reports.IDS_LBL_ALREADYOPEN")
        ),
      };
      Servicecalls(
        "" + this.ELNurl + "/AuditTrail/AuditConfigurationrecord",
        passObject,
        (res) => { },
        (res) => { }
      );
    }
  };

  setDocumentDirData = (Response) => {
    let {
      LSDocReportsLst,
      LSDocDirectoryLst,
      shouldSaveDocx,
      newDocx,
      selectedDirData,
    } = Response;
    let temp = {
      LSDocDirectoryResponse: { LSDocReportsLst, LSDocDirectoryLst }, //,
      //AddDirectoryModal: false
    };
    if (shouldSaveDocx !== undefined && shouldSaveDocx !== null) {
      temp["shouldSaveDocx"] = shouldSaveDocx;
      // temp["DocxDirModalvisible"] = !this.state.DocxDirModalvisible
    }
    if (newDocx !== undefined && newDocx !== null) {
      newDocx["type"] = this.state.response.fileRes.type;
      temp["response"] = { fileRes: newDocx };
    }
    if (selectedDirData !== undefined && selectedDirData !== null) {
      temp["selectedDirData"] = selectedDirData;
    }
    this.setState({ ...temp });
  };

  updateDocxRelatedState = (response) => {
    // this.setState({ response: { fileRes: response.newDocx }, LSDocDirectoryResponse: { ...response.DocxDirectoryLst, ...response.DocxReportLst } });
    let temp = {
      response: { fileRes: response.newDocx },
      LSDocDirectoryResponse: {
        ...response.DocxDirectoryLst,
        ...response.DocxReportLst,
      },
    };
    this.openDirectory("office", temp);
  };

  // setConfigProps = (config) => {
  //   if (config !== "") {
  //     this.setState({ config, configModal: !this.state.configModal, configStatus: "" });
  //   } else {
  //     this.setState({ configModal: !this.state.configModal });
  //   }
  // }

  // onversionChange = (event) => {
  //   let versionNo;
  //   const { LSdocreportsversionhistorylst } = this.state.response.fileRes;
  //   if (event.currentTarget.value === "latest") {
  //     versionNo = LSdocreportsversionhistorylst[LSdocreportsversionhistorylst.length - 1].versionNo;
  //   } else {
  //     versionNo = parseInt(event.currentTarget.value);
  //   }
  //   let oldVersion = this.state.versionNo === LSdocreportsversionhistorylst[LSdocreportsversionhistorylst.length - 1].versionNo ? "Latest" : this.state.versionNo;
  //   const passObjDet = { ...userdetails(), versionNo, ...this.state.response.fileRes, objsilentaudit: Silentauditobject("view", "reports", this.props.t("compareObjs.IDS_REQUESTTOVIEWANTOHERVERSION", { new: event.currentTarget.value, old: oldVersion })) };
  //   request
  //     .post(this.ELNurl + "/reports/getReportDocxonVersion")
  //     .set({ 'Authorization': $.session.get("Token"), 'X-TenantID': $.session.get("tenantid"), Accept: 'application/json' }).send(passObjDet)
  //     .then(res => {
  //       this.setState({ response: { fileRes: JSON.parse(res.text) }, shouldSaveDocx: false, versionNo })
  //     })
  //     .catch(res => {
  //       console.error("createNewDocxserror: ", res.Error);
  //     });
  // }

  setUndefinedHashKey = () => {
    let { response } = this.state;
    if (response["fileRes"] !== undefined) {
      if (response["fileRes"].hashKey !== undefined) {
        const oldhashKey = response["fileRes"].hashKey;
        response["fileRes"]["hashKey"] = undefined;
        this.setState({ response, oldhashKey });
      } else {
        response["fileRes"]["hashKey"] = undefined;
        this.setState({ response });
      }
    }
  };

  setUndefinedResFile = () => {
    let { response } = this.state;
    if (response["fileRes"] !== undefined) {
      this.setState({ response: {} });
    }
  };

  setHashResFile = () => {
    let { response } = this.state;
    if (
      response.fileRes.hashKey !== undefined &&
      response.fileRes.hashKey.length > 36
    ) {
      response["fileRes"]["hashKey"] = response.fileRes.hashKey.slice(0, 36);
      this.setState({ response });
    }
  };

  setHashKey = () => {
    let { response, oldhashKey } = this.state;
    if (oldhashKey !== undefined) {
      oldhashKey += "-10";
      response["fileRes"]["hashKey"] = oldhashKey;
      this.setState({ response, oldhashKey: undefined });
    }
  };

  openDirectory = (displayType, stateUpdateParent) => {
    let onlyofficeDivCss = {};
    let directoryDivCss = {};
    let generateReportDivCss = {};
    let CreateNewTemplateDivCss = {};
    // let displayType = "";
    let DocxDirModalvisible = false;
    let GenertateReportTemplateModalStatus = false;
    let CreateNewTemplateModalStatus = false;
    if (
      displayType === "directory&office" &&
      this.state.displayType === "directory&office"
    ) {
      displayType = "office";
    }
    if (displayType === "directory&office") {
      onlyofficeDivCss = { display: "block", width: "70%" };
      directoryDivCss = { display: "block", width: "30%" };
      generateReportDivCss = { display: "none", width: "0%" };
      CreateNewTemplateDivCss = { display: "none", width: "0%" };
      //displayType = "directory&office";
      DocxDirModalvisible = true;
      GenertateReportTemplateModalStatus = false;
      CreateNewTemplateModalStatus = false;
    } else if (displayType === "office") {
      onlyofficeDivCss = { display: "block", width: "100%" };
      directoryDivCss = { display: "none", width: "0%" };
      generateReportDivCss = { display: "none", width: "0%" };
      CreateNewTemplateDivCss = { display: "none", width: "0%" };
      //displayType = "office";
      DocxDirModalvisible = false;
      GenertateReportTemplateModalStatus = false;
      CreateNewTemplateModalStatus = false;
    } else if (displayType === "directory") {
      onlyofficeDivCss = { display: "none", width: "0%" };
      directoryDivCss = { display: "block", width: "100%" };
      generateReportDivCss = { display: "none", width: "0%" };
      CreateNewTemplateDivCss = { display: "none", width: "0%" };
      //displayType = "directory";
      DocxDirModalvisible = true;
      GenertateReportTemplateModalStatus = false;
      CreateNewTemplateModalStatus = false;
    } else if (displayType === "generateReport") {
      onlyofficeDivCss = { display: "none", width: "0%" };
      directoryDivCss = { display: "none", width: "0%" };
      generateReportDivCss = { display: "block", width: "100%" };
      CreateNewTemplateDivCss = { display: "none", width: "0%" };
      //displayType = "directory";
      DocxDirModalvisible = false;
      GenertateReportTemplateModalStatus = true;
      CreateNewTemplateModalStatus = false;
    } else if (displayType === "CreateNewTemplate") {
      onlyofficeDivCss = { display: "none", width: "0%" };
      directoryDivCss = { display: "none", width: "0%" };
      generateReportDivCss = { display: "none", width: "0%" };
      CreateNewTemplateDivCss = { display: "block", width: "100%" };
      //displayType = "directory";
      DocxDirModalvisible = false;
      GenertateReportTemplateModalStatus = false;
      CreateNewTemplateModalStatus = true;
    }

    if (stateUpdateParent !== undefined && stateUpdateParent !== {}) {
      this.setState({
        onlyofficeDivCss,
        directoryDivCss,
        displayType,
        DocxDirModalvisible,
        generateReportDivCss,
        GenertateReportTemplateModalStatus,
        CreateNewTemplateModalStatus,
        CreateNewTemplateDivCss,
        ...stateUpdateParent,
      });
    } else {
      this.setState({
        onlyofficeDivCss,
        directoryDivCss,
        displayType,
        DocxDirModalvisible,
        generateReportDivCss,
        GenertateReportTemplateModalStatus,
        CreateNewTemplateModalStatus,
        CreateNewTemplateDivCss,
      });
    }
  };

  updateuserrights(screenrights) {
    let Reports = ControlRights("IDS_MDL_REPORTS", "IDS_SCN_REPORTS");
    let GenerateReports = ControlRights("IDS_MDL_REPORTS", "IDS_TSK_GENERATEREPORT");
    let templateDesigning = ControlRights("IDS_MDL_REPORTS", "IDS_TSK_NEWTEMP");
    let documentNew = ControlRights("IDS_MDL_REPORTS", "IDS_TSK_NEWDOCUMENT");
    let Openrights = ControlRights("IDS_MDL_REPORTS", "IDS_TSK_OPENREPORT");
    let importrights = ControlRights("IDS_MDL_REPORTS", "IDS_TSK_IMPORTDOCX");
    let saveAction = Reports.UserGroupRights.rights;
    let GenerateReportsSaveAction = GenerateReports.UserGroupRights.rights;
    let templateDesigningrights = templateDesigning.UserGroupRights.rights;
    let templateSave = templateDesigning.UserGroupRights.save;
    let documentSaveAction = documentNew.UserGroupRights.rights;
    let Openfilerights = Openrights.UserGroupRights.rights;
    let Importdocxrights = importrights.UserGroupRights.rights;
    let saveReport = Reports.UserGroupRights.save
    this.setState({
      saveAction,
      GenerateReportsSaveAction,
      templateDesigningrights,
      documentSaveAction,
      templateSave,
      Openfilerights,
      Importdocxrights,
      saveReport
    });

    if (screenrights !== undefined) {
      this.setState({ validurl: screenrights.Report });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.rights !== undefined) {
      this.updateuserrights(nextProps.rights);
    }
  }

  componentWillUnmount() {
    clearInterval(this.onlyofficeFocus);
  }

  componentDidMount() {
    // window.addEventListener('message', function(event) {
    // })
    this.onlyofficeFocus = setInterval(() => {
      var iframe = document.querySelector("iframe");
      // className="modal-title" id="openSheetLabel"
      const idlePopup = document.querySelector(".modal-title#openSheetLabel");
      const {
        displayType,
        DocxDirModalvisible,
        GenertateReportTemplateModalStatus,
        CreateNewTemplateModalStatus,
        configModal,
      } = this.state;
      if (
        idlePopup === null &&
        iframe !== null &&
        displayType === "office" &&
        !configModal &&
        !DocxDirModalvisible &&
        !GenertateReportTemplateModalStatus &&
        !CreateNewTemplateModalStatus
      ) {
        if (iframe.getAttribute("mouseinside") === "false") {
          iframe.contentWindow.focus();
          // iframe.setAttribute("mouseinside", true);
        }
      }
    }, 2000);
    // this.focusOnlyoffice()
    this.openDirectory("office");
    let passObjDet = {
      ...userdetails(),
      objsilentaudit: Silentauditobject(
        "view",
        "reports",
        this.props.t("compareObjs.IDS_REPORTSSCREENLOADED", {
          screenName: AuditModuleNameEnum["reports"],
        })
      ),
    };

    Servicecalls(
      this.ELNurl + "/reports/getDownloadReportsInitRequest",
      passObjDet,
      (res) => {
        const response = res;
        let temp = {
          LSDocDirectoryResponse: {
            ...response.DocxDirectoryLst,
            ...response.DocxReportLst,
          },
        };
        if ("LstLSuserMaster" in response) {
          temp["LstLSuserMaster"] = response.LstLSuserMaster;
        }
        let temp1 = {};
        if ("config" in response) {
          if ("DocxApi" in response.config) {
            this.setState({ configError: false });
            let DocsAPIScript = document.createElement("script");
            DocsAPIScript.src = response.config.DocxApi;
            document.body.appendChild(DocsAPIScript);
            temp1["DocxApi"] = response.config.DocxApi;
          }
          if (
            "DocxUrl" in response.config &&
            document.getElementById("onlyOfficeUrl").value === ""
          ) {
            document.getElementById("onlyOfficeUrl").value =
              response.config.DocxUrl;
            temp1["DocxUrl"] = response.config.DocxUrl;
          }
          if ("status" in response.config) {
            this.setState({
              configError: true,
              configErrorStatus: response.config.status,
            });
            this.props.alert.error(
              this.props.t("Reports." + response.config.status)
            );
          }
          temp["config"] = temp1;
        }
        this.setState({ ...temp });
      },
      (res) => {
      }
    );

    this.updateuserrights(this.props.rights);
  }

  render() {
    if (!this.state.validurl) {
      return <Redirect to="/Home" />;
    }

    return (
      // <div className="office_wrap">
      <div>
        {this.state.configError ? (
          <div className="centered-event-message-wrap pad-1">
            <div style={{ "height": "calc(100vh - 140px)" }} className="centered-event-message">
              <div className="centered-event-message-inner">
                <span className="big-icon">
                  <i class="fas fa-exclamation-triangle"></i>
                </span>
                <p>
                  {this.props.t(
                    "Reports." + this.state.configErrorStatus
                  )}{" "}
                </p>
              </div>
            </div>
          </div>
        ) : (
          <React.Fragment>

            <div
              id="onlyoffice_topmenu"
              className="pad-1"
            >
              {this.state.documentSaveAction ||
                this.state.saveAction === false ? (
                <button
                  className="btn btn-primary-blue mg-r-half"
                  onClick={this.newReportDocx}
                >
                  <i className="fas fa-file" aria-hidden="true"></i>{" "}
                  {this.props.t("Reports.IDS_LBL_NEWFILE")}
                </button>
              ) : (
                false
              )}
              {this.state.templateDesigningrights ? (
                <button
                  className="btn btn-primary-blue mg-r-half"
                  onClick={() => this.openDirectory("CreateNewTemplate")}
                >
                  <i className="fas fa-file" aria-hidden="true"></i>{" "}
                  {this.props.t("Reports.IDS_LBL_NEWTEMPLATE")}
                </button>
              ) : (
                false
              )}
              {this.state.Importdocxrights ? (
                <span>
                  <button
                    className="btn btn-primary-blue mg-r-half"
                    onClick={this.docxFileUpload}
                  >
                    <i className="far fa-file-word"></i>
                    {this.props.t("Reports.IDS_LBL_IMPORTDOCUMENT")}
                  </button>
                  <input
                    type="file"
                    name="files[]"
                    ref={this.docxFileUploadRef}
                    accept=".docx"
                    hidden={true}
                    onChange={this.uploadFileonChange}
                  />
                </span>
              ) : (
                false
              )}
              {this.state.Openfilerights ? (
                <button
                  className="btn btn-primary-blue mg-r-half"
                  style={
                    this.state.displayType === "directory&office"
                      ? {
                        border: "solid #0e5bca 2px",
                        borderRadius: "15px",
                      }
                      : {}
                  }
                  // onClick={() => this.toggleDialog("openFile", "docxdir")}
                  onClick={() => this.openDirectory("directory&office")}
                >
                  <i
                    className="fas fa-folder-open"
                    aria-hidden="true"
                  ></i>{" "}
                  {this.props.t("Reports.IDS_LBL_OPENDOCXDIR")}
                </button>
              ) : (
                false
              )}

              {JSON.parse(sessionStorage.User).usercode === 1 ? (
                false
              ) : (
                <span>
                  {
                    this.state.saveReport ?
                      <button
                        className="btn btn-primary-blue mg-r-half"
                        onClick={() =>
                          this.toggleDialog("saveFile", "docxdir")
                        }
                      >
                        <i className="far fa-save"></i>
                        {this.props.t("Reports.IDS_LBL_SAVETODIRECTORY")}
                      </button>
                      :
                      false
                  }
                  {
                    this.state.saveReport
                      ? (
                        <button
                          className="btn btn-primary-blue mg-r-half"
                          onClick={() => this.toggleDialog("saveAs", "docxdir")}
                        >
                          <i className="far fa-save"></i>
                          {this.props.t("Reports.IDS_LBL_SAVEAS")}
                        </button>
                      ) : (
                        false
                      )
                  }
                  {this.state.GenerateReportsSaveAction ? (
                    <button
                      className="btn btn-primary-blue mg-r-half"
                      onClick={() => this.openDirectory("generateReport")}
                    >
                      <i className="far fa-files-o"></i>
                      {this.props.t("Reports.IDS_LBL_GENERATEREPORT")}
                    </button>
                  ) : (
                    false
                  )}
                </span>
              )}
              {/* {this.state.displayType !== "directory&office" ?
                  <span className="p-1 float-right">
                    <button className="ButtonAsAnchorCss" onClick={() => { this.setState({ configModal: !this.state.configModal }) }}>
                      <i className="fas fa-cog pr-1" aria-hidden="true"></i>{" "}
                      {this.props.t("DownloadReport.ID_CONFIGURE")}
                    </button>
                  </span>
                  : false} */}
            </div>
            <div className="d-flex">
              <div style={this.state.onlyofficeDivCss}>

                {/* {this.state.configModal ?
                <ConfigurationModal
                  configModal={this.state.configModal}
                  configValueObj={this.state.config}
                  setConfigProps={this.setConfigProps}
                />
                : false} */}
                <div
                  className="bg-white"
                  style={{
                    height: "calc(100vh - 125px)",
                    display:
                      this.state.response.fileRes !== undefined &&
                        (this.state.displayType === "directory&office" ||
                          this.state.displayType === "office")
                        ? "block"
                        : "none",
                  }}
                >
                  <Suspense fallback={<div></div>}>
                    <OnlyOfficeDocs
                      height={"100%"}
                      fileRes={this.state.response.fileRes}
                      config={this.state.config}
                      setUndefinedResFile={this.setUndefinedResFile}
                      setHashResFile={this.setHashResFile}
                      editable={true}
                      callBackURL={
                        isMultitenant === 1
                          ? "/reports/cloudsaveDocxsReport"
                          : "/reports/saveDocxsReport"
                      }
                    />
                  </Suspense>
                </div>
                {
                  this.state.response.fileRes === undefined ?
                    <div className="centered-event-message-wrap no-top pad-1">
                      <div style={{ "height": "calc(100vh - 140px)" }} className="centered-event-message">
                        {/* <span><p > {this.state.configStatus !== "" ? this.state.configStatus : false} </p></span> */}
                        <div className="centered-event-message-inner">
                          <p>
                            {this.props.t(
                              "Reports.IDS_LBL_TOCREATENEWDOCUMENTCLICK"
                            )}{" "}
                          </p>
                          {this.state.documentSaveAction ||
                            this.state.saveAction === false ? (
                            <button
                              onClick={this.newReportDocx}
                              className="btn_white_bg txt-btn">
                              <i
                                className="fas fa-file mg-r-half"
                                aria-hidden="true"
                              ></i>{" "}
                              {this.props.t("Reports.IDS_LBL_NEWFILE")}
                            </button>
                          ) : (
                            false
                          )}
                        </div>
                      </div>
                    </div>
                    : ""}
              </div>
              <div style={this.state.directoryDivCss}>
                {this.state.DocxDirModalvisible &&
                  this.state.LSDocDirectoryResponse !== undefined ? (
                  <div className="sidebar-wrap">
                    {this.state.displayType === "directory&office" ? (
                      <div className="right-align-row">
                        <button
                          className="btn btn-primary-blue mg-r-half"
                          onClick={() => this.openDirectory("directory")}
                        >
                          <i
                            className="fa fa-chevron-left pr-1"
                            aria-hidden="true"
                          ></i>{" "}
                          {this.props.t("Reports.IDS_LBL_MORE")}
                        </button>
                        <button
                          className="btn_white_bg"
                          onClick={() => this.openDirectory("office")}
                        >
                          {this.props.t("Reports.IDS_LBL_CLOSE")}
                        </button>
                      </div>
                    ) : (
                      false
                    )}
                    <ModalDocxDirectory
                      DocxDirModalvisible={this.state.DocxDirModalvisible}
                      toggleDialog={this.toggleDialog}
                      addDirectoryToggleDialog={this.addDirectoryModal}
                      LSDocDirectoryResponse={this.state.LSDocDirectoryResponse}
                      LstLSuserMaster={this.state.LstLSuserMaster}
                      DocxDirModalType={this.state.DocxDirModalType}
                      fileRes={this.state.response.fileRes || {}}
                      setOpenDocxReport={this.setOpenDocxReport}
                      displayType={this.state.displayType}
                      updateDisplayType={this.openDirectory}
                      setDocumentDirData={this.setDocumentDirData}
                      selectedDirData={
                        this.state.selectedDirData ||
                        this.state.LSDocDirectoryResponse.LSDocDirectoryLst[0]
                      }
                    />
                  </div>
                ) : (
                  false
                )}
              </div>
              <div style={this.state.generateReportDivCss}>
                {this.state.GenertateReportTemplateModalStatus ? (
                  <GenertateReportTemplate
                    ModalValidation={
                      this.state.GenertateReportTemplateModalStatus
                    }
                    changeDesign={this.openDirectory}
                    updateDocxRelatedState={this.updateDocxRelatedState}
                    istemplate={
                      this.state.response.fileRes
                        ? this.state.response.fileRes.type
                        : 0
                    }
                    setUndefinedHashKey={this.setUndefinedHashKey}
                    setHashKey={this.setHashKey}
                  />
                ) : (
                  false
                )}
              </div>
              <div style={this.state.CreateNewTemplateDivCss}>
                {this.state.CreateNewTemplateModalStatus ? (
                  <CreateNewTemplate
                    toggleDialog={this.toggleDialog}
                    changeDesign={this.openDirectory}
                    onCreateNewTemplate={this.onCreateNewTemplate}
                    AuditId="downloadReportsTemplateAuditId"
                    AuditUrl={this.state.AuditUrl}
                    AuditObject={this.state.AuditObject}
                    AuditPassfunction={this.state.AuditPassfunction}
                    AuditFailfunction={this.state.AuditFailfunction}
                  />
                ) : (
                  false
                )}
              </div>
            </div>

          </React.Fragment>
        )}
      </div>
    );
  }
}

export default withAlert()(withTranslation(DownloadReport));

class GenertateReportTemplateClass extends Component {
  constructor(props) {
    super(props);
    this.ELNurl = document.getElementById("ELNname").value;
    this.state = {
      response: {},
      selectedData: {},
      DocxDirModalvisible: true,
      // nextStep:false,
      stepOne: true,
      stepTwo: false,
      fileType: 0,
      genReportType: 0,
      fromDate: new Date(new Date().getTime() - 90 * 24 * 60 * 60 * 1000),
      toDate: new Date(),
      fromSecStepDate: new Date(
        new Date().getTime() - 90 * 24 * 60 * 60 * 1000
      ),
      toSecStepDate: new Date(),
      filteddata: [],
      Records: [],
      PrimaryRecords: [],
      LSDocReportsLstData: [],
      templatePrimaryRecords: [],
      templateRecords: [],
      ServiceToUrl: "",
      ServiceMethodType: "POST",
      ServiceHeaderobject: {},
      ServcieonSuccess: undefined,
      ServcieonFailure: undefined,
      Filterclick: false,
    };
    this.DownloadReportOrderLstColumns = [
      {
        header: this.props.t("Reports.IDS_LBL_SELECT"),
        datatype: "batchcode",
        rowselector: true,
        visible: true,
        filterable: false,
        className: "clsSelect",
        width: "10%",
      },
      {
        header: this.props.t("Reports.IDS_LBL_ORDERID"),
        datatype: "batchid",
        rowselector: false,
        visible: true,
        filterable: true,
        className: "clsModulename",
        width: "35%",
      },
      {
        header: this.props.t("Reports.IDS_LBL_TASKNAME"),
        datatype: "testname",
        rowselector: false,
        visible: true,
        filterable: true,
        className: "clsModulename",
        width: "20%",
      },
      {
        header: this.props.t("Reports.IDS_LBL_PROJECTNAME"),
        datatype: "projectname",
        rowselector: false,
        visible: true,
        filterable: true,
        className: "clsModulename",
        width: "15%",
      },
      {
        header: this.props.t("Dashboard.IDS_LBL_SHEETNAME"),
        datatype: "sheetname",
        rowselector: false,
        visible: true,
        filterable: true,
        className: "clsModulename",
        width: "15%",
      },
      {
        header: this.props.t("Reports.IDS_LBL_TRANSACTIONSTATUS"),
        datatype: "transactionstatus",
        rowselector: false,
        visible: true,
        filterable: true,
        className: "clsModulename",
        width: "15%",
      },
    ];
    this.DownloadReportTemplateLstColumns = [
      {
        header: this.props.t("Reports.IDS_LBL_TEMPLATENAME"),
        datatype: "fileName",
        rowselector: false,
        visible: true,
        filterable: true,
        className: "clsModulename",
        width: "100%",
      },
    ];
    this.fileTypeOptions =
      isMultitenant === 0
        ? [
          { value: 0, label: "LIMS-sheet" },
          { value: 2, label: "ELN-sheet" },
          { value: 3, label: "Research" },
          { value: 4, label: "Excel" },
          { value: 5, label: "Evaluation" },
        ]
        : [
          { value: 2, label: "ELN-sheet" },
          { value: 3, label: "Research" },
          { value: 4, label: "Excel" },
          { value: 5, label: "Evaluation" },
        ];
    this.genReportTypeOptions = [
      { value: 0, label: "Combined/Single" },
      { value: 1, label: "Sequence" },
    ];
  }

  onFileTypeChange = (event) => {
    this.setState({ fileType: event.value });
    if (
      event.value !== undefined &&
      event.value !== null &&
      event.value !== ""
    ) {
      let passObjDet = {
        filetype: event.value,
        lsuserMaster: store.getState().Loggeduser,
        lsuserMasterUserCode: parseInt(sessionStorage.User),
        objsilentaudit: Silentauditobject(
          "view",
          "reports",
          this.props.t("compareObjs.IDS_REQUESTTOVIEWALLORDERSLIST")
        ),
      };
      if (this.ELNurl !== "" && !this.state.reportView) {
        this.setState({
          ServiceToUrl: "" + this.ELNurl + "/reports/Getorderbytype",
          ServiceHeaderobject: passObjDet,
          ServiceMethodType: "POST",
          ServcieonSuccess: this.onGetorderbytypeselcetSuccess.bind(this),
          ServcieonFailure: this.onGetorderbytypeselcetFailure.bind(this),
          ServcieProcessbar: false,
        });
      }
    }
  };

  onGetorderbytypeselcetSuccess(response) {
    const instrumentResponse = response;
    let orderBindData = [];
    let temp = [];
    let filteddata = [];
    const { fromDate, toDate } = this.state;
    if (
      instrumentResponse.Completed !== undefined &&
      instrumentResponse.Completed.length > 0
    ) {
      temp = instrumentResponse.Completed;
    } else if (
      instrumentResponse.Pending !== undefined &&
      instrumentResponse.Pending.length > 0
    ) {
      temp = instrumentResponse.Pending;
    }
    if (temp.length > 0) {
      orderBindData = temp.filter(
        (item) => item.approvelstatus === 1 || item.orderflag.trim() === "R"
      );
      // if(item.approvelstatus === 1){
      //   return { select:false, ...item, sheetname: item.lsfile.filenameuser, transactionstatus: item.approvelstatus === 3 ? "Reject" : item.approvelstatus === 1 ? "Completed" : "" }
      // }
      orderBindData = orderBindData.map((item) => {
        // return {batchid:item.batchid, batchcode:item.batchcode, sheetname:item.lsfile.filenameuser, testname: item.testname, samplename: item.samplename, projectname: item.projectname, lsfile:item.lsfile}
        return {
          select: false,
          ...item,
          sheetname: item.lsfile.filenameuser,
          transactionstatus:
            item.approvelstatus === 3
              ? "Reject"
              : item.approvelstatus === 1
                ? "Completed"
                : "Pending",
        };
      });
      filteddata = orderBindData.filter(
        (item) =>
          item.completedtimestamp > fromDate.getTime() &&
          item.completedtimestamp < toDate.getTime()
      );
    }
    this.setState({ orderBindData, orignalData: temp, filteddata });
  }
  onGetorderbytypeselcetFailure(response) {
  }

  onFIlterClick = (event) => {
    if (
      this.state.orderBindData !== undefined &&
      this.state.orderBindData.length > 0
    ) {
      const { orderBindData, fromDate, toDate } = this.state;
      const filteddata = orderBindData.filter(
        (item) =>
          item.completedtimestamp > fromDate.getTime() &&
          item.completedtimestamp < toDate.getTime()
      );
      this.setState({ filteddata, Filterclick: true });
    }
  };

  onResetClick = () => {
    this.setState({
      filteddata: [],
      fromDate: new Date(new Date().getTime() - 90 * 24 * 60 * 60 * 1000),
      toDate: new Date(),
      Filterclick: false,
    });
  };

  onRequestSuccess = (response) => {
    let passObject = {};
    if (
      response.objResponse !== undefined &&
      response.objResponse.status === false
    ) {
      this.props.alert.error(
        this.props.t("Common.IDS_MSG_VALIDATION", { param: "" })
      );
    } else {
      if (response.status === "success") {
        this.props.alert.success(
          this.props.t("Reports.IDS_MSG_REPORTGENERATEDSUCCESSFULLY")
        );
        this.props.updateDocxRelatedState(response);
      } else {
        if (response.status !== undefined) {
          this.props.alert.error(
            this.props.t("Reports." + response.status)
          );
          passObject = {
            objsilentaudit: Silentauditobject(
              "warning",
              "reports",
              this.props.t("Reports." + response.status)
            ),
          };
        } else {
          this.props.alert.error(
            this.props.t("Reports.IDS_MSG_FAILEDDUETOUNKNOWNREASON")
          );
          passObject = {
            objsilentaudit: Silentauditobject(
              "warning",
              "reports",
              this.props.t("Reports.IDS_MSG_FAILEDDUETOUNKNOWNREASON")
            ),
          };
        }
        Servicecalls(
          "" + this.ELNurl + "/AuditTrail/AuditConfigurationrecord",
          passObject,
          (res) => { },
          (res) => { }
        );
      }
    }
  };

  submitSelectedData = () => {
    let rtnObj = {};
    if (
      this.state.Records !== undefined &&
      this.state.Records.length > 0 &&
      this.state.templatePrimaryRecords > 0
    ) {
      const { PrimaryRecords } = this.state;
      let selectedDataLst = this.state.orignalData.filter((item) =>
        PrimaryRecords.includes(item.batchcode)
      );
      let FileCodeArray = selectedDataLst.map((item) => item.lsfile.filecode);
      const lsdoctempObj = this.state.templateRecords;
      delete lsdoctempObj.select;
      // FileCodeArray.sort((itemA, itemB) => itemA < itemB);
      rtnObj["FileCodeString"] = FileCodeArray.sort(
        (itemA, itemB) => itemA - itemB
      ).join(", "); // FileCodeArray.join(", ");
      rtnObj["OrderData"] = selectedDataLst;
      rtnObj["templateRecords"] = lsdoctempObj;
      if (this.props.istemplate === 1) {
        this.props.setUndefinedHashKey();
      }
      let Audit = AuditTrailValidation("IDS_MDL_REPORTS", "IDS_SCN_REPORTS", "IDS_TSK_GENERATEREPORT");
      if (Audit) {
        const propsThis = this;
        var element = document.querySelector(
          "#downloadReportsGenerateAuditMasterModelId>.modal-dialog>.modal-content>#audittrail-modal-footer>#audittrail_closeform"
        );
        element.onmousedown = function (e) {
          if (propsThis.props.istemplate === 1) {
            propsThis.props.setHashKey();
          }
        };
        element.onkeydown = function (e) {
          if (propsThis.props.istemplate === 1) {
            propsThis.props.setHashKey();
          }
        };
        var subelement = document.querySelector(
          "#downloadReportsGenerateAuditMasterModelId>.modal-dialog>.modal-content>#audittrail-modal-footer>#audittrail_submitbtn"
        );
        subelement.onmousedown = function (e) {
          // if (propsThis.props.istemplate === 1) {
          propsThis.props.alert.success(
            propsThis.props.t("Reports.IDS_MSG_REPORTISBEINGGENERATED")
          );
          // }
        };
        subelement.onkeydown = function (e) {
          // if (propsThis.props.istemplate === 1) {
          propsThis.props.alert.success(
            propsThis.props.t("Reports.IDS_MSG_REPORTISBEINGGENERATED")
          );
          // }
        };
        let passObjDet = {
          ...userdetails(),
          ...rtnObj,
          batchcode: selectedDataLst[0].batchcode,
          batchid: selectedDataLst[0].batchid,
          sitecode: $.session.get("Usersite"),
          usercode: $.session.get("User"),
          objsilentaudit: Silentauditobject(
            "insert",
            "reports",
            this.props.t("compareObjs.IDS_REQUESTTOGENERATENEWTEMPLATE")
          ),
          objmanualaudit: Manualauditobject("insert", "reports"),
          Lsusermaster: store.getState().Loggeduser,
          isMultitenant: isMultitenant
        };
        $("#downloadReportsGenerateAuditMasterModelId").modal("show");
        if (isMultitenant === 1) {
          this.setState({
            AuditUrl: this.ELNurl + "/reports/cloudHandleOrderTemplate",
            AuditObject: passObjDet,
            AuditPassfunction: this.onRequestSuccess,
            AuditFailfunction: () => console.error(),
          });
        } else {
          this.setState({
            AuditUrl: this.ELNurl + "/reports/handleOrderTemplate",
            AuditObject: passObjDet,
            AuditPassfunction: this.onRequestSuccess,
            AuditFailfunction: () => console.error(),
          });
        }
      } else {
        this.onSilentAuditSubmit(rtnObj, selectedDataLst);
      }
    } else {
      this.props.alert.error(this.props.t("Reports.IDS_MSG_NODATASELECTED"));
      let passObject = {
        objsilentaudit: Silentauditobject(
          "warning",
          "reports",
          this.props.t("Reports.IDS_MSG_NODATASELECTED")
        ),
      };
      Servicecalls(
        "" + this.ELNurl + "/AuditTrail/AuditConfigurationrecord",
        passObject,
        (res) => { },
        (res) => { }
      );
    }
  };

  nextStepOrPrevStep = (type) => {
    if (type === "next") {
      if (this.state.PrimaryRecords.length > 0) {
        this.setState({ stepOne: false, stepTwo: true }); //, filteddata: [], PrimaryRecords:[], Records:[]
        this.getUpdatedtemplate(this.state.genReportType);
      } else {
        this.props.alert.error(
          this.props.t("Reports.IDS_MSG_NODATASELECTED")
        );
      }
    } else {
      this.setState({ stepOne: true, stepTwo: false });
      this.onFIlterClick(this);
    }
  };

  getUpdatedtemplate = (templatetype) => {
    let rtnObj = {};
    let selectedDataLst = this.state.orignalData.filter((item) =>
      this.state.PrimaryRecords.includes(item.batchcode)
    );
    let FileCodeArray = selectedDataLst.map((item) => item.lsfile.filecode);
    rtnObj["ismultiplesheet"] = templatetype;
    if (templatetype === 0) {
      rtnObj["FileCodeString"] = FileCodeArray.sort(
        (itemA, itemB) => itemA - itemB
      ).join(", ");
    } else {
      let FileCodeString = "";
      if (FileCodeArray.length > 1) {
        const first = FileCodeArray[0];
        const anotherPresent = FileCodeArray.findIndex(
          (item) => item !== first
        );
        // let anotherPresent = -1
        if (anotherPresent > -1) {
          this.props.alert.error(
            "different types of sheet cannot be processed"
          );
          this.setState({ LSDocReportsLstData: [] });
        } else {
          FileCodeString = FileCodeArray[0].toString();
        }
      } else {
        FileCodeString = FileCodeArray[0].toString();
      }
      rtnObj["FileCodeString"] = FileCodeString;
    }
    let passObjDet = {
      ...userdetails(),
      ...rtnObj,
      objsilentaudit: Silentauditobject(
        "insert",
        "reports",
        this.props.t("compareObjs.IDS_REQUESTTOGENERATENEWTEMPLATE")
      ),
      objmanualaudit: Manualauditobject("insert", "reports"),
      // Lsusermaster: store.getState().Loggeduser
      lssitecode: store.getState().Loggeduser.lssitemaster.sitecode,
      isMultitenant: isMultitenant
    };

    Servicecalls(
      "" + this.ELNurl + "/reports/getTemplatesLst",
      passObjDet,
      (res) => {
        const response = res;
        this.setState({ LSDocReportsLstData: response.LSDocReportsLst });
      },
      (res) => {
        // console.error(
        //   "GenertateReportTemplateClass genReportTypeChange() Response: ",
        //   res.Error
        // );
      }
    );

    // request
    //   .post(this.ELNurl + "/reports/getTemplatesLst")
    //   .set({
    //     Authorization: $.session.get("Token"),
    //     "X-TenantID": $.session.get("tenantid"),
    //     Accept: "application/json",
    //   })
    //   .send(passObjDet)
    //   .then((res) => {
    //     const response = JSON.parse(res.text);
    //     this.setState({ LSDocReportsLstData: response.LSDocReportsLst });
    //   })
    //   .catch((res) => {
    //     console.error(
    //       "GenertateReportTemplateClass genReportTypeChange() Response: ",
    //       res.Error
    //     );
    //   });
  };

  genReportTypeChange = (event) => {
    this.setState({ genReportType: event.value });
    this.getUpdatedtemplate(event.value);
  };

  onSilentAuditSubmit = (rtnObj, selectedDataLst) => {
    let passObjDet = {
      ...rtnObj,
      batchcode: selectedDataLst[0].batchcode,
      batchid: selectedDataLst[0].batchid,
      sitecode: $.session.get("Usersite"),
      ...userdetails(),
      objsilentaudit: Silentauditobject(
        "insert",
        "reports",
        this.props.t("compareObjs.IDS_REQUESTTOGENERATENEWTEMPLATE")
      ),
      // Lsusermaster: store.getState().Loggeduser,
      usercode: $.session.get("User"),
      isMultitenant: isMultitenant,
    };
    this.props.alert.success(
      this.props.t("Reports.IDS_MSG_REPORTISBEINGGENERATED")
    );
    let objects = {
      objsilentaudit: Silentauditobject(
        "insert",
        "reports",
        this.props.t("Reports.IDS_MSG_REPORTISBEINGGENERATED")
      ),
    };
    Servicecalls(
      "" + this.ELNurl + "/AuditTrail/AuditConfigurationrecord",
      objects,
      (res) => { },
      (res) => { }
    );
    if (this.props.istemplate === 1) {
      if (isMultitenant === 1) {
        setTimeout(() => {
          // request
          // .post(this.ELNurl + "/reports/cloudHandleOrderTemplate")
          // .set({
          //   Authorization: $.session.get("Token"),
          //   "X-TenantID": $.session.get("tenantid"),
          //   Accept: "application/json",
          // })
          // .send(passObjDet)
          // .then((res) => {
          //   this.onRequestSuccess(JSON.parse(res.text));
          // })
          // .catch((res) => {
          //   console.error("submitSelectedData Response: ", res.Error);
          // });

          Servicecalls(
            "" + this.ELNurl + "/reports/cloudHandleOrderTemplate",
            passObjDet,
            (res) => {
              this.onRequestSuccess(res);
            },
            (res) => {
              // console.error("submitSelectedData Response: ", res.Error);
            }
          );
        }, 5000);
      } else {
        setTimeout(() => {
          Servicecalls(
            "" + this.ELNurl + "/reports/handleOrderTemplate",
            passObjDet,
            (res) => {
              this.onRequestSuccess(res);
            },
            (res) => {
              // console.error("submitSelectedData Response: ", res.Error);
            }
          );
        }, 5000);
      }
    } else {
      if (isMultitenant === 1) {
        Servicecalls(
          "" + this.ELNurl + "/reports/cloudHandleOrderTemplate",
          passObjDet,
          (res) => {
            this.onRequestSuccess(res);
          },
          (res) => {
            // console.error("submitSelectedData Response: ", res.Error);
          }
        );
      } else {
        Servicecalls(
          "" + this.ELNurl + "/reports/handleOrderTemplate",
          passObjDet,
          (res) => {
            this.onRequestSuccess(res);
          },
          (res) => {
            // console.error("submitSelectedData Response: ", res.Error);
          }
        );
      }
    }
  };

  GridSelected = (Recordsel, PrimaryRecordsel) => {
    this.setState({ Records: Recordsel, PrimaryRecords: PrimaryRecordsel });
  };

  componentDidMount() {
    let passObjDet = {
      filetype: isMultitenant === 1 ? 2 : 0,
      lsuserMaster: store.getState().Loggeduser,
      lsuserMaster: store.getState().Loggeduser,
      lsuserMasterUserCode: parseInt(sessionStorage.User),
      objsilentaudit: Silentauditobject(
        "view",
        "reports",
        this.props.t("compareObjs.IDS_REQUESTTOVIEWALLORDERSLIST")
      ),
    };
    this.setState({ fileType: isMultitenant === 1 ? 2 : 0 });
    if (this.ELNurl !== "" && !this.state.reportView) {
      this.setState({
        ServiceToUrl: "" + this.ELNurl + "/reports/Getorderbytype",
        ServiceHeaderobject: passObjDet,
        ServiceMethodType: "POST",
        ServcieonSuccess: this.onGetorderbytypeSuccess.bind(this),
        ServcieonFailure: this.onGetorderbytypeFailure.bind(this),
        ServcieProcessbar: false,
      });

      // request.post(this.ELNurl + "/reports/Getorderbytype")
      //   .set({ 'Authorization': $.session.get("Token"), 'X-TenantID': $.session.get("tenantid"), Accept: 'application/json' })
      //   .send(passObjDet)
      //   .then((res) => {
      //     const instrumentResponse = JSON.parse(res.text);
      //     let orderBindData = [];
      //     let temp = [];
      //     let filteddata = [];
      //     const { fromDate, toDate } = this.state;
      //     if (instrumentResponse.Completed !== undefined && instrumentResponse.Completed.length > 0) {
      //       temp = instrumentResponse.Completed;
      //     }
      //     // else if (instrumentResponse.Pending !== undefined && instrumentResponse.Pending.length > 0) {
      //     //   temp = instrumentResponse.Pending;
      //     // }
      //     if (temp.length > 0) {
      //       // orderBindData = temp.filter(item => item.approvelstatus === 1);
      //       orderBindData = temp.filter(item => item.orderflag.trim() === 'R');
      //       // if(item.approvelstatus === 1){
      //       //   return { select:false, ...item, sheetname: item.lsfile.filenameuser, transactionstatus: item.approvelstatus === 3 ? "Reject" : item.approvelstatus === 1 ? "Completed" : "" }
      //       // }
      //       orderBindData = orderBindData.map(item => {
      //         // return {batchid:item.batchid, batchcode:item.batchcode, sheetname:item.lsfile.filenameuser, testname: item.testname, samplename: item.samplename, projectname: item.projectname, lsfile:item.lsfile}
      //         return { select: false, ...item, sheetname: item.lsfile.filenameuser, transactionstatus: item.approvelstatus === 3 ? "Reject" : item.approvelstatus === 1 ? "Completed" : "" }
      //       })
      //       filteddata = orderBindData.filter(item => item.completedtimestamp > fromDate.getTime() && item.completedtimestamp < toDate.getTime())
      //     }
      //     this.setState({ orderBindData, orignalData: temp, filteddata });
      //   })
      //   .catch((res) => {
      //     console.error("GenertateReportTemplateClass Response: ", res.Error);
      //   });
    }
  }
  onGetorderbytypeSuccess(response) {
    const instrumentResponse = response;
    let orderBindData = [];
    let temp = [];
    let filteddata = [];
    const { fromDate, toDate } = this.state;
    if (
      instrumentResponse.Completed !== undefined &&
      instrumentResponse.Completed.length > 0
    ) {
      temp = instrumentResponse.Completed;
    }
    // else if (instrumentResponse.Pending !== undefined && instrumentResponse.Pending.length > 0) {
    //   temp = instrumentResponse.Pending;
    // }
    if (temp.length > 0) {
      // orderBindData = temp.filter(item => item.approvelstatus === 1);
      orderBindData = temp.filter((item) => item.orderflag.trim() === "R");
      // if(item.approvelstatus === 1){
      //   return { select:false, ...item, sheetname: item.lsfile.filenameuser, transactionstatus: item.approvelstatus === 3 ? "Reject" : item.approvelstatus === 1 ? "Completed" : "" }
      // }
      orderBindData = orderBindData.map((item) => {
        // return {batchid:item.batchid, batchcode:item.batchcode, sheetname:item.lsfile.filenameuser, testname: item.testname, samplename: item.samplename, projectname: item.projectname, lsfile:item.lsfile}
        return {
          select: false,
          ...item,
          sheetname: item.lsfile.filenameuser,
          transactionstatus:
            item.approvelstatus === 3
              ? "Reject"
              : item.approvelstatus === 1
                ? "Completed"
                : "",
        };
      });
      filteddata = orderBindData.filter(
        (item) =>
          item.completedtimestamp > fromDate.getTime() &&
          item.completedtimestamp < toDate.getTime()
      );
    }
    this.setState({ orderBindData, orignalData: temp, filteddata });
  }
  onGetorderbytypeFailure(response) { }

  render() {
    return (
      <React.Fragment>
        {this.props.ModalValidation && (
          <div className="GenertateReportTemplateCls">
            <Service
              Tourl={this.state.ServiceToUrl}
              MethodType={this.state.ServiceMethodType}
              onSuccess={this.state.ServcieonSuccess}
              onFailure={this.state.ServcieonFailure}
              Headerobject={this.state.ServiceHeaderobject}
              onReseturl={() => {
                this.setState({ ServiceToUrl: "" });
              }}
            ></Service>
            <Audit
              AuditId="downloadReportsGenerateAuditMasterModelId"
              AuditUrl={this.state.AuditUrl}
              AuditObject={this.state.AuditObject}
              AuditPassfunction={this.state.AuditPassfunction}
              AuditFailfunction={this.state.AuditFailfunction}
              alert={this.props.alert}
            />
            <div key={1} hidden={!this.state.stepOne}>
              <div className="grid-tool-bar">
                <h6 className="inner-head">
                  {this.props.t("Reports.IDS_LBL_GENERATEREPORT")}
                </h6>
                <div className="row">
                  <div className="col-md-10 no-pad-right md-datepicker">
                    <div className="form-group  inline-form-group">
                      <label className="form-label">
                        {" "}
                        {this.props.t("Dashboard.IDS_LBL_ORDERTYPE")}{" "}
                      </label>
                      <Select
                        className="select-list"
                        value={this.fileTypeOptions.filter(
                          (option) => option.value === this.state.fileType
                        )}
                        options={this.fileTypeOptions}
                        onChange={this.onFileTypeChange}
                        isSearchable={true}
                      />
                    </div>
                    <div className="form-group  inline-form-group">
                      <label className="form-label">
                        {" "}
                        {this.props.t("Reports.IDS_LBL_FROM")}{" "}
                      </label>
                      <Suspense fallback={<div></div>}>
                        <KendoDateTimepicker
                          DefaultValue={this.state.fromDate}
                          onDatachanges={(date) =>
                            this.setState({ fromDate: date })
                          }
                          maxdate={new Date()}
                        />
                      </Suspense>
                    </div>
                    <div className="form-group  inline-form-group">
                      <label className="form-label">
                        {" "}
                        {this.props.t("Reports.IDS_LBL_TO")}{" "}
                      </label>
                      <Suspense fallback={<div></div>}>
                        <KendoDateTimepicker
                          DefaultValue={this.state.toDate}
                          onDatachanges={(date) => this.setState({ toDate: date })}
                          maxdate={new Date()}
                        />
                      </Suspense>
                    </div>
                    <div className="form-group  inline-form-group">
                      <span>
                        <button
                          className="btn  btn-primary-blue mg-r-half"
                          onClick={this.onFIlterClick}
                        >
                          <i className="fas fa-filter" aria-hidden="true"></i>
                          {this.props.t("Reports.IDS_LBL_FILTER")}
                        </button>
                        <button
                          className="btn  btn-primary-blue"
                          onClick={this.onResetClick}
                        >
                          <i className="fas fa-redo"></i>
                          {this.props.t("Reports.IDS_LBL_RESET")}
                        </button>
                      </span>
                    </div>
                  </div>
                  <div className="col-md-2 d-inline-flex-center-right">
                    <div className="ico-btn-group move-right no-margin">
                      <button
                        className="btn btn-primary-blue mg-r-half"
                        onClick={() => this.nextStepOrPrevStep("next")}
                      >
                        <i
                          className="fa fa-chevron-right"
                          aria-hidden="true"
                        ></i>{" "}
                        {this.props.t("Reports.IDS_LBL_NEXT")}
                      </button>
                      <button
                        className="btn_white_bg txt-btn"
                        onClick={() => this.props.changeDesign("office")}
                      >
                        {this.props.t("Reports.IDS_LBL_CLOSE")}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <Suspense fallback={<div></div>}>
                <KendoGridView
                  key={1}
                  primaryKey="batchcode"
                  height={"calc(100vh - 225px)"}
                  source={
                    this.state.Filterclick === true
                      ? this.state.filteddata
                      : this.state.orderBindData
                  }
                  Columns={this.DownloadReportOrderLstColumns}
                  DataSearchvisible={false}
                  // ClickedRecord={this.GridClicked}
                  ShowDetailsVisible={false}
                  SelectedRecords={this.GridSelected}
                  // SelectedRecords={(Recordsel, PrimaryRecordsel) => { this.setState({ Records: Recordsel, PrimaryRecords: PrimaryRecordsel })}}
                  SelectedRecordsArray={this.state.Records}
                />
              </Suspense>
            </div>

            <div key={2} hidden={!this.state.stepTwo}>
              <div className="grid-tool-bar">
                <h6 className="inner-head">
                  {this.props.t("Reports.IDS_LBL_GENERATEREPORT")}
                </h6>
                <div className="row">
                  <div className="col-md-9 md-datepicker no-pad-right">
                    <div className="form-group  sm inline-form-group">
                      <label className="form-label">
                        {" "}
                        {this.props.t("Reports.IDS_LBL_TEMPLATETYPE")}{" "}
                      </label>
                      <Select
                        className="select-list"
                        value={this.genReportTypeOptions.filter(
                          (option) => option.value === this.state.genReportType
                        )}
                        options={this.genReportTypeOptions}
                        onChange={this.genReportTypeChange}
                        isSearchable={true}
                      />
                    </div>
                    <div className="form-group  sm inline-form-group">
                      <label className="form-label">
                        {" "}
                        {this.props.t("Reports.IDS_LBL_FROM")}{" "}
                      </label>
                      <Suspense fallback={<div></div>}>
                        <KendoDateTimepicker
                          DefaultValue={this.state.fromSecStepDate}
                          onDatachanges={(date) =>
                            this.setState({ fromSecStepDate: date })
                          }
                          maxdate={new Date()}
                        />
                      </Suspense>
                    </div>
                    <div className="form-group  sm inline-form-group">
                      <label className="form-label">
                        {" "}
                        {this.props.t("Reports.IDS_LBL_TO")}{" "}
                      </label>
                      <Suspense fallback={<div></div>}>
                        <KendoDateTimepicker
                          DefaultValue={this.state.toSecStepDate}
                          onDatachanges={(date) =>
                            this.setState({ toSecStepDate: date })
                          }
                          maxdate={new Date()}
                        />
                      </Suspense>
                    </div>
                    <div className="form-group  sm inline-form-group">
                      <div className="ico-btn-group move-right no-margin">
                        <button
                          className="btn btn-primary-blue mg-r-half"
                          onClick={this.onFIlterClick}
                        >
                          <i className="fas fa-filter" aria-hidden="true"></i>
                          {this.props.t("Reports.IDS_LBL_FILTER")}
                        </button>
                        <button
                          className="btn btn-primary-blue mg-r-half"
                          onClick={this.onResetClick}
                        >
                          <i className="fas fa-redo"></i>
                          {this.props.t("Reports.IDS_LBL_RESET")}
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3  d-inline-flex-center-right">
                    <span>
                      {/* {this.state.templateSave ? ( */}
                      <button
                        className="btn btn-primary-blue mg-r-half"
                        onClick={() => this.submitSelectedData()}
                      >

                        <i className="fas fa-check" aria-hidden="true"></i>{" "}
                        {this.props.t("Reports.IDS_LBL_SUBMIT")}
                      </button>
                      {/* ) : (
                        false
                      )} */}
                      <button
                        className="btn btn-primary-blue mg-r-half"
                        onClick={() => this.nextStepOrPrevStep("prev")}
                      >
                        <i
                          className="fa fa-chevron-left"
                          aria-hidden="true"
                        ></i>{" "}
                        {this.props.t("Reports.IDS_LBL_PREVIOUS")}
                      </button>
                      <button
                        className="btn_white_bg txt-btn"
                        onClick={() => this.props.changeDesign("office")}
                      >
                        {this.props.t("Reports.IDS_LBL_CLOSE")}
                      </button>
                    </span>
                  </div>
                </div>
              </div>
              <Suspense fallback={<div></div>}>
                <KendoGridView
                  key={2}
                  height={"calc(100vh - 225px)"}
                  primaryKey="docReportsCode"
                  source={this.state.LSDocReportsLstData}
                  Columns={this.DownloadReportTemplateLstColumns}
                  DataSearchvisible={false}
                  ShowDetailsVisible={false}
                  SelectedRecords={(Recordsel, PrimaryRecordsel) => {
                    this.setState({
                      templateRecords: Recordsel,
                      templatePrimaryRecords: PrimaryRecordsel,
                    });
                  }}
                  SelectedRecordsArray={this.state.templateRecords}
                />
              </Suspense>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

const GenertateReportTemplate = withAlert()(
  withTranslation(GenertateReportTemplateClass)
);

class CreateNewTemplateClass extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      Records: [],
      PrimaryRecords: [],
      TemplatesLstColumns: [
        {
          header: this.props.t("Reports.IDS_LBL_SELECT"),
          datatype: "select",
          rowselector: true,
          visible: true,
          filterable: false,
          className: "clsSelect",
          width: "10%",
        },
        {
          header: this.props.t("Dashboard.IDS_LBL_SHEETNAME"),
          datatype: "filenameuser",
          rowselector: false,
          visible: true,
          filterable: true,
          className: "clsModulename",
          width: "35%",
        },
        {
          header: this.props.t("Reports.IDS_LBL_CREATEDDATE"),
          datatype: "createdate",
          rowselector: false,
          visible: true,
          filterable: true,
          className: "clsModulename",
          width: "35%",
        },
        {
          header: this.props.t("Reports.IDS_LBL_TAGPRESENT"),
          datatype: "tagPresent",
          rowselector: false,
          visible: true,
          filterable: true,
          className: "clsModulename",
          width: "35%",
        },
        {
          header: this.props.t("Reports.IDS_LBL_TAGLIST"),
          datatype: "tagLst",
          rowselector: false,
          visible: true,
          filterable: true,
          className: "clsModulename",
          width: "35%",
        },
      ],
      fromDate: new Date(new Date().getTime() - 90 * 24 * 60 * 60 * 1000),
      toDate: new Date(),
      filteddata: [],
      ServiceToUrl: "",
      ServiceMethodType: "POST",
      ServiceHeaderobject: {},
      ServcieonSuccess: undefined,
      ServcieonFailure: undefined,
    };
    this.ELNurl = document.getElementById("ELNname").value;
  }

  GridSelected = (Recordsel, PrimaryRecordsel) => {
    this.setState({
      Records: Recordsel,
      PrimaryRecords: PrimaryRecordsel,
    });
  };

  onFIlterClick = (event) => {
    if (this.state.data.length > 0) {
      const { data, fromDate, toDate } = this.state;
      const filteddata = data.filter(
        (item) =>
          item.createdate.getTime() > fromDate.getTime() &&
          item.createdate.getTime() < toDate.getTime()
      );
      this.setState({ filteddata });
    }
  };

  onResetClick = () => {
    this.setState({
      filteddata: [],
      fromDate: new Date(new Date().getTime() - 90 * 24 * 60 * 60 * 1000),
      toDate: new Date(),
    });
  };

  submitSelectedData = () => {
    const { orginalData, PrimaryRecords } = this.state;
    if (PrimaryRecords !== undefined && PrimaryRecords.length > 0) {
      let selectedData = orginalData.filter((item) =>
        PrimaryRecords.includes(item.filecode)
      );
      this.props.onCreateNewTemplate(selectedData);
    } else {
      this.props.alert.error(this.props.t("Reports.IDS_MSG_NODATASELECTED"));
      let passObject = {
        objsilentaudit: Silentauditobject(
          "warning",
          "reports",
          this.props.t("Reports.IDS_MSG_NODATASELECTED")
        ),
      };
      Servicecalls(
        "" + this.ELNurl + "/AuditTrail/AuditConfigurationrecord",
        passObject,
        (res) => { },
        (res) => { }
      );
    }
  };

  componentDidMount() {
    const passObjDet = {
      ...userdetails(),
      objsilentaudit: Silentauditobject(
        "view",
        "reports",
        this.props.t(
          "Reports.IDS_AUDIT_REQUESTTOVIEWAPPROVEDSHEETSFORTEMPLATECREATION"
        )
      ),
      isMultitenant: isMultitenant,
      sitecode: Number($.session.get("Usersite")),
    };
    this.setState({
      ServiceToUrl: "" + this.ELNurl + "/reports/getSheetLSfileLst",
      ServiceHeaderobject: passObjDet,
      ServiceMethodType: "POST",
      ServcieonSuccess: this.ongetSheetLSfileLstSuccess.bind(this),
      ServcieonFailure: this.ongetSheetLSfileLstFailure.bind(this),
      ServcieProcessbar: false,
    });
    // request.post(this.ELNurl + "/reports/getSheetLSfileLst")
    //   .set({ 'Authorization': $.session.get("Token"), 'X-TenantID': $.session.get("tenantid"), Accept: 'application/json' })
    //   .send(passObjDet)
    //   .then((res) => {
    //     const Response = JSON.parse(res.text);
    //     let data = Response.filelst.map(item => {
    //       if (item.filecontent !== null) {
    //         const tags = JSON.parse(item.filecontent).tags;
    //         let tagPresent = this.props.t("DownloadReport.IDS_FALSE", { param: "" });
    //         let tagLst = "";
    //         if (tags !== undefined) {
    //           if (tags.length > 0) {
    //             tagPresent = this.props.t("DownloadReport.IDS_TRUE", { param: "" });
    //             tagLst = tags.map(tagItem => tagItem.tagname).join(", ");
    //           }
    //         }
    //         return { select: false, filecode: item.filecode, createdate: new Date(item.createdate), filenameuser: item.filenameuser, tagPresent, tagLst }
    //       } else {
    //         return false;
    //       }
    //     })
    //     data = data.filter(item => item !== false);
    //     const { fromDate, toDate } = this.state;
    //     const filteddata = data.filter(item => item.createdate.getTime() > fromDate.getTime() && item.createdate.getTime() < toDate.getTime())
    //     this.setState({ data, filteddata, orginalData: Response.filelst })
    //   })
    //   .catch((res) => {
    //     console.error("GenertateReportTemplateClass Response: ", res.Error);
    //   });
  }

  ongetSheetLSfileLstSuccess(response) {
    const Response = response;
    let data = Response.filelst.map((item) => {
      if (item.filecontent !== null && item.filecontent !== undefined) {
        const tags = JSON.parse(item.filecontent).tags;
        let tagPresent = this.props.t("Reports.IDS_LBL_FALSE", {
          param: "",
        });
        let tagLst = "";
        if (tags !== undefined) {
          if (tags.length > 0) {
            tagPresent = this.props.t("Reports.IDS_LBL_TRUE", { param: "" });
            tagLst = tags.map((tagItem) => tagItem.tagname).join(", ");
          }
        }
        return {
          select: false,
          filecode: item.filecode,
          createdate: new Date(item.createdate),
          filenameuser: item.filenameuser,
          tagPresent,
          tagLst,
        };
      } else {
        return false;
      }
    });
    data = data.filter((item) => item !== false);
    const { fromDate, toDate } = this.state;
    const filteddata = data.filter(
      (item) =>
        item.createdate.getTime() > fromDate.getTime() &&
        item.createdate.getTime() < toDate.getTime()
    );
    this.setState({ data, filteddata, orginalData: Response.filelst });
  }
  ongetSheetLSfileLstFailure(response) {
    // console.error("GenertateReportTemplateClass Response: ", response.Error);
  }

  render() {
    return (
      <div>
        <Service
          Tourl={this.state.ServiceToUrl}
          MethodType={this.state.ServiceMethodType}
          onSuccess={this.state.ServcieonSuccess}
          onFailure={this.state.ServcieonFailure}
          Headerobject={this.state.ServiceHeaderobject}
          onReseturl={() => {
            this.setState({ ServiceToUrl: "" });
          }}
        ></Service>
        <div className="GenertateReportTemplateCls">
          <div className="grid-tool-bar">
            <h6 className="inner-head">
              {this.props.t("Reports.IDS_LBL_SELECTSHEETFORTEMPLATECREATION")}
            </h6>
            <div
              className="row">
              <div className="col-md-9 md-datepicker no-pad-right">
                <div className="form-group  inline-form-group">
                  <label className="form-label">
                    {" "}
                    {this.props.t("Reports.IDS_LBL_FROM")}{" "}
                  </label>
                  <Suspense fallback={<div></div>}>
                    <KendoDateTimepicker
                      DefaultValue={this.state.fromDate}
                      onDatachanges={(date) => this.setState({ fromDate: date })}
                      maxdate={new Date()}
                    />
                  </Suspense>
                </div>
                <div className="form-group  inline-form-group">
                  <label className="form-label">
                    {" "}
                    {this.props.t("Reports.IDS_LBL_TO")}{" "}
                  </label>
                  <Suspense fallback={<div></div>}>
                    <KendoDateTimepicker
                      DefaultValue={this.state.toDate}
                      onDatachanges={(date) => this.setState({ toDate: date })}
                      maxdate={new Date()}
                    />
                  </Suspense>
                </div>
                <div className="form-group  inline-form-group">
                  <span>
                    <button
                      className="btn  btn-primary-blue mg-r-half"
                      onClick={this.onFIlterClick}
                    >
                      <i className="fas fa-filter" aria-hidden="true"></i>
                      {this.props.t("Reports.IDS_LBL_FILTER")}
                    </button>
                    <button
                      className="btn  btn-primary-blue"
                      onClick={this.onResetClick}
                    >
                      <i className="fas fa-redo"></i>
                      {this.props.t("Reports.IDS_LBL_RESET")}
                    </button>
                  </span>
                </div>
              </div>
              <div className="col-md-3">
                <div className="ico-btn-group move-right no-margin">
                  {/* {this.state.templateSave ? ( */}
                  <button
                    className="btn btn-primary-blue mg-r-half"
                    onClick={() => this.submitSelectedData()}
                  >

                    <i className="fas fa-check" aria-hidden="true"></i>{" "}
                    {this.props.t("Reports.IDS_LBL_SUBMIT")}
                  </button>
                  {/* ) : (
                    false
                  )} */}
                  <button
                    className="btn_white_bg txt-btn"
                    onClick={() => this.props.changeDesign("office")}
                  >
                    {this.props.t("Reports.IDS_LBL_CLOSE")}
                  </button>
                </div>
              </div>
            </div>
          </div>

          {/* <Modal centered isOpen={this.state.issave}>
          <div className="modal-header">
            <h5 className="modal-title" id="staticBackdropLabel">
              {this.props.t("Reports.IDS_LBL_CONTENTCHANGE")}
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={this.closeModel.bind(this)}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <ModalBody>
            <div className="login-form">
              <p>{this.props.t("Reports.IDS_LBL_CONTENTMODIFIED")}</p>
            </div>
          </ModalBody>
          <div id="UserMasterADD-modal-footer" className="modal-footer">
            <button
              type="button"
              id="idSaveSheetAs"
              className="btn btn-user btn-primary-blue"
              onClick={this.onsavefunction.bind(this)}
            // disabled={this.state.Selectedfield !== null && this.state.Selectedfield !== undefined &&
            //   Object.keys(this.state.Selectedfield).length > 0 ? "" : "disabled"}
            >
              {this.props.t("Reports.IDS_LBL__SAVE")}
            </button>
            <button
              type="button"
              id="idSaveSheetAs"
              className="btn btn-user btn-primary-blue"
              onClick={this.dontsavefunction.bind(this)}
            // disabled={this.state.Selectedfield !== null && this.state.Selectedfield !== undefined &&
            //   Object.keys(this.state.Selectedfield).length > 0 ? "" : "disabled"}
            >
              {this.props.t("Reports.IDS_LBL_DONTSAVE")}
            </button>
            <button
              type="button"
              className="btn modalbluebtn"
              onClick={this.closeModel.bind(this)}
            >
              {this.props.t("Reports.IDS_LBL_CANCEL", { param: "" })}
            </button>
          </div>
        </Modal> */}


          <Suspense fallback={<div></div>}>
            <KendoGridView
              primaryKey="filecode"
              height={"calc(100vh - 225px)"}
              source={
                this.state.filteddata.length > 0
                  ? this.state.filteddata
                  : this.state.data
              }
              Columns={this.state.TemplatesLstColumns}
              DataSearchvisible={false}
              ShowDetailsVisible={false}
              SelectedRecords={this.GridSelected}
              SelectedRecordsArray={this.state.Records}
            />
          </Suspense>
          <Suspense fallback={<div></div>}>
            <Audit
              AuditId="downloadReportsTemplateAuditId"
              AuditUrl={this.props.AuditUrl}
              AuditObject={this.props.AuditObject}
              AuditPassfunction={this.props.AuditPassfunction}
              AuditFailfunction={this.props.AuditFailfunction}
              alert={this.props.alert}
            />
          </Suspense>
        </div>
      </div>
    );
  }
}

const CreateNewTemplate = withAlert()(withTranslation(CreateNewTemplateClass));

class onlyOfficeReadOnlyClass extends Component {
  constructor() {
    super();
    this.ELNurl = document.getElementById("ELNname").value;
    this.state = { response: {} };
  }
  componentDidMount() {
    let passObjDet = {
      ...userdetails(),
      // objsilentaudit: Silentauditobject(
      //   "view",
      //   "reports",
      //   this.props.t("compareObjs.IDS_REPORTSSCREENLOADED", {
      //     screenName: AuditModuleNameEnum["reports"],
      //   })
      // ),
    };
    // request
    //   .post(this.ELNurl + "/reports/getDownloadReportsInitRequest")
    //   .set({
    //     Authorization: $.session.get("Token"),
    //     "X-TenantID": $.session.get("tenantid"),
    //     Accept: "application/json",
    //   })
    //   .send(passObjDet)
    //   .then((res) => {
    //     const response = JSON.parse(res.text);
    //     if ("config" in response) {
    //       if (
    //         "onlyofficeapiConfigObj" in response.config &&
    //         "docxurlConfigObj" in response.config
    //       ) {
    //         if (
    //           window.DocsAPI === undefined &&
    //           "onlyofficeapiConfigObj" in response.config
    //         ) {
    //           let DocsAPIScript = document.createElement("script");
    //           DocsAPIScript.src =
    //             response.config.onlyofficeapiConfigObj.configpath;
    //           document.body.appendChild(DocsAPIScript);
    //         }
    //       }
    //       this.setState({ config: response.config });
    //     }
    //   })
    //   .catch((res) => console.error(res));

    Servicecalls(
      this.ELNurl + "/reports/getDownloadReportsInitRequest",
      passObjDet,
      (res) => {
        const response = res;
        if ("config" in response) {
          if (
            "onlyofficeapiConfigObj" in response.config &&
            "docxurlConfigObj" in response.config
          ) {
            if (
              window.DocsAPI === undefined &&
              "onlyofficeapiConfigObj" in response.config
            ) {
              let DocsAPIScript = document.createElement("script");
              DocsAPIScript.src =
                response.config.onlyofficeapiConfigObj.configpath;
              document.body.appendChild(DocsAPIScript);
            }
          }
          this.setState({ config: response.config });
        }
      },
      (res) => {
        // console.error(res);
      }
    );

    if (window.location.search !== undefined && window.location.search !== "") {
      let documentInfo = JSON.parse(
        decodeURIComponent(window.location.search.replace("?docxId=", ""))
      );
      let passObjDet = {
        ...userdetails(),
        ...documentInfo,
        // objsilentaudit: Silentauditobject(
        //   "view",
        //   "reports",
        //   this.props.t("compareObjs.IDS_REQUESTOOPENFILE", {
        //     fileName: documentInfo.fileName,
        //   })
        // ),
      };
      setTimeout(() => {
        const updateUrl = this.ELNurl + "/reports/getReportDocxInfo";

        Servicecalls(
          updateUrl,
          passObjDet,
          (res) => {
            const response = res;
            this.setState({ fileRes: response });
          },
          (res) => {
            // console.error(res);
          }
        );
      }, 1000);
    }
  }
  render() {
    return (
      <div className="bg-white" style={{ height: "100vh", display: "block" }}>
        <Suspense fallback={<div></div>}>
          <OnlyOfficeDocs
            height={"100%"}
            fileRes={this.state.fileRes}
            config={this.state.config}
            editable={false}
            callBackURL={
              isMultitenant === 1
                ? "/reports/cloudsaveDocxsReport"
                : "/reports/saveDocxsReport"
            }
          /></Suspense>
      </div>
    );
  }
}

export const onlyOfficeReadOnly = withAlert()(
  withTranslation(onlyOfficeReadOnlyClass)
);
