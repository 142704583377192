const AuditActionEnum = {
    "view": "IDS_TSK_VIEW",
    "edit": "IDS_TSK_EDIT",
    "create": "IDS_TSK_CREATE",
    "Review": "IDS_TSK_REVIEW",
    "insert": "IDS_TSK_INSERT",
    "update": "IDS_TSK_UPDATE",
    "delete": "IDS_TSK_DELETE",
    "upload": "IDS_TSK_UPLOADATTACH",
    "passwordchange": "IDS_TSK_PASSWORDCHANGE",
    "login": "IDS_TSK_LOGIN",
    "createarchive": "IDS_TSK_CREATEARCHIVE",
    "validate": "IDS_VALIDATE",
    "active/deactive": "IDS_TSK_ACTDEACT",
    "unlock": "IDS_TSK_UNLOCK",
    "passwordreset": "IDS_TSK_PASSWORDRESET",
    "Change Password": "IDS_TSK_CHANGEPASSWORD",
    "Export": "IDS_TSK_EXPORT",
    "disable": "IDS_TSK_DISABLE",
    "save": "IDS_TSK_AUDITSAVE",
    "openarchive": "IDS_TSK_OPENARCHIVE",
    "open": "IDS_TSK_OPEN",
    "enable": "IDS_TSK_ENABLE",
    "Share": "IDS_TSK_SHARE",
    "warning": "IDS_TSK_WARNING",
    "version": "IDS_TSK_VERSION",
    "download": "IDS_DOWNLOAD",
    "Logout": "IDS_TSK_LOGOUT",
    "reset": "IDS_TSK_RESET",
    "retire": "IDS_TSK_RETIRE",
    "dispose": "IDS_TSK_DISPOSE",
    "lock": "IDS_TSK_LOCK",
    "eSign": "IDS_TSK_ESIGN",
    "cancel": "IDS_CANCEL",
    "close": "IDS_TSK_CLOSE",
    "select": "IDS_TSK_SELECT",
    "unselect": "IDS_TSK_UNSELECT",
    "Approve": "IDS_TSK_APPROVE",
    "import": "IDS_TSK_IMPORT",
    "Mapping": "IDS_TSK_MAP",
    "Complete Task": "IDS_TSK_COMPLETETASK",
    "unshare": "IDS_TSK_UNSHARE",
    "registered": "IDS_TSK_REGISTERED",
    "assign": "IDS_TSK_ASSIGN",
    "skip": "IDS_TSK_SKIPSTEP",
    "export": "IDS_TSK_EXPORTFILE",
    "start": "IDS_TSK_START",
    "copy": "IDS_TSK_COPY",
    "reject": "IDS_TSK_REJECT",
    "stepcomplete": "IDS_TSK_COMPLETESTEP",
    "return": "IDS_TSK_RETURN",
    "approve": "IDS_TSK_APPROVE",
    "link": "IDS_TSK_LINK",
    "process protocol": "IDS_TSK_PROCESSPROTOCOL",
    "process order": "IDS_TSK_PROCESSORDER",
    "compare": "IDS_TSK_COMPARE",
    "Restore": "IDS_TSK_RESTORE",
    "Open": "IDS_TSK_OPEN",
    "Move Order": "IDS_TSK_MOVEORDER",
    "New Step": "IDS_TSK_NEWSTEP",
    "Move": "IDS_TSK_MOVE",
    "Revert": "IDS_TSK_REVERT",
    "syncLims": "IDS_LBL_SYNCLIMS",
    "webParserSync": "IDS_LBL_WEBPARSERSYNC",
    "senttolims": "IDS_LBL_SENDTOLIMS",
    "Filter": "Filter",
    "Details": "Details",
    "Released": "Released",
    "clear": "IDS_TSK_CLEAR",
    "switchsite": "IDS_TSK_SWITCHSITE"

}
export default AuditActionEnum;

export const AuditModuleNameEnum = {
    "notification": "Notification",
    "search": "IDS_SCN_SEARCH",
    "Login": "IDS_SCN_LOGIN",
    "inventory": "IDS_SCN_INVENTORY",
    "logbook": "IDS_SCN_LOGBOOK",
    "LogBook": "IDS_SCN_LOGBOOK",
    "reports": "IDS_SCN_REPORTS",
    "user management": "IDS_SCN_PROFILEPICTURE",
    // "User Management":"User Management",
    "project team": "IDS_SCN_PROJECTTEAM",
    "usergroup": "IDS_SCN_USERGROUP",
    "Project Master": "Project Master",
    "usermaster": "IDS_SCN_USERMASTER",
    "domain": "IDS_SCN_DOMAIN",
    "orderworkflow": "IDS_SCN_ORDERWORKFLOW",
    "Order Workflow": "Order Workflow",
    "sheetworkflow": "IDS_SCN_SHEETWORKFLOW",
    "TemplateWorkflow": "Template Workflow",
    "protocolworkflow": "IDS_SCN_PROTOCOLWORKFLOW",
    "IDS_SCN_SHEETORDERS": "Sheet Orders",
    "IDS_SCN_PROTOCOLORDERS": "Protocol Orders",
    "userrights": "IDS_SCN_USERRIGHTS",
    "activeuser": "IDS_SCN_ACTIVEUSER",
    "user name": "User Name",
    "passwordpolicy": "IDS_SCN_PASSWORDPOLICY",
    "addscreen": "IDS_SCN_ADSSCREEN",
    "userteam": "IDS_SCN_USERTEAM",
    "Task Master": "IDS_SCN_TASKMASTER",
    // "IDS_SCN_TASKMASTER":"Task Master",
    "Base Master": "Base Master",
    "ProjectMaster": "IDS_SCN_PROJECTMASTER",
    "Projectmaster": "Project Master",
    "IDS_SCN_PROJECTMASTER": "Project Master",
    "Sample Master": "IDS_SCN_SAMPLEMASTER",
    "InstrumentMaster": "IDS_SCN_INSTRUMENTMASTER",
    "Home": "IDS_SCN_HOME",
    "Template mapping": "IDS_SCN_TEMPLATEMAPPING",
    "IDS_SCN_TEMPLATEMAPPING": "Template Mapping",
    "TemplateMapping": "Template Mapping",
    "DashBoard": "IDS_SCN_DASHBOARD",
    "SheetView": "IDS_SCN_SHEETORDERS",
    "Methodsetup": "Methodsetup",
    "AuditTrailHistory": "IDS_SCN_AUDITTRAILHIS",
    "Audittrailconfiguration": "IDS_SCN_AUDITTRAILCONFIG",
    "Auditrail": "IDS_SCN_AUDITTRAIL",
    "Createarchive": "Create Archive",
    "openarchive": "Open Archive",
    "LimsTaskOrder": "Lims Task Order",
    "Elntaskorder": "Eln Task Order",
    "Researchactivityorder": "Research Activity Order",
    "Manageexcel": "Manage Excel",
    "Sheetevaluation": "Sheet Evaluation",
    "Cfr setting": "IDS_SCN_CFRSETTINGS",
    "SiteMaster": "IDS_SCN_SITEMASTER",
    "ELN Task Order": "ELN Task Order",
    "Research Activity Order": "Research Activity Order",
    "Manage Excel": "Manage Excel",
    "Sheet Evaluation": "Sheet Evaluation",
    "LIMS Task Order": "LIMS Task Order",
    "protocolorder": "Register Protocol Orders & Execute",
    "Protocol Order And Register": "Protocol Order",
    "Protocol template": "IDS_SCN_PROTOCOLTEMP",
    "Sheet Workflow": "IDS_SCN_SHEETWORKFLOW",
    "Templates": "Templates",
    "Instrument Category": "IDS_SCN_INSTRUMENTCATEGORY",
    "about": "IDS_SCN_ABOUT",
    "profilepicture": "Profile Picture",
    "Protocol Templates": "IDS_SCN_PROTOCOLTEMPLATE",
    "Protocol Workflow": "Protocol Workflow",
    "Templates Shared By Me": "Templates Shared By Me",
    "Templates Shared To Me": "Templates Shared To Me",
    "My Orders": "My Orders",
    "Pending work": "Pending Work",
    "Assigned Orders": "Assigned Orders",
    "Completed Work": "Completed Work",
    "Orders Shared By Me": "Orders Shared By Me",
    "Order Shared To Me": "Order Shared To Me",
    "login": "Login",
    "Protocol Orders": "IDS_SCN_PROTOCOLORDERS",
    "Sheet Orders": "IDS_SCN_SHEETORDERS",
    "Report Designer": "IDS_SCN_REPORTDESIGNER",
    "Continue": "IDS_AUDIT_CONTINUE",
    "Report Viewer": "IDS_SCN_REPORTVIEWER",
    "UnLockOrders": "IDS_SCN_UNLOCKORDERS",
    "Sheetorder": "Sheet Order",
    "Settings": "IDS_SCN_SETTINGS",
    "Parser": "Parser",
    "Instruments": "IDS_SCN_INSTRUMENTMASTER",
    "Delimiter": "IDS_SCN_DELIMITER",
    "IDS_SCN_DELIMITER": "Delimiters",
    "Method Delimiter": "IDS_SCN_METHODDELIMITER",
    "IDS_SCN_METHODDELIMITER": "Method Delimiter",
    "Method Master": "IDS_SCN_METHODMASTER",
    "IDS_SCN_METHODMASTER": "Method Master",
    "Sheet Order": "IDS_SCN_SHEETORDERS",
    "logout": "IDS_SCN_LOGOUT",
    "Sheet Templates": "IDS_SCN_SHEETTEMPLATES",
    "User Management": "IDS_SCN_USERMANAGEMENT",
    "IDS_SCN_SHEETTEMPLATES": "Sheet Templates",
    "IDS_SCN_PROTOCOLTEMPLATE": "Protocol Templates",
    "IDS_SCN_TASKMASTER": "Task Master",
    "IDS_SCN_SAMPLEMASTER": "Sample Master",
    "IDS_SCN_INVENTORY": "Inventory",
    "IDS_SCN_USERGROUP": "User Role",
    "IDS_SCN_USERMASTER": "User Master",
    "IDS_SCN_PROJECTTEAM": "Project Team",
    "IDS_SCN_ORDERWORKFLOW": "Order Workflow",
    "IDS_SCN_TEMPLATEWORKFLOW": "Template Workflow",
    "IDS_SCN_PASSWORDPOLICY": "Password Policy",
    "IDS_SCN_AUDITTRAILHIS": "Audit Trail History",
    "IDS_SCN_AUDITTRAIL": "Audit Trail History",
    "IDS_SCN_CFRSETTINGS": "CFR Settings",
    "IDS_SCN_REPORTS": "Reports",
    "IDS_SCN_INSTRUMENTCATEGORY": "Instrument Category",
    "IDS_SCN_LOGBOOK": "Logbook",
    "IDS_SCN_SITEMASTER": "Site Master",
    "IDS_SCN_DOMAIN": "Domain",
    "IDS_SCN_UNITMASTER": "IDS_SCN_UNITMASTER",
    "IDS_SCN_SECTIONMASTER": "IDS_SCN_SECTIONMASTER",
    "IDS_SCN_MATERIALTYPE": "IDS_SCN_MATERIALTYPE",
    "IDS_SCN_MATERIAL": "IDS_SCN_MATERIAL",
    "IDS_SCN_GRADEMASTER": "IDS_SCN_GRADEMASTER",
    "IDS_SCN_SUPPLIER": "IDS_SCN_SUPPLIER",
    "IDS_SCN_MANUFACTURER": "IDS_SCN_MANUFACTURER",
    "IDS_SCN_MATERIALCATEGORY": "IDS_SCN_MATERIALCATEGORY",
    "IDS_SCN_STORAGELOCATION": "IDS_SCN_STORAGELOCATION",
    "IDS_SCN_MATERIALINVENTORY": "IDS_SCN_MATERIALINVENTORY"
}