import React from "react";
import { withTranslation } from "react-multi-lang";
import $ from "jquery";
import { Modal, ModalBody } from "reactstrap";


import {
  Grid,
  GridColumn as Column,
  GridColumnMenuSort,
  GridColumnMenuFilter,
  GridToolbar,
  GridColumnMenuWrapper
} from "@progress/kendo-react-grid";
import { process } from "@progress/kendo-data-query";
import { ExcelExport } from "@progress/kendo-react-excel-export";
import { GridPDFExport } from '@progress/kendo-react-pdf';
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import { gzip } from "pako";
import { saveAs } from 'file-saver';
import {
  ColumnMenuCheckboxFilter,
  CustomCell,
  CustomHeader,
  // CustomButtonCell,
  CustomButtonHeader,
  CustomButtonHeaderforusergroup,
  ColumnMenu,
  CustomOrderTransaction,
  OrderharedbymeAlart,
  CompletedCelltemp,
  ProtocolorderunshareViewCell1,
  Protocolactioncell,
  Sheetactioncell,
  Protocolactionsharbycell,
  Sheetactionsharebycell,
  MyCustomButtonCell,
  Parseractioncell,
  InventoryCell,
  MaterialCell,
  MaterialNameAction,
  EquipmentCell,
} from "./columnMenu.jsx";
// import ColumnMenu from './columnMenu.jsx';
import PendingprotocolorderViewCell from "./PendingprotocolorderViewCell.jsx";
import PendingViewCell from "./PendingViewCell.jsx";
import CustomDetailviewCell, { Customcompletecell, FormatResultUsedDetailscell, FormatResultUsedEquipment, Formatprotocolordertransactioncell, Formatprotocolversiondetailscell, } from "./CustomDetailviewCell.jsx";
import { CustomUsergroupviewCells, Custominventrycells, Formatprotocolversioncell, Formatprotocoltransactioncell } from "./CustomDetailviewCell.jsx";
import { LocalizationProvider, loadMessages } from "@progress/kendo-react-intl";
import {
  // ShareforprotocolTemplate,
  ShareforsheetTemplate,
  Shareorder,
  // Shareorderforprotocol,
  // Unshareforprotocolorder,
  UnshareforprotocolTemplate,
  // UnshareforsheetTemplate,
} from "./../custom_functions/ManipulationFunction.jsx";

// import { CopyToClipboard } from "react-copy-to-clipboard";
import enMessages from "./../custom_modules/translations/en.json";
import koMessages from "./../custom_modules/translations/ko.json";
import frMessages from "./../custom_modules/translations/fr.json";
import { withAlert } from "react-alert";
import {
  Servicecalls, sortDataByDate
} from "./../custom_functions/Functions.jsx";
import { NavLink } from "react-router-dom";
import ImportData from "./ImportData.jsx";
import { Tooltip } from "@progress/kendo-react-tooltip";

const Userdetail = React.lazy(() => import("./../custom_modules/general/Userdetail.jsx"));


loadMessages(enMessages, "en-En");
loadMessages(koMessages, "ko-KO");
loadMessages(frMessages, "fr-FR");
const axios = require("axios");
// const ColumnMenu = (props) => {
//     return (
//         <div>
//             {/* <GridColumnMenuSort {...props} /> */}
//             <GridColumnMenuFilter />
//         </div>
//     );
// };
let gridPDFExport;
const initialPagerType = "numeric";
let itemperpage =
  localStorage.getItem("Elnitemperpage") !== undefined &&
    localStorage.getItem("Elnitemperpage") !== null
    ? parseInt(localStorage.getItem("Elnitemperpage"))
    : 10;

class KendoGridView extends React.Component {
  lastSelectedIndex = 0;
  Ismultiselect = false;
  constructor(props) {
    super(props);

    let dataset = {};
    dataset.source = this.props.source !== undefined ? this.props.source : [];
    this.state = this.createDataState(0, itemperpage, dataset, []);

    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);

    this.rendercolumn = [];
    this.renderfield = [];
    this.onsavefinaldata = [];
    this.enableTooltip = props?.enableTooltip
    this.gridUniqueId = props?.name
    this.export = React.createRef(null);
    this.isDuplicateobj = {
      "isDuplicate": false,
      "index": -1,
      "emptycolumnname": "",
      "totalcolumnempty": 0
    };
    // this.pdfExport = React.createRef(null);
    this.ELNurl = document.getElementById("ELNname").value;

    this.month = [
      this.props.t("months.IDS_LBL_JAN", { param: "" }),
      this.props.t("months.IDS_LBL_FEB", { param: "" }),
      this.props.t("months.IDS_LBL_MAR", { param: "" }),
      this.props.t("months.IDS_LBL_APR", { param: "" }),
      this.props.t("months.IDS_LBL_MAY", { param: "" }),
      this.props.t("months.IDS_LBL_JUN", { param: "" }),
      this.props.t("months.IDS_LBL_JUL", { param: "" }),
      this.props.t("months.IDS_LBL_AUG", { param: "" }),
      this.props.t("months.IDS_LBL_SEP", { param: "" }),
      this.props.t("months.IDS_LBL_OCT", { param: "" }),
      this.props.t("months.IDS_LBL_NOV", { param: "" }),
      this.props.t("months.IDS_LBL_DEC", { param: "" }),
    ];
  }

  componentDidMount() {
    this.updateWindowDimensions();
    this.Initiatedata(this.props);
    window.addEventListener("resize", this.updateWindowDimensions);
    itemperpage =
      localStorage.getItem("Elnitemperpage") !== undefined &&
        localStorage.getItem("Elnitemperpage") !== null
        ? parseInt(localStorage.getItem("Elnitemperpage"))
        : 10;
    if (this.props.name !== undefined) {
      let passObjDet = {
        gridname: this.props.name,
        usercode: $.session.get("User"),
        sitecode: $.session.get("Usersite"),
      };

      Servicecalls(
        "" + this.ELNurl + "/User/getGroupedcolumn",
        passObjDet,
        this.ongetgroupsuccess.bind(this),
        this.ongetgroupfailure.bind(this)
      );
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
    this.Initiatedata(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.Initiatedata(nextProps);
    if (nextProps.importlistpopup !== undefined && nextProps.importlistpopup) {
      this.setState({ IsOpen: false })
    }
  }

  exportPDF = () => {
    if (gridPDFExport !== null) {
      gridPDFExport.save();
    }
  };

  async onxportcsv() {
    let data = this.state.orginaldata;
    let tempdate;
    let tempmdate;
    let templdate;
    let tempedate;

    for (let nlen = 0; nlen < data.length; nlen++) {
      tempdate = data[nlen].createddate;
      console.log(typeof (tempdate));
      if (typeof (tempdate) === 'number') {
        data[nlen].createddate = (new Date(tempdate));
      }
      tempmdate = data[nlen].modifieddate;
      console.log(typeof (tempdate));
      if (typeof (tempmdate) === 'number') {
        data[nlen].modifieddate = (new Date(tempmdate));
      }
      templdate = data[nlen].lastloggedon;
      console.log(typeof (templdate));
      if (typeof (templdate) === 'number') {
        data[nlen].lastloggedon = (new Date(templdate));
      }
      tempedate = data[nlen].passwordexpirydate;
      console.log(typeof (tempedate));
      if (typeof (tempedate) === 'number') {
        data[nlen].passwordexpirydate = (new Date(tempedate));
      }
    }
    const inputData = {
      //exportDataList: this.state.orginaldata !== undefined ? this.state.orginaldata : this.state.data.data !== undefined ? this.state.data.data : this.state.data,
      exportDataList: this.state.orginaldata !== undefined ? data : this.state.data.data !== undefined ? this.state.data.data : this.state.data,

      deepDataObject: { createdby: ["createdby"] },
      title: this.props.screenName !== undefined ? this.props.screenName.toUpperCase() : "",
      footerText: "Agaram Technologies Pvt LTD",
      exportFormat: "csv",
      pageOrientation: "landscape",
      localeCode: "en-us",
      timeZone: "Asia/Calcutta",
    };


    const inputParam = {
      url: "/exportFile",
      formName: "DATAGRID",
      nextPage: "",
      hideMenu: true,
      transVisibility: true,
      exportVisibility: true,
      screenName: this.props.screenName !== undefined ? this.props.screenName.toUpperCase() : "usermaster",
      operation: "CSVExport",
      inputData: inputData,
      deepDataObject: { createdby: ["createdby"] },
    };
    // let auditInput = {
    //   inputData: { saveAuditTrail: true },
    // };

    // let reqURL = this.ELNurl.post(inputParam.url, await gzip(JSON.stringify({ ...inputParam.inputData })), {
    //   //reqURL = rsapi.post(inputParam.url,  await gzip({...inputParam.inputData }) , {
    //   responseType: "arraybuffer",
    //   headers: {
    //     'Authorization': $.session.get("Token"),
    //     'X-TenantID': $.session.get("tenantid"),
    //     'Content-Type': 'application/json',
    //     "Content-Encoding": "gzip",
    //     Accept: 'text/csv'
    //   }
    // })


    await axios
      .post(
        "" + this.ELNurl + inputParam.url,
        await gzip(JSON.stringify({ ...inputParam.inputData })),
        {
          responseType: "arraybuffer",
          headers: {
            'Authorization': $.session.get("Token"),
            'X-TenantID': $.session.get("tenantid"),
            'Content-Type': 'application/json',
            "Content-Encoding": "gzip",
            Accept: 'text/csv'
          }
        }
      )
      .then(response => {
        if (response.status === 200 && inputParam.url === "/exportFile") {
          let fileName = inputParam.screenName.toLowerCase().concat("." + inputParam.inputData.exportFormat);
          saveAs(new Blob([response.data],
            { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }),
            fileName);
        }

      })
      .catch(error => {
        throw (error);
      })


    // getCFRSettingsService(inputParam, auditInput);
  }

  excelExport = (values) => {
    // if (values === "PDF") {
    //   if (this.pdfExport.current !== null) {
    // console.log(this.export);
    //     this.pdfExport.current.save();
    //   }
    // } else {
    if (this.export.current !== null) {
      // console.log(this.export);
      this.export.current.save();
    }
    // }

  };

  ongetgroupsuccess(response) {
    if (response !== null && response !== undefined) {
      let dataset = this.state.dataState;
      dataset.group = JSON.parse(response.gridcolumns);
      this.setState(
        this.createDataState(
          this.state.skip,
          this.state.pageSize,
          dataset,
          this.state.collapsedIds
        )
      );

      this.setState({ gridgroupcode: response.usergroupedcolcode });
    }
  }

  ongetgroupfailure(response) { }

  Initiatedata(nextProps) {
    let newdata = nextProps.source;

    if (newdata !== undefined && newdata !== null && newdata.length > 0) {
      if (
        nextProps.SelectedRecordsArray !== undefined &&
        nextProps.SelectedRecordsArray !== null &&
        nextProps.SelectedRecordsArray.length > 0
      ) {
        newdata.map((obj) => {
          if (
            nextProps.SelectedRecordsArray.findIndex(
              (objsel) =>
                objsel[nextProps.primaryKey] === obj[nextProps.primaryKey]
            ) > -1
          ) {
            obj.select = true;
          } else {
            obj.select = false;
          }
          return obj;
        });
      }
    }

    this.setState({ orginaldata: newdata !== undefined ? newdata : [] });

    if (
      this.state.orginaldata !== undefined &&
      this.state.orginaldata.length <= 0
    ) {
      let skip = this.state.dataState.skip;
      if (skip === undefined) {
        skip = 0;
      }
      if (
        this.state.dataState.skip >= itemperpage &&
        newdata.length < itemperpage
      ) {
        skip = 0;
      }
      let dataset = this.state.dataState;
      dataset.source = newdata !== undefined ? newdata : [];
      this.setState(
        this.createDataState(
          skip,
          itemperpage,
          dataset,
          this.state.collapsedIds
        )
      );
    } else {
      let dataset = this.state.dataState;
      dataset.source = newdata !== undefined ? newdata : [];
      this.setState(
        this.createDataState(
          this.state.skip,
          itemperpage,
          dataset,
          this.state.collapsedIds
        )
      );
    }
  }

  createDataState = (skip, take, dataState, collapsedIds) => {
    this.renderfield = [];
    let data = dataState.source;
    let itemperpage = localStorage.getItem("Elnitemperpage") !== undefined &&
      localStorage.getItem("Elnitemperpage") !== null
      ? parseInt(localStorage.getItem("Elnitemperpage"))
      : 10;
    // dataState.take = this.props.pageSizeable ? this.props.pageperrecors : itemperpage;
    dataState.take = itemperpage;
    dataState.skip = skip;
    let griddata = process(data, dataState);
    const validDateFields = ["createdateview", "createdateprotocol", "createondate", "createdonview", "createddateview", "transactiondate", "sDate", "dcreateddate", "addedonview"];
    if (dataState.sort && dataState.sort[0] && validDateFields.includes(dataState.sort[0].field)) {
      const sortedGridData = sortDataByDate(this.state.orginaldata, dataState.sort[0].dir);
      dataState.sort[0].field = "createdate";
      dataState.source = sortedGridData;
      griddata = process(sortedGridData, dataState);
    }
    return {
      //data: process(data.slice(skip, take + skip), dataState),
      data: griddata,
      dataState: dataState,
      total: this.props.total !== undefined ? this.props.total : griddata.total,
      skip: skip,
      group: dataState.group,
      pageSize: itemperpage,
      collapsedIds: collapsedIds,
      //pageable: this.props.pageSizeable ? { pageSize: 1, buttonCount: 5, info: true, pageSizeable: false } :
      pageable: {
        buttonCount: 5,
        info: true,
        type: initialPagerType,
        pageSizes: [5, 10, 20, 30, 40, 60, 80, 100],
        previousNext: true,
      },
    };
  };

  dataStateChange = (event) => {

    let dataset = event.dataState;

    if (event.nativeEvent !== undefined && event.nativeEvent.type === "reset") {
      dataset.sort = undefined
    }
    //let filtes =
    dataset.source = this.state.orginaldata;
    this.setState(
      this.createDataState(
        this.state.skip,
        this.state.pageSize,
        dataset,
        this.state.collapsedIds
      )
    );
  };

  onGroupChange = (event) => {

    let dataset = this.state.dataState;
    dataset.group = event.group;
    this.setState(
      this.createDataState(
        this.state.skip,
        this.state.pageSize,
        dataset,
        this.state.collapsedIds
      )
    );

    if (this.props.name !== undefined) {
      let passObjDet = {};
      if (this.state.gridgroupcode !== undefined) {
        passObjDet = {
          usergroupedcolcode: this.state.gridgroupcode,
          gridname: this.props.name,
          gridcolumns: JSON.stringify(event.group),
          usercode: $.session.get("User"),
          sitecode: $.session.get("Usersite"),
        };
      } else {
        passObjDet = {
          gridname: this.props.name,
          gridcolumns: JSON.stringify(event.group),
          usercode: $.session.get("User"),
          sitecode: $.session.get("Usersite"),
        };
      }

      Servicecalls(
        "" + this.ELNurl + "/User/setGroupedcolumn",
        passObjDet,
        this.onSetgroupsuccess.bind(this),
        this.onSetgroupfailure.bind(this)
      );
    }
  };

  onSetgroupsuccess(response) {
    if (response !== null && response !== undefined) {
      this.setState({ gridgroupcode: response.usergroupedcolcode });
    }
  }

  onSetgroupfailure(response) { }

  onExpandChange = (event) => {

    const item = event.dataItem;
    const isExpanded =
      event.dataItem.expanded === undefined
        ? event.dataItem.aggregates
        : event.dataItem.expanded;
    event.dataItem.expanded = !isExpanded;
    this.setState(this.state.data)
    if (item.groupId) {
      const collapsedIds = !event.value
        ? [...this.state.collapsedState, item.groupId]
        : this.state.collapsedState.filter(
          (groupId) => groupId !== item.groupId
        );

      this.setState(
        this.createDataState(
          this.state.skip,
          this.state.pageSize,
          this.state.dataState,
          collapsedIds
        )
      );
    }
  };

  selectionChange = (event) => {
    const data = this.state.orginaldata.map((item) => {
      if (
        item[this.props.primaryKey] === event.dataItem[this.props.primaryKey]
      ) {
        item.select = !event.dataItem.select;
        if (item.select === 1) {
          item.select = true;
        }
      }

      return item;
    });

    this.formatdata(data);
  };

  rowClick = (event) => {
    let last = this.lastSelectedIndex;
    if (this.state.orginaldata === undefined) return;

    const data = [...this.state.orginaldata];
    const current = data.findIndex((dataItem) => dataItem === event.dataItem);

    if (!this.Ismultiselect) {
      this.lastSelectedIndex = last = current;
      data.forEach((item) => (item.select = false));
      let select = !event.dataItem.select;
      for (let i = Math.min(last, current); i <= Math.max(last, current); i++) {
        data[i].select = select;
      }
    } else {
      if (!event.nativeEvent.shiftKey) {
        this.lastSelectedIndex = last = current;
      }

      if (!event.nativeEvent.ctrlKey) {
        //  data.forEach(item => (item.select = false));
      }

      let select = !event.dataItem.select;
      for (let i = Math.min(last, current); i <= Math.max(last, current); i++) {
        data[i].select = select;
      }
    }

    this.formatdata(data);
  };

  formatdata(data) {
    this.setState({ orginaldata: data });
    let dataset = this.state.dataState;
    dataset.source = data;
    this.setState(this.createDataState(this.state.skip, this.state.pageSize, dataset, this.state.collapsedIds));
    this.fetchselectrecords(data);
  }

  fetchselectrecords(dataval) {
    console.log("selected record")
    let data = dataval.filter((item) => item.select === true || item.select === 1);

    if (data !== undefined && data.length > 0) {
      let primarydata = data.map((a) => a[this.props.primaryKey]);
      if (this.props.SelectedRecords !== undefined) {
        if (this.Ismultiselect === true) {
          this.props.SelectedRecords(data, primarydata);
        } else {
          this.props.SelectedRecords(data[0], primarydata[0]);
        }
      }
    } else {
      if (this.props.SelectedRecords !== undefined) {
        if (this.Ismultiselect === true) {
          this.props.SelectedRecords([], []);
        } else {
          this.props.SelectedRecords({}, "");
        }
      }
    }
  }

  headerSelectionChange = (event) => {
    const checked = event.syntheticEvent.target.checked;
    const data = this.state.orginaldata.map((item) => {
      item.select = checked;
      return item;
    });

    this.formatdata(data);
  };

  onCheckboxheaderchecked = (field, value) => {

    const data = this.state.orginaldata.map((item) => {
      if (item[field] !== null && item[field] !== "NA        ") {
        item[field] = value;
      }
      return item;
    });
    this.setState({ orginaldata: data });
    let dataset = this.state.dataState;
    dataset.source = data;
    this.setState(
      this.createDataState(
        this.state.skip,
        this.state.pageSize,
        dataset,
        this.state.collapsedIds
      )
    );

    if (this.props.Oncheckboxheaderchecked !== undefined) {
      this.props.Oncheckboxheaderchecked(this.state.orginaldata);
    }
  };

  onReset = () => {
    // this.setState({
    //     data: process(this.state.orginaldata, this.state.dataState)
    // });
    let dataset = this.state.dataState;
    dataset.source = this.state.orginaldata;
    this.setState(
      this.createDataState(
        this.state.skip,
        this.state.pageSize,
        dataset,
        this.state.collapsedIds
      )
    );
  };

  onColumnsSubmit = (data) => {
    let filterData = data.filter((item) => {
      if (item.filterChecked) {
        return item;
      } else {
        return null;
      }
    });
    this.setState({
      data: process(filterData, this.state.dataState),
    });
  };

  onCheckboxcheckedinvent = (dataobject, field, value) => {
    if (this.props.Oncheckboxchecked !== undefined) {
      this.props.Oncheckboxchecked(
        field,
        value,
        this.props.primaryKey,
        dataobject
      );

      let last = this.lastSelectedIndex;
      if (this.state.orginaldata === undefined) return;

      const data = [...this.state.orginaldata];
      const current = data.findIndex((dataItem) => dataItem === dataobject);

      if (!this.Ismultiselect) {
        this.lastSelectedIndex = last = current;
        data.forEach((item) => (item.select = false));

      } else {

        let select = !dataobject.select;
        for (let i = Math.min(last, current); i <= Math.max(last, current); i++) {
          data[i].select = select;
        }
      }

      this.formatdata(data);
    }
  };

  onCheckboxchecked = (dataobject, field, value) => {
    if (this.props.Oncheckboxchecked !== undefined) {
      this.props.Oncheckboxchecked(
        field,
        value,
        this.props.primaryKey,
        dataobject
      );

      let last = this.lastSelectedIndex;
      if (this.state.orginaldata === undefined) return;

      const data = [...this.state.orginaldata];
      const current = data.findIndex((dataItem) => dataItem === dataobject);

      if (!this.Ismultiselect) {
        this.lastSelectedIndex = last = current;
        data.forEach((item) => (item.select = false));
        let select = !dataobject.select;


        for (let i = Math.min(last, current); i <= Math.max(last, current); i++) {
          data[i].select = select;

          if ((data[i].screate !== undefined && data[i].screate.trim() === "1") || (data[i].sedit !== undefined && data[i].sedit.trim() === "1") || (data[i].sdelete !== undefined && data[i].sdelete.trim() === "1") || (data[i].sallow !== undefined && data[i].sallow === "1")) {
            let values = document.getElementById(data[i].orderno + "_" + [i]).value
            if (values !== null) {
              data[i].sallow = 1;
            }
            if (this.props.usergroup === 1) {
              if (field === "screate") {
                data[i].screate = 1;
              } else if (field === "sedit") {
                data[i].sedit = 1;
              }
              else if (field === "sdelete") {
                data[i].sdelete = 1;
              }
              else if (field === "sallow") {
                data[i].sallow = 1;
              }
            }
          } else if ((data[i].screate !== undefined && data[i].screate.trim() === "0") || (data[i].sedit !== undefined && data[i].sedit.trim() === "0") || (data[i].sdelete !== undefined && data[i].sdelete.trim() === "0") || (data[i].sallow !== undefined && data[i].sallow === "0")) {

            if (this.props.usergroup === 1) {
              if (field === "screate") {
                data[i].screate = 1;
              } else if (field === "sedit") {
                data[i].sedit = 1;
              }
              else if (field === "sdelete") {
                data[i].sdelete = 1;
              }
              else if (field === "sallow") {
                data[i].sallow = 1;
              }
            }


          }

        }

      } else {

        let select = !dataobject.select;
        for (let i = Math.min(last, current); i <= Math.max(last, current); i++) {
          data[i].select = select;
        }
      }

      this.formatdata(data);
    }
  };

  onbuttonchecked = (value, field) => {
    if (this.props.OpensheetFn !== undefined) {
      this.props.OpensheetFn(value);
    }
  };

  onDetailClick = (value) => {
    if (this.props.openDetailFn !== undefined) {
      this.props.openDetailFn(value);
    }
  };

  onFileViewClick = (value) => {
    if (this.props.openFileFn !== undefined) {
      this.props.openFileFn(value);
    }
  };

  usersitesCellRenderer = (props, i) => {
    const { dataItem } = props;
    let truncatedsitename = dataItem.sitename.slice(0, dataItem.sitename.length).join(', ');
    truncatedsitename = truncatedsitename.split(' ').filter(Boolean).join('');
    const allsitename = dataItem.sitename.join(', ');

    return (
      <td id={props.id + i + this.gridUniqueId}>
        <span title={truncatedsitename}>
          {truncatedsitename.trim()}
        </span>
      </td>
    )
  };
  ontransactionclicked = (value, field) => {
    if (this.props.OpenTransaction !== undefined) {
      this.props.OpenTransaction(value);
    }
  };

  MyCustomCell = (props) => (
    <CustomCell
      {...props}
      keyfield={this.props.primaryKey}
      onchange={this.onCheckboxchecked}
      data={this.state.orginaldata}

    />
  );


  MyCustomCellinventory = (props) => (
    <CustomCell
      {...props}
      keyfield={this.props.primaryKey}
      onchange={this.onCheckboxcheckedinvent}
      data={this.state.orginaldata}

    />
  );


  MyCustomCellActiononprotocolorder = (props) => (
    <PendingprotocolorderViewCell
      {...props}
      keyfield={this.props.primaryKey}
      onclick={this.onbuttonchecked}
      // celltype={this.props.Columns[props.columnIndex].celltype}
      Getorderonsharesucess={this.props.Getorderonsharesucess}
      alert={this.props.alert}
      activeKey={this.props.activeKey}
      protocolorderscreen={this.props.protocolorderscreen}
      protocolscreen={this.props.protocolscreen}
    />
  );

  MyCustomCellActiononprotocol = (props) => (
    <Protocolactioncell
      {...props}
      keyfield={this.props.primaryKey}
      onclick={this.onbuttonchecked}
      // celltype={this.props.Columns[props.columnIndex].celltype}
      passbackorder={this.props.Getorderonunshare}
      alert={this.props.alert}
      activeKey={this.props.activeKey}
      protocolorderscreen={this.props.protocolorderscreen}
      protocolscreen={this.props.protocolscreen}
    />
  );

  MyCustomCellActiononparser = (props) => (
    <Parseractioncell
      {...props}
    />
  );

  MyCustomCellActiononsheet = (props) => (
    <Sheetactioncell
      {...props}
      keyfield={this.props.primaryKey}
      onclick={this.onbuttonchecked}
      // celltype={this.props.Columns[props.columnIndex].celltype}
      text={this.props.Columns[props.columnIndex] !== undefined ? this.props.Columns[props.columnIndex].header : ""}
      details={this.props.Columns[props.columnIndex] !== undefined ? this.props.Columns[props.columnIndex].details : ""}
      passbackorder={this.props.Getsharedorderresponse}
      alert={this.props.alert}
      // lang={this.props.t}
      activeKey={this.props.activeKey}
    />
  );

  MyCustomCellActionOnInventory = (props) => (
    <InventoryCell
      {...props}
      keyfield={this.props.primaryKey}
      onclick={this.onbuttonchecked}
      onDetailClick={this.onDetailClick}
      onFileViewClick={this.onFileViewClick}
      text={this.props.Columns[props.columnIndex] !== undefined ? this.props.Columns[props.columnIndex].header : ""}
      details={this.props.Columns[props.columnIndex] !== undefined ? this.props.Columns[props.columnIndex].details : ""}
      alert={this.props.alert}
      activeKey={this.props.activeKey}
    />
  );

  MyCustomCellActionOnEquipment = (props) => (
    <EquipmentCell
      {...props}
      keyfield={this.props.primaryKey}
      onclick={this.onbuttonchecked}
      onDetailClick={this.onDetailClick}
      onFileViewClick={this.onFileViewClick}
      text={this.props.Columns[props.columnIndex] !== undefined ? this.props.Columns[props.columnIndex].header : ""}
      details={this.props.Columns[props.columnIndex] !== undefined ? this.props.Columns[props.columnIndex].details : ""}
      alert={this.props.alert}
      activeKey={this.props.activeKey}
    />
  );

  MyCustomButtonMaterialCell = (props) => (
    <MaterialCell
      {...props}
      keyfield={this.props.primaryKey}
      onclick={this.onbuttonchecked}
      onFileViewClick={this.onFileViewClick}
    />
  );

  MyCustomButtonMaterialActionCell = (props) => (
    <MaterialNameAction
      {...props}
      keyfield={this.props.primaryKey}
      onclick={this.onbuttonchecked}
      onFileViewClick={this.onFileViewClick}
    />
  );

  MyCustomCellActiononprotocolsharby = (props) => (
    <Protocolactionsharbycell
      {...props}
      keyfield={this.props.primaryKey}
      onclick={this.onbuttonchecked}
      // celltype={this.props.Columns[props.columnIndex].celltype}
      passbackorder={this.props.Getorderonunsharesuccess}
      alert={this.props.alert}
      activeKey={this.props.activeKey}
      protocolorderscreen={this.props.protocolorderscreen}
      protocolscreen={this.props.protocolscreen}
    />
  );

  MyCustomCellActiononsheetsharedby = (props) => (
    <Sheetactionsharebycell
      {...props}
      keyfield={this.props.primaryKey}
      onclick={this.onbuttonchecked}
      // celltype={this.props.Columns[props.columnIndex].celltype}
      Getorderonunshare={this.props.Getorderonunshare}
      alert={this.props.alert}
      activeKey={this.props.activeKey}
    />
  );

  MyCustomCellprotocolorderunshareaction = (props) => (
    <ProtocolorderunshareViewCell1
      {...props}
      keyfield={this.props.primaryKey}
      onclick={this.onbuttonchecked}
      // celltype={this.props.Columns[props.columnIndex].celltype}
      Getorderonunshare={this.props.Getorderonunshare}
      alert={this.props.alert}
      activeKey={this.props.activeKey}
      protocolorderscreen={this.props.protocolorderscreen}
      protocolscreen={this.props.protocolscreen}
    // passbackorder={this.props.Columns[i].passbackorder}
    />
  );

  MyCustomCellAction = (props) => (
    <PendingViewCell
      {...props}
      keyfield={this.props.primaryKey}
      onclick={this.onbuttonchecked}
      passbackorder={this.props.passbackorder}
    />
  );

  CompletedcellAction = (props) => (
    <CompletedCelltemp
      {...props}
      keyfield={this.props.primaryKey}
      onclick={this.onbuttonchecked}
    />
  );

  OrderunsharedbymeCell = (props) => (
    <OrderharedbymeAlart
      {...props}
      keyfield={this.props.primaryKey}
      onclick={this.onbuttonchecked}
      passbackorder={this.props.passbackorder}
    />
  );
  MyCustomButtonCell = (props) => (
    <MyCustomButtonCell
      {...props}
      keyfield={this.props.primaryKey}
      onclick={this.onbuttonchecked}
    />
  );

  MyOrderTransactionCell = (props) => (
    <CustomOrderTransaction
      {...props}
      keyfield={this.props.primaryKey}
      onclick={this.ontransactionclicked}
    />
  );

  formatcolumnwidth(index) {
    let width = "250px";

    if (
      this.props.Columns[index].width !== undefined &&
      this.props.Columns[index].width !== ""
    ) {
      width =
        this.state.width < 1000 ? this.props.Columns[index].width : undefined;
    }
    if (this.props.Columns[index]?.maxWidth) {
      width = this.props.Columns[index]?.maxWidth
    }

    return width;
  }

  formatcolumn() {
    let columns = [];

    if (this.props.Columns !== undefined && this.props.Columns.length > 0) {
      for (let i = 0; i < this.props.Columns.length; i++) {
        let columnwidth = this.formatcolumnwidth(i);

        if (this.props.Columns[i].render !== undefined && this.props.Columns[i].render === true) {
          this.rendercolumn.push(this.props.Columns[i].datatype);
        }

        if (this.props.Columns[i].rowselector) {
          columns.push(
            <Column
              key={this.props.Columns[i].datatype}
              locked={this.props.Columns[i].locked !== undefined && this.props.Columns[i].locked ? true : false}
              field="select"
              width="50px"
              className="kendo-select-col"
              style={{ "background-color": "red" }}
              resizable={(this.props.Columns[i].resizable === undefined) || (this.props.Columns[i].resizable !== undefined && this.props.Columns[i].resizable) ? true : false}
              headerSelectionValue={
                this.state.orginaldata !== undefined && this.state.orginaldata.length > 0
                  ? this.state.orginaldata.findIndex((dataItem) => dataItem.select === false) === -1 : false
              }
            />
          );

          this.Ismultiselect = true;
        } else if (this.props.Columns[i].celltype === "Checkbox") {
          columns.push(
            <Column
              key={this.props.Columns[i].datatype}
              field={this.props.Columns[i].datatype}
              width={columnwidth}
              title={this.props.Columns[i].header}
              editable={true}
              resizable={(this.props.Columns[i].resizable === undefined) || (this.props.Columns[i].resizable !== undefined && this.props.Columns[i].resizable) ? true : false}
              cell={this.MyCustomCell}
              headerCell={(props) => (
                <CustomHeader
                  {...props}
                  title={this.props.Columns[i].header}
                  source={this.state.orginaldata}
                  field={this.props.Columns[i].datatype}
                  onchange={this.onCheckboxheaderchecked}
                />
              )}
              columnMenu={(props) => (
                <ColumnMenuCheckboxFilter
                  {...props}
                  field={this.props.Columns[i].datatype}
                  data={this.state.orginaldata}
                  onReset={this.onReset}
                  onColumnsSubmit={this.onColumnsSubmit}
                  primaryKey={this.props.primaryKey}
                  title={this.props.Columns[i].header}
                />
              )}
            />
          );
        }
        else if (this.props.Columns[i].celltype === "Checkboxinventory") {
          columns.push(
            <Column
              key={this.props.Columns[i].datatype}
              field={this.props.Columns[i].datatype}
              width={columnwidth}
              title={this.props.Columns[i].header}
              editable={true}
              resizable={(this.props.Columns[i].resizable === undefined) || (this.props.Columns[i].resizable !== undefined && this.props.Columns[i].resizable) ? true : false}
              cell={this.MyCustomCellinventory}
              // headerCell={(props) => (
              //   <CustomHeader
              //     {...props}
              //     title={this.props.Columns[i].header}
              //     source={this.state.orginaldata}
              //     field={this.props.Columns[i].datatype}
              //     onchange={this.onCheckboxheaderchecked}
              //   />
              // )}
              columnMenu={(props) => (
                <ColumnMenuCheckboxFilter
                  {...props}
                  field={this.props.Columns[i].datatype}
                  data={this.state.orginaldata}
                  onReset={this.onReset}
                  onColumnsSubmit={this.onColumnsSubmit}
                  primaryKey={this.props.primaryKey}
                  title={this.props.Columns[i].header}
                />
              )}
            />
          );
        }
        else if (this.props.Columns[i].celltype === "Button") {
          columns.push(
            <Column
              key={this.props.Columns[i].datatype}
              field={this.props.Columns[i].datatype}
              width={columnwidth}
              title={this.props.Columns[i].header}
              editable={true}
              resizable={(this.props.Columns[i].resizable === undefined) || (this.props.Columns[i].resizable !== undefined && this.props.Columns[i].resizable) ? true : false}
              cell={this.MyCustomButtonCell}
              headerCell={(props) => <CustomButtonHeader {...props} />}
            />
          );
        } else if (this.props.Columns[i].celltype === "Detailview") {
          columns.push(
            <Column
              key={this.props.Columns[i].datatype}
              field={this.props.Columns[i].datatype}
              width={"70px"}
              title={this.props.Columns[i].header}
              editable={true}
              resizable={(this.props.Columns[i].resizable === undefined) || (this.props.Columns[i].resizable !== undefined && this.props.Columns[i].resizable) ? true : false}
              cell={(props) => (
                <CustomDetailviewCell
                  {...props}
                  keyfield={this.props.primaryKey}
                  text={this.props.Columns[i].header}
                  details={this.props.Columns[i].details}
                />
              )}
              headerCell={(props) => <CustomButtonHeader {...props} />}
            />
          );
        }
        // else if (this.props.Columns[i].celltype === "shareorder") {
        //   columns.push(
        //     <Column
        //       key={this.props.Columns[i].datatype}
        //       field={this.props.Columns[i].datatype}
        //       width={"70px"}
        //       title={this.props.Columns[i].header}
        //       editable={true}
        //       cell={(props) => (
        //         <Shareorderforprotocol
        //           {...props}
        //           keyfield={this.props.primaryKey}
        //           isshared={false}
        //           props={this.props}
        //           text={this.props.Columns[i].header}
        //           details={this.props.Columns[i].details}
        //         />
        //       )}
        //       headerCell={(props) => <CustomButtonHeader {...props} />}
        //     />
        //   );
        // }
        else if (this.props.Columns[i].celltype === "sharesheetorder") {
          columns.push(
            <Column
              key={this.props.Columns[i].datatype}
              field={this.props.Columns[i].datatype}
              width={"70px"}
              title={this.props.Columns[i].header}
              editable={true}
              resizable={(this.props.Columns[i].resizable === undefined) || (this.props.Columns[i].resizable !== undefined && this.props.Columns[i].resizable) ? true : false}
              cell={(props) => (
                <Shareorder
                  {...props}
                  keyfield={this.props.primaryKey}
                  isshared={false}
                  props={this.props}
                  text={this.props.Columns[i].header}
                  details={this.props.Columns[i].details}
                />
              )}
              headerCell={(props) => <CustomButtonHeader {...props} />}
            />
          );
        } else if (this.props.Columns[i].celltype === "copy") {
          columns.push(
            <Column
              key={this.props.Columns[i].datatype}
              field={this.props.Columns[i].datatype}
              width={"150px"}
              title={this.props.Columns[i].header}
              editable={true}
              resizable={(this.props.Columns[i].resizable === undefined) || (this.props.Columns[i].resizable !== undefined && this.props.Columns[i].resizable) ? true : false}
              cell={this.MyCustomCellAction}
              headerCell={(props) => <CustomButtonHeader {...props} />}
            />
          );
        } else if (this.props.Columns[i].celltype === "protocolorderaction") {
          columns.push(
            <Column
              key={this.props.Columns[i].datatype}
              field={this.props.Columns[i].datatype}
              width={columnwidth}
              title={this.props.Columns[i].header}
              editable={true}
              resizable={(this.props.Columns[i].resizable === undefined) || (this.props.Columns[i].resizable !== undefined && this.props.Columns[i].resizable) ? true : false}
              cell={this.MyCustomCellActiononprotocolorder}
              headerCell={(props) => <CustomButtonHeader {...props} />}
            />
          );
        } else if (this.props.Columns[i].celltype === "protocolaction") {
          columns.push(
            <Column
              key={this.props.Columns[i].datatype}
              field={this.props.Columns[i].datatype}
              width={columnwidth}
              title={this.props.Columns[i].header}
              editable={true}
              resizable={(this.props.Columns[i].resizable === undefined) || (this.props.Columns[i].resizable !== undefined && this.props.Columns[i].resizable) ? true : false}
              cell={this.MyCustomCellActiononprotocol}
              headerCell={(props) => <CustomButtonHeader {...props} />}
            />
          );
        }


        else if (this.props.Columns[i].celltype === "parseraction") {
          columns.push(
            <Column
              key={this.props.Columns[i].datatype}
              field={this.props.Columns[i].datatype}
              width={columnwidth}
              title={this.props.Columns[i].header}
              editable={true}
              resizable={(this.props.Columns[i].resizable === undefined) || (this.props.Columns[i].resizable !== undefined && this.props.Columns[i].resizable) ? true : false}
              cell={this.MyCustomCellActiononparser}
              headerCell={(props) => <CustomButtonHeader {...props} />}
            />
          );
        }
        else if (this.props.Columns[i].celltype === "orderGridView") {
          columns.push(
            <Column
              key={this.props.Columns[i].datatype}
              field={this.props.Columns[i].datatype}
              width={columnwidth}
              title={this.props.Columns[i].header}
              editable={false}
              sortable={(this.props.Columns[i].sortable === undefined) || (this.props.Columns[i].sortable !== undefined && this.props.Columns[i].sortable) ? true : false}
              resizable={(this.props.Columns[i].resizable === undefined) || (this.props.Columns[i].resizable !== undefined && this.props.Columns[i].resizable) ? true : false}
              name
              cell={(data) =>
                (data.dataItem !== undefined && data.dataItem.ordercancell !== undefined && data.dataItem.ordercancell === 1) ?
                  <td id={data.id + i + this.gridUniqueId}>
                    {this.enableTooltip ? this.getLengthValue(data.id, data, i) : <span> {this.getCoulumnText(data.dataItem, data.field)}</span>}
                  </td>
                  :
                  <td id={data.id + i + this.gridUniqueId}>                 
                      <NavLink className="column-simple-view"
                        to={{ pathname: data?.dataItem?.pathName, state: data.dataItem.template !== undefined ? { batchid: data.dataItem.id, batchcode: data.dataItem.code } : data.dataItem }}>
                        {this.enableTooltip ? this.getLengthValue(data.id, data, i) : <span> {this.getCoulumnText(data.dataItem, data.field)}</span>}
                      </NavLink>
                    
                  </td>
              }
            />
          );
        }
        else if (this.props.Columns[i].celltype === "customStatusColumn") {
          columns.push(
            <Column
              key={this.props.Columns[i].datatype}
              field={this.props.Columns[i].datatype}
              width={columnwidth}
              title={this.props.Columns[i].header}
              editable={false}
              resizable={(this.props.Columns[i].resizable === undefined) || (this.props.Columns[i].resizable !== undefined && this.props.Columns[i].resizable) ? true : false}
              name
              cell={(data) =>
                <td id={data.id + i + this.gridUniqueId}>
                  <span className={`grid-custom-status ${data.dataItem[this.props.Columns[i].datatype]}`}>
                    {this.enableTooltip ?
                      this.getLengthValue(data.id, data, i)
                      :
                      <span>
                        {this.getCoulumnText(data.dataItem, data.field)}
                      </span>
                    }
                  </span>
                </td>
              }
            />
          );
        } else if (this.props.Columns[i].celltype === "customStatusInventory") {
          columns.push(
            <Column
              key={this.props.Columns[i].datatype}
              field={this.props.Columns[i].datatype}
              width={columnwidth}
              title={this.props.Columns[i].header}
              editable={false}
              resizable={(this.props.Columns[i].resizable === undefined) || (this.props.Columns[i].resizable !== undefined && this.props.Columns[i].resizable) ? true : false}
              name
              cell={(data) =>
                <td id={data.id + i + this.gridUniqueId}>
                  <span className={`grid-custom-status-inventory ${data.dataItem[this.props.Columns[i].datatype]}`}>
                    {this.enableTooltip ?
                      this.getLengthValue(data.id, data, i)
                      :
                      <span>
                        {this.getCoulumnText(data.dataItem, data.field)}
                      </span>
                    }
                  </span>
                </td>
              }
            />
          );
        }
        else if (this.props.Columns[i].celltype === "dateColumn") {
          columns.push(
            <Column
              key={this.props.Columns[i].datatype}
              field={this.props.Columns[i].datatype}
              width={columnwidth}
              title={this.props.Columns[i].header}
              editable={false}
              sortable={(this.props.Columns[i].sortable === undefined) || (this.props.Columns[i].sortable !== undefined && this.props.Columns[i].sortable) ? true : false}
              resizable={(this.props.Columns[i].resizable === undefined) || (this.props.Columns[i].resizable !== undefined && this.props.Columns[i].resizable) ? true : false}
              name
              cell={(data) =>
                <>
                  <td>
                    <div className="calender-list horizontal d-flex" data-toggle="tooltip" title={data.dataItem.month + " " + data.dataItem.day + " " + data.dataItem.year + " " + data.dataItem.time + " " + data.dataItem.Time_Zone}>
                      <span className="eln-stamp" data-toggle="tooltip" title={data.dataItem.month + " " + data.dataItem.day + " " + data.dataItem.year + " " + data.dataItem.time + " " + data.dataItem.Time_Zone}>{data.dataItem.month}</span>
                      <span className="eln-date" data-toggle="tooltip" title={data.dataItem.month + " " + data.dataItem.day + "/" + data.dataItem.year + " " + data.dataItem.time + " " + data.dataItem.Time_Zone}>{data.dataItem.day}</span>
                      <span className="eln-stamp" data-toggle="tooltip" title={data.dataItem.month + " " + data.dataItem.day + " " + data.dataItem.year + " " + data.dataItem.time + " " + data.dataItem.Time_Zone}>{data.dataItem.year}</span>
                      <span className="eln-stamp" data-toggle="tooltip" title={data.dataItem.month + " " + data.dataItem.day + " " + data.dataItem.year + " " + data.dataItem.time + " " + data.dataItem.Time_Zone}>{data.dataItem.time}</span>
                      <span className="eln-stamp" data-toggle="tooltip" title={data.dataItem.month + " " + data.dataItem.day + " " + data.dataItem.year + " " + data.dataItem.time + " " + data.dataItem.Time_Zone}>{data.dataItem.Time_Zone}</span>
                    </div>
                  </td>
                </>
              }
            />
          );
        }
        else if (this.props.Columns[i].celltype === "sheetaction") {
          columns.push(
            <Column
              key={this.props.Columns[i].datatype}
              field={this.props.Columns[i].datatype}
              width={columnwidth}
              title={this.props.Columns[i].header}
              editable={true}
              resizable={(this.props.Columns[i].resizable === undefined) || (this.props.Columns[i].resizable !== undefined && this.props.Columns[i].resizable) ? true : false}
              cell={this.MyCustomCellActiononsheet}
              headerCell={(props) => <CustomButtonHeader {...props} />}
            />
          );
        } else if (this.props.Columns[i].celltype === "inventoryaction") {
          columns.push(
            <Column
              key={this.props.Columns[i].datatype}
              field={this.props.Columns[i].datatype}
              width={"140px"}
              title={this.props.Columns[i].header}
              editable={true}
              resizable={(this.props.Columns[i].resizable === undefined) || (this.props.Columns[i].resizable !== undefined && this.props.Columns[i].resizable) ? true : false}
              cell={this.MyCustomCellActionOnInventory}
              headerCell={(props) => <CustomButtonHeader {...props} />}
            />
          );
        } else if (this.props.Columns[i].celltype === "equipmentaction") {
          columns.push(
            <Column
              key={this.props.Columns[i].datatype}
              field={this.props.Columns[i].datatype}
              width={"140px"}
              title={this.props.Columns[i].header}
              editable={true}
              resizable={(this.props.Columns[i].resizable === undefined) || (this.props.Columns[i].resizable !== undefined && this.props.Columns[i].resizable) ? true : false}
              cell={this.MyCustomCellActionOnEquipment}
              headerCell={(props) => <CustomButtonHeader {...props} />}
            />
          );
        } else if (this.props.Columns[i].celltype === "MaterialButton") {
          columns.push(
            <Column
              key={this.props.Columns[i].datatype}
              field={this.props.Columns[i].datatype}
              width={"140px"}
              title={this.props.Columns[i].header}
              editable={true}
              resizable={(this.props.Columns[i].resizable === undefined) || (this.props.Columns[i].resizable !== undefined && this.props.Columns[i].resizable) ? true : false}
              cell={this.MyCustomButtonMaterialCell}
              headerCell={(props) => <CustomButtonHeader {...props} />}
            />
          );
        } else if (this.props.Columns[i].celltype === "materialaction") {
          columns.push(
            <Column
              key={this.props.Columns[i].datatype}
              field={this.props.Columns[i].datatype}
              width={"200px"}
              title={this.props.Columns[i].header}
              editable={true}
              resizable={(this.props.Columns[i].resizable === undefined) || (this.props.Columns[i].resizable !== undefined && this.props.Columns[i].resizable) ? true : false}
              cell={this.MyCustomButtonMaterialActionCell}
              headerCell={(props) => <CustomButtonHeaderforusergroup {...props} />}
            />
          );
        } else if (this.props.Columns[i].celltype === "protocolsharedbyaction") {
          columns.push(
            <Column
              key={this.props.Columns[i].datatype}
              field={this.props.Columns[i].datatype}
              width={columnwidth}
              title={this.props.Columns[i].header}
              editable={true}
              resizable={(this.props.Columns[i].resizable === undefined) || (this.props.Columns[i].resizable !== undefined && this.props.Columns[i].resizable) ? true : false}
              cell={this.MyCustomCellActiononprotocolsharby}
              headerCell={(props) => <CustomButtonHeader {...props} />}
            />
          );
        } else if (this.props.Columns[i].celltype === "sheetsharedbyaction") {
          columns.push(
            <Column
              key={this.props.Columns[i].datatype}
              field={this.props.Columns[i].datatype}
              width={columnwidth}
              title={this.props.Columns[i].header}
              editable={true}
              resizable={(this.props.Columns[i].resizable === undefined) || (this.props.Columns[i].resizable !== undefined && this.props.Columns[i].resizable) ? true : false}
              cell={this.MyCustomCellActiononsheetsharedby}
              headerCell={(props) => <CustomButtonHeader {...props} />}
            />
          );
        } else if (
          this.props.Columns[i].celltype === "completebuttontemplate"
        ) {
          columns.push(
            <Column
              key={this.props.Columns[i].datatype}
              field={this.props.Columns[i].datatype}
              width={"150px"}
              title={this.props.Columns[i].header}
              editable={true}
              resizable={(this.props.Columns[i].resizable === undefined) || (this.props.Columns[i].resizable !== undefined && this.props.Columns[i].resizable) ? true : false}
              cell={this.CompletedcellAction}
              headerCell={(props) => <CustomButtonHeader {...props} />}
            />
          );
        } else if (this.props.Columns[i].celltype === "orderunsharedbyme") {

          columns.push(
            <Column
              key={this.props.Columns[i].datatype}
              field={this.props.Columns[i].datatype}
              title={this.props.Columns[i].header}
              editable={true}
              resizable={(this.props.Columns[i].resizable === undefined) || (this.props.Columns[i].resizable !== undefined && this.props.Columns[i].resizable) ? true : false}
              // onclick={this.sharedbyclick}
              cell={(props) => <OrderharedbymeAlart
                {...props}
                keyfield={this.props.primaryKey}
                onclick={this.onbuttonchecked}
                passbackorder={this.props.Columns[i].passbackorder}
              />}
              // passbackorder={this.props.passbackorder}
              headerCell={(props) => <CustomButtonHeader {...props} />}
            />
          );
        }
        else if (
          this.props.Columns[i].celltype === "protocolorderunshareaction"
        ) {
          columns.push(
            <Column
              key={this.props.Columns[i].datatype}
              field={this.props.Columns[i].datatype}
              width={columnwidth}
              title={this.props.Columns[i].header}
              editable={true}
              resizable={(this.props.Columns[i].resizable === undefined) || (this.props.Columns[i].resizable !== undefined && this.props.Columns[i].resizable) ? true : false}
              // cell={this.MyCustomCellprotocolorderunshareaction}
              cell={(props) => <ProtocolorderunshareViewCell1
                {...props}
                keyfield={this.props.primaryKey}
                alert={this.props.alert}
                activeKey={this.props.activeKey}
                protocolorderscreen={this.props.protocolorderscreen}
                protocolscreen={this.props.protocolscreen}
                onclick={this.onbuttonchecked}
                passbackorder={this.props.Columns[i].passbackorder}
              />}
              headerCell={(props) => <CustomButtonHeader {...props} />}
            />
          );
        }
        else if (this.props.Columns[i].celltype === "unshareprotocoltemplate") {
          columns.push(
            <Column
              key={this.props.Columns[i].datatype}
              field={this.props.Columns[i].datatype}
              width={"70px"}
              title={this.props.Columns[i].header}
              editable={true}
              resizable={(this.props.Columns[i].resizable === undefined) || (this.props.Columns[i].resizable !== undefined && this.props.Columns[i].resizable) ? true : false}
              cell={(props) => (
                <UnshareforprotocolTemplate
                  {...props}
                  keyfield={this.props.primaryKey}
                  isshared={false}
                  props={this.props}
                  text={this.props.Columns[i].header}
                  details={this.props.Columns[i].details}
                  passbackorder={this.props.Getorderonunsharesuccess}
                />
              )}
              headerCell={(props) => <CustomButtonHeader {...props} />}
            />
          );
        }
        else if (this.props.Columns[i].celltype === "sharesheettemplate") {
          columns.push(
            <Column
              key={this.props.Columns[i].datatype}
              field={this.props.Columns[i].datatype}
              width={"70px"}
              title={this.props.Columns[i].header}
              editable={true}
              resizable={(this.props.Columns[i].resizable === undefined) || (this.props.Columns[i].resizable !== undefined && this.props.Columns[i].resizable) ? true : false}
              cell={(props) => (
                <ShareforsheetTemplate
                  {...props}
                  keyfield={this.props.primaryKey}
                  isshared={false}
                  props={this.props}
                  text={this.props.Columns[i].header}
                  details={this.props.Columns[i].details}
                  passbackorder={this.props.Getsharedorderresponse}
                />
              )}
              headerCell={(props) => <CustomButtonHeader {...props} />}
            />
          );
        } else if (
          this.props.Columns[i].celltype === "unshareprotocoltemplate"
        ) {
          columns.push(
            <Column
              key={this.props.Columns[i].datatype}
              field={this.props.Columns[i].datatype}
              width={"70px"}
              title={this.props.Columns[i].header}
              editable={true}
              resizable={(this.props.Columns[i].resizable === undefined) || (this.props.Columns[i].resizable !== undefined && this.props.Columns[i].resizable) ? true : false}
              cell={(props) => (
                <UnshareforprotocolTemplate
                  {...props}
                  keyfield={this.props.primaryKey}
                  isshared={false}
                  props={this.props}
                  text={this.props.Columns[i].header}
                  details={this.props.Columns[i].details}
                  passbackorder={this.props.Getorderonunsharesuccess}
                />
              )}
              headerCell={(props) => <CustomButtonHeader {...props} />}
            />
          );
        }
        else if (this.props.Columns[i].celltype === "sharesheettemplate") {
          columns.push(
            <Column
              key={this.props.Columns[i].datatype}
              field={this.props.Columns[i].datatype}
              width={"70px"}
              title={this.props.Columns[i].header}
              editable={true}
              resizable={(this.props.Columns[i].resizable === undefined) || (this.props.Columns[i].resizable !== undefined && this.props.Columns[i].resizable) ? true : false}
              cell={(props) => (
                <ShareforsheetTemplate
                  {...props}
                  keyfield={this.props.primaryKey}
                  isshared={false}
                  props={this.props}
                  text={this.props.Columns[i].header}
                  details={this.props.Columns[i].details}
                  passbackorder={this.props.Getsharedorderresponse}
                />
              )}
              headerCell={(props) => <CustomButtonHeader {...props} />}
            />
          );
        }
        else if (this.props.Columns[i].celltype === "projectTeamsView") {
          columns.push(
            <Column
              key={this.props.Columns[i].datatype !== undefined ? this.props.Columns[i].datatype : "projectteamcol_" + i}
              field={this.props.Columns[i].datatype}
              width={columnwidth}
              title={this.props.Columns[i].header}
              editable={true}
              resizable={(this.props.Columns[i].resizable === undefined) || (this.props.Columns[i].resizable !== undefined && this.props.Columns[i].resizable) ? true : false}
              cell={(props) => (
                <CustomUsergroupviewCells
                  {...props}
                  keyfield={this.props.primaryKey}
                  text={this.props.Columns[i].header}
                  details={this.props.Columns[i].details}
                />
              )}
              headerCell={(props) => (
                <CustomButtonHeaderforusergroup {...props} />
              )}
            />
          );
        } else if (this.props.Columns[i].celltype === "usergroupviews") {
          columns.push(
            <Column
              key={this.props.Columns[i].datatype}
              field={this.props.Columns[i].datatype}
              width={columnwidth}
              title={this.props.Columns[i].header}
              editable={true}
              resizable={(this.props.Columns[i].resizable === undefined) || (this.props.Columns[i].resizable !== undefined && this.props.Columns[i].resizable) ? true : false}
              cell={(props) => (
                <CustomUsergroupviewCells
                  {...props}
                  keyfield={this.props.primaryKey}
                  text={this.props.Columns[i].header}
                  details={this.props.Columns[i].details}
                />
              )}
              headerCell={(props) => (
                <CustomButtonHeaderforusergroup {...props} />
              )}
            />
          );
        }
        else if (this.props.Columns[i].celltype === "usermastermultisite") {
          columns.push(
            <Column
              key={this.props.Columns[i].datatype}
              locked={this.props.Columns[i].locked !== undefined && this.props.Columns[i].locked ? true : false}
              field={this.props.Columns[i].datatype}
              width={this.props.Columns[i].locked === undefined ? columnwidth : this.props.Columns[i].width}
              className="breakWord60 text-wrap"
              title={this.props.Columns[i].header}
              cell={(props) => (
                this.usersitesCellRenderer(props, i)
              )}
              headerCell={(props) => {
                return (
                  <div className="custom-th-wrap">
                    <span className="th-tooltip" id={props.field + i + this.gridUniqueId}>
                      {this.enableTooltip ?

                        this.getLengthValue(props.field, props, i, 'header')

                        :
                        <span>
                          {props.title ? props.title : props.field}
                        </span>
                      }
                    </span>
                    {this.props.Columns[i].doFilter !== undefined && this.props.Columns[i].doFilter ? true :
                      <GridColumnMenuWrapper
                        {...props.columnMenuWrapperProps}
                        {...this.columnProps(
                          this.props.Columns[i].datatype,
                          this.state.orginaldata,
                          this.props.primaryKey,
                          this.props.Columns[i].header
                        )}
                      />
                    }
                  </div>
                )
              }}
              resizable={(this.props.Columns[i].resizable === undefined) || (this.props.Columns[i].resizable !== undefined && this.props.Columns[i].resizable) ? true : false}
              {...this.columnProps(
                this.props.Columns[i].datatype,
                this.state.orginaldata,
                this.props.primaryKey,
                this.props.Columns[i].header
              )}
            />);
          // columns.push(
          //   <Column
          //     key={this.props.Columns[i].datatype}
          //     field={this.props.Columns[i].datatype}
          //     width={columnwidth}
          //     title={this.props.Columns[i].header}
          //     editable={true}
          //     resizable={(this.props.Columns[i].resizable === undefined) || (this.props.Columns[i].resizable !== undefined && this.props.Columns[i].resizable) ? true : false}
          //     cell={(props) => (
          //       this.usersitesCellRenderer(props, i)
          //     )}
          //     headerCell={(props) => (
          //       <CustomButtonHeaderforusergroup {...props} />
          //     )}
          //   />
          // );
        }
        else if (this.props.Columns[i].celltype === "ordertransactions") {
          columns.push(
            <Column
              key={this.props.Columns[i].datatype}
              field={this.props.Columns[i].datatype}
              width={"70px"}
              title={this.props.Columns[i].header}
              editable={true}
              resizable={(this.props.Columns[i].resizable === undefined) || (this.props.Columns[i].resizable !== undefined && this.props.Columns[i].resizable) ? true : false}
              cell={(props) => (
                <Customcompletecell
                  {...props}
                  keyfield={this.props.primaryKey}
                  text={this.props.Columns[i].header}
                  details={this.props.Columns[i].details}
                />
              )}
              headerCell={(props) => <CustomButtonHeader {...props} />}
            />
          );
        } else if (this.props.Columns[i].celltype === "inventryhistory") {
          columns.push(
            <Column
              key={this.props.Columns[i].datatype}
              field={this.props.Columns[i].datatype}
              width={columnwidth}
              title={this.props.Columns[i].header}
              editable={true}
              resizable={(this.props.Columns[i].resizable === undefined) || (this.props.Columns[i].resizable !== undefined && this.props.Columns[i].resizable) ? true : false}
              cell={(props) => (
                <Custominventrycells
                  {...props}
                  keyfield={this.props.primaryKey}
                  text={this.props.Columns[i].header}
                  details={this.props.Columns[i].details}
                />
              )}
              headerCell={(props) => <CustomButtonHeader {...props} />}
            />
          );
        }
        else if (this.props.Columns[i].celltype === "formatversiondetails") {
          columns.push(
            <Column
              key={this.props.Columns[i].datatype}
              field={this.props.Columns[i].datatype}
              width={columnwidth}
              title={this.props.Columns[i].header}
              editable={true}
              resizable={(this.props.Columns[i].resizable === undefined) || (this.props.Columns[i].resizable !== undefined && this.props.Columns[i].resizable) ? true : false}
              cell={(props) => (
                <Formatprotocolversioncell
                  {...props}
                  keyfield={this.props.primaryKey}
                  text={this.props.Columns[i].header}
                  details={this.props.Columns[i].details}
                  index={i}
                />
              )}
              headerCell={(props) => <CustomButtonHeader {...props} />}
            />
          );
        } else if (this.props.Columns[i].celltype === "formatresultdetails") {
          columns.push(
            <Column
              key={this.props.Columns[i].datatype}
              field={this.props.Columns[i].datatype}
              width={columnwidth}
              title={this.props.Columns[i].header}
              editable={true}
              resizable={(this.props.Columns[i].resizable === undefined) || (this.props.Columns[i].resizable !== undefined && this.props.Columns[i].resizable) ? true : false}
              cell={(props) => (
                <FormatResultUsedDetailscell
                  {...props}
                  keyfield={this.props.primaryKey}
                  text={this.props.Columns[i].header}
                  details={this.props.Columns[i].details}
                />
              )}
              headerCell={(props) => <CustomButtonHeader {...props} />}
            />
          );
        } else if (this.props.Columns[i].celltype === "formatresultequipmentdetails") {
          columns.push(
            <Column
              key={this.props.Columns[i].datatype}
              field={this.props.Columns[i].datatype}
              width={columnwidth}
              title={this.props.Columns[i].header}
              editable={true}
              resizable={(this.props.Columns[i].resizable === undefined) || (this.props.Columns[i].resizable !== undefined && this.props.Columns[i].resizable) ? true : false}
              cell={(props) => (
                <FormatResultUsedEquipment
                  {...props}
                  keyfield={this.props.primaryKey}
                  text={this.props.Columns[i].header}
                  details={this.props.Columns[i].details}
                />
              )}
              headerCell={(props) => <CustomButtonHeader {...props} />}
            />
          );
        } else if (this.props.Columns[i].celltype === "formattransactiondetails") {
          columns.push(
            <Column
              key={this.props.Columns[i].datatype}
              field={this.props.Columns[i].datatype}
              width={columnwidth}
              title={this.props.Columns[i].header}
              editable={true}
              resizable={(this.props.Columns[i].resizable === undefined) || (this.props.Columns[i].resizable !== undefined && this.props.Columns[i].resizable) ? true : false}
              cell={(props) => (
                <Formatprotocoltransactioncell
                  {...props}
                  keyfield={this.props.primaryKey}
                  text={this.props.Columns[i].header}
                  details={this.props.Columns[i].details}
                  index={i}
                />
              )}
              headerCell={(props) => <CustomButtonHeader {...props} />}
            />
          );
        }
        else if (this.props.Columns[i].celltype === "formatprotocolordertransactiondetails") {
          columns.push(
            <Column
              key={this.props.Columns[i].datatype}
              field={this.props.Columns[i].datatype}
              width={columnwidth}
              title={this.props.Columns[i].header}
              editable={true}
              resizable={(this.props.Columns[i].resizable === undefined) || (this.props.Columns[i].resizable !== undefined && this.props.Columns[i].resizable) ? true : false}
              cell={(props) => (
                <Formatprotocolordertransactioncell
                  {...props}
                  keyfield={this.props.primaryKey}
                  text={this.props.Columns[i].header}
                  details={this.props.Columns[i].details}
                  index={i}
                />
              )}
              headerCell={(props) => <CustomButtonHeader {...props} />}
            />
          );
        }
        else if (this.props.Columns[i].celltype === "formatprotocolorderversiondetails") {
          columns.push(
            <Column
              key={this.props.Columns[i].datatype}
              field={this.props.Columns[i].datatype}
              width={columnwidth}
              title={this.props.Columns[i].header}
              editable={true}
              resizable={(this.props.Columns[i].resizable === undefined) || (this.props.Columns[i].resizable !== undefined && this.props.Columns[i].resizable) ? true : false}
              cell={(props) => (
                <Formatprotocolversiondetailscell
                  {...props}
                  keyfield={this.props.primaryKey}
                  text={this.props.Columns[i].header}
                  details={this.props.Columns[i].details}
                  index={i}
                />
              )}
              headerCell={(props) => <CustomButtonHeader {...props} />}
            />
          );
        }
        else if (this.props.Columns[i].visible) {
          if (this.props.Columns[i].format !== undefined) {
            columns.push(
              <Column
                key={this.props.Columns[i].datatype}
                field={this.props.Columns[i].datatype}
                locked={this.props.Columns[i].locked !== undefined && this.props.Columns[i].locked ? true : false}
                width={this.props.Columns[i].locked === undefined ? columnwidth : this.props.Columns[i].width}
                className="breakWord120"
                title={this.props.Columns[i].header}
                filter="date"
                format={this.props.Columns[i].format}
                resizable={(this.props.Columns[i].resizable === undefined) || (this.props.Columns[i].resizable !== undefined && this.props.Columns[i].resizable) ? true : false}
                columnMenu={(props) => (
                  <ColumnMenuCheckboxFilter
                    {...props}
                    field={this.props.Columns[i].datatype}
                    data={this.state.orginaldata}
                    onReset={this.onReset}
                    onColumnsSubmit={this.onColumnsSubmit}
                    primaryKey={this.props.primaryKey}
                    title={this.props.Columns[i].header}
                  />
                )}
              />
            );
          } else if (this.props.Columns[i].celltype === "noWrapColumn") {
            columns.push(
              <Column
                key={this.props.Columns[i].datatype}
                locked={this.props.Columns[i].locked !== undefined && this.props.Columns[i].locked ? true : false}
                field={this.props.Columns[i].datatype}
                width={this.props.Columns[i].locked === undefined ? columnwidth : this.props.Columns[i].width}
                className="breakWord60 text-wrap"
                title={this.props.Columns[i].header}
                headerCell={(props) => {
                  return (
                    <div className="custom-th-wrap">
                      <span className="th-tooltip " id={props.field + i + this.gridUniqueId}>
                        {this.enableTooltip ?

                          this.getLengthValue(props.field, props, i, 'header')

                          :
                          <span>
                            {props.title ? props.title : props.field}
                          </span>
                        }
                      </span>
                      {this.props.Columns[i].doFilter !== undefined && this.props.Columns[i].doFilter ? true :
                        <GridColumnMenuWrapper
                          {...props.columnMenuWrapperProps}
                          {...this.columnProps(
                            this.props.Columns[i].datatype,
                            this.state.orginaldata,
                            this.props.primaryKey,
                            this.props.Columns[i].header
                          )}
                        />
                      }
                    </div>
                  )
                }}
                resizable={(this.props.Columns[i].resizable === undefined) || (this.props.Columns[i].resizable !== undefined && this.props.Columns[i].resizable) ? true : false}
                cell={(props) => (
                  <td id={props.id + i + this.gridUniqueId}>

                    {this.enableTooltip ?
                      this.getLengthValue(props.id, props, i)
                      :
                      <span>
                        {this.getCoulumnText(props.dataItem, props.field)}
                      </span>
                    }
                  </td>
                )}
              />);

          } else {
            columns.push(
              <Column
                key={this.props.Columns[i].datatype}
                locked={this.props.Columns[i].locked !== undefined && this.props.Columns[i].locked ? true : false}
                field={this.props.Columns[i].datatype}
                width={this.props.Columns[i].locked === undefined ? columnwidth : this.props.Columns[i].width}
                className="breakWord60 text-wrap"
                title={this.props.Columns[i].header}
                headerCell={(props) => {
                  return (
                    <div className="custom-th-wrap">
                      <span className="th-tooltip" id={props.field + i + this.gridUniqueId}>
                        {this.enableTooltip ?

                          this.getLengthValue(props.field, props, i, 'header')

                          :
                          <span>
                            {props.title ? props.title : props.field}
                          </span>
                        }
                      </span>
                      {this.props.Columns[i].doFilter !== undefined && this.props.Columns[i].doFilter ? true :
                        <GridColumnMenuWrapper
                          {...props.columnMenuWrapperProps}
                          {...this.columnProps(
                            this.props.Columns[i].datatype,
                            this.state.orginaldata,
                            this.props.primaryKey,
                            this.props.Columns[i].header
                          )}
                        />
                      }
                    </div>
                  )
                }}
                resizable={(this.props.Columns[i].resizable === undefined) || (this.props.Columns[i].resizable !== undefined && this.props.Columns[i].resizable) ? true : false}
                {...this.columnProps(
                  this.props.Columns[i].datatype,
                  this.state.orginaldata,
                  this.props.primaryKey,
                  this.props.Columns[i].header
                )}
              />);

          }
        }
      }
    }

    return columns;
  }
  getCoulumnText(data, key) {
    let value = "";
    if (key.includes(".")) {
      let keys = key.split(".")
      keys.forEach((obj) => {
        value = value ? value[obj] : data[obj]
      })
    } else {
      value = data[key]
    }
    if (typeof value === 'boolean' || value instanceof Date) {
      value = String(value)
    }
    return value

  }
  getLengthValue = (elementId, props, i, type) => {
    const id = elementId + i + this.gridUniqueId
    const data = document.getElementById(id)
    let value = "";
    let content = ""
    if (type !== 'header') {
      if (props?.field.includes(".")) {
        let keys = props.field.split(".")
        keys.forEach((obj) => {
          value = value ? value[obj] : props.dataItem[obj]
        })
      } else {
        value = props.dataItem[props.field]
      }
      if (typeof value === 'boolean') {
        value = String(value)
      }
    } else {
      value = props.title ? props.title : props.field
    }
    if (data && data.scrollWidth && data.clientHeight && data.clientWidth < data.scrollWidth) {
      content = (
        <span title={value}>
          {value}
        </span>
      )
    } else {
      content = (
        <span>
          {value}
        </span>
      )
    }
    return content
  }

  columnProps = (field, orginaldata, primaryKey, header) => {
    if (!this.props.hideColumnFilter) {
      return {
        columnMenu: (props) => (
          <ColumnMenu
            {...props}
            field={field}
            data={this.state.orginaldata}
            onReset={this.onReset}
            onColumnsSubmit={this.onColumnsSubmit}
            primaryKey={primaryKey}
          />
        ),
        headerClassName: this.isColumnActive(field, this.state.dataState) ? "active" : "",
      };
    }
  };

  isColumnActive = (field, dataState) => {
    return (
      GridColumnMenuFilter.active(field, dataState.filter) ||
      GridColumnMenuSort.active(field, dataState.sort)
    );
  };

  cellRender = (cell, props) => {
    if (this.rendercolumn.includes(props.field)) {
      let st = this.state.data;
      let values = st.data.reduce(function (r, a) {
        r[a[props.field]] = r[a[props.field]] || [];
        r[a[props.field]].push(a);
        return r;
      }, Object.create(null));

      let object = values[props.dataItem[props.field]];
      if (!this.renderfield.includes(props.dataItem[props.field])) {
        this.renderfield.push(props.dataItem[props.field]);
        let span = object !== undefined ? object.length : 1;
        return (
          <td rowSpan={span}>
            {props.dataItem[props.field] !== undefined &&
              props.dataItem[props.field] !== null
              ? props.dataItem[props.field].toString()
              : props.dataItem[props.field]}
          </td>
        );
      } else {
        return null;
      }
    }
    return cell;
  };

  pageChange = (event) => {
    let dataset = this.state.dataState;
    // if (this.props.pagechangeeevent === undefined) {
    localStorage.setItem("Elnitemperpage", event.page.take);
    // }
    dataset.source = this.state.orginaldata;

    this.setState(
      this.createDataState(
        event.page.skip,
        event.page.take,
        dataset,
        this.state.collapsedIds
      ), () => {
        console.log(this.state.dataState);
        if (this.props.pagechangeeevent !== undefined && this.props.Gridname !== undefined) {
          // parseInt(event.nativeEvent.target.textContent) - 1
          this.props.pagechangeeevent((event.page.skip / this.props.pageperrecors), event.page.take, this.props.Gridname)
        }
      }
    );

  };

  onimporteddats = (onsavefinaldata, emptycolumnname) => {
    if (emptycolumnname.length > 0) {
      this.isDuplicateobj.emptycolumnname = emptycolumnname[0];
      this.isDuplicateobj.totalcolumnempty = emptycolumnname.length;
    } else if (emptycolumnname.length === 0) {
      this.isDuplicateobj.emptycolumnname = "";
      this.isDuplicateobj.totalcolumnempty = emptycolumnname.length;
    }
    this.onsavefinaldata = onsavefinaldata;
  }

  onImport = (e) => {
    if (this.props.onImportDataGet !== undefined) {
      this.props.onImportDataGet();
    }
    e.preventDefault();
    this.setState({ IsOpen: true });
    this.props.onsavegriddata([], true);
  }

  closeModel = (e) => {
    e.preventDefault();
    this.setState({ IsOpen: false });
    this.props.onsavegriddata([], true);
  }

  onsavegriddata = () => {
    this.onsavefinaldata = this.onsavefinaldata.filter((x) => {
      return Object.keys(x).length > 0;
    })
    if (this.onsavefinaldata.length > 0 && this.isDuplicateobj.emptycolumnname === "") {
      let mandaterycolumns = this.props.Columns
      mandaterycolumns = mandaterycolumns.filter(items => items.ismandatory !== undefined && items.ismandatory);

      let isAcceptable = undefined, dataName = undefined;

      if (mandaterycolumns.length > 0) {
        mandaterycolumns.some((items) => {
          let valueArr = this.onsavefinaldata.map(function (item) {
            return item[items.datatype]
          });

          if (items.acceptabletype !== undefined) {
            if (items.acceptabletype === 'Number') {
              valueArr.map((x) => { if (x !== undefined && !Number.isInteger(Number(x))) { isAcceptable = false } });
              if (isAcceptable === false) {
                dataName = items.header;
              }
            } else if (items.acceptabletype === 'Predefined_0') {
              valueArr.map((x) => { if (x !== undefined && x.toLowerCase() !== 'yes' && x.toLowerCase() !== 'no') { isAcceptable = false } });
              if (!isAcceptable === false) {
                dataName = items.header;
              }
            } else if (items.acceptabletype === 'Predefined_1') {
              valueArr.map((x) => { if (x !== undefined && x.toLowerCase() !== 'no expiry' && x.toLowerCase() !== 'expiry date' && x.toLowerCase() !== 'expiry policy') { isAcceptable = false } });
              if (!isAcceptable === false) {
                dataName = items.header;
              }
            }
          }
          // if (isAcceptable === undefined) {
          if (valueArr.length === this.onsavefinaldata.length) {
            this.isDuplicateobj.isDuplicate = valueArr.some((item, idx) => {
              if (items.ignoremandatory !== undefined && (item === undefined || item === null || item === "")) {
                this.isDuplicateobj.index = -1;
                return true;
              } else {
                this.isDuplicateobj.index = idx;
              }

              return valueArr.indexOf(item) != idx;
            });
            // console.log(this.isDuplicateobj);
            if (this.isDuplicateobj.isDuplicate && this.isDuplicateobj.index !== -1 && items.ignoredublicate === undefined) {
              this.props.alert.error(this.props.t("Dashboard.Sheetorders.IDS_MSG_DUBLICATERECORD", {
                Username: items.header
              }));
              return true;
            } else {
              this.isDuplicateobj.isDuplicate = false
            }
            return false;
          }
          this.props.alert.error(this.props.t("Dashboard.Sheetorders.IDS_MSG_DUBLICATERECORD", { Username: items.header }));
          return true;
          // }
        })
      }
      if (isAcceptable === undefined) {
        if (!this.isDuplicateobj.isDuplicate) {
          this.props.onsavegriddata(this.onsavefinaldata, null);
        } else {
          this.setState({ ischangegriddata: true })
        }
      } else {
        this.props.alert.error(`${dataName} the acceptable type of data has been changed`)
      }
    } else {

      if (this.onsavefinaldata.length === 0) {
        this.props.alert.error(this.props.t("Inventory.Unit.IDS_LBL_SELECTRECORD"));
      } else {
        this.props.alert.error(this.props.t("Dashboard.Sheetorders.IDS_MSG_EMPTYCOLUMN", { column: this.isDuplicateobj.emptycolumnname }));
      }
    }
  }

  render() {
    let lang = localStorage.getItem("ElnLang");
    let localizeprovider = "en-EN";
    if (lang !== null) {
      localizeprovider = lang + "-" + lang.toUpperCase();
    }
    return (
      <div className="at-list-table">

        {this.state.IsOpen &&
          <Modal isOpen={this.state.IsOpen} className={"modal-lg"}>
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">
                {this.props.t("Common.IDS_LBL_IMPORTDATA")}
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={this.closeModel.bind(this)}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <ModalBody>
              <ImportData ischangegriddata={this.state.ischangegriddata} isDuplicateobj={this.isDuplicateobj} Columns={this.props.Columns} t={this.props.t} onimporteddats={this.onimporteddats.bind(this)} alert={this.props.alert}></ImportData>
            </ModalBody>
            <div id="UserMasterADD-modal-footer" className="modal-footer">
              <button
                type="button"
                id="idSaveSheetAs"
                className="btn btn-user btn-primary-blue"
                onClick={this.onsavegriddata.bind(this)}
              >
                {this.props.t("Setup.Userrights.IDS_LBL_SAVE")}
              </button>
              <button
                type="button"
                className="btn modalbluebtn"
                onClick={this.closeModel.bind(this)}
              >
                {this.props.t("Header.IDS_LBL_CLOSE", { param: "", })}
              </button>
            </div>
          </Modal>}
        <LocalizationProvider language={localizeprovider}>
          {/* <IntlProvider locale={lang} > */}
          {/* {this.state.data &&
            this.state.data &&
            this.state.data.data.length > 0 ? ( */}
          <Tooltip openDelay={100} position="top">
            <ExcelExport data={this.state.data.data !== undefined ? this.state.data.data : this.state.data} ref={this.export}>

              <Grid
                data={this.state.data}
                {...this.state.dataState}
                style={{
                  height:
                    this.props.height !== undefined ? this.props.height : "56vh",
                }}
                selectedField="select"
                onSelectionChange={this.selectionChange}
                onHeaderSelectionChange={this.headerSelectionChange}
                onRowClick={this.rowClick}
                onDataStateChange={this.dataStateChange}
                onGroupChange={this.onGroupChange}
                onExpandChange={this.onExpandChange}
                expandField="expanded"
                sortable={true}
                onPageChange={this.pageChange}
                groupable={this.props.name !== undefined ? true : false}
                total={this.state.total}
                skip={this.state.skip}
                pageable={this.state.pageable}
                pageSize={this.state.pageSize}
                cellRender={this.cellRender}
                resizable
              // fixedScroll={true}
              >
                {this.formatcolumn()}
                {this.props.screenName !== undefined ?
                  <GridToolbar>

                    <button
                      title=""
                      className="btn btn btn-primary-blue mg-l-1"
                      onClick={this.onImport}
                      disabled={this.props.Usermaster === true ? false : this.props.Usermaster === undefined ? false : true}
                    >
                      {this.props.t("Common.IDS_LBL_IMPORT")}
                    </button>


                    <span title={this.props.t("Common.IDS_LBL_EXPORT")}>
                      <DropdownButton
                        drop="down"
                        alignRight
                        // variant="primary"
                        // title="Download"
                        // name="Download"
                        className='download-btn'
                        variant="outline-light"
                      // ref={this.methodRef}
                      // hidden={!this.props.Login.visibility}
                      >
                        <Dropdown.Item
                          key={"samplesplitKey"}
                          value={"SampleSplit"}
                          onSelect={() =>
                            this.excelExport()
                            // this.switchButton("sampleSplit", "SampleSplit")
                          }
                        >
                          {this.props.t("Parser.Instrument.IDS_LBL_EXPORTEXCEL")}
                        </Dropdown.Item>
                        <Dropdown.Item
                          key={"parsersetupkey"}
                          value={"ParserSetup"}
                          onSelect={() =>
                            this.exportPDF()
                            // this.switchButton("sampleSplit", "SampleSplit")
                          }
                        >
                          {this.props.t("Parser.Instrument.IDS_LBL_EXPORTPDF")}
                        </Dropdown.Item>
                        <Dropdown.Item
                          key={"evaluateparserkey"}
                          value={"EvaluateParser"}
                          onSelect={() =>
                            this.onxportcsv()
                          }
                        >
                          {this.props.t("Parser.Instrument.IDS_LBL_EXPORTCSV")}
                        </Dropdown.Item>

                      </DropdownButton>
                    </span>
                  </GridToolbar> : false
                }

                {/* <Column
                              field="selected"
                              width="50px"
                              headerSelectionValue={
                                  this.state.data.findIndex(dataItem => dataItem.selected === false) === -1
                              } />
                          <Column field="ProductName" title="Product Name" width="300px" />
                          <Column field="UnitsInStock" title="Units In Stock" />
                          <Column field="UnitsOnOrder" title="Units On Order" />
                          <Column field="ReorderLevel" title="Reorder Level" /> */}
              </Grid>

            </ExcelExport>
          </Tooltip>
          <GridPDFExport allPages={true} ref={(pdfExport) => (gridPDFExport = pdfExport)}>
            <Grid
              data={this.state.data}
              {...this.state.dataState}
            // fixedScroll={true}
            >
              {this.formatcolumn()}
            </Grid>
          </GridPDFExport>
        </LocalizationProvider>
      </div>
    );
  }
}
export default withAlert()(withTranslation(KendoGridView));
