import {
  ADD_VIEWSHEET,
  ADD_MODIFIEDSHEET,
  ADD_PARSEDFIELDS,
  UPDATE_DESIGNERFORVIEWSHEET,
  UPDATE_STOPVIEWSHEET,
  REPLACE_STATE,
  UPDATE_KENDOSTATE,
  UPDATE_CONTROLRIGHTS,
  ADD_AUDITOPTION,
  ADD_KENDOSTATE,
  ADD_VIEWCREATION,
  UPDATE_KENDOSTATECREATE,
  UPDATE_MENU_PROPS,
  ADD_KENDOSTATECREATE,
  UPDATE_MENU_LIST,
  UPDATE_SEARCH_ORDERS,
  UPDATE_SEARCH_TEMPLATES,
  UPDATE_INACTIVE_LOGOUT,
  UPDATE_SHEET_ORDER_ORG_CONTANT,
  UPDATE_SHEET_OPEN,
  UPDATE_INST_PARSE_IDS,
  UPDATE_PROFILE_IMAGE,
  GET_PROFILE_IMAGE,
  INITILIZE_REDUX_STATE,
  UPDATE_SHEET_ORIGINAL_CONTANT,
  UPDATE_PROTOCOL_OPEN_DATAS,
  UPDATE_PROTOCOLORDERS_OPEN_DATAS,
  UPDATE_Celllevel_Audit_Datas
} from "../constants/action-types";



export function addViewSheet(payload) {
  return { type: ADD_VIEWSHEET, payload };
}

export function addSheetCreation(payload) {
  return { type: ADD_VIEWCREATION, payload };
}


export function addModifiedSheets(payload) {
  return { type: ADD_MODIFIEDSHEET, payload };
}

export function addParsedFields(payload) {
  return { type: ADD_PARSEDFIELDS, payload };
}

export function updateDesigner(payload) {
  return { type: UPDATE_DESIGNERFORVIEWSHEET, payload };
}

export function updateStopView(payload) {
  return { type: UPDATE_STOPVIEWSHEET, payload };
}

export function replacestate(payload) {
  return { type: REPLACE_STATE, payload };
}

export function updateKenodstate(payload) {
  return { type: UPDATE_KENDOSTATE, payload };
}

export function updatecontrolrights(payload) {
  return { type: UPDATE_CONTROLRIGHTS, payload };
}
export function updateMenuList(payload) {
  return { type: UPDATE_MENU_LIST, payload }
}
export function updateSearchOrders(payload) {
  return { type: UPDATE_SEARCH_ORDERS, payload }
}
export function updateSearchTemplates(payload) {
  return { type: UPDATE_SEARCH_TEMPLATES, payload }
}
export function addauditoption(payload) {
  return { type: ADD_AUDITOPTION, payload };
}

export function addkendoSheet(payload) {
  return { type: ADD_KENDOSTATE, payload };
}

export function addkendosheetcreate(payload) {
  return { type: ADD_KENDOSTATECREATE, payload };
}

export function updatekendosheetcreate(payload) {
  return { type: UPDATE_KENDOSTATECREATE, payload };
}

export function updateMenuProps(payload) {
  return { type: UPDATE_MENU_PROPS, payload };
}

export function updateInactiveLogout(payload) {
  return { type: UPDATE_INACTIVE_LOGOUT, payload };
}

export function updateorderoriginalcontent(payload) {
  return { type: UPDATE_SHEET_ORDER_ORG_CONTANT, payload };
}

export function updateinstparseids(payload) {
  return { type: UPDATE_INST_PARSE_IDS, payload };
}

export function updateorderopen(payload) {
  return { type: UPDATE_SHEET_OPEN, payload };
}

export function updateprofilepic(payload) {
  return { type: UPDATE_PROFILE_IMAGE, payload };
}
export function getprofilepic(payload) {
  return { type: GET_PROFILE_IMAGE, payload };
}

export function initilizereduxstate(payload) {
  return {
    type: INITILIZE_REDUX_STATE, payload
  };
}

export function updateorderoriginalcontentforsheet(payload) {
  return { type: UPDATE_SHEET_ORIGINAL_CONTANT, payload };
}

export function updatewithoutcloseprotocoldatas(payload) {
  return { type: UPDATE_PROTOCOL_OPEN_DATAS, payload };
}
export function updatewithoutcloseprotocolorderdatas(payload) {
  return { type: UPDATE_PROTOCOLORDERS_OPEN_DATAS, payload };
}

export function Celllevelauditforspreadsheet(payload) {
  return { type: UPDATE_Celllevel_Audit_Datas, payload };
}




