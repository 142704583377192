import { ActionTypes } from "../actions/TreeActionTypes";

const intialState = {

};

export const TreeReducer = (state = intialState, { type, payload }) => {
  switch (type) {
    case ActionTypes.DEFAULT_RETURN:
      return { ...state, ...payload };
    default:
      return state;
  }
};