import React, { Component, Suspense } from "react";
import { withTranslation } from "react-multi-lang";
import $ from "jquery";
import "bootstrap";
import Service from "./general/Service.jsx";
import { onAuditHandler,  Servicecalls, Servicecallsforfiles } from "./../custom_functions/Functions.jsx";
import { AuditModuleNameEnum } from "../custom_functions/AuditActionEnum.js";

const SelectBox = React.lazy(() => import("./../controls/SelectBox.jsx"));
const TextBox = React.lazy(() => import("./../controls/TextBox.jsx"));
const TextArea = React.lazy(() => import("./../controls/TextArea.jsx"));

class Audit extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.ELNurl = document.getElementById("ELNname").value;
    this.state = {
      ResonCode: "",
      ResonValue: "",
      ResonCodeValidcls: "",
      ResonData: [],
      UserName: $.session.get("Username"),
      UserNameValidcls: "",
      Password: $.session.get("loggedfrom") !== undefined && $.session.get("loggedfrom") === "1" ? $.session.get("azuretoken") : "",
      PasswordValidcls: "",
      Comment: "",
      CommentValidcls: "",
      AuditUrl: "",
      AuditObject: [],
      AuditPassfunction: null,
      AuditFailfunction: null,
      loaderEventStopFunction : null,
      getauditcomment: false,
      desableaudit: false,
      auditstatusupdate: undefined,
      AuditParameters: {},
      AuditId: "auditTrailModal",
      AuditCancelfunction: null,
      Audittenant: $.session.get("tenantid"),
    };

  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      AuditUrl: nextProps.AuditUrl,
      AuditObject: nextProps.AuditObject,
      AuditPassfunction: nextProps.AuditPassfunction,
      getauditcomment: nextProps.getauditcomment !== undefined ? nextProps.getauditcomment ? true : false : false,
      AuditFailfunction: nextProps.AuditFailfunction,
      AuditCancelfunction: nextProps.AuditCancelfunction,
      loaderEventStopFunction : nextProps.loaderEventStopFunction,
      auditstatusupdate: nextProps.updatestatus !== undefined ? nextProps.updatestatus : undefined,
      AuditParameters: nextProps.AuditParameters !== undefined ? nextProps.AuditParameters : {},
      AuditId: nextProps.AuditId !== undefined ? nextProps.AuditId : "auditTrailModal",
      Audittenant: nextProps.Audittenant !== undefined ? nextProps.Audittenant : $.session.get("tenantid"),
    });
  }

  onGetCFRSuccess(res) {
    this.setState({
      ResonData: res,
      ResonCode: res[0].reasoncode !== undefined ? res[0].reasoncode.toString() : "",
      ResonValue: res[0].comments,
    });
  }

  onGetCFRFailure(res) { }

  componentDidMount() {
    this._isMounted = true;
    Servicecalls("" + this.ELNurl + "/AuditTrail/GetReasons", {}, this.onGetCFRSuccess.bind(this), this.onGetCFRFailure.bind(this));
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  onReasonChange(value, text) {
    this.setState({ ResonCode: value, ResonValue: text });
  }

  onUsernamechange(Value) {
    this.setState({ UserName: Value });
  }

  onUserPasswordchange(Value) {
    this.setState({ Password: Value, PasswordValidcls: '' });
  }

  onCommentchange(Value) {
    this.setState({ Comment: Value });
  }
  onContentKeyPress(e) {
    if (e.key === "Enter") {
      this.OnAuditSubmit(e);
    }
  }

  onAuditsuccessreturn(response) {
    $("#" + this.state.AuditId).modal("hide");

    this.setState({
      Password: $.session.get("loggedfrom") !== undefined &&  $.session.get("loggedfrom") === "1" ? $.session.get("azuretoken") : "",
      Comment: "",
      desableaudit: false,
    });

    let returnobj = response;
    if (this.state.AuditParameters !== undefined) {
      $.extend(true, returnobj, this.state.AuditParameters);
    }
    returnobj["objuser"] = response.objuser;

    this.state.AuditPassfunction(returnobj);
  }

  onAuditfailurereturn(response) {
    this.state.AuditFailfunction(response);
    if(this.state.loaderEventStopFunction !== undefined){
      this.state.loaderEventStopFunction(false);
    }
    $("#" + this.state.AuditId).modal("hide");
    this.setState({  desableaudit: false, });
  }

  OnAuditSubmit(e) {
    if(this.state.loaderEventStopFunction !== undefined){
      this.state.loaderEventStopFunction(true);
    }
    if (this.state.UserName.trim() !== "" && this.state.Password.trim() !== "" && this.state.Password.trim() !== undefined && this.state.ResonCode.trim() !== "" && this.state.Comment.trim() !== "" ) {
      let auditTrailValues = {};
      auditTrailValues["sUsername"] = this.state.UserName;
      auditTrailValues["sPassword"] = this.state.Password;
      auditTrailValues["sPassword"] = this.state.Password;
      auditTrailValues["sReasonNo"] = this.state.ResonCode;
      auditTrailValues["sReasonName"] = this.state.ResonValue;
      auditTrailValues["comments"] = this.state.Comment;
      auditTrailValues["sSiteCode"] = $.session.get("Usersite");
      auditTrailValues["encryptedpassword"] = $.session.get("userpasscodeencrypted");
      auditTrailValues["loggedfrom"] = $.session.get("loggedfrom") !== undefined && $.session.get("loggedfrom") === "1" ? 1 : 0;

      let Passobject = this.state.AuditObject;
      if (Passobject.constructor === Array) {
        Passobject = Passobject.filter((obj) => {
          obj.objuser = auditTrailValues;
          return obj;
        });
      } else if (typeof Passobject === "object") {
        Passobject["objuser"] = auditTrailValues;
      } else {
        Passobject["objuser"] = auditTrailValues;
      }
      this.setState({
        Password: $.session.get("loggedfrom") !== undefined && $.session.get("loggedfrom") === "1" ? $.session.get("azuretoken") : this.state.Password,
        Comment: this.state.Comment,
        desableaudit: true,
        serivcepassobject: Passobject
      });

      Servicecalls( "" + this.ELNurl + "/AuditTrail/checkManualAudit",  { manualAuditPass: { objuser: auditTrailValues }, valuePass: {} }, this.onAuditValidateSuccess.bind(this),  this.onAuditValidateFailure.bind(this));

    } else {
      if (this.state.UserName.trim() === "") {
        this.setState({ UserNameValidcls: "invalid" });
      } else {
        this.setState({ UserNameValidcls: "" });
      }
      if (this.state.Password.trim() === "") {
        this.setState({ PasswordValidcls: "invalid" });
      } else {
        this.setState({ PasswordValidcls: "" });
      }
      if (this.state.ResonCode.trim() === "") {
        this.setState({ ResonCodeValidcls: "invalid" });
      }
      else {
        this.setState({ ResonCodeValidcls: "" });
      }
      if (this.state.Comment.trim() === "") {
        this.setState({ CommentValidcls: "invalid" });
      } else {
        this.setState({ CommentValidcls: "" });
      }
    }
  }

  onAuditValidateSuccess(res) {
    let objuser = this.state.serivcepassobject;
    if (res.audit) {
      if (this.state.getauditcomment) {
        if (objuser.length !== 0) {
          objuser.map(
            (item) => (item.reviewvercomments = this.state.Comment)
          );
        }
      }
      if (this.props.Auditfilefunction !== undefined && this.props.Auditfilefunction) {
        if (objuser.fileduids !== undefined) {
          objuser = objuser.fileduids;
        }
        Servicecallsforfiles(
          this.state.AuditUrl,
          objuser.elnformData !== undefined ? objuser.elnformData : objuser,
          this.onAuditsuccessreturn.bind(this),
          this.onAuditfailurereturn.bind(this),
        );
      }
      else {
        this.setState({
          ServiceToUrl: this.state.AuditUrl,
          ServiceHeaderobject: objuser.elnformData !== undefined ? objuser.elnformData : objuser,
          ServiceMethodType: "POST",
          ServcieonSuccess: this.onAuditsuccessreturn.bind(this),
          ServcieonFailure: this.onAuditfailurereturn.bind(this),
          ServiceTenant: this.state.Audittenant !== undefined ? this.state.Audittenant : $.session.get("tenantid"),
          Servicecontenttype: objuser.elnformData !== undefined ? "multipart" : undefined
        });
      }
      let manualAuditPass = {};

      if (objuser.constructor === Array) {
        manualAuditPass = objuser[0];
        if (objuser[0].objsilentauditlst !== undefined) {
          if (
            objuser[0].objsilentauditlst.length !== undefined &&
            objuser[0].objsilentauditlst.length > 0
          ) {
            objuser.objsilentaudit = objuser[0].objsilentauditlst[0];
          } else {
            objuser.objsilentaudit = objuser[0].objsilentauditlst;
          }
        }
      } else if (typeof objuser === "object") {
        manualAuditPass = objuser;
      } else {
        manualAuditPass = objuser;
      }

      if (
        manualAuditPass.objmanualaudit !== undefined &&
        manualAuditPass.objmanualaudit !== null
      ) {
        manualAuditPass.objmanualaudit.reason = this.state.ResonValue;
        manualAuditPass.objmanualaudit.comments = this.state.Comment;

        let passObjDet = {
          objmanualaudit: manualAuditPass.objmanualaudit,
          objsilentaudit: objuser.objsilentaudit,
        };

        Servicecalls(
          "" + this.ELNurl + "/AuditTrail/silentandmanualRecordHandler",
          passObjDet,
          this.onAuditValidateError.bind(this),
          this.onAuditValidateFailure.bind(this)
        );
      }
    } else {
      this.setState({
        Password: $.session.get("loggedfrom") !== undefined && $.session.get("loggedfrom") === "1" ? $.session.get("azuretoken") : "",
        Comment: "",
        desableaudit: false,
      });
      if (this.state.AuditCancelfunction !== undefined) {
        console.log("invalid password", this.state.AuditCancelfunction);
        //  this.state.AuditCancelfunction();
        this.setState({ PasswordValidcls: "invalid" });
      
      }
      this.props.alert.error(this.props.t("Common.IDS_MSG_INVALID"));

      // this.onAuditfailurereturn();
      if(this.state.loaderEventStopFunction !== undefined){
        this.state.loaderEventStopFunction(true);
      }
     // $("#" + this.state.AuditId).modal("hide");

      this.setState({
        Password: "",
        Comment: "",
        PasswordValidcls: "invalid",
      });

      let objsilentaudit = onAuditHandler(
        "warning",
        "Auditrail",
        this.props.t("Common.IDS_AUDIT_WARNINGAUDITINVALID", {
          module: AuditModuleNameEnum[objuser.objmanualaudit.moduleName]
        })
      );

      if (objsilentaudit !== null) {
        let passObjDet = { objsilentaudit: objsilentaudit };

        Servicecalls(
          "" + this.ELNurl + "/AuditTrail/silentandmanualRecordHandler",
          passObjDet,
          this.onAuditValidateError.bind(this),
          this.onAuditValidateFailure.bind(this)
        );
      }
    }
  }

  onAuditValidateError(res) { }

  onAuditValidateFailure(res) {
    // this.setState({ Password: $.session.get("loggedfrom") !== undefined && $.session.get("loggedfrom") === "1" ? $.session.get("azuretoken") : "", Comment: "" });
    // if (this.state.AuditCancelfunction !== undefined) {
    //   this.state.AuditCancelfunction();
    // }
    this.setState({
      desableaudit: false,
    });
  }

  OnAuditCancel(e) {
    this.setState({
      Password:
        $.session.get("loggedfrom") !== undefined &&
          $.session.get("loggedfrom") === "1"
          ? $.session.get("azuretoken")
          : "",
      Comment: "",
      desableaudit: false,
    });
    if (this.state.AuditCancelfunction !== undefined) {
      this.state.AuditCancelfunction();
    }
    if (
      this.state.getauditcomment &&
      this.state.auditstatusupdate !== undefined
    ) {
      this.state.auditstatusupdate();
    }
  }

  render() {
    return (
      <div
        className="modal"
        id={this.state.AuditId}
        role="dialog"
        data-keyboard="false"
      >
        <Service
          Tourl={this.state.ServiceToUrl}
          MethodType={this.state.ServiceMethodType}
          onSuccess={this.state.ServcieonSuccess}
          onFailure={this.state.ServcieonFailure}
          Headerobject={this.state.ServiceHeaderobject}
          onReseturl={() => {
            this.setState({ ServiceToUrl: "" });
          }}
          ServiceTenant={this.state.ServiceTenant}
          contenttype={this.state.Servicecontenttype}
        ></Service>
        <div className="modal-dialog smallPopup">
          <div className="modal-content">
            <div id="audittrail-modal-header" className="modal-header">
              <h5 className="modal-title" id="audittrail-modal-title">
                <span multilingual="audittrail">
                  {this.props.t("Audittrail.Audittrailhistory.IDS_LBL_AUDITTRAIL", {
                    param: "",
                  })}
                </span>
              </h5>
              <button
                type="button"
                className="btn_white_bg"
                data-dismiss="modal"
                aria-label="Close"
                onClick={this.OnAuditCancel.bind(this)}
              >
                <i className="fas fa-times" aria-hidden="true"></i>
              </button>
            </div>
            <div id="audittrail-modal-body" className="modal-body">
              <div className="form-group">
                <label className="required form-label" multilingual="username">
                  {this.props.t("Setup.Usermaster.IDS_LBL_USERNAME", {
                    param: "",
                  })}
                </label>
                <Suspense fallback={<div></div>}>
                  <TextBox
                    txtType="text"
                    txtClsname={"form-control " + this.state.UserNameValidcls}
                    txtName="Username"
                    txtDataType="Text"
                    txtmaxlength="100"
                    txtvalue={this.state.UserName}
                    sendData={this.onUsernamechange.bind(this)}
                    txtrequired="true"
                    autofocus="false"
                    disabled
                  />
                </Suspense>
              </div>
              <div
                className="form-group"
                onKeyPress={this.onContentKeyPress.bind(this)}
              >
                <label className="required form-label" multilingual="password">
                  {this.props.t("Audittrail.Audittrailhistory.IDS_LBL_PASSWORD", {
                    param: "",
                  })}
                </label>
                <Suspense fallback={<div></div>}>
                  <TextBox
                    txtType="password"
                    txtClsname={"form-control " + this.state.PasswordValidcls}
                    txtName="Password"
                    txtDataType="Text"
                    txtmaxlength="100"
                    txtvalue={this.state.Password}
                    sendData={this.onUserPasswordchange.bind(this)}
                    ref="txtPassword"
                    txtrequired="true"
                    autofocus="true"
                    disabled={
                      $.session.get("loggedfrom") !== undefined &&
                        $.session.get("loggedfrom") === "1"
                        ? true
                        : undefined
                    }
                  />
                </Suspense>
                <div
                  id="audittrail_password_msgid"
                  className="inline-msg failed clearmsg_at"
                ></div>
              </div>
              <div
                className="form-group"
                onKeyPress={this.onContentKeyPress.bind(this)}
              >
                <label className="required form-label" multilingual="reason">
                  {this.props.t("Audittrail.Audittrailhistory.IDS_LBL_REASON", {
                    param: "",
                  })}
                </label>
                <Suspense fallback={<div></div>}>
                  <SelectBox
                    Keyvalue="reasoncode"
                    selClsname={"form-control " + this.state.ResonCodeValidcls}
                    Datasource={this.state.ResonData}
                    ValueMember="reasoncode"
                    DisplayMember="comments"
                    valuechange={this.onReasonChange.bind(this)}
                  />
                </Suspense>
              </div>
              <div className="form-group">
                <label className="required form-label" multilingual="comments">
                  {this.props.t("Audittrail.Audittrailhistory.IDS_LBL_COMMENTS", {
                    param: "",
                  })}
                </label>
                <Suspense fallback={<div></div>}>
                  <TextArea
                    txtClsname={"form-control " + this.state.CommentValidcls}
                    txtName="Comment"
                    txtDataType="Text"
                    txtmaxlength="150"
                    txtvalue={this.state.Comment}
                    sendData={this.onCommentchange.bind(this)}
                    txtrequired="true"
                    autofocus="false"
                  />
                </Suspense>
              </div>
            </div>
            <div id="audittrail-modal-footer" className="modal-footer">
              <button
                type="button"
                id="audittrail_submitbtn"
                ctrl_type="button"
                className="btn btn-user btn-primary-blue mg-r-1"
                ajaxcallmethod=""
                disabled={this.state.desableaudit ? "disabled" : ""}
                onClick={this.OnAuditSubmit.bind(this)}
              >
                <span multilingual="submit">
                  {" "}
                  {this.props.t("Audittrail.Audittrailhistory.IDS_LBL_SUBMIT", {
                    param: "",
                  })}
                </span>
              </button>
              <button
                type="button"
                id="audittrail_closeform"
                className="btn_white_bg txt-btn"
                ctrl_type="button"
                data-dismiss="modal"
                onClick={this.OnAuditCancel.bind(this)}
              >
                <span multilingual="close">
                  {" "}
                  {this.props.t("Audittrail.Audittrailhistory.IDS_LBL_CLOSE", {
                    param: "",
                  })}
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default (withTranslation(Audit));
