import React, { Component } from "react";
// import * as ReactDOM from "react-dom";

import { DatePicker } from "@progress/kendo-react-dateinputs";
import { LocalizationProvider, loadMessages } from '@progress/kendo-react-intl';

import enMessages from '../custom_modules/translations/en.json';
import koMessages from '../custom_modules/translations/ko.json';
import frMessages from '../custom_modules/translations/fr.json';

loadMessages(enMessages, 'en-En');
loadMessages(koMessages, 'ko-KO');
loadMessages(frMessages, 'fr-FR');

class KendoDatepicker extends Component {
    constructor(props) {
        super(props);

        this.state = {
            value: new Date(this.props.DefaultValue),
            MaxiDate: this.props.maxdate !== undefined ? this.props.maxdate : new Date(),
            miniDate: this.props.mindate !== undefined ? this.props.mindate : undefined
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setState({
            value:
                nextProps.DefaultValue !== undefined
                    ? new Date(nextProps.DefaultValue)
                    : new Date(),
            MaxiDate: nextProps.maxdate !== undefined ? nextProps.maxdate : new Date(),
            miniDate: nextProps.mindate !== undefined ? nextProps.mindate : undefined
        });
    }

    handleChange = event => {
        this.setState({
            value: event.target.value
        });

        this.props.onDatachanges(event.target.value);
    };
    render() {
        let lang = localStorage.getItem("ElnLang");
        let localizeprovider = "en-EN";
        if (lang !== null) {
            localizeprovider = lang + "-" + lang.toUpperCase();
        }

        return (
            <LocalizationProvider language={localizeprovider}>
                <DatePicker
                    onChange={this.handleChange}
                    value={this.state.value}
                    format="dd/MM/yyyy"
                    max={this.state.MaxiDate}
                    min={this.state.miniDate}
                    className="clskendoDatepick"
                    popupSettings={{ animate: true, popupClass: "clsKendoDatepop" }}
                />
            </LocalizationProvider>
        );
    }
}
export default KendoDatepicker;
