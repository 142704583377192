import React from "react";
import {
    Grid,
    GridColumn as Column,
    GridColumnMenuSort,
    GridColumnMenuFilter,
} from "@progress/kendo-react-grid";
import * as XLSX from 'xlsx';
import ListGroup from 'react-bootstrap/ListGroup';
import { Label, Modal, ModalBody } from "reactstrap";

const SelectBox = React.lazy(() => import("./SelectBox.jsx"));
class ImportData extends React.Component {

    constructor(props) {
        super(props);
        this.state = { data: [], emptycolumnname: [] };
        this.aRef = React.createRef(null);
        this.selectioncolumn = [];
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
        this.onsetimporteddata = this.onsetimporteddata.bind(this);
    }

    componentDidMount() {
        window.addEventListener("resize", this.updateWindowDimensions);
        this.setState({ columns: this.props.Columns })
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateWindowDimensions);
    }

    // static getDerivedStateFromProps(nextProps, prevState) {
    //     if (nextProps.isDuplicateobj.isDuplicate && this.state.data.length > 0) {
    //         let Data = this.state.data.map((items, index) => {
    //             if (index === 2) {
    //                 items.select = true;
    //             }
    //             return items
    //         })
    //         this.setState({ Data })
    //     }
    // }


    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.isDuplicateobj.isDuplicate && this.state.data.length > 0) {
            let Data = this.state.data.map((items, index) => {
                if (index === 2) {
                    items.selectsecondgrid = true;
                }
                return items
            })
            this.setState({ Data })
        }
    }

    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }

    formatcolumnwidth(index) {
        let width = "250px";

        if (this.props.Columns[index].width !== undefined && this.props.Columns[index].width !== "") {
            width = this.state.width < 1000 ? this.props.Columns[index].width : undefined;
        }

        return width;
    }



    formatcolumn() {
        let columns = [];

        if (this.props.Columns !== undefined && this.props.Columns.length > 0) {
            for (let i = 0; i < this.props.Columns.length; i++) {
                let columnwidth = this.formatcolumnwidth(i);

                if (!this.props.Columns[i].rowselector && this.props.Columns[i].celltype !== "Checkbox"
                    && this.props.Columns[i].celltype !== "Checkboxinventory" && this.props.Columns[i].celltype !== "Button"
                    && this.props.Columns[i].celltype !== "Detailview" && this.props.Columns[i].celltype !== "sharesheetorder"
                    && this.props.Columns[i].celltype !== "copy" && this.props.Columns[i].celltype !== "protocolorderaction"
                    && this.props.Columns[i].celltype !== "protocolaction" && this.props.Columns[i].celltype !== "parseraction"
                    && this.props.Columns[i].celltype !== "orderGridView" && this.props.Columns[i].celltype !== "customStatusColumn"
                    && this.props.Columns[i].celltype !== "dateColumn" && this.props.Columns[i].celltype !== "sheetaction"
                    && this.props.Columns[i].celltype !== "protocolsharedbyaction" && this.props.Columns[i].celltype !== "sheetsharedbyaction" &&
                    this.props.Columns[i].celltype !== "completebuttontemplate" && this.props.Columns[i].celltype !== "orderunsharedbyme" &&
                    this.props.Columns[i].celltype !== "protocolorderunshareaction" && this.props.Columns[i].celltype !== "unshareprotocoltemplate" &&
                    this.props.Columns[i].celltype !== "sharesheettemplate" && this.props.Columns[i].celltype !== "unshareprotocoltemplate"
                    && this.props.Columns[i].celltype !== "sharesheettemplate" && this.props.Columns[i].celltype !== "projectTeamsView" &&
                    // this.props.Columns[i].celltype !== "usergroupviews" &&
                    this.props.Columns[i].celltype !== "ordertransactions" &&
                    this.props.Columns[i].celltype !== "inventryhistory") {
                    if (this.props.Columns[i].format === undefined
                        && this.props.Columns[i].header
                        && this.props.Columns[i].header !== ""
                    ) {
                        columns.push(
                            <Column
                                key={this.props.Columns[i].datatype}
                                field={this.props.Columns[i].datatype}
                                width={columnwidth}
                                className="breakWord60 text-wrap"
                                title={this.props.Columns[i].header}
                            // {...this.columnProps(
                            //     this.props.Columns[i].datatype,
                            //     this.state.orginaldata,
                            //     this.props.primaryKey,
                            //     this.props.Columns[i].header
                            // )}
                            />
                        );
                    }

                    // columnMenu = { ColumnMenuCheckboxFilter }
                }
            }
        }

        return columns;
    }

    onImport = (e) => {

    }

    onChangefile = (e) => {
        var files = e.target.files, f = files[0];
        var reader = new FileReader();
        let _This = this;
        reader.onload = function (e) {
            var data = e.target.result;
            let readedData = XLSX.read(data, { type: 'binary' });
            const wsname = readedData.SheetNames[0];
            const ws = readedData.Sheets[wsname];

            /* Convert array to json*/
            const dataParse = XLSX.utils.sheet_to_json(ws, { header: 1 });
            console.log(dataParse);
            _This.onsetimporteddata(dataParse);

            // setFileUploaded(dataParse);

            // if ()
        };
        reader.readAsBinaryString(f);
    }

    convertToAlpha(number) {

        number = number + 1;

        var baseChar = ("A").charCodeAt(0),
            letters = "";

        do {
            number -= 1;
            letters = String.fromCharCode(baseChar + (number % 26)) + letters;
            number = (number / 26) >> 0; // quick `floor`
        } while (number > 0);

        return letters;
    }

    onsetimporteddata(dataParseobj) {
        let dataParse = [];
        console.log(dataParseobj)
        if (dataParseobj[0].length > 0 && dataParseobj[0][0] === undefined && dataParseobj[0][1] !== undefined) {
            dataParseobj.map((item, index) => {
                dataParse.push([]);
                item.map((items) => {
                    dataParse[index].push(items.trim())
                })
                return item;
            })
        } else {

            dataParseobj = dataParseobj.map((item, index) => {
                item = item.map((items) => {
                    if (items !== undefined && items !== "" && typeof (items) === "string") {
                        items = items.trim()
                    }
                    return items
                })
                return item;
            })
            dataParse = dataParseobj
        }
        let getDifference = this.state.columns.filter(object1 => {
            return !dataParse[0].some(object2 => {
                return object1.header == object2;
            });
        });
        let customizedcolumn = [];
        this.selectioncolumn = [];
        getDifference = getDifference.filter(items => items.header !== "Site name")
        if (getDifference.length === 0) {
            let originaldatacolumn = dataParse[0];

            let customcolum = [];
            let originadefaultcomun = this.props.Columns.filter(items => items.datatype !== "sitename")
            originadefaultcomun.map((items, index) => {
                originaldatacolumn.map((itemsoriginal, indexori) => {
                    if (items.header === itemsoriginal) {
                        items.impoertedfileindex = index;
                        items.originalheader = index.items;
                        customcolum.push(items);
                        this.selectioncolumn.push({ ...items, "key": index, displayheader: items.header, impoertedfileindex: index, originalheader: items.header })
                    }
                })
                return items
            })
            customcolum = customcolum.filter(items => items.datatype !== "sitename" && items.ismandatory !== undefined && items.ismandatory)
            // dataParse.splice(index, 1);
            this.selectioncolumn = this.selectioncolumn.filter(items => items.datatype !== "sitename" && items.ismandatory !== undefined && items.ismandatory)

            let siteIndex = dataParse[0].indexOf("Site name");
            dataParse = dataParse.splice(1, dataParse.length);

            if (siteIndex !== -1) {
                let filteredData = dataParse.map(row => row.filter((_, i) => i !== siteIndex));
                this.onupdategrid(filteredData, customcolum);
            } else {
                this.onupdategrid(dataParse, customcolum);
            }
            // let originadataobj = [];
            // dataParse.map((items) => {
            //     let obj = {};
            //     items.map((items1, index) => {
            //         obj[this.props.Columns[index].datatype] = items1;
            //     })
            //     originadataobj.push(obj);
            // })
            this.importedcompileddata()
            // this.setState({ data: originadataobj })
            this.aRef.current.value = null;
        } else {

            // let aIndex = 65;
            for (let i = 0; i < dataParse[0].length; i++) {
                customizedcolumn.push({
                    // header: String.fromCharCode(aIndex + i),
                    header: this.convertToAlpha(i),
                    impoertedfileindex: i,
                    originalheader: dataParse[0][i]
                })
            }

            customizedcolumn.unshift({
                header: "N/A",
                impoertedfileindex: -1,
                originalheader: "N/A"
            })

            if (customizedcolumn.length > 0) {
                this.props.Columns.map((items, index) => {
                    if (items.ismandatory !== undefined && items.ismandatory) {
                        this.selectioncolumn.push({ ...items, displayheader: "N/A", "key": index, impoertedfileindex: -1, originalheader: "N/A" })
                    }
                })
                dataParse = dataParse.splice(1, dataParse.length);
                this.onupdategrid(dataParse, customizedcolumn);
            }
            this.aRef.current.value = null;
        }
    }

    onupdategrid(originadataobj, customizedcolumn) {
        console.log(originadataobj);
        const filteredArr = originadataobj.filter(arr => arr.length > 0);
        this.setState({
            originalimporteddatas: filteredArr,
            iscolumncustomise: true,
            customizedcolumn: customizedcolumn
        })
    }

    selectioncolumnchange(key, value, text, object) {
        let filtervalues = this.selectioncolumn.filter(items => items.key === parseInt(key));
        if (filtervalues.length > 0) {
            this.selectioncolumn = this.selectioncolumn.map((items) => {
                if (items.key === parseInt(key)) {
                    items = {
                        ...items, "key": key, displayheader: object.header, impoertedfileindex: object.impoertedfileindex, originalheader: object.originalheader
                    }
                }
                return items
            });
        } else {
            let obj = { ...this.props.Columns[parseInt(key)] }
            this.selectioncolumn.push({ ...obj, "key": key, displayheader: object.header, impoertedfileindex: object.impoertedfileindex, originalheader: object.originalheader })
        }
    }
    importedcompileddata = (e) => {
        let originadataobj = [];
        let emptycolumnname = [];
        let notaplicableformanitery = false;

        this.state.originalimporteddatas.some((items) => {
            let obj = {};
            items.length > 0 && this.selectioncolumn.some((item, index) => {

                if (items[item.impoertedfileindex] !== undefined && items[item.impoertedfileindex] !== "") {
                    obj[item.datatype] = items[item.impoertedfileindex];
                } else if (item.impoertedfileindex === -1 && item.ignoremandatory === undefined) {
                    obj[item.datatype] = null;
                    notaplicableformanitery = true;
                    emptycolumnname.push(item.header);
                } else {
                    if (item.ignoremandatory === undefined) { emptycolumnname.push(item.header); }
                }
                if (emptycolumnname.length > 0 && notaplicableformanitery) {
                    this.props.alert.error(this.props.t("Dashboard.Sheetorders.IDS_MSG_EMPTYCOLUMN", { column: emptycolumnname[emptycolumnname.length - 1] }));
                    return true;
                }
                return false
            })
            if (emptycolumnname.length > 0 && notaplicableformanitery) { return true; }
            else { originadataobj.push(obj); }
            return false;
        })
        // this.state.originalimporteddatas.map((items) => {
        //     let obj = {};
        //     this.selectioncolumn.map((item, index) => {
        //         if (items[item.impoertedfileindex] !== undefined && items[item.impoertedfileindex] !== "") {
        //             obj[item.datatype] = items[item.impoertedfileindex];
        //         } else if (item.impoertedfileindex === -1 && item.ignoremandatory === undefined) {
        //             obj[item.datatype] = null;
        //             emptycolumnname.push(item.header);
        //         } else {
        //             if (item.ignoremandatory !== undefined) {
        //                 emptycolumnname.push(item.header);

        //             }
        //         }
        //     })
        //     originadataobj.push(obj);
        // })
        if ((emptycolumnname.length > 0 && !notaplicableformanitery) || (emptycolumnname.length === 0 && !notaplicableformanitery)) {
            this.setState({ data: originadataobj, iscolumncustomise: false, emptycolumnname: emptycolumnname })
            this.props.onimporteddats(originadataobj, emptycolumnname);
        }
    }

    closeModel = (e) => {
        e.preventDefault();
        this.setState({ iscolumncustomise: false });
    }
    render() {
        return (<div>
            {this.state.iscolumncustomise &&
                <Modal isOpen={this.state.iscolumncustomise} className={"modal-lg"}>
                    <div className="modal-header">
                        <h5 className="modal-title" id="staticBackdropLabel">
                            {this.props.t("Inventory.Section.IDS_LBL_IMPORTDATA")}
                        </h5>
                        <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                            onClick={this.closeModel.bind(this)}
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>

                    <ModalBody>
                        <div class="row">
                            <div class="col-5 show-order-content  lg-title-bold">
                                 {this.props.t("Inventory.Section.IDS_LBL_DEFAULTCOL")}
                            </div>
                            <div class="col-7 show-order-content  lg-title-bold">
                            {this.props.t("Inventory.Section.IDS_LBL_IMPORTEDCOL")}
                            </div>
                        </div>
                        < ListGroup as="ol" numbered style={{ maxHeight: 430, overflow: "auto" }} >
                            {
                                this.props.Columns &&
                                this.props.Columns.map((item, index) => (
                                    item.header && item.header !== "" ?
                                        // item.ismandatory !== undefined && item.ismandatory ?
                                        <div key={index} >
                                            <ListGroup.Item as="li" >
                                                <div class="row">
                                                    <div class={"col-sm-5 p-3"}>{item.header}</div>
                                                    <div class="col-sm-7 p-3">
                                                        <SelectBox
                                                            Keyvalue={index}
                                                            selClsname="form-control"
                                                            Datasource={this.state.customizedcolumn}
                                                            ValueMember="header"
                                                            DisplayMember="header"
                                                            valuechange={this.selectioncolumnchange.bind(this)}
                                                        // value={
                                                        //     this.selectioncolumn !== undefined && this.selectioncolumn.length > 0 ? this.selectioncolumn.filter((items) => items.key === index)[0] : -1
                                                        // }
                                                        />
                                                    </div>
                                                </div>
                                            </ListGroup.Item>
                                            < dd className="k-line-break" > </dd>
                                        </div>
                                        : false
                                ))
                            }
                        </ListGroup>
                    </ModalBody>
                    <div id="UserMasterADD-modal-footer" className="modal-footer">
                        <button
                            type="button"
                            id="idSaveSheetAs"
                            className="btn btn-user btn-primary-blue"
                            onClick={this.importedcompileddata.bind(this)}
                        >
                            {this.props.t("Inventory.Section.IDS_BTN_OK")}
                        </button>
                        <button
                            type="button"
                            className="btn modalbluebtn"
                            onClick={this.closeModel.bind(this)}
                        >
                            {this.props.t("Header.IDS_LBL_CLOSE", { param: "", })}
                        </button>
                    </div>
                </Modal>}
            <ul className="list-inline">
                <li className="list-inline-item">
                    <div className="align-items-center mb-2 customUploadFile">
                        <span className="btn btn-file file-upload-btn">
                            <i className="fas fa-file-upload mr-2"></i>
                            {/* Choose File  */}
                            {/* {"Select file"} */}
                            {this.props.t("Inventory.Unit.IDS_LBL_SELECTFILE", { param: "", })}
                            <input
                                ref={this.aRef}
                                className="btn-file"
                                type="file"
                                name="file"
                                onChange={this.onChangefile}
                                accept={[".xls", ".xlsx"]}
                            />
                        </span>
                        <label className="uploaded-file ml-1">
                            {this.state.selectedfiles !== undefined &&
                                this.state.selectedfiles.length > 0
                                ? this.state.selectedfiles[0].name
                                : ""}
                        </label>
                    </div>
                </li>
            </ul>
            <Grid
                data={this.state.data}
                // cellRender={this.cellRender}
                style={{
                    height: "65vh",
                }}
                selectedField="selectsecondgrid"

            >
                {this.formatcolumn()}
            </Grid>
        </div>)
    }
}
export default ImportData;