import React, { Component } from "react";
import { css } from "@emotion/core";
import PuffLoader from "react-spinners/PuffLoader";
import SyncLoader from "react-spinners/SyncLoader";

const override = css`
    position: fixed;
    z-index: 99999;
    height: 2em;
    width: 2em;
    overflow: visible;
    margin: auto;
    top: 50%;
    left: 50%;
    bottom: 0;
    right: 0;
    margin-top: -50px;
    margin-left: -50px;
    pointer-events:none;

    :before {
        content: '';
        display: block;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,0.3);
        pointer-events:none;
    }
`;
const overrideSync = css`
  position: fixed;
  margin: 5px;
  clear: both;
  left: 40%;

  :before {
    content: "";
    display: block;
    position: fixed;

    background-color: rgba(0, 0, 0, 0.3);
  }
`;
class PageLoader extends Component {

    constructor(props) {
        super(props);
        this.state = {
            IsLoader: false
        }
    }

    closeModel() {
        this.setState({ IsLoader: false });
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps !== undefined && nextProps.isloader !== undefined) {
            this.setState({ IsLoader: nextProps.isloader });
        }
    }

    componentDidMount() {
        this.setState({ IsLoader: true });
    }

    componentWillUnmount() {
        this.setState({ IsLoader: false });
    }

    render() {
        let display = "clsDisplaynone";
        if (this.state.IsLoader) {
            display = "clsdisplayblock";
        }
        return (
            <React.Fragment>
                {!this.props.innerLoader ?
                    <div className={`cls-loader ${display}`} >
                        {/* <div className={"row justify-content-center align-items-center "}>
                        <div className="col-12">
                            <Loader type="Puff" color="#00BFFF" width={180} height={180} />
                        </div>
                    </div>
                    <div className="clear"></div> */}
                        <PuffLoader
                            css={override}
                            size={100}
                            color={"#123abc"}
                        />
                    </div> :
                    <div className={`cls-loader ${display} inner-loader`} >
                        <SyncLoader
                            css={this.props.loadstyle && overrideSync}
                            size={10}
                            color={"#123abc"}
                            margin={5} />
                    </div>
                }
            </React.Fragment>
        )
    }
}
export default PageLoader;