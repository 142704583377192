import React, { Component, Suspense, lazy } from "react";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import { connect } from 'react-redux';
import { transitions, positions, Provider as AlertProvider } from 'react-alert'
import AlertTemplate from 'react-alert-template-basic'
import { onlyOfficeReadOnly } from './custom_modules/DownloadReport'
import PageLoader from "./custom_modules/general/PageLoader.jsx";
import jszip from "jszip";
import $ from "jquery";
// import { isMultitenant } from "./custom_functions/Constance";
import { Redirect } from "react-router-dom";
import { LogoutSessionClear, Servicecalls } from "./custom_functions/Functions";
import store from "./redux_store/store";
import { Celllevelauditforspreadsheet } from "./redux_store/actions";

const Login = lazy(() => import('./custom_modules/Login.jsx'));
const Home = lazy(() => import('./custom_modules/Home.jsx'));
const Notfound = lazy(() => import('./custom_modules/Notfound.jsx'));

const Dashboard = lazy(() => import('./custom_modules/main_pages/PDashboard.jsx'));

const PAuditConfig = lazy(() => import('./custom_modules/main_pages/audit/PAuditConfig.jsx'));
const PAuditTrailHistory = lazy(() => import('./custom_modules/main_pages/audit/PAuditTrailHistory.jsx'));
const PCFRsetting = lazy(() => import('./custom_modules/main_pages/audit/PCFRsetting.jsx'));

const PTestmaster = lazy(() => import('./custom_modules/main_pages/base_master/PTestmaster.jsx'));
// const PSamplemaster = lazy(() => import('./custom_modules/main_pages/base_master/PSamplemaster.jsx'));
const PProjectmaster = lazy(() => import('./custom_modules/main_pages/base_master/PProjectmaster.jsx'));
const PUnLockOrder = lazy(() => import('./custom_modules/main_pages/base_master/PUnLockOrder.jsx'));
const PLogbook = lazy(() => import('./custom_modules/main_pages/logbook/PLogbook.jsx'));

const Report = lazy(() => import('./custom_modules/main_pages/PReport.jsx'));

const Protocols = lazy(() => import('./custom_modules/main_pages/templates/PProtocols.jsx'));
const Sheetcreation = lazy(() => import('./custom_modules/main_pages/templates/PSheetcreation.jsx'));
const SheetMapping = lazy(() => import('./custom_modules/main_pages/templates/PSheetMapping.jsx'));
const Delimiters = lazy(() => import('./custom_modules/main_pages/methodsetup/PDelimiters.jsx'));
const Methoddelimiters = lazy(() => import('./custom_modules/main_pages/methodsetup/PMethoddelimiters.jsx'));
const Methodmaster = lazy(() => import('./custom_modules/main_pages/methodsetup/PMethodmaster.jsx'));
const InstrumentCategory = lazy(() => import('./custom_modules/main_pages/methodsetup/PInstrumentCategory.jsx'));
const PInstruments = lazy(() => import('./custom_modules/main_pages/methodsetup/PInstruments.jsx'));
const PSdmsMapInstruments = lazy(() => import('./custom_modules/main_pages/methodsetup/PSdmsMapInstruments.jsx'));

const Activeuser = lazy(() => import('./custom_modules/main_pages/user_management/Pactiveusers.jsx'));
const Domain = lazy(() => import('./custom_modules/main_pages/user_management/Pdomain.jsx'));
const Orderworkflow = lazy(() => import('./custom_modules/main_pages/user_management/Porderworkflow.jsx'));
const Passwordpolicy = lazy(() => import('./custom_modules/main_pages/user_management/Ppasswordpolicy.jsx'));
const Projectteam = lazy(() => import('./custom_modules/main_pages/user_management/Pprojectteam.jsx'));
const Protocolworkflow = lazy(() => import('./custom_modules/main_pages/user_management/Pprotocolworkflow.jsx'));
const Sheetworkflow = lazy(() => import('./custom_modules/main_pages/user_management/Psheetworkflow.jsx'));
const Usergroup = lazy(() => import('./custom_modules/main_pages/user_management/Pusergroup.jsx'));
const Usermaster = lazy(() => import('./custom_modules/main_pages/user_management/Pusermaster.jsx'));
const Userrights = lazy(() => import('./custom_modules/main_pages/user_management/Puserrights.jsx'));
const Usersite = lazy(() => import('./custom_modules/main_pages/user_management/Pusersite.jsx'));

const Registertask = lazy(() => import('./custom_modules/main_pages/orders/PRegistertask.jsx'));
const Protocolorder = lazy(() => import('./custom_modules/main_pages/orders/PProtocolorders.jsx'));
const Vieworder = lazy(() => import('./custom_modules/main_pages/orders/PViewerorder'));

// const PTenantRegistration = lazy(() => import('./custom_modules/main_pages/PTenantRegistration.jsx'));
const PHelp = lazy(() => import('./custom_modules/main_pages/PHelp.jsx'));
const Ptenantlogin = lazy(() => import('./custom_modules/tenant/Tenantlogin.jsx'));
const PMethodsetup = lazy(() => import('./custom_modules/main_pages/PMethodsetup.jsx'));
const PFileViewer = lazy(() => import('./custom_modules/main_pages/PFileViewer.jsx'));

const Ptenantprofile = lazy(() => import('./custom_modules/tenant/Tenantprofile.jsx'));

const Administrator = lazy(() => import('./custom_modules/administrator/AdministratorLogin.jsx'));
const AdministratorHome = lazy(() => import('./custom_modules/administrator/AdministratorHome.jsx'));
const AdministratorUser = lazy(() => import('./custom_modules/administrator/UserManagement.jsx'));
const AdministratorHelpDocument = lazy(() => import('./custom_modules/administrator/AdministratorHelpDocument.jsx'));
const AdministratorHomeDocument = lazy(() => import('./custom_modules/administrator/AdministratorHomeDocument.jsx'));
// const AdministratorDynamicInventory = lazy(() => import('./custom_modules/administrator/DynamicInventory.jsx'));


// const PManuFacturer = lazy(() => import('./custom_modules/main_pages/inventory/PManuFacturer.jsx'));
const PUnitMaster = lazy(() => import('./custom_modules/main_pages/inventory/PUnitMaster.jsx'));
const PSectionMater = lazy(() => import('./custom_modules/main_pages/inventory/PSectionMaster.jsx'));
const Materialcategory = lazy(() => import('./custom_modules/main_pages/inventory/PMaterialcategory.jsx'));
const Materialgrade = lazy(() => import('./custom_modules/main_pages/inventory/PMaterialGrade.jsx'));
const Supplier = lazy(() => import('./custom_modules/main_pages/inventory/PSupplier.jsx'));
const Manufacturer = lazy(() => import('./custom_modules/main_pages/inventory/PManufacturer.jsx'));
const Storagelocation = lazy(() => import('./custom_modules/main_pages/inventory/PStoragelocation.jsx'));
const Material = lazy(() => import('./custom_modules/main_pages/inventory/PMaterial.jsx'));
const Materialinventory = lazy(() => import('./custom_modules/main_pages/inventory/PMaterialInventory.jsx'));
const PInventory = lazy(() => import('./custom_modules/main_pages/inventory/PInventroy.jsx'));
const MaterialType = lazy(() => import('./custom_modules/main_pages/inventory/PMaterialType.jsx'));
const EquipmentType = lazy(() => import('./custom_modules/main_pages/inventory/PEquipmentType.jsx'));
const Equipment = lazy(() => import('./custom_modules/main_pages/inventory/PEquipment.jsx'));
const EquipmentCat = lazy(() => import('./custom_modules/main_pages/inventory/PEquipmentCategory.jsx'));
// const MaterialType = lazy(() => import('./custom_modules/main_pages/inventory/PMaterialType.jsx'));

const Logbooklist = lazy(() => import('./custom_modules/logbook/module/Logbooklist.jsx'));

// const Reportdesigner = lazy(() => import('./custom_modules/main_pages/reports/PReportdesigner.jsx'));
// const Reportviewer = lazy(() => import('./custom_modules/main_pages/reports/PReportviewer.jsx'));

const options = {
  timeout: 5000,
  position: positions.TOP_RIGHT,
  //offset: '30px',
  // you can also just use 'scale'
  transition: transitions.SCALE,
  containerStyle: {
    zIndex: 999999
  }
};



const mapStateToProps = state => {
  return state;
}
let Auditservice_Iscaling = false;
//basename="/ELN"
class App extends Component {

  componentDidMount() {
    window.JSZip = jszip;
    window.jQuery = $;
    // window.tui = tuieditor;
    // window.FroalaEditor = FroalaEditor;

    // window.addEventListener('beforeunload', function (event) {
    //   sessionStorage.removeItem('__lock');
    // });

    window.addEventListener('unload', this.handleEndConcert, true)


    if (sessionStorage.getItem('__lock')) {
      console.log("session cleared")
      // sessionStorage.clear();
      LogoutSessionClear();
      console.warn('Found a lock in session storage. The storage was cleared.');
      return (
        <Redirect to="/" />
      );
    }

    sessionStorage.setItem('__lock', '1');

    if (sessionStorage.getItem("__reloadOrder") || sessionStorage.getItem("reloadOrder_Protocol")) {
      let passObjDet1 = { usercode: sessionStorage.getItem("User") };
      const getOrderDetail = sessionStorage.getItem("__reloadOrder");
      const Protocolorder_Ordercode = sessionStorage.getItem("reloadOrder_Protocol");
      if (getOrderDetail !== undefined && getOrderDetail !== null) {
        passObjDet1.Batch = Number(getOrderDetail)
      }
      if (Protocolorder_Ordercode !== undefined && Protocolorder_Ordercode !== null) {
        passObjDet1.protocolordercode = Protocolorder_Ordercode;
      }
      if (Object.keys(passObjDet1).length > 0) {
        const url = document.getElementById("ELNname").value + "/File/unlockorderOnViewClose";
        fetch(url, {
          method: "POST",
          body: JSON.stringify(passObjDet1),
          headers: new Headers({
            Authorization: sessionStorage.getItem("Token"),
            "X-TenantID": sessionStorage.getItem("tenantid"),
            Accept: "application/json",
            "Content-Type": "application/json",
          }),
        }).then(response => { }).catch(error => { });
        sessionStorage.removeItem("__reloadOrder");
        sessionStorage.removeItem("reloadOrder_Protocol");
      }

    }

    window.addEventListener('beforeunload', this.handleUnload);
  }

  handleEndConcert = async (e) => {
    sessionStorage.removeItem('__lock');
    if (sessionStorage.getItem("_OrderView") !== undefined && sessionStorage.getItem("_OrderView") !== null) {

    } else {
      let passObjDet = { usercode: sessionStorage.getItem("User"), activeusercode: sessionStorage.getItem('activeUserId') };
      fetch("" + document.getElementById("ELNname").value + "/Login/autoLogout", {
        method: "POST",
        body: JSON.stringify(passObjDet),
        headers: new Headers({
          Authorization: sessionStorage.getItem("Token"),
          "X-TenantID": sessionStorage.getItem("tenantid"),
          Accept: "application/json",
          "Content-Type": "application/json",
        }),
      })

    }

    let Celllevel_Auditdatas = store.getState().Celllevel_Auditdatas || [];
    if (Celllevel_Auditdatas.length > 0 && !Auditservice_Iscaling) {
      Auditservice_Iscaling = true;
      fetch(document.getElementById("ELNname").value + "/AuditTrail/silentRecordHandlerForOrder", {
        method: "POST",
        body: JSON.stringify(Celllevel_Auditdatas),
        headers: {
          Authorization: sessionStorage.getItem("Token"),
          "X-TenantID": sessionStorage.getItem("tenantid"),
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then(response => {
          store.dispatch(Celllevelauditforspreadsheet(
            []
          ));
        })
        .catch(error => {
          // Handle error if needed
        });
    }

    const getOrderDetail = store.getState().Sheetview;
    const storedataforprotocolorder = store.getState().OpenprotocolordersDatas;

    const { OrderTestcode } = getOrderDetail || {};

    const { protocolOrder, protocolorderopen } = storedataforprotocolorder || {};
    const lockeduser = protocolOrder?.lockeduser || undefined;


    // if ((getOrderDetail?.OrderTestcode?.batchcode !== undefined && getOrderDetail.OrderTestcode.batchcode !== null) || (storedataforprotocolorder !== undefined && storedataforprotocolorder?.protocolOrder?.protocolordercode && storedataforprotocolorder?.lockeduser !== undefined && storedataforprotocolorder?.lockeduser === Number(sessionStorage.User))) {
    if ((OrderTestcode?.batchcode || (protocolOrder?.protocolordercode && lockeduser === Number(sessionStorage.User)))) {

      const orderArray = localStorage.getItem("_viewOrderDetail") !== undefined
        && localStorage.getItem("_viewOrderDetail") !== null && localStorage.getItem("_viewOrderDetail") !== ""
        ? localStorage.getItem("_viewOrderDetail") : [];
      let passObjDet1 = { usercode: sessionStorage.getItem("User") };
      if (getOrderDetail?.OrderTestcode?.batchcode !== undefined && getOrderDetail.OrderTestcode.batchcode !== null) {
        passObjDet1.Batch = getOrderDetail.OrderTestcode.batchcode
        orderArray.push(passObjDet1)
        localStorage.setItem("_viewOrderDetail", JSON.stringify(orderArray));
      }
      if (protocolorderopen && protocolOrder?.protocolordercode && lockeduser === Number(sessionStorage.User)) {
        passObjDet1.protocolordercode = storedataforprotocolorder.protocolOrder?.protocolordercode;
        orderArray.push(passObjDet1)
        localStorage.setItem("_viewOrderDetail", JSON.stringify(orderArray));
      }

      const url = document.getElementById("ELNname").value + "/File/unlockorderOnViewClose";
      if (Object.keys(passObjDet1).length > 0) {
        fetch(url, {
          method: "POST",
          body: JSON.stringify(passObjDet1),
          headers: new Headers({
            Authorization: sessionStorage.getItem("Token"),
            "X-TenantID": sessionStorage.getItem("tenantid"),
            Accept: "application/json",
            "Content-Type": "application/json",
          }),
        })
          .then(response => { localStorage.setItem("_viewOrderDetail", ''); })
          .catch(error => { });
      }


    }

    // var message = "\o/";

    // (e || window.event).returnValue = message; //Gecko + IE
    // return message;
  }


  componentWillUnmount() {
    sessionStorage.setItem("__lock", "1");
    window.removeEventListener('beforeunload', this.handleUnload);

    window.addEventListener('unload', this.handleEndConcert, true)
    // this.handleEndConcert()
  }

  handleUnload = (e) => {
    let Celllevel_Auditdatas = store.getState().Celllevel_Auditdatas || [];
    if (Celllevel_Auditdatas.length > 0) {
      Auditservice_Iscaling = true;
      fetch(document.getElementById("ELNname").value + "/AuditTrail/silentRecordHandlerForOrder", {
        method: "POST",
        body: JSON.stringify(Celllevel_Auditdatas),
        headers: {
          Authorization: sessionStorage.getItem("Token"),
          "X-TenantID": sessionStorage.getItem("tenantid"),
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then(response => {
          store.dispatch(Celllevelauditforspreadsheet(
            []
          ));
        })
        .catch(error => {
          // Handle error if needed
        });
    }
    e.preventDefault();
    e.returnValue = '';
  };

  // handleUnload(e) {

  //   const getOrderDetail = store.getState().Sheetview;
  //   const storedataforprotocolorder = store.getState().OpenprotocolordersDatas;
  //   if (getOrderDetail !== undefined && getOrderDetail !== null && getOrderDetail.OrderTestcode !== undefined && getOrderDetail.OrderTestcode !== null) {
  //     if (getOrderDetail.OrderTestcode.batchcode !== undefined && getOrderDetail.OrderTestcode.batchcode !== null) {
  //       sessionStorage.setItem("__reloadOrder", getOrderDetail.OrderTestcode.batchcode);
  //     }
  //   }
  //   if (storedataforprotocolorder?.protocolorderopen && storedataforprotocolorder?.protocolOrder?.protocolordercode !== undefined) {
  //     sessionStorage.setItem("reloadOrder_Protocol", storedataforprotocolorder.protocolOrder?.protocolordercode);
  //   }
  //   let Celllevel_Auditdatas = store.getState().Celllevel_Auditdatas || [];
  //   if (Celllevel_Auditdatas.length > 0) {
  //     fetch(document.getElementById("ELNname").value + "/AuditTrail/silentRecordHandlerForOrder", {
  //       method: "POST",
  //       body: JSON.stringify(Celllevel_Auditdatas),
  //       headers: new Headers({
  //         Authorization: sessionStorage.getItem("Token"),
  //         "X-TenantID": sessionStorage.getItem("tenantid"),
  //         Accept: "application/json",
  //         "Content-Type": "application/json",
  //       }),
  //     })
  //       .then(response => { })
  //       .catch(error => { });
  //   }
  //   // eslint-disable-next-line no-useless-escape
  //   var message = "\o/";

  //   (e || window.event).returnValue = message; //Gecko + IE
  //   return message;
  // }

  render() {
    return (
      <AlertProvider template={AlertTemplate} {...options}>
        <Router>
          <Suspense fallback={<PageLoader />}>
            <Switch>
              <Route
                exact
                path="/"
                component={Login}
              // onEnter={requireAuth.bind(this, store)}
              />

              <Route path="/Home" component={Home} />
              <Route path="/documents" component={onlyOfficeReadOnly} />\

              <Route path="/dashboard" component={Dashboard} />

              <Route path="/audithistory" component={PAuditTrailHistory} />
              <Route path="/auditconfiguration" component={PAuditConfig} />
              <Route path="/cfrsettings" component={PCFRsetting} />
              <Route path="/instrumentcategory" component={InstrumentCategory} />
              <Route path="/instruments" component={PInstruments} />
              <Route path="/sdmsmappedinstruments" component={PSdmsMapInstruments} />
              <Route path="/delimiters" component={Delimiters} />
              <Route path="/methoddelimiters" component={Methoddelimiters} />
              <Route path="/methodmaster" component={Methodmaster} />


              <Route path="/audithistory" component={PAuditTrailHistory} />
              <Route path="/auditconfiguration" component={PAuditConfig} />
              <Route path="/cfrsettings" component={PCFRsetting} />

              <Route path="/testmaster" component={PTestmaster} />
              {/* <Route path="/samplemaster" component={PSamplemaster} /> */}
              <Route path="/projectmaster" component={PProjectmaster} />
              <Route path="/unlockorder" component={PUnLockOrder} />
              <Route path="/logbook" component={PLogbook} />

              <Route path="/registertask" component={Registertask} />
              <Route path="/Protocolorder" component={Protocolorder} />
              <Route path="/vieworder" component={Vieworder} />

              <Route path="/report" component={Report} />

              <Route path="/sheetcreation" component={Sheetcreation} />
              <Route path="/protocols" component={Protocols} />
              <Route path="/sheetmapping" component={SheetMapping} />

              <Route path="/activeuser" component={Activeuser} />
              <Route path="/domain" component={Domain} />
              <Route path="/Orderworkflow" component={Orderworkflow} />
              <Route path="/Passwordpolicy" component={Passwordpolicy} />
              <Route path="/Projectteam" component={Projectteam} />
              <Route path="/Protocolworkflow" component={Protocolworkflow} />

              <Route path="/Sheetworkflow" component={Sheetworkflow} />
              <Route path="/Usergroup" component={Usergroup} />
              <Route path="/Usermaster" component={Usermaster} />
              <Route path="/Userrights" component={Userrights} />
              <Route path="/Usersite" component={Usersite} />


              {/* <Route path="/registerorganisation" component={PTenantRegistration} /> */}
              <Route path="/tenantlogin" component={Ptenantlogin} />
              <Route path="/tenantprofile" component={Ptenantprofile} />
              <Route path="/administrator" component={Administrator} />
              <Route path="/ad_organisation" component={AdministratorHome} />
              <Route path="/ad_usermanagement" component={AdministratorUser} />
              <Route path="/ad_adhelpdocument" component={AdministratorHelpDocument} />
              <Route path="/ad_adhomedocument" component={AdministratorHomeDocument} />
              {/* <Route path="/ad_dynamicinventory" component={AdministratorDynamicInventory} /> */}
              <Route path="/help" component={PHelp} />
              <Route path="/fileviewer" component={PFileViewer} />

              <Route path="/methodsetup" component={PMethodsetup} />

              {/* <Route path="/manufacturer" component={PManuFacturer} /> */}
              <Route path="/sectionmaster" component={PSectionMater} />
              <Route path="/materialtype" component={MaterialType} />
              <Route path="/unitmaster" component={PUnitMaster} />
              <Route path="/materialcategory" component={Materialcategory} />
              <Route path="/materialgrade" component={Materialgrade} />
              <Route path="/supplier" component={Supplier} />
              <Route path="/manufacturer" component={Manufacturer} />
              <Route path="/storagelocation" component={Storagelocation} />
              <Route path="/material" component={Material} />
              <Route path="/materialinventory" component={Materialinventory} />
              <Route path="/logbook" component={Logbooklist} />
              <Route path="/inventory" component={PInventory} />
              <Route path="/equipmenttype" component={EquipmentType} />
              <Route path="/equipment" component={Equipment} />
              <Route path="/equipmentcategory" component={EquipmentCat} />
              {/* <Route path="/reportdesigner" component={Reportdesigner} />
              <Route path="/reportviewer" component={Reportviewer} /> */}

              <Route component={Notfound} />
            </Switch>
          </Suspense>
        </Router>
      </AlertProvider >
    );
  }
}
export default connect(mapStateToProps)(App, { forwardRef: true });;
