import React from "react";
// import ReactDOM from "react-dom";
import { ComboBox } from "@progress/kendo-react-dropdowns";
import { filterBy } from "@progress/kendo-data-query";

import { LocalizationProvider, loadMessages } from '@progress/kendo-react-intl';

import enMessages from './../custom_modules/translations/en.json';
import koMessages from './../custom_modules/translations/ko.json';
import frMessages from './../custom_modules/translations/fr.json';

loadMessages(enMessages, 'en-En');
loadMessages(koMessages, 'ko-KO');
loadMessages(frMessages, 'fr-FR');

class KendoCombobox extends React.Component {
  state = {
    data: this.props.Datasource,
    DefaultValue: this.props.DefaultValue,
    disabled: this.props.disabled,
    loading: false
  };

  UNSAFE_componentWillReceiveProps(
    nextProps,
    nextState,
    prevProps,
    prevState,
    nextContext,
    prevContext
  ) {

    if (nextProps.Datasource !== undefined) {
      this.setState({ data: nextProps.Datasource });
    }
    if (nextProps.DefaultValue === undefined) {
      this.setState({ DefaultValue: "" });
    } else {
      if (
        nextProps.DefaultValue !== null &&
        nextProps.DefaultValue[this.props.DisplayMember] !== undefined
      ) {
        nextProps.DefaultValue[
          this.props.DisplayMember
        ] = nextProps.DefaultValue[this.props.DisplayMember].toString();
      }

      this.setState({ DefaultValue: nextProps.DefaultValue });
    }

    this.setState({ disabled: nextProps.disabled });
  }

  onOpen(e) { }
  onClose(e) { }
  onFocus(e) { }
  onBlur(e) { }
  onChange(e) {

    if (e.target.value !== null) {
      if (this.props.ValueMember !== undefined && this.props.DisplayMember !== undefined) {
        e.target.value[this.props.DisplayMember] = e.target.value[
          this.props.DisplayMember
        ].toString();
      }
    }
    this.setState({ DefaultValue: e.target.value });
    this.props.onvaluechange(e.target.value);
  }

  onFilterChange(event) {
    this.setState({
      data: this.filterData(event.filter),
      loading: false
    });
  }

  filterData(filter) {
    let data = [];
    if (this.props.Datasource !== undefined) {
      data = this.props.Datasource.slice();
    }
    return filterBy(data, filter);
  }

  render() {
    let lang = localStorage.getItem("ElnLang");
    let localizeprovider = "en-EN";
    if (lang !== null) {
      localizeprovider = lang + "-" + lang.toUpperCase();
    }

    if (this.props.ValueMember === undefined && this.props.DisplayMember === undefined) {
      return (
        <LocalizationProvider language={localizeprovider}>
          <ComboBox
            id={this.props.id}
            data={this.state.data}
            onOpen={this.onOpen.bind(this)}
            onClose={this.onClose.bind(this)}
            onFocus={this.onFocus.bind(this)}
            onBlur={this.onBlur.bind(this)}
            onChange={this.onChange.bind(this)}
            onFilterChange={this.onFilterChange.bind(this)}
            filterable={true}
            value={this.state.DefaultValue}
            placeholder={this.props.Placeholder}
            disabled={this.state.disabled}
            className={this.props.additionalclass !== undefined ? "clsKenodCombo " + this.props.additionalclass : "clsKenodCombo"}
            popupSettings={{ className: this.props.className ? this.props.className : "clscombopop" }}
          />
        </LocalizationProvider>
      );
    }
    else {

      return (
        <LocalizationProvider language={localizeprovider}>
          <ComboBox
            id={this.props.id}
            data={this.state.data}
            onOpen={this.onOpen.bind(this)}
            onClose={this.onClose.bind(this)}
            onFocus={this.onFocus.bind(this)}
            onBlur={this.onBlur.bind(this)}
            onChange={this.onChange.bind(this)}
            onFilterChange={this.onFilterChange.bind(this)}
            filterable={true}
            dataItemKey={this.props.ValueMember}
            value={this.state.DefaultValue}
            textField={this.props.DisplayMember}
            placeholder={this.props.Placeholder}
            disabled={this.state.disabled}
            className={this.props.additionalclass !== undefined ? "clsKenodCombo " + this.props.additionalclass : "clsKenodCombo"}
            popupSettings={{ className: this.props.className ? this.props.className : "clscombopop" }}
          />
        </LocalizationProvider>
      );

    }
  }
}
export default KendoCombobox;
