import {
  ADD_VIEWSHEET,
  ADD_MODIFIEDSHEET,
  ADD_PARSEDFIELDS,
  UPDATE_DESIGNERFORVIEWSHEET,
  UPDATE_STOPVIEWSHEET,
  REPLACE_STATE,
  UPDATE_KENDOSTATE,
  UPDATE_CONTROLRIGHTS,
  ADD_AUDITOPTION,
  ADD_KENDOSTATE,
  ADD_VIEWCREATION,
  ADD_KENDOSTATECREATE,
  UPDATE_MENU_PROPS,
  UPDATE_KENDOSTATECREATE,
  LOGIN, SORT_DATA, SEARCH_DATA, TOGGLE_MENU, UPDATE_LANG, POST_CRUD, SEARCH_FIELD_DATA, FILE_UPLOAD,
  DETAIL_DATA, DEFAULT_RETURN, LOGOUT, DEFAULT_SITE_CHANGE, REQUEST_FAILURE, UPDATE_MENU_LIST,
  UPDATE_SEARCH_ORDERS,
  UPDATE_SEARCH_TEMPLATES,
  UPDATE_INACTIVE_LOGOUT,
  UPDATE_SHEET_ORDER_ORG_CONTANT,
  UPDATE_INST_PARSE_IDS,
  UPDATE_SHEET_OPEN,
  UPDATE_PROFILE_IMAGE,
  GET_PROFILE_IMAGE,
  INITILIZE_REDUX_STATE,
  UPDATE_SHEET_ORIGINAL_CONTANT,
  UPDATE_PROTOCOL_OPEN_DATAS,
  UPDATE_PROTOCOLORDERS_OPEN_DATAS,
  UPDATE_Celllevel_Audit_Datas
} from "../constants/action-types";
import { initialreduxState } from "./../../custom_functions/Constance.jsx"
const initialState = initialreduxState;

function rootReducer(state = initialState, action) {
  if (action.type === ADD_VIEWSHEET) {
    return Object.assign({}, state, {
      Sheetview: action.payload
    });
  } else if (action.type === ADD_VIEWCREATION) {
    return Object.assign({}, state, {
      SheetCreation: action.payload
    });
  } else if (action.type === ADD_KENDOSTATE) {
    return Object.assign({}, state, {
      KendoSpread: action.payload
    });
  }
  else if (action.type === ADD_KENDOSTATECREATE) {
    return Object.assign({}, state, {
      KendoSpreadCreate: action.payload
    });
  } else if (action.type === ADD_MODIFIEDSHEET) {
    let spreaddatachange = state.Sheetview;
    spreaddatachange.SpreadData.SpreadDatasource = action.payload;
    return Object.assign({}, state, {
      Sheetview: spreaddatachange
    });
  } else if (action.type === ADD_PARSEDFIELDS) {
    let Parsedata = state.sheet;
    Parsedata.ParseFields = action.payload;
    return Object.assign({}, state, {
      sheet: Parsedata
    });
  } else if (action.type === UPDATE_DESIGNERFORVIEWSHEET) {
    let spreaddatachange = state.Sheetview;
    spreaddatachange.SpreadData.IsTreeView = action.payload.IsTreeView;
    spreaddatachange.Draggeddata = action.payload.Draggeddata;
    spreaddatachange.IsShowDesigner = action.payload.IsShowDesigner;
    return Object.assign({}, state, {
      Sheetview: spreaddatachange
    });
  } else if (action.type === UPDATE_STOPVIEWSHEET) {
    let spreaddatachange = state.Sheetview;
    spreaddatachange.SpreadData.stopview = action.payload.SpreadData.stopview;
    spreaddatachange.SpreadData.menuclick = action.payload.SpreadData.menuclick;
    spreaddatachange.Draggeddata = action.payload.Draggeddata;
    spreaddatachange.IsShowDesigner = action.payload.IsShowDesigner;
    spreaddatachange.stopDisabled = action.payload.stopDisabled;
    spreaddatachange.SheetName = action.payload.SheetName;
    spreaddatachange.SheetCode = action.payload.SheetCode;
    spreaddatachange.MaterialSheetCode = action.payload.MaterialSheetCode;
    spreaddatachange.MaterialSheetName = action.payload.MaterialSheetName;
    spreaddatachange.OrderTestcode = action.payload.OrderTestcode;
    spreaddatachange.OrderTestName = action.payload.OrderTestName;
    spreaddatachange.orderid = action.payload.orderid;

    return Object.assign({}, state, {
      Sheetview: spreaddatachange
    });
  } else if (action.type === REPLACE_STATE) {
    if (state.isnotactivelogout) {
      action.payload.isnotactivelogout = state.isnotactivelogout;
    }
    return Object.assign({}, state, action.payload);
  } else if (action.type === UPDATE_KENDOSTATE) {
    return Object.assign({}, state, {
      KendoSpread: action.payload
    });
  }
  else if (action.type === UPDATE_KENDOSTATECREATE) {
    return Object.assign({}, state, {
      KendoSpreadCreate: action.payload
    });
  } else if (action.type === UPDATE_CONTROLRIGHTS) {
    let rights = state.Menu;
    rights.Controlrights = action.payload;
    return Object.assign({}, state, {
      Menu: rights
    });
  } else if (action.type === ADD_AUDITOPTION) {
    let Option = state.audit;
    Option.auditoption = action.payload;
    return Object.assign({}, state, {
      audit: Option
    });
  }
  else if (action.type === LOGIN) {
    return {
      ...state,
      userData: { ...action.payload.userData }
    }
  }
  else if (action.type === LOGOUT) {
    //console.log("logout reducer");
    return {
      ...initialState,
      ...action.payload
    }
  }
  else if (action.type === POST_CRUD) {
    //console.log("POST_CRUD response in reducer");
    return {
      ...state,
      masterData: {
        ...state.masterData,
        originalData: action.payload.originalData,
        selectedItem: action.payload.selectedItem,
        initialData: action.payload.initialData
      },
      inputParam: action.payload.inputParam,
      navigation: action.payload.navigation,
      fieldDetail: action.payload.fieldDetail,
      updateData: action.payload.selectedItem,
      visibility: action.payload.inputParam.hideMenu,
      transVisibility: action.payload.inputParam.transVisibility,
      exportVisibility: action.payload.inputParam.exportVisibility,
      operation: action.payload.inputParam.operation,
      dataGridStatus: "",
      filterColumnName: "",
      searchColumnData: undefined,
      auditStatus: "",
      rawDataText: "",
      fluidPage: false
    }
  }
  else if (action.type === DEFAULT_SITE_CHANGE) {
    return {
      ...state,
      masterData: {
        ...state.masterData,
        originalData: action.payload.originalData,
        selectedItem: action.payload.selectedItem,
        initialData: action.payload.initialData
      },
      inputParam: action.payload.inputParam,
      navigation: action.payload.navigation,
      fieldDetail: action.payload.fieldDetail,
      updateData: action.payload.selectedItem,
      visibility: action.payload.inputParam.hideMenu,
      transVisibility: action.payload.inputParam.transVisibility,
      exportVisibility: action.payload.inputParam.exportVisibility,
      operation: action.payload.inputParam.operation,
      dataGridStatus: "",
      filterColumnName: "",
      searchColumnData: undefined
    }
  }
  else if (action.type === SORT_DATA) {
    return {
      ...state,
      updateData: action.payload.updateData,
      dataGridStatus: "",

      masterData: {
        ...state.masterData,
        initialData: action.payload.initialData,
        sortColumn: action.payload.sortColumn,
        sortType: action.payload.sortType,
        filterColumnName: action.payload.filterColumnName
      }
    }
  }
  else if (action.type === SEARCH_DATA) {
    return {
      ...state,
      updateData: action.payload.updateData,
      dataGridStatus: action.payload.dataGridStatus,
      masterData: {
        ...state.masterData,
        initialData: action.payload.initialData,
        selectedItem: action.payload.selectedItem,
        filterColumnName: action.payload.filterColumnName

      },
      searchColumnData: action.payload.searchColumnData
    }
  }
  else if (action.type === TOGGLE_MENU) {
    return {
      ...state,
      navigation: action.payload.navigation,
      visibility: action.payload.visibility,
      transVisibility: action.payload.transVisibility,
      exportVisibility: action.payload.exportVisibility,
      loginStatus: "",
      inputParam: {
        ...state.inputParam,
        submenu: action.payload.subMenu
      },
      rawDataText: "",
      instrawdataurl: "",
      fluidPage: action.payload.fluidPage,
      manipulationStatus: "",
      dataGridStatus: ""
    }
  }
  else if (action.type === UPDATE_LANG) {
    return {
      ...state,
      userData: {
        ...state.userData,
        language: action.payload
      }
    }
  }
  else if (action.type === SEARCH_FIELD_DATA) {
    return {
      ...state,
      ...action.payload,
      status: ""
    }
  }
  else if (action.type === FILE_UPLOAD) {
    return {
      ...state,
      // updateData : {...state.updateData,
      //    ...action.payload}
      ...action.payload
    }
  }
  else if (action.type === DETAIL_DATA) {
    return {
      ...state,
      masterData: {
        ...state.masterData,
        detailedData: action.payload.detailedData
      },
      updateData: action.payload.updateData,
      dataGridStatus: ""
    }
  }
  else if (action.type === DEFAULT_RETURN) {
    return {
      ...state,
      ...action.payload
    }
  }
  else if (action.type === REQUEST_FAILURE) {
    return {
      ...state,
      error: action.payload
    }
  } else if (action.type === UPDATE_MENU_LIST) {
    return {
      ...state,
      ...action.payload
    }
  } else if (action.type === UPDATE_SEARCH_ORDERS) {
    return {
      ...state,
      ...action.payload
    }
  } else if (action.type === UPDATE_SEARCH_TEMPLATES) {
    return {
      ...state,
      ...action.payload
    }
  } else if (action.type === UPDATE_MENU_PROPS) {
    return {
      ...state,
      ...action.payload
    }
  }
  else if (action.type === UPDATE_INACTIVE_LOGOUT) {
    return Object.assign({}, state, {
      isnotactivelogout: action.payload
    });
  } else if (action.type === UPDATE_SHEET_ORDER_ORG_CONTANT) {
    return Object.assign({}, state, {
      sheetorgdata: action.payload
    });
  }
  else if (action.type === UPDATE_SHEET_OPEN) {
    return Object.assign({}, state, {
      sheetopne: action.payload
    });
  }
  else if (action.type === UPDATE_INST_PARSE_IDS) {
    return Object.assign({}, state, {
      instrumentidparser: action.payload
    });
  }
  else if (action.type === UPDATE_PROFILE_IMAGE) {
    let profile = state.profileimages;
    let index = state.profileimages.findIndex(obj => obj.usercode === action.payload.usercode);
    if (index <= -1) {
      profile.push(action.payload);
    }
    return Object.assign({}, state, {
      ...state,
      ...{ profileimages: profile }
    });
  }
  else if (action.type === GET_PROFILE_IMAGE) {
    let profile = state.profileimages.find(obj => obj.usercode === action.payload);
    return profile;
  }
  else if (action.type === INITILIZE_REDUX_STATE) {
    return action.payload;
  } else if (action.type === UPDATE_SHEET_ORIGINAL_CONTANT) {
    return Object.assign({}, state, {
      sheettemplateorgdata: action.payload
    });
  } else if (action.type === UPDATE_PROTOCOL_OPEN_DATAS) {
    return Object.assign({}, state, {
      OpenprotocolDatas: action.payload
    });
  }
  else if (action.type === UPDATE_PROTOCOLORDERS_OPEN_DATAS) {
    return Object.assign({}, state, {
      OpenprotocolordersDatas: action.payload
    });
  }
  else if (action.type === UPDATE_Celllevel_Audit_Datas) {
    return Object.assign({}, state, {
      // Celllevel_Auditdatas: [...state.Celllevel_Auditdatas, action.payload.passObj]
      Celllevel_Auditdatas: action.payload
    });
  }

  return state;
}
export default rootReducer;
