import React from "react";
import {
  GridColumnMenuSort,
  GridColumnMenuFilter,
  GridColumnMenuItemGroup,
  GridColumnMenuItem,
  GridColumnMenuItemContent,
  GridColumnMenuCheckboxFilter,
} from "@progress/kendo-react-grid";
import { LocalizationProvider, loadMessages } from "@progress/kendo-react-intl";
import { withTranslation } from "react-multi-lang";
import $ from "jquery";
import enMessages from "./../custom_modules/translations/en.json";
import koMessages from "./../custom_modules/translations/ko.json";
import frMessages from "./../custom_modules/translations/fr.json";

import {
  Customordercells,
  Customprotocolordercells,
  Customprotocolcells,
} from "./CustomDetailviewCell";
import CustomDetailviewCell from "./CustomDetailviewCell.jsx";

import {
  Shareorder,
  Unshareorder,
  Shareorderforprotocol,
  ShareforprotocolTemplate,
  ShareforsheetTemplate,
  UnshareforsheetTemplate,
  UnshareforprotocolTemplate,
  Unshareforprotocolorder,

} from "../custom_functions/ManipulationFunction";
import { withAlert } from "react-alert";
import { faAlignRight } from "@fortawesome/free-solid-svg-icons";
import { isMultitenant } from "../custom_functions/Constance";
import { Button } from "react-bootstrap";

loadMessages(enMessages, "en-En");
loadMessages(koMessages, "ko-KO");
loadMessages(frMessages, "fr-FR");

class CustomColumnMenu extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: this.props.data,
      columnsExpanded: true,
    };
  }

  onToggleColumn = (item) => {
    let data = this.props.data;
    data.map((dataItem) => {
      if (dataItem[this.props.primaryKey] === item[this.props.primaryKey]) {
        dataItem.filterChecked
          ? (dataItem.filterChecked = false)
          : (dataItem.filterChecked = true);
      }
      return item;
    });

    this.setState({
      data: data,
    });
  };

  onReset = (event) => {
    event.preventDefault();
    this.props.onReset();
    if (this.props.onCloseMenu) {
      this.props.onCloseMenu();
    }
  };

  onSubmit = (event) => {
    if (event) {
      event.preventDefault();
    }
    this.props.onColumnsSubmit(this.state.data);
    if (this.props.onCloseMenu) {
      this.props.onCloseMenu();
    }
  };

  render() {
    let lang = localStorage.getItem("ElnLang");
    let localizeprovider = "en-EN";
    if (lang !== null) {
      localizeprovider = lang + "-" + lang.toUpperCase();
    }
    return (
      <div>
        <LocalizationProvider language={localizeprovider}>
          <GridColumnMenuSort {...this.props} />
          <GridColumnMenuItemGroup>
            <GridColumnMenuItem
              title={this.props.title}
              iconClass={"k-i-columns"}
              onClick={this.onMenuItemClick}
            />
            <GridColumnMenuItemContent show={this.state.columnsExpanded}>
              <div className={"k-column-list-wrapper"}>
                <form onSubmit={this.onSubmit} onReset={this.onReset}>
                  <div className={"k-column-list"}>
                    {this.state.data.map((item, idx) => (
                      <div key={idx} className={"k-column-list-item"}>
                        <span>
                          <input
                            id={`column-visiblity-show-
                            
                            {idx}`}
                            className="k-checkbox"
                            type="checkbox"
                            readOnly={true}
                            checked={item.filterChecked}
                            onClick={() => {
                              this.onToggleColumn(item);
                            }}
                          />
                          <label
                            htmlFor={`column-visiblity-show-${idx}`}
                            className="k-checkbox-label"
                            style={{ userSelect: "none" }}
                          >
                            {item[this.props.field]}
                          </label>
                        </span>
                      </div>
                    ))}
                  </div>
                  <div className={"k-columnmenu-actions"}>
                    <button type={"reset"} className={"k-button"}>
                      Reset
                    </button>
                    <button className={"k-button k-primary"}>Save</button>
                  </div>
                </form>
              </div>
            </GridColumnMenuItemContent>
          </GridColumnMenuItemGroup>
        </LocalizationProvider>
      </div>
    );
  }
}

class ColumnMenu extends React.Component {
  render() {
    return (
      <div className="grid-popup-filter">
        <GridColumnMenuSort {...this.props} />
        {/* <GridColumnMenuFilter {...this.props} /> */}
        <GridColumnMenuFilter  {...this.props} expanded={true} />
        {/* <GridColumnMenuFilter {...this.props} expanded={true}  /> */}
      </div>
    );
  }
}

class ColumnMenuCheckboxFilter extends React.Component {
  render() {
    return (
      <div>
        <GridColumnMenuCheckboxFilter
          {...this.props}
          data={this.props.data !== undefined ? this.props.data : []}
          expanded={true}
        />
      </div>
    );
  }
}

class CustomCell extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.dataItem[this.props.field],
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      value: nextProps.dataItem[this.props.field],
    });
  }

  handleOptionChange(e) {
    this.setState({ value: e.target.value === "0" ? "1" : "0" });
    this.props.onchange(
      this.props.dataItem,
      this.props.field,
      e.target.value === "0" ? "1" : "0"
    );
  }

  handleOptionChangeboolean(e) {
    this.setState({ value: e.target.value === "true" ? false : true });
    this.props.onchange(
      this.props.dataItem,
      this.props.field,
      e.target.value === "true" ? false : true
    );
  }

  render() {
    if (typeof this.state.value === "number") {
      return (
        <td>
          {
            <input
              type="Checkbox"
              value={
                this.state.value !== null && this.state.value !== undefined
                  ? this.state.value
                  : ""
              }
              checked={
                this.state.value !== null &&
                  this.state.value !== undefined &&
                  this.state.value === 1
                  ? true
                  : false
              }
              onChange={this.handleOptionChange.bind(this)}
              disabled={false}
              id={this.props.dataItem !== undefined && this.props.field !== undefined && this.props.dataItem !== null && this.props.dataItem.orderno !== undefined && this.props.field === "sallow" ? this.props.dataItem.orderno + "_" + this.props.dataIndex : ""}
            ></input>
          }
        </td>
      );
    } else if (typeof this.state.value === "boolean") {
      return (
        <td>
          {
            <input
              type="Checkbox"
              value={
                this.state.value !== null && this.state.value !== undefined
                  ? this.state.value
                  : ""
              }
              checked={
                this.state.value !== null &&
                  this.state.value !== undefined &&
                  this.state.value === true
                  ? true
                  : false
              }
              onChange={this.handleOptionChangeboolean.bind(this)}
              disabled={false}
              id={this.props.dataItem !== undefined && this.props.field !== undefined && this.props.dataItem !== null && this.props.dataItem.orderno !== undefined && this.props.field === "sallow" ? this.props.dataItem.orderno + "_" + this.props.dataIndex : ""}
            ></input>
          }
        </td>
      )
    }
    else {
      return (
        <td>
          {this.state.value !== null &&
            this.state.value !== undefined &&
            this.state.value.trim() === "NA" ? (
            "NA"
          ) : (
            <input
              type="Checkbox"
              value={
                this.state.value !== null && this.state.value !== undefined
                  ? this.state.value.trim()
                  : ""
              }
              checked={
                this.state.value !== null &&
                  this.state.value !== undefined &&
                  this.state.value.trim() === "1"
                  ? true
                  : false
              }
              onChange={this.handleOptionChange.bind(this)}
              id={this.props.dataItem !== undefined && this.props.field !== undefined && this.props.dataItem !== null && this.props.dataItem.orderno !== undefined && this.props.field === "sallow" ? this.props.dataItem.orderno + "_" + this.props.dataIndex : ""}
              disabled={false}
            ></input>
          )}
        </td>
      );
    }
  }
}

class ProtocolorderunshareViewCell extends React.Component {
  onbuttonclick(e) {
    this.props.onclick(
      JSON.parse(e.target.getAttribute("value")),
      this.props.field
    );
  }

  onbuttonclick1(values) {
    this.props.onclick(JSON.parse(values), this.props.field);
  }

  render() {
    return (
      <td>
        {this.props.dataItem[this.props.keyfield] !== undefined ? (
          <div className="actionsBtn 3 align-items-center ">
            {/* {$.session.get("Username") === "Administrator" ? (
              ""
            ) : (
              // (this.props.dataItem.canuserprocess !== undefined &&
              //   this.props.dataItem.canuserprocess) ||
              //   this.state.filetype === 0 ? (
              <div className="processOrderBtn 2 rejectColm">
                <CustomButtonCell
                  {...this.props}
                  keyfield={this.props.keyfield}
                  onclick={this.props.onclick}
                />
              </div>
            )} */}

            <Unshareforprotocolorder
              {...this.props}
              keyfield={this.props.keyfield}
              isshared={false}
              // props={this.props}
              passbackorder={this.props.passbackorder}
            // details={this.props.Columns[i].details}
            />
          </div>
        ) : (
          ""
        )}
      </td>
    );
  }
}
const ProtocolorderunshareViewCell1 = withAlert()(
  withTranslation(ProtocolorderunshareViewCell)
);

class Protocolactioncel extends React.Component {

  onbuttonclick(e) {
    this.props.onclick(
      JSON.parse(e.target.getAttribute("value")),
      this.props.field
    );
  }

  onbuttonclick1(values) {
    this.props.onclick(JSON.parse(values), this.props.field);
  }

  render() {
    return (
      <td>
        {this.props.dataItem[this.props.keyfield] !== undefined ? (
          <div className="actionsBtn 4 align-items-center ">
            {/* {$.session.get("Username") === "Administrator" ? (
              ""
            ) : ( */}

            <div className="processOrderBtn 3 rejectColm">
              <CustomButtonCell
                {...this.props}
                keyfield={this.props.keyfield}
                onclick={this.props.onclick}
              />
            </div>
            {/* )} */}
            {/* {$.session.get("Username") === "Administrator" ? (
                ""
              ) : */}
            {this.props.protocolscreen ? (
              <Customprotocolcells
                {...this.props}
                keyfield={this.props.keyfield}
              />
            ) : (
              ""
            )}

            {/* <ShareforprotocolTemplate
                      {...this.props}
                      keyfield={this.props.keyfield}
                      isshared={false}
                    // props={this.props}
                    // text={this.props.Columns[i].header}
                    // details={this.props.Columns[i].details}
                    // passbackorder={this.props.Getorderonunshare}
                    /> */}
            {/* {$.session.get("Username") === "Administrator" ? (
                  ""
                ) : ( */}
            <ShareforprotocolTemplate
              {...this.props}
              keyfield={this.props.keyfield}
              isshared={false}
              passbackorder={this.props.passbackorder}
            />
            {/* )} */}
          </div>
        ) : (
          ""
        )}
      </td>
    );
  }
}
const Protocolactioncell = withAlert()(withTranslation(Protocolactioncel));

class parseractioncel extends React.Component {

  onbuttonclick(e) {
    //   this.props.downloadparserattachment(
    //  this.props.dataItem.filename
    //   );
    this.props.dataItem.tenantid = $.session.get("tenantid");
    let passobj = this.props.dataItem;
    // passobj.tenantid =  $.session.get("tenantid")
    let urlattach;
    console.log(this)
    this.ELNurl = document.getElementById("ELNname").value;

    if (isMultitenant === 1) {
      urlattach = "" + this.ELNurl + "/Instrument/parserattachment";
    } else {
      urlattach = "" + this.ELNurl + "/Instrument/sqlparserattachment";
    }

    fetch(urlattach, {
      method: "POST",
      body: JSON.stringify(passobj),
      headers: new Headers({
        Authorization: $.session.get("Token"),
        "X-TenantID": $.session.get("tenantid"),
        Accept: "application/json",
        "Content-Type": "application/json",
      }),
    }).then((response) => {
      const filename = this.props.dataItem.filename;
      response.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = filename;
        a.click();
        this.setState({ currentFile: "", loader: <div></div> });
      });
      this.props.alert.success(filename + " downloaded successfully");

    });
  }

  render() {
    return (
      <td>
        <div class="container">
          <div class="row justify-content-start">
            <div class="col-9">
              {this.props.dataItem.filename}
            </div>
            <div class="col-3">
              <button
                className='btn_white_bg'
                primary={true}
                look="outline"
                title="download file"
                icon='download'
                onClick={() => this.onbuttonclick()}
              >
                <i class="fas fa-download"></i>
              </button>

            </div>
          </div>

        </div>


      </td>

    );
  }
}
const Parseractioncell = withAlert()(withTranslation(parseractioncel));


class Sheetactioncel extends React.Component {

  onbuttonclick(e) {
    this.props.onclick(
      JSON.parse(e.target.getAttribute("value")),
      this.props.field
    );
  }

  onbuttonclick1(values) {
    this.props.onclick(JSON.parse(values), this.props.field);
  }

  render() {
    return (
      <td>
        {this.props.dataItem[this.props.keyfield] !== undefined ? (
          <div className="actionsBtn 5 align-items-center ">
            {/* {$.session.get("Username") === "Administrator" ? (
              ""
            ) : ( */}
            <CustomButtonCell
              {...this.props}
              keyfield={this.props.keyfield}
              onclick={this.props.onclick}
            />
            {/* )} */}

            {/* {$.session.get("Username") === "Administrator" ? (
              ""
            ) : ( */}
            <CustomDetailviewCell
              {...this.props}
              keyfield={this.props.keyfield}
              //  keyfield={this.props.primaryKey}
              text={this.props.text}
              details={this.props.details}
            />
            {/* )} */}

            {/* {$.session.get("Username") === "Administrator" ? (
              ""
            ) : ( */}
            <ShareforsheetTemplate
              {...this.props}
              keyfield={this.props.keyfield}
              isshared={false}
              passbackorder={this.props.passbackorder}
            />
            {/* )} */}
          </div>
        ) : (
          ""
        )}
      </td>
    );
  }
}
const Sheetactioncell = withAlert()(withTranslation(Sheetactioncel));

class InventoryActionCell extends React.Component {

  render() {
    return (
      <td>
        {this.props.dataItem[this.props.keyfield] !== undefined ? (
          <div className="actionsBtn 5 align-items-center" style={{ display: 'flex', alignItems: 'center' }}>
            {/* <Button
              id={`user-${this.props.dataItem[this.props.keyfield]}`}
              className="grid-icons"
              alt="user"
              data-placement="top"
              title={"Show Transaction"}
              onClick={(e)=>this.props.onclick(this.props.dataItem)}
              style={{ marginRight: '10px' }}
            >
              <i className="far fa-eye" />
            </Button> */}

            <Button
              id={`user-${this.props.dataItem[this.props.keyfield]}`}
              className="grid-icons"
              alt="user"
              data-placement="top"
              title={"Detail"}
              onClick={(e)=>this.props.onDetailClick(this.props.dataItem)}
              style={{ marginRight: '10px' }}
            >
              <i className="fas fa-eye" />
            </Button>
            <Button
              id={`user-${this.props.dataItem[this.props.keyfield]}`}
              className="grid-icons"
              alt="user"
              data-placement="top"
              title={"File"}
              onClick={(e)=>this.props.onFileViewClick(this.props.dataItem)}
            >
              <i className="fas fa-paperclip" />
              <span class="badge rounded-pill bg-light text-dark notification-badge ">{this.props.dataItem.lsOrderattachments !== undefined && this.props.dataItem.lsOrderattachments !== null 
              && this.props.dataItem.lsOrderattachments.length > 0 ? this.props.dataItem.lsOrderattachments.length : ""}</span>
            </Button>            
          </div>
        ) : (
          ""
        )}
      </td>
    );
  }
}
const InventoryCell = withAlert()(withTranslation(InventoryActionCell));

class EquipmentActionCell extends React.Component {

  render() {
    return (
      <td>
        {this.props.dataItem[this.props.keyfield] !== undefined ? (
          <div className="actionsBtn 5 align-items-center" style={{ display: 'flex', alignItems: 'center' }}>
            {/* <Button
              id={`user-${this.props.dataItem[this.props.keyfield]}`}
              className="grid-icons"
              alt="user"
              data-placement="top"
              title={"Show Transaction"}
              onClick={(e)=>this.props.onclick(this.props.dataItem)}
              style={{ marginRight: '10px' }}
            >
              <i className="far fa-eye" />
            </Button> */}

            <Button
              id={`user-${this.props.dataItem[this.props.keyfield]}`}
              className="grid-icons"
              alt="user"
              data-placement="top"
              title={"Detail"}
              onClick={(e)=>this.props.onDetailClick(this.props.dataItem)}
              style={{ marginRight: '10px' }}
            >
              <i className="fas fa-eye" />
            </Button>
            {/* <Button
              id={`user-${this.props.dataItem[this.props.keyfield]}`}
              className="grid-icons"
              alt="user"
              data-placement="top"
              title={"File"}
              onClick={(e)=>this.props.onFileViewClick(this.props.dataItem)}
            >
              <i className="fas fa-paperclip" />
              <span class="badge rounded-pill bg-light text-dark notification-badge ">{this.props.dataItem.lsOrderattachments !== undefined && this.props.dataItem.lsOrderattachments !== null 
              && this.props.dataItem.lsOrderattachments.length > 0 ? this.props.dataItem.lsOrderattachments.length : ""}</span>
            </Button>             */}
          </div>
        ) : (
          ""
        )}
      </td>
    );
  }
}
const EquipmentCell = withAlert()(withTranslation(EquipmentActionCell));

class MaterialActionCell extends React.Component {

  render() {
    return (
      <td>
        {this.props.dataItem[this.props.keyfield] !== undefined ? (
          <div className="actionsBtn 5 align-items-center" style={{ display: 'flex', alignItems: 'center' }}>
            {/* <Button
              id={`user-${this.props.dataItem[this.props.keyfield]}`}
              className="grid-icons"
              alt="user"
              data-placement="top"
              title={"Detail"}
              onClick={(e)=>this.props.onclick(this.props.dataItem)}
              style={{ marginRight: '10px' }}
            >
              <i className="fas fa-info" />
            </Button> */}
            <Button
              id={`user-${this.props.dataItem[this.props.keyfield]}`}
              className="grid-icons"
              alt="user"
              data-placement="top"
              title={"File"}
              onClick={(e)=>this.props.onFileViewClick(this.props.dataItem)}
            >
              <i className="fas fa-paperclip" />
              <span class="badge rounded-pill bg-light text-dark notification-badge">{this.props.dataItem.lsOrderattachments !== undefined && this.props.dataItem.lsOrderattachments !== null 
              && this.props.dataItem.lsOrderattachments.length > 0 ? this.props.dataItem.lsOrderattachments.length : ""}</span>
            </Button> 
          </div>
        ) : (
          ""
        )}
      </td>
    );
  }
}
const MaterialCell = withAlert()(withTranslation(MaterialActionCell));

class MaterialNameActionCell extends React.Component {

  onbuttonclick(e) {
    this.props.onclick(
      JSON.parse(e.target.getAttribute("value")),
      this.props.field
    );
  }

  render() {
    return (
      <td>
        {this.props.dataItem[this.props.keyfield] !== undefined ? (
          <div className="actionsBtn 5">
            <div className="process">
              <button
                className={"clsopenbutton white-bg-btn"}
                value={JSON.stringify(this.props.dataItem)}
                onClick={this.onbuttonclick.bind(this)}
                title=""
              >
                {this.props.dataItem[this.props.field]}
              </button>
            </div>
          </div>
        ) : (
          ""
        )}
      </td>
    );
  }
}
const MaterialNameAction = withAlert()(withTranslation(MaterialNameActionCell));

class Protocolactionsharbycel extends React.Component {
  onbuttonclick(e) {
    this.props.onclick(
      JSON.parse(e.target.getAttribute("value")),
      this.props.field
    );
  }

  onbuttonclick1(values) {
    this.props.onclick(JSON.parse(values), this.props.field);
  }

  render() {
    return (
      <td>
        {this.props.dataItem[this.props.keyfield] !== undefined ? (
          <div className="actionsBtn 6 align-items-center ">
            {
              $.session.get("Username") === "Administrator" ? (
                ""
              ) : (
                // (this.props.dataItem.canuserprocess !== undefined &&
                //   this.props.dataItem.canuserprocess) ||
                //   this.state.filetype === 0 ? (
                <div className="processOrderBtn 4 rejectColm">
                  <CustomButtonCell
                    {...this.props}
                    keyfield={this.props.keyfield}
                    onclick={this.props.onclick}
                  />

                  <UnshareforprotocolTemplate
                    {...this.props}
                    keyfield={this.props.keyfield}
                    isshared={false}
                    passbackorder={this.props.passbackorder}
                  />
                </div>
              )
              // ) : (
              //   ""
              // )
            }
          </div>
        ) : (
          ""
        )}
      </td>
    );
  }
}
const Protocolactionsharbycell = withAlert()(withTranslation(Protocolactionsharbycel));


class Sheetactionsharebycells extends React.Component {
  onbuttonclick(e) {
    this.props.onclick(
      JSON.parse(e.target.getAttribute("value")),
      this.props.field
    );
  }

  onbuttonclick1(values) {
    this.props.onclick(JSON.parse(values), this.props.field);
  }

  render() {
    return (
      <td>
        {this.props.dataItem[this.props.keyfield] !== undefined ? (
          <div className="actionsBtn 7 align-items-center ">
            {$.session.get("Username") === "Administrator" ? (
              ""
            ) : (
              <div className="processOrderBtn 5 rejectColm">
                <CustomButtonCell
                  {...this.props}
                  keyfield={this.props.keyfield}
                  onclick={this.props.onclick}
                />

                <UnshareforsheetTemplate
                  {...this.props}
                  keyfield={this.props.primaryKey}
                  isshared={false}
                  props={this.props}
                  Getorderonunshare={this.props.Getorderonunshare}
                />
              </div>
            )}
          </div>
        ) : (
          ""
        )}
      </td>
    );
  }
}

const Sheetactionsharebycell = withAlert()(withTranslation(Sheetactionsharebycells));


class CustomButtonCell extends React.Component {
  onbuttonclick(e) {
    this.props.onclick(
      this.props.dataItem,
      this.props.dataItem
    );
  }

  onbuttonclick1(values) {
    this.props.onclick(JSON.parse(values), this.props.field);
  }

  render() {
    return (
      // <td>
      //   {
      // this.props.dataItem.protocolmastercode === undefined &&
      // this.props.dataItem.sharedbytoprotocolordercode === undefined &&
      // this.props.dataItem.sharetoprotocolordercode === undefined

      this.props.dataItem !== undefined && this.props.dataItem[this.props.keyfield] !== undefined ? (
        this.props.keyfield !== "protocolmastercode" ? (
          <div className="actionsBtn 9 align-items-center rejectColm">
            {this.props.field === "Open" ? (
              <div className="open">
                <button
                  // className="clsopenbutton  btn-outline-primary"
                  className={this.props.dataItem.ordercancell !== undefined && this.props.dataItem.ordercancell === 1 ? "clsopenbutton  btn_white_bg" : "clsopenbutton  btn-outline-primary"}
                  hidden={
                    this.props.dataItem.protocolordercode !== undefined && this.props.dataItem.canuserprocess === false ? "disabled" : ""
                  }
                  disabled={this.props.dataItem.ordercancell !== undefined && this.props.dataItem.ordercancell === 1 ? true : false}
                  value={JSON.stringify(this.props.dataItem)}
                  onClick={this.onbuttonclick.bind(this)}
                  title=""
                >
                  {/* <i
                    className="fas fa-box-open mg-r-xsm"
                    aria-hidden="true"
                  ></i> */}

                  <span>{this.props.dataItem.canuserprocess !== undefined &&
                    this.props.dataItem.canuserprocess && this.props.dataItem.orderflag !== undefined
                    && this.props.dataItem.orderflag.trim() === "N" && this.props.dataItem.filefor !== "ASO" ? this.props.t("Orders.Sheetorders.IDS_LBL_PROCESSORDER"): this.props.t("Orders.Sheetorders.IDS_LBL_OPEN")}</span>
                </button>
              </div>
            ) : (
              <div className="process">
                <button
                  className={this.props.dataItem.ordercancell !== undefined && this.props.dataItem.ordercancell === 1 ? "clsopenbutton  btn_white_bg" : "clsopenbutton  btn-outline-primary"}
                  disabled={this.props.dataItem.ordercancell !== undefined && this.props.dataItem.ordercancell === 1 ? true : false}
                  value={JSON.stringify(this.props.dataItem)}
                  onClick={this.onbuttonclick.bind(this)}
                  title=""
                >
                  {this.props.dataItem.canuserprocess !== undefined && this.props.dataItem.canuserprocess && this.props.dataItem.orderflag !== undefined
                    && this.props.dataItem.orderflag.trim() === "N" && this.props.dataItem.filefor !== "ASO" ? this.props.t("Orders.Sheetorders.IDS_LBL_PROCESSORDER") : this.props.t("Orders.Sheetorders.IDS_LBL_OPEN")}
                </button>
              </div>
            )}
            {this.props.screen === "Sheet"
              ? this.props.dataItem?.order?.approvelstatus && this.props.dataItem.order.orderflag?.trim() === "R" && this.props.dataItem.order.approvelstatus === 3
                ? <div className="reject"><span className="dot"></span></div>
                : null
              : this.props.dataItem.rejected !== undefined && this.props.dataItem.orderflag?.trim() === "R" && this.props.dataItem.rejected === 1
                ? <div className="reject"><span className="dot"></span></div>
                : null
            }
            {/* {this.props.dataItem.rejected !== undefined && this.props.dataItem.orderflag !== undefined
              && this.props.dataItem.orderflag.trim() === "R" ? (this.props.dataItem.rejected === 1 ? (
                <div className="reject">
                  <span className="dot"></span>
                </div>
              ) : (
                ""
              )
            ) : (
              ""
            )} */}

            {/* {this.props.dataItem.rejected === undefined &&
              this.props.dataItem.assignedto !== undefined ? (


              this.props.dataItem.orderflag.trim() === "R" ? (
                <div className="reject">
                  <span className="dotCompleted"></span>
                </div>
              ) : (
                <div className="reject">
                  <span className="dotPending"></span>
                </div>
              )
            ) : (
              ""
            )} */}
          </div>
        ) : (
          <div className="protocol">
            {this.props.field === "Open" ? (
              <div className="process">
                <button
                  className="clsopenbutton  btn-outline-primary"
                  value={JSON.stringify(this.props.dataItem)}
                  onClick={() =>
                    this.onbuttonclick1(JSON.stringify(this.props.dataItem))
                  }
                  data-bs-toggle="tooltip"
                  data-bs-placement="bottom"
                  title="Open"
                >
                  {/* <i className="fas fa-box-open 1" aria-hidden="true"></i> */}
                  <span> {this.props.field}</span>
                </button>
              </div>
            ) : (
              <div className="process">
                <button
                  className="clsopenbutton  btn-outline-primary"
                  value={JSON.stringify(this.props.dataItem)}
                  onClick={() =>
                    this.onbuttonclick1(JSON.stringify(this.props.dataItem))
                  }
                  data-bs-toggle="tooltip"
                  data-bs-placement="bottom"
                  title="Open"
                >
                  {/* <i className="fas fa-box-open 2" aria-hidden="true"></i> */}
                  <span> {this.props.field}</span>
                </button>
              </div>
            )}
          </div>
        )
      ) : (
        ""
      )

      // <span className="clsopenbutton" onClick={() => this.onbuttonclick1(JSON.stringify(this.props.dataItem))} data-bs-toggle="tooltip" data-bs-placement="bottom" title="Tooltip on bottom">
      //   <i className="fas fa-box-open" aria-hidden="true" ></i>
      //   {this.props.field}
      // </span>

      // <button className="clsopenbutton" value={JSON.stringify(this.props.dataItem)} onClick={this.onbuttonclick.bind(this)}>{this.props.field}</button>
      //   }
      // </td>
    );
  }
}


class MyCustomButtonCell extends React.Component {
  onbuttonclick(e) {
    this.props.onclick(
      JSON.parse(e.target.getAttribute("value")),
      this.props.field
    );
  }

  onbuttonclick1(values) {
    this.props.onclick(JSON.parse(values), this.props.field);
  }

  render() {
    return (
      <td>
        {this.props.dataItem[this.props.keyfield] !== undefined ? (
          this.props.keyfield !== "protocolmastercode" ? (
            <div className="actionsBtn 8 align-items-center rejectColm">
              {this.props.field === "Open" ? (
                <div className="open">
                  <button
                    className="clsopenbutton  btn-outline-primary"
                    hidden={
                      this.props.dataItem.protocolordercode !== undefined &&
                        this.props.dataItem.canuserprocess === false
                        ? "disabled"
                        : ""
                    }
                    value={JSON.stringify(this.props.dataItem)}
                    onClick={this.onbuttonclick.bind(this)}
                  >
                    {this.props.field}
                  </button>
                </div>
              ) : (
                <div className="process">
                  <button
                    className="clsopenbutton  btn-outline-primary"
                    hidden={
                      this.props.dataItem.protocolordercode !== undefined &&
                        this.props.dataItem.canuserprocess === false
                        ? "disabled"
                        : ""
                    }
                    value={JSON.stringify(this.props.dataItem)}
                    onClick={this.onbuttonclick.bind(this)}
                  >
                    {this.props.field}
                  </button>
                </div>
              )}

              {this.props.dataItem.rejected !== undefined ? (
                this.props.dataItem.rejected === 1 ? (
                  <div className="reject">
                    {/* <style allign={faAlignRight}></style> */}
                    <span className="dot"></span>
                  </div>
                ) : (
                  ""
                )
              ) : (
                ""
              )}

              {this.props.dataItem.rejected === undefined &&
                this.props.dataItem.assignedto !== undefined ? (
                // <div className="reject">
                //   <style allign={faAlignRight}></style>
                //   <span class="completeddot"></span>
                // </div >

                this.props.dataItem.orderflag.trim() === "R" ? (
                  <div className="reject">
                    {/* <style allign={faAlignRight}></style> */}
                    <span className="dotCompleted"></span>
                  </div>
                ) : (
                  // <span class="dotPending"></span>
                  <div className="reject">
                    {/* <style allign={faAlignRight}></style> */}
                    <span className="dotPending"></span>
                  </div>
                )
              ) : (
                ""
              )}
            </div>
          ) : (
            <div className="protocol">
              {this.props.field === "Open" ? (
                <div className="open">
                  <button
                    className="clsopenbutton  btn-outline-primary"
                    value={JSON.stringify(this.props.dataItem)}
                    onClick={() =>
                      this.onbuttonclick1(JSON.stringify(this.props.dataItem))
                    }
                    data-bs-toggle="tooltip"
                    data-bs-placement="bottom"
                    title="Open"
                  >
                    {/* <i className="fas fa-box-open 3" aria-hidden="true"></i> */}
                    <span> {this.props.field}</span>
                  </button>
                </div>
              ) : (
                <div className="process">
                  <button
                    className="clsopenbutton  btn-outline-primary"
                    value={JSON.stringify(this.props.dataItem)}
                    onClick={() =>
                      this.onbuttonclick1(JSON.stringify(this.props.dataItem))
                    }
                    data-bs-toggle="tooltip"
                    data-bs-placement="bottom"
                    title="Open"
                  >
                    {/* <i className="fas fa-box-open 4" aria-hidden="true"></i> */}
                    <span> {this.props.field}</span>
                  </button>
                </div>
              )}
            </div>
          )
        ) : (
          ""
        )}
      </td>
    );
  }
}

class CustomOrderTransaction extends React.Component {
  onbuttonclick(e) {
    this.props.onclick(JSON.parse(e), this.props.field);
  }

  render() {
    return (
      <td>
        <li className="list-inline-item">
          <button
            className="clsopenbutton  btn-outline-primary"
            value={JSON.stringify(this.props.dataItem)}
            onClick={() =>
              this.onbuttonclick(JSON.stringify(this.props.dataItem))
            }
            data-bs-toggle="tooltip"
            data-bs-placement="bottom"
            title="Open"
          >
            <i className="fas fa-angle-double-down" aria-hidden="true"></i>
          </button>
        </li>
      </td>
    );
  }
}

class CustomHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value:
        this.props.source !== undefined && this.props.source.length > 0
          ? this.props.source.findIndex(
            (dataItem) => parseInt(dataItem[this.props.field]) === 0
          ) === -1
          : false,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      value:
        nextProps.source !== undefined && nextProps.source.length > 0
          ? nextProps.source.findIndex(
            (dataItem) => parseInt(dataItem[this.props.field]) === 0
          ) === -1
          : false,
    });
  }

  handleOptionChange(e) {
    this.setState({ value: e.target.value === "0" ? true : false });
    this.props.onchange(
      this.props.field,
      parseInt(e.target.value) === 0 ? 1 : 0
    );
  }

  render() {
    return (
      <span>
        <span>
          {
            <input
              type="Checkbox"
              value={
                this.state.value !== null &&
                  this.state.value !== undefined &&
                  this.state.value === true
                  ? 1
                  : 0
              }
              checked={this.state.value}
              onChange={this.handleOptionChange.bind(this)}
            ></input>
          }
        </span>
        <span className="ml-2">{this.props.title}</span>
      </span>
    );
  }
}

class CustomButtonHeader extends React.Component {
  render() {
    return <span></span>;
  }
}

class CustomButtonHeaderforusergroup extends React.Component {
  render() {
    return <span>{this.props.title}</span>;
  }
}

class Orderunsharedbyme extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.dataItem[this.props.field],
    };
    this.props = props;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      value: nextProps.dataItem[this.props.field],
    });
  }

  render() {
    return (
      <td>
        {this.props.dataItem[this.props.keyfield] !== undefined ? (
          <div className="actionsBtn 12 align-items-center unshareBlock">
            {/* {$.session.get("Username") === "Administrator" ? (
              ""
            ) : (
              <CustomButtonCell
                {...this.props}
                keyfield={this.props.keyfield}
                onclick={this.props.onclick}
              />
            )}

            {$.session.get("Username") === "Administrator" ? (
              ""
            ) : ( */}
            {/* // <Unshareorderorderinsidescreen */}
            {/* //   {...this.props}
              //   keyfield={this.props.keyfield}
              //   onclick={this.props.onclick} */}
            {/* // /> */}
            <Unshareorder
              //  order={this.state.Orderunsharedbyme}
              keyfield={this.props.keyfield}
              props={this.props}
              passbackorder={this.props.passbackorder}
            />
            {/* )} */}
          </div>
        ) : (
          ""
        )}
      </td>
    );
  }
}
const OrderharedbymeAlart = withAlert()(withTranslation(Orderunsharedbyme));

class CompletedViewCell extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.dataItem[this.props.field],
      filetype: this.props.dataItem["filetype"],
    };
    this.props = props;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      value: nextProps.dataItem[this.props.field],
      filetype: this.props.dataItem["filetype"],
    });
  }

  onbuttonclick(e) {
    this.props.onclick(
      JSON.parse(e.target.getAttribute("value")),
      this.props.field
    );
  }

  render() {
    return (
      <td>
        <div className="column">
          {this.props.dataItem[this.props.keyfield] !== undefined ? (
            <div className="actionsBtn 13 align-items-center rejectColm">
              {/* {$.session.get("Username") === "Administrator" ? (
            ""
          ) : ( */}
              <div>
                <button
                  className="clsopenbutton  btn-outline-primary"
                  hidden={
                    this.props.dataItem.protocolordercode !== undefined &&
                      this.props.dataItem.canuserprocess === false
                      ? "disabled"
                      : ""
                  }
                  value={JSON.stringify(this.props.dataItem)}
                  onClick={this.onbuttonclick.bind(this)}
                >
                  {this.props.field}
                </button>
              </div>
              {/*  )
           }  */}

              {Number(this.state.filetype) === 0 ? (
                ""
              ) : (
                <Customordercells
                  // {...props}
                  keyfield={this.props.keyfield}
                  props={this.props}
                />
              )}

              {this.props.dataItem.approvelstatus === 3 ? (
                <div className="reject">
                  <style allign={faAlignRight}></style>
                  <span className="dot"></span>
                </div>
              ) : (
                ""
              )}
            </div>
          ) : (
            ""
          )}
        </div>
      </td>
    );
  }
}
const CompletedCelltemp = withAlert()(withTranslation(CompletedViewCell));

export {
  CustomButtonHeader,
  CustomHeader,
  CustomButtonCell,
  CustomCell,
  ColumnMenuCheckboxFilter,
  ColumnMenu,
  CustomColumnMenu,
  CustomButtonHeaderforusergroup,
  CustomOrderTransaction,
  ProtocolorderunshareViewCell1,
  Protocolactioncell,
  Protocolactionsharbycell,
  OrderharedbymeAlart,
  CompletedCelltemp,
  Sheetactioncell,
  Sheetactionsharebycell,
  MyCustomButtonCell,
  Parseractioncell,
  InventoryCell,MaterialCell,MaterialNameAction,EquipmentCell
};
