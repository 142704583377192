export const ADD_VIEWSHEET = "ADD_VIEWSHEET";
export const ADD_MODIFIEDSHEET = "ADD_MODIFIEDSHEET";
export const ADD_PARSEDFIELDS = "ADD_PARSEDFIELDS";
export const UPDATE_DESIGNERFORVIEWSHEET = "UPDATE_DESIGNERFORVIEWSHEET";
export const UPDATE_STOPVIEWSHEET = "UPDATE_STOPVIEWSHEET";
export const REPLACE_STATE = "REPLACE_STATE";
export const UPDATE_KENDOSTATE = "UPDATE_KENDOSTATE";
export const UPDATE_CONTROLRIGHTS = "UPDATE_CONTROLRIGHTS";
export const ADD_AUDITOPTION = "ADD_AUDITOPTION";
export const ADD_KENDOSTATE = "ADD_KENDOSTATE";
export const ADD_VIEWCREATION = "ADD_VIEWCREATION";
export const UPDATE_KENDOSTATECREATE = "UPDATE_KENDOSTATECREATE";
export const UPDATE_MENU_PROPS = "UPDATE_MENU_PROPS";
export const ADD_KENDOSTATECREATE = "ADD_KENDOSTATECREATE";
export const LOGIN = 'LOGIN';
export const TOGGLE_MENU = 'TOGGLE_MENU';
//export const MASTERDATA_SELECT = 'MASTERDATA_SELECT';
export const SORT_DATA = "SORT_DATA";
export const SEARCH_DATA = "SEARCH_DATA";
export const UPDATE_LANG = "UPDATE_LANG";
export const POST_CRUD = "POST_CRUD";
export const SEARCH_FIELD_DATA = "SEARCH_FIELD_DATA";
export const FILE_UPLOAD = "FILE_UPLOAD";
export const DETAIL_DATA = "DETAIL_DATA";
export const DEFAULT_RETURN = "DEFAULT_RETURN";
export const LOGIN_STATUS = "LOGIN_STATUS";
export const LOGOUT = "LOGOUT";
export const DEFAULT_SITE_CHANGE = "DEFAULT_SITE_CHANGE";
export const REQUEST_FAILURE = "REQUEST_FAILURE";
export const UPDATE_MENU_LIST = "UPDATE_MENU_LIST";
export const UPDATE_SEARCH_ORDERS = "UPDATE_SEARCH_ORDERS";
export const UPDATE_SEARCH_TEMPLATES = "UPDATE_SEARCH_TEMPLATES";
export const UPDATE_INACTIVE_LOGOUT = "UPDATE_INACTIVE_LOGOUT";
export const UPDATE_SHEET_ORDER_ORG_CONTANT = "UPDATE_SHEET_ORDER_ORG_CONTANT";
export const UPDATE_INST_PARSE_IDS = "UPDATE_INST_PARSE_IDS";
export const UPDATE_SHEET_OPEN = "UPDATE_SHEET_OPEN";
export const UPDATE_PROFILE_IMAGE = "UPDATE_PROFILE_IMAGE";
export const GET_PROFILE_IMAGE = "GET_PROFILE_IMAGE";
export const INITILIZE_REDUX_STATE = "INITILIZE_REDUX_STATE";
export const UPDATE_SHEET_ORIGINAL_CONTANT = "UPDATE_SHEET_ORIGINAL_CONTANT";
export const UPDATE_PROTOCOL_OPEN_DATAS = "UPDATE_PROTOCOL_OPEN_DATAS";
export const UPDATE_PROTOCOLORDERS_OPEN_DATAS = "UPDATE_PROTOCOLORDERS_OPEN_DATAS";
export const UPDATE_Celllevel_Audit_Datas = "UPDATE_Celllevel_Audit_Datas"
