import React, { Suspense, useState } from "react";
import store from "./../redux_store/store/index";
import {
  updatecontrolrights,
  addauditoption,
} from "./../redux_store/actions/index";
import $ from "jquery";
import * as translation from "react-multi-lang";
import AuditActionEnum, { AuditModuleNameEnum } from "./AuditActionEnum";
import CryptoJS from "crypto-js";
import ReactHtmlParser from "react-html-parser";
import pako, { gzip } from "pako";
import { Protocolscreenenum, isMultitenant } from "./Constance";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { Modal } from "reactstrap";
import { ModalBody } from "reactstrap";
import KendoCombobox from "../controls/KendoCombobox";
import KendoDatepicker from "../controls/KendoDatepicker";
import KendoGridView from "../controls/KendoGridView";
import { CardBody } from "reactstrap";
import { Collapse } from "reactstrap";

const axios = require("axios");

function userdetails() {
  let objdat = {},
    rtnObj = {};

  objdat["sUserStatus"] = "";
  rtnObj["ActiveUserDetails"] = objdat;
  return rtnObj;
}

function addShowClass(e) {
  $(".filterBlock").hasClass("clsFilters");
  $(".filterBlock").toggleClass("show");
  $(".filterByBtn").toggleClass("active");
  $(".filter-top-group").toggleClass("active");
}

function showOrderBlock(e) {
  $(".leftcontainer").hasClass("clsdisplayblock");
  $(".leftcontainer").toggleClass("show");
  $(".createOrderBtn").toggleClass("active");
}

function PercentageCalculator(Totalcount, count) {
  let percentage = (count / Totalcount) * 100;
  return percentage;
}

function SPCCalculation(xvalue, yvalue, Subgroupcount) {
  let CalculatedValue = {};
  let count = yvalue !== undefined && yvalue.length > 0 ? yvalue.length : 0;
  let average = 0;
  let sum = 0;
  if (yvalue !== undefined && yvalue.length > 0) {
    for (let ycount = 0; ycount < yvalue.length; ycount++) {
      let value = yvalue[ycount];
      let value1 = value === null ? "" : value.toString().replace(",", ".");
      sum += parseFloat(value1);
    }
  }
  /* yvalue.reduce(function(a, b) {
    return a + b;
  }, 0);*/
  average = sum / count;
  CalculatedValue.avegare = average;
  let startvalue = 0;
  let endvalue = 0;
  if (xvalue !== undefined && xvalue.length > 0) {
    startvalue = xvalue[0];
    endvalue = xvalue[xvalue.length - 1];
  }
  CalculatedValue.centerXval = [startvalue, endvalue];
  CalculatedValue.centerYval = [average, average];

  let upperband2time = average + 2 * (average / 1.128);
  let upperband3time = average + 3 * (average / 1.128);

  CalculatedValue.Upper2Xval = [startvalue, endvalue];
  CalculatedValue.Upper2Yval = [upperband2time, upperband2time];

  CalculatedValue.Upper3Xval = [startvalue, endvalue];
  CalculatedValue.Upper3Yval = [upperband3time, upperband3time];

  let lowerband2time = average - 2 * (average / 1.128);
  let lowerband3time = average - 3 * (average / 1.128);

  CalculatedValue.Lower2Xval = [startvalue, endvalue];
  CalculatedValue.Lower2Yval = [lowerband2time, lowerband2time];

  CalculatedValue.Lower3Xval = [startvalue, endvalue];
  CalculatedValue.Lower3Yval = [lowerband3time, lowerband3time];

  return CalculatedValue;
}

function convertdoubledigitdate(day, month) {
  var newDate;

  if (day !== undefined && day >= 1) {
    newDate = day.toString();
    // day = Number(day.trim());
    // return day;
  }

  if (month !== undefined && month >= 1) {
    newDate = month.toString();
    // day = Number(day.trim());
    // return month;
  }

  return newDate;
}

function convertdoubledigittime(hour, min, secounds) {
  if (hour.toString().length === 1) {
    hour = "0" + hour.toString();
    // day = Number(day.trim());
  }
  if (min.toString().length === 1) {
    min = "0" + min.toString();
    // day = Number(day.trim());
  }
  if (secounds.toString().length === 1) {
    secounds = "0" + secounds.toString();
    // day = Number(day.trim());
  }
  let time = hour + ":" + min + ":" + secounds;
  return time;
}

function convertfulldateformat(year, month, day) {
  if (month.toString().length === 1) {
    month = "0" + month.toString();
  }
  if (day.toString().length === 1) {
    day = "0" + day.toString();
  }
  let fullyear = year.toString() + month + day;
  return fullyear;
}

function AuditTrailValidation(ModuleName, ScreenName, TaskName) {
  let auditConfig = store.getState().audit.auditoption;

  let auditarray = auditConfig.AuditConfig;
  if (auditarray === undefined) {
    return false;
  }
  let val = auditarray.findIndex(
    (e) =>
      e.modulename === ModuleName &&
      e.screenname === ScreenName &&
      e.taskname === TaskName
  );
  let audiVal = false;

  if (val > -1) {
    if (auditarray[val].manualaudittrail === 1) {
      audiVal = true;
    }
  }
  return audiVal;
}

function generateLink(ordercode, screen, activekey) {
  let link = "";
  // this.path = window.location.href.toString();
  let encryptedbatchcode = CF_encryptionData(ordercode).EncryptData;

  let detail = "";
  if (screen === "Sheet") {
    detail = CF_encryptionData(
      (sessionStorage.getItem("tenantdomain") !== undefined &&
        sessionStorage.getItem("tenantdomain") !== null &&
        sessionStorage.getItem("tenantdomain") !== ""
        ? sessionStorage.getItem("tenantdomain")
        : "-1") +
      "//" +
      screen
    ).EncryptData;
  } else {
    detail = CF_encryptionData(
      (sessionStorage.getItem("tenantdomain") !== undefined &&
        sessionStorage.getItem("tenantdomain") !== null &&
        sessionStorage.getItem("tenantdomain") !== ""
        ? sessionStorage.getItem("tenantdomain")
        : "-1") +
      "//" +
      screen +
      "//" +
      activekey
    ).EncryptData;
  }

  /**
   * don't remove below commented code
   *
   */

  // start

  // let encryptedbatchid = CF_encryptionData("VALIDATE1000004").EncryptData;
  // const userObject = {
  //   usercode: sessionStorage.getItem("User"),
  //   username: sessionStorage.getItem("Username"),
  //   userfullname: sessionStorage.getItem("Userfullname"),
  //   password: 'gyX57EHs08KuakyVhFVvGQ==',
  //   lsusergroup: {
  //       usergroupcode: sessionStorage.getItem("multiusergroups"),
  //       usergroupname: sessionStorage.getItem("usergroupname"),
  //   },
  //   lssitemaster:{
  //     sitecode:sessionStorage.getItem('sSiteCode')
  //   }
  // }
  // let encrypteduser = CF_encryptionData(userObject).EncryptData;

  // end

  const baseURL =
    isMultitenant === 1
      ? window.location.origin.toString()
      : window.location.origin.toString() + "/ELN";

  link =
    baseURL +
    "/vieworder" +
    '#{"orderid":"' +
    encryptedbatchcode +
    '","d":"' +
    detail +
    /**
     * don't remove below commented code
     *
     */
    // '","user":"' +
    // encrypteduser +
    // '","batchid":"' +
    // encryptedbatchid +
    '"}';

  return link;
}

function ControlRights(ModuleName, DisplayTopic) {
  let screencontrol = store.getState().Menu.Controlrights.rights;

  let val = -1;

  let rights = false;
  let save = false;
  let edit = false;
  let del = false;
  let val1 = -1;
  if (screencontrol !== undefined) {
    val = screencontrol.findIndex(
      (e) => e.modulename === ModuleName && e.displaytopic === DisplayTopic
    );
    val1 = screencontrol.filter(
      (e) => e.modulename === ModuleName && e.screenname === DisplayTopic
    );
  }

  if (val > -1) {
    if (screencontrol[val].sallow.trim() === "1") {
      rights = true;
      if (screencontrol[val].screate.trim() === "1") {
        save = true;
      }
      if (screencontrol[val].sedit.trim() === "1") {
        edit = true;
      }
      if (screencontrol[val].sdelete.trim() === "1") {
        del = true;
      }
    } else if (val1.length > 0) {
      val1.map((item) => {
        if (item.sallow.trim() === "1") {
          rights = true;
          return true;
        } else {
          return false;
        }
      });
    }
  } else if (val1.length > 0) {
    val1.map((item) => {
      if (item.sallow.trim() === "1") {
        rights = true;
        return true;
      } else {
        return false;
      }
    });
  }

  let objdat = {},
    rtnObj = {};

  objdat["rights"] = rights;
  objdat["save"] = save;
  objdat["edit"] = edit;
  objdat["del"] = del;
  rtnObj["UserGroupRights"] = objdat;

  return rtnObj;
}

function SubMenuRights(ModuleName, DisplayTopic) {
  let screeenRights = store.getState().Menu.Controlrights.rights;

  let rights = false;

  if (screeenRights !== undefined) {
    let val1 = screeenRights.filter((e) => e.modulename === ModuleName && e.screenname === DisplayTopic);
    val1.map((item) => {
      if (item.sallow.trim() === "1") {
        rights = true;
        return true;
      } else {
        return false;
      }
    });
  }
  if(DisplayTopic === "IDS_SCN_EQUIPMENT" || DisplayTopic === "IDS_SCN_EQUIPMENTPARAMS" ){
    rights = true;
  }
  return rights;
}

function ModuleRights(ModuleName, ScreenName) {
  let screencontrol = store.getState().Menu.Controlrights.rights;
  let rights = false;
  if (screencontrol !== undefined) {
    if (ModuleName !== "IDS_MDL_ORDERS") {
      let val = screencontrol.findIndex(
        (e) => e.modulename === ModuleName && e.sallow.trim() === "1"
      );

      if (val > -1) {
        if (screencontrol[val].sallow.trim() === "1") {
          rights = true;
        }
      }
    } else if (ModuleName === "IDS_MDL_ORDERS") {
      let val = screencontrol.findIndex(
        (e) =>
          e.modulename === ModuleName &&
          e.screenname === ScreenName &&
          e.sallow.trim() === "1"
      );

      if (val > -1) {
        if (screencontrol[val].sallow.trim() === "1") {
          rights = true;
        }
      }
    }
  }

  return rights;
}

function mainModuleRights(ModuleName) {
  // let modules = ModuleName;

  let screencontrol = store.getState().Menu.Controlrights.rights;
  let rights = false;
  if (screencontrol !== undefined) {
    let val = screencontrol.findIndex(
      (e) =>
        (e.modulename === ModuleName && e.sallow.trim() === "1") ||
        (e.modulename === ModuleName && e.sallow.trim() === "1")
    );

    if (val > -1) {
      if (screencontrol[val].sallow.trim() === "1") {
        rights = true;
      }
    }
  }
  // else if (modules[0] === "inventory") {
  //   rights = true;
  // }
  return rights;
}

function IsJSON(str) {
  if (str === undefined || str === null) {
    return false;
  }
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

function isINT(value) {
  let val = Number.isInteger(value);

  return val;
}

function killSessionLogout() {
  $.session.remove("User");
  $.session.remove("Username");
  $.session.remove("name");
  $.session.remove("Usersite");
  $.session.remove("Usersitename");
  $.session.remove("usergroupname");
  $.session.remove("idletime");
}

function Silentauditobject(
  ManipulationType,
  ModuleName,
  objA,
  objB,
  TableName
) {
  if (ModuleName === "Sheet Orders") {
    ModuleName = "Sheet Order";
  } else if (ModuleName === "Sheet Templates") {
    ModuleName = "Sheet Templates";
  }

  let comments = "";
  if (objA !== undefined || objB !== undefined) {
    comments = compareObjs(objA, objB);
  }

  if (ManipulationType === "IDS_TSK_DELETE" || ManipulationType === "update") {
    comments = compareObjsOperation(objA, objB, ManipulationType, ModuleName);
  }

  let name = $.session.get("name");

  let Audit = {
    lssitemaster: parseInt($.session.get("Usersite")),
    lsuserMaster: parseInt($.session.get("User")),
    transactiondate: GetutcDate(new Date()),
    moduleName: AuditModuleNameEnum[ModuleName],
    manipulatetype: translation.t(
      "compareObjs." + AuditActionEnum[ManipulationType]
    ),
    comments,
    actions: AuditActionEnum[ManipulationType],
    systemcoments:
      "Audittrail.Audittrailhistory.Audittype.IDS_AUDIT_SYSTEMGENERATED",
    username: name,
    tableName: TableName,
  };
  return Audit;
}

function SilentauditobjectForOrder(
  ManipulationType,
  ModuleName,
  objA,
  objB,
  TableName,
  auditKey
) {
  if (ModuleName === "Sheet Orders") {
    ModuleName = "Sheet Order";
  } else if (ModuleName === "Sheet Templates") {
    ModuleName = "Sheet Templates";
  }

  let comments = "";
  if (objA !== undefined || objB !== undefined) {
    comments = compareObjs(objA, objB);
  }

  if (ManipulationType === "IDS_TSK_DELETE" || ManipulationType === "update") {
    comments = compareObjsOperation(objA, objB, ManipulationType, ModuleName);
  }

  let name = $.session.get("name");

  let Audit = {
    lssitemaster: parseInt($.session.get("Usersite")),
    lsuserMaster: parseInt($.session.get("User")),
    transactiondate: GetutcDate(new Date()),
    moduleName: AuditModuleNameEnum[ModuleName],
    manipulatetype: translation.t(
      "compareObjs." + AuditActionEnum[ManipulationType]
    ),
    comments,
    actions: AuditActionEnum[ManipulationType],
    systemcoments:
      "Audittrail.Audittrailhistory.Audittype.IDS_AUDIT_SYSTEMGENERATED",
    username: name,
    tableName: TableName,
    batchcode: auditKey !== undefined ? auditKey : "",
  };
  return Audit;
}

function onAuditHandler(ManipulationType, ModuleName, objA, tableName) {
  if (ModuleName === "Sheet Orders") {
    ModuleName = "Sheet Order";
  } else if (ModuleName === "Sheet Templates") {
    ModuleName = "Sheet Templates";
  }

  let comments = objA;
  // let name = store.getState().Loggeduser.username;
  let name = $.session.get("name");
  let Audit = {
    lssitemaster: parseInt($.session.get("Usersite")),
    lsuserMaster: parseInt($.session.get("User")),
    transactiondate: GetutcDate(new Date()),
    moduleName: AuditModuleNameEnum[ModuleName],
    manipulatetype: translation.t(
      "compareObjs." + AuditActionEnum[ManipulationType]
    ),
    comments,
    actions: AuditActionEnum[ManipulationType],
    systemcoments:
      "Audittrail.Audittrailhistory.Audittype.IDS_AUDIT_SYSTEMGENERATED",
    username: name,
    tableName: tableName,
  };

  return Audit;
}

function Silentauditobjectonlogout(
  ManipulationType,
  lsuser,
  ModuleName,
  objA,
  objB
) {
  if (ModuleName === "Sheet Orders") {
    ModuleName = "Sheet Order";
  } else if (ModuleName === "Sheet Templates") {
    ModuleName = "Sheet Templates";
  }

  let comments = "";
  if (objA !== undefined || objB !== undefined) {
    comments = compareObjs(objA, objB);
  }
  let name = $.session.get("name");

  let Audit = {
    lssitemaster: lsuser.lssitemaster
      ? lsuser.lssitemaster.sitecode
      : parseInt($.session.get("Usersite")),
    lsuserMaster: lsuser.usercode,
    transactiondate: GetutcDate(new Date()),
    moduleName: AuditModuleNameEnum[ModuleName],
    manipulatetype: translation.t(
      "compareObjs." + AuditActionEnum[ManipulationType]
    ),
    comments,
    actions: translation.t("compareObjs." + AuditActionEnum[ManipulationType]),
    systemcoments:
      "Audittrail.Audittrailhistory.Audittype.IDS_AUDIT_SYSTEMGENERATED",
    username: name,
  };
  return Audit;
}

function Manualauditobject(ManipulationType, ModuleName) {
  if (ModuleName === "Sheet Orders") {
    ModuleName = "Sheet Order";
  } else if (ModuleName === "Sheet Templates") {
    ModuleName = "Sheet Templates";
  }
  let Audit1 = {
    lssitemaster: parseInt($.session.get("Usersite")),
    lsuserMaster: parseInt($.session.get("User")),
    transactiondate: GetutcDate(new Date()),
    moduleName: AuditModuleNameEnum[ModuleName],
    manipulatetype: translation.t(
      "compareObjs." + AuditActionEnum[ManipulationType]
    ),
    actions: AuditActionEnum[ManipulationType],
    systemcoments:
      "Audittrail.Audittrailhistory.Audittype.IDS_AUDIT_USERGENERATED",
  };
  return Audit1;
}

function GetutcDate(dateval) {
  var date = new Date(dateval);
  var now_utc = Date.UTC(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds()
  );
  return now_utc;
}

function GetutctoDate(dateval) {
  var date = new Date(dateval);
  let settime = new Date();
  var now_utc = Date.UTC(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    settime.getUTCHours(),
    settime.getUTCMinutes(),
    settime.getUTCSeconds()
  );
  return now_utc;
}

function GetutcDateoncurenttime(dateval) {
  var datetime = new Date();
  var date = new Date(dateval);
  var now_utc = Date.UTC(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    datetime.getUTCHours(),
    datetime.getUTCMinutes(),
    datetime.getUTCSeconds()
  );
  return new Date(now_utc);
}

function convertUTCDateToLocalDate(date) {
  var now_utc = new Date(date.toISOString());
  return now_utc;
}

function GetutcDateaudi(dateval) {
  var date = new Date(dateval);
  // var nowUtc = Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), 12, 1, 0);
  var nowUtc = new Date(date.setHours(0, 0, 1, 0));
  // var nowUtc = new Date(dateval.setUTCHours(0, 0, 0, 0) + dateval.getTimezoneOffset() * 60000);
  return nowUtc;
}

function compareData(fieldname, dataA, dataB) {
  let transField = translation.t("Common.IDS_AUDIT_" + fieldname);
  if (dataB !== undefined) {
    if (transField.includes("Common.")) {
      transField = "";
      return "";
    } else {
      // transField += ": ";
      return dataA !== dataB
        ? transField +
        ": " +
        translation.t("Common.IDS_AUDIT_WASCHANGEDTO", {
          from: dataA,
          to: dataB,
        })
        : transField +
        ": " +
        translation.t("Common.IDS_LBL_UPDATED", { param: "" });
    }
    // return dataA !== dataB ? transField + translation.t("compareObjs.ID_WASCHANGEDTO", { from: dataA, to: dataB }) : "";
  } else {
    return "";
  }
}

function predefineddatabase(databasename) {
  var dbname = [
    "template1",
    "template0",
    "postgres",
    "sales",
    "database",
    "CLASS",
    "DATA",
  ];
  let init = 0;
  let warningmsg = "";
  for (init; init < dbname.length; init++) {
    if (databasename.toLowerCase() === dbname[init].toLowerCase()) {
      warningmsg = translation.t("Organisation.IDS_LBL_WARMSG");
    }
  }
  return warningmsg;
}

function generateComment(obj) {
  let commentArray = [];
  Object.entries(obj).map((objEntries) => {
    if (obj[objEntries[0]] !== null && typeof obj[objEntries[0]] === "object") {
      Object.entries(obj[objEntries[0]]).map((objAentries) => {
        let tempKey = translation.t("compareObjs.ID_" + objAentries[0]);
        if (!tempKey.includes("compareObjs.")) {
          commentArray.push(
            translation.t("Common.IDS_AUDIT_KEYANDVALUE", {
              KeyVal: tempKey,

              ValueVal: objAentries[1],
            })
          );
        } else {
          commentArray.push(
            translation.t("Common.IDS_AUDIT_KEYANDVALUE", {
              KeyVal: objAentries[0],
              ValueVal: objEntries[1],
            })
          );
        }
        return undefined;
      });
    } else {
      if (objEntries[1] !== "") {
        let tempKey = translation.t("compareObjs.ID_" + objEntries[0]);
        if (!tempKey.includes("compareObjs.")) {
          commentArray.push(
            translation.t("Common.IDS_AUDIT_KEYANDVALUE", {
              KeyVal: tempKey,
              user: $.session.get("Username"),
              ValueVal: objEntries[1],
            })
          );
        } else {
          commentArray.push(
            translation.t("Common.IDS_AUDIT_KEYANDVALUE", {
              KeyVal: objEntries[0],
              user: $.session.get("Username"),
              ValueVal: objEntries[1],
            })
          );
        }
      }
    }
    return undefined;
  });
  return commentArray;
}

function compareObjs(objA, objB) {
  let returnComment = "";
  if (typeof objA === "string") {
    returnComment = objA;
  } else {
    objA = objA || {};
    objB = objB || {};
    if (
      Object.entries(objA).length > 0 &&
      objA !== undefined &&
      Object.entries(objB).length > 0 &&
      objB !== undefined
    ) {
      if (
        typeof objA === "object" &&
        Object.entries(objA)[0][0] !== 0 &&
        Object.entries(objA)[0][0] !== "0"
      ) {
        const objAkeys = Object.keys(objA).sort();
        const objBkeys = Object.keys(objB).sort();
        let commentArray = [];
        let isEditedItem = true;
        if (objAkeys.join(", ") === objBkeys.join(", ")) {
          isEditedItem = true;
        } else {
          let present = objBkeys.find((keyName) => objAkeys.includes(keyName));
          // if (present.length > 0) {
          if (present > 0) {
            isEditedItem = true;
          } else {
            isEditedItem = false;
          }
        }
        if (isEditedItem) {
          objAkeys.map((objKey) =>
            typeof objA[objKey] === "object" && typeof objB[objKey] === "object"
              ? Object.entries(objA[objKey]).map((objAentries) =>
                commentArray.push(
                  compareData(
                    objAentries[0],
                    objA[objKey][objAentries[0]],
                    objB[objKey][objAentries[0]]
                  )
                )
              )
              : commentArray.push(
                compareData(objKey, objA[objKey], objB[objKey])
              )
          );
        } else {
          commentArray = generateComment(objB);
        }
        returnComment = commentArray
          .filter((string) => string !== "")
          .join(", ");
      }
    } else if (
      objA !== undefined &&
      Object.entries(objA).length > 0 &&
      (objB === undefined || Object.entries(objB).length === 0)
    ) {
      let commentArray = [];
      commentArray = generateComment(objA);
      returnComment = commentArray.join(", ");
    } else if (
      (Object.entries(objA).length === 0 || objA === undefined) &&
      (Object.entries(objB).length === 0 || objB === undefined)
    ) {
      returnComment = translation.t("compareObjs.IDS_BOTHOBJECTCANNOTBEEMPTY");
    }
  }
  return returnComment;
}

function compareObjsOperation(objA, objB, ManipulationType, ModuleName) {
  let returnComment = "";
  if (typeof objA === "string") {
    returnComment = objA;
  } else {
    objA = objA || {};
    objB = objB || {};
    if (
      Object.entries(objA).length > 0 &&
      objA !== undefined &&
      Object.entries(objB).length > 0 &&
      objB !== undefined
    ) {
      if (
        typeof objA === "object" &&
        Object.entries(objA)[0][0] !== 0 &&
        Object.entries(objA)[0][0] !== "0"
      ) {
        const objAkeys = Object.keys(objA).sort();
        const objBkeys = Object.keys(objB).sort();
        let commentArray = [];
        let isEditedItem = true;
        if (objAkeys.join(", ") === objBkeys.join(", ")) {
          isEditedItem = true;
        } else {
          let present = objBkeys.find((keyName) => objAkeys.includes(keyName));
          // if (present.length > 0) {
          if (present > 0) {
            isEditedItem = true;
          } else {
            isEditedItem = false;
          }
        }
        if (isEditedItem) {
          objAkeys.map((objKey) =>
            typeof objA[objKey] === "object" && typeof objB[objKey] === "object"
              ? Object.entries(objA[objKey]).map((objAentries) =>
                commentArray.push(
                  compareData(
                    objAentries[0],
                    objA[objKey][objAentries[0]],
                    objB[objKey][objAentries[0]]
                  )
                )
              )
              : commentArray.push(
                compareData(objKey, objA[objKey], objB[objKey])
              )
          );
        } else {
          commentArray = generateComment(objB);
        }
        returnComment = commentArray
          .filter((string) => string !== "")
          .join(", ");
      }
    } else if (
      objA !== undefined &&
      Object.entries(objA).length > 0 &&
      (objB === undefined || Object.entries(objB).length === 0)
    ) {
      if (ManipulationType === "delete") {
        // returnComment = translation.t("compareObjs.IDS_DELETEREQ", {
        //   user1: Object.values(objA)[0],
        //   user2: ModuleName,
        // });
      } else {
        let commentArray = [];
        commentArray = generateComment(objA);
        returnComment = commentArray.join(", ");
      }
    } else if (
      (Object.entries(objA).length === 0 || objA === undefined) &&
      (Object.entries(objB).length === 0 || objB === undefined)
    ) {
      returnComment = translation.t("compareObjs.IDS_BOTHOBJECTCANNOTBEEMPTY");
    }
  }
  return returnComment;
}

function orderType(order) {
  switch (order) {
    case 0:
      return "Lims Order";
    case 2:
      return translation.t("Orders.Sheetorders.IDS_LBL_ELNORDERNEW");
    case 3:
      return translation.t("Orders.Sheetorders.IDS_LBL_RESEARCHORDERNEW");
    case 4:
      return translation.t("Orders.Sheetorders.IDS_LBL_EXCELMANAGEMENT");
    case 5:
      return translation.t("Orders.Sheetorders.IDS_LBL_EVALUATION");
    default:
      return "-";
  }
}

function orderTypeforprotocol(protocolorder) {
  switch (protocolorder) {
    case 1:
      return translation.t("Orders.Protocolorders.IDS_LBL_ELNPROTO", {
        param: "",
      });
    case 2:
      return translation.t("Orders.Protocolorders.IDS_LBL_DYNAMICPROTO", {
        param: "",
      });
    default:
      return "-";
  }
}

function returnMIMEtype(extension) {
  switch (extension) {
    case ".aac":
      return "audio/aac";
    case ".abw":
      return "application/x-abiword";
    case ".arc":
      return "application/x-freearc";
    case ".avi":
      return "video/x-msvideo";
    case ".azw":
      return "application/vnd.amazon.ebook";
    case ".bin":
      return "application/octet-stream";
    case ".bmp":
      return "image/bmp";
    case ".bz":
      return "application/x-bzip";
    case ".bz2":
      return "application/x-bzip2";
    case ".csh":
      return "application/x-csh";
    case ".css":
      return "text/css";
    case ".csv":
      return "text/csv";
    case ".doc":
      return "application/msword";
    case ".docx":
      return "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
    case ".eot":
      return "application/vnd.ms-fontobject";
    case ".epub":
      return "application/epub+zip";
    case ".gz":
      return "application/gzip";
    case ".gif":
      return "image/gif";
    case ".htm":
      return "text/html";
    case ".html":
      return "text/html";
    case ".ico":
      return "image/vnd.microsoft.icon";
    case ".ics":
      return "text/calendar";
    case ".jar":
      return "application/java-archive";
    case ".jpeg":
      return "image/jpeg";
    case ".jpg":
      return "image/jpeg";
    case ".js":
      return "text/javascript";
    case ".json":
      return "application/json";
    case ".jsonld":
      return "application/ld+json";
    case ".mid":
      return "audio/midi";
    case ".midi":
      return "audio/x-midi";
    case ".mj":
      return "text/javascript";
    case ".mp3":
      return "audio/mpeg";
    case ".mpeg":
      return "video/mpeg";
    case ".mpkg":
      return "application/vnd.apple.installer+xml";
    case ".odp":
      return "application/vnd.oasis.opendocument.presentation";
    case ".ods":
      return "application/vnd.oasis.opendocument.spreadsheet";
    case ".odt":
      return "application/vnd.oasis.opendocument.text";
    case ".oga":
      return "audio/ogg";
    case ".ogv":
      return "video/ogg";
    case ".ogx":
      return "application/ogg";
    case ".opus":
      return "audio/opus";
    case ".otf":
      return "font/otf";
    case ".png":
      return "image/png";
    case ".pdf":
      return "application/pdf";
    case ".php":
      return "application/x-httpd-php";
    case ".ppt":
      return "application/vnd.ms-powerpoint";
    case ".pptx":
      return "application/vnd.openxmlformats-officedocument.presentationml.presentation";
    case ".rar":
      return "application/vnd.rar";
    case ".rtf":
      return "application/rtf";
    case ".sh":
      return "application/x-sh";
    case ".svg":
      return "image/svg+xml";
    case ".swf":
      return "application/x-shockwave-flash";
    case ".tar":
      return "application/x-tar";
    case ".tif":
      return "image/tiff";
    case ".tiff":
      return "image/tiff";
    case ".ts":
      return "video/mp2t";
    case ".ttf":
      return "font/ttf";
    case ".txt":
      return "text/plain";
    case ".vsd":
      return "application/vnd.visio";
    case ".wav":
      return "audio/wav";
    case ".weba":
      return "audio/webm";
    case ".webm":
      return "video/webm";
    case ".webp":
      return "image/webp";
    case ".woff":
      return "font/woff";
    case ".woff2":
      return "font/woff2";
    case ".xhtml":
      return "application/xhtml+xml";
    case ".xls":
      return "application/vnd.ms-excel";
    case ".xlsx":
      return "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    case ".xml":
      return "text/xml";
    case ".xul":
      return "application/vnd.mozilla.xul+xml";
    case ".zip":
      return "application/zip";
    case ".3gp":
      return "video/3gpp";
    case ".3g2":
      return "video/3gpp2";
    case ".7z":
      return "application/x-7z-compressed";
    default:
      return "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
  }
}

function returnICON(extension) {
  switch (extension) {
    case ".aac":
      return "images/FileIcons/aac.svg";
    case ".abw":
      return "images/FileIcons/docs.svg";
    case ".arc":
      return "images/FileIcons/docs.svg";
    case ".avi":
      return "images/FileIcons/avi.svg";
    case ".azw":
      return "images/FileIcons/docs.svg";
    case ".bin":
      return "images/FileIcons/bin.svg";
    case ".bmp":
      return "images/FileIcons/bmp.svg";
    case ".bz":
      return "images/FileIcons/docs.svg";
    case ".bz2":
      return "images/FileIcons/docs.svg";
    case ".csh":
      return "images/FileIcons/csh.svg";
    case ".css":
      return "images/FileIcons/file.svg";
    case ".csv":
      return "images/FileIcons/csv.svg";
    case ".doc":
      return "images/FileIcons/doc.svg";
    case ".docx":
      return "images/FileIcons/doc.svg";
    case ".eot":
      return "images/FileIcons/docs.svg";
    case ".epub":
      return "images/FileIcons/docs.svg";
    case ".gz":
      return "images/FileIcons/docs.svg";
    case ".gif":
      return "images/FileIcons/gif.svg";
    case ".htm":
      return "images/FileIcons/htm.svg";
    case ".html":
      return "images/FileIcons/html.svg";
    case ".ico":
      return "images/FileIcons/ico.svg";
    case ".ics":
      return "images/FileIcons/docs.svg";
    case ".jar":
      return "images/FileIcons/jar.svg";
    case ".jpeg":
      return "images/FileIcons/image.svg";
    case ".jpg":
      return "images/FileIcons/image.svg";
    case ".js":
      return "images/FileIcons/js.svg";
    case ".json":
      return "images/FileIcons/json.svg";
    case ".jsonld":
      return "images/FileIcons/docs.svg";
    case ".mid":
      return "images/FileIcons/docs.svg";
    case ".midi":
      return "images/FileIcons/docs.svg";
    case ".mj":
      return "images/FileIcons/docs.svg";
    case ".mp3":
      return "images/FileIcons/mp3.svg";
    case ".mpeg":
      return "images/FileIcons/mpeg.svg";
    case ".mpkg":
      return "images/FileIcons/docs.svg";
    case ".odp":
      return "images/FileIcons/odp.svg";
    case ".ods":
      return "images/FileIcons/docs.svg";
    case ".odt":
      return "images/FileIcons/odt.svg";
    case ".oga":
      return "images/FileIcons/docs.svg";
    case ".ogv":
      return "images/FileIcons/docs.svg";
    case ".ogx":
      return "images/FileIcons/docs.svg";
    case ".opus":
      return "images/FileIcons/docs.svg";
    case ".otf":
      return "images/FileIcons/otf.svg";
    case ".png":
      return "images/FileIcons/png.svg";
    case ".pdf":
      return "images/FileIcons/pdf.svg";
    case ".php":
      return "images/FileIcons/php.svg";
    case ".ppt":
      return "images/FileIcons/ppt.svg";
    case ".pptx":
      return "images/FileIcons/pptx.svg";
    case ".rar":
      return "images/FileIcons/rar.svg";
    case ".rtf":
      return "images/FileIcons/rtf.svg";
    case ".sh":
      return "images/FileIcons/sh.svg";
    case ".svg":
      return "images/FileIcons/svg.svg";
    case ".swf":
      return "images/FileIcons/swf.svg";
    case ".tar":
      return "images/FileIcons/tar.svg";
    case ".tif":
      return "images/FileIcons/tif.svg";
    case ".tiff":
      return "images/FileIcons/tiff.svg";
    case ".ts":
      return "images/FileIcons/ts.svg";
    case ".ttf":
      return "images/FileIcons/ttf.svg";
    case ".txt":
      return "images/FileIcons/txt.svg";
    case ".vsd":
      return "images/FileIcons/docs.svg";
    case ".wav":
      return "images/FileIcons/wav.svg";
    case ".weba":
      return "images/FileIcons/docs.svg";
    case ".webm":
      return "images/FileIcons/docs.svg";
    case ".webp":
      return "images/FileIcons/docs.svg";
    case ".woff":
      return "images/FileIcons/docs.svg";
    case ".woff2":
      return "images/FileIcons/docs.svg";
    case ".xhtml":
      return "images/FileIcons/xhtml.svg";
    case ".xls":
      return "images/FileIcons/xls.svg";
    case ".xlsx":
      return "images/FileIcons/xlsx.svg";
    case ".xml":
      return "images/FileIcons/xml.svg";
    case ".xul":
      return "images/FileIcons/docs.svg";
    case ".zip":
      return "images/FileIcons/zip.svg";
    case ".3gp":
      return "images/FileIcons/3gp.svg";
    case ".3g2":
      return "images/FileIcons/3g2.svg";
    case ".7z":
      return "images/FileIcons/7z.svg";
    default:
      return "images/FileIcons/docs.svg";
  }
}

function extractValuefromsheet(sheet) {
  let sheetcontent = [];
  let sheetvalue = [];
  let sheetparameter = [];
  if (!IsJSON(sheet)) {
    sheetcontent = sheet;
  } else {
    sheetcontent = JSON.parse(sheet);
  }

  if (
    sheetcontent !== undefined &&
    sheetcontent.sheets !== undefined &&
    sheetcontent.sheets !== null &&
    sheetcontent.sheets.length > 0
  ) {
    sheetcontent.sheets.map((objsheet) => {
      if (
        objsheet.rows !== undefined &&
        objsheet.rows !== null &&
        objsheet.rows.length > 0
      ) {
        objsheet.rows.map((objrows) => {
          if (objrows !== undefined && objrows !== null) {
            if (
              objrows.cells !== undefined &&
              objrows.cells !== null &&
              objrows.cells.length > 0
            ) {
              objrows.cells.map((objcells) => {
                if (
                  objcells.value !== undefined &&
                  objcells.value !== null &&
                  objcells.value.toString().trim() !== ""
                ) {
                  sheetvalue.push(objcells.value);
                }
                if (
                  objcells.tag !== undefined &&
                  objcells.tag !== null &&
                  objcells.tag.trim() !== ""
                ) {
                  let tagvalue = JSON.parse(objcells.tag);
                  if (
                    tagvalue !== undefined &&
                    tagvalue !== null &&
                    tagvalue.ParameterName !== undefined &&
                    tagvalue.ParameterName !== null &&
                    tagvalue.ParameterName.trim() !== ""
                  )
                    sheetparameter.push(tagvalue.ParameterName);
                }
                return objcells.value;
              });
            }
          }
          return objrows;
        });
      }
      return objsheet;
    });
  }

  return {
    contentvalues: JSON.stringify(sheetvalue),
    contentparameter: JSON.stringify(sheetparameter),
  };
}

// function convertUTCDateToLocalDate(date) {
//   var newDate = new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000);
//   var dateutc = newDate.toLocaleString();

//   return dateutc;
// }

function CF_encryptionData(Data) {
  const salt = CryptoJS.lib.WordArray.random(128 / 8);
  const key = CryptoJS.PBKDF2("AGARAM_SDMS_SCRT", salt, {
    keySize: 128 / 32,
    iterations: 100,
  });
  const iv = CryptoJS.lib.WordArray.random(128 / 8);
  const encrypted = CryptoJS.AES.encrypt(JSON.stringify(Data), key, {
    iv: iv,
    padding: CryptoJS.pad.Pkcs7,
    mode: CryptoJS.mode.CBC,
  });
  // salt, iv will be hex 32 in length
  // append them to the ciphertext for use  in decryption
  const transitmessage = salt.toString() + iv.toString() + encrypted.toString();
  const ct = {
    EncryptData: transitmessage,
  };
  return ct;
}

function CF_decrypt(encryptedAES) {
  //const salt = CryptoJS.lib.WordArray.random(128 / 8);
  //const iv = CryptoJS.lib.WordArray.random(128 / 8);

  var salt = CryptoJS.enc.Hex.parse(encryptedAES.substr(0, 32));
  var iv = CryptoJS.enc.Hex.parse(encryptedAES.substr(32, 32));
  var encrypted = encryptedAES.substring(64);

  var key = CryptoJS.PBKDF2("AGARAM_SDMS_SCRT", salt, {
    keySize: 128 / 32,
    iterations: 100,
  });

  var decrypted = CryptoJS.AES.decrypt(encrypted, key, {
    iv: iv,
    padding: CryptoJS.pad.Pkcs7,
    mode: CryptoJS.mode.CBC,
  });

  return decrypted.toString(CryptoJS.enc.Utf8);
}

function extractValueforDynamiccombo(sheet, tagvalue) {
  let sheetcontent = [];
  let sheetvalue = [];
  let sheetvalueAbovedefault = [];
  let tag = {};
  let sheetindex = 1;
  if (!IsJSON(sheet)) {
    sheetcontent = sheet;
  } else {
    sheetcontent = JSON.parse(sheet);
  }

  if (!IsJSON(tagvalue)) {
    tag = tagvalue;
  } else {
    tag = JSON.parse(tagvalue);
  }

  if (
    sheetcontent !== undefined &&
    sheetcontent.sheets !== undefined &&
    sheetcontent.sheets !== null &&
    sheetcontent.sheets.length > 0 &&
    tag !== undefined &&
    sheetcontent.sheets.length >= parseInt(tag.datafromsheet)
  ) {
    sheetcontent.sheets.map((objsheet) => {
      if (
        sheetindex === parseInt(tag.datafromsheet) &&
        objsheet.rows !== undefined &&
        objsheet.rows !== null &&
        objsheet.rows.length > 0
      ) {
        objsheet.rows.map((objrows) => {
          if (objrows !== undefined && objrows !== null) {
            if (
              objrows.index >= parseInt(tag.datarangefrom) - 1 &&
              objrows.cells !== undefined &&
              objrows.cells !== null &&
              objrows.cells.length > 0
            ) {
              if (objrows.index < 200) {
                objrows.cells.map((objcells) => {
                  if (
                    objcells.index === parseInt(tag.datacolumn) - 1 &&
                    objcells.value !== undefined &&
                    objcells.value !== null &&
                    objcells.value.toString().trim() !== ""
                  ) {
                    sheetvalue.push({
                      datavalue: objcells.value,
                      datadisplay: objcells.value,
                      rowindex: objrows.index,
                    });
                  }
                  return objcells.value;
                });
              } else {
                objrows.cells.map((objcells) => {
                  if (
                    objcells.index === parseInt(tag.datacolumn) - 1 &&
                    objcells.value !== undefined &&
                    objcells.value !== null &&
                    objcells.value.toString().trim() !== ""
                  ) {
                    sheetvalueAbovedefault.push({
                      datavalue: objcells.value,
                      datadisplay: objcells.value,
                      rowindex: objrows.index,
                    });
                  }
                  return objcells.value;
                });
              }
            }
          }
          return objrows;
        });
      }
      sheetindex++;
      return objsheet;
    });
  }

  let returnarray = [];
  if (sheetvalueAbovedefault.length > 0) {
    returnarray = sheetvalue.concat(sheetvalueAbovedefault);
  } else {
    returnarray = sheetvalue;
  }
  returnarray.sort((a, b) => parseInt(a.rowindex) - parseInt(b.rowindex));
  returnarray.reverse();
  return returnarray;
}

async function linkSheetLogin(orderdetails) {
  let ELNurl = document.getElementById("ELNname").value;
  const decryptTenentID = JSON.parse(CF_decrypt(orderdetails.t));
  const decryptUserID = JSON.parse(CF_decrypt(orderdetails.u));
  const decryptGroupCode = JSON.parse(CF_decrypt(orderdetails.uc));
  const decryptGroupName = JSON.parse(CF_decrypt(orderdetails.ug));
  const decryptSiteName = JSON.parse(CF_decrypt(orderdetails.sn));
  const decryptSiteCode = JSON.parse(CF_decrypt(orderdetails.sc));
  // const decryptLogginID = JSON.parse(CF_decrypt(orderdetails.l));
  const decrypttoken = JSON.parse(CF_decrypt(orderdetails.k));
  let decryptloggedfrom = "";
  if (orderdetails.lf !== undefined) {
    decryptloggedfrom = JSON.parse(CF_decrypt(orderdetails.lf));
  }

  let decryptazuretoken = "";
  if (orderdetails.at !== undefined) {
    if (CF_decrypt(orderdetails.at) !== "") {
      decryptazuretoken = JSON.parse(CF_decrypt(orderdetails.at));
    }
  }

  $.session.set("usergroupname", decryptGroupName);
  $.session.set("multiusergroups", decryptGroupCode);
  $.session.set("Usersite", decryptSiteCode);
  $.session.set("Usersitename", decryptSiteName);
  $.session.set("User", Number(decryptUserID));
  $.session.set("tenantcode", decryptTenentID);
  $.session.set("Token", decrypttoken);
  $.session.set("tenantid", decryptTenentID);

  $.session.set("loggedfrom", decryptloggedfrom);
  $.session.set("azuretoken", decryptazuretoken);

  let tenantObj = { name: decryptTenentID };

  var response = await axios
    .post(
      "" + ELNurl + "/multitenant/ValidatetenantByName",
      await gzip(JSON.stringify(tenantObj)),
      {
        headers: {
          "X-TenantID": "MAIN",
          Accept: "application/json",
          "Content-Type": "application/json",
          "Content-Encoding": "gzip",
        },
      }
    )

    .then(async (res) => {
      let response = await formatdata(res.data);
      if (response !== undefined && response !== null) {
        let status =
          response.objResponse !== undefined && response.objResponse !== null
            ? response.objResponse.status
            : false;

        if (status) {
          $.session.set("tenantcode", response.id);
          $.session.set("tenantid", response.name);
          $.session.set("tenantdomain", response.tenantid);
          $.session.set("loginfrom", response.loginfrom);
          $.session.set("noofusers", response.noofusers);
          $.session.set("User", Number(decryptUserID));
          sessionStorage.setItem("licencetype", response.licencetype);
          sessionStorage.setItem("__lock", "1");

          let userObject = { usercode: Number(decryptUserID) };

          Servicecalls(
            "" + ELNurl + "/User/getUserOnCode",
            userObject,
            (res) => {
              Serviceprojectdetails(
                "" +
                document.getElementById("ELNname").value +
                "/Instrument/Getuserprojects",
                { usercode: $.session.get("User"), lssitemaster: { sitecode: $.session.get("Usersite") } }
              );

              let response = res;
              if (response !== undefined && response !== null) {
                $.session.set("User", response.usercode);
                $.session.set("Username", response.username);
                $.session.set("Usersite", response.lssitemaster.sitecode);
                $.session.set("Usersitename", response.lssitemaster.sitename);
                $.session.set(
                  "usergroupname",
                  response.multiusergroupcode[0].lsusergroup.usergroupname
                );
                $.session.set(
                  "userpasscodeencrypted",
                  response.encryptedpassword
                );

                if ($.session.get("loggedfrom") === "1") {
                  $.session.set("azuretoken", response.password);
                }
              }
            },
            (res) => { }
          );
        } else {
        }
      }
    })
    .catch((error) => { });

  return response;
}

async function linkSheetnontenantLogin(orderdetails) {
  let ELNurl = document.getElementById("ELNname").value;
  const decryptGroupCode = JSON.parse(CF_decrypt(orderdetails.uc));
  const decryptGroupName = JSON.parse(CF_decrypt(orderdetails.ug));
  const decryptSiteName = JSON.parse(CF_decrypt(orderdetails.sn));
  const decryptSiteCode = JSON.parse(CF_decrypt(orderdetails.sc));
  const decryptUserID = JSON.parse(CF_decrypt(orderdetails.u));
  // const decryptLogginID = JSON.parse(CF_decrypt(orderdetails.l));
  const decrypttoken = JSON.parse(CF_decrypt(orderdetails.k));

  $.session.set("usergroupname", decryptGroupName);
  $.session.set("multiusergroups", decryptGroupCode);
  $.session.set("Usersite", decryptSiteCode);
  $.session.set("Usersitename", decryptSiteName);
  $.session.set("User", Number(decryptUserID));
  $.session.set("Token", decrypttoken);

  $.session.set("User", Number(decryptUserID));

  let userObject = { usercode: Number(decryptUserID) };

  Servicecalls(
    "" + ELNurl + "/User/getUserOnCode",
    userObject,
    (res) => {
      Serviceprojectdetails(
        "" +
        document.getElementById("ELNname").value +
        "/Instrument/Getuserprojects",
        { usercode: $.session.get("User"), lssitemaster: { sitecode: $.session.get("Usersite") } }
      );

      let response = res;

      if (response !== undefined && response !== null) {
        $.session.set("User", response.usercode);
        $.session.set("Username", response.username);
        $.session.set("Usersite", response.lssitemaster.sitecode);
        $.session.set("Usersitename", response.lssitemaster.sitename);
        // $.session.set("usergroupname", response.lsusergroup.usergroupname);
        $.session.set(
          "usergroupname",
          response.multiusergroupcode[0].lsusergroup.usergroupname
        );
        $.session.set("ServerDateFormat", response.dformat);
        $.session.set("userpasscodeencrypted", response.encryptedpassword);

        if ($.session.get("loggedfrom") === "1") {
          $.session.set("azuretoken", response.password);
        }
      }
    },
    (res) => { }
  );
}

async function linkOTPLogin(orderdetails) {
  let ELNurl = document.getElementById("ELNname").value;
  const decryptTenentID = orderdetails.t;

  let tenantObj = { name: decryptTenentID };

  ServicecallsMAIN(
    "" + ELNurl + "/multitenant/ValidatetenantByName",
    tenantObj,
    onValidatetenantByNameSuccess.bind(this),
    onValidatetenantByNameFailure.bind(this)
  );
}

async function onValidatetenantByNameSuccess(res) {
  let response = await formatdata(res);
  if (response !== undefined && response !== null) {
    $.session.set("tenantcode", response.id);
    $.session.set("tenantid", response.name);
    $.session.set("tenantdomain", response.tenantid);
    $.session.set("loginfrom", response.loginfrom);
    $.session.set("noofusers", response.noofusers);
    sessionStorage.setItem("licencetype", response.licencetype);
    sessionStorage.setItem("__lock", "1");
  }
}

async function onValidatetenantByNameFailure(res) { }

async function linkLIMSLogin(orderdetails) {
  let ELNurl = document.getElementById("ELNname").value;

  let userObject = {
    username: orderdetails.username,
    password: orderdetails.password,
  };

  ServicecallsMAIN(
    "" + ELNurl + "/Login/LinkLogin",
    userObject,
    onLinkLoginSuccess.bind(this),
    onValidatetenantByNameFailure.bind(this)
  );
}

async function onLinkLoginSuccess(res) {
  let ELNurl = document.getElementById("ELNname").value;
  let response = await formatdata(res);
  if (response !== undefined && response !== null) {
    $.session.set("User", response.usercode);
    $.session.set("Username", response.username);
    $.session.set("Usersite", response.lssitemaster.sitecode);
    $.session.set("Usersitename", response.lssitemaster.sitename);
    // $.session.set("usergroupname", response.lsusergroup.usergroupname);
    $.session.set(
      "usergroupname",
      response.multiusergroupcode[0].lsusergroup.usergroupname
    );

    $.session.set("ServerDateFormat", response.dformat);

    let passobject = {
      username: response.username,
      lssitemaster: response.lssitemaster,
    };

    Servicecalls(
      "" + ELNurl + "/Login/limsloginusertokengenarate",
      passobject,
      (response) => {
        let token = "Bearer " + response.token;
        $.session.set("Token", token);
      },
      (response) => { }
    );
  }
}

function capitalizeFirstLetter(stringvalue) {
  return stringvalue.charAt(0).toUpperCase() + stringvalue.slice(1);
}

function isNumericvalue(value) {
  let decimal = getDecimalSeparator();
  const regexpint = /^[0-9\b]+$/;

  if (value.length === 1 && value === decimal) {
    return false;
  } else if (value.length > 1 && value.split(decimal).length > 2) {
    return false;
  } else if (
    !regexpint.test(value) &&
    value.charAt(value.length - 1) === decimal
  ) {
    return true;
  } else if (!regexpint.test(value.replace(decimal, ""))) {
    return false;
  }

  return true;
}

function getDecimalSeparator() {
  let locale = localStorage.getItem("ElnLang");
  const numberWithDecimalSeparator = 1.1;
  return Intl.NumberFormat(locale)
    .formatToParts(numberWithDecimalSeparator)
    .find((part) => part.type === "decimal").value;
}

function replacedecimalwithdot(value) {
  let decimal = getDecimalSeparator();
  return value.replace(decimal, ".");
}

function replacedotwithdecimal(value) {
  let decimal = getDecimalSeparator();
  return value.replace(".", decimal);
}

const _MS_PER_DAY = 1000 * 60 * 60 * 24;

// a and b are javascript Date objects
function dateDiffInDays(a, b) {
  // Discard the time and time-zone information.
  const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
  const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

  return Math.floor((utc2 - utc1) / _MS_PER_DAY);
}

function setDateAsuserDefined(dateGet, dateformat) {
  let date;
  let dformat = dateformat;
  let dateValue;

  if (dateGet !== null && dateGet.toString() === "Invalid Date") {
    dateValue = new Date();
  } else {
    dateValue = new Date(dateGet);
  }

  const formatterMonth = new Intl.DateTimeFormat("en", { month: "short" });
  const formatterDay = new Intl.DateTimeFormat("en", { weekday: "short" });
  let montname;
  let dayname;

  try {
    montname = formatterMonth.format(new Date(dateValue));
    dayname = formatterDay.format(new Date(dateValue));
  } catch { }

  let getHours = dateValue.getHours() + "";
  let getMinutes = dateValue.getMinutes() + "";
  let getSeconds = dateValue.getSeconds() + "";

  let day = dateValue.getDate() + "";
  let year = dateValue.getFullYear();
  let monthAsNum = dateValue.getMonth() + 1 + "";

  // if (monthAsNum.trim().length === 1) {
  //   if (Number(monthAsNum.trim()) !== 9) {
  //     monthAsNum = "0" + (Number(monthAsNum.trim()) + 1);
  //     monthAsNum = monthAsNum.trim();
  //   } else {
  //     monthAsNum = Number(monthAsNum.trim()) + 1;
  //   }
  // }

  if (monthAsNum.trim().length === 1) {
    monthAsNum = "0" + monthAsNum.trim();
  }

  if (day.trim().length === 1) {
    day = "0" + Number(day.trim());
    day = day.trim();
  }
  if (getHours.trim().length === 1) {
    getHours = "0" + Number(getHours.trim());
    getHours = getHours.trim();
  }
  if (getMinutes.trim().length === 1) {
    getMinutes = "0" + Number(getMinutes.trim());
    getMinutes = getMinutes.trim();
  }
  if (getSeconds.trim().length === 1) {
    getSeconds = "0" + Number(getSeconds.trim());
    getSeconds = getSeconds.trim();
  }

  // let time = getHours +" :" + getMinutes + getSeconds
  date = dayname + " " + montname + " " + day + " " + year;

  if (dformat === "Mon-DD-YYYY HH:mm:ss") {
    //(Feb 17, 2009)
    date = montname + "-" + day + "-" + year;
  } else if (dformat === "Yr, Month D HH:mm:ss") {
    // 2009, February 17
    date = year + ", " + montname + " " + day;
  } else if (dformat === "D Month, Yr HH:mm:ss") {
    // 17 February, 2009
    date = day + " " + montname + ", " + year;
  } else if (dformat === "Month D, Yr HH:mm:ss") {
    // February 17, 2009
    date = montname + " " + day + ", " + year;
  } else if (dformat === "MM/DD/YY HH:mm:ss") {
    // 02 / 17 / 2009
    date = monthAsNum + "/" + day + "/" + year;
  } else if (dformat === "YYYY-MM-DD HH:mm:ss") {
    // 2022-01-28
    date = year + "-" + monthAsNum + "-" + day;
  } else if (dformat === "YY/MM/DD HH:mm:ss") {
    date = year + "/" + monthAsNum + "/" + day;
  } else if (dformat === "dd / MM / yyyy HH:mm:ss") {
    date = day + "/" + monthAsNum + "/" + year;
  } else if (dformat === "dd-MM-yy HH:mm:ss") {
    date = day + "-" + monthAsNum + "-" + year;
  } else if (dformat === "dd-MM-yy") {
    return (date = day + "-" + monthAsNum + "-" + year);
  } else if (dformat === "dd-MM-yy HH:mm:ss") {
    date = day + "-" + monthAsNum + "-" + year; // + " " + getHours + ":" + getMinutes;
  } else if (dformat === "dd-MM-yyyy HH:mm:ss") {
    date = day + "-" + monthAsNum + "-" + year;
    //  +
    // " " +
    // getHours +
    // ":" +
    // getMinutes +
    // ":" +
    // getSeconds;
  } else if (dformat === "yyyy. M. d HH:mm:ss") {
    date = year + "." + monthAsNum + "." + day;
  } else if (dformat === "UTC Format") {
    // let ampm = getHours >= 12 ? 'pm' : 'am';
    // let temp = " ";
    // date = day + "-" + monthAsNum + "-" + year + temp + getHours + ":" + getMinutes + ":" + getSeconds + temp + ampm + temp + "UTC";
    const dateutc = dateValue;
    // date =dateValue.toUTCString();
    //  Fri, 30 Oct 2020 11:13:4 GMT
    date =
      dayname +
      ", " +
      day +
      " " +
      montname +
      " " +
      year +
      " " +
      dateutc.getUTCHours() +
      ":" +
      dateutc.getUTCMinutes() +
      ":" +
      dateutc.getUTCSeconds() +
      " GMT";
    return date;
  } else if (dformat === "GMT Format") {
    date = dateValue.toGMTString();
    return date;
  }
  // else if (dformat === "ISO Format") {
  //   date = new Date(dateGet).toISOString();
  // }
  var timezone = "";
  // let userAgent = navigator.userAgent;
  // if (userAgent.match(/edg/i)) {
  timezone = new Date()
    .toLocaleTimeString("en-us", { timeZoneName: "short" })
    .split(" ")[2];
  if (timezone === undefined) {
    timezone = new Date()
      .toLocaleTimeString("en-us", { timeZoneName: "short" })
      .split(" ")[1];
  }

  // } else {
  //
  // }

  date =
    date +
    " " +
    getHours +
    ":" +
    getMinutes +
    ":" +
    getSeconds +
    " " +
    timezone;

  return date;
}

function AuditDatetimeDefined(dateGet, dateformat) {
  let date;
  let dformat = dateformat;

  if (dateGet !== null && dateGet.toString() === "Invalid Date") {
    date = new Date(new Date());
  } else {
    date = new Date(dateGet);
  }

  const formatterMonth = new Intl.DateTimeFormat("en", { month: "short" });
  const formatterDay = new Intl.DateTimeFormat("en", { weekday: "short" });
  const montname = formatterMonth.format(new Date(date));
  const dayname = formatterDay.format(new Date(date));

  const getHours = date.getHours();
  const getMinutes = date.getMinutes();
  const getSeconds = date.getSeconds();

  let day = date.getDate();
  let year = date.getFullYear();

  day = convertdoubledigitdate(day, undefined);
  let month = new Date(dateGet).getMonth() + 1;
  month = convertdoubledigitdate(undefined, month);

  let time = getHours + ":" + getMinutes + ":" + getSeconds;
  date = dayname + " " + montname + " " + day + " " + year;

  if (dformat === "Mon-DD-YYYY HH:mm:ss") {
    //(Feb 17, 2009)
    date = montname + "-" + day + "-" + year + " " + time;
  } else if (dformat === "Yr, Month D HH:mm:ss") {
    // 2009, February 17
    date = year + ", " + montname + " " + day + " " + time;
  } else if (dformat === "D Month, Yr HH:mm:ss") {
    // 17 February, 2009
    date = day + " " + montname + ", " + year + " " + time;
  } else if (dformat === "Month D, Yr HH:mm:ss") {
    // February 17, 2009
    date = montname + " " + day + ", " + year + " " + time;
  } else if (dformat === "MM/DD/YY HH:mm:ss") {
    // 02 / 17 / 2009
    date =
      new Date(dateGet).getMonth() + 1 + "/" + day + "/" + year + " " + time;
  } else if (dformat === "YY/MM/DD HH:mm:ss") {
    date =
      // year + "/" + (new Date(dateGet).getMonth() + 1) + "/" + day + " " + time;
      year + "/" + month + "/" + day + " " + time;
  } else if (dformat === "dd / MM / yyyy HH:mm:ss") {
    date =
      // day + "/" + (new Date(dateGet).getMonth() + 1) + "/" + year + " " + time;
      day + "/" + month + "/" + year + " " + time;
  } else if (dformat === "dd-MM-yy HH:mm:ss") {
    date =
      // day + "-" + (new Date(dateGet).getMonth() + 1) + "-" + year + " " + time;
      day + "-" + month + "-" + year + " " + time;
  } else if (dformat === "dd-MM-yy HH:mm:ss") {
    date =
      day +
      "-" +
      // (new Date(dateGet).getMonth() + 1) +
      month +
      "-" +
      year +
      " " +
      getHours +
      ":" +
      getMinutes +
      ":" +
      getSeconds;
  } else if (dformat === "dd-MM-yyyy HH:mm:ss") {
    date =
      day +
      "-" +
      // (new Date(dateGet).getMonth() + 1) +
      month +
      "-" +
      year +
      " " +
      getHours +
      ":" +
      getMinutes +
      ":" +
      getSeconds;
  } else if (dformat === "yyyy. M. d HH:mm:ss") {
    date =
      // year + "." + (new Date(dateGet).getMonth() + 1) + "." + day + " " + time;
      year + "." + month + "." + day + " " + time;
  } else if (dformat === "UTC Format") {
    // date = new Date(dateGet).toUTCString();
    const dateutc = dateGet;
    // date =dateValue.toUTCString();
    //  Fri, 30 Oct 2020 11:13:4 GMT
    date =
      dayname +
      ", " +
      day +
      " " +
      montname +
      " " +
      year +
      " " +
      dateutc.getUTCHours() +
      ":" +
      dateutc.getUTCMinutes() +
      ":" +
      dateutc.getUTCSeconds() +
      " GMT";
    return date;
  } else if (dformat === "GMT Format") {
    date = new Date(dateGet).toGMTString();
  } else if (dformat === "MM-dd-yyyy hh:mm:ss") {
    date =
      // new Date(dateGet).getMonth() +
      // 1 +
      month +
      "-" +
      day +
      "-" +
      year +
      " " +
      getHours +
      ":" +
      getMinutes +
      ":" +
      getSeconds;
  }
  // else if (dformat === "ISO Format") {
  //   date = new Date(dateGet).toISOString();
  // }

  return date;
}

function setDateTimeAsuserDefined(dateGet, dateformat) {
  let date;
  let dformat = dateformat;

  if (dateGet !== null && dateGet.toString() === "Invalid Date") {
    date = new Date(new Date());
  } else {
    date = new Date(dateGet);
  }

  const formatterMonth = new Intl.DateTimeFormat("en", { month: "short" });
  const formatterDay = new Intl.DateTimeFormat("en", { weekday: "short" });
  const montname = formatterMonth.format(new Date(date));
  const dayname = formatterDay.format(new Date(date));

  let day = date.getDate();
  let year = date.getFullYear();

  let hour = addZero(date.getHours());
  let minute = addZero(date.getMinutes());
  let secound = addZero(date.getSeconds());

  date = dayname + " " + montname + " " + day + " " + year;

  if (dformat === "Mon-DD-YYYY HH:mm:ss") {
    //(Feb 17, 2009)
    date =
      montname +
      "-" +
      day +
      "-" +
      year +
      " " +
      hour +
      ":" +
      minute +
      ":" +
      secound;
  } else if (dformat === "Yr, Month D HH:mm:ss") {
    // 2009, February 17
    date =
      year +
      ", " +
      montname +
      " " +
      day +
      " " +
      hour +
      ":" +
      minute +
      ":" +
      secound;
  } else if (dformat === "D Month, Yr HH:mm:ss") {
    // 17 February, 2009
    date =
      day +
      " " +
      montname +
      ", " +
      year +
      " " +
      hour +
      ":" +
      minute +
      ":" +
      secound;
  } else if (dformat === "Month D, Yr HH:mm:ss") {
    // February 17, 2009
    date =
      montname +
      " " +
      day +
      ", " +
      year +
      " " +
      hour +
      ":" +
      minute +
      ":" +
      secound;
  } else if (dformat === "MM/DD/YY HH:mm:ss") {
    // 02 / 17 / 2009
    date =
      new Date(dateGet).getMonth() +
      1 +
      "/" +
      day +
      "/" +
      year +
      " " +
      hour +
      ":" +
      minute +
      ":" +
      secound;
  } else if (dformat === "YY/MM/DD HH:mm:ss") {
    date =
      year +
      "/" +
      (new Date(dateGet).getMonth() + 1) +
      "/" +
      day +
      " " +
      hour +
      ":" +
      minute +
      ":" +
      secound;
  } else if (dformat === "dd / MM / yyyy HH:mm:ss") {
    date =
      day +
      "/" +
      (new Date(dateGet).getMonth() + 1) +
      "/" +
      year +
      " " +
      hour +
      ":" +
      minute +
      ":" +
      secound;
  }
  return date;
}

function addZero(i) {
  if (i < 10) {
    i = "0" + i;
  }
  return i;
}

async function Servicecalls(
  url,
  data,
  success,
  failure,
  auditurl,
  auditdata,
  auditsuccess,
  auditfailure
) {
  axios
    .post(url, await gzip(JSON.stringify(data)), {
      headers: {
        Authorization: $.session.get("Token"),
        "X-TenantID": $.session.get("tenantid"),
        Accept: "application/json",
        "Content-Type": "application/json",
        "Content-Encoding": "gzip",
      },
      responseType: "arraybuffer",
    })
    .then((response) => {
      if (
        auditurl !== undefined &&
        auditdata !== undefined &&
        auditsuccess !== undefined &&
        auditfailure
      ) {
        Servicecalls(auditurl, auditdata, auditsuccess, auditfailure);
      }
      const compressedData = new Uint8Array(response.data);
      const decompressedData = pako.inflate(compressedData, { to: "string" });
      success(formatdata(JSON.parse(decompressedData)), data);
    })
    .catch((error) => {
      failure(error);
    });
}

function Servicecallsforfiles(
  url,
  data,
  success,
  failure,
  auditurl,
  auditdata,
  auditsuccess,
  auditfailure
) {
  axios
    .post(url, data, {
      headers: {
        Authorization: $.session.get("Token"),
        "X-TenantID": $.session.get("tenantid"),
        Accept: "application/json",
      },
      responseType: "arraybuffer",
    })
    .then((response) => {
      if (
        auditurl !== undefined &&
        auditdata !== undefined &&
        auditsuccess !== undefined &&
        auditfailure
      ) {
        Servicecalls(auditurl, auditdata, auditsuccess, auditfailure);
      }
      const compressedData = new Uint8Array(response.data);
      const decompressedData = pako.inflate(compressedData, { to: "string" });
      success(formatdata(JSON.parse(decompressedData)), data);
    })
    .catch((error) => {
      failure(error);
    });
}

function ServicecallsforSDMS(
  url,
  data,
  success,
  failure,
  auditurl,
  auditdata,
  auditsuccess,
  auditfailure
) {
  axios
    .post(url, data, {
      headers: {
        Authorization: $.session.get("Token"),
        "X-TenantID": $.session.get("tenantid"),
        Accept: "application/json",
      },
    })
    .then((response) => {
      if (
        auditurl !== undefined &&
        auditdata !== undefined &&
        auditsuccess !== undefined &&
        auditfailure
      ) {
        Servicecalls(auditurl, auditdata, auditsuccess, auditfailure);
      }
      success(formatdata(response.data));
    })
    .catch((error) => {
      failure(error);
    });
}

function Servicecallsforwithougzip(
  url,
  data,
  success,
  failure,
  auditurl,
  auditdata,
  auditsuccess,
  auditfailure
) {
  axios
    .post(url, data, {
      headers: {
        Authorization: $.session.get("Token"),
        "X-TenantID": $.session.get("tenantid"),
        Accept: "application/json",
      },
      // responseType: 'arraybuffer'
    })
    .then((response) => {
      // const compressedData = new Uint8Array(response.data);
      // const decompressedData = pako.inflate(compressedData, { to: 'string' });
      // success(formatdata(JSON.parse(decompressedData)), data);

      if (
        auditurl !== undefined &&
        auditdata !== undefined &&
        auditsuccess !== undefined &&
        auditfailure
      ) {
        Servicecalls(auditurl, auditdata, auditsuccess, auditfailure);
      }
      success(formatdata(response.data));
    })
    .catch((error) => {
      failure(error);
    });
}

async function ServicecallsMAIN(url, data, success, failure) {
  axios
    .post(url, await gzip(JSON.stringify(data)), {
      headers: {
        Authorization: $.session.get("Token"),
        "X-TenantID": "MAIN",
        Accept: "application/json",
        "Content-Type": "application/json",
        "Content-Encoding": "gzip",
      },
      responseType: "arraybuffer",
    })
    .then((response) => {
      const compressedData = new Uint8Array(response.data);
      const decompressedData = pako.inflate(compressedData, { to: "string" });
      success(formatdata(JSON.parse(decompressedData)));
    })
    .catch((error) => {
      failure(error);
    });
}
async function ServicecallsToken(url, data, success, failure) {
  axios
    .post(url, await gzip(JSON.stringify(data)), {
      headers: {
        // Authorization: $.session.get("Token"),
        "X-TenantID": "MAIN",
        Accept: "application/json",
        "Content-Type": "application/json",
        "Content-Encoding": "gzip",
      },
      responseType: "arraybuffer",
    })
    .then((response) => {
      const compressedData = new Uint8Array(response.data);
      const decompressedData = pako.inflate(compressedData, { to: "string" });
      success(formatdata(JSON.parse(decompressedData)));
    })
    .catch((error) => {
      failure(error);
    });
}

function Serviceuserworkflowdetails(url, data) {
  Servicecalls(
    url,
    data,
    (response) => {
      $.session.set("Userworkflows", JSON.stringify(response?.Sheet_Order));
      sessionStorage.setItem("Protocol_Userworkflows", JSON.stringify(response?.Protocol_Order))
    },
    (response) => { }
  );
}

function Serviceprojectteam(url, data, returnstatefunction) {
  // let teams = false;
  // let returnservice = undefined;
  Servicecalls(
    url,
    data,
    returnstatefunction,
    // (response) => {
    //   returnstatefunction(response)
    //   // return teams = response;
    //   // returnservice = true
    // },
    (response) => {
      // returnservice = false
    }
  );
}

function Serviceforuserrights(url, data) {
  Servicecalls(
    url,
    data,
    (response) => {
      response.User = sessionStorage.User;
      store.dispatch(updatecontrolrights(response));
    },
    (response) => { }
  );
}

function Serviceforauditconfig(url, data) {
  Servicecalls(
    url,
    data,
    (response) => {
      store.dispatch(addauditoption(response));
    },
    (response) => { }
  );
}

function Serviceforusersettings(url, data) {
  Servicecalls(
    url,
    data,
    (response) => {
      store.getState().Loggeduser.dformat =
        response !== undefined && response !== null
          ? response.dformat
          : "dd / MM / yyyy";

      $.session.set(
        "userDefinedDate",
        response !== undefined && response !== null
          ? response.dformat
          : "dd / MM / yyyy"
      );

      $.session.set(
        "ServerDateFormat",
        response !== undefined && response !== null
          ? response.dformat
          : "dd / MM / yyyy"
      );
    },
    (response) => { }
  );
}

async function Serviceforserverdateformat(url, data) {
  Servicecalls(
    url,
    data,
    (response) => {
      $.session.set("ServerDateFormat", response.serverDateFormat);
    },
    (response) => { }
  );
}

async function ServiceforMainFormUserLicense(url, data) {
  Servicecalls(
    url,
    data,
    (response) => {
      $.session.set("isLicensed", response);
    },
    (response) => { }
  );
}

async function Serviceprojectdetails(url, data) {
  Servicecalls(
    url,
    data,
    (response) => {
      $.session.set("Userprojects", JSON.stringify(uniq(response.project)));
      $.session.set("Userteam", JSON.stringify(response.team));
      $.session.set("teamusercode", JSON.stringify(response.teamuser));
    },
    (response) => { }
  );
}

function Getteamfromsession() {
  let teamvalue = null;
  let sessionvalue = $.session.get("Userteam");

  if (
    sessionvalue !== undefined &&
    sessionvalue !== null &&
    sessionvalue !== ""
  ) {
    let teamcodes = JSON.parse(sessionvalue);

    if (teamcodes !== undefined && teamcodes !== null && teamcodes.length > 0) {
      teamvalue = [];
      for (let index = 0; index < teamcodes.length; index++) {
        teamvalue.push({ teamcode: teamcodes[index] });
      }
    }
  }

  return teamvalue;
}

function Getteamusersfromsession() {
  let teamuservalue = null;
  let sessionvalue = $.session.get("teamusercode");

  if (
    sessionvalue !== undefined &&
    sessionvalue !== null &&
    sessionvalue !== ""
  ) {
    let teamusercodes = JSON.parse(sessionvalue);

    if (
      teamusercodes !== undefined &&
      teamusercodes !== null &&
      teamusercodes.length > 0
    ) {
      teamuservalue = [];
      if (teamusercodes.length > 0) {
        teamusercodes.map((items) => {
          teamuservalue.push({ usercode: items });
          return items;
        });
      }
      // for (let index = 0; index < teamusercodes.length; index++) {
      //   teamuservalue.push({ usercode: teamusercodes[index] });
      // }
    }
  }

  return teamuservalue;
}

function Getuserprojectfromsession() {
  let projectvalue = null;
  let sessionvalue = $.session.get("Userprojects");

  if (
    sessionvalue !== undefined &&
    sessionvalue !== null &&
    sessionvalue !== ""
  ) {
    let projectcodes = JSON.parse(sessionvalue);

    if (
      projectcodes !== undefined &&
      projectcodes !== null &&
      projectcodes.length > 0
    ) {
      projectvalue = [];
      for (let index = 0; index < projectcodes.length; index++) {
        projectvalue.push({ projectcode: projectcodes[index] });
      }
    }
  }

  return projectvalue;
}

function Getuserworkflowfromsession() {
  let workflowvalue = null;
  let sessionvalue = $.session.get("Userworkflows");

  if (
    sessionvalue !== undefined &&
    sessionvalue !== null &&
    sessionvalue !== ""
  ) {
    let workflowcodes = JSON.parse(sessionvalue);

    if (
      workflowcodes !== undefined &&
      workflowcodes !== null &&
      workflowcodes.length > 0
    ) {
      workflowvalue = [];
      for (let index = 0; index < workflowcodes.length; index++) {
        workflowvalue.push({ workflowcode: workflowcodes[index] });
      }
    }
  }

  return workflowvalue;
}
function Getprotocoluserworkflowfromsession() {
  let workflowvalue = null;
  let sessionvalue = $.session.get("Protocol_Userworkflows");

  if (
    sessionvalue !== undefined &&
    sessionvalue !== null &&
    sessionvalue !== ""
  ) {
    let workflowcodes = JSON.parse(sessionvalue);

    if (
      workflowcodes !== undefined &&
      workflowcodes !== null &&
      workflowcodes.length > 0
    ) {
      workflowvalue = [];
      for (let index = 0; index < workflowcodes.length; index++) {
        workflowvalue.push({ workflowcode: workflowcodes[index] });
      }
    }
  }

  return workflowvalue;
}
//subscription plan
function valiadateTenantPackage() {
  let packagetype = $.session.get("packagetype");
  //Free
  if (packagetype === "1") return "1";
  //Standard
  if (packagetype === "2") return "2";
  //Premium
  if (packagetype === "3") return "3";

  return "1";
}

function formatprotocolsteps(stepinfo) {
  let record = [];
  const doc = ReactHtmlParser(stepinfo);

  for (var i = 0; i < doc.length; i++) {
    if (
      doc[i].props !== undefined &&
      doc[i].props.children !== undefined &&
      doc[i].props.children.length > 0
    ) {
      for (let j = 0; j < doc[i].props.children.length; j++) {
        if (
          doc[i].props.children[j] !== undefined &&
          doc[i].props.children[j].type !== undefined
        ) {
          if (doc[i].props.children[j].type === "table") {
            if (
              doc[i].props.style !== undefined &&
              doc[i].props.style.width !== undefined
            ) {
              let width = doc[i].props.style.width.substring(
                0,
                doc[i].props.style.width.length - 2
              );
              let modifiedwidth = parseInt(width) * 100;
              doc[i].props.style.width = modifiedwidth + "px";
            }
          }
        }
      }
    }

    let container = <div key={"ckpreviewkey_" + i}>{doc[i]}</div>;
    record.push(container);
  }

  return record;
}

//help
async function onHelpClick(link, path) {
  let dataLink = link.toLowerCase();
  let ELNurl = document.getElementById("ELNname").value;
  ServicecallsToken(
    "" + ELNurl + "/helpdocument/getnodeonpage",
    { dataLink },
    onHelpClickSuccess.bind(path, this),
    onHelpClickFailure.bind(this)
  );
}

async function onHelpClickSuccess(path, res) {
  let node = res;
  if (node !== undefined && node !== null) {
    path = "/help#" + node.nodecode;
  } else {
    path = "/help";
  }
}
async function onHelpClickFailure(res) { }

function uniq(valArray) {
  var prims = { boolean: {}, number: {}, string: {} },
    objs = [];

  return valArray.filter(function (item) {
    var type = typeof item;
    if (type in prims)
      return prims[type].hasOwnProperty(item)
        ? false
        : (prims[type][item] = true);
    else return objs.indexOf(item) >= 0 ? false : objs.push(item);
  });
}

function decryptdata(object) {
  var key = CryptoJS.enc.Latin1.parse("1234567812345678");
  var iv = CryptoJS.enc.Latin1.parse("1234567812345678");

  var decrypted = CryptoJS.AES.decrypt(object.data.toString(), key, {
    iv: iv,
    padding: CryptoJS.pad.ZeroPadding,
  });
  var decryptedstring = decrypted.toString(CryptoJS.enc.Utf8);
  let lastoccuranceofbrace = decryptedstring.lastIndexOf("}");
  let lastoccuranceofarray = decryptedstring.lastIndexOf("]");
  let lastindex = decryptedstring.length;
  if (
    lastoccuranceofbrace !== undefined &&
    lastoccuranceofbrace > -1 &&
    lastoccuranceofarray !== undefined &&
    lastoccuranceofarray > -1
  ) {
    if (lastoccuranceofbrace > lastoccuranceofarray) {
      lastindex = lastoccuranceofbrace + 1;
    } else {
      lastindex = lastoccuranceofarray + 1;
    }
  } else if (lastoccuranceofbrace !== undefined && lastoccuranceofbrace > -1) {
    lastindex = lastoccuranceofbrace + 1;
  } else if (lastoccuranceofarray !== undefined && lastoccuranceofarray > -1) {
    lastindex = lastoccuranceofarray + 1;
  }

  var formattedstring = decryptedstring.substring(0, lastindex);
  return JSON.parse(formattedstring);
}

function encryptdata(data) {
  var key = CryptoJS.enc.Latin1.parse("1234567812345678");
  var iv = CryptoJS.enc.Latin1.parse("1234567812345678");
  var encrypted = CryptoJS.AES.encrypt(data, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.ZeroPadding,
  });

  // var decrypted = CryptoJS.AES.decrypt(encrypted, key, { iv: iv, padding: CryptoJS.pad.ZeroPadding });

  return { data: encrypted.toString() };
}

function formatdata(object) {
  try {
    // let data = object.data;
    // let compressData = object.data;
    // compressData = compressData.split('').map(function (e) {
    //   return e.charCodeAt(0);
    // });
    // let binData = new Uint8Array(compressData);
    // let datauncom = inflate(binData);
    // return String.fromCharCode.apply(null, new Uint16Array(datauncom));

    // var key = CryptoJS.enc.Latin1.parse('1234567812345678');
    // var iv = CryptoJS.enc.Latin1.parse('1234567812345678');

    // var decrypted = CryptoJS.AES.decrypt(object.toString(), key, { iv: iv, padding: CryptoJS.pad.ZeroPadding });
    // var decryptedstring = decrypted.toString(CryptoJS.enc.Utf8);
    // let lastoccuranceofbrace = decryptedstring.lastIndexOf('}');
    // let lastoccuranceofarray = decryptedstring.lastIndexOf(']');
    // let lastindex = decryptedstring.length;
    // if (lastoccuranceofbrace !== undefined && lastoccuranceofbrace > -1 &&
    //   lastoccuranceofarray !== undefined && lastoccuranceofarray > -1) {
    //   if (lastoccuranceofbrace > lastoccuranceofarray) {
    //     lastindex = lastoccuranceofbrace + 1;
    //   }
    //   else {
    //     lastindex = lastoccuranceofarray + 1;
    //   }
    // }
    // else if (lastoccuranceofbrace !== undefined && lastoccuranceofbrace > -1) {
    //   lastindex = lastoccuranceofbrace + 1;
    // }
    // else if (lastoccuranceofarray !== undefined && lastoccuranceofarray > -1) {
    //   lastindex = lastoccuranceofarray + 1;
    // }

    // var formattedstring = decryptedstring.substring(0, lastindex);
    return object;
  } catch (err) { }
  // return object;
}

function rangearray(start, end) {
  return Array(end - start + 1)
    .fill()
    .map((_, idx) => start + idx);
}

function validatesheetforversioning(originalsheet, range, data) {
  let doversion = false;
  let topLeftrow = range._ref.topLeft.row;
  let bottomRightrow = range._ref.bottomRight.row;
  let topLeftcol = range._ref.topLeft.col;
  let bottomRightcol = range._ref.bottomRight.col;
  let sheetname = range._sheet._sheetName;

  let originalsheets = originalsheet.sheets;

  var row = rangearray(topLeftrow, bottomRightrow);
  var col = rangearray(topLeftcol, bottomRightcol);

  if (
    originalsheets !== undefined &&
    originalsheets !== null &&
    originalsheets.length > 0
  ) {
    let sheetindex = -1;

    sheetindex = originalsheets.findIndex((sheet) => sheet.name === sheetname);
    if (sheetindex > -1) {
      row.forEach((rcount) => {
        col.forEach((ccount) => {
          originalsheets[sheetindex].rows.map((obj) => {
            if (obj.index === rcount) {
              obj.cells.map((objcell) => {
                if (objcell.index === ccount) {
                  if (objcell.tag !== undefined) {
                    let tagvalue = JSON.parse(objcell.tag);
                    if (
                      tagvalue.fieldname !== undefined &&
                      objcell.value !== "[" + tagvalue.fieldname + "]" &&
                      objcell.value !== data
                    ) {
                      doversion = true;
                    } else if (
                      tagvalue.Chemical !== undefined &&
                      tagvalue.Chemical &&
                      objcell.value !== data
                    ) {
                      doversion = true;
                    }
                  } else if (objcell.value !== data) {
                    doversion = true;
                  }
                }
                return objcell;
              });
            }
            return obj;
          });
        });
      });
    } else {
      doversion = false;
    }
  }

  return doversion;
}

function restrictSheetEditonFields(originalsheetdata) {
  let originalsheet = originalsheetdata.sheets;

  if (
    originalsheet !== undefined &&
    originalsheet !== null &&
    originalsheet.length > 0
  ) {
    originalsheet.map((objsheet, index) => {
      if (objsheet.name.trim() !== "Lims Batch") {
        objsheet.rows.map((objrow, rowindex) => {
          objrow.cells.map((objcell, colindex) => {
            if (objcell.tag !== undefined && objcell.tag !== null) {
              let tag = JSON.parse(objcell.tag);
              if (tag !== undefined && tag !== null) {
                objcell.enable = true;
              }
            }
            return objcell;
          });
          return objrow;
        });
      }
      return objsheet;
    });
  }
  return originalsheetdata;
}

function checkChangesinSheet(originalsheetdata, versionedsheetdata) {
  let doVersion = false;

  if (
    originalsheetdata !== undefined &&
    versionedsheetdata !== undefined &&
    originalsheetdata.sheets !== undefined &&
    versionedsheetdata.sheets !== undefined
  ) {
    let originalsheet = originalsheetdata.sheets;
    let versionedsheet = versionedsheetdata.sheets;

    if (
      originalsheet !== undefined &&
      originalsheet !== null &&
      originalsheet.length > 0 &&
      versionedsheet !== undefined &&
      versionedsheet !== null &&
      versionedsheet.length > 0
    ) {
      versionedsheet.map((objsheet, index) => {
        if (
          objsheet.rows !== undefined &&
          objsheet.rows.length > 0 &&
          objsheet.name.trim() !== "Lims Batch"
        ) {
          objsheet.rows.map((objrow, rowindex) => {
            if (objrow.cells !== undefined && objrow.cells.length > 0) {
              objrow.cells.map((objcell, colindex) => {
                let rowindexval = -1;

                if (
                  originalsheet[index] !== undefined &&
                  originalsheet[index].rows !== undefined
                ) {
                  rowindexval = originalsheet[index].rows.findIndex(
                    (row) => row.index === objrow.index
                  );
                }

                let colindexval = -1;
                if (
                  rowindexval > -1 &&
                  originalsheet[index].rows[rowindexval].cells !== undefined
                ) {
                  colindexval = originalsheet[index].rows[
                    rowindexval
                  ].cells.findIndex((col) => col.index === objcell.index);
                }

                if (colindexval !== -1) {
                  if (
                    originalsheet[index] !== undefined &&
                    originalsheet[index].rows[rowindexval] !== undefined &&
                    originalsheet[index].rows[rowindexval].cells[
                    colindexval
                    ] !== undefined
                  ) {
                    if (objcell.tag !== undefined) {
                      let tagvalue = JSON.parse(objcell.tag);

                      if (tagvalue !== undefined && tagvalue !== null) {
                        if (
                          tagvalue.FieldCode !== undefined &&
                          tagvalue.FieldCode !== null &&
                          tagvalue.FieldCode === "LINK" &&
                          tagvalue.Linktosheet !== undefined &&
                          tagvalue.Linktosheet !== null &&
                          tagvalue.Linktosheet === true
                        ) {
                          if (
                            originalsheet[index].rows[rowindexval].cells[
                              colindexval
                            ].value !== undefined &&
                            originalsheet[index].rows[rowindexval].cells[
                              colindexval
                            ].value
                              .toString()
                              .trim() !== "" &&
                            originalsheet[index].rows[rowindexval].cells[
                              colindexval
                            ].value !== objcell.value &&
                            originalsheet[index].rows[rowindexval].cells[
                              colindexval
                            ].value !==
                            "[" + tagvalue.fieldname + "]" &&
                            objcell.value !== undefined &&
                            typeof objcell.value !== "object"
                          ) {
                            doVersion = true;
                          }
                        } else if (
                          originalsheet[index].rows[rowindexval].cells[
                            colindexval
                          ].value !== undefined &&
                          originalsheet[index].rows[rowindexval].cells[
                            colindexval
                          ].value !== objcell.value &&
                          originalsheet[index].rows[rowindexval].cells[
                            colindexval
                          ].value !==
                          "[" + tagvalue.fieldname + "]" &&
                          objcell.value !== undefined &&
                          objcell.formula === undefined &&
                          typeof objcell.value !== "object"
                        ) {
                          doVersion = true;
                        }
                        //dont remove below code

                        // else if(originalsheet[index].rows[rowindexval].cells[colindexval].value !== undefined && objcell.value === undefined){
                        //   // after deleting new cell come here
                        //   if (
                        //     (originalsheet[index].rows[rowindexval].cells[colindexval].value !== undefined &&
                        //     originalsheet[index].rows[rowindexval].cells[colindexval].value !== tagvalue.value &&
                        //     originalsheet[index].rows[rowindexval].cells[colindexval].value !== "[" + tagvalue.fieldname + "]" &&
                        //     tagvalue.value !== undefined &&  objcell.formula === undefined && typeof objcell.value !== "object") === true
                        //   ) {
                        //     doVersion = true;
                        //   }
                        // }else if(objcell.value !== undefined && originalsheet[index].rows[rowindexval].cells[colindexval].value === undefined){
                        //   // making entry on deleted cell
                        //   let tagvalue1 = JSON.parse(originalsheet[index].rows[rowindexval].cells[colindexval].tag);
                        //   console.log(tagvalue1.value)
                        //   console.log(objcell.value)
                        //   if (
                        //     (tagvalue1 !== null && (tagvalue1.value !== objcell.value) &&
                        //     tagvalue1.value !== "[" + tagvalue1.fieldname + "]" &&
                        //     objcell.value !== undefined && objcell.formula === undefined &&
                        //     typeof objcell.value !== "object") === true
                        //   ) {
                        //     console.log(((tagvalue1.value !== objcell.value)  &&
                        //     tagvalue1.value !== "[" + tagvalue1.fieldname + "]" &&
                        //     objcell.value !== undefined && objcell.formula === undefined &&
                        //     typeof objcell.value !== "object") === true)
                        //     doVersion = true;
                        //   }
                        // }
                      }
                    } else if (
                      objcell.value !== undefined &&
                      originalsheet[index].rows[rowindexval] !== undefined &&
                      originalsheet[index].rows[rowindexval].cells[
                      colindexval
                      ] !== undefined &&
                      originalsheet[index].rows[rowindexval].cells[colindexval]
                        .value !== undefined &&
                      objcell.value !==
                      originalsheet[index].rows[rowindexval].cells[
                        colindexval
                      ].value &&
                      typeof originalsheet[index].rows[rowindexval].cells[
                        colindexval
                      ].value !== "object" &&
                      objcell.formula === undefined &&
                      typeof objcell.value !== "object"
                    ) {
                      doVersion = true;
                    }
                  } else if (
                    objcell.value !== undefined &&
                    objcell.formula === undefined &&
                    typeof objcell.value !== "object"
                  ) {
                    doVersion = true;
                  }
                }
                return objcell;
              });
            }
            return objrow;
          });
        }
        return objsheet;
      });
    }
  }
  return doVersion;
}

function Sheetchangeornotfunction(originalsheetdata, versionedsheetdata) {
  let doVersion = false;

  if (
    originalsheetdata !== undefined &&
    versionedsheetdata !== undefined &&
    originalsheetdata.sheets !== undefined &&
    versionedsheetdata.sheets !== undefined
  ) {
    let originalsheet = originalsheetdata.sheets;
    let versionedsheet = versionedsheetdata.sheets;

    if (
      originalsheet !== undefined &&
      originalsheet !== null &&
      originalsheet.length > 0 &&
      versionedsheet !== undefined &&
      versionedsheet !== null &&
      versionedsheet.length > 0
    ) {
      if (versionedsheet.length === originalsheet.length) {
        versionedsheet.some((objsheet, index) => {
          if (
            objsheet.rows !== undefined &&
            objsheet.rows.length > 0 &&
            objsheet.name.trim() !== "Lims Batch"
          ) {
            if (
              objsheet !== undefined &&
              originalsheet[index].rows.length === objsheet.rows.length
            ) {
              objsheet.rows.some((objrow, rowindex) => {
                if (objrow.cells !== undefined && objrow.cells.length > 0) {
                  objrow.cells.some((objcell, colindex) => {
                    let rowindexval = -1;

                    if (
                      originalsheet[index] !== undefined &&
                      originalsheet[index].rows !== undefined
                    ) {
                      rowindexval = originalsheet[index].rows.findIndex(
                        (row) => row.index === objrow.index
                      );
                    }

                    let colindexval = -1;
                    if (
                      rowindexval > -1 &&
                      originalsheet[index].rows[rowindexval].cells !== undefined
                    ) {
                      colindexval = originalsheet[index].rows[
                        rowindexval
                      ].cells.findIndex((col) => col.index === objcell.index);
                    }

                    if (colindexval !== -1) {
                      if (
                        originalsheet[index] !== undefined &&
                        originalsheet[index].rows[rowindexval] !== undefined &&
                        originalsheet[index].rows[rowindexval].cells[
                        colindexval
                        ] !== undefined
                      ) {
                        if (
                          objcell.value !== undefined &&
                          originalsheet[index].rows[rowindexval] !==
                          undefined &&
                          originalsheet[index].rows[rowindexval].cells[
                          colindexval
                          ] !== undefined &&
                          originalsheet[index].rows[rowindexval].cells[
                            colindexval
                          ].value !== undefined &&
                          objcell.value !==
                          originalsheet[index].rows[rowindexval].cells[
                            colindexval
                          ].value &&
                          typeof originalsheet[index].rows[rowindexval].cells[
                            colindexval
                          ].value !== "object" &&
                          objcell.formula === undefined &&
                          typeof objcell.value !== "object"
                        ) {
                          doVersion = true;
                          return true;
                        } else if (
                          objcell.value === undefined &&
                          originalsheet[index].rows[rowindexval].cells[
                            colindexval
                          ].value !== undefined
                        ) {
                          doVersion = true;
                          return true;
                        }
                      }
                    }
                    if (doVersion) {
                      return true;
                    }
                    return false;
                  });
                }
                if (doVersion) {
                  return true;
                }
                return false;
                // return objrow;
              });
            } else {
              doVersion = true;
              return true;
            }
          }
          if (doVersion) {
            return true;
          }
          return false;
        });
      } else {
        doVersion = true;
        return true;
      }
    }
  }

  return doVersion;
}

function Sheetchangeornotfunctionforsheet(
  originalsheetdata,
  versionedsheetdata
) {
  let doVersion = false;
  // let originalchart = originalsheetdata.charts
  // let versionedchart = versionedsheetdata.charts
  if (
    originalsheetdata !== undefined &&
    versionedsheetdata !== undefined &&
    originalsheetdata.sheets !== undefined &&
    versionedsheetdata.sheets !== undefined
  ) {
    let originalsheet = versionedsheetdata.sheets;
    let versionedsheet = originalsheetdata.sheets;

    if (
      originalsheet !== undefined &&
      originalsheet !== null &&
      originalsheet.length > 0 &&
      versionedsheet !== undefined &&
      versionedsheet !== null &&
      versionedsheet.length > 0
    ) {
      if (versionedsheet.length === originalsheet.length) {
        versionedsheet.some((objsheet, index) => {
          if (
            objsheet.rows !== undefined &&
            objsheet.rows.length > 0 &&
            objsheet.name.trim() !== "Lims Batch"
          ) {
            if (
              objsheet !== undefined &&
              originalsheet[index].rows.length === objsheet.rows.length
            ) {
              objsheet.rows.some((objrow, rowindex) => {
                if (objrow.cells !== undefined && objrow.cells.length > 0) {
                  objrow.cells.some((objcell, colindex) => {
                    let rowindexval = -1;
                    if (
                      originalsheet[index] !== undefined &&
                      originalsheet[index].rows !== undefined
                    ) {
                      rowindexval = originalsheet[index].rows.findIndex(
                        (row) => row.index === objrow.index
                      );
                    }

                    let colindexval = -1;
                    if (
                      rowindexval > -1 &&
                      originalsheet[index].rows[rowindexval].cells !== undefined
                    ) {
                      colindexval = originalsheet[index].rows[
                        rowindexval
                      ].cells.findIndex((col) => col.index === objcell.index);
                    }
                    if (colindexval !== -1) {
                      if (
                        originalsheet[index] !== undefined &&
                        originalsheet[index].rows[rowindexval] !== undefined &&
                        originalsheet[index].rows[rowindexval].cells[
                        colindexval
                        ] !== undefined
                      ) {
                        if (
                          objcell.value !== undefined &&
                          originalsheet[index].rows[rowindexval] !==
                          undefined &&
                          originalsheet[index].rows[rowindexval].cells[
                          colindexval
                          ] !== undefined &&
                          originalsheet[index].rows[rowindexval].cells[
                            colindexval
                          ].value !== undefined &&
                          objcell.value !==
                          originalsheet[index].rows[rowindexval].cells[
                            colindexval
                          ].value &&
                          typeof originalsheet[index].rows[rowindexval].cells[
                            colindexval
                          ].value !== "object" &&
                          objcell.formula === undefined &&
                          typeof objcell.value !== "object"
                        ) {
                          doVersion = true;
                        } else if (
                          objcell.value !== undefined &&
                          objcell.tag !== undefined &&
                          originalsheet[index].rows[rowindexval] !==
                          undefined &&
                          originalsheet[index].rows[rowindexval].cells[
                          colindexval
                          ] !== undefined &&
                          objcell.value !==
                          originalsheet[index].rows[rowindexval].cells[
                            colindexval
                          ].value &&
                          originalsheet[index].rows[rowindexval].cells[
                            colindexval
                          ].tag !== undefined &&
                          typeof originalsheet[index].rows[rowindexval].cells[
                            colindexval
                          ].value !== "object" &&
                          objcell.formula === undefined &&
                          typeof objcell.value !== "object"
                        ) {
                          doVersion = true;
                        } else if (
                          objcell.value === undefined &&
                          originalsheet[index].rows[rowindexval].cells[
                            colindexval
                          ].value !== undefined
                        ) {
                          doVersion = true;
                          return true;
                        }
                      }
                    } else {
                      doVersion = true;
                    }
                    if (doVersion) {
                      return true;
                    }
                    return false;
                  });
                }

                if (doVersion) {
                  return true;
                }
                return false;
              });
            } else {
              doVersion = true;
              return true;
            }
          } else if (
            objsheet !== undefined &&
            originalsheet[index].rows.length > 0
          ) {
            doVersion = true;
            return true;
          }
          if (doVersion) {
            return true;
          }
          return false;
        });
      } else {
        doVersion = true;
        return true;
      }
    }
  }
  return doVersion;
}

function sheetModifyiedOrNot(originalsheetdata, versionedsheetdata) {
  let doVersion = false;
  // let originalchart = originalsheetdata.charts
  // let versionedchart = versionedsheetdata.charts
  if (
    originalsheetdata !== undefined &&
    versionedsheetdata !== undefined &&
    originalsheetdata.sheets !== undefined &&
    versionedsheetdata.sheets !== undefined
  ) {
    let originalsheet = originalsheetdata.sheets;
    let versionedsheet = versionedsheetdata.sheets;

    if (
      originalsheet !== undefined &&
      originalsheet !== null &&
      originalsheet.length > 0 &&
      versionedsheet !== undefined &&
      versionedsheet !== null &&
      versionedsheet.length > 0
    ) {
      if (versionedsheet.length === originalsheet.length) {
        versionedsheet.some((objsheet, index) => {
          if (
            objsheet.rows !== undefined &&
            objsheet.rows.length > 0 &&
            objsheet.name.trim() !== "Lims Batch"
          ) {
            if (
              objsheet !== undefined &&
              originalsheet[index].rows.length === objsheet.rows.length
            ) {
              objsheet.rows.some((objrow, rowindex) => {
                if (objrow.cells !== undefined && objrow.cells.length > 0) {
                  objrow.cells.some((objcell, colindex) => {
                    let rowindexval = -1;

                    if (
                      originalsheet[index] !== undefined &&
                      originalsheet[index].rows !== undefined
                    ) {
                      rowindexval = originalsheet[index].rows.findIndex(
                        (row) => row.index === objrow.index
                      );
                    }

                    if (
                      objrow.cells.length ===
                      originalsheet[index].rows[rowindexval].cells.length
                    ) {
                      let colindexval = -1;
                      if (
                        rowindexval > -1 &&
                        originalsheet[index].rows[rowindexval].cells !==
                        undefined
                      ) {
                        colindexval = originalsheet[index].rows[
                          rowindexval
                        ].cells.findIndex((col) => col.index === objcell.index);
                      }
                      if (colindexval !== -1) {
                        if (
                          originalsheet[index] !== undefined &&
                          originalsheet[index].rows[rowindexval] !== undefined &&
                          originalsheet[index].rows[rowindexval].cells[colindexval] !== undefined
                        ) {
                          if (
                            objcell.value !== undefined &&
                            originalsheet[index].rows[rowindexval] !== undefined &&
                            originalsheet[index].rows[rowindexval].cells[colindexval] !== undefined &&
                            originalsheet[index].rows[rowindexval].cells[colindexval].value !== undefined &&
                            objcell.value !== originalsheet[index].rows[rowindexval].cells[colindexval].value &&
                            typeof originalsheet[index].rows[rowindexval].cells[colindexval].value !== "object" &&
                            objcell.formula === undefined && typeof objcell.value !== "object"
                          ) {
                            doVersion = true;
                          } else if (
                            objcell.value !== undefined && objcell.tag !== undefined &&
                            originalsheet[index].rows[rowindexval] !== undefined &&
                            originalsheet[index].rows[rowindexval].cells[colindexval] !== undefined &&
                            objcell.value !== originalsheet[index].rows[rowindexval].cells[colindexval].value &&
                            originalsheet[index].rows[rowindexval].cells[colindexval].tag !== undefined &&
                            typeof originalsheet[index].rows[rowindexval].cells[colindexval].value !== "object" &&
                            (originalsheet[index].rows[rowindexval].cells[colindexval].value !== undefined &&
                            originalsheet[index].rows[rowindexval].cells[colindexval].tag !== undefined) &&
                            objcell.formula === undefined && typeof objcell.value !== "object"
                          ) {
                            doVersion = true;
                          } else if (
                            originalsheet[index].rows[rowindexval] !== undefined &&
                            originalsheet[index].rows[rowindexval].cells[colindexval] !== undefined &&
                            originalsheet[index].rows[rowindexval].cells[colindexval].value !== undefined &&
                            objcell.value !== originalsheet[index].rows[rowindexval].cells[colindexval].value &&
                            typeof originalsheet[index].rows[rowindexval].cells[colindexval].value !== "object" &&
                            objcell.formula === undefined && typeof objcell.value !== "object"
                          ) {
                            doVersion = true;
                          }
                        }
                      } else {
                        doVersion = true;
                      }
                    } else {
                      doVersion = true;
                    }
                    if (doVersion) {
                      return true;
                    }
                    return false;
                  });
                }
                if (doVersion) {
                  return true;
                }
                return false;
              });
            } else {
              doVersion = true;
              return true;
            }
          } else if (
            objsheet !== undefined &&
            originalsheet[index].rows.length > 0
          ) {
            doVersion = true;
            return true;
          }
          if (doVersion) {
            return true;
          }
          return false;
        });
      } else {
        doVersion = true;
        return true;
      }
    }
  }
  return doVersion;
}

// function chartversionchages(originalchart, versionedchart) {
//   let doVersion = false
//   if (originalchart.length !== 0 &&
//     originalchart !== undefined
//     && originalchart !== null
//     && versionedchart !== undefined &&
//     versionedchart.length !== undefined && versionedchart.length !== 0
//     && versionedchart !== null ) {
//     let value = false;
//     let versionedchartlenth;
//     for (let i = 0; i < originalchart.length; i++) {
//       if (value) {
//         doVersion = true
//         break
//       }
//       for (let j = 0; j < versionedchart.length; j++) {
//         if (value) {
//           doVersion = true
//           break
//         }
//         if (versionedchart[j].series[j] !== undefined && originalchart[i].series[i] !== undefined) {
//           versionedchartlenth = versionedchart[j].series[j].points.length
//           value = originalchart[i].series[i].points.length === versionedchartlenth ? false : true
//         }

//       }

//     }
//   }
//   //  else {
//   //   return doVersion = true
//   // }
//   return doVersion
// }

function markversionchanges(
  originalsheetdata,
  versionedsheetdata,
  displaytype
) {
  let originalsheet = originalsheetdata.sheets;
  let versionedsheet = versionedsheetdata.sheets;

  if (
    originalsheet !== undefined &&
    originalsheet !== null &&
    originalsheet.length > 0 &&
    versionedsheet !== undefined &&
    versionedsheet !== null &&
    versionedsheet.length > 0
  ) {
    versionedsheet.map((objsheet, index) => {
      let sheetIndex = originalsheet.findIndex(
        (sheet) => objsheet.name === sheet.name
      );

      if (sheetIndex > -1) {
        objsheet.rows.map((objrow, rowindex) => {
          objrow.cells.map((objcell, colindex) => {
            let rowindexval = originalsheet[sheetIndex].rows.findIndex(
              (row) => row.index === objrow.index
            );

            let colindexval = -1;
            if (rowindexval > -1) {
              colindexval = originalsheet[sheetIndex].rows[
                rowindexval
              ].cells.findIndex((col) => col.index === objcell.index);
            }
            if (
              originalsheet[sheetIndex] !== undefined &&
              originalsheet[sheetIndex].rows[rowindexval] !== undefined &&
              originalsheet[sheetIndex].rows[rowindexval].cells[colindexval] !==
              undefined
            ) {
              if (objcell.tag !== undefined) {
                let newTag = originalsheet[sheetIndex].rows[rowindexval].cells[colindexval].tag !== undefined ? JSON.parse(originalsheet[sheetIndex].rows[rowindexval].cells[colindexval].tag) : undefined;
                let oldTag = JSON.parse(objcell.tag);
                let fieldname = "[" + oldTag.fieldname + "]";
                if (newTag === undefined) {
                  objcell.background = "#ffcccc";
                  objcell.borderBottom = { color: "#ff0000", size: 1 };
                  objcell.borderLeft = { color: "#ff0000", size: 1 };
                  objcell.borderRight = { color: "#ff0000", size: 1 };
                  objcell.borderTop = { color: "#ff0000", size: 1 };
                } else if (displaytype === 0) {
                  if (
                    originalsheet[sheetIndex].rows[rowindexval].cells[colindexval].value !== undefined &&
                    originalsheet[sheetIndex].rows[rowindexval].cells[colindexval].value !== objcell.value &&
                    typeof objcell.value !== "object"
                  ) {
                    objcell.background = "#ffcccc";
                    objcell.borderBottom = { color: "#ff0000", size: 1 };
                    objcell.borderLeft = { color: "#ff0000", size: 1 };
                    objcell.borderRight = { color: "#ff0000", size: 1 };
                    objcell.borderTop = { color: "#ff0000", size: 1 };
                  } else if (
                    originalsheet[sheetIndex].rows[rowindexval].cells[colindexval].value !== undefined &&
                    originalsheet[sheetIndex].rows[rowindexval].cells[colindexval].value === objcell.value &&
                    newTag.Parameter !== undefined &&
                    oldTag.Parameter === undefined
                  ) {
                    objcell.background = "#ffcccc";
                    objcell.borderBottom = { color: "#ff0000", size: 1 };
                    objcell.borderLeft = { color: "#ff0000", size: 1 };
                    objcell.borderRight = { color: "#ff0000", size: 1 };
                    objcell.borderTop = { color: "#ff0000", size: 1 };
                  } else if (newTag.FieldCode === 'LINK' &&
                    originalsheet[sheetIndex].rows[rowindexval].cells[colindexval].value !== objcell.value &&
                    typeof objcell.value !== "object"
                  ) {
                    objcell.background = "#ffcccc";
                    objcell.borderBottom = { color: "#ff0000", size: 1 };
                    objcell.borderLeft = { color: "#ff0000", size: 1 };
                    objcell.borderRight = { color: "#ff0000", size: 1 };
                    objcell.borderTop = { color: "#ff0000", size: 1 };
                  }
                } else {
                  if (
                    originalsheet[sheetIndex].rows[rowindexval].cells[colindexval].value !== undefined &&
                    originalsheet[sheetIndex].rows[rowindexval].cells[colindexval].value !== objcell.value &&
                    originalsheet[sheetIndex].rows[rowindexval].cells[colindexval].value !== fieldname &&
                    objcell.value !== fieldname &&
                    typeof objcell.value !== "object"
                  ) {

                    if (objcell.tag !== undefined) {
                      let tagField = JSON.parse(objcell.tag).FieldCode !== undefined && JSON.parse(objcell.tag).FieldCode !== null ? JSON.parse(objcell.tag).FieldCode : undefined;
                      if (tagField === 'LINK') {

                      } else {
                        objcell.background = "#ffcccc";
                        objcell.borderBottom = { color: "#ff0000", size: 1 };
                        objcell.borderLeft = { color: "#ff0000", size: 1 };
                        objcell.borderRight = { color: "#ff0000", size: 1 };
                        objcell.borderTop = { color: "#ff0000", size: 1 };
                      }
                    }
                  } else if (
                    originalsheet[sheetIndex].rows[rowindexval].cells[
                      colindexval
                    ].value !== undefined &&
                    originalsheet[sheetIndex].rows[rowindexval].cells[
                      colindexval
                    ].value === objcell.value &&
                    newTag.Parameter !== undefined &&
                    oldTag.Parameter === undefined
                  ) {
                    objcell.background = "#ffcccc";
                    objcell.borderBottom = { color: "#ff0000", size: 1 };
                    objcell.borderLeft = { color: "#ff0000", size: 1 };
                    objcell.borderRight = { color: "#ff0000", size: 1 };
                    objcell.borderTop = { color: "#ff0000", size: 1 };
                  }
                }
              } else if (
                objcell.value !== undefined &&
                typeof objcell.value !== "object" &&
                originalsheet[sheetIndex].rows[rowindexval].cells[colindexval]
                  .value !== undefined &&
                objcell.value !==
                originalsheet[sheetIndex].rows[rowindexval].cells[colindexval]
                  .value
              ) {
                objcell.background = "#ffcccc";
                objcell.borderBottom = { color: "#ff0000", size: 1 };
                objcell.borderLeft = { color: "#ff0000", size: 1 };
                objcell.borderRight = { color: "#ff0000", size: 1 };
                objcell.borderTop = { color: "#ff0000", size: 1 };
              }
            } else if (
              objcell.value !== undefined &&
              typeof objcell.value !== "object"
            ) {
              objcell.background = "#ffcccc";
              objcell.borderBottom = { color: "#ff0000", size: 1 };
              objcell.borderLeft = { color: "#ff0000", size: 1 };
              objcell.borderRight = { color: "#ff0000", size: 1 };
              objcell.borderTop = { color: "#ff0000", size: 1 };
            }
            return objcell;
          });
          return objrow;
        });
      }
      return objsheet;
    });
  }
  return versionedsheetdata;
}

function restrictVersioncomparion(originalsheetdata) {
  let originalsheet = originalsheetdata.sheets;

  if (
    originalsheet !== undefined &&
    originalsheet !== null &&
    originalsheet.length > 0
  ) {
    originalsheet.map((objsheet, index) => {
      objsheet.rows.map((objrow, rowindex) => {
        objrow.cells.map((objcell, colindex) => {
          objcell.enable = false;
          return objcell;
        });
        return objrow;
      });
      return objsheet;
    });
  }
  return originalsheetdata;
}

function removeByAttr(arr, attr, value) {
  var i = arr.length;
  while (i--) {
    if (
      arr[i] &&
      arr[i].hasOwnProperty(attr) &&
      arguments.length > 2 &&
      arr[i][attr] === value
    ) {
      arr.splice(i, 1);
    }
  }
  return arr;
}

function getGridorderColumns(columns, type) {
  let columnsValue = columns;

  if (Number(type) === 0) {
    removeByAttr(columnsValue, "header", "Sample");
    removeByAttr(columnsValue, "header", "Project");
    removeByAttr(columnsValue, "header", "Inventory");
  } else if (Number(type) === 5) {
    removeByAttr(columnsValue, "header", "Task");
    removeByAttr(columnsValue, "header", "Sample");
    removeByAttr(columnsValue, "header", "Inventory");
  } else if (Number(type) === 4) {
    removeByAttr(columnsValue, "header", "Task");
    removeByAttr(columnsValue, "header", "Sheet");
  }

  return columnsValue;
}

function timeout(ms) {
  return new Promise((res) => setTimeout(res, ms));
}

function CheckIsMergedandreturntags(
  spread,
  Mergedcells,
  tlRow,
  tlCol,
  btRow,
  btCol
) {
  let tag = undefined;
  let sheetindex = -1;

  if (Mergedcells !== undefined && Mergedcells.length > 0) {
    let MergedCellstoModify = undefined;

    if (spread.activeSheet() !== undefined) {
      sheetindex = spread.sheetIndex(spread.activeSheet());
    }

    for (let index = 0; index < Mergedcells.length; index++) {
      if (Mergedcells[index] !== undefined) {
        if (
          Mergedcells[index].bottomRight !== undefined &&
          Mergedcells[index].topLeft !== undefined
        ) {
          if (
            Mergedcells[index].topLeft.row === tlRow &&
            Mergedcells[index].bottomRight.row === btRow &&
            Mergedcells[index].topLeft.col === tlCol &&
            Mergedcells[index].bottomRight.col === btCol
          ) {
            MergedCellstoModify = Mergedcells[index];

            let row = 0;
            let col = 0;
            let toprow = 0;
            let topleft = 0;
            row = MergedCellstoModify.bottomRight.row;
            col = MergedCellstoModify.bottomRight.col;
            toprow = MergedCellstoModify.topLeft.row;
            topleft = MergedCellstoModify.topLeft.col;

            for (let rowindex = toprow; rowindex <= row; rowindex++) {
              for (let colindex = topleft; colindex <= col; colindex++) {
                tag = spread.activeSheet().range(rowindex, colindex).tag();
                if (tag !== undefined && tag !== null) {
                  tag = {
                    tagvalue: tag,
                    sheet: sheetindex,
                    row: rowindex,
                    col: colindex,
                  };
                }

                if (tag !== null && tag !== undefined) {
                  break;
                }
              }
              if (tag !== null && tag !== undefined) {
                break;
              }
            }
          } else {
            tag = spread.activeSheet().range(tlRow, tlCol).tag();
            if (tag !== undefined && tag !== null) {
              tag = {
                tagvalue: tag,
                sheet: sheetindex,
                row: tlRow,
                col: tlCol,
              };
            }
          }

          break;
        }
      }
    }
    if (tag !== undefined && tag !== null) {
      return tag;
    } else {
      return undefined;
    }
  } else {
    if (spread.activeSheet() !== undefined) {
      sheetindex = spread.sheetIndex(spread.activeSheet());
    }
    tag = spread.activeSheet().range(tlRow, tlCol).tag();
    if (tag !== undefined && tag !== null) {
      tag = {
        tagvalue: tag,
        sheet: sheetindex,
        row: tlRow,
        col: tlCol,
      };
    }
    return tag;
  }
}

function userDateFormat(date) {
  let data = setDateAsuserDefined(date, $.session.get("userDefinedDate"));
  return data;
}

function checksheetdiffandmodifychangesheet(
  spread,
  ref,
  e,
  sheettomodify,
  istemplate
) {
  if (
    spread === null ||
    sheettomodify === undefined ||
    sheettomodify.sheets === undefined
  )
    return;

  if (e.sender.sheets().length !== sheettomodify.sheets.length) {
    checksheetsandequlize(e, sheettomodify);
  }

  // if (e.sender._workbook._imagesval !== undefined && e.sender._workbook._imagesval.length > 0) {
  //   sheettomodify.images = e.sender._workbook._imagesval.filter(obj => obj !== null && obj !== undefined && !obj.candelete);
  // }

  if (
    e.sender._workbook._tags !== undefined &&
    e.sender._workbook._tags.length > 0
  ) {
    sheettomodify.tags = e.sender._workbook._tags;
  }

  if (
    e.sender._workbook._chart !== undefined &&
    e.sender._workbook._chart.length > 0
  ) {
    sheettomodify.charts = e.sender._workbook._chart;
  }

  if (spread.activeSheet().range(ref).value() !== null) {
    updatecell(
      spread,
      ref,
      "value",
      spread.activeSheet().range(ref).value(),
      sheettomodify
    );
    if (!istemplate) {
      updateCellAudit(
        spread,
        ref,
        "value",
        spread.activeSheet().range(ref).value(),
        sheettomodify
      );
    }
  } else {
    cleancell(
      spread,
      ref,
      "value",
      spread.activeSheet().range(ref).value(),
      sheettomodify
    );
  }

  if (spread.activeSheet().range(ref).bold() !== null) {
    updatecell(
      spread,
      ref,
      "bold",
      spread.activeSheet().range(ref).bold(),
      sheettomodify
    );
  } else {
    cleancell(
      spread,
      ref,
      "bold",
      spread.activeSheet().range(ref).bold(),
      sheettomodify
    );
  }

  if (spread.activeSheet().range(ref).italic() !== null) {
    updatecell(
      spread,
      ref,
      "italic",
      spread.activeSheet().range(ref).italic(),
      sheettomodify
    );
  } else {
    cleancell(
      spread,
      ref,
      "italic",
      spread.activeSheet().range(ref).italic(),
      sheettomodify
    );
  }

  if (spread.activeSheet().range(ref).underline() !== null) {
    updatecell(
      spread,
      ref,
      "underline",
      spread.activeSheet().range(ref).underline(),
      sheettomodify
    );
  } else {
    cleancell(
      spread,
      ref,
      "underline",
      spread.activeSheet().range(ref).underline(),
      sheettomodify
    );
  }

  if (spread.activeSheet().range(ref).borderLeft() !== null) {
    updatecell(
      spread,
      ref,
      "borderLeft",
      spread.activeSheet().range(ref).borderLeft(),
      sheettomodify
    );
  } else {
    cleancell(
      spread,
      ref,
      "borderLeft",
      spread.activeSheet().range(ref).borderLeft(),
      sheettomodify
    );
  }

  if (spread.activeSheet().range(ref).borderRight() !== null) {
    updatecell(
      spread,
      ref,
      "borderRight",
      spread.activeSheet().range(ref).borderRight(),
      sheettomodify
    );
  } else {
    cleancell(
      spread,
      ref,
      "borderRight",
      spread.activeSheet().range(ref).borderRight(),
      sheettomodify
    );
  }

  if (spread.activeSheet().range(ref).borderTop() !== null) {
    updatecell(
      spread,
      ref,
      "borderTop",
      spread.activeSheet().range(ref).borderTop(),
      sheettomodify
    );
  } else {
    cleancell(
      spread,
      ref,
      "borderTop",
      spread.activeSheet().range(ref).borderTop(),
      sheettomodify
    );
  }

  if (spread.activeSheet().range(ref).borderBottom() !== null) {
    updatecell(
      spread,
      ref,
      "borderBottom",
      spread.activeSheet().range(ref).borderBottom(),
      sheettomodify
    );
  } else {
    cleancell(
      spread,
      ref,
      "borderBottom",
      spread.activeSheet().range(ref).borderBottom(),
      sheettomodify
    );
  }

  if (spread.activeSheet().range(ref).color() !== null) {
    updatecell(
      spread,
      ref,
      "color",
      spread.activeSheet().range(ref).color(),
      sheettomodify
    );
  } else {
    cleancell(
      spread,
      ref,
      "color",
      spread.activeSheet().range(ref).color(),
      sheettomodify
    );
  }

  if (spread.activeSheet().range(ref).background() !== null) {
    updatecell(
      spread,
      ref,
      "background",
      spread.activeSheet().range(ref).background(),
      sheettomodify
    );
  } else {
    cleancell(
      spread,
      ref,
      "background",
      spread.activeSheet().range(ref).background(),
      sheettomodify
    );
  }

  // if (spread.activeSheet().range(ref).enable() !== null && spread.activeSheet().range(ref).enable() === false) {
  //   updatecell(spread, ref, "enable", spread.activeSheet().range(ref).enable(), sheettomodify);
  // }
  // else {
  //   cleancell(spread, ref, "enable", null, sheettomodify);
  // }

  // if (spread.activeSheet().range(ref).wrap() !== null && spread.activeSheet().range(ref).wrap() === true) {
  //   updatecell(spread, ref, "wrap", spread.activeSheet().range(ref).wrap(), sheettomodify);
  // }
  // else {
  //   cleancell(spread, ref, "wrap", null, sheettomodify);
  // }

  return sheettomodify;
}

function cellLevelAudit(oldValue, newValue, cellRange, sheetName) {
  // this.oldCellTag = this.state.kendoSpread.activeSheet().selection().tag();
  setTimeout(() => {
    const passObj = {
      objsilentaudit: SilentauditobjectForOrder(
        "view",
        "Sheet Orders",
        this.props.t("Orders.Sheetorders.IDS_AUDIT_CELLLEVEL", {
          // param0: this.state.orderDetails.batchid,
          param1: sheetName,
          param2: oldValue === null ? "-" : oldValue,
          param3: newValue,
          // param4: store.getState().Loggeduser.username,
          // rangeR: Number(cellRange.row)+1,
          rangeR: cellRange,
          // rangeC: this.convertToAlpha(cellRange.col),
        }),
        "",
        "LSlogilablimsorderdetail",
        this.state.orderDetails.batchcode
      ),
    };
    Servicecalls(
      "" + this.ELNurl + "/AuditTrail/silentRecordHandlerForOrder",
      passObj,
      (res) => { },
      (res) => { }
    );
  }, 1);
}

function updateCellAudit(spread, ref, key, value, sheettomodify) {
  let sheetname = spread.activeSheet()._sheetName;
  let sheetindex = sheettomodify.sheets.findIndex(
    (obj) => obj.name === sheetname
  );
  if (sheetindex > -1) {
    for (
      let rowindex = ref.topLeft.row;
      rowindex <= ref.bottomRight.row;
      rowindex++
    ) {
      for (
        let colindex = ref.topLeft.col;
        colindex <= ref.bottomRight.col;
        colindex++
      ) {
        if (sheettomodify.sheets[sheetindex].rows === undefined)
          sheettomodify.sheets[sheetindex].rows = [];
        let sheetrowindex = sheettomodify.sheets[sheetindex].rows.findIndex(
          (row) => row.index === rowindex
        );
        let sheetcolindex = -1;
        if (sheetrowindex > -1) {
          if (
            sheettomodify.sheets[sheetindex].rows[sheetrowindex].cells ===
            undefined
          )
            sheettomodify.sheets[sheetindex].rows[sheetrowindex].cells = [];

          sheetcolindex = sheettomodify.sheets[sheetindex].rows[
            sheetrowindex
          ].cells.findIndex((col) => col.index === colindex);
          if (
            sheetcolindex > -1 &&
            sheettomodify.sheets[sheetindex].rows[sheetrowindex].cells[
            sheetcolindex
            ][key] !== value &&
            sheettomodify.sheets[sheetindex].rows[sheetrowindex].cells[
            sheetcolindex
            ][key] !== undefined &&
            sheettomodify.sheets[sheetindex].rows[sheetrowindex].cells[
            sheetcolindex
            ][key] !== null
          ) {
            cellLevelAudit(
              sheettomodify.sheets[sheetindex].rows[sheetrowindex].cells[
              sheetcolindex
              ][key],
              value,
              ref.toString(),
              sheetname
            );
          }
        }
      }
    }
  }
}

function updatecell(spread, ref, key, value, sheettomodify) {
  let sheetname = spread.activeSheet()._sheetName;
  let sheetindex = sheettomodify.sheets.findIndex(
    (obj) => obj.name === sheetname
  );
  if (sheetindex > -1) {
    for (
      let rowindex = ref.topLeft.row;
      rowindex <= ref.bottomRight.row;
      rowindex++
    ) {
      for (
        let colindex = ref.topLeft.col;
        colindex <= ref.bottomRight.col;
        colindex++
      ) {
        if (sheettomodify.sheets[sheetindex].rows === undefined)
          sheettomodify.sheets[sheetindex].rows = [];
        let sheetrowindex = sheettomodify.sheets[sheetindex].rows.findIndex(
          (row) => row.index === rowindex
        );
        let sheetcolindex = -1;
        if (sheetrowindex > -1) {
          if (
            sheettomodify.sheets[sheetindex].rows[sheetrowindex].cells ===
            undefined
          )
            sheettomodify.sheets[sheetindex].rows[sheetrowindex].cells = [];

          sheetcolindex = sheettomodify.sheets[sheetindex].rows[
            sheetrowindex
          ].cells.findIndex((col) => col.index === colindex);
          if (sheetcolindex > -1) {
            sheettomodify.sheets[sheetindex].rows[sheetrowindex].cells[
              sheetcolindex
            ][key] = value;
          } else {
            sheettomodify.sheets[sheetindex].rows[sheetrowindex].cells.push({
              index: colindex,
              [key]: value,
            });
          }
        } else {
          sheettomodify.sheets[sheetindex].rows.push({
            index: rowindex,
            cells: [{ index: colindex, [key]: value }],
          });
        }
      }
    }
  }
}

function cleancell(spread, ref, key, value, sheettomodify) {
  let sheetname = spread.activeSheet()._sheetName;
  let sheetindex = sheettomodify.sheets.findIndex(
    (obj) => obj.name === sheetname
  );
  if (sheetindex > -1) {
    for (
      let rowindex = ref.topLeft.row;
      rowindex <= ref.bottomRight.row;
      rowindex++
    ) {
      for (
        let colindex = ref.topLeft.col;
        colindex <= ref.bottomRight.col;
        colindex++
      ) {
        if (sheettomodify.sheets[sheetindex].rows === undefined)
          sheettomodify.sheets[sheetindex].rows = [];
        let sheetrowindex = sheettomodify.sheets[sheetindex].rows.findIndex(
          (row) => row.index === rowindex
        );
        let sheetcolindex = -1;
        if (sheetrowindex > -1) {
          if (
            sheettomodify.sheets[sheetindex].rows[sheetrowindex].cells ===
            undefined
          )
            sheettomodify.sheets[sheetindex].rows[sheetrowindex].cells = [];

          sheetcolindex = sheettomodify.sheets[sheetindex].rows[
            sheetrowindex
          ].cells.findIndex((col) => col.index === colindex);
          if (sheetcolindex > -1) {
            if (
              sheettomodify.sheets[sheetindex].rows[sheetrowindex].cells[
              sheetcolindex
              ][key] !== undefined
            ) {
              let cell =
                sheettomodify.sheets[sheetindex].rows[sheetrowindex].cells[
                sheetcolindex
                ];
              if (key !== "value") {
                delete cell[key];
              } else {
                cell[key] = "";
              }
              sheettomodify.sheets[sheetindex].rows[sheetrowindex].cells[
                sheetcolindex
              ] = cell;
            }

            if (
              Object.keys(
                sheettomodify.sheets[sheetindex].rows[sheetrowindex].cells[
                sheetcolindex
                ]
              ).length <= 1
            ) {
              sheettomodify.sheets[sheetindex].rows[sheetrowindex].cells.splice(
                sheetcolindex,
                1
              );
            }

            if (
              sheettomodify.sheets[sheetindex].rows[sheetrowindex].cells
                .length <= 0
            ) {
              delete sheettomodify.sheets[sheetindex].rows[sheetrowindex].cells;
            }
          }

          if (
            Object.keys(sheettomodify.sheets[sheetindex].rows[sheetrowindex])
              .length <= 1
          ) {
            sheettomodify.sheets[sheetindex].rows.splice(sheetrowindex, 1);
          }

          if (sheettomodify.sheets[sheetindex].rows.length <= 0) {
            sheettomodify.sheets[sheetindex].rows = [];
          }
        }
      }
    }
  }
}

function checksheetsandequlize(e, sheettomodify) {
  if (e.sender.sheets().length > sheettomodify.sheets.length) {
    let insertedsheet = e.sender
      .sheets()
      .find((obj) => !sheettomodify.sheets.includes(obj._sheetName));
    if (insertedsheet !== undefined) {
      sheettomodify.sheets.push({
        name: insertedsheet._sheetName,
        rows: [],
        columns: [],
        selection: "A1:A1",
        activeCell: "A1:A1",
        frozenRows: 0,
        frozenColumns: 0,
        showGridLines: true,
        gridLinesColor: null,
        mergedCells: [],
        hyperlinks: [],
        defaultCellStyle: { fontFamily: "Arial", fontSize: "12" },
        drawings: [],
      });
    }
  } else {
    let deletesheetindex = sheettomodify.sheets.findIndex(
      (obj) => !e.sender.sheets().includes(obj.name)
    );
    if (deletesheetindex > -1) {
      sheettomodify.sheets.splice(deletesheetindex, 1);
    }
  }
}

async function ongetcurentdata(curentprotocolororderstepcode) {
  try {
    let ELNurl = document.getElementById("ELNname").value;
    const response = await fetch(
      "" +
      ELNurl +
      "/protocol/getprotocolperticulerstep/" +
      curentprotocolororderstepcode +
      "/" +
      isMultitenant +
      "/" +
      1,
      {
        method: "GET",
        // body: JSON.stringify(attachment),
        headers: new Headers({
          Authorization: $.session.get("Token"),
          "X-TenantID": $.session.get("tenantid"),
          Accept: "application/json",
          "Content-Type": "application/json",
        }),
      }
    );
    const json = await response.json();
    return json;
    // }
  } catch (error) { }

  // return information;
}

function Protocolcontentchangeornot(oldcontent, newcontent) {
  let content = newcontent;
  let information = oldcontent;
  information = information.replaceAll("&nbsp;", "");
  content = content.replaceAll("&nbsp;", "");
  content = content.replaceAll("<br>", "");
  information = information.replaceAll("<br>", "");
  content = content.replaceAll("<p>", "");
  information = information.replaceAll("<p>", "");
  content = content.replaceAll("</p>", "");
  information = information.replaceAll("</p>", "");
  content = content.replace(/ /g, "");
  information = information.replace(/ /g, "");
  // let htmlcontent = newcontent;
  if (content.length === information.length) {
    return false;
  } else {
    return true;
  }
}

function importimagetosheet(spreadsheet, url) {
  let imageId = spreadsheet.addImage(url);
  let sheet = spreadsheet.activeSheet();
  let selectedcell = sheet._viewSelection.selection;
  sheet.batch(function () {
    sheet.addDrawing({
      topLeftCell: selectedcell.topLeft,
      offsetX: 0,
      offsetY: 0,
      width: 150,
      height: 150,
      image: imageId,
    });
  });
}

function plantyprestrictedpagename(props) {
  let tenantdomain = $.session.get("tenantdomain");
  tenantdomain = isMultitenant !== 0 ? "/#" + tenantdomain : "/";
  let plantype = valiadateTenantPackage();
  if (plantype === "1" || plantype === "2") {
    if (isMultitenant !== 0) {
      props.history.push(tenantdomain);
    } else {
      props.history.push(tenantdomain);
    }
  }
}

function updateActiveUserTime() {
  let orderDetails = localStorage.getItem("_viewOrderDetail");
  let ELNurl = document.getElementById("ELNname").value;
  if (
    orderDetails !== undefined &&
    orderDetails !== null &&
    orderDetails !== ""
  ) {
    let passObj = JSON.parse(orderDetails);

    if (passObj.length > 0) {
      passObj.map((x) => {
        Servicecalls(
          "" + ELNurl + "/File/unlockorderOnViewClose",
          x,
          (response) => {
            localStorage.setItem("_viewOrderDetail", "");
          },
          (response) => {
            localStorage.setItem("_viewOrderDetail", "");
          }
        );
      });
    }
  }

  let passObjDet = {
    activeusercode: Number(sessionStorage.activeUserId),
  };
  Servicecalls(
    "" + ELNurl + "/Login/updateActiveUserTime",
    passObjDet,
    (response) => { },
    (response) => { }
  );
}

const pickbackgroudontype = (sampletype) => {
  switch (sampletype) {
    case "s":
      return "#90EE90"; //"lightgreen";
    case "u":
      return "yellow";
    case "q":
      return "pink";
    case "n":
      return "red";
    default:
      return "white";
  }
};

function checksheetformulachangeornot(orginal, version) {
  function getFormulas(
    sheets,
    value,
    change_cell_value,
    change_cell_index,
    change_orinalvalue
  ) {
    const finalarray = [];
    if (value && change_cell_value && change_cell_index) {
      for (const sheet of sheets) {
        if (
          sheet.name === orginal.activeSheet ||
          sheet.name === version.activeSheet
        ) {
          const sheetrows = sheet.rows;
          for (const row of sheetrows) {
            const temp = row.cells;
            if (temp) {
              // eslint-disable-next-line no-loop-func
              temp.forEach((cell) => {
                if (cell.formula !== undefined && cell.formula !== null) {
                  if (cell.formula === change_cell_index) {
                    cell.value = change_cell_value;
                  }
                  finalarray.push({
                    row: row.index,
                    col: cell.index,
                    value: cell.value,
                    formula: cell.formula,
                    orginalvalue: change_orinalvalue,
                  });
                }
              });
            }
          }
        }
      }

      return finalarray;
    } else {
      for (const sheet of sheets) {
        if (
          sheet.name === orginal.activeSheet ||
          sheet.name === version.activeSheet
        ) {
          const sheetrows = sheet.rows;
          for (const row of sheetrows) {
            const temp = row.cells;
            if (temp) {
              // eslint-disable-next-line no-loop-func
              temp.forEach((cell) => {
                if (cell.formula !== undefined && cell.formula !== null) {
                  finalarray.push({
                    row: row.index,
                    col: cell.index,
                    value: cell.value,
                    formula: cell.formula,
                  });
                }
              });
            }
          }
        }
      }
      return finalarray;
    }
  }
  let versionFormulas;
  let orginalFormulas;
  if (orginal) {
    orginalFormulas = getFormulas(orginal.sheets);
    console.log("original sheet - formulas");
    console.log(orginalFormulas);
  }

  if (version) {
    versionFormulas = getFormulas(version.sheets);
    console.log("version - formulas");
    console.log(versionFormulas);
  }
  let change_cell_value;
  let change_cell_index;
  let formulaarray;
  let change_orinalvalue;
  for (let i = 0; i < orginalFormulas.length; i++) {
    if (
      orginalFormulas[i].col === versionFormulas[i].col &&
      orginalFormulas[i].value !== versionFormulas[i].value &&
      orginalFormulas[i].row === versionFormulas[i].row
    ) {
      console.log("-------------------------------");
      console.log(orginalFormulas[i].value);
      console.log(versionFormulas[i].value);
      console.log("-------------------------------");
      change_cell_value = versionFormulas[i].value;
      change_cell_index = versionFormulas[i].formula;
      change_orinalvalue = orginalFormulas[i].value;

      //  let update_orginal=orginal;

      formulaarray = getFormulas(
        orginal.sheets,
        true,
        change_cell_value,
        change_cell_index,
        change_orinalvalue
      );
    }
  }

  return formulaarray;
}

// function findPathRecursive(node, targetId, currentPath) {
//   if (node.id === targetId) {
//     return currentPath + " -> " + node.text;
//   }

//   if (node.items) {
//     for (let i = 0; i < node.items.length; i++) {
//       const childNode = node.items[i];
//       const path = findPathRecursive(childNode, targetId, currentPath + " -> " + node.text);
//       if (path !== "") {
//         return path;
//       }
//     }
//   }

//   return "";
// }

function findPathInArray(nodeArray, targetId, currentPath = "") {
  for (const node of nodeArray) {
    const path = findPathRecursive(node, targetId, currentPath);
    if (path !== "") {
      return path;
    }
  }
  return "";
}

function findPathRecursive(node, targetId, currentPath) {
  if (node.id === targetId) {
    return currentPath + " -> " + node.text;
  }

  if (node.items) {
    for (let i = 0; i < node.items.length; i++) {
      const childNode = node.items[i];
      const path = findPathRecursive(childNode, targetId, currentPath + " -> " + node.text);
      if (path !== "") {
        return path;
      }
    }
  }

  return "";
}

function getCurrentDateAndTimeOnBackend() {
  return new Promise((resolve, reject) => {
    Servicecalls(
      "" +
      document.getElementById("ELNname").value +
      "/Login/getCurrentUTCDate",
      {},
      (response) => {
        sessionStorage.setItem("currentDate", new Date(response));
        resolve(response);
      },
      (error) => {
        sessionStorage.setItem("currentDate", new Date());
        resolve(new Date());
      }
    );
  });
}

function updateOrderStatusCheck(ordercode, usercode) {
  let orderDetails = localStorage.getItem("_viewOrderDetail");
  if (
    orderDetails !== undefined &&
    orderDetails !== null &&
    orderDetails !== ""
  ) {
    let passObj = JSON.parse(orderDetails);
    if (passObj.length > 0) {
      passObj.map((x) => {
        if (
          Number(x.Batch) === Number(ordercode) &&
          Number(x.usercode) === Number(usercode)
        ) {
          Servicecalls(
            "" +
            document.getElementById("ELNname").value +
            "/File/unlockorderOnViewClose",
            x,
            (response) => {
              localStorage.setItem("_viewOrderDetail", "");
            },
            (response) => {
              localStorage.setItem("_viewOrderDetail", "");
            }
          );
        }
      });
    }
  }
}

function checkADSUser() {
  try {
    if (Number(sessionStorage.isadsuser) === 0) {
      return false;
    } else if (Number(sessionStorage.isadsuser)) {
      return true;
    }
  } catch (e) {
    return false;
  }
}

function filterfieldforfile(data) {
  const originaldata = data.map((object) => {
    const {
      filecode,
      filenameuser,

      fileversioncount = object.versioncout !== undefined &&
        object.versioncout !== null
        ? object.versioncout
        : 0,
      lsfileversion,

      createduser = object.createby !== null ? object.createby.username : "",
      status = object.approved === undefined &&
        object.rejected !== null &&
        object.rejected === 0
        ? "Created"
        : object.rejected !== null && object.rejected === 1
          ? "Rejected"
          : object.approved !== null && object.approved === 1
            ? "Approved"
            : "Inprogress",
      lssheetworkflowhistory,
      modifiedlist,
      createdate = object.createdate,
      modifiedby,
      createby,
      category,
      viewoption,
      approved,
      rejected,
      viewoptionstr,
    } = object;
    return {
      filecode,
      filenameuser,
      fileversioncount,
      lsfileversion,
      createduser,
      status,
      lssheetworkflowhistory,
      modifiedlist,
      createdateview: setDateAsuserDefined(
        convertUTCDateToLocalDate(
          createdate !== undefined ? new Date(createdate) : new Date()
        ),
        $.session.get("userDefinedDate")
      ),
      createdate,
      modifiedby,
      createby,
      category,
      viewoption,
      approved,
      rejected,
      viewoptionstr,
    };
  });

  return originaldata.filter((item) => item.approved === 1);
}

function OnConvertProtocolSteps(response, protocolMaster) {
  let protocol = {
    protocolname: protocolMaster.protocolmastername ? protocolMaster.protocolmastername : protocolMaster.protoclordername,
    protocolmastercode: protocolMaster.protocolmastercode ? protocolMaster.protocolmastercode : protocolMaster.protocolordercode,
    AI: {
      value: {
        data: []
      }
    },
    abstract: {},
    matrices: {},
    material: {},
    sections: [],
    result: {},
  };
  response.protocolstepLst.map((step) => {
    if (protocolMaster.protocolmastercode && protocolMaster.protocolmastercode === step.protocolmastercode || protocolMaster.protocolordercode && protocolMaster.protocolordercode === step.protocolordercode) {
      let value = step.lsprotocolstepInformation
        ? JSON.parse(step.lsprotocolstepInformation)
        : "";
      let newStep = {
        sectionno: "section " + step.stepno,
        sectionname: step.stepno + " section",
        steps: [
          {
            stepno: "step " + step.stepno,
            stepname: step.stepno + " step",
            editors: [
              {
                editorname: "editor1",
                editortype: "documenteditor",
                value:
                  value.protocolstepinfo !== null
                    ? value.protocolstepinfo
                    : value,
              },
            ],
            createddate: setDateAsuserDefined(
              step.createddate,
              $.session.get("userDefinedDate")
            ),
            createdby: step.createdbyusername,
            addfrom: Protocolscreenenum.protocoltemplate,
          },
        ],
        createddate: setDateAsuserDefined(
          step.createddate,
          $.session.get("userDefinedDate")
        ),
        createdby: step.createdbyusername,
        addfrom: Protocolscreenenum.protocoltemplate,
      };
      protocol.sections.push(newStep);
    }
  });
  return protocol;
}


function compareProtocolDataChanges(a, b) {
  // Convert objects to JSON strings for comparison
  const aStr = JSON.stringify(a);
  const bStr = JSON.stringify(b);
  // Return true if the JSON strings are not equal (indicating changes)
  return aStr !== bStr;
}
function getHeight() {
  document.addEventListener('DOMContentLoaded', function () {
    const divElement = document.getElementById('.my-2 protocol-dropdown');
    if (divElement) {
      // Now you can safely use getBoundingClientRect on the divElement
      return divElement.getBoundingClientRect().height;
    } else {
      console.error("Element not found in the DOM");
    }
  });
}

export const sortDataByDate = (data, sortOrder) => [...data].sort((a, b) => {
  const getTimestamp = obj => obj.createdtimestamp || obj.createdate || obj.createdDate || obj.createdon || obj.createddate || obj.addedon;
  return sortOrder === 'asc' ? getTimestamp(a) - getTimestamp(b) : sortOrder === 'desc' ? getTimestamp(b) - getTimestamp(a) : 0;
});

function reporttags(data, tags) {

  const tagvalues = tags.map(tag => {
    const sheet = data.sheets.find(sheet => sheet.name === tag.tagsheetname);
    if (sheet) {
      const row = sheet.rows.find(row => row.index === tag.tagtoprow);
      if (row) {
        const cell = row.cells.find(cell => cell.index === tag.tagleftcol);
        if (cell) {
          return {
            tagname: tag.tagname,
            tagvalue: cell.value || null,
            tagsheetname: sheet.name,
            // tag: cell.tag,
            colindex: cell.index,
            rowindex: row.index,
          };
        }
      }
    }
  });
  // console.log(tagvalues);
  return tagvalues;
}

function exportToCSV(apiData, fileName) {
  const ws = XLSX.utils.json_to_sheet(apiData);
  const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const data = new Blob([excelBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8" });
  FileSaver.saveAs(data, fileName + ".xlsx");
};

function exporttranslationsToCSV() {
  let apiData = [];
  let fileName = "translations"
  const ws = XLSX.utils.json_to_sheet(apiData);
  const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const data = new Blob([excelBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8" });
  FileSaver.saveAs(data, fileName + ".xlsx");
};
const ProtocolHistory = (props) => {

  const [activeTab, setActiveTab] = useState(props.state.activeTab ? props.state.activeTab : 1);
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const FilterType = [
    {
      id: 1,
      type: props.props.t("Dashboard.IDS_LBL_FILTERBYCURRENTDATE")
    },
    {
      id: 2,
      type: props.props.t("Dashboard.IDS_LBL_FILTERBY7DAYS")
    },
    {
      id: 3,
      type: props.props.t("Dashboard.IDS_LBL_FILTERBY30DAYS")
    },
    {
      id: 4,
      type: props.props.t("Dashboard.IDS_LBL_FILTERBYCUSTOMDATE")
    },
  ];

  const currnetthis = props.getThis();


  return (
    <>
      <Modal
        isOpen={props.isexpand}
        className="modal-md smallPopup  screen-centered-scroll protocol-history"
      >
        <div className="modal-header">
          <h5 className="modal-title" id="staticBackdropLabel">
            {props.screentype === 1 ? props.props.t("Templates.Protocoltemplates.IDS_LBL_TEMPHISTORY", { param: "" }) : props.props.t("Orders.Protocolorders.IDS_LBL_ORDERHISTORY")}
          </h5>
          <button
            type="button"
            className="btn_white_bg"
            data-dismiss="modal"
            aria-label="Close"
            onClick={props.onClose}
          >
            <i className="fas fa-times" aria-hidden="true"></i>
          </button>

        </div>
        <ModalBody >
          <div className="row w-100">
            <span className="col-3 filter-table text-left">{props.props.t("Dashboard.IDS_LBL_FILTERBY")}</span>
            <KendoCombobox
              initialValue=""
              id="protocol-history-date"
              Datasource={FilterType}
              DefaultValue={props.state.selecteddatevalue ? props.state.selecteddatevalue : FilterType[0]}
              value={props.state.selecteddatevalue ? props.state.selecteddatevalue : FilterType[0]}
              onvaluechange={props.handleChange}
              ValueMember="id"
              DisplayMember="type"
              allowCustom={true}
              additionalclass={"clshideclearbutton col-6"}
            />
          </div>
          {props.state.selecteddatevalue?.id === 4 ?
            (<div className="shadow-box filter-date-group">
              <div className="flex-form-group padding-align-form disable-callendar-input">
                <label htmlFor="inputText">
                  {props.props.t("Dashboard.IDS_LBL_FILTERFROMDATE")}
                </label>
                <Suspense fallback={<div></div>}>
                  <KendoDatepicker
                    DefaultValue={props.state.OnKendoDateFrom}
                    onDatachanges={props.OnHistoryfromDatachange}
                  // maxdate={new Date(this.state.OnKendoDateTo)}
                  />
                </Suspense>
              </div>
              <div className="flex-form-group padding-align-form disable-callendar-input">
                <label htmlFor="inputText">
                  {props.props.t("Dashboard.IDS_LBL_FILTERTO")}
                </label>
                <Suspense fallback={<div></div>}>
                  <KendoDatepicker
                    DefaultValue={props.state.OnKendoDateTo}
                    onDatachanges={props.OnHistorytoDatachange}
                  // mindate={new Date(this.state.OnKendoDateFrom)}

                  />
                </Suspense>
              </div>
              <div className="d-flex align-items-end flex-column bd-highlight mb-3">
                <button
                  className="mg-l-1 btn btn-primary-blue active"
                  onClick={props.ondatefilterreset}
                >
                  {props.props.t("Orders.Sheetorders.IDS_LBL_RESET")}
                </button>
              </div>
            </div>) : ''
          }
          <ul className="nav nav-tabs" id="myTab" role="tablist">
            <li className={`nav-item ${activeTab === 1 ? 'active' : ''}`} role="presentation">
              <a className="nav-link" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true"
                onClick={() => handleTabClick(1)}
              >
                {props.props.t(
                  "Templates.Protocoltemplates.IDS_LBL_VERSIONHIS",
                  { param: "" }
                )}
              </a>
            </li>
            <li className={`nav-item ${activeTab === 2 ? 'active' : ''}`} role="presentation">
              <a className="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false"
                onClick={() => handleTabClick(2)}
              >
                {props.props.t(
                  "Templates.Protocoltemplates.IDS_LBL_TRANS",
                  {
                    param: "",
                  }
                )}
              </a>
            </li>
            <li className={`nav-item ${activeTab === 3 ? 'active' : ''}`} role="presentation">
              <a className="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact" type="button" role="tab" aria-controls="contact" aria-selected="false"
                onClick={() => handleTabClick(3)}
              >
                {props.props.t(
                  "Templates.Protocoltemplates.IDS_LBL_WORKF",
                  {
                    param: "",
                  }
                )}
              </a>
            </li>
          </ul>
          <div className="tab-content" id="myTabContent">
            <div className={`tab-pane ${activeTab === 1 ? 'show active' : ''}`} id="home" role="tabpanel" aria-labelledby="home-tab">
              <ul className="list-group list-group-flush">
                <li
                  className="list-group-item"
                  aria-disabled="true"
                >
                  <KendoGridView
                    enableTooltip={true}
                    primaryKey={props.screentype === 2 ? "protocolorderversioncode" : "protocolversioncode"}
                    source={props.state.versiondata && props.state.versiondata.length !== 0 ? props.state.versiondata : []}
                    Columns={props.versioncolumn}
                    DataSearchvisible={false}
                    ShowDetailsVisible={false}
                    height="300px"
                  />
                </li>
              </ul>

            </div>
            <div className={`tab-pane fade ${activeTab === 2 ? 'show active' : ''}`} id="profile" role="tabpanel" aria-labelledby="profile-tab">
              <ul className="list-group list-group-flush">
                <li
                  className="list-group-item"
                  aria-disabled="true"
                >
                  <KendoGridView
                    enableTooltip={true}
                    primaryKey={props.screentype === 1 ? "protocolmastercode" : "protocolorderstephistorycode"}
                    source={props.screen === "protocolordereyeview" || props.screen === "protocolorderview" ? props.state.protocoltransactionhistory : props.state.transactionhistory ? props.state.transactionhistory : []}
                    Columns={props.transactionscolumn}
                    DataSearchvisible={false}
                    ShowDetailsVisible={false}
                    height="300px"
                  />
                </li>
              </ul>


            </div>
            <div className={`tab-pane fade ${activeTab === 3 ? 'show active' : ''}`} id="contact" role="tabpanel" aria-labelledby="contact-tab">
              {props.state.lsprotocolworkflowhistory !== undefined &&
                props.state.lsprotocolworkflowhistory.length > 0 ? (
                props.screen === "protocolview" ? currnetthis.formatworkflowdetails(props.state.lsprotocolworkflowhistory, currnetthis.props) :
                  props.screen === "protocoleye" ? currnetthis.formatProtocolTemplateWorkflowHistoryDetails() : ("")) : ("")}
              {
                props.screen === "protocolordereyeview" ? currnetthis.formatworrkflowhistorydetails() : props.screen === "protocolorderview" ? currnetthis.formatworkflowdetails() : ""
              }
            </div>

          </div>
        </ModalBody>
      </Modal>
    </>
  )
}

function toDateFilter(todate) {
  todate = GetutcDateoncurenttime(todate);
  todate.setHours(23);
  todate.setMinutes(59);
  todate.setSeconds(59);
  return todate;
}

function checkFormulaUndefinedInSheet(sheetdata) {
  let isForumula = true;
	sheetdata?.sheets?.map((sheet) => {
	sheet?. rows?.map((row) => {
	  row?.cells?.map((cell) => {
		if(cell.formula){
		  let isArray = typeof cell.formula != "string";
		  let isForumula1 = isArray ? cell?.formula?.src ? true : false : true;
		  if(!isForumula1){
			isForumula = false;
		  }
		}
	  })
	})
	})
	return isForumula;
}

function LogoutSessionClear(){
      sessionStorage.removeItem('UserMail');
      sessionStorage.removeItem('tenantcode');
      sessionStorage.removeItem('loginfrom');
      sessionStorage.removeItem('loggedfrom');
      sessionStorage.removeItem('Userteam');
      sessionStorage.removeItem('packagetype');
      sessionStorage.removeItem('licencetype');
      sessionStorage.removeItem('idletime');
      sessionStorage.removeItem('Usersite');
      sessionStorage.removeItem('sSiteCode');
      sessionStorage.removeItem('name');
      sessionStorage.removeItem('multiusergroups');
      sessionStorage.removeItem('usergroupname');
      sessionStorage.removeItem('Userprojects');
      sessionStorage.removeItem('teamusercode');
      sessionStorage.removeItem('tenantid');
      sessionStorage.removeItem('unifieduserid');
      sessionStorage.removeItem('Usersitename');
      sessionStorage.removeItem('Userfullname');
      sessionStorage.removeItem('ServerDateFormat');
      sessionStorage.removeItem('LoginTime');
      sessionStorage.removeItem('userDefinedDate');
      sessionStorage.removeItem('Username');
      sessionStorage.removeItem('Userworkflows');
      sessionStorage.removeItem('User');
      sessionStorage.removeItem('activeUserId');
      sessionStorage.removeItem('userpasscodeencrypted');
      sessionStorage.removeItem('Token');
      sessionStorage.removeItem('noofusers');
      sessionStorage.removeItem('usergroupstatus');
      sessionStorage.removeItem('Protocol_Userworkflows');
      localStorage.clear()
      
}
export {
  ProtocolHistory,
  checkADSUser,
  userdetails,
  PercentageCalculator,
  SPCCalculation,
  convertdoubledigitdate,
  convertfulldateformat,
  convertdoubledigittime,
  AuditTrailValidation,
  ControlRights,
  IsJSON,
  isINT,
  killSessionLogout,
  Silentauditobject,
  SilentauditobjectForOrder,
  ModuleRights,
  Manualauditobject,
  compareObjs,
  Silentauditobjectonlogout,
  returnMIMEtype,
  returnICON,
  extractValuefromsheet,
  convertUTCDateToLocalDate,
  CF_encryptionData,
  CF_decrypt,
  extractValueforDynamiccombo,
  predefineddatabase,
  linkSheetLogin,
  linkSheetnontenantLogin,
  linkOTPLogin,
  linkLIMSLogin,
  capitalizeFirstLetter,
  isNumericvalue,
  getDecimalSeparator,
  replacedecimalwithdot,
  replacedotwithdecimal,
  GetutcDate,
  GetutctoDate,
  GetutcDateoncurenttime,
  GetutcDateaudi,
  dateDiffInDays,
  setDateAsuserDefined,
  Servicecalls,
  ServicecallsMAIN,
  onAuditHandler,
  Serviceuserworkflowdetails,
  Serviceprojectdetails,
  Getuserprojectfromsession,
  Getuserworkflowfromsession,
  valiadateTenantPackage,
  formatprotocolsteps,
  Getteamusersfromsession,
  Getteamfromsession,
  Serviceforuserrights,
  Serviceprojectteam,
  Serviceforauditconfig,
  Serviceforusersettings,
  Serviceforserverdateformat,
  ServiceforMainFormUserLicense,
  SubMenuRights,
  mainModuleRights,
  onHelpClick,
  uniq,
  decryptdata,
  encryptdata,
  formatdata,
  ServicecallsToken,
  Servicecallsforfiles,
  ServicecallsforSDMS,
  Servicecallsforwithougzip,
  setDateTimeAsuserDefined,
  validatesheetforversioning,
  checkChangesinSheet,
  Sheetchangeornotfunction,
  Sheetchangeornotfunctionforsheet,
  sheetModifyiedOrNot,
  restrictSheetEditonFields,
  markversionchanges,
  restrictVersioncomparion,
  generateLink,
  getGridorderColumns,
  removeByAttr,
  AuditDatetimeDefined,
  rangearray,
  addShowClass,
  showOrderBlock,
  compareObjsOperation,
  timeout,
  CheckIsMergedandreturntags,
  userDateFormat,
  checksheetdiffandmodifychangesheet,
  ongetcurentdata,
  Protocolcontentchangeornot,
  importimagetosheet,
  plantyprestrictedpagename,
  updateActiveUserTime,
  orderType,
  orderTypeforprotocol,
  checksheetsandequlize,
  pickbackgroudontype,
  checksheetformulachangeornot,
  getCurrentDateAndTimeOnBackend, findPathRecursive, findPathInArray,
  updateOrderStatusCheck,
  filterfieldforfile,
  OnConvertProtocolSteps,
  compareProtocolDataChanges,
  getHeight,
  reporttags,
  exportToCSV,
  toDateFilter,
  Getprotocoluserworkflowfromsession,
  checkFormulaUndefinedInSheet,
  LogoutSessionClear
};
// export const compareObjs = withTranslation(compareObjsFx);
