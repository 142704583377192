import { ActionTypes } from "../actions/ActionTypes";

const intialState = {};

export const DataReducer = (state = intialState, { type, payload }) => {
  switch (type) {
    case ActionTypes.DEFAULT_RETURN:
      return { ...state, ...payload };
    default:
      return state;
  }
};