import {
  userDateFormat,
  generateLink,
  setDateAsuserDefined, convertUTCDateToLocalDate, convertdoubledigittime, convertfulldateformat
} from "../custom_functions/Functions";
import {
  BreadcrumbDataModel,
  DataModel,
  GridViewBtnGroup,
  SortingBtnGroup,
} from "../interfaces/FileManagerModels";

export const convertExtensionToIcon = (
  item: string | null | undefined,
  color: string | null | undefined
) => {
  if (!item) {
    return null;
  }
  const extension: string | null =
    item.split(".").length > 1 ? item.split(".")[1] : null;

  switch (extension ? extension.toLowerCase() : null) {
    case "pdf":
      return {
        iconClass: "k-i-file-pdf k-i-pdf",
        type: "Data",
      };
    case "ppt":
    case "pptx":
      return {
        iconClass: "k-i-file-ppt k-i-ppt",
        type: "Data",
      };
    case "xlsx":
    case "xls":
      return {
        iconClass: "k-i-file-data",
        type: "Data",
      };
    case "jpg":
    case "png":
      return {
        iconClass: "k-i-file-image",
        type: "Image",
      };
    case "txt":
    case "doc":
    case "docx":
      return {
        iconClass: "k-i-file-txt",
        type: "Text",
      };
    case "mp3":
    case "mp4":
    case "mp":
      return {
        iconClass: "k-i-audio",
        type: "Text",
      };
    case "ord":
      return {
        iconClass: "k-i-file-data ",
        type: "Order",
        color: color !== undefined && color !== null ? color : "#54c518",
      };
    case null:
      return {
        iconClass: "k-i-folder",
        type: "Folder",
      };
    default:
      return {
        iconClass: "k-i-file k-i-file-vertical",
        type: "Folder",
      };
  }
};

export const getName = (path: string | undefined) => {
  if (!path) {
    return path;
  }
  return path.split("/").pop();
};

export const getFolderPath = (path: string | undefined) => {
  if (!path) {
    return path;
  }
  const pathArr = path.split("/");
  pathArr.pop();
  return pathArr.join("/");
};

export const convertDateFormat = (date: Date | null, intl: any) => {
  let date1 = userDateFormat(date);
  return date1;
};

export const mapData = (data: any, intl: any) => {
  if (!data) {
    return [];
  }

  return data.map(
    (item: {
      path: string | undefined;
      dateCreated: any;
      dateModified: any;
      size: any;
      items: string | any[];
      directorycode: number;
      parentdircode: number;
      directoryname: string | undefined;
      order: { batchcode: any };
      filefor: string | undefined;
      viewoption: number | undefined,
      teamname: string | undefined;
    }) => {
      const name = getName(item.path);
      return {
        path: item.path,
        // dateCreated: convertDateFormat(
        //   new Date(item.dateCreated) || null,
        //   intl
        // ),
        // dateModified: convertDateFormat(
        //   new Date(item.dateCreated) || null,
        //   intl
        // ),
        dateCreated: item.dateCreated,
        dateModified: item.dateModified,
        size: item.size,
        icon: convertExtensionToIcon(name, null),
        items: item.items && item.items.length ? mapData(item.items, intl) : [],
        directorycode: item.directorycode,
        parentdircode: item.parentdircode,
        directoryname: item.directoryname,
        order: item.order,
        filefor: item.filefor,
        link:
          item.order !== undefined
            ? generateLink(item.order.batchcode, item.order.batchcode ? "Sheet" : "Protocol")
            : undefined,
        viewoption: item.viewoption,
        teamname: item.teamname !== undefined ? item.teamname : undefined
      };
    }
  );
};

export const formatData = (data: DataModel[], intl: any) => {
  return mapData(data, intl);
};

export const formatserverData = (data: any[], groupeddata: any, intl: any, screen: any, path: any) => {
  let folderdata = data.map(
    (obj: {
      directoryname: string;
      items: any[];
      directorycode: number;
      lsorderitems: any[];
      path: string;
      viewoption: any,
      teamname: any,
    }) => {
      //obj.Items = formatserverData(groupeddata[obj.directorycode], groupeddata, intl);
      if (groupeddata[obj.directorycode] !== undefined) {
        obj.path = path + "/" + obj.directoryname;
        obj.items = formatserverData(groupeddata[obj.directorycode], groupeddata,
          intl, screen, obj.path);

        if (obj.lsorderitems !== undefined && obj.lsorderitems.length > 0) {
          obj.lsorderitems.map((objorder) => {
            let id = screen === "Sheet" ? objorder.batchid : objorder.protoclordername;
            let ordercode = screen === "Sheet"
              ? objorder.batchcode
              : objorder.protocolordercode;
            obj.items.push({
              path: `${obj.path}/${id + ".ord"}`,
              dateCreated: convertDateFormat(
                new Date(objorder.createdtimestamp) || null,
                intl
              ),
              dateModified: convertDateFormat(
                new Date(objorder.createdtimestamp) || null,
                intl
              ),
              size: 124,
              icon: convertExtensionToIcon(
                id + ".ord",
                objorder.orderflag !== undefined &&
                  objorder.orderflag.trim() === "R" && objorder.ordercancell === undefined
                  ? "#54c518" : objorder.ordercancell !== undefined && objorder.ordercancell === 1 ? "#808080"
                    : "#e16733"
              ),
              // icon: convertExtensionToIcon(
              //   id + ".ord",
              //   objorder.orderflag !== undefined &&
              //     objorder.orderflag.trim() === "R"
              //     ? "#54c518"
              //     : "#e16733"
              // ),
              items: [],
              directorycode: ordercode,
              parentdircode: obj.directorycode,
              directoryname: id,
              order: objorder,
              link: generateLink(ordercode, screen),
              viewoption: obj.viewoption,
              teamname: obj.teamname !== undefined ? obj.teamname : undefined
            });
            return objorder;
          });
        }
      } else {
        obj.path = path + "/" + obj.directoryname;
        obj.items =
          groupeddata[obj.directorycode] !== undefined
            ? groupeddata[obj.directorycode]
            : [];
        if (obj.lsorderitems !== undefined && obj.lsorderitems.length > 0) {
          obj.lsorderitems.map((objorder) => {
            let id = screen === "Sheet" ? objorder.batchid : objorder.protoclordername;
            let ordercode = screen === "Sheet"
              ? objorder.batchcode
              : objorder.protocolordercode;
            obj.items.push({
              path: `${obj.path}/${id + ".ord"}`,
              dateCreated: convertDateFormat(
                new Date(objorder.dateCreated) || null,
                intl
              ),
              dateModified: convertDateFormat(
                new Date(objorder.dateModified) || null,
                intl
              ),
              size: 124,
              icon: convertExtensionToIcon(
                id + ".ord",
                objorder.orderflag !== undefined &&
                  objorder.orderflag.trim() === "R" && objorder.ordercancell === undefined
                  ? "#54c518" : objorder.ordercancell !== undefined && objorder.ordercancell === 1 ? "#808080"
                    : "#e16733"
              ),
              // icon: convertExtensionToIcon(
              //   id + ".ord",
              //   objorder.orderflag !== undefined &&
              //     objorder.orderflag.trim() === "R"
              //     ? "#54c518"
              //     : "#e16733"
              // ),
              items: [],
              directorycode: ordercode,
              parentdircode: obj.directorycode,
              directoryname: id,
              order: objorder,
              link: generateLink(ordercode, screen),
              viewoption: obj.viewoption,
              teamname: obj.teamname !== undefined ? obj.teamname : undefined
            });
            return objorder;
          });
        }
      }
      return obj;
    }
  );

  return folderdata;
};

// Accepts the array and key
export const groupBy = (array: any[], key: string | number) => {
  // Return the end result
  return array.reduce((result, currentValue) => {
    // If an array already present for key, push it to the array. Else create an array and push the object
    (result[currentValue[key]] = result[currentValue[key]] || []).push(
      currentValue
    );
    // Return the current iteration `result` value, this will be taken as next iteration `result` value and accumulate
    return result;
  }, {}); // empty object is the initial value for result object
};

// Accepts the array and key
export const groupByobject = (
  array: any[],
  parentkey: string | number,
  key: string | number
) => {
  // Return the end result
  return array.reduce((result, currentValue) => {
    // If an array already present for key, push it to the array. Else create an array and push the object
    (result[currentValue[parentkey][key]] =
      result[currentValue[parentkey][key]] || []).push(currentValue);
    // Return the current iteration `result` value, this will be taken as next iteration `result` value and accumulate
    return result;
  }, {}); // empty object is the initial value for result object
};

export const groupByForsinglevaluearray = (
  array: any[],
  key: string | number,
  returnkey: string | number
) => {
  // Return the end result
  return array.reduce((result, currentValue) => {
    // If an array already present for key, push it to the array. Else create an array and push the object
    (result[currentValue[key]] = result[currentValue[key]] || []).push(
      currentValue[returnkey]
    );
    // Return the current iteration `result` value, this will be taken as next iteration `result` value and accumulate
    return result;
  }, {}); // empty object is the initial value for result object
};

export const getFolderTree = (
  data: DataModel[],
  selectedItem: DataModel | null = null
) => {
  if (!data) {
    return data;
  }
  const newItems: DataModel[] = [];

  for (let index = 0; index < data.length; index++) {
    const currentItem = { ...data[index] };
    if (currentItem.path && !currentItem.path.includes(".")) {
      if (currentItem.items && currentItem.items.length) {
        currentItem.items = getFolderTree(currentItem.items, selectedItem);
      }
      newItems.push({
        ...currentItem,
        selected: selectedItem
          ? selectedItem.path === currentItem.path &&
          currentItem.parentdircode === selectedItem.parentdircode &&
          currentItem.directorycode === selectedItem.directorycode
          : false,
      });
    }
  }
  return newItems;
};

export const searchTreeItem = (
  data: string | any[],
  selectedItem: DataModel | null
) => {
  if (!selectedItem) {
    return data;
  }

  for (let index = 0; index < data.length; index++) {
    const currentItem: any = data[index];
    if (
      currentItem.path === selectedItem.path &&
      currentItem.parentdircode === selectedItem.parentdircode &&
      currentItem.directorycode === selectedItem.directorycode
    ) {
      console.log(currentItem);
      return currentItem;
    }
    if (currentItem.items && currentItem.items.length) {
      const foundItem: any = searchTreeItem(currentItem.items, selectedItem);
      if (foundItem) {
        return foundItem;
      }
    }
  }
};

export const validateTreeItem = (
  data: string | any[],
  selectedItem: DataModel | null
) => {
  if (!selectedItem) {
    return data;
  }

  for (let index = 0; index < data.length; index++) {
    const currentItem: any = data[index];
    if (
      currentItem.path === selectedItem.path &&
      currentItem.parentdircode === selectedItem.parentdircode &&
      currentItem.directorycode !== selectedItem.directorycode
    ) {
      console.log(currentItem);
      return currentItem;
    }
    if (currentItem.items && currentItem.items.length) {
      const foundItem: any = validateTreeItem(currentItem.items, selectedItem);
      if (foundItem) {
        return foundItem;
      }
    }
  }
};

export const searchTreeItemonpath = (
  data: string | any[],
  selectedItem: DataModel | null
) => {
  if (!selectedItem) {
    return data;
  }

  for (let index = 0; index < data.length; index++) {
    const currentItem: any = data[index];
    if (currentItem.path === selectedItem.path) {
      console.log(currentItem);
      return currentItem;
    }
    if (currentItem.items && currentItem.items.length) {
      const foundItem: any = searchTreeItemonpath(
        currentItem.items,
        selectedItem
      );
      if (foundItem) {
        return foundItem;
      }
    }
  }
};

export const addDataItem = (
  data: DataModel[],
  selectedItem: DataModel | null = null,
  files: any[],
  intl: any
) => {
  if (!selectedItem) {
    files.forEach((file) => {
      data.push({
        path: `Sheet/${file.filename}`,
        createdDate: file.createdtimestamp,
        dateCreated: convertDateFormat(new Date(file.createdtimestamp), intl),
        dateModified: convertDateFormat(new Date(file.createdtimestamp), intl),
        size: file.filesize,
        icon: convertExtensionToIcon(file.filename, null),
        directoryname: file.filename,
        directorycode: file.folderfilecode,
        file: file,
        filefor: "FIL"
      });
    });

    return data;
  }

  if (!data) {
    return data;
  }

  return data.map((item) => {
    const currentItem = { ...item };

    if (currentItem.path === selectedItem.path) {
      files.forEach((file) => {
        if (!currentItem.items) {
          currentItem.items = [];
        }

        currentItem.items.push({
          path: `${currentItem.path}/${file.filename}`,
          createdDate: file.createdtimestamp,
          dateCreated: convertDateFormat(new Date(file.createdtimestamp), intl),
          dateModified: convertDateFormat(new Date(file.createdtimestamp), intl),
          size: file.filesize,
          icon: convertExtensionToIcon(file.filename, null),
          directoryname: file.filename,
          directorycode: file.folderfilecode,
          file: file,
          filefor: "FIL"
        });
      });
    } else if (currentItem.items && currentItem.items.length) {
      currentItem.items = addDataItem(
        currentItem.items,
        selectedItem,
        files,
        intl
      );
    }

    return currentItem;
  });
};

let FOLDER_NAME_COUNTER = 0;
export const addFolder = (
  data: DataModel[],
  selectedItem: DataModel | null,
  intl: any,
  name: string
) => {
  if (!selectedItem) {
    const folderName =
      name !== undefined && name !== null
        ? name
        : FOLDER_NAME_COUNTER
          ? `New Folder (${FOLDER_NAME_COUNTER})`
          : "New Folder";
    FOLDER_NAME_COUNTER++;

    data.push({
      path: `Sheet/${folderName}`,
      dateCreated: convertDateFormat(new Date(), intl),
      dateModified: convertDateFormat(new Date(), intl),
      size: 124,
      icon: convertExtensionToIcon(folderName, null),
    });

    return data.map((item) => item);
  }

  if (!data) {
    return data;
  }

  let returndata = data.map((item) => {
    const currentItem = { ...item };

    if (
      currentItem.path === selectedItem?.path &&
      currentItem.filefor === selectedItem?.filefor
    ) {
      const folderName =
        name !== undefined && name !== null
          ? name
          : FOLDER_NAME_COUNTER
            ? `New Folder (${FOLDER_NAME_COUNTER})`
            : "New Folder";
      FOLDER_NAME_COUNTER++;

      if (!currentItem.items) {
        currentItem.items = [];
      }

      currentItem.items.push({
        path: `${currentItem.path}/${folderName}`,
        dateCreated: convertDateFormat(new Date(), intl),
        dateModified: convertDateFormat(new Date(), intl),
        size: 124,
        icon: convertExtensionToIcon(folderName, null),
      });
    } else if (currentItem.items && currentItem.items.length) {
      currentItem.items = addFolder(
        currentItem.items,
        selectedItem,
        intl,
        name
      );
    }

    return currentItem;
  });

  return returndata;
};

export const addFolderonObj = (
  data: DataModel[],
  selectedItem: DataModel | null,
  intl: any,
  addobj: DataModel
) => {
  if (!selectedItem) {
    data.push(addobj);

    return data.map((item) => item);
  }

  if (!data) {
    return data;
  }

  let returndata = data.map((item) => {
    const currentItem = { ...item };

    if (currentItem.directorycode === selectedItem?.directorycode) {
      if (!currentItem.items) {
        currentItem.items = [];
      }
      if (addobj.order) {
        addobj.path = selectedItem?.path + "/" + addobj.directoryname + ".ord";
      }
      else {
        addobj.path = selectedItem?.path + "/" + addobj.directoryname;
      }
      addobj.parentdircode = selectedItem?.directorycode;
      let itemindex = currentItem.items.findIndex(obj => obj.directorycode === addobj.directorycode);
      if (addobj.items && addobj.items.length && addobj.directorycode && addobj.path) {
        addobj.items = changepathandparentcodeonmultimove(addobj.items, addobj.path, addobj.directorycode);
      }
      if (itemindex > -1) {
        currentItem.items[itemindex] = addobj;
      }
      else {
        currentItem.items.push(addobj);
      }
    } else if (currentItem.items && currentItem.items.length) {
      currentItem.items = addFolderonObj(
        currentItem.items,
        selectedItem,
        intl,
        addobj
      );
    }

    return currentItem;
  });

  return returndata;
};

export const addorderitemstoFolder = (
  data: DataModel[],
  directorycode: number | null,
  intl: any,
  orderItems: any[],
  screen: string
) => {
  if (directorycode === -1) {
    return data.map((item) => item);
  }

  if (!data) {
    return data;
  }

  let returndata = data.map((item) => {
    const currentItem = { ...item };

    if (currentItem.directorycode === directorycode) {
      if (!currentItem.items) {
        currentItem.items = [];
      }

      // currentItem.expanded = true;

      if (orderItems !== undefined && orderItems.length > 0) {
        orderItems.map((objorder) => {
          if (currentItem.items !== undefined) {
            let itemindex = currentItem.items.findIndex(
              (obj) =>
                obj.directoryname ===
                (screen === "Sheet"
                  ? objorder.batchid
                  : objorder.protoclordername)
            );
            let id =
              screen === "Sheet" ? objorder.batchid : objorder.protoclordername;
            let additem = {
              path: `${currentItem.path}/${id + ".ord"}`,
              dateCreated: convertDateFormat(
                new Date(objorder.createdtimestamp) || null,
                intl
              ),
              dateModified: convertDateFormat(
                new Date(objorder.createdtimestamp) || null,
                intl
              ),
              size: 124,
              icon: convertExtensionToIcon(
                id + ".ord",
                objorder.orderflag !== undefined &&
                  objorder.orderflag.trim() === "R" && objorder.ordercancell === undefined
                  ? "#54c518" : objorder.ordercancell !== undefined && objorder.ordercancell === 1 ? "#808080"
                    : "#e16733"
              ),
              // icon: convertExtensionToIcon(
              //   id + ".ord",
              //   objorder.orderflag !== undefined &&
              //     objorder.orderflag.trim() === "R"
              //     ? "#54c518"
              //     : "#e16733"
              // ),
              items: [],
              directorycode:
                screen === "Sheet"
                  ? objorder.batchcode
                  : objorder.protocolordercode,
              parentdircode: currentItem.directorycode,
              directoryname:
                screen === "Sheet"
                  ? objorder.batchid
                  : objorder.protoclordername,
              order: objorder,
              link: generateLink(objorder.batchcode, screen),
              viewoption: currentItem.viewoption,
              teamname: currentItem.teamname !== undefined ? currentItem.teamname : undefined
            };
            if (itemindex <= -1) {
              currentItem.items.push(additem);
            } else {
              currentItem.items[itemindex] = additem;
            }
          }
          return objorder;
        });
      }
    } else if (currentItem.items && currentItem.items.length) {
      currentItem.items = addorderitemstoFolder(
        currentItem.items,
        directorycode,
        intl,
        orderItems,
        screen
      );
    }

    return currentItem;
  });

  return returndata;
};

export const addorderitemstoFolderwithfilefor = (
  data: DataModel[],
  directorycode: number | null,
  intl: any,
  orderItems: any[],
  filefor: String,
  screen: string
) => {
  if (directorycode === -1) {
    return data.map((item) => item);
  }

  if (!data) {
    return data;
  }

  let returndata = data.map((item) => {
    const currentItem = { ...item };

    if (
      currentItem.directorycode === directorycode &&
      currentItem.filefor === filefor
    ) {
      if (!currentItem.items) {
        currentItem.items = [];
      }

      // currentItem.expanded = true;

      if (orderItems !== undefined && orderItems.length > 0) {
        orderItems.map((objorder) => {
          if (currentItem.items !== undefined) {
            let id =
              screen === "Sheet" ? objorder.batchid : objorder.protoclordername;
            let itemindex = currentItem.items.findIndex(
              (obj) =>
                obj.directoryname ===
                (screen === "Sheet"
                  ? objorder.batchid
                  : objorder.protoclordername)
            );
            let additem = {
              path: `${currentItem.path}/${id + ".ord"}`,
              dateCreated: convertDateFormat(
                new Date(objorder.createdtimestamp) || null,
                intl
              ),
              dateModified: convertDateFormat(
                new Date(objorder.createdtimestamp) || null,
                intl
              ),
              size: 124,
              icon: convertExtensionToIcon(
                id + ".ord",
                objorder.orderflag !== undefined &&
                  objorder.orderflag.trim() === "R" && objorder.ordercancell === undefined
                  ? "#54c518" : objorder.ordercancell !== undefined && objorder.ordercancell === 1 ? "#808080"
                    : "#e16733"
              ),
              // icon: convertExtensionToIcon(
              //   id + ".ord",
              //   objorder.orderflag !== undefined &&
              //     objorder.orderflag.trim() === "R"
              //     ? "#54c518"
              //     : "#e16733"
              // ),
              items: [],
              directorycode:
                screen === "Sheet"
                  ? objorder.batchcode
                  : objorder.protocolordercode,
              //  objorder.batchcode,
              parentdircode: currentItem.directorycode,
              directoryname:
                screen === "Sheet"
                  ? objorder.batchid
                  : objorder.protoclordername,
              // objorder.batchid,
              order: objorder,
              link: generateLink(screen === "Sheet" ? objorder.batchcode : objorder.protocolordercode, screen),
            };
            if (itemindex <= -1) {
              currentItem.items.push(additem);
            } else {
              currentItem.items[itemindex] = additem;
            }
          }
          return objorder;
        });
      }
    } else if (currentItem.items && currentItem.items.length) {
      currentItem.items = addorderitemstoFolderwithfilefor(
        currentItem.items,
        directorycode,
        intl,
        orderItems,
        filefor,
        screen
      );
    }

    return currentItem;
  });

  return returndata;
};

export const addfileitemstoFolderwithfilefor = (
  data: DataModel[],
  directorycode: number | null,
  intl: any,
  orderItems: any[],
  filefor: String,
  screen: string
) => {
  if (directorycode === -1) {
    return data.map((item) => item);
  }

  if (!data) {
    return data;
  }

  let returndata = data.map((item) => {
    const currentItem = { ...item };

    if (
      currentItem.directorycode === directorycode &&
      currentItem.filefor === filefor
    ) {
      if (!currentItem.items) {
        currentItem.items = [];
      }

      // currentItem.expanded = true;

      if (orderItems !== undefined && orderItems.length > 0) {
        orderItems.map((objfile) => {
          if (currentItem.items !== undefined) {
            let name = screen === "Sheet" ? objfile.filename : objfile.filename;
            let itemindex = currentItem.items.findIndex(
              (obj) => obj.directoryname === (screen === "Sheet"
                ? objfile.filename : objfile.filename));
            let additem = {
              path: `${currentItem.path}/${name}`,
              createdDate: objfile.createdtimestamp,
              dateCreated: convertDateFormat(
                new Date(objfile.createdtimestamp) || null,
                intl
              ),
              dateModified: convertDateFormat(
                new Date(objfile.createdtimestamp) || null,
                intl
              ),
              size: 124,
              icon: convertExtensionToIcon(name, null),
              items: [],
              directorycode: screen === "Sheet" ? objfile.folderfilecode : objfile.folderfilecode,
              //  objfile.batchcode,
              parentdircode: currentItem.directorycode,
              directoryname: name,
              file: objfile,
              filefor: "FIL"

            };
            if (itemindex <= -1) {
              currentItem.items.push(additem);
            } else {
              currentItem.items[itemindex] = additem;
            }
          }
          return objfile;
        });
      }
    } else if (currentItem.items && currentItem.items.length) {
      currentItem.items = addfileitemstoFolderwithfilefor(
        currentItem.items,
        directorycode,
        intl,
        orderItems,
        filefor,
        screen
      );
    }

    return currentItem;
  });

  return returndata;
};



export const addfileitemsforhomebutton = (
  data: DataModel[],
  directorycode: number | null,
  intl: any,
  orderItems: any[],
  filefor: String,
  screen: string
) => {
  let currentItem: any = [];

  let additem: any = {};
  orderItems.map((objfile) => {
    // let length = data.length;
    let isfilepresent = false;
    data.map((item: any, index: Number) => {
      if (objfile.folderfilecode === item?.directorycode && item?.filefor === "FIL") {
        isfilepresent = true;
      }
      return item
    });
    if (!isfilepresent) {
      let name = screen === "Sheet" ? objfile.filename : objfile.filename;
      additem = {
        path: `${screen === "Sheet" ? "Sheet" : "Protocol"}/${name}`,
        createdDate: objfile.createdtimestamp,
        dateCreated: convertDateFormat(
          new Date(objfile.createdtimestamp) || null,
          intl
        ),
        dateModified: convertDateFormat(
          new Date(objfile.createdtimestamp) || null,
          intl
        ),
        size: 124,
        icon: convertExtensionToIcon(name, null),
        items: [],
        directorycode: screen === "Sheet" ? objfile.folderfilecode : objfile.folderfilecode,
        //  objfile.batchcode,
        parentdircode: -1,
        directoryname: name,
        file: objfile,
        filefor: "FIL"

      };
      currentItem.push(additem);
    }
    return objfile;
  });

  if (currentItem.length > 0) {
    data = data.concat(currentItem);
  }
  return data;
};

export const addorderitemsforhomebutton = (
  data: DataModel[],
  directorycode: number | null,
  intl: any,
  orderItems: any[],
  filefor: String,
  screen: string
) => {
  let currentItem: any = [];


  orderItems.map((objorder) => {
    let isorderpresent = false;
    data.map((item: any, index: Number) => {
      if (screen === "Sheet" ? objorder.batchcode === item.directorycode : objorder.protocolordercode === item.directorycode) {
        isorderpresent = true;
      }
      return item;
    });
    if (!isorderpresent) {
      let name = screen === "Sheet" ? objorder.batchid : objorder.protocolordercode;
      //let id = getitemnameonscreen(screen, objorder);

      let additem = {
        path: `${currentItem.path}/${name + ".ord"}`,
        dateCreated: convertDateFormat(
          new Date(objorder.createdtimestamp) || null,
          intl
        ),
        dateModified: convertDateFormat(
          new Date(objorder.createdtimestamp) || null,
          intl
        ),
        dateCompleted: convertDateFormat(
          new Date(objorder.completedtimestamp === undefined ? new Date() : objorder.completedtimestamp) || null,
          intl
        ),
        size: 124,
        icon: convertExtensionToIcon(
          name + ".ord",
          objorder.orderflag !== undefined &&
            objorder.orderflag.trim() === "R" && objorder.ordercancell === undefined
            ? "#54c518" : objorder.ordercancell !== undefined && objorder.ordercancell === 1 ? "#808080"
              : "#e16733"
        ),
        // icon: convertExtensionToIcon(
        //   id + ".ord",
        //   objorder.orderflag !== undefined &&
        //     objorder.orderflag.trim() === "R"
        //     ? "#54c518"
        //     : "#e16733"
        // ),
        items: [],
        directorycode:
          screen === "Sheet"
            ? objorder.batchcode
            : objorder.protocolordercode,
        //  objorder.batchcode,
        parentdircode: currentItem.directorycode,
        directoryname: name,
        // objorder.batchid,
        order: objorder,
        link: generateLink(screen === "Sheet" ? objorder.batchcode : objorder.protocolordercode, screen, currentItem.filefor),
        filefor: filefor,
      };
      currentItem.push(additem);
    }
    isorderpresent = false;

    return objorder;
  });

  if (currentItem.length > 0) {
    data = data.concat(currentItem);
  }
  return data;

};

export const deletefileitemsforhomebutton = (
  data: DataModel[],
  // directorycode: number | null,
  // intl: any,
  orderItems: any[],
  // filefor: String,
  // screen: string
) => {
  orderItems.map((objorder) => {
    let isfile = false;
    data = data.filter((item) => {
      if (objorder?.directorycode === item?.directorycode && item?.filefor === "FIL") {
        isfile = true;
      }
      if (!isfile) {
        return item
      }
      return null
    });
    return objorder
  });
  return data;
};


export const replaceonechangeorderordertoFolderwithfilefor = (
  data: DataModel[],
  directorycode: number | null,
  intl: any,
  orderItems: any[],
  filefor: string,
  screen: string
) => {
  if (directorycode === -1) {
    return data.map((item) => item);
  }

  if (!data) {
    return data;
  }

  let returndata = data.map((item) => {
    const currentItem = { ...item };
    let currentItemcopy = { ...currentItem };
    if (currentItem.directorycode === directorycode && currentItem.filefor === filefor) {
      if (!currentItem.items) {
        currentItem.items = [];
      }
      currentItem.items = currentItem.items.filter((obj) => obj.order === undefined);

      if (currentItem.items !== undefined && currentItemcopy.items !== undefined && currentItemcopy.items.length > 0) {
        currentItem.items = [];

        currentItemcopy.items.map((objorder) => {
          let code = screen === "Sheet" ? orderItems[0].batchcode : orderItems[0].protocolordercode;
          if (currentItem.items !== undefined && objorder.order !== undefined && objorder.directorycode === code) {
            let id = screen === "Sheet" ? orderItems[0].batchid : orderItems[0].protoclordername;

            let additem = {
              // path: `${currentItemcopy.path}/${id + ".ord"}`,
              path: `${currentItemcopy.path}/${objorder.directoryname + ".ord"}`,
              dateCreated: convertDateFormat(new Date(orderItems[0].createdtimestamp) || null, intl),
              dateModified: convertDateFormat(new Date(orderItems[0].createdtimestamp) || null, intl),
              size: 124,

              icon: convertExtensionToIcon(
                id + ".ord", orderItems[0].orderflag !== undefined && orderItems[0].orderflag.trim() === "R" && orderItems[0].ordercancell === undefined
                ? "#54c518" : orderItems[0].ordercancell !== undefined && orderItems[0].ordercancell === 1 ? "#808080" : "#e16733"
              ),
              items: [],
              directorycode: screen === "Sheet" ? orderItems[0].batchcode : orderItems[0].protocolordercode,
              parentdircode: currentItemcopy.directorycode,
              directoryname: screen === "Sheet" ? orderItems[0].batchid : orderItems[0].protoclordername,
              order: orderItems[0],
              link: generateLink(screen === "Sheet" ? orderItems[0].batchcode : orderItems[0].protocolordercode, screen),
              filefor: filefor,
            };

            objorder = additem;
            currentItem.items.push(objorder);
          } else if (currentItem.items !== undefined) {
            currentItem.items.push(objorder);
          }
          return objorder;
        });
      }
    } else if (currentItem.items && currentItem.items.length) {
      currentItem.items = replaceonechangeorderordertoFolderwithfilefor(
        currentItem.items,
        directorycode,
        intl,
        orderItems,
        filefor,
        screen
      );
    }

    return currentItem;
  });

  return returndata;
};
export const addprojectinsidetask = (
  data: DataModel[],
  directorycode: number | null,
  intl: any,
  orderItems: any,
  filefor: string,
  screen: string
) => {
  if (directorycode === -1) {
    return data.map((item) => item);
  }

  if (!data) {
    return data;
  }
  let returndata = data.map((item) => {
    const currentItem = { ...item };
    let currentItemcopy = { ...currentItem };
    let istestmaster = screen === "Sheet" ? orderItems.lstestmasterlocal : screen === "Protocol" ? orderItems.testcode : undefined;
    if (currentItem.filefor === filefor && istestmaster !== undefined) {
      if (currentItem.items !== undefined && currentItemcopy.items !== undefined && currentItemcopy.items.length > 0 && currentItemcopy?.directoryname === "Projects") {
        currentItemcopy.items.some((objorder) => {

          if (orderItems?.lsprojectmaster?.projectcode + 3 === objorder.directorycode) {
            let filteobj = [];
            let addfolder = undefined;
            if (objorder?.items && objorder.items.length > 0) {
              filteobj = objorder.items.filter((obj) => obj.directoryname === orderItems?.testname);
            }
            if (filteobj.length > 0) {
              return true;
            } else {
              let directorycode = 0;
              let directoryname = "";
              if (screen === "Sheet") {
                directorycode = orderItems.lstestmasterlocal !== undefined && orderItems.lstestmasterlocal.testcode === -2 ? -Math.abs(parseFloat(orderItems.lsprojectmaster.projectcode + "." + 2)) : parseFloat(orderItems.lsprojectmaster.projectcode + "." + orderItems.lstestmasterlocal.testcode);
                directoryname = orderItems.lstestmasterlocal !== undefined && orderItems.lstestmasterlocal.testcode === -2 ? "Defaul Template" : orderItems.lstestmasterlocal.testname;
              } else if (screen === "Protocol") {
                directorycode = orderItems.testcode !== undefined && orderItems.testcode === -2 ? -Math.abs(parseFloat(orderItems.lsprojectmaster.projectcode + "." + 2)) : parseFloat(orderItems.lsprojectmaster.projectcode + "." + orderItems.testcode);
                directoryname = orderItems.testcode !== undefined && orderItems.testcode === -2 ? "Defaul Template" : orderItems.testname;

              }
              let Project = "Project";
              addfolder = {
                directorycode: directorycode,
                parentdircode: parseInt(orderItems.lsprojectmaster.projectcode) + 3,
                dateCreated: convertDateFormat(new Date(), intl),
                dateModified: convertDateFormat(new Date(), intl),
                size: 124,
                path: `${screen}/${Project}/${orderItems.lsprojectmaster.projectname}/${orderItems.testname}`,
                directoryname: directoryname,
                icon: convertExtensionToIcon(directoryname, null),
                filefor: "TMP",
                items: [],
              };
            }
            if (addfolder !== undefined) {
              objorder.items?.push(addfolder);
              return true;
            } else {
              return false;
            }

          } else {
            return false;
          }


        });
      }
    } else if (currentItem.filefor === "SM" && orderItems.lssamplemaster !== undefined) {
      if (currentItem.items !== undefined && currentItemcopy.items !== undefined && currentItemcopy.items.length > 0 && currentItemcopy?.directoryname === "Samples") {
        currentItemcopy.items.some((objorder) => {

          if (orderItems?.lssamplemaster?.samplecode + 4 === objorder.directorycode) {
            let filteobj = [];
            let addfolder = undefined;
            if (objorder?.items && objorder.items.length > 0) {
              filteobj = objorder.items.filter((obj) => obj.directoryname === orderItems?.samplename);
            }
            if (filteobj.length > 0) {
              return true;
            } else {
              let Sample = "Sample";
              let testcode = 0;
              if (screen === "Sheet") {
                testcode = orderItems.lstestmasterlocal.testcode;

              } else if (screen === "Protocol") {
                testcode = orderItems.testcode;
              }
              addfolder = {
                directorycode: parseFloat(orderItems.lssamplemaster.samplecode + "." + testcode),
                parentdircode: parseInt(orderItems.lssamplemaster.samplecode) + 4,
                dateCreated: convertDateFormat(new Date(), intl),
                dateModified: convertDateFormat(new Date(), intl),
                size: 124,
                path: `${screen}/${Sample}/${orderItems.samplename}/${orderItems.testname}`,
                directoryname: orderItems.testname,
                icon: convertExtensionToIcon(orderItems.testname, null),
                filefor: "TMS",
                items: [],
              };
            }
            if (addfolder !== undefined) {
              objorder.items?.push(addfolder);
              return true;
            } else {
              return false;
            }
          } else {
            return false;
          }


        });
      }
    }
    else if (currentItem.items && currentItem.items.length) {
      currentItem.items = addprojectinsidetask(
        currentItem.items,
        directorycode,
        intl,
        orderItems,
        filefor,
        screen
      );
    }

    return currentItem;
  });

  return returndata;
};
export const getitemnameonscreenanditempath = (screen: string, item: any) => {
  switch (screen) {
    case "Sheet":
      return item.order !== undefined
        ? item.order.batchid
        : getName(item.path);
    case "Protocol":
      return item.order !== undefined
        ? item.order.protoclordername
        : getName(item.path);
    case "Reportdesigner":
      return item.order !== undefined
        ? item.order.templatename
        : getName(item.path);
    case "Reportviewer":
      return item.order !== undefined
        ? item.order.reportname
        : getName(item.path);
    default:
      return item.batchid;
  }
};

export const getsheetitemnameonscreen = (item: any) => {
  let localdate = convertUTCDateToLocalDate(new Date(item.createdtimestamp));
  let d = new Date(localdate);

  let date = convertfulldateformat(d.getFullYear(), d.getMonth(), d.getDate()) + "_"
    + convertdoubledigittime(d.getHours(), d.getMinutes(), d.getSeconds())
    + "/" + item.batchid;
  return date;
}

export const getitemnameonscreen = (screen: string, item: any) => {
  switch (screen) {
    case "Sheet":
      return getsheetitemnameonscreen(item);
    case "Protocol":
      return item.protoclordername;
    case "Reportdesigner":
      return item.templatename;
    case "Reportviewer":
      return item.reportname;
    default:
      return item.batchid;
  }
};

export const replaceallordertoFolderwithfilefor = (
  data: DataModel[],
  directorycode: number | null,
  intl: any,
  orderItems: any[],
  filefor: string,
  screen: string
) => {
  if (directorycode === -1) {
    return data.map((item) => item);
  }

  if (!data) {
    return data;
  }

  let returndata = data.map((item) => {
    const currentItem = { ...item };

    if (
      currentItem.directorycode === directorycode &&
      currentItem.filefor === filefor
    ) {
      if (!currentItem.items) {
        currentItem.items = [];
      }

      currentItem.items = currentItem.items.filter(
        (obj) => obj.order === undefined
      );

      // currentItem.expanded = true;

      if (orderItems !== undefined && orderItems.length > 0) {
        orderItems.map((objorder) => {
          if (currentItem.items !== undefined) {
            let id = getitemnameonscreen(screen, objorder);

            let additem = {
              path: `${currentItem.path}/${id + ".ord"}`,
              dateCreated: convertDateFormat(
                new Date(objorder.createdtimestamp) || null,
                intl
              ),
              dateModified: convertDateFormat(
                new Date(objorder.createdtimestamp) || null,
                intl
              ),
              dateCompleted: convertDateFormat(
                new Date(objorder.completedtimestamp === undefined ? new Date() : objorder.completedtimestamp) || null,
                intl
              ),
              size: 124,
              icon: convertExtensionToIcon(
                id + ".ord",
                objorder.orderflag !== undefined &&
                  objorder.orderflag.trim() === "R" && objorder.ordercancell === undefined
                  ? "#54c518" : objorder.ordercancell !== undefined && objorder.ordercancell === 1 ? "#808080"
                    : "#e16733"
              ),
              // icon: convertExtensionToIcon(
              //   id + ".ord",
              //   objorder.orderflag !== undefined &&
              //     objorder.orderflag.trim() === "R"
              //     ? "#54c518"
              //     : "#e16733"
              // ),
              items: [],
              directorycode:
                screen === "Sheet"
                  ? objorder.batchcode
                  : objorder.protocolordercode,
              //  objorder.batchcode,
              parentdircode: currentItem.directorycode,
              directoryname: id,
              // objorder.batchid,
              order: objorder,
              link: generateLink(screen === "Sheet" ? objorder.batchcode : objorder.protocolordercode, screen, currentItem.filefor),
              filefor: filefor,
            };
            currentItem.items.push(additem);
          }
          return objorder;
        });
      }
    } else if (currentItem.items && currentItem.items.length) {
      currentItem.items = replaceallordertoFolderwithfilefor(
        currentItem.items,
        directorycode,
        intl,
        orderItems,
        filefor,
        screen
      );
    }

    return currentItem;
  });

  return returndata;
};

export const deleteorderitemstoFolderwithfilefor = (
  data: DataModel[],
  directorycode: number | null,
  intl: any,
  orderItems: any[],
  filefor: String,
  screen: string
) => {
  if (directorycode === -1) {
    return data.map((item) => item);
  }

  if (!data) {
    return data;
  }

  let returndata = data.map((item) => {
    const currentItem = { ...item };

    if (
      currentItem.directorycode === directorycode &&
      currentItem.filefor === filefor
    ) {
      if (!currentItem.items) {
        currentItem.items = [];
      }

      // currentItem.expanded = true;

      if (orderItems !== undefined && orderItems.length > 0) {
        orderItems.map((objorder) => {
          if (currentItem.items !== undefined) {
            let itemindex = currentItem.items.findIndex(
              (obj) =>
                obj.directoryname ===
                (screen === "Sheet"
                  ? objorder.batchid
                  : objorder.protoclordername)
            );

            if (itemindex > -1) {
              currentItem.items.splice(itemindex, 1);
            }
          }
          return objorder;
        });
      }
    } else if (currentItem.items && currentItem.items.length) {
      currentItem.items = deleteorderitemstoFolderwithfilefor(
        currentItem.items,
        directorycode,
        intl,
        orderItems,
        filefor,
        screen
      );
    }

    return currentItem;
  });

  return returndata;
};

export const deletefileitemstoFolderwithfilefor = (
  data: DataModel[],
  directorycode: number | null,
  intl: any,
  orderItems: any[],
  filefor: String,
  screen: string
) => {
  if (directorycode === -1) {
    return data.map((item) => item);
  }

  if (!data) {
    return data;
  }

  let returndata = data.map((item) => {
    let currentItem = { ...item };

    if (
      currentItem.directorycode === directorycode &&
      currentItem.filefor === filefor
    ) {
      if (!currentItem.items) {
        currentItem.items = [];
      }

      // currentItem.expanded = true;

      if (orderItems !== undefined && orderItems.length > 0) {
        orderItems.map((objorder) => {
          if (currentItem.items !== undefined) {
            let itemindex = currentItem.items.findIndex(
              (obj) =>
                obj.directorycode === (screen === "Sheet"
                  ? objorder.directorycode
                  : objorder.directorycode)
            );

            if (itemindex > -1) {
              currentItem.items.splice(itemindex, 1);
            }
          }
          return objorder;
        });
      }
    } else if (currentItem.items && currentItem.items.length) {
      currentItem.items = deletefileitemstoFolderwithfilefor(
        currentItem.items,
        directorycode,
        intl,
        orderItems,
        filefor,
        screen
      );
    }

    return currentItem;
  });

  return returndata;
};

export const getFolderToSave = (
  data: DataModel[],
  selectedItem: DataModel | null,
  intl: any,
  name: string,
  screen: string,
  response: any
) => {
  let addfolder: DataModel = {};
  const folderName = name !== undefined && name !== null ? name : "New Folder";
  if (!selectedItem) {
    addfolder = {
      path: `${screen}/${folderName}`,
      dateCreated: new Date(),
      dateModified: new Date(),
      size: 124,
      icon: null,
      parentdircode: -1,
      directoryname: folderName,
      createdby: response.createdby,
      createdbyusername: response.createdbyusername,
      sitemaster: response.sitemaster,
      viewoption: response.viewoption,
      // teamcode: response.teamcode !== undefined ? response.teamcode : null,
      teamname: response.teamname !== undefined ? response.teamname : undefined
    };
  } else {
    addfolder = {
      path: `${selectedItem?.path}/${folderName}`,
      dateCreated: new Date(),
      dateModified: new Date(),
      size: 124,
      icon: null,
      parentdircode: selectedItem?.directorycode,
      directoryname: folderName,
      createdby: response.createdby,
      createdbyusername: response.createdbyusername,
      sitemaster: response.sitemaster,
      viewoption: response.viewoption,
      // teamcode: response.teamcode !== undefined ? response.teamcode : null,
      teamname: response.teamname !== undefined ? response.teamname : undefined
    };
  }
  if (response !== undefined && response.onlytome !== undefined) {
    addfolder.onlytome = response.onlytome;
  } else if (response !== undefined && response.tothesite !== undefined) {
    addfolder.tothesite = response.tothesite;
  }
  return addfolder;
};

export const registerOrder = (
  data: DataModel[],
  selectedItem: DataModel | null,
  intl: any,
  name: string
) => {
  let createOrder: DataModel = {};
  return createOrder;
};
export const toggleViewBtnGroup = (
  btnGroupState: GridViewBtnGroup,
  view: string
) => {
  if (!btnGroupState.listView && view !== "grid") {
    return { gridView: false, listView: true };
  }
  if (!btnGroupState.gridView && view !== "list") {
    return { gridView: true, listView: false };
  }
  return btnGroupState;
};

export const toggleSortBtnGroup = (
  btnGroupState: SortingBtnGroup,
  curState: string
) => {
  if (!btnGroupState.sortDesc && curState !== "asc") {
    return { sortAsc: false, sortDesc: true };
  }
  if (!btnGroupState.sortAsc && curState !== "desc") {
    return { sortAsc: true, sortDesc: false };
  }
  return btnGroupState;
};

export const formatBytes = (bytes: number, decimals: number = 2) => {
  if (bytes === 0) return "0 Bytes";

  const k: number = 1024;
  const dm: number = decimals < 0 ? 0 : decimals;
  const sizes: string[] = [
    "Bytes",
    "KB",
    "MB",
    "GB",
    "TB",
    "PB",
    "EB",
    "ZB",
    "YB",
  ];

  const i: number = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};

export const editDataItem = (
  data: any,
  selectedItem: DataModel,
  newPath: string,
  intl: any,
) => {
  if (!data) {
    return data;
  }
  const newItems: DataModel[] = [];

  for (let index = 0; index < data.length; index++) {
    const currentItem = { ...data[index] };
    if (currentItem.path === selectedItem.path) {
      currentItem.path = newPath;
      currentItem.dateModified = convertDateFormat(new Date(), intl);
    }

    if (currentItem.items) {
      currentItem.items = editDataItem(
        currentItem.items,
        selectedItem,
        newPath,
        intl
      );
    }
    newItems.push(currentItem);
  }
  return newItems;
};

export const removeDataItem = (data: any, selectedItem: DataModel) => {
  if (!data) {
    return data;
  }
  const newItems: DataModel[] = [];

  for (let index = 0; index < data.length; index++) {
    const currentItem = { ...data[index] };
    if (currentItem.directorycode !== selectedItem.directorycode) {
      if (currentItem.items && currentItem.items.length) {
        currentItem.items = removeDataItem(currentItem.items, selectedItem);
      }

      newItems.push(currentItem);
    }
  }
  return newItems;
};

export const removeDataItemandStaticFolder = (
  data: any,
  selectedItem: DataModel | DataModel[]
) => {
  if (!data) {
    return data;
  }
  const newItems: DataModel[] = [];

  for (let index = 0; index < data.length; index++) {
    const currentItem = { ...data[index] };
    if (!Array.isArray(selectedItem) &&
      currentItem.directorycode !== selectedItem.directorycode &&
      currentItem.filefor === "DR"
    ) {
      if (currentItem.items && currentItem.items.length) {
        currentItem.items = removeDataItemandStaticFolder(currentItem.items, selectedItem);
      }

      newItems.push(currentItem);
    }
    else if (Array.isArray(selectedItem) && currentItem.filefor === "DR" &&
      selectedItem.findIndex(dir => dir.directorycode === currentItem.directorycode) < 0) {
      if (currentItem.items && currentItem.items.length) {
        currentItem.items = removeDataItemandStaticFolder(currentItem.items, selectedItem);
      }

      newItems.push(currentItem);
    }
  }
  return newItems;
};

const updatepathandparentdirectory = (items: any, parentpath: String) => {

  for (let index = 0; index < items.length; index++) {
    if (parentpath === "Sheet") {
      items[index].parentdircode = -1;
    }
    items[index].path = parentpath + "/" + items[index].directoryname

    if (items[index].items && items[index].items?.length) {
      items[index].items = updatepathandparentdirectory(items[index].items, items[index].path);
    }
  }

  return items;
}

export const collectcustomfolderfromstatic = (data: any, newItems: DataModel[]) => {
  if (!data) {
    return data;
  }

  for (let index = 0; index < data.length; index++) {
    const currentItem = { ...data[index] };

    if (currentItem.filefor !== "DR") {
      if (currentItem.items && currentItem.items.length) {
        let childitems = collectcustomfolderfromstatic(currentItem.items, newItems);
        if (childitems && childitems.length) {
          let path = `Sheet`;
          newItems.concat(updatepathandparentdirectory(childitems, path));
        }
      }
    }
    else {
      newItems.push(currentItem);
    }
  }

  return newItems;
}

export const removeItem = (data: any, selectedItem: DataModel) => {
  if (!data) {
    return data;
  }
  let itemindex = -1;
  for (let index = 0; index < data.length; index++) {
    const currentItem = { ...data[index] };
    if (currentItem.path === selectedItem.path) {
      itemindex = index;
      break;
    } else if (currentItem.items && currentItem.items.length) {
      currentItem.items = removeItem(currentItem.items, selectedItem);
    }
  }
  if (itemindex > -1) {
    data.splice(itemindex, 1);
  }
  return data;
};

export const getDirectoryOncode = (data: any, directorycode: number) => {
  if (!data) {
    return data;
  }

  let selecteddirectory = {};
  for (let index = 0; index < data.length; index++) {
    const currentItem = { ...data[index] };

    if (
      currentItem.directorycode !== undefined &&
      currentItem.directorycode === directorycode
    ) {
      selecteddirectory = currentItem;
      break;
    } else if (currentItem.items && currentItem.items.length) {
      selecteddirectory = getDirectoryOncode(currentItem.items, directorycode);
      if (
        selecteddirectory !== undefined &&
        Object.keys(selecteddirectory).length > 0
      ) {
        break;
      }
    }
  }

  return selecteddirectory;
};

export const getDirectoryOncodeandfilefor = (
  data: any,
  directorycode: number,
  filefor: String
) => {
  if (!data) {
    return data;
  }

  let selecteddirectory = {};
  for (let index = 0; index < data.length; index++) {
    const currentItem = { ...data[index] };
    if (
      currentItem.directorycode !== undefined &&
      currentItem.directorycode === directorycode &&
      currentItem.filefor === filefor
    ) {
      selecteddirectory = currentItem;
      break;
    } else if (currentItem.items && currentItem.items.length) {
      selecteddirectory = getDirectoryOncodeandfilefor(
        currentItem.items,
        directorycode,
        filefor
      );
      if (
        selecteddirectory !== undefined &&
        Object.keys(selecteddirectory).length > 0
      ) {
        break;
      }
    }
  }

  return selecteddirectory;
};

export const getDirectoryOnName = (data: any, directoryname: String) => {
  if (!data) {
    return data;
  }

  let selecteddirectory = {};
  for (let index = 0; index < data.length; index++) {
    const currentItem = { ...data[index] };
    if (
      currentItem.directoryname !== undefined &&
      currentItem.directoryname === directoryname
    ) {
      selecteddirectory = currentItem;
      break;
    } else if (currentItem.items && currentItem.items.length) {
      selecteddirectory = getDirectoryOnName(currentItem.items, directoryname);
      if (
        selecteddirectory !== undefined &&
        Object.keys(selecteddirectory).length > 0
      ) {
        break;
      }
    }
  }

  return selecteddirectory;
};

export const getAllFolderFromItem = (
  data: any,
  deletedirectory: any,
  movetodirectory: number,
  maintaindirectory: boolean,
  movetopath: any
) => {
  if (!data) {
    return data;
  }

  let directories: any = [];
  let orders: any = [];
  for (let index = 0; index < data.length; index++) {
    const currentItem = { ...data[index] };

    if (currentItem.items && currentItem.items.length) {
      let directory = currentItem;
      if (!maintaindirectory) {
        directory.parentdircode = -2;
        directory.dircodetomove = movetopath.directorycode;
      } else {
        if (currentItem.directorycode === deletedirectory.directorycode) {
          directory.parentdircode = -2;
          directory.dircodetomove = movetopath.directorycode;
        } else {
          if (directory.parentdircode === deletedirectory.directorycode) {
            directory.parentdircode = movetodirectory;
          }
          let newpath = directory.path.replace(
            deletedirectory.path,
            movetopath.path
          );
          directory.path = newpath;
        }
      }
      delete directory.icon;
      directory.dateCreated = new Date(directory.dateCreated);
      directory.dateModified = new Date(directory.dateModified);
      if (directory.filefor === "DR")
        directories.push(directory);

      let childobject: any = getAllFolderFromItem(
        currentItem.items,
        deletedirectory,
        movetodirectory,
        maintaindirectory,
        movetopath
      );
      orders = orders.concat(childobject.orders);
      directories = directories.concat(childobject.directries);
    } else {
      if (currentItem.order !== undefined) {
        let order = currentItem.order;
        if (!maintaindirectory) {
          order.directorycode = movetodirectory;
        }
        else if (
          maintaindirectory &&
          currentItem.parentdircode === deletedirectory.directorycode
        ) {
          order.directorycode = movetodirectory;
        }
        orders.push(order);
      } else {
        let directory = currentItem;
        if (!maintaindirectory) {
          directory.parentdircode = -2;
          directory.dircodetomove = movetopath.directorycode;
        } else {
          if (currentItem.directorycode === deletedirectory.directorycode) {
            directory.parentdircode = -2;
            directory.dircodetomove = movetopath.directorycode;
          } else {
            if (directory.parentdircode === deletedirectory.directorycode) {
              directory.parentdircode = movetodirectory;
            }
            let newpath = directory.path.replace(
              deletedirectory.path,
              movetopath.path
            );
            directory.path = newpath;
          }
        }

        delete directory.icon;
        directory.dateCreated = new Date(directory.dateCreated);
        directory.dateModified = new Date(directory.dateModified);
        if (directory.filefor === "DR")
          directories.push(directory);
      }
    }
  }

  let structureobject = { orders: orders, directries: directories };

  return structureobject;
};


export const getAllFolderFromMultiItem = (
  data: any,
  deletedirectory: any,
  movetodirectory: number,
  maintaindirectory: boolean,
  movetopath: any
) => {
  if (!data) {
    return data;
  }

  let directories: any = [];
  let orders: any = [];
  for (let index = 0; index < data.length; index++) {
    const currentItem = { ...data[index] };

    if (currentItem.items && currentItem.items.length) {
      let directory = currentItem;
      if (!maintaindirectory) {
        directory.parentdircode = -2;
        directory.dircodetomove = movetopath.directorycode;
      }
      //  else {
      //   if (currentItem.directorycode === deletedirectory.directorycode) {
      //     directory.parentdircode = -2;
      //     directory.dircodetomove = movetopath.directorycode;
      //   } else {
      //     if (directory.parentdircode === deletedirectory.directorycode) {
      //       directory.parentdircode = movetodirectory;
      //     }
      //     let newpath = directory.path.replace(
      //       deletedirectory.path,
      //       movetopath.path
      //     );
      //     directory.path = newpath;
      //   }
      // }

      deletedirectory.forEach((item: any) => {
        if (
          currentItem.directorycode === item.directorycode) {
          directory.parentdircode = -2;
          directory.dircodetomove = movetopath.directorycode;

          let newpath = directory.path.replace(
            item.path,
            movetopath.path
          );
          directory.path = newpath;

        }
        else {
          if (directory.parentdircode === item.directorycode) {
            directory.parentdircode = movetodirectory;

            let newpath = directory.path.replace(
              item.path,
              movetopath.path
            );
            directory.path = newpath;
          }
        }
      })
      delete directory.icon;
      directory.dateCreated = new Date(directory.dateCreated);
      directory.dateModified = new Date(directory.dateModified);
      if (directory.filefor === "DR")
        directories.push(directory);

      let childobject: any = getAllFolderFromMultiItem(
        currentItem.items,
        deletedirectory,
        movetodirectory,
        maintaindirectory,
        movetopath
      );
      orders = orders.concat(childobject.orders);
      directories = directories.concat(childobject.directries);
    } else {
      if (currentItem.order !== undefined) {
        let order = currentItem.order;
        if (!maintaindirectory) {
          order.directorycode = movetodirectory;
        }

        // else if (
        //   maintaindirectory &&
        //   currentItem.parentdircode === deletedirectory.directorycode
        // ) {
        //   order.directorycode = movetodirectory;
        // }

        deletedirectory.forEach((item: any) => {
          if (maintaindirectory &&
            currentItem.parentdircode === item.directorycode) {
            order.directorycode = movetodirectory;
          }
        })

        orders.push(order);
      } else {
        let directory = currentItem;
        if (!maintaindirectory) {
          directory.parentdircode = -2;
          directory.dircodetomove = movetopath.directorycode;
        } else {
          // if (currentItem.directorycode === deletedirectory.directorycode) {
          //   directory.parentdircode = -2;
          //   directory.dircodetomove = movetopath.directorycode;
          // } else {
          //   if (directory.parentdircode === deletedirectory.directorycode) {
          //     directory.parentdircode = movetodirectory;
          //   }

          deletedirectory.forEach((item: any) => {
            if (
              currentItem.directorycode === item.directorycode) {
              directory.parentdircode = -2;
              directory.dircodetomove = movetopath.directorycode;

              let newpath = directory.path.replace(
                item.path,
                movetopath.path
              );
              directory.path = newpath;

            }
            else {
              if (directory.parentdircode === item.directorycode) {
                directory.parentdircode = movetodirectory;

                let newpath = directory.path.replace(
                  item.path,
                  movetopath.path
                );
                directory.path = newpath;
              }
            }
          })

          // let newpath = directory.path.replace(
          //   deletedirectory.path,
          //   movetopath.path
          // );
          // directory.path = newpath;

        }

        delete directory.icon;
        directory.dateCreated = new Date(directory.dateCreated);
        directory.dateModified = new Date(directory.dateModified);
        if (directory.filefor === "DR")
          directories.push(directory);
      }
    }
  }

  let structureobject = { orders: orders, directries: directories };

  return structureobject;
};

export const Movemultifolders = (data: any, directoriestomove: any, movetodirectory: DataModel) => {
  if (!data) {
    return data;
  }

  let removedata: DataModel[] = [];
  let extracteddata = collectandRemoveFolderstoMove(data, directoriestomove, movetodirectory, removedata);
  let newitem: DataModel[] = extracteddata.data;
  let removeitem: DataModel[] = extracteddata.movedata;


  return movefolders(newitem, removeitem, movetodirectory);
}

export const movefolders = (data: any, removeitem: any, movetodirectory: DataModel) => {
  if (!data) {
    return data;
  }

  let newitem: DataModel[] = [];
  for (let index = 0; index < data.length; index++) {
    const currentItem = { ...data[index] };
    if (currentItem.directorycode === movetodirectory.directorycode && currentItem.filefor === "DR") {
      if (currentItem.items && currentItem.items.length > 0) {
        currentItem.items = [...currentItem.items, ...removeitem];
      }
      else {
        currentItem.items = removeitem;
      }
    }
    else if (currentItem.items && currentItem.items.length > 0) {
      currentItem.items = movefolders(currentItem.items, removeitem, movetodirectory);
    }

    newitem.push(currentItem);
  }
  return newitem;
}

export const collectandRemoveFolderstoMove = (data: any, directoriestomove: any, movetodirectory: DataModel, removeitem: DataModel[]) => {
  if (!data) {
    return data;
  }
  let newitem: DataModel[] = [];
  for (let index = 0; index < data.length; index++) {
    const currentItem = { ...data[index] };

    if (directoriestomove.findIndex((dir: DataModel) => dir.directorycode === currentItem.directorycode) > -1 && currentItem.filefor === "DR") {
      if (movetodirectory && movetodirectory.directorycode) {

        if (currentItem.items && currentItem.items.length > 0) {
          currentItem.items = changepathandparentcodeonmultimove(currentItem.items, currentItem.path, currentItem.parentdircode);

        }
        else {
          let path = movetodirectory.path + "/" + currentItem.directoryname;
          currentItem.parentdircode = movetodirectory.directorycode;
          currentItem.path = path;
        }
        removeitem.push(currentItem);
      }
    }
    else if (currentItem.items && currentItem.items.length) {
      let childdata = collectandRemoveFolderstoMove(currentItem.items, directoriestomove, movetodirectory, removeitem);
      currentItem.items = childdata.data;
      newitem.push(currentItem);
      // removeitem.concat(childdata.movedata);
    }
    else {
      newitem.push(currentItem);
    }

  }

  return { data: newitem, movedata: removeitem }
};

export const changepathandparentcodeonmultimove = (data: any, path: string, parentcode: number) => {
  if (!data) {
    return data;
  }
  let newitem: DataModel[] = [];
  for (let index = 0; index < data.length; index++) {
    const currentItem = { ...data[index] };

    if (currentItem.items && currentItem.items.length > 0) {
      if (currentItem.order) {
        currentItem.path = path + "/" + currentItem.directoryname + ".ord";
      }
      else {
        currentItem.path = path + "/" + currentItem.directoryname;
      }
      currentItem.parentdircode = parentcode;
      currentItem.items = changepathandparentcodeonmultimove(currentItem.items, currentItem.path, currentItem.parentdircode);
    }
    else {
      if (currentItem.order) {
        currentItem.path = path + "/" + currentItem.directoryname + ".ord";
      }
      else {
        currentItem.path = path + "/" + currentItem.directoryname;
      }
      currentItem.parentdircode = parentcode;
    }

    newitem.push(currentItem);
  }

  return newitem;
}

export const convertToBreadcrumbData = (
  selectedItem: DataModel | null,
  screen: any
) => {
  let path: BreadcrumbDataModel[] = [];

  if (!selectedItem) {
    return [
      {
        id: screen === "Sheet" ? "Sheet" : "ProtocolOrder",
        name: screen === "Sheet" ? "Sheet" : "ProtocolOrder",
        iconClass: "k-i-home",
      },
    ];
  }

  if (selectedItem.path) {
    const items = selectedItem.path.split("/");
    let curItemPath: string[] = [];

    for (let i = 0; i < items.length; i++) {
      curItemPath.push(items[i]);

      if (items[i] === "Sheet") {
        path.push({
          id: "Sheet",
          name: items[i]
        });
      } else if (items[i] === "ProtocolOrder") {
        path.push({
          id: "Sheet",
          name: items[i]
        });
      } else if (items[i] === "Protocol") {
        path.push({
          id: items[i],
          name: items[i]
        });
      } else {
        path.push({
          id: curItemPath.join("/"),
          name: items[i],
        });
      }
    }
  }
  return path;
};

export const setdates = (
  filterType: any,
  isFiltered: any,
  different: string,
  toRange: Date,
  changedtodate: Boolean,
) => {
  if (!isFiltered) {
    let userDefinedDate = sessionStorage.userDefinedDate === "dd-MM-yyyy HH:mm:ss" || sessionStorage.userDefinedDate === "dd-MM-yy HH:mm:ss" ? "YY/MM/DD HH:mm:ss" : sessionStorage.userDefinedDate;
    let retuobj: any = {};
    if (filterType === 1) {
      //  setfromRange(() => {
      // let fromdate = new Date();
      // fromdate.setHours(0, 0, 0, 0);
      // //  return setDateAsuserDefined(fromdate, sessionStorage.userDefinedDate);
      // //  });
      // retuobj = {
      //   fromRange: setDateAsuserDefined(
      //     fromdate,
      //     sessionStorage.userDefinedDate
      //   ),
      // };
      const sheetdaydiff = localStorage.getItem(different);

      let daydiff = 7;
      if (typeof sheetdaydiff === "string" && sheetdaydiff !== "NaN") {
        daydiff = parseInt(JSON.parse(sheetdaydiff));
      }
      retuobj = {
        ...retuobj,
        fromRange: setDateAsuserDefined(new Date(new Date().setDate(new Date().getDate() - daydiff)), userDefinedDate),
      };
    } else {
      //  setfromRange(() => {
      const sheetdaydiff = localStorage.getItem(different);

      let daydiff = 7;
      if (typeof sheetdaydiff === "string" && sheetdaydiff !== "NaN") {
        daydiff = parseInt(JSON.parse(sheetdaydiff));
      }
      //  return setDateAsuserDefined(
      //    new Date(new Date().setDate(new Date().getDate() - daydiff)),
      //    sessionStorage.userDefinedDate
      //  );
      retuobj = {
        ...retuobj,
        fromRange: changedtodate ? setDateAsuserDefined(
          //  new Date(new Date().setDate(new Date().getDate() - daydiff)),
          new Date().setDate(new Date(toRange).getDate() - daydiff),
          userDefinedDate
        ) : (
          setDateAsuserDefined(
            new Date(new Date().setDate(new Date().getDate() - daydiff)),
            userDefinedDate
          )),
        toRange: changedtodate ? setDateAsuserDefined(toRange, userDefinedDate) : setDateAsuserDefined(new Date(), userDefinedDate)
      };
      //    retuobj = {
      //   ...retuobj,
      //   toRange:changedtodate ? setDateAsuserDefined(toRange, userDefinedDate) : setDateAsuserDefined(new Date(), userDefinedDate)
      // };

      return retuobj;
    }

    retuobj = {
      ...retuobj,
      toRange: setDateAsuserDefined(new Date(), userDefinedDate),
    };
  }

};

export const expandparentfolder = (
  data: DataModel[],
  directorycode: number | null,
  intl: any,
  filefor: String,
  screen: string
) => {

  if (!data) {
    return { data: data };
  }
  let isselectedparent = false;
  let returndata = data.map((item) => {
    const currentItem = { ...item };

    if (
      currentItem.directorycode === directorycode &&
      currentItem.filefor === filefor
    ) {
      currentItem.expanded = true;
      isselectedparent = true;
    }
    else if (currentItem.items && currentItem.items.length) {
      let dataitems = expandparentfolder(currentItem.items, directorycode, intl, filefor, screen);
      currentItem.items = dataitems.data;
      currentItem.expanded = dataitems.selectedparent;
    }

    return currentItem;
  });

  return { data: returndata, selectedparent: isselectedparent };
}


export const foldersstructure = (directory: any, key: any, intl: any) => {
  // let directory = directory;
  let orders: any = [];
  if (directory !== null && directory !== undefined && directory.length > 0) {

    directory = directory.filter((items: any) => items.path !== undefined);
    directory.forEach((dic: any, index: number) => {
      orders.push({
        directorycode: dic.directorycode,
        parentdircode: dic.parentdircode,
        dateCreated: convertDateFormat(
          convertUTCDateToLocalDate(new Date(dic.dateCreated)),
          intl
        ),
        dateModified: convertDateFormat(
          convertUTCDateToLocalDate(new Date(dic.dateModified)),
          intl
        ),
        size: 124,
        path: dic.path,
        directoryname: dic.directoryname,
        icon: convertExtensionToIcon(dic.directoryname, null),
        filefor: "DR",
        viewoption: dic.viewoption,
        teamname: dic.teamname !== undefined ? dic.teamname : undefined
      });
    });
  }
  let groupeddata = groupBy(orders, "parentdircode");
  let data = groupeddata["-1"];
  let folderdata: any = [];
  if (key === 1) {
    if (data !== undefined) {
      orders = formatData(
        formatserverData(data, groupeddata, intl, "Sheet", "Sheet"),
        intl
      );
      folderdata = orders;
    }
  } else {
    // let basepath = this.state.Orderfor === 1 ? "Sheet" : "Protocol";
    // let screen = this.state.Orderfor === 1 ? "Sheet" : "Protocol";
    if (data !== undefined) {
      orders = formatData(
        formatserverData(data, groupeddata, intl, "Protocol", "Protocol"),
        intl
      );
      folderdata = orders;
    }
  }
  return folderdata;
}
