import {
    LOGIN, SORT_DATA, SEARCH_DATA, TOGGLE_MENU, UPDATE_LANG, POST_CRUD, SEARCH_FIELD_DATA, FILE_UPLOAD,
    DETAIL_DATA, DEFAULT_RETURN, LOGOUT, DEFAULT_SITE_CHANGE, REQUEST_FAILURE
} from '../actions/types';

const initialState = {
    navigation: "methodMainMenu",
    fluidPage: false,
    userData: {
        userName: "Administrator",
        role: 1,
        domain: "LocalDB",
        language: "en",
        site: { sitecode: 1, sitename: "Default", status: 1 },
        userSite: 1,
        error: ""
    },
    hideMenu: {},
    masterData: {},
    visibility: true,
    transVisibility: true,
    fieldDetail: {},
    instrumentCategory: 2016,//primary key for 'General'
    saveAuditTrail: false,

    dataGridStatus: "",
    filterColumnName: "",
    searchColumnData: undefined,
    auditStatus: "",
    rawDataText: "",
    loginStatus: "",
    instrawdataurl: "",
    status: "",

    //status:"",
    loginUser: {
        usercode: 1,
        username: "Administrator",
        firstname: "Admin",
        lastname: "Admin",
        email: "admin@agaramtech.com",
        contactno: "4498765432",
        transstatusdetail: {
            transstatusdetailkey: 4,
            transstatus: { transstatuskey: 2, transstatusname: "Users" },
            statusmaster: { statuskey: 1, statusname: "Active", displayname: "Active" }
        },
        signImageUrl: null,
        userImageUrl: null,
        userimgname: null,
        signimgname: null,
        status: 0,
        createdby: { usercode: 1, username: "Administrator", firstname: "Admin", lastname: "Admin" },
        createddate: new Date()
    },
    loginSitePageList: [{
        "pagekey": 43,
        "module": {
            "modulekey": 7,
            "modulename": "MethodSetup",
            "site": {
                "sitecode": 1,
                "sitename": "Default",
                "status": 1
            },
            "status": 1
        },
        "pagename": "MethodSetup",
        "status": 1
    },
    {
        "pagekey": 44,
        "module": {
            "modulekey": 7,
            "modulename": "MethodSetup",
            "site": {
                "sitecode": 1,
                "sitename": "Default",
                "status": 1
            },
            "status": 1
        },
        "pagename": "MethodMaster",
        "status": 1
    },
    {
        "pagekey": 45,
        "module": {
            "modulekey": 7,
            "modulename": "MethodSetup",
            "site": {
                "sitecode": 1,
                "sitename": "Default",
                "status": 1
            },
            "status": 1
        },
        "pagename": "Delimiters",
        "status": 1
    },
    {
        "pagekey": 46,
        "module": {
            "modulekey": 7,
            "modulename": "MethodSetup",
            "site": {
                "sitecode": 1,
                "sitename": "Default",
                "status": 1
            },
            "status": 1
        },
        "pagename": "SampleSplit",
        "status": 1
    },
    {
        "pagekey": 47,
        "module": {
            "modulekey": 7,
            "modulename": "MethodSetup",
            "site": {
                "sitecode": 1,
                "sitename": "Default",
                "status": 1
            },
            "status": 1
        },
        "pagename": "ParserSetup",
        "status": 1
    },
    {
        "pagekey": 48,
        "module": {
            "modulekey": 7,
            "modulename": "MethodSetup",
            "site": {
                "sitecode": 1,
                "sitename": "Default",
                "status": 1
            },
            "status": 1
        },
        "pagename": "MethodDelimiter",
        "status": 1
    },
    {
        "pagekey": 49,
        "module": {
            "modulekey": 7,
            "modulename": "MethodSetup",
            "site": {
                "sitecode": 1,
                "sitename": "Default",
                "status": 1
            },
            "status": 1
        },
        "pagename": "GeneralField",
        "status": 1
    },
    {
        "pagekey": 51,
        "module": {
            "modulekey": 7,
            "modulename": "MethodSetup",
            "site": {
                "sitecode": 1,
                "sitename": "Default",
                "status": 1
            },
            "status": 1
        },
        "pagename": "CustomField",
        "status": 1
    },
    {
        "pagekey": 52,
        "module": {
            "modulekey": 7,
            "modulename": "InstrumentSetup",
            "site": {
                "sitecode": 1,
                "sitename": "Default",
                "status": 1
            },
            "status": 1
        },
        "pagename": "InstrumentCategory",
        "status": 1
    },
    {
        "pagekey": 53,
        "module": {
            "modulekey": 7,
            "modulename": "InstrumentSetup",
            "site": {
                "sitecode": 1,
                "sitename": "Default",
                "status": 1
            },
            "status": 1
        },
        "pagename": "InstrumentMaster",
        "status": 1
    },
    ],
    loginUserSiteRole: {
        userrolekey: 1,
        usersite: {
            usersitekey: 1,
            users: { usercode: 1, username: "Administrator", firstname: "Admin", lastname: "Admin", email: "admin@agaramtech.com" },
            site: { sitecode: 1, sitename: "Default", status: 1 },
            defaultstatus: 1,
            status: 1,
            createdby: { usercode: 1, username: "Administrator", firstname: "Admin", lastname: "Admin" },
            createddate: new Date()
        },
        rolepolicy: {
            rolepolicykey: 3,
            role: {
                rolekey: 7,
                rolename: "Administrator",
                description: "Administrator Role",
                site: { sitecode: 1, sitename: "Default", status: 1 },
                status: 0,
                createdby: { usercode: 1, username: "Administrator", firstname: "Admin", lastname: "Admin" },
                createddate: new Date()
            },
            passcodepolicy: { policykey: 1, policyname: "Administrator", description: "Used for Administrator", maxpasscode: 20, nooffailedattempt: 3 },
            status: 0,
            createdby: { usercode: 1, username: "Administrator", firstname: "Admin", lastname: "Admin" },
            createddate: new Date()
        },

        passcode: "A@m1n",
        expirydate: "2020-01-13T10:32:57.017Z",
        transstatusdetail: {
            transstatusdetailkey: 4,
            transstatus: { transstatuskey: 2, transstatusname: "Users" },
            statusmaster: { statuskey: 1, statusname: "Active", displayname: "Active" },
        },
        clientip: "192.168.0.147",
        defaultstatus: 1,
        status: 1,
        createdby: { usercode: 1, username: "Administrator", firstname: "Admin", lastname: "Admin" },
        createddate: new Date()
    },
    loginInvalidAttempts: 0,
    Tree: {
        loadTreeProperties: false,
        operation: "create"
    },
   // materialcategory:{
     //   nmaterialtypecode:"",
        //sdescription:"",
      //  smaterialcatname:""

    // }
}

const LoginReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOGIN:
            return {
                ...state,
                userData: { ...action.payload.userData }
            }

        case LOGOUT:
            //console.log("logout reducer");
            return {
                ...initialState,
                ...action.payload
            }

        case POST_CRUD:
            //console.log("POST_CRUD response in reducer");
            return {
                ...state,
                masterData: {
                    ...state.masterData,
                    originalData: action.payload.originalData,
                    selectedItem: action.payload.selectedItem,
                    initialData: action.payload.initialData
                },
                inputParam: action.payload.inputParam,
                navigation: action.payload.navigation,
                fieldDetail: action.payload.fieldDetail,
                updateData: action.payload.selectedItem,
                visibility: action.payload.inputParam.hideMenu,
                transVisibility: action.payload.inputParam.transVisibility,
                exportVisibility: action.payload.inputParam.exportVisibility,
                operation: action.payload.inputParam.operation,
                dataGridStatus: "",
                filterColumnName: "",
                searchColumnData: undefined,
                auditStatus: "",
                rawDataText: "",
                fluidPage: false
            }

        case DEFAULT_SITE_CHANGE:
            return {
                ...state,
                masterData: {
                    ...state.masterData,
                    originalData: action.payload.originalData,
                    selectedItem: action.payload.selectedItem,
                    initialData: action.payload.initialData
                },
                inputParam: action.payload.inputParam,
                navigation: action.payload.navigation,
                fieldDetail: action.payload.fieldDetail,
                updateData: action.payload.selectedItem,
                visibility: action.payload.inputParam.hideMenu,
                transVisibility: action.payload.inputParam.transVisibility,
                exportVisibility: action.payload.inputParam.exportVisibility,
                operation: action.payload.inputParam.operation,
                dataGridStatus: "",
                filterColumnName: "",
                searchColumnData: undefined
            }

        case SORT_DATA:
            return {
                ...state,
                updateData: action.payload.updateData,
                dataGridStatus: "",

                masterData: {
                    ...state.masterData,
                    initialData: action.payload.initialData,
                    sortColumn: action.payload.sortColumn,
                    sortType: action.payload.sortType,
                    filterColumnName: action.payload.filterColumnName
                }
            }

        case SEARCH_DATA:
            return {
                ...state,
                updateData: action.payload.updateData,
                dataGridStatus: action.payload.dataGridStatus,
                masterData: {
                    ...state.masterData,
                    initialData: action.payload.initialData,
                    selectedItem: action.payload.selectedItem,
                    filterColumnName: action.payload.filterColumnName

                },
                searchColumnData: action.payload.searchColumnData
            }

        case TOGGLE_MENU:
            return {
                ...state,
                navigation: action.payload.navigation,
                visibility: action.payload.visibility,
                transVisibility: action.payload.transVisibility,
                exportVisibility: action.payload.exportVisibility,
                loginStatus: "",
                inputParam: {
                    ...state.inputParam,
                    submenu: action.payload.subMenu
                },
                rawDataText: "",
                instrawdataurl: "",
                fluidPage: action.payload.fluidPage,
                manipulationStatus: "",
                dataGridStatus: ""
            }

        case UPDATE_LANG:
            return {
                ...state,
                userData: {
                    ...state.userData,
                    language: action.payload
                }
            }

        case SEARCH_FIELD_DATA:
            return {
                ...state,
                ...action.payload,
                status: ""
            }

        // case FILE_UPLOAD:
        //      return {
        //         ...state,                
        //         updateData : {...state.updateData,
        //             ...action.payload}
        //     }      

        case FILE_UPLOAD:
            return {
                ...state,
                // updateData : {...state.updateData,
                //    ...action.payload}
                ...action.payload
            }


        case DETAIL_DATA:
            return {
                ...state,
                masterData: {
                    ...state.masterData,
                    detailedData: action.payload.detailedData
                },
                updateData: action.payload.updateData,
                dataGridStatus: ""
            }

        case DEFAULT_RETURN:
            return {
                ...state,
                ...action.payload
            }
        case REQUEST_FAILURE:
            return {
                ...state,
                error: action.payload
            }

        default:
            return { ...state };
    };
};

export default LoginReducer;