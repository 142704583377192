/* eslint-disable react-hooks/exhaustive-deps */
import React, { Suspense, useState, useEffect, useContext } from "react";
import { useHistory } from 'react-router-dom';
// import '@progress/kendo-theme-default/dist/all.css';
import {
  Splitter,
  SplitterOnChangeEvent,
} from "@progress/kendo-react-layout";
import { useInternationalization } from "@progress/kendo-react-intl";
import { process, SortDescriptor, State } from "@progress/kendo-data-query";
import { clone, getter } from "@progress/kendo-react-common";
import {
  GridRowDoubleClickEvent,
  GridRowClickEvent,
  getSelectedState,
} from "@progress/kendo-react-grid";

import { withTranslation } from "react-multi-lang";
import {
  sheetDefaultContent,
  isMultitenant,
} from "./../../custom_functions/Constance.jsx";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import PageLoader from "./../../custom_modules/general/PageLoader.jsx";
import {
  DataModel,
  PanesModel,
  SplitBtnItems,
  SelectedItemType,
  UploadAddEvent,
  ViewChangeEvent,
  SortChangeEvent,
  AppSwitchChangeEvent,
  ContextMenuEvent,
} from "../../interfaces/FileManagerModels";

import {
  formatData,
  formatserverData,
  groupBy,
  // groupByobject,
  getFolderTree,
  searchTreeItem,
  searchTreeItemonpath,
  addDataItem,
  addFolderonObj,
  addprojectinsidetask,
  getFolderToSave,
  convertExtensionToIcon,
  convertDateFormat,
  convertToBreadcrumbData,
  removeDataItem,
  editDataItem,
  getFolderPath,
  removeItem,
  getDirectoryOncode,
  getDirectoryOncodeandfilefor,
  getDirectoryOnName,
  addfileitemstoFolderwithfilefor,
  addfileitemsforhomebutton,
  addorderitemsforhomebutton,
  addorderitemstoFolderwithfilefor,
  replaceallordertoFolderwithfilefor,
  deleteorderitemstoFolderwithfilefor,
  deletefileitemstoFolderwithfilefor,
  deletefileitemsforhomebutton,
  replaceonechangeorderordertoFolderwithfilefor,
  getAllFolderFromItem,
  getAllFolderFromMultiItem,
  setdates,
  expandparentfolder,
  Movemultifolders,
  getsheetitemnameonscreen,
  validateTreeItem
} from "../../helpers/helperMethods";

import {
  extractValuefromsheet,
  Silentauditobject,
  AuditTrailValidation,
  GetutcDate,
  Servicecalls,
  Getuserprojectfromsession,
  Getuserworkflowfromsession,
  generateLink,
  setDateAsuserDefined,
  Manualauditobject,
  dateDiffInDays,
  convertUTCDateToLocalDate,
  ControlRights,
  Getteamusersfromsession,
  valiadateTenantPackage,
  orderType,
  GetutcDateaudi,
  updateOrderStatusCheck
} from "./../../custom_functions/Functions.jsx";

import { updateorderopen } from "./../../redux_store/actions/index";

import { useLayoutEffect } from "react";
import store from "./../../redux_store/store/index";

import Service from "./../../custom_modules/general/Service.jsx";


import { Modal, ModalBody } from "reactstrap";
import Audit from "../../custom_modules/Audit";
//import Audit from "../Audit";
// const Audittrail = React.lazy(
//   () => import("../../custom_modules/Audit")
// );
import "bootstrap";
import $ from "jquery";

import { UploadFileInfo } from "@progress/kendo-react-upload";
import { TreeViewItemClickEvent } from "@progress/kendo-react-treeview";
import { InputChangeEvent } from "@progress/kendo-react-inputs";
import { Offset } from "@progress/kendo-react-popup";
import { withAlert } from "react-alert";
// import { FileManagerToolbar } from "../../controls/FileManagerToolbar";
import { NameCell, DateCreatedCell } from "../../controls/GridView";
import { MyFolderContext } from "../main_pages/orders/PRegistertask.jsx";
// import { ListView } from "../../controls/ListView";
// import { FileInformation } from "../../controls/FileInformation";
// import { FolderTree } from "../../controls/FolderTree";
// import { DateFilter } from "../../controls/DateFilter";
// import { BreadcrumbComponent } from "../../controls/Breadcrumb";
// import {
//   DeleteDialog,
//   EditDialog,
//   MoveDialog,
//   AddDialog,
//   CommonDialog,
// } from "../../controls/Dialog";
// import { ContextMenu } from "../../controls/ContextMenu";
// import { Unsharelistview } from "./../../custom_functions/ManipulationFunction.jsx";
// import { PendingViewCell1 } from "./../../controls/columnMenu.jsx";
const PendingViewCell = React.lazy(() => import('./../../controls/PendingViewCell.jsx'));
const Unsharelistview = React.lazy(() => import('./../../custom_functions/ManipulationFunction.jsx').then(({ Unsharelistview }) => ({ default: Unsharelistview })),
);
const FileManagerToolbar = React.lazy(() => import('../../controls/FileManagerToolbar').then(({ FileManagerToolbar }) => ({ default: FileManagerToolbar })),
);

const GridView = React.lazy(() => import('../../controls/GridView').then(({ GridView }) => ({ default: GridView })),
);
const ListView = React.lazy(() => import('../../controls/ListView').then(({ ListView }) => ({ default: ListView })),
);

const FileInformation = React.lazy(() => import('../../controls/FileInformation').then(({ FileInformation }) => ({ default: FileInformation })),
);

const FolderTree = React.lazy(() => import('../../controls/FolderTree').then(({ FolderTree }) => ({ default: FolderTree })),
);

const DateFilter = React.lazy(() => import('../../controls/DateFilter').then(({ DateFilter }) => ({ default: DateFilter })),
);
const BreadcrumbComponent = React.lazy(() => import('../../controls/Breadcrumb').then(({ BreadcrumbComponent }) => ({ default: BreadcrumbComponent })),
);

const DeleteDialog = React.lazy(() => import("../../controls/Dialog").then(({ DeleteDialog }) => ({ default: DeleteDialog })),);

const EditDialog = React.lazy(() => import("../../controls/Dialog").then(({ EditDialog }) => ({ default: EditDialog })),);

const MoveDialog = React.lazy(() => import("../../controls/Dialog").then(({ MoveDialog }) => ({ default: MoveDialog })),);

const AddDialog = React.lazy(() => import("../../controls/Dialog").then(({ AddDialog }) => ({ default: AddDialog })),);

const CommonDialog = React.lazy(() => import("../../controls/Dialog").then(({ CommonDialog }) => ({ default: CommonDialog })),);
const ContextMenu = React.lazy(() => import('../../controls/ContextMenu').then(({ ContextMenu }) => ({ default: ContextMenu })),
);
const isPremiumTenant = valiadateTenantPackage() === "3" ? true : false;
export const MyGlobalContext = React.createContext<any>(null);
export const OrdershowContext = React.createContext<any>(null);
// export const AuditUrl = React.createContext<any>(null);

const KendoTreeList = React.lazy(
  () => import("../../controls/KendoTreeList"));

const Sheetorderview = React.lazy(() => import("./Sheetorderview"));


const splitterPanes: PanesModel[] = [
  {
    size: "20%",
    min: "20px",
    collapsible: true,
  },
  {},
  {
    size: "20%",
    min: "20px",
    collapsible: true,
  },
];

const initialSort: SortDescriptor[] = [
  {
    field: "path",
    dir: "desc",
  },
];

const DATA_ITEM_KEY = "path";
const SELECTED_FIELD = "selected";
const idGetter = getter(DATA_ITEM_KEY);


let passobjectdates = {};
const Sheetorder = (props: any) => {
  const currentDate = sessionStorage.getItem("currentDate");
  const Foldercontext = useContext(MyFolderContext);
  const history = useHistory();
  const [sheetdatainit, setsheetdatainit] = React.useState<any>(undefined);
  const [sheetdatainitservice, setsheetdatainitservice] = React.useState(false);

  const intl = useInternationalization();
  const updateuserrights = (screenrights: any) => {
    let pend = ControlRights("IDS_MDL_ORDERS", "IDS_TSK_PENDINGWORK");
    let complete = ControlRights("IDS_MDL_ORDERS", "IDS_TSK_COMPLETEDWORK");
    // let myOrder = ControlRights("IDS_MDL_ORDERS", "IDS_TSK_MYORDERS");
    // let assignedOrder = ControlRights("IDS_MDL_ORDERS", "IDS_TSK_ASSIGNED");
    let orderByMe = ControlRights("IDS_MDL_ORDERS", "IDS_TSK_ORDERSHAREDBYME");
    let orderToMe = ControlRights("IDS_MDL_ORDERS", "IDS_TSK_ORDERSHAREDTOME");
    let limsorder = ControlRights("IDS_MDL_ORDERS", "IDS_TSK_LIMSTASKORDER");
    let elnorder = ControlRights("IDS_MDL_ORDERS", "IDS_TSK_ELNTASKORDER");
    let researchorder = ControlRights(
      "IDS_MDL_ORDERS",
      "IDS_TSK_RESEARCHACTIVITY"
    );
    let excelorder = ControlRights("IDS_MDL_ORDERS", "IDS_TSK_MANAGEEXCEL");
    let Evaluation = ControlRights("IDS_MDL_ORDERS", "IDS_TSK_SHEETEVALUATION");
    let Foldercreate = ControlRights(
      "IDS_MDL_ORDERS",
      "IDS_TSK_FOLDERCREATION"
    );
    let sheetorderrights = ControlRights("IDS_MDL_ORDERS", "IDS_SCN_SHEETORDERS");
    let moveorder = ControlRights("IDS_MDL_ORDERS", "IDS_TSK_MOVEORDERS");
    let sheetorderexport = ControlRights("IDS_MDL_ORDERS", "IDS_TSK_SHEETORDEREXPORT");
    let fileupload = ControlRights("IDS_MDL_ORDERS", "IDS_TSK_UPLOADSHEETORDER")

    // if (intialrights.Assigntestfor !== undefined && intialrights.Assigntestfor === 0) {
    //   let currentorder = 0;

    //   if (limsorder.UserGroupRights.rights === true && isMultitenant !== 1) {
    //     currentorder = 0;
    //   } else if (elnorder.UserGroupRights.rights === true) {
    //     currentorder = 2;
    //   } else if (researchorder.UserGroupRights.rights === true) {
    //     currentorder = 3;
    //   } else if (excelorder.UserGroupRights.rights === true) {
    //     currentorder = 4;
    //   } else if (Evaluation.UserGroupRights.rights === true) {
    //     currentorder = 5;
    //   }

    //   setIntialrights((intialrights: any) => ({
    //     ...intialrights,
    //     Assigntestfor: currentorder,
    //   }));
    // }
    // if (intialrights.stopDisabled === true) {
    //   let currentorder = 0;

    //   if (limsorder.UserGroupRights.rights === true && isMultitenant !== 1) {
    //     currentorder = 0;
    //   } else if (elnorder.UserGroupRights.rights === true) {
    //     currentorder = 2;
    //   } else if (researchorder.UserGroupRights.rights === true) {
    //     currentorder = 3;
    //   } else if (excelorder.UserGroupRights.rights === true) {
    //     currentorder = 4;
    //   } else if (Evaluation.UserGroupRights.rights === true) {
    //     currentorder = 5;
    //   }
    //   if (
    //     intialrights.OrderTestcode !== null &&
    //     intialrights.OrderTestcode !== undefined &&
    //     intialrights.OrderTestcode.filetype !== undefined
    //   ) {
    //     setIntialrights((intialrights: any) => ({
    //       ...intialrights,
    //       Assigntestfor: intialrights.OrderTestcode.filetype,
    //     }));
    //   } else {
    //     setIntialrights((intialrights: any) => ({
    //       ...intialrights,
    //       Assigntestfor: currentorder,
    //     }));
    //   }
    // }

    let UserRights = ({
      orderByMeVisible: orderByMe.UserGroupRights.rights,
      orderToMeVisible: orderToMe.UserGroupRights.rights,
      LIMSorderVisible: limsorder.UserGroupRights.rights,
      LIMSordersyncvisb: limsorder.UserGroupRights.save,
      ELNorderVisible: elnorder.UserGroupRights.rights,
      ExcelorderVisible: excelorder.UserGroupRights.rights,
      DocumentorderVisible: Evaluation.UserGroupRights.rights,
      Researchorder: researchorder.UserGroupRights.rights,
      Evalsavevisb: Evaluation.UserGroupRights.save,
      Excelsavevisb: excelorder.UserGroupRights.save,
      Researchvisible: researchorder.UserGroupRights.save,
      Folderrights: Foldercreate.UserGroupRights.rights,
      Folderrightscreate: Foldercreate.UserGroupRights.save,
      Folderrightseditvisb: Foldercreate.UserGroupRights.edit,
      Folderrightdeletevisb: Foldercreate.UserGroupRights.del,
      ELNorder: elnorder.UserGroupRights.save,
      sheetordervisible: sheetorderrights.UserGroupRights.rights,
      moveorderrights: moveorder.UserGroupRights.rights,
      sheetexportvisible: sheetorderexport.UserGroupRights.rights,
      pendingrights: pend.UserGroupRights.rights,
      completedrights: complete.UserGroupRights.rights,
      uploadrights: fileupload.UserGroupRights.rights

    });
    return UserRights;
  };



  const [userrights] = React.useState<any>(updateuserrights(props.rights));

  const [stateData, setStateData] = React.useState<DataModel[]>(
    formatData([], intl)
  );

  const [panes, setPanes] = React.useState<PanesModel[]>(splitterPanes);
  // const [clonedStateData, setclonedStateData] = React.useState<DataModel[]>(
  //   formatData([], intl)
  // );
  const [pullcontents, setPullcontents] = React.useState<boolean>(false);
  const [IsLoader, setIsLoader] = React.useState<boolean>(false);
  const [selectedGridItem, setSelectedGridItem] = React.useState<DataModel>({});
  const pendinglimsorderfolder = {
    directorycode: -7,
    parentdircode: -5,
    dateCreated: convertDateFormat(new Date(currentDate !== undefined && currentDate !== null ? currentDate : new Date()), intl),
    dateModified: convertDateFormat(new Date(), intl),
    size: 124,
    path: `${"Sheet"}/${"Pending Orders"}/${"Lims Order"}`,
    directoryname: props.t("Orders.Sheetorders.IDS_LBL_LIMSORDER"),
    icon: convertExtensionToIcon("Lims Order", null),
    filefor: "LPO",
    items: []
  };
  const pendingelnorderfolder = {
    directorycode: -8,
    parentdircode: -5,
    dateCreated: convertDateFormat(new Date(currentDate !== undefined && currentDate !== null ? currentDate : new Date()), intl),
    dateModified: convertDateFormat(new Date(), intl),
    size: 124,
    path: `${"Sheet"}/${"Pending Orders"}/${"ELN Order"}`,
    directoryname: props.t("Orders.Sheetorders.IDS_LBL_ELNTASKORDER"),
    icon: convertExtensionToIcon("ELN Order", null),
    filefor: "EPO",
    items: []
  };
  const completedlimsorderfolder = {
    directorycode: -12,
    parentdircode: -6,
    dateCreated: convertDateFormat(new Date(currentDate !== undefined && currentDate !== null ? currentDate : new Date()), intl),
    dateModified: convertDateFormat(new Date(), intl),
    size: 124,
    path: `${"Sheet"}/${"Completed Orders"}/${"Lims Order"}`,
    directoryname: props.t("Orders.Sheetorders.IDS_LBL_LIMSORDER"),
    icon: convertExtensionToIcon("Lims Order", null),
    filefor: "LCO",
    items: []
  };
  const completedelnorderfolder = {
    directorycode: -13,
    parentdircode: -6,
    dateCreated: convertDateFormat(new Date(currentDate !== undefined && currentDate !== null ? currentDate : new Date()), intl),
    dateModified: convertDateFormat(new Date(), intl),
    size: 124,
    path: `${"Sheet"}/${"Completed Orders"}/${"ELN Order"}`,
    directoryname: props.t("Orders.Sheetorders.IDS_LBL_ELNTASKORDER"),
    icon: convertExtensionToIcon("ELN Order", null),
    filefor: "ECO",
    items: []
  };
  const [selectedTreeItem, setSelectedTreeItem] = React.useState<DataModel | null>(isMultitenant === 0 ? pendinglimsorderfolder : pendingelnorderfolder);

  const [selectedTreeItemDuplicate, setSelectedTreeItemDuplicate] =
    React.useState<DataModel | null>(isMultitenant === 0 ? pendinglimsorderfolder : pendingelnorderfolder);

  const [gridSelection, setGridSelection] = React.useState<{
    [id: string]: boolean | number[];
  }>({});

  const [detailsData, setDetailsData] = React.useState<null | number | Object>(
    null
  );
  const [files, setFiles] = React.useState<UploadFileInfo[]>([]);
  const [contentView, setContentView] = React.useState<string | null>(
    localStorage.getItem("elnsheetorderview") !== undefined &&
      localStorage.getItem("elnsheetorderview") !== null
      ? localStorage.getItem("elnsheetorderview")
      : "list"
  );
  const [contextMenuView, setContextMenuView] = React.useState<boolean>(false);
  const [menuOffSet, setMenuOffSet] = React.useState<Offset>({
    left: 0,
    top: 0,
  });
  const [editDialogView, setEditDialogView] = React.useState<boolean>(false);
  const [deleteDialogView, setDeleteDialogView] =
    React.useState<boolean>(false);
  const [moveDialogView, setMoveDialogView] = React.useState<boolean>(false);
  const [isMenuContext, setIsMenuContext] = React.useState<boolean>(false);
  const [adddialogVisible, setAddDialogVisible] =
    React.useState<boolean>(false);

  const [selectedOrders, setSelectedOrders] = React.useState<any[]>([]);
  const [addedfiles, setAddedfiles] = React.useState<any[]>([]);
  const [isMultiSelectOrder, setIsMultiSelectOrder] = React.useState<boolean>(false);

  const [selectedFiles, setSelectedFiles] = React.useState<any[]>([]);
  const [isMultiSelectFile, setIsMultiSelectFile] = React.useState<boolean>(false);

  const [selectedFolders, setSelectedFolders] = React.useState<any[]>([]);
  const [isMultiSelectFolders, setIsMultiSelectFolders] = React.useState<boolean>(false);

  const [stateUserData] = React.useState<DataModel>();
  const [fileviewfor] = React.useState<number>(1);
  let itemperpage = 10;
  if (
    localStorage.getItem("Elnitemperpage") !== undefined &&
    localStorage.getItem("Elnitemperpage") !== null
  ) {
    let sheetcolumnsval: any = localStorage.getItem("Elnitemperpage");
    itemperpage = JSON.parse(sheetcolumnsval);
  }
  const [takeskip, setTakeskip] = React.useState<any>({ skip: 0, take: itemperpage, group: [], collapsedIds: [] });

  const sheetdaydiff = localStorage.getItem("sheetdaydiff");
  const [sheetUnmapped, setSheetUnmapped] = React.useState<boolean>(false);
  const [isorderopen, setIsorderopen] = React.useState<any>(store.getState().sheetopne !== null && props.notificationorder === "" ? store.getState().sheetopne : false);
  // const [openedorder, setOpenedorder] = React.useState<any>(store.getState().Sheetview.OrderTestcode !== null && store.getState().Sheetview.OrderTestcode !== "" ? store.getState().Sheetview.OrderTestcode : undefined);
  const [openedorder, setOpenedorder] = React.useState<any>(undefined);


  let daydiff = 7;
  if (typeof sheetdaydiff === "string" && sheetdaydiff !== "NaN") {
    daydiff = parseInt(JSON.parse(sheetdaydiff));
  }

  const [changedtodate, setchangedtodate] = React.useState<boolean>(false);
  const sheetfiltertype = localStorage.getItem("sheetfiltertype");
  const [SearchContentText, setSearchContentText] = useState<any>("");
  const [cancellorder, setCancellorder] = React.useState<boolean>(false);
  const [cancellorderitem, setCancellorderitem] = React.useState<any>("");
  const [searchCriteriaType, setsearchCriteriaType] = useState<any>(0);
  const [orderstatusType, setorderstatusType] = React.useState(0);
  const [orderflag, setorderflag] = React.useState(-1);
  const [flitertest, setFlitertest] = useState<any>({ testcode: -1, testname: "All" });
  const [fliterproject, setFliterproject] = useState<any>({ projectcode: -1, projectname: "All" });
  let filtertype = 2;
  if (typeof sheetfiltertype === "string") {
    filtertype = parseInt(JSON.parse(sheetfiltertype));
  }
  const userDefinedDate = sessionStorage.userDefinedDate === "dd-MM-yyyy HH:mm:ss" || sessionStorage.userDefinedDate === "dd-MM-yy HH:mm:ss" || sessionStorage.userDefinedDate === "UTC Format" ? "YY/MM/DD HH:mm:ss" : sessionStorage.userDefinedDate;
  const [filterType, setfilterType] = React.useState(filtertype);
  let diffdate;
  diffdate = new Date().setDate(new Date().getDate() - daydiff);

  const [fromRange, setfromRange] = React.useState(
    filtertype === 1
      ? setDateAsuserDefined(
        new Date(
          new Date().setDate(new Date().setHours(0, 0, 0, 0)),
          sessionStorage.userDefinedDate
        )
      )
      : setDateAsuserDefined(
        diffdate,
        // sessionStorage.userDefinedDate
        userDefinedDate
      )
  );
  const [toRange, settoRange] = React.useState(
    setDateAsuserDefined(new Date(), userDefinedDate)
  );

  const [isunshareopen, setIsunshareopen] = React.useState<boolean>(false);
  const [selectedUnsharedItem, setSelectedUnsharedItem] = React.useState<DataModel>({});
  let initialorders: any = null;

  const [servicecall, setServicecall] = React.useState<any>({
    ServiceToUrl: "",
    ServiceHeaderobject: {},
    ServiceMethodType: "POST",
    ServcieonSuccess: undefined,
    ServcieonFailure: undefined,
    ServiceTenant: undefined,
    Servicecontenttype: undefined,
  });
  const [Auditservicecall, setAuditservicecall] = React.useState<any>({
    AuditUrl: "",
    AuditObject: {},
    AuditPassfunction: undefined,
    AuditFailfunction: undefined,
    AuditCancelfunction: undefined,
    Auditfilefunction: false,
  });



  const [isFiltered] = React.useState<boolean>(false);

  const [fileType, setFileType] = React.useState(-1);

  const [sheetData, setSheetData] = React.useState<DataModel[]>(
    formatData([], intl)
  );

  const [sheetSelected, setSheetSelected] = React.useState<any>(
    formatData([], intl)
  );

  const projects = Getuserprojectfromsession();
  const workflow = Getuserworkflowfromsession();

  let sheetcolumns = undefined;
  if (
    localStorage.getItem("elnsheetcolumns") !== undefined &&
    localStorage.getItem("elnsheetcolumns") !== null
  ) {
    let sheetcolumnsval: any = localStorage.getItem("elnsheetcolumns");
    sheetcolumns = JSON.parse(sheetcolumnsval);
  }

  let orderdataforfolder: any = [];
  let sateorderdummy: any = [];

  // const setdates = () => {
  //   if (!isFiltered) {
  //     if (filterType === 1) {
  //       setfromRange(() => {
  //         let fromdate = new Date();
  //         fromdate.setHours(0, 0, 0, 0);
  //         return setDateAsuserDefined(fromdate, sessionStorage.userDefinedDate)
  //       });
  //     }
  //     else {
  //       setfromRange(() => {
  //         const sheetdaydiff = localStorage.getItem("sheetdaydiff")

  //         let daydiff = 7
  //         if (typeof sheetdaydiff === 'string') {
  //           daydiff = parseInt(JSON.parse(sheetdaydiff))
  //         }
  //         return setDateAsuserDefined(new Date(new Date().setDate(
  //           new Date().getDate() - daydiff)), sessionStorage.userDefinedDate)
  //       });
  //     }
  //     settoRange(() => { return setDateAsuserDefined(new Date(), sessionStorage.userDefinedDate) });
  //   }
  // }

  const openunsharelist = (item: any) => {
    setIsunshareopen(true);
    setSelectedUnsharedItem(item);
  };

  const closeunsharelist = (item: any) => {
    setIsunshareopen(false);
    setSelectedUnsharedItem({});
  };

  const onorderunshare = (item: any) => {
    let selectunshare: any = selectedUnsharedItem;
    if (selectunshare !== undefined && Object.keys(selectunshare).length > 0) {
      let unsharedindex = selectunshare.sharebyme.findIndex(
        (obj: any) => obj.sharedbycode === item.sharedbycode
      );
      if (unsharedindex > -1) {
        let canremove = false;
        selectunshare.sharebyme.splice(unsharedindex, 1);
        if (selectunshare.sharebyme.length <= 0) {
          delete selectunshare.sharebyme;
          if (selectunshare.assignedto === undefined && selectunshare.sharetome === undefined) {
            canremove = true;
          }
        }
        let newData = stateData;
        let selectedtree = selectedTreeItem;
        if (selectedTreeItem?.directorycode !== undefined) {
          if (!canremove) {
            newData = addorderitemstoFolderwithfilefor(
              stateData,
              selectedTreeItem?.directorycode,
              intl,
              [selectunshare],
              "UR",
              screen,
            );
            setStateData(newData);
            setSelectedGridItem(selectunshare);
            setSelectedUnsharedItem(selectunshare);
          } else if (canremove) {
            newData = deleteorderitemstoFolderwithfilefor(
              stateData,
              selectedTreeItem?.directorycode,
              intl,
              [selectunshare],
              "OSBM",
              screen
            );
            setStateData(newData);
            setSelectedGridItem({});
            setSelectedUnsharedItem({});
            setDetailsData(null);
          }
          selectedtree = getDirectoryOncodeandfilefor(
            newData,
            selectedTreeItem?.directorycode,
            "OSBM"
          );
          setSelectedTreeItem(selectedtree);
          setSelectedTreeItemDuplicate(selectedtree);
        }

        setPullcontents(true);
      }
    }
  };
  const oncancelledorder = (items: any) => {
    setCancellorderitem(items.order !== undefined ? items.order : items);
    setCancellorder(true);
  }

  const MyCustomCellAction = (props: any) => (
    <Suspense fallback={<div></div>}>
      <PendingViewCell
        {...props}
        keyfield={"batchcode"}
        // onclick={this.onbuttonchecked}
        passbackorder={onOrderShared}
        openunsharelist={openunsharelist}
        oncancelledorderevent={oncancelledorder}
        filedownloadurl={ELNurl + "/Instrument/downloadsheetfileforfolder/" + isMultitenant + "/" + sessionStorage.getItem("tenantid")}
        ondeletefile={ondeletefile}

      /></Suspense>
  );

  const [columns, setColumns] = React.useState<any[]>([
    {
      field: "batchid",
      title: props.t("Orders.Sheetorders.IDS_LBL_ORDERID"),
      show: true,
      default: true,
      cell: NameCell,
      // headerCell: "namecell"
      isprimary: true,
      showtoall: true
    },
    {
      field: "dateCreated",
      title: props.t("Orders.Sheetorders.IDS_LBL_DATECREATE"),
      show: true,
      classNames: "date-block",
      default: true,
      cell: DateCreatedCell,
      showtoall: true
    },
    {
      field: "dateCompleted",
      title: props.t("Orders.Sheetorders.IDS_LBL_DATECOMPLETED"),
      show: false,
      classNames: "date-block",
      default: true,
      cell: DateCreatedCell,
      showtoall: true
    },
    {
      field: "testname",
      title: props.t("Orders.Sheetorders.IDS_LBL_TASK"),
      show: true,
      default: true,
    },
    {
      field: "filename",
      title: props.t("Orders.Sheetorders.IDS_LBL_SHEET"),
      show:
        sheetcolumns !== undefined &&
          sheetcolumns.find((obj: string) => obj === "filename")
          ? true
          : false,
      default: false,
    },
    {
      field: "projectname",
      title: props.t("Orders.Sheetorders.IDS_LBL_PROJECT"),
      show:
        sheetcolumns !== undefined &&
          sheetcolumns.find((obj: string) => obj === "projectname")
          ? true
          : false,
      default: false,
    },
    {
      field: "materialname",
      title: props.t("Logbook.IDS_LBL_MATERIAL"),
      show:
        sheetcolumns !== undefined &&
          sheetcolumns.find((obj: string) => obj === "materialname")
          ? true
          : false,
      default: false,
    },
    // {
    //   field: "repositoryitemname",
    //   title: props.t("Orders.Sheetorders.IDS_LBL_INVENTORYNAME"),
    //   show:
    //     sheetcolumns !== undefined &&
    //       sheetcolumns.find((obj: string) => obj === "repositoryitemname")
    //       ? true
    //       : false,
    //   default: false,
    // },
    {
      field: "keyword",
      title: props.t("Orders.Sheetorders.IDS_LBL_KEYWORD"),
      show:
        sheetcolumns !== undefined &&
          sheetcolumns.find((obj: string) => obj === "keyword")
          ? true
          : false,
      default: false,
    },
    {
      field: props.t("Orders.Sheetorders.IDS_LBL_PROCESSORDER"),
      title: props.t("Orders.Sheetorders.IDS_LBL_ACTION"),
      width: "170px",
      show: true,
      default: true,
      cell: MyCustomCellAction,
      headerCell: "actionheader",
      ismapulationcol: true
    },
  ]);

  let split: SplitBtnItems[] = columns.filter((col) => col.show && !col.ismapulationcol);
  //  columns.map((col) => { return col.show && !col.ismapulationcol ? { text: col.title, value: col.field } : null }).filter(notUndefined => notUndefined !== undefined) as SplitBtnItems[];
  if (split.length > 0) {
    split = split.map((col: any) => {
      return { text: col.title, value: col.field }
    })
  }
  const [splitBtnItems, setsplitBtnItems] = React.useState<SplitBtnItems[] | undefined>(split);

  let ELNurl = (document.getElementById("ELNname") as HTMLInputElement).value;
  // let SDMSurl = (document.getElementById("SDMSname") as HTMLInputElement).value;

  // let deleteitem: DataModel | null = {};
  let deleteitem: any = [];

  const screen = "Sheet";
  const ongetfoldersuccess = (response: any, intl: any) => {

    let projects = response.projects;
    if (projects.length > 0) {
      projects = projects.map((project: any) => {
        if (typeof project.projectname === 'string' && project.projectname.includes("/")) {
          project.projectname = project.projectname.replaceAll("/", " | ");
        }
        return project;
      });
    }

    let tests = response.tests;
    if (tests.length > 0) {
      tests = tests.map((test: any) => {
        if (Array.isArray(test) && test.length === 3 && typeof test[2] === 'string' && test[2].includes("/")) {
          test[2] = test[2].replaceAll("/", " | ");
        }
        return test;
      });
    }

    let directory = response.directory;
    let Material_List = response.Material;
    let Materialtest = response.Materialtest;

    let orders: DataModel[] = [];
    // let useritems: DataModel[] = [];

    // let pendingordersitems: DataModel[] = [];
    if (userrights.LIMSorderVisible !== undefined && userrights.LIMSorderVisible === true && isMultitenant === 0) {
      orders.push(pendinglimsorderfolder);
    }
    if (userrights.ELNorderVisible === true) {
      orders.push(pendingelnorderfolder);
    }
    if (userrights.Researchorder === true) {
      orders.push({
        directorycode: -9,
        parentdircode: -5,
        dateCreated: convertDateFormat(new Date(currentDate !== undefined && currentDate !== null ? currentDate : new Date()), intl),
        dateModified: convertDateFormat(new Date(), intl),
        size: 124,
        path: `${"Sheet"}/${"Pending Orders"}/${"Activity Order"}`,
        directoryname: props.t("Orders.Sheetorders.IDS_LBL_REASEARCHACTIVITY"),
        icon: convertExtensionToIcon("Activity Order", null),
        filefor: "RPO",
        items: []
      });
    }
    if (userrights.ExcelorderVisible === true) {
      orders.push({
        directorycode: -10,
        parentdircode: -5,
        dateCreated: convertDateFormat(new Date(currentDate !== undefined && currentDate !== null ? currentDate : new Date()), intl),
        dateModified: convertDateFormat(new Date(), intl),
        size: 124,
        path: `${"Sheet"}/${"Pending Orders"}/${"Manage Excel"}`,
        directoryname: props.t("Orders.Sheetorders.IDS_LBL_EXCEL"),
        icon: convertExtensionToIcon("Manage Excel", null),
        filefor: "MPO",
        items: []
      });
    }
    if (userrights.DocumentorderVisible === true) {
      orders.push({
        directorycode: -11,
        parentdircode: -5,
        dateCreated: convertDateFormat(new Date(currentDate !== undefined && currentDate !== null ? currentDate : new Date()), intl),
        dateModified: convertDateFormat(new Date(), intl),
        size: 124,
        path: `${"Sheet"}/${"Pending Orders"}/${"Sheet Evaluation"}`,
        directoryname: props.t("Orders.Sheetorders.IDS_LBL_SHEETEVAL"),
        icon: convertExtensionToIcon("Sheet Evaluation", null),
        filefor: "SPO",
        items: []
      });
    }
    if (userrights.ELNorderVisible === true || userrights.Researchorder === true || userrights.ExcelorderVisible === true
      || userrights.DocumentorderVisible === true) {
      orders.push({
        directorycode: -5,
        parentdircode: -1,
        dateCreated: convertDateFormat(new Date(currentDate !== undefined && currentDate !== null ? currentDate : new Date()), intl),
        dateModified: convertDateFormat(new Date(), intl),
        size: 124,
        path: `${"Sheet"}/${"Pending Orders"}`,
        directoryname: props.t("Orders.Sheetorders.IDS_LBL_PENDINGWORK"),
        icon: convertExtensionToIcon("Pending Orders", null),
        filefor: "PO",
        items: [], //pendingordersitems,
        expanded: true
      });
    }
    // let completedordersitems: DataModel[] = [];
    if (userrights.LIMSorderVisible !== undefined && userrights.LIMSorderVisible === true && isMultitenant === 0) {
      orders.push(completedlimsorderfolder);
    }
    if (userrights.ELNorderVisible === true) {
      orders.push(completedelnorderfolder);
    }
    if (userrights.Researchorder === true) {
      orders.push({
        directorycode: -14,
        parentdircode: -6,
        dateCreated: convertDateFormat(new Date(currentDate !== undefined && currentDate !== null ? currentDate : new Date()), intl),
        dateModified: convertDateFormat(new Date(), intl),
        size: 124,
        path: `${"Sheet"}/${"Completed Orders"}/${"Activity Order"}`,
        directoryname: props.t("Orders.Sheetorders.IDS_LBL_REASEARCHACTIVITY"),
        icon: convertExtensionToIcon("Activity Order", null),
        filefor: "RCO",
        items: []
      });
    }
    if (userrights.ExcelorderVisible === true) {
      orders.push({
        directorycode: -15,
        parentdircode: -6,
        dateCreated: convertDateFormat(new Date(currentDate !== undefined && currentDate !== null ? currentDate : new Date()), intl),
        dateModified: convertDateFormat(new Date(), intl),
        size: 124,
        path: `${"Sheet"}/${"Completed Orders"}/${"Manage Excel"}`,
        directoryname: props.t("Orders.Sheetorders.IDS_LBL_EXCEL"),
        icon: convertExtensionToIcon("Manage Excel", null),
        filefor: "MCO",
        items: []
      });
    }
    if (userrights.DocumentorderVisible === true) {
      orders.push({
        directorycode: -16,
        parentdircode: -6,
        dateCreated: convertDateFormat(new Date(currentDate !== undefined && currentDate !== null ? currentDate : new Date()), intl),
        dateModified: convertDateFormat(new Date(), intl),
        size: 124,
        path: `${"Sheet"}/${"Completed Orders"}/${"Sheet Evaluation"}`,
        directoryname: props.t("Orders.Sheetorders.IDS_LBL_SHEETEVAL"),
        icon: convertExtensionToIcon("Sheet Evaluation", null),
        filefor: "SCO",
        items: []
      });
    }
    if (userrights.ELNorderVisible === true || userrights.Researchorder === true || userrights.ExcelorderVisible === true
      || userrights.DocumentorderVisible === true) {
      orders.push({
        directorycode: -6,
        parentdircode: -1,
        dateCreated: convertDateFormat(new Date(currentDate !== undefined && currentDate !== null ? currentDate : new Date()), intl),
        dateModified: convertDateFormat(new Date(), intl),
        size: 124,
        path: `${"Sheet"}/${"Completed Orders"}`,
        directoryname: props.t("Orders.Sheetorders.IDS_LBL_COMPLETEDWORK"),
        icon: convertExtensionToIcon("Completed Orders", null),
        filefor: "CO",
        items: [] //ompletedordersitems,
      });
    }
    // let userName = "Sheet/My Site";
    // users.forEach(
    //   (res: {
    //     usercode: any;
    //     createddate: string | number | Date;
    //     username: any | null | undefined;
    //     userfullname: any | null | undefined;
    //   }) => {
    //     let username =
    //       res.username !== undefined &&
    //         res.username.split(".").length > 1 &&
    //         res.userfullname !== undefined
    //         ? res.userfullname.replace(".", " ")
    //         : res.username;
    //     orders.push({
    //       directorycode: res.usercode,
    //       parentdircode: -2,
    //       dateCreated: convertDateFormat(
    //         convertUTCDateToLocalDate(new Date(res.createddate)) || null,
    //         intl
    //       ),
    //       // dateModified : convertDateFormat(new Date(), intl),
    //       size: 124,
    //       path: `${userName}/${username}`,
    //       directoryname: username,
    //       icon: convertExtensionToIcon(username, null),
    //       filefor: "UR",
    //     });
    //   }
    // );

    // orders.push({
    //   directorycode: -2,
    //   parentdircode: -1,
    //    dateCreated: convertDateFormat(new Date(currentDate !== undefined && currentDate !== null ? currentDate : new Date()), intl),
    //   dateModified: convertDateFormat(new Date(), intl),
    //   size: 124,
    //   path: userName,
    //   directoryname: "My Site",
    //   icon: convertExtensionToIcon("My Site", null),
    //   filefor: "MUR",
    //   items: [] //useritems,
    // });

    orders.push(
      {
        directorycode: -17,
        parentdircode: -1,
        dateCreated: convertDateFormat(new Date(currentDate !== undefined && currentDate !== null ? currentDate : new Date()), intl),
        dateModified: convertDateFormat(new Date(), intl),
        size: 124,
        path: `${"Sheet"}/${"Assigned Orders"}`,
        directoryname: props.t("Orders.Sheetorders.IDS_LBL_ASSIGNED"),
        icon: convertExtensionToIcon("Assigned Orders", null),
        filefor: "ASO",
        items: [],
      });

    orders.push(
      {
        directorycode: -18,
        parentdircode: -1,
        dateCreated: convertDateFormat(new Date(currentDate !== undefined && currentDate !== null ? currentDate : new Date()), intl),
        dateModified: convertDateFormat(new Date(), intl),
        size: 124,
        path: `${"Sheet"}/${"My Orders"}`,
        directoryname: props.t("Orders.Sheetorders.IDS_LBL_MYORDERS"),
        icon: convertExtensionToIcon("My Orders", null),
        filefor: "MYO",
        items: [],
      });
    if (userrights.orderByMeVisible === true || userrights.orderToMeVisible === true) {
      orders.push(
        {
          directorycode: -19,
          parentdircode: -1,
          dateCreated: convertDateFormat(new Date(currentDate !== undefined && currentDate !== null ? currentDate : new Date()), intl),
          dateModified: convertDateFormat(new Date(), intl),
          size: 124,
          path: `${"Sheet"}/${"Shared Orders"}`,
          directoryname: props.t("Orders.Sheetorders.IDS_LBL_SHAREDORDERS"),
          icon: convertExtensionToIcon("Shared Orders", null),
          filefor: "SHO",
          items: [],
        });
    }
    if (userrights.orderByMeVisible === true) {
      orders.push(
        {
          directorycode: -20,
          parentdircode: -19,
          dateCreated: convertDateFormat(new Date(currentDate !== undefined && currentDate !== null ? currentDate : new Date()), intl),
          dateModified: convertDateFormat(new Date(), intl),
          size: 124,
          path: `${"Sheet"}/${"Orders Shared By Me"}`,
          directoryname: props.t("Orders.Sheetorders.IDS_LBL_ORDERSHAREDBYME"),
          icon: convertExtensionToIcon("Orders Shared By Me", null),
          filefor: "OSBM",
          items: [],
        });
    }
    if (userrights.orderToMeVisible === true) {
      orders.push(
        {
          directorycode: -21,
          parentdircode: -19,
          dateCreated: convertDateFormat(new Date(currentDate !== undefined && currentDate !== null ? currentDate : new Date()), intl),
          dateModified: convertDateFormat(new Date(), intl),
          size: 124,
          path: `${"Sheet"}/${"Orders Shared To Me"}`,
          directoryname: props.t("Orders.Sheetorders.IDS_LBL_ORDERSHAREDTOME"),
          icon: convertExtensionToIcon("Orders Shared To Me", null),
          filefor: "OSTM",
          items: [],
        });
    }
    // let projectitems: DataModel[] = [];
    if (projects !== undefined) {
      projects.map((obj: any) => {
        let projectname = obj.projectname.replaceAll(".", " ");
        let projectpath = `${"Sheet"}/${"Project"}/${projectname}`;
        let testitems = [];
        let projecttest = tests.filter(
          (testary: any) => testary[1] === obj.projectcode
        );
        if (projecttest !== undefined) {
          testitems = projecttest.map((testobj: any) => {
            return {
              directorycode: parseFloat(obj.projectcode + "." + testobj[0]),
              parentdircode: parseInt(obj.projectcode) + 3,
              dateCreated: convertDateFormat(new Date(currentDate !== undefined && currentDate !== null ? currentDate : new Date()), intl),
              dateModified: convertDateFormat(new Date(), intl),
              size: 124,
              path: `${projectpath}/${testobj[2]}`,
              directoryname: testobj[2],
              icon: convertExtensionToIcon(testobj[2], null),
              filefor: "TMP",
              items: [],
            };
          });
        }
        orders = orders.concat(testitems)
        // orders = [...orders, testitems];
        orders.push({
          directorycode: parseInt(obj.projectcode) + 3,
          parentdircode: -3,
          dateCreated: convertDateFormat(new Date(currentDate !== undefined && currentDate !== null ? currentDate : new Date()), intl),
          dateModified: convertDateFormat(new Date(), intl),
          size: 124,
          path: projectpath,
          directoryname: projectname,
          icon: convertExtensionToIcon(projectname, null),
          filefor: "PR",
          items: []//testitems,
        }); return obj;
      });
    }

    orders.push({
      directorycode: -3,
      parentdircode: -1,
      dateCreated: convertDateFormat(new Date(currentDate !== undefined && currentDate !== null ? currentDate : new Date()), intl),
      dateModified: convertDateFormat(new Date(), intl),
      size: 124,
      path: "Sheet/Project",
      directoryname: props.t("Orders.Sheetorders.IDS_LBL_PROJECT"),
      icon: convertExtensionToIcon("Project", null),
      filefor: "PR",
      items: []//projectitems,
    });


    let Material_Items: DataModel[] = [];
    if (Material_List !== undefined) {
      Material_List.map((obj: any) => {
        let Material_Path = `${"Sheet"}/${"Material"}/${obj.smaterialname}`;

        let testitems = [];
        let Material_Temp = Materialtest.filter(
          (testary: any) => testary[1] === obj.nmaterialcode
        );
        if (Material_Temp !== undefined) {
          testitems = Material_Temp.map((testobj: any) => {
            return {
              directorycode: parseFloat(obj.nmaterialcode + "." + testobj[0]),
              parentdircode: parseFloat(`${obj.nmaterialcode}.001`),// parseFloat(obj.nmaterialcode + "." + 521),
              dateCreated: convertDateFormat(new Date(currentDate !== undefined && currentDate !== null ? currentDate : new Date()), intl),
              dateModified: convertDateFormat(new Date(), intl),
              size: 124,
              path: `${Material_Path}/${testobj[2]}`,
              directoryname: testobj[2],
              icon: convertExtensionToIcon(testobj[2], null),
              filefor: "TMM",
              items: [],
            };
          });
        }
        orders = orders.concat(testitems)
        // orders = [...orders, testitems];
        orders.push({
          directorycode: parseFloat(`${obj.nmaterialcode}.001`),// parseFloat(obj.nmaterialcode + "." + 521),
          parentdircode: -22,
          dateCreated: convertDateFormat(new Date(currentDate !== undefined && currentDate !== null ? currentDate : new Date()), intl),
          dateModified: convertDateFormat(new Date(), intl),
          size: 124,
          path: Material_Path,
          directoryname: obj.smaterialname,
          icon: convertExtensionToIcon(obj.smaterialname, null),
          filefor: "SMM",
          items: []//testitems,
        }); return obj;
      });
    }
    orders.push({
      directorycode: -22,
      parentdircode: -1,
      dateCreated: convertDateFormat(new Date(currentDate !== undefined && currentDate !== null ? currentDate : new Date()), intl),
      dateModified: convertDateFormat(new Date(), intl),
      size: 124,
      path: `${"Sheet"}/${"Material"}`,
      directoryname: props.t("Dashboard.Sheetorders.IDS_LBL_MATERIAL"),
      icon: convertExtensionToIcon("Material", null),
      filefor: "SMM",
      items: Material_Items,
    });
    // orders.push({
    //   directorycode: -4,
    //   parentdircode: -1,
    //   dateCreated: convertDateFormat(new Date(currentDate !== undefined && currentDate !== null ? currentDate : new Date()), intl),
    //   dateModified: convertDateFormat(new Date(), intl),
    //   size: 124,
    //   path: `${"Sheet"}/${"Sample"}`,
    //   directoryname: props.t("Orders.Sheetorders.IDS_LBL_SAMPLE"),
    //   icon: convertExtensionToIcon("Sample", null),
    //   filefor: "SM",
    //   items: sampleitems,
    // });

    directory = directory.map((items: any) => {
      if (items.floatvalues !== undefined) {
        items.parentdircode = parseFloat(items.parentdircode + "." + items.floatvalues);
      }
      return items;
    })

    if (directory !== null && directory !== undefined && directory.length > 0) {
      // let directories: DataModel[] = [];
      directory = directory.filter((items: any) => items.path !== undefined);
      directory.map((dic: any, index: any) => {
        orders.push({
          directorycode: dic.directorycode,
          parentdircode: dic.parentdircode,
          dateCreated: convertDateFormat(new Date(dic.dateCreated), intl),
          dateModified: convertDateFormat(new Date(dic.dateModified), intl),
          createdDate: dic.dateCreated,
          // dateCreated: dic.dateCreated,
          // dateModified: dic.dateModified,
          size: 124,
          path: dic.path,
          directoryname: dic.directoryname,
          icon: convertExtensionToIcon(dic.directoryname, null),
          filefor: "DR",
          viewoption: dic.viewoption,
          teamname: dic.teamname !== undefined ? dic.teamname : undefined
        }); return dic;
      });

    }
    let groupeddata = groupBy(orders, "parentdircode");

    let data = groupeddata["-1"];
    let basepath = "Sheet";
    if (data !== undefined) {
      orders = formatData(
        formatserverData(data, groupeddata, intl, screen, basepath),
        intl
      );
      // orders = orders.concat(folderdata);
    }
    // setclonedStateData( JSON.parse(JSON.stringify(orders)));
    // orders = getFilteredByDate(orders, fromRange, toRange)

    if (selectedTreeItem !== undefined && selectedTreeItem?.directorycode !== undefined &&
      selectedTreeItem?.filefor !== undefined) {
      orders = expandparentfolder(orders, selectedTreeItem?.directorycode, intl, selectedTreeItem?.filefor, screen).data;
    }
    setStateData(orders);
    initialorders = orders;
    if (Foldercontext === undefined) {
      setPullcontents(true);
    }
    // setsheetdatainitservice(true);

  };
  /**
   * Used to Update the from and To Date to filter the folder data
   * @param From date
   * @param To date
   * @param Cloned local copy of folder data
   */
  // const getFilteredByDate = (
  //   dataList: DataModel[],
  //   fromDate: string,
  //   toDate: string
  // ) => {
  //   let list: DataModel[] = [];
  //   if (toDate && fromDate) {
  //     dataList.forEach((element) => {
  //       let obj = element;
  //       obj.items = element.items?.filter(
  //         (obj) =>
  //           obj &&
  //           obj.dateCreated &&
  //           new Date(obj.dateCreated).getTime() >=
  //           new Date(fromDate).getTime() &&
  //           new Date(obj.dateCreated).getTime() <= new Date(toDate).getTime()
  //       );
  //       list.push(obj);
  //     });
  //   } else if (fromDate) {
  //     dataList.forEach((element) => {
  //       let obj = element;
  //       obj.items = element.items?.filter(
  //         (obj) =>
  //           obj &&
  //           obj.dateCreated &&
  //           new Date(obj.dateCreated).getTime() >= new Date(fromDate).getTime()
  //       );
  //       list.push(obj);
  //     });
  //   } else {
  //     list = dataList;
  //   }
  //   return list;
  // };

  const onnotificationprocessOpenSuccess = (response: any, parameter: any) => {
    console.log(response);
    if (parameter.openfor === "link") {
      let passdata = {
        order: response.order, rights: userrights, user: store.getState().Loggeduser,
        tenantid: sessionStorage.getItem("tenantid"), token: sessionStorage.getItem("Token"),
        loginTime: sessionStorage.getItem("LoginTime"), loggedfrom: sessionStorage.getItem("loggedfrom"),
        azuretoken: sessionStorage.getItem("azuretoken"), tenantdomain: sessionStorage.getItem("tenantdomain"),
        packagetype: isMultitenant === 0 ? "3" : sessionStorage.getItem("packagetype")
      };
      localStorage.setItem("linkedorder", JSON.stringify(passdata));
      setTimeout(() => {
        history.push("/vieworder");
      }, 500);

    }
    else {
      handleOrderopenClick(null, response.order);
    }
  }

  const onnotificationprocessOpenFailure = (response: any) => {

  }

  const updateStateforprocessnotification = (ordercode: any, openfor: String) => {
    updateOrderStatusCheck(ordercode, store.getState().Loggeduser.usercode);
    let passObjDet = {
      batchcode: ordercode,
      objLoggeduser: store.getState().Loggeduser,
      ismultitenant: isMultitenant,
      lstproject: projects,
      lstworkflow: workflow,
      openfor: openfor
    };

    setServicecall({
      ServiceToUrl: "" + ELNurl + "/Instrument/Getorderforlink",
      ServiceHeaderobject: passObjDet,
      ServiceMethodType: "POST",
      ServcieonSuccess: onnotificationprocessOpenSuccess,
      ServcieonFailure: onnotificationprocessOpenFailure,
      ServiceTenant: undefined,
      Servicecontenttype: undefined,
    });

  }

  // const openorderfornotification = (isnotificationprocess: boolean, notificationorder: any) => {
  //   if (notificationorder !== undefined && notificationorder !== null &&
  //     notificationorder !== "") {

  //     updateStateforprocessnotification(notificationorder, "screen");
  //     // change
  //     // setStateOrder((stateOrder: any) => ({
  //     //   ...stateOrder,
  //     //   KenodclassName: "clsdisplayblock",
  //     //   orderDetailsclassName: "clsDisplaynone",
  //     // }));

  //     if (props.onresetnotification !== undefined) {
  //       props.onresetnotification();
  //     }

  //   }
  // }

  const onAuditValidateError = (response: any) => {

  };

  const onAuditValidateFailure = (response: any) => { };

  const ongetfolderfailure = (event: any) => { };

  const initialLogic: "and" | "or" = "and";

  const [stateContentData, setStateContentData] = React.useState<State>({
    sort: initialSort,
    filter: {
      logic: initialLogic,
      filters: [{ field: "path", operator: "contains", value: "" }],
    },
  });

  const updateFileDetailsData = React.useCallback(
    (selection: SelectedItemType) => {
      if (selection.path) {
        // const newSelectedItem = searchTreeItem(stateData, selection);
        setDetailsData(selection);
      } else if (!selection.path && Object.keys(selection).length) {
        setDetailsData(Object.keys(selection).length);
      } else {
        setDetailsData(null);
      }
    },
    []
  );

  const ongetorderssuccess = (response: any, passeddata: any) => {
    if (
      passeddata !== undefined &&
      passeddata?.directorycode !== undefined &&
      passeddata.filefor !== undefined
    ) {
      if (passeddata.filefor === "DR" && passeddata.orderflag !== undefined) {
        response = response.filter((item: any) => {
          if (item.orderflag?.trim() === passeddata.orderflag) {
            if (passeddata.approvelstatus === 3) {
              return item.approvelstatus === passeddata.approvelstatus;
            }
            return true;
          }
          return false;
        });
      }
      if ((passeddata.filefor === "LCO" || passeddata.filefor === "ECO" || passeddata.filefor === "RCO" || passeddata.filefor === "MCO" || passeddata.filefor === "SCO") && passeddata.isFinalStep === 2) {
        response = response.filter((itesm: any) => itesm.orderflag !== undefined && itesm.orderflag.trim() === "R" && itesm.approvelstatus !== 3)
      }
      if (passeddata.filefor === "LCO" || passeddata.filefor === "ECO" || passeddata.filefor === "RCO" || passeddata.filefor === "MCO" || passeddata.filefor === "SCO") {
        response = response.map((items: any) => { delete items?.lockeduser; return items })
      }
      response.forEach((item: any) => {
        if (item.orderflag.trim() === "N" && item.filetype === 0) {
          item.canuserprocess = true;
        } else if (item.lsprojectmaster === undefined && item.viewoption !== undefined && item.viewoption === 1) {
          item.canuserprocess = true;
        } else if ((item.lsprojectmaster === undefined && item.viewoption !== undefined && item.viewoption === 2 && item.lsuserMaster?.usercode === parseInt(sessionStorage.User)) || item.viewoption === 3) {
          item.canuserprocess = true;
        }
        if (item.assignedto !== undefined && (passeddata.filefor === "ASO" || passeddata.filefor === "MYO")) {
          delete item.lsworkflow;
        }
      });
      orderdataforfolder = response;
      getfolderfiles(passeddata);
    }
  };

  const ongetordersfailure = (response: any) => { };



  const getfolderfiles = (directory: any) => {
    // if (loadtime) {
    //   ongetfilessuccess([], {
    //     directorycode: directory.directorycode, filefor: directory.filefor,
    //     contentsearchtype: searchCriteriaType.value,
    //     contentsearch: SearchContentText.SearchContentText
    //     , ismultitenant: isMultitenant,
    //     orderflag: orderflag !== undefined && orderflag !== -1 && orderflag === 1 ? "N" : "R",
    //     approvelstatus: orderflag !== undefined && orderflag === 3 ? 3 : "",
    //   })
    // } else {
    setIsLoader(true);
    setServicecall({
      ServiceToUrl: "" + ELNurl + "/Instrument/Getfilesforfolder",
      ServiceHeaderobject: {
        directorycode: directory.directorycode, filefor: directory.filefor,
        contentsearchtype: searchCriteriaType.value,
        contentsearch: SearchContentText.SearchContentText
        , ismultitenant: isMultitenant,
        orderflag: orderflag !== undefined && orderflag !== -1 && orderflag === 1 ? "N" : "R",
        approvelstatus: orderflag !== undefined && orderflag === 3 ? 3 : "",
        fromdate: GetutcDate(new Date(fromRange)),
        todate: GetutcDate(new Date(toRange)),
      },

      ServiceMethodType: "POST",
      ServcieonSuccess: ongetfilessuccess,
      ServcieonFailure: ongetfilesfailure,
      ServiceTenant: undefined,
      Servicecontenttype: undefined,
    });
    // }
  }

  const ongetfilessuccess = (response: any, passeddata: any) => {

    if (
      passeddata !== undefined &&
      passeddata?.directorycode !== undefined &&
      passeddata.filefor !== undefined
    ) {
      // setIsLoader(true);
      let directorycode = passeddata.directorycode;
      let itemdata = [];
      if (response !== undefined && response.length > 0) {
        itemdata = response;
      }
      let folderdata =
        stateData !== undefined && stateData !== null && stateData.length > 0
          ? stateData
          : initialorders !== null
            ? initialorders
            : [];

      let newdata = replaceallordertoFolderwithfilefor(
        folderdata,
        directorycode,
        intl,
        orderdataforfolder !== undefined && orderdataforfolder.length > 0 ? orderdataforfolder : [],
        passeddata.filefor,
        screen
      );
      newdata = addfileitemstoFolderwithfilefor(
        newdata,
        directorycode,
        intl,
        itemdata,
        passeddata.filefor,
        screen
      );
      let selectedtree = getDirectoryOncodeandfilefor(
        newdata,
        directorycode,
        passeddata.filefor
      );
      setSelectedTreeItem(selectedtree);
      setSelectedTreeItemDuplicate(selectedtree);
      setStateData(newdata);
      setsheetdatainitservice(false);
      setsheetdatainit(undefined)
      setIsLoader(false);
    } else {
      setIsLoader(false);
    }

    // let passObjDet = {
    //   objsilentaudit: Silentauditobject(
    //     "warning",
    //     "Sheet Orders",
    //     props.t("Orders.Protocolorders.IDS_AUDIT_FOLDEREXIST", {
    //       // folder: response.directoryname,
    //     })
    //   ),
    // };


    // Servicecalls(
    //   "" + ELNurl + "/AuditTrail/silentandmanualRecordHandler",
    //   passObjDet,
    //   onAuditValidateError.bind(this),
    //   onAuditValidateFailure.bind(this)
    // );
  }

  const ongetfilesfailure = (response: any) => {
    setIsLoader(false);
  }

  const handleTreeItemClick = (event: TreeViewItemClickEvent) => {
    let fromDate: any;
    let toDate: any;

    const newSelectedItem: DataModel = searchTreeItem(stateData, event.item);
    newSelectedItem.expanded = !event.item.expanded;
    let returndateobj = setdates(filterType, isFiltered, "sheetdaydiff", toRange, changedtodate);
    if (returndateobj !== undefined && filterType !== 1) {
      setfromRange(returndateobj.fromRange);
      settoRange(returndateobj.toRange);
      fromDate = returndateobj.fromRange;
      toDate = returndateobj.toRange;
    } else {
      fromDate = new Date(fromRange);
      fromDate.setHours(0, 0, 0);
      toDate = new Date(fromRange);
      toDate.setHours(23, 59, 59, 999);

      setfromRange(fromDate);
      settoRange(toDate);
    }
    initialorders = null;
    getordersforfolders(
      event.item,
      newSelectedItem,
      fromDate,
      toDate,
      fileType,
      searchCriteriaType,
      orderflag,
      flitertest,
      fliterproject
    );
    // console.log(selectedTreeItem)
    setSelectedTreeItem(newSelectedItem);
    setSelectedTreeItemDuplicate(newSelectedItem);
    setDetailsData(event.item);
    setSelectedGridItem({});
    setGridSelection({});
  };

  const onuserordersuccess = (response: any, passeddata: any) => {
    // let assignedorder =
    //   response.assigned !== undefined ? response.assigned : [];
    let assignedorder: any = [];
    let sharedorder =
      response.sharebyme !== undefined ? response.sharebyme : [];
    let sharetomeorder =
      response.sharetome !== undefined ? response.sharetome : [];
    if (sharedorder !== undefined && sharedorder.length > 0) {
      sharedorder = groupBy(sharedorder, "sharebatchcode");
      sharedorder = Object.keys(sharedorder).map(function (key) {
        let order = sharedorder[key][0].order;
        let shares: any = [];
        sharedorder[key].map((share: any) => {
          share.sharedonDate = convertDateFormat(
            convertUTCDateToLocalDate(new Date(share.sharedon)),
            intl
          );
          delete share.order;
          shares.push(share);
          return share;
        });
        order.sharebyme = shares;
        // let assignedorderindex = assignedorder.findIndex(
        //   (obj: any) => obj.batchcode === parseInt(key)
        // );
        // if (assignedorderindex > -1) {
        //   assignedorder.splice(assignedorderindex, 1);
        // }
        return order;
      });

      assignedorder = assignedorder.concat(sharedorder);
    }

    if (sharetomeorder !== undefined && sharetomeorder.length > 0) {
      sharetomeorder = groupBy(sharetomeorder, "sharebatchcode");
      sharetomeorder = Object.keys(sharetomeorder).map(function (key) {
        let order = sharetomeorder[key][0].order;
        let shares: any = [];
        sharetomeorder[key].map((share: any) => {
          share.sharedonDate = convertDateFormat(
            convertUTCDateToLocalDate(new Date(share.sharedon)),
            intl
          );
          delete share.order;
          shares.push(share);
          return share;
        });
        order.sharetome = shares;
        // let assignedorderindex = assignedorder.findIndex(
        //   (obj: any) => obj.batchcode === parseInt(key)
        // );
        // if (assignedorderindex > -1) {
        //   if (assignedorder[assignedorderindex].sharebyme !== undefined) {
        //     order.sharebyme = assignedorder[assignedorderindex].sharebyme;
        //   }
        //   assignedorder.splice(assignedorderindex, 1);
        // }
        return order;
      });

      assignedorder = assignedorder.concat(sharetomeorder);
    }

    // let directorycode = response.directorycode;
    // let newData = replaceallordertoFolderwithfilefor(
    //   stateData,
    //   directorycode,
    //   intl,
    //   assignedorder,
    //   "UR",
    //   screen
    // );
    // let selectedtree = getDirectoryOncodeandfilefor(
    //   newData,
    //   directorycode,
    //   "UR"
    // );
    // setSelectedTreeItem(selectedtree);
    // setStateData(newData);
    orderdataforfolder = assignedorder;
    getfolderfiles({ directorycode: response.directorycode, filefor: passeddata.filefor, searchCriteriaType, SearchContentText });
  };
  const Auditcancel = () => {
    ($('#auditTrailModalsheetview') as any).modal("hide");
    const fileduids = files.map(function (file) {
      return file['uid'];
    });
    const screen = "sheet"
    Servicecalls("" + ELNurl + "/Instrument/onDeleteforCancel/" + screen, fileduids,
      ondeletefileCancelSuccess.bind(this), onaddFailure.bind(this));
    setFiles([]);
  }

  const getordersforfolders = (
    item: any,
    newSelectedItem: any,
    fromdate: any,
    todate: any,
    filetype: any,
    searchCriteriaType: any,
    orderflag: any,
    flitertest: any,
    fliterproject: any
  ) => {
    // let fromdate = filterType === 1 ? GetutcDateaudi(GetutcDate(new Date(fromdateoriginal))) : fromdateoriginal;
    if (item !== undefined && item !== null) {
      if (
        (item.directorycode === -18 &&
          item.filefor === "MYO") || (item.directorycode === -17 &&
            item.filefor === "ASO")) {

        let lslogilablimsorderdetail = {
          filetype: filetype,
          fromdate: GetutcDate(new Date(fromdate)),
          todate: GetutcDate(new Date(todate)),
          lstworkflow: workflow,
          lsuserMaster:
            store.getState().Loggeduser !== undefined &&
              store.getState().Loggeduser !== null &&
              Object.keys(store.getState().Loggeduser).length > 0
              ? store.getState().Loggeduser
              : {
                usercode: sessionStorage.User,
                username: sessionStorage.Username,
                unifieduserid: sessionStorage.unifieduserid,
              },
          orderflag: item.filefor === "MYO" ? "M" : "A",
          searchCriteria: {
            contentsearchtype: searchCriteriaType.value,
            contentsearch: SearchContentText.SearchContentText
          },
          ismultitenant: isMultitenant
        };
        let passObjDet: any = {
          filetype: filetype,
          filefor: item.filefor,
          directorycode: item.directorycode,
        };
        passObjDet.lslogilablimsorderdetail = lslogilablimsorderdetail;
        if (orderflag !== undefined && orderflag !== -1) {
          passObjDet.orderflag = orderflag === 1 ? "N" : "R";
          if (orderflag === 3) {
            passObjDet.rejected = 1
          }
        }
        setServicecall({
          ServiceToUrl: "" + ELNurl + "/Instrument/Getordersonassignedandmyorders",
          ServiceHeaderobject: passObjDet,
          ServiceMethodType: "POST",
          ServcieonSuccess: ongetorderssuccess,
          ServcieonFailure: (response: any) => { },
          ServiceTenant: undefined,
          Servicecontenttype: undefined,
        });
      } else if ((item !== undefined && item.filefor === "OSBM") || (item.filefor === "OSTM")) {

        let passObjDet = {
          filetype: filetype,
          // loginuser: store.getState().Loggeduser,
          selecteduser: {
            usercode: sessionStorage.User,
            username: sessionStorage.Username,
            objuser: {
              fromdate: GetutcDate(new Date(fromdate)),
              todate: GetutcDate(new Date(todate)),
            },
          },
          directorycode: item.directorycode,
          lstworkflow: workflow,
          filefor: item.filefor,

          lslogilablimsorderdetail: {
            searchCriteria: {
              contentsearchtype: searchCriteriaType.value,
              contentsearch: SearchContentText.SearchContentText
            }
          },
          ismultitenant: isMultitenant
        };
        setServicecall({
          ServiceToUrl: "" + ELNurl + "/Instrument/Getusersharedorders",
          ServiceHeaderobject: passObjDet,
          ServiceMethodType: "POST",
          ServcieonSuccess: onuserordersuccess,
          ServcieonFailure: (response: any) => { },
          ServiceTenant: undefined,
          Servicecontenttype: undefined,

        })
      }
      else if (item !== undefined && item.filefor === "DR") {
        let passObjDet1: any = {
          filetype: filetype,
          directorycode: newSelectedItem.directorycode,
          objuser: {
            fromdate: GetutcDate(new Date(fromdate)),
            todate: GetutcDate(new Date(todate)),
          },
          filefor: item.filefor,
          createdby: store.getState().Loggeduser,
          lsuserMaster:
            store.getState().Loggeduser !== undefined &&
              store.getState().Loggeduser !== null &&
              Object.keys(store.getState().Loggeduser).length > 0
              ? store.getState().Loggeduser
              : {
                usercode: localStorage.getItem("User"),
                username: localStorage.getItem("Username"),
                unifieduserid: localStorage.getItem("unifieduserid"),
              },
          searchCriteria: {
            contentsearchtype: searchCriteriaType.value,
            contentsearch: SearchContentText.SearchContentText
          },
          ismultitenant: isMultitenant
        };
        if (orderflag !== undefined && orderflag !== -1) {
          passObjDet1.orderflag = orderflag === 1 ? "N" : "R"
        }
        if (orderflag !== undefined && orderflag === 3) {
          passObjDet1.approvelstatus = 3
        } else if (orderflag !== undefined && orderflag === 2) {
          passObjDet1.approvelstatus = 1
        }
        passObjDet1.lstuserMaster = Getteamusersfromsession();
        setServicecall({
          ServiceToUrl: "" + ELNurl + "/Instrument/Getordersondirectory",
          ServiceHeaderobject: passObjDet1,
          ServiceMethodType: "POST",
          ServcieonSuccess: ongetorderssuccess,
          ServcieonFailure: ongetordersfailure,
          ServiceTenant: undefined,
          Servicecontenttype: undefined,
        });
      } else if (item !== undefined && item.filefor === "TMP") {
        let directorycode = newSelectedItem.directorycode.toString().split(".");

        let fromDate = GetutcDate(new Date(fromdate));

        if (fromdate === todate) {
          fromdate = new Date(fromdate).setHours(0);
          fromDate = GetutcDate(new Date(fromdate));
        }

        let passObjDet2: any = {
          filetype: filetype,
          fromdate: fromDate,
          todate: GetutcDate(new Date(todate)),
          lsprojectmaster: { projectcode: directorycode[0] },
          lstestmasterlocal: { testcode: directorycode[1] },
          directorycode: newSelectedItem.directorycode,
          filefor: item.filefor,
          lsuserMaster: store.getState().Loggeduser,
          searchCriteria: {
            contentsearchtype: searchCriteriaType.value,
            contentsearch: SearchContentText.SearchContentText
          },
          ismultitenant: isMultitenant

        };
        if (orderflag !== undefined && orderflag !== -1) {
          passObjDet2.orderflag = orderflag === 1 ? "N" : "R"
        }
        if (orderflag !== undefined && orderflag === 3) {
          passObjDet2.approvelstatus = 3
        } else if (orderflag !== undefined && orderflag === 2) {
          passObjDet2.approvelstatus = 1
        }
        setServicecall({
          ServiceToUrl: "" + ELNurl + "/Instrument/Getordersonproject",
          ServiceHeaderobject: passObjDet2,
          ServiceMethodType: "POST",
          ServcieonSuccess: ongetorderssuccess,
          ServcieonFailure: ongetordersfailure,
          ServiceTenant: undefined,
          Servicecontenttype: undefined,
        });
      } else if (item !== undefined && item.filefor === "TMS") {
        let directorycode = newSelectedItem.directorycode.toString().split(".");

        let passObjDet3: any = {
          filetype: filetype,
          fromdate: GetutcDate(new Date(fromdate)),
          todate: GetutcDate(new Date(todate)),
          lssamplemaster: { samplecode: directorycode[0] },
          lstestmasterlocal: { testcode: directorycode[1] },
          directorycode: newSelectedItem.directorycode,
          filefor: item.filefor,
          lsuserMaster: store.getState().Loggeduser,
          searchCriteria: {
            contentsearchtype: searchCriteriaType.value,
            contentsearch: SearchContentText.SearchContentText
          },
          ismultitenant: isMultitenant
        };
        if (orderflag !== undefined && orderflag !== -1) {
          passObjDet3.orderflag = orderflag === 1 ? "N" : "R"
        }
        if (orderflag !== undefined && orderflag === 3) {
          passObjDet3.approvelstatus = 3
        } else if (orderflag !== undefined && orderflag === 2) {
          passObjDet3.approvelstatus = 1
        }
        setServicecall({
          ServiceToUrl: "" + ELNurl + "/Instrument/Getordersonsample",
          ServiceHeaderobject: passObjDet3,
          ServiceMethodType: "POST",
          ServcieonSuccess: ongetorderssuccess,
          ServcieonFailure: ongetordersfailure,
          ServiceTenant: undefined,
          Servicecontenttype: undefined,
        });
      }
      else if (item !== undefined && item.filefor === "TMM") {
        let directorycode = newSelectedItem.directorycode.toString().split(".");

        let passObjDet3: any = {
          filetype: filetype,
          fromdate: GetutcDate(new Date(fromdate)),
          todate: GetutcDate(new Date(todate)),
          elnmaterial: { nmaterialcode: directorycode[0] },
          lstestmasterlocal: { testcode: directorycode[1] },
          directorycode: newSelectedItem.directorycode,
          filefor: item.filefor,
          lsuserMaster: store.getState().Loggeduser,
          searchCriteria: {
            contentsearchtype: searchCriteriaType.value,
            contentsearch: SearchContentText.SearchContentText
          },
          ismultitenant: isMultitenant,
          lstproject: projects,
        };
        if (orderflag !== undefined && orderflag !== -1) {
          passObjDet3.orderflag = orderflag === 1 ? "N" : "R"
        }
        if (orderflag !== undefined && orderflag === 3) {
          passObjDet3.approvelstatus = 3
        } else if (orderflag !== undefined && orderflag === 2) {
          passObjDet3.approvelstatus = 1
        }

        passObjDet3.lstuserMaster = Getteamusersfromsession();
        setServicecall({
          ServiceToUrl: "" + ELNurl + "/Instrument/Getordersonmaterial",
          ServiceHeaderobject: passObjDet3,
          ServiceMethodType: "POST",
          ServcieonSuccess: ongetorderssuccess,
          ServcieonFailure: ongetordersfailure,
          ServiceTenant: undefined,
          Servicecontenttype: undefined,
        });
      }

      else if (item !== undefined && (item.filefor === "LPO" || item.filefor === "EPO"
        || item.filefor === "RPO" || item.filefor === "MPO" || item.filefor === "SPO" || item.filefor === "LCO" || item.filefor === "ECO"
        || item.filefor === "RCO" || item.filefor === "MCO" || item.filefor === "SCO")) {

        columns.map((col) => {
          if (item.filefor === "LCO" || item.filefor === "ECO" || item.filefor === "RCO" || item.filefor === "MCO" || item.filefor === "SCO") {
            if (col.field === 'dateCompleted') {
              col.show = true;
            }
          } else {
            if (col.field === 'dateCompleted') {
              col.show = false;
            }
          }
          return col;
        });

        let fromDate = GetutcDate(new Date(fromdate));

        if (fromdate === todate) {
          fromdate = new Date(fromdate).setHours(0);
          fromDate = GetutcDate(new Date(fromdate));
        }

        let passObjDet4: any = {
          filetype: renderSwitch(item.filefor),
          fromdate: fromDate,
          todate: GetutcDate(new Date(todate)),
          lstproject: projects,
          lstworkflow: workflow,
          directorycode: newSelectedItem.directorycode,
          orderflag: item.filefor === "LPO" || item.filefor === "EPO"
            || item.filefor === "RPO" || item.filefor === "MPO" || item.filefor === "SPO" ? "N" : "R",
          filefor: item.filefor,
          lsuserMaster: store.getState().Loggeduser,
          searchCriteria: {
            contentsearchtype: searchCriteriaType.value,
            contentsearch: SearchContentText.SearchContentText
          },
          ismultitenant: isMultitenant
        };

        if (item.filefor === "LCO" || item.filefor === "ECO" || item.filefor === "RCO" || item.filefor === "MCO" || item.filefor === "SCO") {
          if (orderflag !== undefined && (orderflag === -1 || orderflag === 2)) {
            passObjDet4.orderflag = "R"
            passObjDet4.isFinalStep = orderflag;
          } else if (orderflag !== undefined && orderflag === 3) {
            passObjDet4.approvelstatus = 3
          }
        }
        // else {
        //   if (orderflag !== undefined && orderflag !== -1) {
        //     passObjDet4.orderflag = orderflag === 1 ? "N" : "R"
        //   }
        //   if (orderflag !== undefined && orderflag === 3) {
        //     passObjDet4.approvelstatus = 3
        //   }
        // }
        if (flitertest.testcode !== -1) {
          passObjDet4.testcode = flitertest.testcode
        }
        if (fliterproject.projectcode !== undefined && fliterproject.projectcode !== -1) {
          passObjDet4.lsprojectmaster = fliterproject;
        }
        passObjDet4.lstuserMaster = Getteamusersfromsession();
        setServicecall({
          ServiceToUrl: "" + ELNurl + "/Instrument/Getorderbyflaganduser",
          ServiceHeaderobject: passObjDet4,
          ServiceMethodType: "POST",
          ServcieonSuccess: ongetorderssuccess,
          ServcieonFailure: ongetordersfailure,
          ServiceTenant: undefined,
          Servicecontenttype: undefined,
        });
      }
      columns.map((col) => {
        if (item.filefor === "LCO" || item.filefor === "ECO" || item.filefor === "RCO" || item.filefor === "MCO" || item.filefor === "SCO") {
          if (col.field === 'dateCompleted') {
            col.show = true;
          }
        } else {
          if (col.field === 'dateCompleted') {
            col.show = false;
          }
        }
        return col;
      });
      setPullcontents(false);
    }
  };

  const renderSwitch = (type: string) => {
    switch (type) {
      case 'LPO':
        return 0;
      case 'EPO':
        return 2;
      case 'RPO':
        return 3;
      case 'MPO':
        return 4;
      case 'SPO':
        return 5;
      case 'LCO':
        return 0;
      case 'ECO':
        return 2;
      case 'RCO':
        return 3;
      case 'MCO':
        return 4;
      case 'SCO':
        return 5;
      default:
        return 2;
    }
  }

  const handleSplitterChange = (event: SplitterOnChangeEvent) => {
    setPanes(event.newState);
  };

  const handleSelectionChange = (event: GridRowClickEvent, skipdata: any) => {
    // if (contentView === "grid" && click >= 2) {
    //   handleGridDoubleClick(event, skipdata);
    // }
    if (skipdata !== undefined) {
      setTakeskip(skipdata);
    }

    setSelectedGridItem(event.dataItem);
    updateFileDetailsData(event.dataItem);
    if (event.dataItem.path) {
      const key = event.dataItem["path"].toString();
      setGridSelection({ [key]: true });
    }


  };

  const handleMultipleSelection = (event: any, skipdata: any) => {
    if (skipdata !== undefined) {
      setTakeskip(skipdata);
    }

    const newSelection = getSelectedState({
      event,
      selectedState: gridSelection,
      dataItemKey: DATA_ITEM_KEY,
    });

    setGridSelection(newSelection);
    updateFileDetailsData(newSelection);


  };

  const handleSortChange = (event: SortChangeEvent, skipdata: any) => {
    if (skipdata !== undefined) {
      setTakeskip(skipdata);
    }
    const newSortedData = clone(stateContentData);

    if (event.direction === "asc" || event.direction === "desc") {
      newSortedData.sort[0].dir = event.direction;
    }

    if (event.item) {
      newSortedData.sort[0].field = event.item.value;
    }

    if (event.sort) {
      newSortedData.sort = event.sort;
    }
    setStateContentData(newSortedData);


  };

  const handleGridDoubleClick = (event: GridRowDoubleClickEvent, skipdata: any) => {
    console.trace();
    if (skipdata !== undefined) {
      setTakeskip(skipdata);
    }

    const itemType = convertExtensionToIcon(event.dataItem.path, null);
    if (itemType?.type === "Folder") {
      if (selectedTreeItem) {
        selectedTreeItem.expanded = true;
      }

      const newSelectedItem = searchTreeItem(stateData, event.dataItem);
      newSelectedItem.expanded = true;
      setSelectedTreeItem(newSelectedItem);
      setSelectedTreeItemDuplicate(newSelectedItem);
      initialorders = null;
      getordersforfolders(
        event.dataItem,
        newSelectedItem,
        fromRange,
        toRange,
        fileType,
        searchCriteriaType,
        orderflag,
        flitertest,
        fliterproject
      );
    } else if (
      event.dataItem !== undefined &&
      event.dataItem.order !== undefined && event.dataItem.order.ordercancell === undefined
    ) {
      handleOrderopenClick(null, event.dataItem.order);
    } else if (event.dataItem !== undefined &&
      event.dataItem.order !== undefined && event.dataItem.order.ordercancell !== undefined && event.dataItem.order.ordercancell === 1) {
      props.alert.error(props.t("Orders.Protocolorders.IDS_LBL_CANCELLORDEROPEN"));

    }


  };

  const handleMultiSelectClick = (event: any, item: any) => {
    let itemindex = selectedOrders.findIndex(
      (obj) => obj.batchcode === item.batchcode
    );
    if (itemindex > -1) {
      selectedOrders.splice(itemindex, 1);
    } else {
      selectedOrders.push(item);
    }

    setSelectedOrders([...selectedOrders]);
  };

  const handleMultiSelectFileClick = (event: any, item: any) => {

    let itemindex = selectedFiles.findIndex(
      (obj) => obj.folderfilecode === item.folderfilecode
    );
    if (itemindex > -1) {
      selectedFiles.splice(itemindex, 1);
    } else {
      selectedFiles.push(item);
    }

    setSelectedFiles([...selectedFiles]);
  };

  const handleMultiSelectFolderClick = (event: any, item: any) => {

    let itemindex = selectedFolders.findIndex(
      (obj) => obj.directorycode === item.directorycode
    );
    if (itemindex > -1) {
      selectedFolders.splice(itemindex, 1);
    } else {
      selectedFolders.push(item);
    }

    setSelectedFolders([...selectedFolders]);
  };

  const onOrderShared = (item: any) => {
    if (selectedTreeItem !== undefined && selectedTreeItem !== null) {
      let directorycode = selectedTreeItem?.directorycode;
      let filefor = selectedTreeItem?.filefor;
      if (
        filefor === "UR" &&
        directorycode !== undefined &&
        directorycode?.toString() === sessionStorage.getItem("User")
      ) {
        let selectedtreeitems =
          selectedTreeItem !== undefined ? selectedTreeItem?.items : [];
        let selecteditem = selectedGridItem;
        selectedtreeitems = selectedtreeitems?.map((obj: any) => {
          if (
            obj.directorycode === item.order.order.batchcode &&
            obj.order !== undefined
          ) {
            if (obj.order.sharebyme === undefined) {
              obj.order.sharebyme = [];
            }
            let shareindex = obj.order.sharebyme.findIndex(
              (shareobj: any) =>
                shareobj.sharedbycode === item.order.sharedbycode
            );
            let itemvalue = item.order;
            itemvalue.sharedonDate = convertDateFormat(
              convertUTCDateToLocalDate(new Date(itemvalue.sharedon)),
              intl
            );
            if (shareindex <= -1) {
              obj.order.sharebyme.push(itemvalue);
            } else {
              obj.order.sharebyme[shareindex] = itemvalue;
            }
            selecteditem = obj;
          }
          return obj;
        });
        if (selectedtreeitems !== undefined) {
          selectedTreeItem.items = selectedtreeitems;
          setSelectedTreeItem(selectedTreeItem);
          setSelectedTreeItemDuplicate(selectedTreeItem);
          setSelectedGridItem(selecteditem);
          setDetailsData(selecteditem);
        }
      }
    }
  };

  const handleNewFolderClick = (event: any) => {
    let newData = props.sessionvalues;
    newData.directoryname = event.value.trim();
    newData.viewoption = event.folderplace;
    newData.sitemaster = { sitecode: sessionStorage.Usersite };
    newData.createdby = { usercode: sessionStorage.User };
    // newData.teamcode = event.projectteamcode !== undefined ? event.projectteamcode.teamcode : undefined;

    // if (selectedTreeItem) {
    //   if (selectedTreeItem.filefor === "DR" || selectedTreeItem.filefor === "TMP" || selectedTreeItem.filefor === "TMS") {
    //     newData.parentdircode = selectedTreeItem.directorycode;
    //   } else {
    //     newData.parentdircode = stateData[0].parentdircode;
    //   }
    // }
    if (selectedTreeItem) {
      if (selectedTreeItem !== undefined && selectedTreeItem.filefor === "DR" || selectedTreeItem.filefor === "TMP" || selectedTreeItem.filefor === "TMS") {
        let parts = selectedTreeItem?.directorycode?.toString().split(".");
        if (parts?.length === 2) {
          newData.parentdircode = parts[0];
          newData.floatvalues = parts[1];
        } else {
          newData.parentdircode = selectedTreeItem.directorycode;
        }

      } else {
        newData.parentdircode = stateData[0].parentdircode;
      }
    }
    else {
      newData.parentdircode = stateData[0].parentdircode;
    }
    Servicecalls(
      "" + ELNurl + "/Instrument/Insertdirectory",
      newData,
      onfoldersuccess,
      onfolderfailure
    );

  };


  const onfoldersuccess = (response: any, event: any) => {

    let passObjDet = {};
    let validData = response.response.status;
    let newData = props.sessionvalues;
    response.parentdircode = response.parentdircode + "." + event.floatvalues;
    newData.sitemaster = { sitecode: sessionStorage.Usersite };
    newData.createdby = { usercode: sessionStorage.User };

    if (validData) {
      if (selectedTreeItem && (selectedTreeItem?.filefor === "DR" || selectedTreeItem.filefor === "TMP" || selectedTreeItem.filefor === "TMS")) {
        newData = getFolderToSave(
          stateData,
          selectedTreeItem,
          intl,
          response.directoryname,
          screen,
          response
        );
        // let passObjDet = {
        //   objsilentaudit: Silentauditobject(
        //     "create",
        //     "Sheet Orders",
        //     props.t("Orders.Sheetorders.IDS_AUDIT_WITHINANOTHERFOLDER", {
        //       user: props.sessionvalues.username,
        //       folder2: response.directoryname,
        //       folder1: selectedTreeItem.directoryname,
        //       visible: response.viewoption === 1 ? "Site" : response.viewoption === 2 ? "Only me" : "Project Team"

        //     })
        //   ),
        // };
        // Servicecalls(
        //   "" + ELNurl + "/AuditTrail/silentandmanualRecordHandler",
        //   passObjDet,
        //   onAuditValidateError.bind(this),
        //   onAuditValidateFailure.bind(this)
        // );
      } else {
        newData = getFolderToSave(
          stateData,
          null,
          intl,
          response.directoryname,
          screen,
          response,

        );
        // let passObjDet = {
        //   objsilentaudit: Silentauditobject(
        //     "create",
        //     "Sheet Orders",
        //     props.t("Orders.Sheetorders.IDS_AUDIT_REQADDNEWFOLDER", {
        //       user: props.sessionvalues.username,
        //       folder: response.directoryname,
        //       visible: response.viewoption === 1 ? "Site" : response.viewoption === 2 ? "Only me" : "Project Team"

        //     })
        //   ),
        // };
        // Servicecalls(
        //   "" + ELNurl + "/AuditTrail/silentandmanualRecordHandler",
        //   passObjDet,
        //   onAuditValidateError.bind(this),
        //   onAuditValidateFailure.bind(this)
        // );
      }
      newData.parentdircode = event.parentdircode;
      newData.floatvalues = event.floatvalues;
      let Audit = AuditTrailValidation(
        "IDS_MDL_ORDERS",
        "IDS_SCN_SHEETORDERS",
        "IDS_TSK_NEWFOLDER"
      );
      if (Audit) {
        ($('#auditTrailModalsheetview') as any).modal("show");
        newData.objmanualaudit = Manualauditobject("create", "Sheet Orders");
        setAuditservicecall({
          AuditUrl: "" + ELNurl + "/Instrument/Insertnewdirectory",
          AuditObject: newData,
          AuditPassfunction: oninsertfoldersuccess,
          AuditFailfunction: oninsertfolderfailure,
        });

      }
      else {
        Servicecalls(
          "" + ELNurl + "/Instrument/Insertnewdirectory",
          newData,
          oninsertfoldersuccess,
          oninsertfolderfailure
        );
      }
    } else {
      props.alert.error(props.t("Orders.Sheetorders.IDS_MSG_FOLDEREXIST"));
      passObjDet = {
        objsilentaudit: Silentauditobject(
          "warning",
          "Sheet Orders",
          props.t("Orders.Protocolorders.IDS_AUDIT_FOLDEREXIST", {
            folder: response.directoryname,
          })
        ),
      };

    }

    Servicecalls(
      "" + ELNurl + "/AuditTrail/silentandmanualRecordHandler",
      passObjDet,
      onAuditValidateError.bind(this),
      onAuditValidateFailure.bind(this)
    );
  };

  const onfolderfailure = (response: any) => { };

  const oninsertfoldersuccess = (response: any) => {
    if (response.floatvalues !== undefined) {
      response.parentdircode = parseFloat(response.parentdircode + "." + response.floatvalues);
    }
    ($('#auditTrailModalsheetview') as any).modal("hide")

    if (selectedTreeItem && (selectedTreeItem?.filefor === "DR" || selectedTreeItem.filefor === "TMP" || selectedTreeItem.filefor === "TMS")) {
      // newData = getFolderToSave(
      //   stateData,
      //   selectedTreeItem,
      //   intl,
      //   response.directoryname,
      //   screen,
      //   response
      // );
      let passObjDet = {
        objsilentaudit: Silentauditobject(
          "create",
          "Sheet Orders",
          props.t("Orders.Sheetorders.IDS_AUDIT_WITHINANOTHERFOLDER", {
            user: props.sessionvalues.username,
            folder2: response.directoryname,
            folder1: selectedTreeItem.directoryname,
            visible: response.viewoption === 1 ? "Site" : response.viewoption === 2 ? "Only me" : "Project Team"

          })
        ),
      };
      Servicecalls(
        "" + ELNurl + "/AuditTrail/silentandmanualRecordHandler",
        passObjDet,
        onAuditValidateError.bind(this),
        onAuditValidateFailure.bind(this)
      );
    } else {
      // newData = getFolderToSave(
      //   stateData,
      //   null,
      //   intl,
      //   response.directoryname,
      //   screen,
      //   response,

      // );
      let passObjDet = {
        objsilentaudit: Silentauditobject(
          "create",
          "Sheet Orders",
          props.t("Orders.Sheetorders.IDS_AUDIT_REQADDNEWFOLDER", {
            user: props.sessionvalues.username,
            folder: response.directoryname,
            visible: response.viewoption === 1 ? "Site" : response.viewoption === 2 ? "Only me" : "Project Team"

          })
        ),
      };
      Servicecalls(
        "" + ELNurl + "/AuditTrail/silentandmanualRecordHandler",
        passObjDet,
        onAuditValidateError.bind(this),
        onAuditValidateFailure.bind(this)
      );
    }

    let newData;

    props.alert.success(props.t("Orders.Sheetorders.IDS_MSG_FOLDERADDED"));


    if (response !== undefined) {
      response.icon = convertExtensionToIcon(response.directoryname, null);
      response.dateCreated = convertDateFormat(
        convertUTCDateToLocalDate(new Date(response.dateCreated)),
        intl
      );
      response.dateModified = convertDateFormat(
        convertUTCDateToLocalDate(new Date(response.dateModified)),
        intl
      );

      response.filefor = "DR";
    }
    if (selectedTreeItem && (selectedTreeItem?.filefor === "DR" || selectedTreeItem.filefor === "TMP" || selectedTreeItem.filefor === "TMS")) {
      newData = addFolderonObj(stateData, selectedTreeItem, intl, response);
      const newTreeItem = searchTreeItem(newData, selectedTreeItem);
      setSelectedTreeItem(newTreeItem);
      setSelectedTreeItemDuplicate(newTreeItem);
    } else {
      newData = addFolderonObj(stateData, null, intl, response);
    }
    setStateData(newData);


  };

  const oninsertfolderfailure = (response: any) => { };

  const handleRegisterOrder = (response: any) => {
    let selectedorder = response.selectOrderData;
    let selectedtest = response.selectTestData;
    let selectedsheet = response.selectSheetData;
    let selectedproject = response.selectProjectData;
    let selectedsample = response.selectSampleData;
    let selectedrepository = response.selectRepoData;
    let selectedrepositorydata = response.selectRepodataData;
    // let selectedworkflow = response.selectWflowData;
    let selectedkeyword = response.selectOrderKey;
    let selectedfolder = response.selectFolderData;
    let selectassigneduser = response.selectassigneduser;
    let viewoption = response.viewoption;
    let selectedMaterial = response.selectMaterial;
    let selectedMaterialInventory = response.selectMaterialInventory;
    // let teamcode = response.projectteamcode !== undefined ? response.projectteamcode.teamcode : undefined

    let contentparsed =
      selectedsheet !== undefined &&
        selectedsheet.filecontent === undefined &&
        selectedsheet.filecontent !== null
        ? extractValuefromsheet(selectedsheet.filecontent)
        : extractValuefromsheet(sheetDefaultContent);

    if (parseInt(selectedorder.type) === 4) {
      selectedsheet = { filecode: 1, filecontent: sheetDefaultContent }
    }

    let passObjDet: any = {
      testcode: selectedtest !== undefined && selectedtest !== null ? selectedtest.testcode : null,
      testname: selectedtest !== undefined && selectedtest !== null ? selectedtest.testname : null,
      lstestmasterlocal: selectedtest !== undefined && selectedtest !== null && Object.keys(selectedtest).length > 0 ? selectedtest : null,
      lsfile: selectedsheet,
      lssamplefile: {
        filecontent:
          selectedsheet !== undefined
            ? selectedsheet.filecontent
            : sheetDefaultContent,
        contentvalues: contentparsed.contentvalues,
        contentparameter: contentparsed.contentparameter,
        createdate: GetutcDate(new Date()),
        createbyuser: store.getState().Loggeduser,
        processed: 0,
        versionno: 1,
        lssamplefileversion: [
          {
            filecontent:
              selectedsheet !== undefined
                ? selectedsheet.filecontent
                : sheetDefaultContent,
            createdate: GetutcDate(new Date()),
            createbyuser: store.getState().Loggeduser,
            versionno: 1,
            versionname: "version_1",
          },
        ],
      },
      filetype: parseInt(selectedorder.type),
      lsuserMaster: store.getState().Loggeduser,
      lsprojectmaster: selectedproject,
      assignedto: selectassigneduser,
      lssamplemaster:
        selectedsample !== undefined && selectedsample !== null
          ? selectedsample
          : null,
      objLoggeduser: store.getState().Loggeduser,
      //  objsilentaudit: silentaudit,
      objmanualaudit: Manualauditobject("insert", "Sheet Orders"),
      ismultitenant: isMultitenant,
      createdtimestamp: GetutcDate(new Date()),
      lsrepositories: !isPremiumTenant ? selectedrepository : null,
      lsrepositoriesdata: !isPremiumTenant ? selectedrepositorydata : null,
      elnmaterial: selectedMaterial,
      elnmaterialinventory: isPremiumTenant ? selectedMaterialInventory : null,
      keyword: selectedkeyword,
      directorycode: selectedfolder === undefined || selectedfolder === null ? null : selectedfolder.directorycode,
      orderdisplaytype: response.orderdisplaytype,
      viewoption: selectedproject !== undefined && selectedproject !== null ? 1 : viewoption,
      lstworkflow: workflow
    };

    passObjDet.keyword = selectedkeyword;
    // if (viewoption === 3) {
    //   passObjDet.teamcode = teamcode
    // }

    passObjDet.notificationdate = convertUTCDateToLocalDate(new Date());

    let Audit = AuditTrailValidation("IDS_MDL_ORDERS", "IDS_SCN_SHEETORDERS", "IDS_TSK_REGISTER");

    let ordersuccess = false;
    if (response.selectWflowData !== undefined) {
      ordersuccess = true;
    }
    if (Audit) {

      ($('#auditTrailModalsheetview') as any).modal('show');
      passObjDet.objmanualaudit = Manualauditobject("registered", "Sheet Orders");
      setAuditservicecall({
        AuditUrl: "" + ELNurl + "/Instrument/InsertELNOrder",
        AuditObject: passObjDet,
        AuditPassfunction: oninsertordersuccess.bind(this, ordersuccess),
        AuditFailfunction: oninsertorderfailure.bind(this),


      });
    }
    else {
      Servicecalls(
        "" + ELNurl + "/Instrument/InsertELNOrder",
        passObjDet,
        oninsertordersuccess.bind(this, ordersuccess),
        oninsertorderfailure.bind(this),
      )
    }
  };
  const oninsertordersuccess = (ordersuccess: any, response: any) => {
    ($('#auditTrailModalsheetview') as any).modal("hide")

    if (
      response !== undefined &&
      response.orderdisplaytype !== undefined &&
      response.orderdisplaytype !== null
    ) {


      let selectedtree = selectedTreeItem;
      props.alert.success(props.t("Orders.Sheetorders.IDS_LBL_ORDERMSG", {
        orderid: response.batchid
      }));
      if (response.assignedto && response.assignedto !== null) {
        if (response.assignedto.usercode === parseInt(sessionStorage.User)) {
          selectedtree = getDirectoryOncodeandfilefor(stateData, -18, "MYO");
        }
        else {
          selectedtree = getDirectoryOncodeandfilefor(stateData, -17, "ASO");
        }
      }
      else if ((response.orderdisplaytype === 1 && response.lsprojectmaster !== undefined && response.lsprojectmaster !== null)
        || (response.orderdisplaytype === 2 && response.elnmaterial !== undefined && response.elnmaterial !== null)) {
        if (response.filetype === 2) {
          selectedtree = getDirectoryOncodeandfilefor(stateData, -8, "EPO");
        }
        else if (response.filetype === 3) {
          selectedtree = getDirectoryOncodeandfilefor(stateData, -9, "RPO");
        }
        else if (response.filetype === 4) {
          selectedtree = getDirectoryOncodeandfilefor(stateData, -10, "MPO");
        }
        else if (response.filetype === 5) {
          selectedtree = getDirectoryOncodeandfilefor(stateData, -11, "SPO");
        }

      }
      else {
        selectedtree = getDirectoryOncodeandfilefor(stateData, response.directorycode, "DR");
      }

      if (response.assignedto !== undefined && response.assignedto.userstatus === "Locked") {
        let passObjDet1 = {
          objsilentaudit: Silentauditobject(
            "assign",
            "Sheet Orders",
            props.t("Orders.Sheetorders.IDS_AUDIT_ASSIGNTOUSER", {
              // screen: orderType(response.filetype),
              user1: response.batchid,
              user2: response.lockedusername
            })
          ),
        };
        Servicecalls(
          "" + ELNurl + "/AuditTrail/silentandmanualRecordHandler",
          passObjDet1,
          onAuditValidateError.bind(this),
          onAuditValidateFailure.bind(this)
        );
      } else {
        let passObjDet = {
          objsilentaudit: Silentauditobject(
            "registered",
            "Sheet Orders",
            props.t("Orders.Sheetorders.IDS_AUDIT_INSERTORDERS", {
              orderid: response.batchid,
              user: store.getState().Loggeduser.username,
              screenName: orderType(response.filetype),
              visible: response.viewoption === 1 ? "Site" : response.viewoption === 2 ? "Only me" : "Project Team"

            })
          ),
        };
        Servicecalls(
          "" + ELNurl + "/AuditTrail/silentandmanualRecordHandler",
          passObjDet,
          onAuditValidateError.bind(this),
          onAuditValidateFailure.bind(this)
        );
      }
      // else if (response.noworkflow === false) {
      //   passObjDet = {
      //     objsilentaudit: Silentauditobject(
      //       "assign",
      //       "Sheet Orders",
      //       props.t("Orders.Sheetorders.IDS_AUDIT_WORKFLOWASSIGNTOUSER", {
      //         screen: orderType(response.filetype),
      //         user1: response.batchid,
      //         user2: store.getState().Loggeduser.username,
      //       })
      //     ),
      //   };
      //   Servicecalls(
      //     "" + ELNurl + "/AuditTrail/silentandmanualRecordHandler",
      //     passObjDet,
      //     onAuditValidateError.bind(this),
      //     onAuditValidateFailure.bind(this)
      //   );
      // }

      if (response.directorycode !== undefined && response.orderflag.trim() === "N") {
        response.canuserprocess = true
      } else if (response.lsprojectmaster === undefined && response.viewoption !== undefined && response.viewoption === 1) {
        response.canuserprocess = true;
      } else if ((response.lsprojectmaster === undefined && response.viewoption !== undefined && response.viewoption === 2 && response.lsuserMaster?.usercode === parseInt(sessionStorage.User)) || response.viewoption === 3) {
        response.canuserprocess = true;
      }

      let orderobject = {
        dateCreated: convertDateFormat(
          convertUTCDateToLocalDate(new Date(response.createdtimestamp)) ||
          null,
          intl
        ),
        dateModified: convertDateFormat(
          convertUTCDateToLocalDate(new Date(response.createdtimestamp)) ||
          null,
          intl
        ),
        dateCompleted: response.completedtimestamp !== undefined ? convertDateFormat(
          convertUTCDateToLocalDate(new Date(response.completedtimestamp)) ||
          null,
          intl
        ) : null,
        size: 124,
        icon: convertExtensionToIcon(
          response.batchid + ".ord",
          response.orderflag !== undefined && response.orderflag.trim() === "R"
            ? "#54c518"
            : "#e16733"
        ),
        items: [],
        path: `${selectedtree?.path}/${response.batchid + ".ord"}`,
        directorycode: response.batchcode,
        parentdircode:
          response.orderdisplaytype === 3
            ? selectedtree?.directorycode
            : response.orderdisplaytype === 1 &&
              response.lsprojectmaster !== undefined &&
              response.lsprojectmaster !== null &&
              response.testcode !== undefined
              ? parseFloat(
                response.lsprojectmaster.projectcode + "." + response.testcode
              )
              : response.orderdisplaytype === 2 &&
                response.lssamplemaster !== undefined &&
                response.lssamplemaster !== null &&
                response.testcode !== undefined
                ? parseFloat(
                  response.lssamplemaster.samplecode + "." + response.testcode
                )
                : selectedtree?.directorycode,
        directoryname: response.batchid,
        order: response,
        link: generateLink(response.batchcode, "Sheet"),
      };

      let newdata = addFolderonObj(
        stateData,
        selectedtree,
        intl,
        orderobject
      );
      newdata = addprojectinsidetask(
        newdata,
        null,
        intl,
        response,
        "PR",
        screen
      )
      if (selectedtree?.directorycode && selectedtree?.filefor) {
        setSelectedTreeItem(getDirectoryOncodeandfilefor(newdata, selectedtree?.directorycode, selectedtree?.filefor));
        setSelectedTreeItemDuplicate(getDirectoryOncodeandfilefor(newdata, selectedtree?.directorycode, selectedtree?.filefor));
      }
      if (selectedTreeItem?.filefor === "LCO" || selectedTreeItem?.filefor === "ECO" || selectedTreeItem?.filefor === "RCO" || selectedTreeItem?.filefor === "MCO" || selectedTreeItem?.filefor === "SCO") {
        columns.map((col) => {
          if (col.field === 'dateCompleted') {
            col.show = false;
          }
          return col;
        });
      }
      setStateData(newdata);
      setDetailsData(orderobject);
      setSelectedGridItem(orderobject);
      setGridSelection({
        [`${selectedtree?.path}/${response.batchid + ".ord"}`]: true,
      });
      settoRange(new Date());
      // setPullcontents(true);






      props.props.alert.success(props.t("Orders.Sheetorders.IDS_MSG_ORDER", { param: "" }));
    }
  };

  const oninsertorderfailure = (response: any) => { };


  const handleSearchChange = (event: InputChangeEvent) => {
    // let eventPath = "";
    if (event.value !== undefined && event.value !== "") {
      // if (selectedTreeItem?.items) {
      //   const curPath = getFolderPath(selectedTreeItem.items[0].path);
      //   eventPath = `${curPath}/${event.value}`;
      // } else if (stateData && stateData.length > 0) {
      //   const curPath = getFolderPath(stateData[0].path);
      //   eventPath = `${curPath}/${event.value}`;
      // }

      setStateContentData({
        ...stateContentData,
        filter: {
          logic: initialLogic,
          filters: [{ field: "path", operator: "contains", value: event.value }],
        },
      });
    } else {
      setStateContentData({
        sort: initialSort,
        filter: {
          logic: initialLogic,
          filters: [{ field: "path", operator: "contains", value: "" }],
        },
      });
    }
  };

  const handleSwitchChange = (event: AppSwitchChangeEvent) => {
    const newPanes: PanesModel[] = panes.slice(0);
    if (event.value) {
      newPanes[2].size = "30%";
      setPanes(newPanes);
    } else {
      newPanes[2].size = "0%";
      setPanes(newPanes);
    }
  };

  const handleViewChange = (event: ViewChangeEvent) => {
    if (event.viewValue.gridView) {
      setContentView("grid");
      localStorage.setItem("elnsheetorderview", "grid");
    }
    if (event.viewValue.listView) {
      setContentView("list");
      localStorage.setItem("elnsheetorderview", "list");
    }
  };

  // const handleFileChange = (event: UploadAddEvent) => {
  const handleFileChange = (event: any) => {
    if (event && event.event && event.event.affectedFiles && event.event.affectedFiles.length > 0
      && event.event.affectedFiles[0].status === 4 && event.event.response
      && event.event.response.status === 200 && event.event.response.response &&
      event.event.response.response.res && !event.event.response.response.res.status
      && event.event.response.response.uid
    ) {
      let uid = event.event.response.response.uid;
      if (event.files) {
        let files = event.files.filter((file: any) => file.uid !== uid);
        setFiles(files);
      }

      props.alert.error(props.t(event.event.affectedFiles[0].name + " file already exists in the directory"));
    }
    else {
      if (event.files) {
        setFiles(event.files);
      }
    }
  };

  const handleAddFileChange = (event: UploadAddEvent) => {
    if (event && event.event && event.event.affectedFiles && event.event.affectedFiles.length > 0
      && event.event.affectedFiles[0].status === 3 && event.event.affectedFiles[0].progress === 0) {
      if (event.files) {
        setAddedfiles(event.files);
      }
      Servicecalls(
        "" + ELNurl + "/Instrument/validatefileexistonfolder",
        {
          directorycode: selectedTreeItem?.directorycode, filefor: selectedTreeItem?.filefor
          , filename: event.event.affectedFiles[0].name
        },
        onvalidateSuccess.bind(this),
        onvalidateFailure.bind(this)
      );
    }
  };

  const onvalidateSuccess = (response: any) => {
    if (response.status) {
      if (addedfiles) {
        setFiles(addedfiles);
      }
    }
  }

  const onvalidateFailure = (response: any) => {

  }

  const handleClearFileList = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    if (event) {
      setFiles([]);
    }
  };

  const handleUploadComplete = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>, ishomeupload: boolean, click: string
  ) => {
    if (files && files.length > 0) {

      let fileduids = files.map(function (file) {
        return file['uid'];
      });

      if (!ishomeupload) {
        if (click !== "close") {
          let Audit = AuditTrailValidation(
            "IDS_MDL_ORDERS",
            "IDS_SCN_SHEETORDERS",
            "IDS_TSK_UPLOAD"
          );
          let passobject: any = {}
          passobject = {
            objmanualaudit: Manualauditobject("upload", "Sheet Orders"), "fileduids": fileduids
          }
          if (Audit) {
            ($('#auditTrailModalsheetview') as any).modal("show");
            setAuditservicecall({
              AuditUrl: "" + ELNurl + "/Instrument/Getaddedfilesforfolder",
              AuditObject: passobject,
              AuditPassfunction: onaddSuccess.bind(this),
              AuditFailfunction: onaddFailure.bind(this),
              AuditCancelfunction: Auditcancel.bind(this),
              Auditfilefunction: true
            })
          }
          else {
            if (click === "close") {
              Auditcancel();
            } else {
              Servicecalls("" + ELNurl + "/Instrument/Getaddedfilesforfolder", fileduids,
                onaddSuccess.bind(this), onaddFailure.bind(this));
            }
          }
        } else {
          Auditcancel();
        }
      } else if (ishomeupload) {
        setFiles([]);
      }
    }
  };

  const onaddSuccess = (response: any) => {
    props.alert.success(props.t("Orders.Sheetorders.IDS_LBL_FILEUPLOADSUCCESS", { param: "" }));
    ($('#auditTrailModalsheetview') as any).modal("hide")
    let passObjDet = {
      objsilentaudit: Silentauditobject(
        "upload",
        "Sheet Orders",
        props.t("Orders.Sheetorders.IDS_AUDIT_FOLDERFILEUPLOAD", {
          file: response[0].filename,
          user: store.getState().Loggeduser.username
        })
      ),
    };

    Servicecalls(
      "" + ELNurl + "/AuditTrail/silentandmanualRecordHandler",
      passObjDet,
      onAuditValidateError.bind(this),
      onAuditValidateFailure.bind(this)
    );
    setAuditservicecall({
      AuditUrl: "",
      AuditObject: {},
      AuditPassfunction: undefined,
      AuditFailfunction: undefined,
      AuditCancelfunction: undefined,
      Auditfilefunction: false,
    });
    const newData: DataModel | any = addDataItem(
      stateData,
      selectedTreeItem,
      response,
      intl
    );

    setStateData(newData);
    setFiles([]);


  }

  const onaddFailure = (response: any) => {
    ($('#auditTrailModalsheetview') as any).modal("hide")
    setFiles([]);
  }

  const handleBreadcrumbSelection = (event: any) => {
    setSelectedGridItem({});
    setGridSelection({});

    if (!event.nativeEvent.keyCode) {
      let returndateobj = setdates(filterType, isFiltered, "sheetdaydiff", toRange, changedtodate);
      //  let fromRange = returndateobj.fromRange;
      if (returndateobj !== undefined) {
        setfromRange(returndateobj.fromRange);
        settoRange(returndateobj.toRange);
      }
      if (event.target !== undefined) {

        sateorderdummy =
          stateData !== undefined && stateData !== null && stateData.length > 0
            ? stateData
            : initialorders !== null
              ? initialorders
              : [];
      }
      const newItem = searchTreeItemonpath(stateData, { path: event.id });
      setSelectedTreeItem(newItem);
      setSelectedTreeItemDuplicate(newItem);
      if (newItem === undefined) {

        let passObjDet1: any = {
          filetype: fileType,
          directorycode: -1,
          objuser: {
            fromdate: GetutcDate(new Date(returndateobj.fromRange)),
            todate: GetutcDate(new Date(returndateobj.toRange)),
          },
          filefor: "DR",
          createdby: store.getState().Loggeduser,
          lsuserMaster:
            store.getState().Loggeduser !== undefined &&
              store.getState().Loggeduser !== null &&
              Object.keys(store.getState().Loggeduser).length > 0
              ? store.getState().Loggeduser
              : {
                usercode: localStorage.getItem("User"),
                username: localStorage.getItem("Username"),
                unifieduserid: localStorage.getItem("unifieduserid"),
              }
        };
        passObjDet1.lstuserMaster = Getteamusersfromsession();

        setServicecall({
          ServiceToUrl: "" + ELNurl + "/Instrument/Getordersondirectory",
          ServiceHeaderobject: passObjDet1,
          ServiceMethodType: "POST",
          ServcieonSuccess: ongetordersuccesshome,
          ServcieonFailure: ongetordersfailure,
          ServiceTenant: undefined,
          Servicecontenttype: undefined,
        });
      }
    }
    if (event.nativeEvent.keyCode === 13) {
      const newItem = searchTreeItemonpath(stateData, { path: event.id });
      setSelectedTreeItem(newItem);
      setSelectedTreeItemDuplicate(newItem);
    }
  };


  const ongetfilessuccesshome = (response: any, passeddata: any) => {
    if (
      passeddata !== undefined &&
      passeddata?.directorycode !== undefined &&
      passeddata.filefor !== undefined
    ) {
      // setIsLoader(true);
      let directorycode = passeddata.directorycode;
      let itemdata = [];
      if (response !== undefined && response.length > 0) {
        itemdata = response;
      }
      let folderdata = sateorderdummy;

      let newdata = replaceallordertoFolderwithfilefor(
        folderdata,
        directorycode,
        intl,
        orderdataforfolder !== undefined && orderdataforfolder.length > 0 ? orderdataforfolder : [],
        passeddata.filefor,
        screen
      );
      newdata = addorderitemsforhomebutton(
        newdata,
        directorycode,
        intl,
        orderdataforfolder !== undefined && orderdataforfolder.length > 0 ? orderdataforfolder : [],
        passeddata.filefor,
        screen
      );
      newdata = addfileitemsforhomebutton(
        newdata,
        directorycode,
        intl,
        itemdata,
        passeddata.filefor,
        screen
      );

      setStateData(newdata);
      setsheetdatainitservice(false);
      setsheetdatainit(undefined)
      setIsLoader(false);
      sateorderdummy = [];
    } else {
      setIsLoader(false);
    }

  }

  const ongetordersuccesshome = (response: any, passeddata: any) => {
    if (
      passeddata !== undefined &&
      passeddata?.directorycode !== undefined
      &&
      passeddata.filefor !== undefined
    ) {
      response.forEach((item: any) => {
        if (item.orderflag.trim() === "N" && item.filetype === 0) {
          item.canuserprocess = true;
        } else if (item.lsprojectmaster === undefined && item.viewoption !== undefined && item.viewoption === 1) {
          item.canuserprocess = true;
        } else if (item.lsprojectmaster === undefined && item.viewoption !== undefined && response.viewoption === 2 && item.lsuserMaster?.usercode === parseInt(sessionStorage.User) || response.viewoption === 3) {
          item.canuserprocess = true;
        }
      });
      orderdataforfolder = response;
      setServicecall({
        ServiceToUrl: "" + ELNurl + "/Instrument/Getfilesforfolder",
        ServiceHeaderobject: {
          directorycode: -1, filefor: "DR",
          contentsearchtype: searchCriteriaType.value,
          contentsearch: SearchContentText.SearchContentText
          , ismultitenant: isMultitenant,
          orderflag: orderflag !== undefined && orderflag !== -1 && orderflag === 1 ? "N" : "R",
          approvelstatus: orderflag !== undefined && orderflag === 3 ? 3 : "",

          fromdate: GetutcDate(new Date(passeddata.objuser.fromdate)),
          todate: GetutcDate(new Date(passeddata.objuser.todate)),
        },

        ServiceMethodType: "POST",
        ServcieonSuccess: ongetfilessuccesshome,
        ServcieonFailure: ongetfilesfailure,
        ServiceTenant: undefined,
        Servicecontenttype: undefined,
      });
    }
  }

  const ondeletedirectorysuccess = (response: any, directories: any) => {
    console.log(directories)
    if (
      deleteitem === undefined ||
      deleteitem === null ||
      Object.keys(deleteitem).length <= 0 ||
      response === undefined ||
      response === null ||
      response.length <= 0
    )
      return;

    let deletedfolderindex = response.findIndex(
      (obj: { parentdircode: number }) => obj.parentdircode === -2
    );

    if (deletedfolderindex > -1) {
      const curItem = deleteitem[0];
      const movetodirectory = getDirectoryOncode(
        stateData,
        response[deletedfolderindex].dircodetomove
        //=== undefined ? response[deletedfolderindex].dircodetomove = -1 : response[deletedfolderindex].dircodetomove
      );

      let updatedData = removeDataItem(stateData, curItem);
      let statedataclone = stateData;

      for (let ind = 0; ind < response.length; ind++) {
        if (response[ind].parentdircode !== -2) {
          response[ind].icon = convertExtensionToIcon(
            response[ind].directoryname,
            null
          );

          let movedirectory = getDirectoryOncode(statedataclone, response[ind].parentdircode)
          let directorytomove = getDirectoryOncode(statedataclone, response[ind].directorycode);
          directorytomove.path = movedirectory.path;
          updatedData = addFolderonObj(
            updatedData,
            movedirectory,
            intl,
            directorytomove
          );

        } else {
          // updatedData = removeDataItem(updatedData, response[ind]);
        }
      }
      statedataclone = updatedData;
      setSelectedTreeItem(searchTreeItem(updatedData, movetodirectory));
      setSelectedTreeItemDuplicate(searchTreeItem(updatedData, movetodirectory));
      // setStateData(updatedData);
      // setSelectedFolders([]);
      sateorderdummy = updatedData;
      setDeleteDialogView(false);
      setIsMultiSelectFolders(false);
      let id = { "id": 'Sheet', "nativeEvent": { "try": "try" } };
      handleBreadcrumbSelection(id);
      // setPullcontents(true);
    }
  };

  const ondeletedirectoryfaliure = (response: any) => { };

  const ondeletemultidirectorysuccess = (response: any) => {
    if (
      deleteitem === undefined ||
      deleteitem === null ||
      Object.keys(deleteitem).length <= 0 ||
      response === undefined ||
      response === null ||
      response.length <= 0
    )
      return;

    let deletedfolderindex: any = [];
    response.map((resitem: any, index: any) => {

      if (resitem.parentdircode === -2) {
        deletedfolderindex.push(index)
      }

      return resitem;
    })

    // if (deletedfolderindex > -1) {
    let updatedData: any = [];

    let statedataclone: any = [];
    let movetodirectory: any = {};
    let newlyupdateddata: any = [];

    deletedfolderindex.forEach((folderitemindex: any) => {

      deleteitem.forEach((item: any) => {

        // const curItem = deleteitem;
        const curItem = item;
        movetodirectory = getDirectoryOncode(
          stateData,
          response[folderitemindex].dircodetomove
        );

        if (newlyupdateddata.length === 0) {
          updatedData = removeDataItem(stateData, curItem);
          statedataclone = stateData;
        }
        else {
          updatedData = removeDataItem(newlyupdateddata, curItem);
        }


        //  for (let ind = 0; ind < response.length; ind++) {
        if (response[folderitemindex].parentdircode !== -2) {
          response[folderitemindex].icon = convertExtensionToIcon(
            response[folderitemindex].directoryname,
            null
          );
          response[folderitemindex].dateCreated = convertDateFormat(
            convertUTCDateToLocalDate(new Date(response[folderitemindex].dateCreated)),
            intl
          );
          response[folderitemindex].dateModified = convertDateFormat(
            convertUTCDateToLocalDate(new Date(response[folderitemindex].dateModified)),
            intl
          );
          let movedirectory = getDirectoryOncode(statedataclone, response[folderitemindex].parentdircode)
          let directorytomove = getDirectoryOncode(statedataclone, response[folderitemindex].directorycode);
          directorytomove.path = movedirectory.path;
          updatedData = addFolderonObj(
            updatedData,
            movedirectory,
            intl,
            directorytomove
          );
          statedataclone = updatedData;
        } else {
          //  updatedData = removeDataItem(updatedData, response[ind]);
          newlyupdateddata = updatedData;
        }
        //   }
      })
    })
    setSelectedTreeItem(searchTreeItem(updatedData, movetodirectory));
    // setSelectedTreeItemDuplicate(searchTreeItem(updatedData, movetodirectory));
    // setStateData(updatedData);
    sateorderdummy = updatedData;
    // setSelectedFolders([]);
    setDeleteDialogView(false);
    setIsMultiSelectFolders(false);
    // setPullcontents(true);
    let id = { "id": 'Sheet', "nativeEvent": { "try": "try" } };
    handleBreadcrumbSelection(id);

    props.alert.success(props.t("Orders.Sheetorders.IDS_MULTIFOLDERDELETE"));

    //  } 
  };
  const handleDialogClick = (event: any) => {
    if (event.type === "cancel add") {
      setAddDialogVisible(false);
      let passObjDet = {
        objsilentaudit: Silentauditobject(
          "cancel",
          "Sheet Orders",
          props.t("Orders.Sheetorders.IDS_AUDIT_REQCANCELNEWFOLDER", {
            user: props.sessionvalues.username,
          })
        ),
      };
      Servicecalls(
        "" + ELNurl + "/AuditTrail/silentandmanualRecordHandler",
        passObjDet,
        onAuditValidateError.bind(this),
        onAuditValidateFailure.bind(this)
      );
    }
    if (event.type === "cancel rename") {
      setEditDialogView(false);
      let passObjDet = {
        objsilentaudit: Silentauditobject(
          "cancel",
          "Sheet Orders",
          props.t("Orders.Sheetorders.IDS_AUDIT_REQCANCELRENAME", {
            user: props.sessionvalues.username,
          })
        ),
      };
      Servicecalls(
        "" + ELNurl + "/AuditTrail/silentandmanualRecordHandler",
        passObjDet,
        onAuditValidateError.bind(this),
        onAuditValidateFailure.bind(this)
      );
    }
    if (event.type === "cancel delete") {
      setDeleteDialogView(false);
      let passObjDet = {
        objsilentaudit: Silentauditobject(
          "cancel",
          "Sheet Orders",
          props.t("Orders.Sheetorders.IDS_AUDIT_REQCANCELDELETE", {
            user: props.sessionvalues.username,
          })
        ),
      };
      Servicecalls(
        "" + ELNurl + "/AuditTrail/silentandmanualRecordHandler",
        passObjDet,
        onAuditValidateError.bind(this),
        onAuditValidateFailure.bind(this)
      );
    }
    if (event.type === "cancel") {
      setMoveDialogView(false);
    }

    const curItem: DataModel | null = Object.keys(selectedGridItem).length
      ? selectedGridItem
      : selectedTreeItem;
    if (!curItem) {
      return;
    }

    if (event.type === "delete" && isMultiSelectFolders === false) {
      let movedircode = event.directoryToMove;

      let movestatus = event.movestatus
      // let res = !isNaN(+moveparent)
      // if(res){
      //   moveparent = moveparent === 1 ? false:true;
      // }

      if (event.dynamicfolders.length === 0 && movestatus === 0 && event.directoryToDelete.parentdircode === -1) {
        let directorytomove = getDirectoryOncode(stateData, movedircode);

        let data = getAllFolderFromItem(
          [event.directoryToDelete],
          event.directoryToDelete,
          movedircode,
          event.keepfolderstructure,
          directorytomove
        );
        deleteitem = [curItem];
        let directories = data.directries;

        if (directories[0].dircodetomove === undefined) {
          directories[0].dircodetomove = -1
        }
        Servicecalls(
          "" + ELNurl + "/Instrument/Deletedirectories",
          directories,
          ondeletedirectorysuccess.bind(this, directories),
          ondeletedirectoryfaliure
        );
        props.alert.success(props.t("Orders.Sheetorders.IDS_MSG_FOLDERDELETE"));
        let passObjDet = {
          objsilentaudit: Silentauditobject(
            "delete",
            "Sheet Orders",
            props.t("Orders.Sheetorders.IDS_AUDIT_REQFOLDERDELETED", {
              user: props.sessionvalues.username,
              folder: curItem.directoryname,
            })
          ),
        };
        Servicecalls(
          "" + ELNurl + "/AuditTrail/silentandmanualRecordHandler",
          passObjDet,
          onAuditValidateError.bind(this),
          onAuditValidateFailure.bind(this)
        );
      } else if (event.dynamicfolders.length !== 0 && movestatus === 1 && movedircode === -1) {
        //  && moveparent === false){
        props.alert.error(props.t("select folder to move"));
      }
      else if ((event.dynamicfolders.length !== 0 && movestatus === 0) || (event.dynamicfolders.length !== 0 && movestatus === 1 && movedircode !== -1)) {
        // if (event.selectedItem.parentdircode === -1 && movestatus === 0) {
        //   movedircode = -1;
        // } else if (movestatus === 1 && event.selectedItem.parentdircode === -1) {
        //   movedircode = movedircode;
        // } else if (event.selectedItem.parentdircode !== -1 && movestatus === 0) {
        //   movedircode = event.selectedItem.parentdircode;
        // } else {
        //   movedircode = movedircode;
        // }

        let directorytomove = getDirectoryOncode(stateData, movedircode);

        let data = getAllFolderFromItem(
          [event.directoryToDelete],
          event.directoryToDelete,
          movedircode,
          event.keepfolderstructure,
          directorytomove
        );
        deleteitem = [curItem];
        let directories = data.directries;

        if (event.selectedItem.parentdircode === -1 && movestatus === 0) {
          directories[0].dircodetomove = -1
        } else if (movestatus === 1 && event.selectedItem.parentdircode === -1) {
          directories[0].dircodetomove = movedircode;
        } else if (event.selectedItem.parentdircode !== -1 && movestatus === 0) {
          directories[0].dircodetomove = event.selectedItem.parentdircode;
        } else {
          directories[0].dircodetomove = movedircode;
        }
        let passobj = JSON.parse(JSON.stringify(directories));
        if (directories.length > 0) {
          directories = directories.map((items: any) => {
            items.dateCreated = undefined;
            items.dateModified = undefined;
            return items;
          });
        }
        Servicecalls(
          "" + ELNurl + "/Instrument/Deletedirectories",
          directories,
          ondeletedirectorysuccess.bind(this, passobj),
          ondeletedirectoryfaliure
        );
        props.alert.success(props.t("Orders.Sheetorders.IDS_MSG_FOLDERDELETE"));
        let passObjDet = {
          objsilentaudit: Silentauditobject(
            "delete",
            "Sheet Orders",
            props.t("Orders.Sheetorders.IDS_AUDIT_REQFOLDERDELETED", {
              user: props.sessionvalues.username,
              folder: curItem.directoryname,
            })
          ),
        };
        Servicecalls(
          "" + ELNurl + "/AuditTrail/silentandmanualRecordHandler",
          passObjDet,
          onAuditValidateError.bind(this),
          onAuditValidateFailure.bind(this)
        );
      }
    }

    if (event.type === "delete" && isMultiSelectFolders === true) {

      let movedircode = event.directoryToMove;
      let directorytomove = getDirectoryOncode(stateData, movedircode);
      let data = getAllFolderFromMultiItem(
        // [event.directoryToDelete],
        // event.directoryToDelete,
        selectedFolders,
        selectedFolders,
        movedircode,
        event.keepfolderstructure,
        directorytomove
      );
      deleteitem = selectedFolders;
      let directories = data.directries;

      Servicecalls(
        "" + ELNurl + "/Instrument/Deletemultidirectories",
        directories,
        ondeletemultidirectorysuccess,
        ondeletedirectoryfaliure
      );
    }


    if (event.type === "rename") {
      const folderPath = getFolderPath(event.path);
      const newPath = `${folderPath}/${event.value}`;
      let currentItem = { ...curItem };
      currentItem.path = newPath;
      const existingItem = validateTreeItem(stateData, currentItem);
      if (!existingItem) {
        const updatedData = editDataItem(stateData, curItem, newPath, intl);
        setSelectedTreeItem(searchTreeItem(updatedData, { path: newPath }));
        setSelectedTreeItemDuplicate(searchTreeItem(updatedData, { path: newPath }));
        setStateData(updatedData);
        //  }
        let directory = {
          directoryname: event.value,
          directorycode: curItem.directorycode,
          parentdircode: curItem.parentdircode,
          path: newPath,
        };
        Servicecalls(
          "" + ELNurl + "/Instrument/Movedirectory",
          directory,
          (response: any) => {
            let passObjDet = {
              objsilentaudit: Silentauditobject(
                "update",
                "Sheet Orders",
                props.t("Orders.Sheetorders.IDS_AUDIT_REQCHANGEFOLDERNAME", {
                  user: props.sessionvalues.username,
                  from: curItem.directoryname,
                  to: event.value,
                })
              ),
            };
            Servicecalls(
              "" + ELNurl + "/AuditTrail/silentandmanualRecordHandler",
              passObjDet,
              onAuditValidateError.bind(this),
              onAuditValidateFailure.bind(this)
            );
            props.alert.success(
              props.t("Orders.Sheetorders.IDS_MSG_FOLDERRENAME")
            );
          },
          (response: any) => { }
        );
      }
      else {
        props.alert.error(props.t("Orders.Sheetorders.IDS_MSG_FOLDEREXIST"));
      }
      setEditDialogView(false);
    }

    if (event.type === "add") {
      setAddDialogVisible(false);
      handleNewFolderClick(event);
    }
  };

  const onMoveItemSuccess = (response: any, event: any) => {
    let directorycode = response.directorycode;
    let directoryname = response.directoryname;
    let selecteditem =
      response.batchcode !== undefined
        ? getDirectoryOnName(stateData, directoryname)
        : getDirectoryOncode(stateData, directorycode);
    let selectedfolder = selectedTreeItem;

    selectedfolder =
      response.batchcode !== undefined
        ? getDirectoryOncode(stateData, directorycode)
        : getDirectoryOncode(stateData, response.parentdircode);

    selecteditem.directoryname = response.directoryname;
    let newData = removeItem(stateData, selecteditem);

    if (selectedfolder) {
      if (selecteditem.order !== undefined) {
        selecteditem.path = `${selectedfolder.path}/${selecteditem.directoryname + ".ord"
          }`;
      } else {
        selecteditem.path = `${selectedfolder.path}/${selecteditem.directoryname}`;
      }
      newData = response.directoryname ? addFolderonObj(newData, selectedfolder, intl, selecteditem) : newData;
      const newTreeItem = searchTreeItem(newData, selectedfolder);
      setSelectedTreeItem(newTreeItem);
      setSelectedTreeItemDuplicate(newTreeItem);
    } else {
      newData = addFolderonObj(newData, null, intl, selecteditem);
    }

    let passObjDet = {};
    setStateData(stateData => Object.assign([], stateData, newData));
    if (selecteditem.directoryname !== undefined) {
      passObjDet = {
        objsilentaudit: Silentauditobject(
          "update",
          "Sheet Orders",
          props.t("Orders.Sheetorders.IDS_AUDIT_REQFOLDERMOVED", {
            user: props.sessionvalues.username,
            folder: selecteditem.directoryname,
            folder2: selectedfolder?.directoryname,
          })
        ),
      };
      Servicecalls(
        "" + ELNurl + "/AuditTrail/silentandmanualRecordHandler",
        passObjDet,
        onAuditValidateError.bind(this),
        onAuditValidateFailure.bind(this)
      );

    }
    else {
      passObjDet = {
        objsilentaudit: Silentauditobject(
          "update",
          "Sheet Orders",
          props.t("Orders.Sheetorders.IDS_AUDIT_ORDERMOVED", {

            orderid: response.batchid,
            folderselect: selectedfolder?.directoryname,
            fromfolder: selectedTreeItem?.directoryname,
            user: store.getState().Loggeduser.username,

          })
        ),
      };
      Servicecalls(
        "" + ELNurl + "/AuditTrail/silentandmanualRecordHandler",
        passObjDet,
        onAuditValidateError.bind(this),
        onAuditValidateFailure.bind(this)
      );

    }

    setPullcontents(true);
    setMoveDialogView(false);
    // setTimeout(() => {
    //   getordersforfolders(selectedfolder, selectedfolder, fromRange, toRange, fileType,
    //     searchCriteriaType, orderflag);
    // }, 1000);


  };


  const onMoveFileSuccess = (response: any) => {
    let directorycode = response.directorycode;
    let selecteditem =
      response.folderfilecode !== undefined
        ? getDirectoryOnName(stateData, response.filename)
        : getDirectoryOncode(stateData, directorycode);
    let selectedfolder =
      response.folderfilecode !== undefined
        ? getDirectoryOncode(stateData, directorycode)
        : getDirectoryOncode(stateData, response.parentdircode);

    selecteditem.directoryname = response.filename;
    let newData = removeItem(stateData, selecteditem);

    if (selectedfolder) {

      selecteditem.path = `${selectedfolder.path}/${selecteditem.directoryname}`;
      newData = addFolderonObj(newData, selectedfolder, intl, selecteditem);
      const newTreeItem = searchTreeItem(newData, selectedfolder);
      setSelectedTreeItem(newTreeItem);
      setSelectedTreeItemDuplicate(newTreeItem);
    } else {
      newData = addFolderonObj(newData, null, intl, selecteditem);
    }
    setStateData(newData);
    let passObjDet = {
      objsilentaudit: Silentauditobject(
        "update",
        "Sheet Orders",
        props.t("Orders.Sheetorders.IDS_AUDIT_REQFOLDERMOVED", {
          user: props.sessionvalues.username,
          folder: selecteditem.directoryname,
          folder2: selectedfolder.directoryname,
        })
      ),
    };
    Servicecalls(
      "" + ELNurl + "/AuditTrail/silentandmanualRecordHandler",
      passObjDet,
      onAuditValidateError.bind(this),
      onAuditValidateFailure.bind(this)
    );
    props.alert.success(props.t("Orders.Sheetorders.IDS_MSG_FOLDERMOVE"));
    setMoveDialogView(false);
  };

  const onMultiMoveItemSuccess = (response: any) => {
    ($('#auditTrailModalsheetview') as any).modal('hide');
    setMoveDialogView(false);

    setAuditservicecall({
      AuditUrl: "",
      AuditObject: {},
      AuditPassfunction: undefined,
      AuditFailfunction: undefined,
      AuditCancelfunction: undefined,
      Auditfilefunction: false,
    })
    if (response === undefined || response.length <= 0) return;
    let directorycode = response[0].directorycode;
    let newData = stateData;
    let selectedfolder = selectedTreeItem;
    for (let ind = 0; ind < response.length; ind++) {
      // let batchid = getsheetitemnameonscreen(response[ind]);
      let batchid = response[ind].batchid;
      let selecteditem = getDirectoryOnName(newData, batchid);
      selectedfolder = getDirectoryOncode(newData, directorycode);

      newData = removeItem(newData, selecteditem);

      if (selectedfolder) {
        selecteditem.path = `${selectedfolder.path}/${selecteditem.directoryname + ".ord"
          }`;
        newData = addFolderonObj(newData, selectedfolder, intl, selecteditem);
        const newTreeItem = searchTreeItem(newData, selectedfolder);
        setSelectedTreeItem(newTreeItem);
        setSelectedTreeItemDuplicate(newTreeItem);
      } else {
        newData = addFolderonObj(newData, null, intl, selecteditem);
      }
    }

    setStateData(newData);

    setMoveDialogView(false);
    setIsMultiSelectOrder(false);
    setSelectedOrders([]);
    setPullcontents(true);
    let passObjDet = {
      objsilentaudit: Silentauditobject(
        "Move",
        "Sheet Orders",
        props.t("Orders.Sheetorders.IDS_AUDIT_ORDERMOVED", {
          orderid: response[0].batchid,
          folderselect: selectedfolder?.directoryname,
          fromfolder: selectedTreeItem?.directoryname,
          user: store.getState().Loggeduser.username,

        })
      ),

    };
    Servicecalls(
      "" + ELNurl + "/AuditTrail/silentandmanualRecordHandler",
      passObjDet,
      onAuditValidateError.bind(this),
      onAuditValidateFailure.bind(this)
    );

  };

  const onMoveItemFailure = (response: any) => { };

  const onMultiMoveFileSuccess = (response: any) => {
    if (response === undefined || response.length <= 0) return;
    let directorycode = response[0].directorycode;
    let newData = stateData;
    let selectedfolder = selectedTreeItem;
    for (let ind = 0; ind < response.length; ind++) {
      let selecteditem = getDirectoryOnName(newData, response[ind].filename);
      selectedfolder = getDirectoryOncode(newData, directorycode);

      newData = removeItem(newData, selecteditem);

      if (selectedfolder) {
        selecteditem.path = `${selectedfolder.path}/${selecteditem.directoryname}`;
        newData = addFolderonObj(newData, selectedfolder, intl, selecteditem);
        const newTreeItem = searchTreeItem(newData, selectedfolder);
        setSelectedTreeItem(newTreeItem);
        setSelectedTreeItemDuplicate(newTreeItem);
        let passObjDet = {
          objsilentaudit: Silentauditobject(
            "Move",
            "Sheet Orders",
            props.t("Orders.Sheetorders.IDS_AUDIT_FILEMOVED", {
              user: props.sessionvalues.username,
              filename: response[ind].filename,
              folder: selectedfolder?.directoryname
            })
          ),
        };

        Servicecalls(
          "" + ELNurl + "/AuditTrail/silentandmanualRecordHandler",
          passObjDet,
          onAuditValidateError.bind(this),
          onAuditValidateFailure.bind(this)
        );
      } else {
        newData = addFolderonObj(newData, null, intl, selecteditem);
      }
    }

    setStateData(newData);

    setMoveDialogView(false);
    setIsMultiSelectFile(false);
    setSelectedFiles([]);
    setPullcontents(true);

  }

  const onMoveFileFailure = (response: any) => { };

  const onMultiMoveFolderSuccess = (response: any) => {

    if (response === undefined || response.length <= 0) return;
    for (let ind = 0; ind < response.length; ind++) {
      let movetofolder = getDirectoryOncode(stateData, response[0].parentdircode);
      let newData = Movemultifolders(stateData, response, movetofolder);
      movetofolder = getDirectoryOncode(newData, response[0].parentdircode);

      setStateData(newData);
      setSelectedTreeItem(movetofolder);
      setSelectedTreeItemDuplicate(movetofolder);
      setMoveDialogView(false);
      setIsMultiSelectFolders(false);
      setSelectedFolders([]);

      let passObjDet = {
        objsilentaudit: Silentauditobject(
          "Move",
          "Sheet Orders",
          props.t("Orders.Sheetorders.IDS_AUDIT_SHEETFOLDERMOVED", {
            user: props.sessionvalues.username,
            folder1: response[ind].directoryname,
            folder2: movetofolder.directoryname
          })
        ),
      };

      Servicecalls(
        "" + ELNurl + "/AuditTrail/silentandmanualRecordHandler",
        passObjDet,
        onAuditValidateError.bind(this),
        onAuditValidateFailure.bind(this)
      );
    }
  };

  const onMoveFolderFailure = (response: any) => { };

  const handlemoveDialogClick = (event: any) => {
    if (event.selectedfolder === undefined) {
      event.selectedfolder = { directorycode: -1, path: "Sheet" };
    }

    if (event.selectedfolder === null) {
      props.alert.error(props.t("Orders.Sheetorders.IDS_MSG_FOLDERSELECT"));
    }
    else {
      let selectedfolder = event.selectedfolder;
      let selecteditem = event.selecteditem;

      if (selectedfolder.directorycode !== selecteditem.parentdircode) {
        let passData = {
          directoryname: selecteditem.directoryname,
          parentdircode: selectedfolder.directorycode,
        };

        Servicecalls(
          "" + ELNurl + "/Instrument/getMoveDirectory",
          passData,
          (response: any) => {
            selecteditem.directoryname = response.directoryname;
            if (
              isMultiSelectOrder &&
              selectedOrders !== undefined &&
              selectedOrders.length > 0
            ) {
              let orders: any = selectedOrders.map((obj) => {
                obj.directorycode = selectedfolder.directorycode;
                obj.objmanualaudit = Manualauditobject("Move", "Sheet Orders");
                return obj;
              });

              let Audit = AuditTrailValidation(
                "IDS_MDL_ORDERS",
                "IDS_SCN_SHEETORDERS",
                "IDS_TSK_MOVEORDER"
              );
              if (Audit) {

                ($('#auditTrailModalsheetview') as any).modal('show');
                setAuditservicecall({
                  AuditUrl: "" + ELNurl + "/Instrument/UpdateFolderfororders",
                  AuditObject: orders,
                  AuditPassfunction: onMultiMoveItemSuccess.bind(this),
                  AuditFailfunction: onMoveItemFailure.bind(this),

                });
              }
              else {
                Servicecalls(
                  "" + ELNurl + "/Instrument/UpdateFolderfororders",
                  orders,
                  onMultiMoveItemSuccess,
                  onMoveItemFailure
                );
              }

            } else if (
              isMultiSelectFile &&
              selectedFiles !== undefined &&
              selectedFiles.length > 0
            ) {
              let files = selectedFiles.map((obj) => {
                obj.directorycode = selectedfolder.directorycode;
                return obj;
              });
              Servicecalls(
                "" + ELNurl + "/Instrument/UpdateFolderforfiles",
                files,
                onMultiMoveFileSuccess,
                onMoveFileFailure
              );
            } else if (
              isMultiSelectFolders &&
              selectedFolders !== undefined &&
              selectedFolders.length > 0
            ) {
              let folders = selectedFolders.map((obj) => {
                return {
                  path: selectedfolder.path + "/" + obj.directoryname,
                  parentdircode: selectedfolder.directorycode,
                  directorycode: obj.directorycode
                };
              });

              Servicecalls(
                "" + ELNurl + "/Instrument/Updateparentforfolder",
                folders,
                onMultiMoveFolderSuccess,
                onMoveFolderFailure
              );
            }


            else {
              if (selecteditem.order !== undefined) {
                let order = selecteditem !== undefined ? selecteditem.order : {};

                order.directorycode = selectedfolder.directorycode;

                Servicecalls(
                  "" + ELNurl + "/Instrument/UpdateFolderfororder",
                  order,
                  onMoveItemSuccess,
                  onMoveItemFailure
                );
              }
              else if (selecteditem.file !== undefined) {
                let order = selecteditem !== undefined ? selecteditem.file : {};

                order.directorycode = selectedfolder.directorycode;

                Servicecalls(
                  "" + ELNurl + "/Instrument/UpdateFolderforfile",
                  order,
                  onMoveFileSuccess,
                  onMoveFileFailure
                );
              } else {
                let currentpath = `${selectedfolder.path}/${selecteditem.directoryname}`;
                let directory = {
                  directoryname: selecteditem.directoryname,
                  directorycode: selecteditem.directorycode,
                  parentdircode: selectedfolder.directorycode,
                  path: currentpath,
                };
                Servicecalls(
                  "" + ELNurl + "/Instrument/Movedirectory",
                  directory,
                  onMoveItemSuccess,
                  onMoveItemFailure
                );
              }
            }
          },
          (response: any) => { }
        );
      } else {
        props.alert.error(props.t("Orders.Sheetorders.IDS_MSG_FOLDERNOTMOVE"));
      }

    }
  };

  const handleListItemClick = (event: any) => {
    let newSelectedGridItem = { selected: true, [event.dataItem.path]: true };
    if (event.dataItem.path) {
      newSelectedGridItem = event.dataItem;
      newSelectedGridItem[event.dataItem.path] = true;
      newSelectedGridItem.selected = true;

      setSelectedGridItem(newSelectedGridItem);

      if (event.dataItem.path) {
        const key = event.dataItem.path.toString();
        setGridSelection({ [key]: true });
        //   setSelectedTreeItem(newSelectedGridItem);
      }
    }

    if (event.tree) {
      const newSelectedTreeItem: DataModel = searchTreeItem(
        stateData,
        newSelectedGridItem
      );
      setSelectedTreeItem(newSelectedTreeItem);
      setSelectedTreeItemDuplicate(newSelectedTreeItem);
    }
    updateFileDetailsData(newSelectedGridItem);

    let directorycode = event.dataItem.directorycode;

    if (directorycode !== undefined && event.dataItem.filefor !== undefined && event.dataItem.filefor === "DR") {
      Servicecalls(
        "" + ELNurl + "/Instrument/Getordersondirectory",
        {
          directorycode: directorycode,
          objuser: {
            fromdate: GetutcDate(new Date(fromRange)),
            todate: GetutcDate(new Date(toRange)),
          },
          lsuserMaster:
            store.getState().Loggeduser !== undefined &&
              store.getState().Loggeduser !== null &&
              Object.keys(store.getState().Loggeduser).length > 0
              ? store.getState().Loggeduser
              : {
                usercode: localStorage.getItem("User"),
                username: localStorage.getItem("Username"),
                unifieduserid: localStorage.getItem("unifieduserid"),
              },
          lstuserMaster: Getteamusersfromsession()
        },
        ongetorderssuccess,
        ongetordersfailure
      );
    }
  };

  const handleOrderopenClick = (event: any, item: any) => {

    if (item.filecode && item.filecode === -1 && item.filetype === 0) {
      let passObj = {
        testcode: item.testcode,
        testtype: 0,
        objLoggeduser: props.sessionvalues.usercode !== undefined &&
          props.sessionvalues.username !== undefined &&
          props.sessionvalues.usercode !== null &&
          props.sessionvalues.username !== null
          ? {
            usercode: props.sessionvalues.usercode,
            username: props.sessionvalues.username,
          }
          : false,
        ismultitenant: isMultitenant,
        lssitemaster: {
          sitecode: sessionStorage.Usersite,
          sitename: sessionStorage.Usersitename,
        }

      };
      Servicecalls(
        "" + ELNurl + "/File/GetfilesOnTestcode",
        passObj,
        Formatrecordfortreevie,
        (res: any) => { }
      );

    } else {
      // if (item.approved === 1) {
      //   let passObjDet = {
      //     objsilentaudit: Silentauditobject(
      //       "open",
      //       "Sheet Orders",
      //       props.t("Orders.Sheetorders.IDS_AUDIT_SHEETORDEROPEN", {
      //         batchid: item.batchid,
      //         screen: ordertypename,
      //         Processed: item.canuserprocess && item.approved === 1 ? "Opened" : "Processed",
      //         user: sessionStorage.Username
      //       }),
      //     ),
      //   };
      //   Servicecalls(
      //     "" + ELNurl + "/AuditTrail/silentandmanualRecordHandler",
      //     passObjDet,
      //     onAuditValidateError.bind(this),
      //     onAuditValidateFailure.bind(this)
      //   );
      // }
      // else {
      //   let passObjDet = {
      //     objsilentaudit: Silentauditobject(
      //       "process order",
      //       "Sheet Orders",
      //       props.t("Orders.Sheetorders.IDS_AUDIT_PROCESSSHEETVIEW", {
      //         batchid: item.batchid,
      //         screen: ordertypename,
      //         Processed: item.canuserprocess && item.approved === 1 ? "Opened" : "Processed",
      //         user: sessionStorage.Username
      //       }),
      //     ),
      //   };
      //   Servicecalls(
      //     "" + ELNurl + "/AuditTrail/silentandmanualRecordHandler",
      //     passObjDet,
      //     onAuditValidateError.bind(this),
      //     onAuditValidateFailure.bind(this)
      //   );
      // }
      updateOrderStatusCheck(item.batchcode, store.getState().Loggeduser.usercode);

      recordorderopenhistory(item);

    }
    // item.lstproject = projects;
    // item.lstworkflow = workflow;
    setOpenedorder(item);
  };

  const recordorderopenhistory = (item: any) => {
    let passobj: any = {
      // protocolorderstepcode: this.state.protocolToBeEdited.protocolorderstepcode,
      batchcode: item.batchcode,
      createby: { usercode: sessionStorage.User },
      stepstartdate: GetutcDate(new Date()),
      action: props.t("Templates.Protocoltemplates.IDS_LBL_PROCESSORDER", {
        batchid: item.batchid,
        Processed: item.canuserprocess && item.approvelstatus === undefined ? "Processed" : "Opened",
        user2: sessionStorage.Username
      }),
      viewoption: 1
    }
    let Audit = AuditTrailValidation(
      "IDS_MDL_ORDERS",
      "IDS_SCN_SHEETORDERS",
      "IDS_TSK_PROCESSORDER"
    );
    if (Audit) {
      ($('#auditTrailModalsheetview') as any).modal("show")
      if ((item.approvelstatus !== undefined) && (item.approvelstatus === 1 || item.approvelstatus === 3)) {
        passobj.objmanualaudit = Manualauditobject("Open", "Sheet Order")

      }
      else {
        passobj.objmanualaudit = Manualauditobject("process order", "Sheet Order")

      }
      setAuditservicecall({
        AuditUrl: "" + ELNurl + "/protocol/updatetransactionhistory",
        AuditObject: passobj,
        AuditPassfunction:
          (res: any) => {
            setSheetUnmapped(false);
            item.lstproject = projects;
            item.lstworkflow = workflow;
            setOpenedorder(item);
            setIsorderopen(true);
            ($('#auditTrailModalsheetview') as any).modal("hide")

            if (item.approved === 1) {
              let passObjDet = {
                objsilentaudit: Silentauditobject(
                  "open",
                  "Sheet Orders",
                  props.t("Orders.Sheetorders.IDS_AUDIT_PROCESSSHEETVIEW", {
                    batchid: item.batchid,
                    screen: orderType(item.filetype),
                    Processed: (item.canuserprocess && item.approvelstatus !== undefined) && (item.approvelstatus === 1 || item.approvelstatus === 3) ? "Opened" : "Processed",
                    user: sessionStorage.Username
                  }),
                ),
              };
              Servicecalls(
                "" + ELNurl + "/AuditTrail/silentandmanualRecordHandler",
                passObjDet,
                onAuditValidateError.bind(this),
                onAuditValidateFailure.bind(this)
              );
            }
            else {
              let passObjDet = {
                objsilentaudit: Silentauditobject(
                  "process order",
                  "Sheet Orders",
                  props.t("Orders.Sheetorders.IDS_AUDIT_PROCESSSHEETVIEW", {
                    batchid: item.batchid,
                    screen: orderType(item.filetype),
                    Processed: (item.canuserprocess && item.approvelstatus !== undefined) && (item.approvelstatus === 1 || item.approvelstatus === 3) ? "Opened" : "Processed",
                    user: sessionStorage.Username
                  }),
                ),
              };
              Servicecalls(
                "" + ELNurl + "/AuditTrail/silentandmanualRecordHandler",
                passObjDet,
                onAuditValidateError.bind(this),
                onAuditValidateFailure.bind(this)
              );
            }
          },
        AuditFailfunction: ongetordersfailure.bind(this),
      });
    }
    else {
      setSheetUnmapped(false);
      item.lstproject = projects;
      item.lstworkflow = workflow;
      setOpenedorder(item);
      setIsorderopen(true);
      Servicecalls(
        "" + ELNurl + "/protocol/updatetransactionhistory",
        passobj,
        //ongetordersfailure,
        processsuccess.bind(this, item),
        ongetordersfailure
      );

    }
  }

  const processsuccess = (item: any, res: any) => {

    // setSheetUnmapped(false);
    // item.lstproject = projects;
    // item.lstworkflow = workflow;
    // setOpenedorder(item);
    // setIsorderopen(true);
    // ($('#auditTrailModalsheetview') as any).modal("hide")

    if ((item.approvelstatus !== undefined) && (item.approvelstatus === 1 || item.approvelstatus === 3)) {
      let passObjDet = {
        objsilentaudit: Silentauditobject(
          "open",
          "Sheet Orders",
          props.t("Orders.Sheetorders.IDS_AUDIT_PROCESSSHEETVIEW", {
            batchid: item.batchid,
            screen: orderType(item.filetype),
            Processed: (item.canuserprocess && item.approvelstatus !== undefined) && (item.approvelstatus === 1 || item.approvelstatus === 3) ? "Opened" : "Processed",
            user: sessionStorage.Username
          }),
        ),
      };
      Servicecalls(
        "" + ELNurl + "/AuditTrail/silentandmanualRecordHandler",
        passObjDet,
        onAuditValidateError.bind(this),
        onAuditValidateFailure.bind(this)
      );
    }
    else {
      let passObjDet = {
        objsilentaudit: Silentauditobject(
          "process order",
          "Sheet Orders",
          props.t("Orders.Sheetorders.IDS_AUDIT_PROCESSSHEETVIEW", {
            batchid: item.batchid,
            screen: orderType(item.filetype),
            Processed: (item.canuserprocess && item.approvelstatus !== undefined) && (item.approvelstatus === 1 || item.approvelstatus === 3) ? "Opened" : "Processed",
            user: sessionStorage.Username
          }),
        ),
      };
      Servicecalls(
        "" + ELNurl + "/AuditTrail/silentandmanualRecordHandler",
        passObjDet,
        onAuditValidateError.bind(this),
        onAuditValidateFailure.bind(this)
      );
    }

  }
  const handleContextMenuView = (event: ContextMenuEvent) => {
    setMenuOffSet({ left: event.event.clientX, top: event.event.clientY });
    if (
      event.dataItem !== undefined &&
      event.dataItem !== null &&
      ((event.dataItem.filefor !== undefined &&
        event.dataItem.filefor === "DR") ||
        event.dataItem.order !== undefined)
    ) {
      if (event.dataItem.order !== undefined && event.dataItem.order.ordercancell !== undefined) {
        setIsMenuContext(false);
        setContextMenuView(false);
      } else {
        setContextMenuView(true);

        setIsMenuContext(false);
        handleListItemClick(event);
      }


    }
    else if (
      event.dataItem !== undefined &&
      event.dataItem !== null &&
      ((event.dataItem.filefor !== undefined) ||
        event.dataItem.file !== undefined)
    ) {
      setContextMenuView(true);

      setIsMenuContext(false);
      handleListItemClick(event);
    } else {
      setIsMenuContext(true);
    }
  };

  const handleContextMenuClick = (event: ContextMenuEvent, item: any) => {
    if (item !== undefined && item.order !== undefined) {
      if (event.item && event.item.data && event.item.data.key && event.item.data.key === "openorder") {
        recordorderopenhistory(item.order)
        item.order.lstproject = projects;
        item.order.lstworkflow = workflow;

      } else if (event.item && event.item.data && event.item.data.key && event.item.data.key === "moveorder") {
        setMoveDialogView(true);
      }
    }
    else if (item !== undefined && item.file !== undefined) {
      if (event.item && event.item.data && event.item.data.key && event.item.data.key === "movefiles") {
        setMoveDialogView(true);
      } else if (event.item && event.item.data && event.item.data.key && event.item.data.key === "deletefiles") {
        ondeletefile(item.file);
      }
    } else if (item !== undefined && item.menuselected) {
      if (event.item && event.item.data && event.item.data.key && event.item.data.key === "selectmultiorders") {
        setIsMultiSelectOrder(true);
        setSelectedOrders([]);
      }
      else if (event.item && event.item.data && event.item.data.key && event.item.data.key === "selectmultifiles") {
        setIsMultiSelectFile(true);
        setSelectedFiles([]);
      }
      else if (event.item && event.item.data && event.item.data.key && event.item.data.key === "selectmultifolders") {
        setIsMultiSelectFolders(true);
        setSelectedFolders([]);
      }
    } else {
      if (event.item && event.item.data && event.item.data.key && event.item.data.key === "createfolder") {
        setAddDialogVisible(true);
      } else if (event.item && event.item.data && event.item.data.key && event.item.data.key === "renamefolder") {
        setEditDialogView(true);
      } else if (event.item && event.item.data && event.item.data.key && event.item.data.key === "deletefolder") {
        if (
          selectedTreeItem !== undefined &&
          selectedTreeItem !== null &&
          selectedTreeItem.parentdircode === -1
        ) {
          let issingleparent = stateData.findIndex(
            (obj) =>
              obj.parentdircode === -1 &&
              obj.directorycode !== selectedTreeItem?.directorycode
          );
          if (issingleparent <= -1) {
            props.alert.error("Orders.Sheetorders.IDS_MSG_HAVEONEDIRECTORY");
          } else {
            setDeleteDialogView(true);
          }
        } else {
          setDeleteDialogView(true);
        }
      } else if (event.item && event.item.data && event.item.data.key && event.item.data.key === "movefolder") {
        setMoveDialogView(true);
      }
    }
  };

  const handleDialogClose = () => {
    setDeleteDialogView(false);
    setEditDialogView(false);
    setMoveDialogView(false);
    setAddDialogVisible(false);
  };

  const handleCloseMultiselectClick = (event: any) => {
    setIsMultiSelectOrder(false);
    setIsMultiSelectFile(false);
    setIsMultiSelectFolders(false);
    setSelectedOrders([]);
    setSelectedFiles([]);
    setSelectedFolders([]);
  };

  const handleMoveOnMultiselect = (event: any) => {
    console.log(selectedFiles)
    if ((selectedFiles && selectedFiles.length > 0) || (selectedOrders && selectedOrders.length > 0)
      || (selectedFolders && selectedFolders.length > 0)) {
      setMoveDialogView(true);
    }
    else {
      props.alert.error(props.t("Orders.Sheetorders.IDS_MSG_FILESELECT"));
    }
  };

  const handleDeleteOnMultiselect = (event: any) => {
    //  ondeletefile();
    console.log(selectedFiles)

    if (isMultiSelectFile && selectedFiles && selectedFiles.length) {
      ondeletemultifile(selectedFiles);
    }

    else if (isMultiSelectFolders && selectedFolders && selectedFolders.length) {
      ondeletemultifolder(selectedFolders)
    }
    else {
      props.alert.error(props.t("Please select a record"));
    }
  }

  document.addEventListener("click", (event) => {
    let id = (event.target as HTMLElement).getAttribute("id");
    if (id === "idcontextmenushow" || id === "idcontextmenushowicon") {
      setIsMenuContext(true);
      setContextMenuView(true);
    } else if (isMenuContext || contextMenuView) {
      setIsMenuContext(false);
      setContextMenuView(false);
    }
  });


  /**
   * Used to get the selected from and to date for date filteration
   * @param From date
   * @param To date
   */
  const setdateRange = (e: any, isrest: Boolean) => {
    setfromRange(e.from);
    settoRange(e.to);
    setchangedtodate(e.changedtodate);

    // setFileType(e.filetype);
    let daydiff = dateDiffInDays(new Date(e.from), new Date(e.to)).toString();
    localStorage.setItem("sheetdaydiff", daydiff);


    // if(filterType === 1){
    //   let daydiff = dateDiffInDays(new Date(e.from), new Date()).toString();
    //   localStorage.setItem("sheetdaydiff", daydiff);
    // }
    initialorders = null;
    if (isrest) {
      setFileType(-1);
      setsearchCriteriaType(0);
      setorderflag(-1);
      setSearchContentText("");
      setorderstatusType(0);
      setFlitertest(-1);
      setFliterproject(-1);

      getordersforfolders(
        selectedTreeItem,
        selectedTreeItem,
        e.from,
        e.to,
        fileType,
        0,
        -1,
        { testcode: -1, testname: "All" },
        { projectcode: -1, projectname: "All" }
      );


    } else if (e.test !== undefined || e.project !== undefined) {
      setFliterproject(e.projects)
      setFlitertest(e.test)
      getordersforfolders(
        selectedTreeItem,
        selectedTreeItem,
        e.from,
        e.to,
        e.filetype,
        searchCriteriaType,
        orderflag,
        e.test,
        e.projects
      );
    }
    else if (e.filetype !== undefined) {
      setFileType(e.filetype);
      getordersforfolders(
        selectedTreeItem,
        selectedTreeItem,
        e.from,
        e.to,
        e.filetype,
        searchCriteriaType,
        orderflag,
        flitertest,
        fliterproject
      );
    }
    else if (e.orderflag !== undefined) {
      setorderflag(e.orderflag);
      getordersforfolders(
        selectedTreeItem,
        selectedTreeItem,
        e.from,
        e.to,
        fileType,
        searchCriteriaType,
        e.orderflag,
        flitertest,
        fliterproject
      );
    }

  };
  const handleSyncClick = (event: any) => {
    let passObjDet = sessionStorage.Usersite;
    setServicecall({
      ServiceToUrl: "" + ELNurl + "/Restcall/ImportLimsOrder",
      ServiceHeaderobject: passObjDet,
      ServiceMethodType: "POST",
      ServcieonSuccess: onImportLimsorderSuccess,
      ServcieonFailure: onImportLimsorderFailure,
      ServiceTenant: undefined,
      Servicecontenttype: undefined,
    });

  }

  const onImportLimsorderSuccess = (response: any) => {
    // this.getorders(0, this.state.activemenukey);
    // props.onImportLimsorderFailure();
    setTimeout(() => {
      getordersforfolders(
        selectedTreeItem,
        selectedTreeItem,
        fromRange,
        new Date(),
        fileType,
        searchCriteriaType,
        orderflag,
        flitertest,
        fliterproject
      );
    }, 500);

    if (response === "success") {
      props.alert.success(props.t("Orders.Sheetorders.IDS_MSG_SYNCSUCCESS"));
    } else {
      props.alert.error(props.t("Orders.Sheetorders.IDS_MSG_SYNCFAILURE"));
    }


    let passObjDet = {
      objsilentaudit: Silentauditobject(
        "syncLims",
        "Sheet Order",
        props.t("Orders.Sheetorders.IDS_AUDIT_SYNCLIMSORDERS", {
          user: store.getState().Loggeduser.username,
        }),
      )
    };
    Servicecalls(
      "" + ELNurl + "/AuditTrail/silentandmanualRecordHandler",
      passObjDet,
      onAuditValidateError,
      onAuditValidateFailure
    );

  }
  const onImportLimsorderFailure = (response: any) => {
    // this.getorders(0, this.state.activemenukey);
    // this.onsetDefaultdate();
  }


  const onfilterTypeChange = (key: any) => {
    localStorage.setItem("sheetfiltertype", key);
    setfilterType(key);
  };

  const ondeletefile = (file: any) => {
    let filedeleteurl =
      selectedTreeItem !== undefined && selectedTreeItem !== null ? ELNurl + '/Instrument/deletefilesforfolder/' + selectedTreeItem.directorycode
        + '/' + selectedTreeItem.filefor + '/' + sessionStorage.getItem("tenantid") + '/' + isMultitenant
        + '/' + sessionStorage.getItem("User") + '/' + sessionStorage.getItem("Usersite") + '/' + new Date() +
        '/' + fileviewfor
        : ELNurl + '/Instrument/deletefilesforfolder/' + -1
        + '/' + "DR" + '/' + sessionStorage.getItem("tenantid") + '/' + isMultitenant
        + '/' + sessionStorage.getItem("User") + '/' + sessionStorage.getItem("Usersite") + '/' + new Date() +
        '/' + fileviewfor;

    console.log(stateData);
    setDetailsData(null);
    setServicecall({
      ServiceToUrl: filedeleteurl,
      ServiceHeaderobject: file,
      ServiceMethodType: "POST",
      ServcieonSuccess: ondeletefileSuccess,
      ServcieonFailure: ondeletefileFailure,
      ServiceTenant: undefined,
      Servicecontenttype: undefined,
      ServcieProcessbar: true
    });
  }



  const ondeletefileCancelSuccess = (response: any, passdata: any) => {

  }
  const ondeletefileSuccess = (response: any, passdata: any) => {
    let file = passdata;
    // let selecttree = getDirectoryOncode(stateData, file.folderfilecode);
    // let directory: number = file.folderfilecode;

    let selecttree = getDirectoryOncode(stateData, file.directorycode);
    let directory: number = file.directorycode;
    let newData: any = [];
    if (selectedTreeItem === undefined || selectedTreeItem === null) {
      selecttree = getDirectoryOncode(stateData, file.folderfilecode);
      directory = file.folderfilecode;
      newData = deletefileitemsforhomebutton(
        stateData,
        [{
          directorycode: file.folderfilecode,
          file: file,
          filefor: "FIL"
        }],
      );
    } else {
      newData = deletefileitemstoFolderwithfilefor(
        stateData,
        directory,
        intl,
        [{
          directorycode: file.folderfilecode,
          file: file,
          filefor: "FIL"
        }],
        selecttree !== undefined && selecttree !== null && selecttree?.filefor ? selecttree?.filefor : "",
        screen
      );
    }
    setStateData(newData);
    // updateFileDetailsData({});
    setDetailsData(null);

    let passObjDet = {
      objsilentaudit: Silentauditobject(
        "delete",
        "Sheet Orders",
        props.t("Orders.Sheetorders.IDS_AUDIT_FILEDELETE", {
          file: response.file.filename,
          user: store.getState().Loggeduser.username
        })
      ),
    };
    Servicecalls(
      "" + ELNurl + "/AuditTrail/silentandmanualRecordHandler",
      passObjDet,
      onAuditValidateError.bind(this),
      onAuditValidateFailure.bind(this)
    );

  }

  const ondeletefileFailure = (response: any) => {
  }


  const ondeletemultifile = (multifile: any) => {

    let filedeleteurl =
      selectedTreeItem !== undefined && selectedTreeItem !== null ? ELNurl + '/Instrument/deletemultifilesforfolder/' + selectedTreeItem.directorycode
        + '/' + selectedTreeItem.filefor + '/' + sessionStorage.getItem("tenantid") + '/' + isMultitenant
        + '/' + sessionStorage.getItem("User") + '/' + sessionStorage.getItem("Usersite") + '/' + new Date() +
        '/' + fileviewfor
        : ELNurl + '/Instrument/deletemultifilesforfolder/' + -1
        + '/' + "DR" + '/' + sessionStorage.getItem("tenantid") + '/' + isMultitenant
        + '/' + sessionStorage.getItem("User") + '/' + sessionStorage.getItem("Usersite") + '/' + new Date() +
        '/' + fileviewfor;

    console.log(stateData);
    setDetailsData(null);
    setServicecall({
      ServiceToUrl: filedeleteurl,
      ServiceHeaderobject: multifile,
      ServiceMethodType: "POST",
      ServcieonSuccess: ondeletemultifileSuccess,
      ServcieonFailure: ondeletefileFailure,
      ServiceTenant: undefined,
      Servicecontenttype: undefined,
      ServcieProcessbar: true
    });
  }

  const ondeletemultifileSuccess = (response: any, passdata: any) => {
    let file = passdata;
    let newData: any = [];
    let updateddata: any = [];
    file.forEach((item: any) => {

      let selecttree = getDirectoryOncode(stateData, item.directorycode);
      let directory: number = item.directorycode;
      if (selectedTreeItem !== undefined && selectedTreeItem !== null) {
        if (updateddata.length === 0) {
          newData = deletefileitemstoFolderwithfilefor(
            stateData,
            directory,
            intl,
            [{
              directorycode: item.folderfilecode,
              file: item,
              filefor: "FIL"
            }],
            selecttree !== undefined && selecttree !== null && selecttree?.filefor ? selecttree?.filefor : "",
            screen
          );
          updateddata = newData;
        }
        else {
          newData = deletefileitemstoFolderwithfilefor(
            updateddata,
            directory,
            intl,
            [{
              directorycode: item.folderfilecode,
              file: item,
              filefor: "FIL"
            }],
            selecttree !== undefined && selecttree !== null && selecttree?.filefor ? selecttree?.filefor : "",
            screen
          );
        }
        updateddata = newData;
      } else {

        newData = deletefileitemsforhomebutton(
          stateData,
          [{
            directorycode: item.folderfilecode,
            file: item,
            filefor: "FIL"
          }],
        );
      }
    })
    props.alert.success(props.t("Orders.Sheetorders.IDS_MULTIFILEDELETE"));

    setStateData(newData);
    setIsMultiSelectFile(false);
    setSelectedFiles([]);
    setDetailsData(null);
  }

  // const ondeletemultifileFailure = (response: any) => {

  // }

  const ondeletemultifolder = (multifolder: any) => {

    if (
      selectedTreeItem !== undefined &&
      selectedTreeItem !== null &&
      selectedTreeItem.parentdircode === -1
    ) {
      let issingleparent = stateData.findIndex(
        (obj) =>
          obj.parentdircode === -1 &&
          obj.directorycode !== selectedTreeItem?.directorycode
      );
      if (issingleparent <= -1) {
        props.alert.error("The system should have atleast one directory.");
      } else {
        setDeleteDialogView(true);
      }
    } else {
      setDeleteDialogView(true);
    }
  }

  const Samplelogin = (order: any, methoddetails: any,) => {
    let passObjDet: any = {};
    if (
      methoddetails !== undefined &&
      methoddetails.filenameuser !== "" &&
      methoddetails.filecode > 0
    ) {
      passObjDet = order;
      passObjDet.lsfile = methoddetails.file;
      passObjDet.methodcode = methoddetails.methodid;
      passObjDet.instrumentcode = methoddetails.instrumentid;
      passObjDet.lsuserMaster = props.sessionvalues.lsuserMaster;
      // passObjDet.createdtimestamp = GetutcDate(new Date());
      if (
        passObjDet.lssamplefile === undefined ||
        passObjDet.lssamplefile === null) {

        passObjDet.lssamplefile = {
          batchcode: order.batchcode,
          testid: order.testcode,
          createdate: GetutcDate(new Date()),
          createbyuser: store.getState().Loggeduser,
          processed: 0,
          // filecontent: OrderTestcode.filecontent,
          versionno: 1,
          lssamplefileversion: [],
        };
      }
      passObjDet.ismultitenant = isMultitenant;

      let Audit = false;

      if (Audit) {
        // $("#auditTrailModalsheetview").modal("show");
        setServicecall({
          Orderclosebuttonvisible: true,

          AuditUrl: "" + ELNurl + "/Instrument/UpdateLimsOrder",
          AuditObject: passObjDet,
          AuditPassfunction: onUpdateLimsorderSuccess,
          AuditFailfunction: onUpdateLimsorderFaliure,
        });
      } else {
        setServicecall({
          Orderclosebuttonvisible: true,

          ServiceToUrl: "" + ELNurl + "/Instrument/UpdateLimsOrder",
          ServiceHeaderobject: passObjDet,
          ServiceMethodType: "POST",
          ServcieonSuccess: onUpdateLimsorderSuccess,
          ServcieonFailure: onUpdateLimsorderFaliure,
          ServiceTenant: undefined,
          Servicecontenttype: undefined,
        });
      }
    } else {
      props.alert.error(
        props.t("Templates.Protocoltemplates.IDS_MSG_INVALID", { param: "" })
      );
      passObjDet = {
        objsilentaudit: Silentauditobject(
          "warning",
          "Sheet Orders",
          props.t("Orders.Sheetorders.IDS_AUDIT_INVALIDSEL", {
            module: props.t("Orders.Sheetorders.IDS_LBL_SHEETORDERS", {
              param: "",
            }),
          })
        ),
      };
      Servicecalls(
        "" + ELNurl + "/AuditTrail/AuditConfigurationrecord",
        passObjDet,
        onAuditValidateError,
        onAuditValidateFailure
      );
    }
  }

  const onUpdateLimsorderSuccess = (response: any) => {

    if (
      response.response !== undefined &&
      response.response !== null &&
      response.response === false
    ) {
      props.alert.error(
        props.t("Common.ID_VALIDATION", { param: "" })
      );
    } else {
      response.canuserprocess = true;
      setSheetUnmapped(false);
      if (selectedTreeItem && selectedTreeItem.items) {
        selectedTreeItem.items.map((item) => {
          if (item.order.batchcode === response.batchcode) {
            item.order = response;
          }
          return item;
        });
      }
      setIsorderopen(true);

      let passObjDet = {
        objsilentaudit: Silentauditobject(
          "Mapping",
          "Sheet Order",
          props.t("Orders.Sheetorders.IDS_AUDIT_SHEETMAPTOLIMSORDER", {
            sheet: response.filename,
            order: response.batchid,
            user: store.getState().Loggeduser.username,
          }),
        )
      };
      Servicecalls(
        "" + ELNurl + "/AuditTrail/silentandmanualRecordHandler",
        passObjDet,
        onAuditValidateError,
        onAuditValidateFailure
      );
    }
  }

  const onUpdateLimsorderFaliure = (response: any) => { }

  const Formatrecordfortreevie = (Sheetdata: any) => {
    let FileData: any = [];
    let ParentIDindex = 0;

    if (Sheetdata !== undefined && Sheetdata.length > 0) {
      for (let sheet = 0; sheet < Sheetdata.length; sheet++) {
        let data: any = {};
        ParentIDindex = ParentIDindex + 1;
        data = {
          id: ParentIDindex,
          filecode: Sheetdata[sheet].filecode,
          parentID: null,
          name: Sheetdata[sheet].filenameuser,
          filenameuser: Sheetdata[sheet].filenameuser,
          methodid:
            Sheetdata[sheet].lsmethods !== undefined &&
              Sheetdata[sheet].lsmethods.length > 0
              ? Sheetdata[sheet].lsmethods[0].methodid
              : null,
          instrumentid:
            Sheetdata[sheet].lsmethods !== undefined &&
              Sheetdata[sheet].lsmethods.length > 0
              ? Sheetdata[sheet].lsmethods[0].instrumentid
              : null,
          data: Sheetdata[sheet],
          selected: false,
          file: Sheetdata[sheet],
        }
        FileData.push(data);
      }
    }

    setTimeout(() => {
      setSheetData(FileData);
    }, 1000);
    setSheetUnmapped(true);
    setIsorderopen(false);
    // setOpenedorder(undefined);
    // return FileData;
  }

  const openFileSelected = (response: any) => {
    $("#idOpenSheet").attr("data-dismiss", "modal");
    $("#idOpenSheet").removeAttr("disabled");

    setSheetSelected(response);
  }

  const Opensheet = (response: any) => {
    Samplelogin(openedorder,
      sheetSelected !== undefined && sheetSelected
    );
  }

  const OnCancelOpensheet = (response: any) => {
    setSheetUnmapped(false);
    if (props.openorder !== undefined) {
      getfoldersforclose();
    }
  }



  const Detailview = React.useCallback(() => {
    return <Suspense fallback={<div></div>}><FileInformation data={detailsData} screen={screen} filefor={selectedTreeItem?.filefor}
      t={props.t} /></Suspense>;
  }, [detailsData]);

  let filesaveurl =
    selectedTreeItem !== undefined && selectedTreeItem !== null ?
      ELNurl + '/Instrument/uploadfilessheetfolder/' + selectedTreeItem.directorycode
      + '/' + selectedTreeItem.filefor + '/' + sessionStorage.getItem("tenantid") + '/' + isMultitenant
      + '/' + sessionStorage.getItem("User") + '/' + sessionStorage.getItem("Usersite") + '/' + new Date() +
      '/' + fileviewfor
      : ELNurl + '/Instrument/uploadfilessheetfolder/' + -1
      + '/' + "DR" + '/' + sessionStorage.getItem("tenantid") + '/' + isMultitenant
      + '/' + sessionStorage.getItem("User") + '/' + sessionStorage.getItem("Usersite") + '/' + new Date() +
      '/' + fileviewfor
    ;

  let fileremoveurl =
    selectedTreeItem !== undefined && selectedTreeItem !== null ? ELNurl + '/Instrument/removefilessheetfolder/' + selectedTreeItem.directorycode
      + '/' + selectedTreeItem.filefor + '/' + sessionStorage.getItem("tenantid") + '/' + isMultitenant
      + '/' + sessionStorage.getItem("User") + '/' + sessionStorage.getItem("Usersite") + '/' + new Date() +
      '/' + fileviewfor
      : ELNurl + '/Instrument/removefilessheetfolder/' + -1
      + '/' + "DR" + '/' + sessionStorage.getItem("tenantid") + '/' + isMultitenant
      + '/' + sessionStorage.getItem("User") + '/' + sessionStorage.getItem("Usersite") + '/' + new Date() +
      '/' + fileviewfor;

  let homeuploadfile = ELNurl + '/Instrument/uploadfilessheetfolder/' + -1
    + '/' + "DR" + '/' + sessionStorage.getItem("tenantid") + '/' + isMultitenant
    + '/' + sessionStorage.getItem("User") + '/' + sessionStorage.getItem("Usersite") + '/' + new Date() +
    '/' + fileviewfor

  let homeremovefile = ELNurl + '/Instrument/removefilessheetfolder/' + -1
    + '/' + "DR" + '/' + sessionStorage.getItem("tenantid") + '/' + isMultitenant
    + '/' + sessionStorage.getItem("User") + '/' + sessionStorage.getItem("Usersite") + '/' + new Date() +
    '/' + fileviewfor;


  const searchonButtonClick = (
    SearchContentText: any,
    searchCriteriaType: any
  ) => {
    let returndateobj = setdates(filterType, isFiltered, "sheetdaydiff", toRange, changedtodate);
    //  let fromRange = returndateobj.fromRange;
    if (returndateobj !== undefined) {
      setfromRange(returndateobj.fromRange);
      settoRange(returndateobj.toRange);
    }
    setsearchCriteriaType(searchCriteriaType.value);
    if (
      SearchContentText !== "" &&
      searchCriteriaType.value !== undefined &&
      searchCriteriaType.value === 2
    ) {
      if (selectedTreeItemDuplicate !== undefined && selectedTreeItemDuplicate !== null && selectedTreeItemDuplicate.items !== undefined) {
        let filterorders = selectedTreeItemDuplicate.items.filter((item) =>
          item && item.order && item.order.keyword
            .toLowerCase()
            .includes(SearchContentText.toLowerCase())
        );
        setSelectedTreeItem({
          ...selectedTreeItem,
          items: filterorders,
        });
      }

      // if (selectedTreeItem !== undefined && selectedTreeItem !== null && selectedTreeItem.items !== undefined) {
      //   let filterorders = selectedTreeItem.items.filter((item) =>
      //     item && item.order && item.order.keyword
      //       .toLowerCase()
      //       .includes(SearchContentText.toLowerCase())
      //   );
      //   setSelectedTreeItem({
      //     ...selectedTreeItem,
      //     items: filterorders,
      //   });
      // }
    } else if (
      searchCriteriaType.value !== undefined &&
      searchCriteriaType.value === 1
    ) {
      if (
        selectedTreeItem !== undefined &&
        selectedTreeItem !== null &&
        selectedTreeItem.items !== undefined
      ) {
        let filterorders: any = [];
        if (
          (selectedTreeItem.directorycode === -8 ||
            selectedTreeItem.directorycode === -9) &&
          stateData[0].items !== undefined
        ) {
          filterorders = stateData[0].items.filter(
            (item) => item.directorycode === selectedTreeItem.directorycode
          );
        } else if (
          (selectedTreeItem.directorycode === -10 ||
            selectedTreeItem.directorycode === -11) &&
          stateData[1].items !== undefined
        ) {
          filterorders = stateData[1].items.filter(
            (item) => item.directorycode === selectedTreeItem.directorycode
          );
        }
        console.log(filterorders);
        if (filterorders.length > 0) {
          setSelectedTreeItem({
            ...selectedTreeItem,
            items: filterorders[0].items,
          });
          setSelectedTreeItemDuplicate({
            ...selectedTreeItem,
            items: filterorders[0].items,
          });
        }
      }
    } else if (
      searchCriteriaType.value !== undefined &&
      searchCriteriaType.value === 3
    ) {

      setsearchCriteriaType({ ...searchCriteriaType });
      if (searchCriteriaType.value === 3) {
        setSearchContentText({ SearchContentText });
      }
      else if (searchCriteriaType.value === 1) {
        setSearchContentText("");
      }
    }

  };

  const updateskiptake = (skiptake: any) => {
    setTakeskip(skiptake);
  }

  const handleoncolumnchange = (column: any, splititems: any) => {
    setColumns([...column]);
    setsplitBtnItems(splititems);
  }

  const updateorder = (order: any) => {
    if (stateData.length > 0) {
      if (selectedTreeItem && selectedTreeItem.directorycode && (selectedTreeItem.filefor === "LPO" || selectedTreeItem.filefor === "EPO"
        || selectedTreeItem.filefor === "RPO" || selectedTreeItem.filefor === "MPO" || selectedTreeItem.filefor === "SPO")
        && order && order.orderflag && order.orderflag.trim() === "R") {

        let newData = removeDataItem(stateData, { directorycode: order.batchcode });
        let selectedtree = getDirectoryOncode(newData, selectedTreeItem.directorycode);
        setStateData(newData);
        setSelectedTreeItem(selectedtree);
        setSelectedTreeItemDuplicate(selectedtree);
      } else if (selectedTreeItem && selectedTreeItem.directorycode && (selectedTreeItem.filefor === "MYO" || order.orderflag.trim() === "R")) {
        let folderdata =
          stateData !== undefined && stateData !== null && stateData.length > 0
            ? stateData
            : initialorders !== null
              ? initialorders
              : [];
        let directorycode: any = selectedTreeItem !== null ? selectedTreeItem.directorycode : null;
        let filefor: any = selectedTreeItem !== null ? selectedTreeItem.filefor : "";
        if (order !== undefined) {
          let responseorder = [order];
          let newdata = replaceonechangeorderordertoFolderwithfilefor(
            folderdata,
            directorycode,
            intl,
            responseorder !== undefined && responseorder.length > 0 ? responseorder : [],
            filefor,
            screen
          );
          let selectedtree = getDirectoryOncode(newdata, selectedTreeItem.directorycode);
          setStateData(newdata);
          setSelectedTreeItem(selectedtree);
          setSelectedTreeItemDuplicate(selectedtree);
        }
      }
    }
  }

  const oncloseorder = () => {
    setIsorderopen(false);
    setOpenedorder(undefined);

    if (stateData.length === 0) {
      getfoldersforclose()
    }
  }

  const getfoldersforclose = () => {
    let passObj: any = {
      // filetype: parseInt(type),
      fromdate: GetutcDate(new Date(fromRange)),
      todate: GetutcDate(new Date(toRange)),
      lstproject: projects,
      lstworkflow: workflow,
      // objsilentaudit: silentaudit,
      lsuserMaster:
        store.getState().Loggeduser !== undefined &&
          store.getState().Loggeduser !== null &&
          Object.keys(store.getState().Loggeduser).length > 0
          ? store.getState().Loggeduser
          :
          {
            usercode: parseInt(sessionStorage.User),
            username: sessionStorage.Username,
            unifieduserid: sessionStorage.unifieduserid,
          },
      ismultitenant: isMultitenant,
    };
    passObj.lstuserMaster = Getteamusersfromsession();

    passObj.lsuserMaster.lsusergrouptrans = {
      usergroupcode: localStorage.getItem("multiusergroups"),
      usergroupname: localStorage.getItem("usergroupname"),
      usergroupstatus: localStorage.getItem("usergroupstatus"),
    };
    Servicecalls(
      "" + ELNurl + "/Instrument/Getfoldersfororders",
      passObj, ongetfoldersuccess, ongetfolderfailure
    );
  }


  useEffect(() => {

    if (((SearchContentText !== undefined && searchCriteriaType.value === 3)
      || (SearchContentText === "" && searchCriteriaType.value === 1)) || pullcontents) {
      getordersforfolders(
        selectedTreeItem,
        selectedTreeItem,
        fromRange,
        toRange,
        fileType,
        searchCriteriaType,
        orderflag,
        flitertest,
        fliterproject
      );
    }

    if (sheetdatainit === undefined && sheetdatainitservice) {
      let passobj = isMultitenant === 0 ? pendinglimsorderfolder : pendingelnorderfolder
      let passObjDet4: any = {
        filetype: renderSwitch(passobj.filefor),
        fromdate: filterType === 1 ? GetutcDateaudi(GetutcDate(new Date(fromRange))) : GetutcDate(new Date(fromRange)),
        todate: GetutcDate(new Date(toRange)),
        lstproject: projects,
        lstworkflow: workflow,
        directorycode: passobj.directorycode,
        orderflag: passobj.filefor === "LPO" || passobj.filefor === "EPO"
          || passobj.filefor === "RPO" || passobj.filefor === "MPO" || passobj.filefor === "SPO" ? "N" : "R",
        filefor: passobj.filefor,
        lsuserMaster: store.getState().Loggeduser,
        searchCriteria: {
          contentsearchtype: searchCriteriaType.value,
          contentsearch: SearchContentText.SearchContentText
        },
        ismultitenant: isMultitenant
      };
      if (orderflag !== undefined && orderflag !== -1) {
        passObjDet4.orderflag = orderflag === 1 ? "N" : "R"
      }
      if (orderflag !== undefined && orderflag === 3) {
        passObjDet4.approvelstatus = 3
      }

      if (flitertest.testcode !== -1) {
        passObjDet4.testcode = flitertest.testcode
      }
      if (fliterproject.projectcode !== undefined && fliterproject.projectcode !== -1) {
        passObjDet4.lsprojectmaster = fliterproject;
      }
      passobjectdates = passObjDet4;
      if (Foldercontext !== undefined) {
        ongetfoldersuccess(Foldercontext, intl);
      }
      passObjDet4.lstuserMaster = Getteamusersfromsession();
      setServicecall((servicecall: any) => ({
        ...servicecall,
        ServiceToUrl: "" + ELNurl + "/Instrument/Getorderbyflaganduser",
        ServiceHeaderobject: passObjDet4,
        ServiceMethodType: "POST",
        ServcieonSuccess: ongetorderssuccess01,
        ServcieonFailure: ongetfolderfailure,
        ServiceTenant: undefined,
        Servicecontenttype: undefined,
      }));
    } else if (sheetdatainit !== undefined && sheetdatainitservice) {
      orderdataforfolder = sheetdatainit;
      getfolderfiles(passobjectdates);
    }

    store.dispatch(updateorderopen(isorderopen));
    // return () => {
    //   store.dispatch(updateorderopen(isorderopen));
    // }

  }, [SearchContentText, pullcontents, isorderopen, sheetdatainitservice, sheetdatainit])

  const ongetorderssuccess01 = (response: any, passeddata: any) => {
    if (
      passeddata !== undefined &&
      passeddata?.directorycode !== undefined &&
      passeddata.filefor !== undefined
    ) {
      response.forEach((item: any) => {
        if (item.orderflag.trim() === "N" && item.filetype === 0) {
          item.canuserprocess = true;
        } else if (item.lsprojectmaster === undefined && item.viewoption !== undefined && item.viewoption === 1) {
          item.canuserprocess = true;
        } else if ((item.lsprojectmaster === undefined && item.viewoption !== undefined && item.viewoption === 2 && item.lsuserMaster?.usercode === parseInt(sessionStorage.User)) || item.viewoption === 3) {
          item.canuserprocess = true;
        }
      });
      // setsheetdatainitservice(false);
      setsheetdatainit(response)
    } else {
      setsheetdatainit([])
    }
  };


  useLayoutEffect(() => {


    if (props.notificationorder === "") {
      if (userrights.LIMSorderVisible !== undefined && userrights.LIMSorderVisible === true && isMultitenant === 0) {
        setsheetdatainitservice(true);
      }
      else if (userrights.ELNorderVisible === true && isMultitenant === 1) {
        setsheetdatainitservice(true);
      } else {
        if (Foldercontext !== undefined) {
          ongetfoldersuccess(Foldercontext, intl);
        }
      }
    } else if (props.notificationorder) {

      let Audit = AuditTrailValidation(
        "IDS_MDL_ORDERS",
        "IDS_SCN_SHEETORDERS",
        "IDS_TSK_PROCESSORDER"
      );
      if (Audit) {
        // setsheetdatainitservice(true);
        setOpenedorder(props.notificationorder);
        getfoldersforclose()
        setTimeout(() => {
          recordorderopenhistory(props.openorder)
          props.onresetnotification();
        }, 700);

      } else {
        if (props.openorder.filecode !== undefined && props.openorder.filecode === -1) {
          handleOrderopenClick(null, props.openorder);
        } else {
          setIsorderopen(true);
          setOpenedorder(props.notificationorder);
          props.onresetnotification();
        }
      }
      // openorderfornotification(props.isnotificationprocess, props.notificationorder);
    }
    // if (props.notificationorder) {
    //   let passObj: any = {
    //     // filetype: parseInt(type),
    //     fromdate: GetutcDate(new Date(fromRange)),
    //     todate: GetutcDate(new Date(toRange)),
    //     lstproject: projects,
    //     lstworkflow: workflow,
    //     // objsilentaudit: silentaudit,
    //     lsuserMaster:
    //       store.getState().Loggeduser !== undefined &&
    //         store.getState().Loggeduser !== null &&
    //         Object.keys(store.getState().Loggeduser).length > 0
    //         ? store.getState().Loggeduser
    //         :
    //         {
    //           usercode: parseInt(sessionStorage.User),
    //           username: sessionStorage.Username,
    //           unifieduserid: sessionStorage.unifieduserid,
    //         },
    //     ismultitenant: isMultitenant,
    //   };
    //   passObj.lstuserMaster = Getteamusersfromsession();

    //   passObj.lsuserMaster.lsusergrouptrans = {
    //     usergroupcode: localStorage.getItem("multiusergroups"),
    //     usergroupname: localStorage.getItem("usergroupname"),
    //     usergroupstatus: localStorage.getItem("usergroupstatus"),
    //   };
    //   Servicecalls(
    //     "" + ELNurl + "/Instrument/Getfoldersfororders",
    //     passObj, ongetfoldersuccess, ongetfolderfailure
    //   );

    //   setTimeout(() => {
    //     openorderfornotification(props.isnotificationprocess, props.notificationorder);
    //   }, 500);

    // }
    else if (props.ordercode && props.ordercode !== "") {
      setTimeout(() => {
        updateStateforprocessnotification(props.ordercode, "link");
      }, 500);
      // setIsorderopen(true);

    }
  }, [ELNurl]);

  const Itemview = React.useCallback(() => {
    let item = contentView === "grid" ? (selectedTreeItem && selectedTreeItem.items
      ? process(
        selectedTreeItem.items.map((item) => {
          if (item.order !== undefined) {
            item.order.directorycode = item.directorycode;
          }
          return {
            ...item,
            ...item.order,
            // link: generateLink(item.order !== undefined ? item.order.batchcode : ""),
            [SELECTED_FIELD]: gridSelection[idGetter(item)],
          };
        }),
        stateContentData
      )
      : !selectedTreeItem
        ? process(
          stateData.map((item) => {
            if (item.order !== undefined) {
              item.order.directorycode = item.directorycode;
            }
            return {
              ...item,
              ...item.order,
              // link: generateLink(item.order !== undefined ? item.order.batchcode : ""),
              [SELECTED_FIELD]: gridSelection[idGetter(item)],
            };
          }),
          stateContentData
        )
        : null) :
      (selectedTreeItem && selectedTreeItem.items
        ? process(
          selectedTreeItem.items.map((item) => ({
            ...item,
            [SELECTED_FIELD]: gridSelection[idGetter(item)],
          })),
          stateContentData
        )
        : !selectedTreeItem
          ? process(
            stateData.map((item) => ({
              ...item,
              [SELECTED_FIELD]: gridSelection[idGetter(item)],
            })),
            stateContentData
          )
          : null);

    return (
      <React.Fragment>
        {contentView === "grid" ? (
          // <OverlayScrollbarsComponent style={{ height: "calc(100vh - 225px)" }}>
          <Suspense fallback={<div></div>}>
            <MyGlobalContext.Provider value={userrights}>
              <GridView
                data={item}
                sort={stateContentData.sort}
                dataItemKey={DATA_ITEM_KEY}
                onSelectionChange={handleSelectionChange}
                onMultipleSelection={handleMultipleSelection}
                onSortChange={handleSortChange}
                onDoubleClick={handleGridDoubleClick}
                onContextMenu={handleContextMenuView}
                onOrderShared={onOrderShared}
                props={props}
                columns={columns}
                splitItems={splitBtnItems}
                openunsharelist={openunsharelist}
                filedownloadurl={ELNurl + "/Instrument/downloadsheetfileforfolder/" + isMultitenant + "/" + sessionStorage.getItem("tenantid")}
                ondeletefile={ondeletefile}
                screen={screen}
                onOrderOpen={handleOrderopenClick}
                name={"sheetorder_1"}
                skiptake={takeskip}
                updateskiptake={updateskiptake}
                multiSelectedOrders={selectedOrders}
                onMultiOrderSelect={handleMultiSelectClick}
                isMultiSelectOrder={isMultiSelectOrder}
                multiSelectedFiles={selectedFiles}
                onMultiFileSelect={handleMultiSelectFileClick}
                isMultiSelectFile={isMultiSelectFile}
                multiSelectedFolders={selectedFolders}
                onMultiFolderSelect={handleMultiSelectFolderClick}
                isMultiSelectFolders={isMultiSelectFolders}
                oncancelledorderevent={oncancelledorder}
              />
            </MyGlobalContext.Provider></Suspense>
          // </OverlayScrollbarsComponent>
        ) : (
          <div> <Suspense fallback={<div></div>}>
            <MyGlobalContext.Provider value={userrights}>
              <ListView
                data={item}
                onSelectionChange={handleSelectionChange}
                onMultipleSelection={handleMultipleSelection}
                onItemClick={handleListItemClick}
                onDoubleClick={handleGridDoubleClick}
                onContextMenu={handleContextMenuView}
                onOrderOpen={handleOrderopenClick}
                multiSelectedOrders={selectedOrders}
                onMultiOrderSelect={handleMultiSelectClick}
                isMultiSelectOrder={isMultiSelectOrder}
                onOrderShared={onOrderShared}
                props={props}
                screen={screen}
                openunsharelist={openunsharelist}
                closeunsharelist={closeunsharelist}
                filedownloadurl={ELNurl + "/Instrument/downloadsheetfileforfolder/" + isMultitenant + "/" + sessionStorage.getItem("tenantid")}
                ondeletefile={ondeletefile}
                multiSelectedFiles={selectedFiles}
                onMultiFileSelect={handleMultiSelectFileClick}
                isMultiSelectFile={isMultiSelectFile}
                columns={columns}
                splitItems={splitBtnItems}
                multiSelectedFolders={selectedFolders}
                onMultiFolderSelect={handleMultiSelectFolderClick}
                isMultiSelectFolders={isMultiSelectFolders}
                oncancelledorder={oncancelledorder}
              />
            </MyGlobalContext.Provider></Suspense>
            {/* <div className="clsitemcount pr-1" >{item?.total + " " + "items"}</div> */}
          </div>
        )}
      </React.Fragment>
    );
  }, [
    selectedTreeItem,
    stateData,
    stateContentData,
    selectedGridItem,
    contentView,
    isMultiSelectOrder,
    isMenuContext,
    takeskip,
    gridSelection,
    selectedOrders,
    selectedFiles,
    isMultiSelectFile,
    isMultiSelectFolders,
    selectedFolders,
    columns
  ]);

  const folderview = React.useCallback(() => {
    return (<div
      className={
        "k-widget k-filemanager k-filemanager-resizable " +
        // stateOrder.orderDetailsclassName +
        " cls-filemanager "
      }
      style={{ display: !isorderopen ? "block" : "none" }}
    >
      <div className="k-filemanager-header">
        <Suspense fallback={<div></div>}>
          <MyGlobalContext.Provider value={userrights}>
            <FileManagerToolbar
              splitItems={splitBtnItems}
              columns={columns}
              sort={stateContentData.sort}
              files={files}
              filesaveurl={filesaveurl}
              fileremoveurl={fileremoveurl}
              homeuploadfile={homeuploadfile}
              homeremovefile={homeremovefile}
              onNewFolderClick={handleNewFolderClick}
              onCreateOrderClick={handleRegisterOrder}
              onSearchChange={handleSearchChange}
              onSwitchChange={handleSwitchChange}
              onViewChange={handleViewChange}
              onSortChange={handleSortChange}
              onFileChange={handleFileChange}
              onAddFileChange={handleAddFileChange}
              onClearFileList={handleClearFileList}
              onUploadComplete={handleUploadComplete}
              onContextMenu={handleContextMenuView}
              isMultiSelectOrder={isMultiSelectOrder}
              isMultiSelectFile={isMultiSelectFile}
              isMultiSelectFolders={isMultiSelectFolders}
              onCloseMultiSelect={handleCloseMultiselectClick}
              onMultiselectMove={handleMoveOnMultiselect}
              onMultiselectDelete={handleDeleteOnMultiselect}
              onhandleSyncClick={handleSyncClick}
              sessionvalues={props.sessionvalues}
              folderdata={getFolderTree(stateData, selectedTreeItem)}
              selectedItem={selectedGridItem}
              selectedFolder={selectedTreeItem}
              t={props.t}
              userData={stateUserData}
              props={props}
              screen={screen}
              view={localStorage.getItem("elnsheetorderview") !== undefined &&
                localStorage.getItem("elnsheetorderview") !== null &&
                localStorage.getItem("elnsheetorderview") === "grid"
                ? false
                : true}
              oncolumnchange={handleoncolumnchange}
              rights={userrights}

            />
          </MyGlobalContext.Provider></Suspense>
      </div>
      <Suspense fallback={<div></div>}>
        <OrdershowContext.Provider value={userrights}>
          <DateFilter
            t={props.t}
            setFilterType={onfilterTypeChange}
            filterType={filterType}
            fromRange={fromRange}
            toRange={toRange}
            setDateRange={setdateRange}
            props={props}
            screen={screen}
            folder={selectedTreeItem}
            searchonButtonClick={searchonButtonClick}
            searchCriteriaType={searchCriteriaType}
            orderstatusType={orderstatusType}
            ismultitenant={isMultitenant}
            orderflag={orderflag}

          />
        </OrdershowContext.Provider>
      </Suspense>
      <div className="k-filemanager-content-container">
        <Splitter panes={panes} onChange={handleSplitterChange}>
          <OverlayScrollbarsComponent
            style={{ height: "calc(100vh - 195px)" }}
          ><Suspense fallback={<div></div>}>
              <FolderTree
                data={getFolderTree(stateData, selectedTreeItem)}
                selected={selectedTreeItem}
                selectedItem={selectedTreeItem}
                onItemClick={handleTreeItemClick}
                onContextMenu={handleContextMenuView}
              />
            </Suspense>
          </OverlayScrollbarsComponent>
          <div className="k-filemanager-content positioned-tr-group iconed-breadcrumb table-header-overflow">
            <Suspense fallback={<div></div>}>
              <BreadcrumbComponent
                data={convertToBreadcrumbData(selectedTreeItem, screen)}
                onBreadcrumbSelect={handleBreadcrumbSelection}
              /></Suspense>
            {/* <OverlayScrollbarsComponent
                  style={{ height: "calc(100vh - 195px)" }}
                > */}
            {Itemview()}
            {/* </OverlayScrollbarsComponent> */}

          </div>
          <OverlayScrollbarsComponent
            style={{ height: "calc(100vh - 195px)" }}
          >
            {Detailview()}
          </OverlayScrollbarsComponent>
        </Splitter>
      </div>
    </div>)
  }, [menuOffSet, selectedGridItem, selectedTreeItem, isMultiSelectFile, isMultiSelectFolders, selectedFolders,
    orderstatusType, fromRange, toRange, splitBtnItems, columns, stateContentData, files, isMultiSelectOrder,
    selectedOrders, contentView, searchCriteriaType, stateData, isorderopen, sheetUnmapped, openedorder, panes,
    takeskip
  ])

  const closeModel = () => {
    setCancellorder(false);
  }

  const onsavecancelformodal = () => {
    if (cancellorderitem !== undefined) {
      let passObjDet = cancellorderitem;
      passObjDet.ordercancell = 1;
      passObjDet.objLoggeduser = store.getState().Loggeduser;
      Servicecalls(
        "" + ELNurl + "/Instrument/cancelsheetorder",
        passObjDet,
        oncancancelsheetordersucess.bind(this),
        oncancancelsheetorderFailure.bind(this)
      );
    }

  }

  const oncancancelsheetordersucess = (response: any) => {

    let responseorder = [];
    responseorder.push(response);
    let folderdata = stateData !== undefined && stateData !== null && stateData.length > 0
      ? stateData : initialorders !== null ? initialorders : [];
    let directorycode: any = selectedTreeItem !== null ? selectedTreeItem.directorycode : null;
    let filefor: any = selectedTreeItem !== null ? selectedTreeItem.filefor : "";
    let newdata = replaceonechangeorderordertoFolderwithfilefor(
      folderdata,
      directorycode,
      intl,
      responseorder !== undefined && responseorder.length > 0 ? responseorder : [],
      filefor,
      screen
    );

    let selectedtree = getDirectoryOncodeandfilefor(
      newdata,
      directorycode,
      filefor
    );
    setCancellorder(false);
    props.alert.success(props.t("Orders.Sheetorders.IDS_MSG_ORDERCANCELLEDSUCESS"));
    setSelectedTreeItem(selectedtree);
    setSelectedTreeItemDuplicate(selectedtree);
    setStateData(newdata);
    let passObjDet1 = {
      objsilentaudit: Silentauditobject(
        "cancel",
        "Sheet Orders",
        props.t("Orders.Sheetorders.IDS_AUDIT_CANCELORDER", {
          order: cancellorderitem.batchid,
          screen: orderType(response.filetype),
          user: store.getState().Loggeduser.username,
        })
      ),
    };
    Servicecalls(
      "" + ELNurl + "/AuditTrail/silentandmanualRecordHandler",
      passObjDet1,
      onAuditValidateError.bind(this),
      onAuditValidateFailure.bind(this)
    );

    let passobj = {
      // protocolorderstepcode: this.state.protocolToBeEdited.protocolorderstepcode,
      batchcode: cancellorderitem.batchcode,
      createby: { usercode: sessionStorage.User },
      stepstartdate: GetutcDate(new Date()),
      action: props.t("Templates.Protocoltemplates.IDS_LBL_PROCESSORDER", {
        batchid: cancellorderitem.batchid,
        Processed: "Cancelled",
        user2: sessionStorage.Username
      }),
      viewoption: 3
    }

    Servicecalls(
      "" + ELNurl + "/protocol/updatetransactionhistory",
      passobj,
      ongetordersfailure,
      ongetordersfailure
    );


  }

  const oncancancelsheetorderFailure = (response: any) => { }

  const onsinglerecordupdater = (order: any) => {
    if (stateData.length > 0) {
      let responseorder: any = [];
      responseorder.push(order);
      let folderdata =
        stateData !== undefined && stateData !== null && stateData.length > 0
          ? stateData
          : initialorders !== null
            ? initialorders
            : [];
      let directorycode: any = selectedTreeItem !== null ? selectedTreeItem.directorycode : null;
      let filefor: any = selectedTreeItem !== null ? selectedTreeItem.filefor : "";
      let newdata = replaceonechangeorderordertoFolderwithfilefor(
        folderdata,
        directorycode,
        intl,
        responseorder !== undefined && responseorder.length > 0 ? responseorder : [],
        filefor,
        screen
      );
      let selectedtree = getDirectoryOncodeandfilefor(
        newdata,
        directorycode,
        filefor
      );
      setSelectedTreeItem(selectedtree);
      setSelectedTreeItemDuplicate(selectedtree);
      setStateData(newdata);
      setOpenedorder(order);
    }

    if ((selectedGridItem !== undefined && selectedGridItem.order !== undefined && selectedGridItem.order.lsworkflow !== undefined) && (order !== undefined && order.lsworkflow !== undefined)) {
      let selectedGridItemobj: any = selectedGridItem;
      selectedGridItemobj.order.lsworkflow = order.lsworkflow;
      setSelectedGridItem(selectedGridItemobj);
      updateFileDetailsData(selectedGridItemobj);

    }
  }

  return (
    <div>
      {/* {!stateOrder.stopDisabled && ( */}
      {folderview()}
      {/* )} */}
      {contextMenuView &&
        <Suspense fallback={<div></div>}>
          <ContextMenu
            offset={menuOffSet}
            onContextMenuCLick={handleContextMenuClick}
            selectedItem={selectedGridItem}
            ismenucontext={isMenuContext}
            rights={userrights}
            t={props.t}
          /></Suspense>}

      {editDialogView && <Suspense fallback={<div></div>}><EditDialog
        editValue={
          Object.keys(selectedGridItem).length
            ? selectedGridItem
            : selectedTreeItem
        }
        onDialogClose={handleDialogClose}
        onDialogClick={handleDialogClick}
        selectedFolder={selectedTreeItem}
        sessionvalues={props.sessionvalues}
        t={props.t}
        alert={props.alert}
      /></Suspense>}

      {deleteDialogView &&
        <Suspense fallback={<div></div>}>
          <DeleteDialog
            onDialogClose={handleDialogClose}
            onDialogClick={handleDialogClick}
            selectedFolder={selectedTreeItem}
            folderdata={getFolderTree(stateData, selectedTreeItem)}
            selectedItem={selectedGridItem}
            sessionvalues={props.sessionvalues}
            userData={stateUserData}
            t={props.t}
            selectedFolders={selectedFolders}
          /></Suspense>}

      {moveDialogView &&
        <Suspense fallback={<div></div>}>
          <MoveDialog
            folderdata={getFolderTree(stateData, selectedTreeItem)}
            selectedItem={selectedGridItem}
            selectedFolder={selectedTreeItem}
            userData={stateUserData}
            onDialogClose={handleDialogClose}
            onDialogClick={handlemoveDialogClick}
            sessionvalues={props.sessionvalues}
            mutifolders={selectedFolders}
            isshowallfolder={(isMultiSelectFile
              // &&
              // selectedFiles !== undefined &&
              // selectedFiles.length > 0 || selectedGridItem.file !== undefined 
              // ? 0 : isMultiSelectFolders ? 1 : 2)}
              ? 2 : isMultiSelectFolders ? 1 : 2)}
            t={props.t}
            alert={props.alert}
          /></Suspense>
      }

      {adddialogVisible &&
        <Suspense fallback={<div></div>}>
          <AddDialog
            addValue={undefined}
            onDialogClose={handleDialogClose}
            onDialogClick={handleDialogClick}
            sessionvalues={props.sessionvalues}
            t={props.t}
            alert={props.alert}
            selectedFolder={selectedTreeItem}
          /></Suspense>
      }

      {isunshareopen && (
        <Suspense fallback={<div></div>}>
          <Unsharelistview
            isOpen={isunshareopen}
            closeunsharelist={closeunsharelist}
            shareitem={selectedUnsharedItem}
            onorderunshare={onorderunshare}
            screen={screen}
          /></Suspense>
      )}
      {/* openedorder && */}
      {isorderopen &&
        <Suspense fallback={<div></div>}>
          <Sheetorderview projects={projects} workflow={workflow} order={openedorder} t={props.t} sessionvalues={props.sessionvalues} alert={props.alert} updateorder={updateorder} userrights={userrights}
            onclose={oncloseorder} openfrom={"screen"} singlrecoredupdate={onsinglerecordupdater} filefor={selectedTreeItem?.filefor} /></Suspense>
      }

      <Modal centered isOpen={sheetUnmapped}>
        <div className="modal-header">
          <h5 className="modal-title" id="openSheetLabel">
            {props.t("Orders.Sheetorders.IDS_LBL_OPENSHEET", {
              param: "",
            })}
          </h5>
          <button
            type="button"
            className="btn_white_bg"
            data-dismiss="modal"
            aria-label="Close"
            onClick={OnCancelOpensheet}
          >
            <i className="fas fa-times" aria-hidden="true"></i>
          </button>
        </div>
        <ModalBody>
          <div className="row">
            <div className="col-md-12">
              <form>
                <div className="form-group">
                  <Suspense fallback={<div></div>}>
                    <KendoTreeList
                      Datasorce={sheetData}
                      selectedsheet={openFileSelected}
                    />
                  </Suspense>
                </div>
              </form>
            </div>
          </div>
        </ModalBody>
        <div className="modal-footer">
          <button
            type="button"
            id="idOpenSheet"
            className="btn btn-user btn-primary-blue"
            onClick={Opensheet}
          >
            {props.t("Orders.Sheetorders.IDS_LBL_OPEN", {
              param: "",
            })}
          </button>
          <button
            type="button"
            id="idOpenCancel"
            className="btn btn-user btn-cancel"
            data-dismiss="modal"
            onClick={OnCancelOpensheet}
          >
            {props.t("Orders.Sheetorders.IDS_LBL_CANCEL", {
              param: "",
            })}
          </button>
        </div>
      </Modal>

      <Service
        Tourl={servicecall.ServiceToUrl}
        MethodType={servicecall.ServiceMethodType}
        onSuccess={servicecall.ServcieonSuccess}
        onFailure={servicecall.ServcieonFailure}
        Headerobject={servicecall.ServiceHeaderobject}
        onReseturl={() => {
          setServicecall({ ...servicecall, ServiceToUrl: "" });
        }}
        ServiceTenant={servicecall.ServiceTenant}
        contenttype={servicecall.Servicecontenttype}
      ></Service>

      <div>
        <Suspense fallback={<div></div>}>
          <Audit
            AuditId="auditTrailModalsheetview"
            AuditUrl={Auditservicecall.AuditUrl}
            AuditObject={Auditservicecall.AuditObject}
            AuditPassfunction={Auditservicecall.AuditPassfunction}
            AuditFailfunction={Auditservicecall.AuditFailfunction}
            Auditfilefunction={Auditservicecall.Auditfilefunction}
            AuditCancelfunction={Auditcancel}
            alert={props.alert}
          /></Suspense>

        {
          cancellorder &&
          <Suspense fallback={<div></div>}>
            <CommonDialog
              cancellorder={cancellorder}
              header="Orders.Protocolorders.IDS_LBL_CANCELLORDER"
              bodycontent={props.t("Orders.Sheetorders.IDS_LBL_CANCELLORDERCONSHEET")}
              submitbuttonname={props.t("Templates.Protocoltemplates.IDS_LBL_SUBMIT")}
              closeuttonname={props.t("Templates.Protocoltemplates.IDS_LBL_CLOSE", { param: "" })}
              closeModel={closeModel}
              onsavecancelformodal={onsavecancelformodal}
              t={props.t}
            /></Suspense>
        }
      </div>
      <PageLoader isloader={IsLoader} />
    </div>
  );
};

export default withAlert()(withTranslation(Sheetorder));
