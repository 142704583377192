import React from "react";
import { NavLink } from "react-router-dom";
import { withAlert } from "react-alert";
import { withTranslation } from "react-multi-lang";

import {
    Silentauditobject, Servicecalls, Getuserprojectfromsession,
    Getuserworkflowfromsession,
    orderType
} from "./../custom_functions/Functions.jsx";
import store from "../redux_store/store/index";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { CustomButtonCell } from "./columnMenu.jsx";
import {
    Shareorder,
    Downloadfile,
    Deletefile,
    Viewfile
} from "../custom_functions/ManipulationFunction";
import {
    Customordercells,

} from "./CustomDetailviewCell";
import { MyGlobalContext } from "../custom_modules/orders/Sheetorder";
import { isMultitenant } from "../custom_functions/Constance.jsx";

let rights;
class PendingViewCell extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: this.props.dataItem["link"],
            filetype: this.props.dataItem["filetype"],
            isAssignedOrder:
                this.props.dataItem["assignedto"] === undefined ? false : true,
            orderflag: this.props.dataItem["orderflag"],
        };
        this.props = props;
    }


    componentDidMount() {
        this._isMounted = true;

        rights = this.context;
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setState({
            value: nextProps.dataItem["link"],
            filetype: this.props.dataItem["filetype"],
            isAssignedOrder:
                this.props.dataItem["assignedto"] === undefined ? false : true,
            orderflag: this.props.dataItem["orderflag"],
        });
    }
    onAuditValidateError(res) { }

    onAuditValidateFailure(res) { }
    setClickPopover(e, event) {
        this.setState({
            popOverLeft: e.clientX,
            popOverTop: e.clientY,
            eventKey: event === this.state?.eventKey ? false : event
        })
    }
    handleMouseOut = () => {
        let body = document.getElementsByClassName("modal-open");
        if (!body.length) {
            this.setState({
                eventKey: false
            })
        }
    };

    onbuttonclick(e) {

        let passObjDet = {
            objsilentaudit: Silentauditobject(
                "copy",
                "Sheet Orders",
                this.props.t("Orders.Sheetorders.IDS_AUDIT_COPYORDER", {
                    screen: orderType(this.state.filetype),
                    batchid: this.props.dataItem.batchid,
                    user: store.getState().Loggeduser.username
                })
            ),
        };
        Servicecalls(
            "" + document.getElementById("ELNname").value + "/AuditTrail/silentandmanualRecordHandler",
            passObjDet,
            this.onAuditValidateError.bind(this),
            this.onAuditValidateFailure.bind(this)
        );
        this.props.alert.success(this.props.t("Orders.Sheetorders.IDS_MSG_COPYLINK", {
            batchid: this.props.dataItem.batchid,
        }));
    }

    onUnShareClick = (event, item) => {
        this.props.openunsharelist(item);
    };

    oncancelledorder = (event, item) => {
        this.props.oncancelledorderevent(item)
    }

    render() {
        return (
            <div onMouseLeave={this.handleMouseOut}>
                {this.props.dataItem[this.props.keyfield] !== undefined ?
                    (<div className="actionsBtn flex-centered padd-actionsBtn pr-3" >

                        <div className="processOrderBtn no-margin-process rejectColm">
                            <CustomButtonCell
                                {...this.props}
                                keyfield={this.props.keyfield}
                                onclick={this.props.onorderopen}
                                screen={"Sheet"}
                            />
                        </div>

                        {(this.props.dataItem.order.ordercancell === undefined || this.props.dataItem.order.ordercancell === 0) && Number(this.props.dataItem.filecode) !== -1 ?
                            <div className="group-hover-icon">
                                <button className="" style={{backgroundColor: "transparent", color: "#1970eb", border : "0"}} onClick={(event) => this.setClickPopover(event, this.props.dataItem.order.batchid)}><i className="fa fa-ellipsis-v"></i></button>
                                {this.state?.eventKey === this.props.dataItem.order.batchid && Number(this.props.dataItem.filecode) !== -1 &&
                                    <div className="list-view-btn-group" style={{ "left": this.state.popOverLeft, "top": this.state.popOverTop }}>
                                        <React.Fragment>
                                            <CopyToClipboard text={this.state.value}>
                                                <button title="copy link" className="grid-icons"
                                                    onClick={this.onbuttonclick.bind(this)}>
                                                    <i className="fas fa-copy"></i>
                                                </button>
                                            </CopyToClipboard>
                                            {(this.props.dataItem.sharetome === undefined || this.props.dataItem.sharetome === null) &&
                                                <Shareorder
                                                    keyfield={this.props.dataItem[this.props.keyfield]}
                                                    isshared={false}
                                                    props={this.props}
                                                    passbackorder={this.props.passbackorder}
                                                />
                                            }
                                            {this.props.dataItem.order !== undefined &&
                                                this.props.dataItem.order.sharebyme !== undefined &&
                                                this.props.dataItem.order.sharebyme !== null && this.props.dataItem.order.sharebyme.length > 0 &&
                                                (<button title={"Unshare"} className="btn grid-icons" onClick={event => this.onUnShareClick(event, this.props.dataItem.order)}>
                                                    <span className="fa-stack fa-sm fa-stack-size">
                                                        <i className="fa fa-share-alt fa-stack-1x"></i>
                                                        <i className="fa fa-ban  fa-stack-2x"></i>
                                                    </span>
                                                </button>)}
                                            {Number(this.props.dataItem.filecode) !== -1 &&
                                                <Customordercells
                                                    // {...props}
                                                    keyfield={this.props.keyfield}
                                                    props={this.props}
                                                />
                                            }
                                            <NavLink
                                                className="grid-icons"
                                                target="_blank"
                                                title="Open in New Tab"
                                                onClick={() => {
                                                    let order = this.props.dataItem.order;
                                                    let projects = Getuserprojectfromsession();
                                                    let workflow = Getuserworkflowfromsession();
                                                    order.lstproject = projects;
                                                    order.lstworkflow = workflow;
                                                    let passdata = {
                                                        order: order, rights: rights, user: store.getState().Loggeduser,
                                                        tenantid: sessionStorage.getItem("tenantid"), token: sessionStorage.getItem("Token"),
                                                        loginTime: sessionStorage.getItem("LoginTime"), loggedfrom: sessionStorage.getItem("loggedfrom"),
                                                        azuretoken: sessionStorage.getItem("azuretoken"), tenantdomain: sessionStorage.getItem("tenantdomain"),
                                                        screen: "Sheet",
                                                        multiusergroups: sessionStorage.multiusergroups,
                                                        userpasscodeencrypted: sessionStorage.userpasscodeencrypted,
                                                        packagetype: isMultitenant === 0 ? "3" : sessionStorage.getItem("packagetype"),
                                                        teamusercode: sessionStorage.getItem("teamusercode"),
                                                    };
                                                    localStorage.setItem("linkedorder", JSON.stringify(passdata));
                                                }}
                                                to={{
                                                    pathname: "/vieworder",
                                                    state: { order: this.props.dataItem.order, rights: rights },

                                                }}
                                            >
                                                <i className="fa fa-external-link-alt"></i>
                                            </NavLink>

                                            {this.props.dataItem.order.canuserprocess ?
                                                <React.Fragment>

                                                    <button title="Cancel Order" className="grid-icons new-close" onClick={event => this.oncancelledorder(event, this.props.dataItem)}>
                                                        {/* <svg fill="#0e5bca" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M367.2 412.5L99.5 144.8C77.1 176.1 64 214.5 64 256c0 106 86 192 192 192c41.5 0 79.9-13.1 111.2-35.5zm45.3-45.3C434.9 335.9 448 297.5 448 256c0-106-86-192-192-192c-41.5 0-79.9 13.1-111.2 35.5L412.5 367.2zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256z" /></svg> */}
                                                        <i className="fa fa-times mr-1" aria-hidden="true"></i>
                                                    </button>
                                                </React.Fragment> : false
                                            }

                                        </React.Fragment>
                                    </div>
                                }
                            </div>
                            : Number(this.props.dataItem.filecode) !== -1 &&
                            <Customordercells
                                // {...props}
                                keyfield={this.props.keyfield}
                                props={this.props}
                            />

                        }
                    </div>)
                    : this.props.dataItem !== undefined && this.props.dataItem.file !== undefined &&
                    <div className="actionsBtn 11 align-items-center ">
                        <Downloadfile file={this.props.dataItem.file}
                            url={this.props.filedownloadurl} />

                        <Deletefile
                            file={this.props.dataItem.file}
                            ondeletefile={this.props.ondeletefile} />

                        <Viewfile
                            file={this.props.dataItem.file}
                            url={this.props.filedownloadurl} />
                    </div>
                }
            </div>
        );
    }
}
PendingViewCell.contextType = MyGlobalContext;
export default withAlert()(withTranslation(PendingViewCell));