import React, { Component, Suspense } from "react";
import { withTranslation } from "react-multi-lang";
// import "@progress/kendo-ui";
// import { Spreadsheet } from "@progress/kendo-spreadsheet-react-wrapper";
import {
  userdetails,
  Silentauditobject,
  AuditTrailValidation,
  Manualauditobject,
  Servicecalls, onAuditHandler
} from "./../custom_functions/Functions.jsx";

// import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
// import request from "superagent";
import { withAlert } from "react-alert";
// import KendoGridView from "./KendoGridView.jsx";
// import Audit from "./../custom_modules/Audit.jsx";
import $ from "jquery";
import { confirmAlert } from "react-confirm-alert";
import store from "./../redux_store/store/index";

const KendoGridView = React.lazy(() => import("./KendoGridView.jsx"));
const Audit = React.lazy(() => import("./../custom_modules/Audit.jsx"));
// class ModalKendoSpread extends Component {
//     constructor(props) {
//         super(props);

//         this.spreadSheetRef = React.createRef();
//         this.state = { OrderLstfromTestData: [] }
//     }

//     componentDidUpdate(prevProps, prevState) {
//         if (this.props.sheetData) {
//             if (Object.keys(this.props.sheetData).length > 0 && this.spreadSheetRef.current) {
//                 let spreadSheet = this.spreadSheetRef.current.widgetInstance;
//                 spreadSheet.fromJSON(JSON.parse(this.props.sheetData));
//             }
//         }
//     }

//     render() {
//         return (
//             <div>
//                 {this.props.SpreadSheetModalvisible && (
//                     <Dialog
//                         title={this.props.t("DownloadReport.ID_DATAINEXCEL")}
//                         onClose={() => this.props.toggleDialog("hide", "spreadsheet")}
//                         width={850} height={580} >
//                         <span className="pt-1" id="ID-ModelKendoSpread">
//                             <Spreadsheet
//                                 ref={this.spreadSheetRef}
//                                 toolbar={false}
//                                 sheetsbar={false}
//                             />
//                         </span>
//                         <DialogActionsBar>
//                             <button className="k-button" onClick={() => this.props.toggleDialog("copy", "spreadsheet")}>{this.props.t("DownloadReport.ID_DATACOPYBUTTON")}</button>
//                             <button className="k-button" onClick={() => this.props.toggleDialog("hide", "spreadsheet")}>{this.props.t("DownloadReport.ID_CLOSE")}</button>
//                         </DialogActionsBar>
//                     </Dialog>
//                 )}
//             </div>
//         );
//     }
// }

// export default ModalKendoSpread;

// class ModalDocxDirectoryClass extends Component {
//     constructor(props) {
//         super(props);
//         this.state = {
//             selectedDirData: props.LSDocDirectoryResponse.LSDocDirectoryLst[0]
//         };
//         this.ELNurl = document.getElementById("ELNname").value;
//     }

//     setSelectedDirData = (selectedDirData) => {
//         this.setState({ selectedDirData });
//     }

//     render() {
//         return (<React.Fragment>
//             {this.props.DocxDirModalvisible && (
//                 <Dialog
//                     title={<span className="text-uppercase">{this.props.DocxDirModalType === "openFile" ? this.props.t("DownloadReport.ID_OPENFILE") : this.props.DocxDirModalType === "saveFile" ? this.props.t("DownloadReport.ID_SAVEFILE") : this.props.t("DownloadReport.ID_SAVEAS")}</span>}
//                     onClose={() => this.props.toggleDialog("hide", "docxdir")}
//                     width={850} height={580} >
//                     <div className="pt-1">
//                         {this.props.DocxDirModalType === "saveFile" || this.props.DocxDirModalType === "saveAs" ?
//                             <div>
//                                 <span className="p-1 float-right">
//                                     <button className="ButtonAsAnchorCss" onClick={() => this.props.addDirectoryToggleDialog("subDir", this.state.selectedDirData)}>
//                                         <i className="fas fa-plus-square pr-1" aria-hidden="true"></i> {this.props.t("DownloadReport.ID_ADDSUBDIRECTORY")}
//                                     </button>
//                                 </span>
//                                 {this.state.selectedDirData &&//this.props.LSDocDirectoryResponse && this.props.LSDocDirectoryResponse.LSDocDirectoryLst.length > 1 &&
//                                     this.state.selectedDirData.directorytype !== 0 &&
//                                     <span className="p-1 float-right">
//                                         <button className="ButtonAsAnchorCss" onClick={() => this.props.addDirectoryToggleDialog("dir", this.state.selectedDirData)}>
//                                             <i className="fas fa-plus pr-1" aria-hidden="true"></i> {this.props.t("DownloadReport.ID_ADDDIRECTORY")}
//                                         </button>
//                                     </span>}
//                                 <span className="clearfix"></span>
//                             </div>
//                             : false}
//                         <div>
//                             {this.props.LSDocDirectoryResponse ?
//                                 <DocxDirDesign LSDocDirectoryResponse={this.props.LSDocDirectoryResponse}
//                                     setSelectedData={this.setSelectedDirData}
//                                     selectedDirData={this.state.selectedDirData || {}}
//                                     setOpenDocxReport={this.props.setOpenDocxReport}
//                                     DocxDirModalType={this.props.DocxDirModalType}
//                                 />
//                                 : false
//                             }
//                         </div>
//                     </div>
//                     <DialogActionsBar>
//                         <div className="modal-footer">
//                             {this.props.DocxDirModalType === "saveFile" || this.props.DocxDirModalType === "saveAs" ?
//                                 <button className="btn btn-user btn-primary-blue" onClick={() => this.props.addDirectoryToggleDialog(this.props.DocxDirModalType, this.state.selectedDirData)}>{this.props.t("DownloadReport.ID_SAVE")}</button>
//                                 // <button className="btn btn-user btn-primary-blue" onClick={() => this.props.addDirectoryToggleDialog("saveDocx", this.state.selectedDirData)}>{this.props.t("DownloadReport.ID_SAVE")}</button>
//                                 : false//<button className="btn btn-user btn-primary-blue" onClick={() => this.props.setOpenDocxReport(this.state.selectedDirData)}>{this.props.t("DownloadReport.ID_OPEN")}</button>
//                             }
//                             <button className="btn btn-user btn-cancel" onClick={() => this.props.toggleDialog("", "docxdir")}>{this.props.t("DownloadReport.ID_CLOSE")}</button>
//                         </div>
//                     </DialogActionsBar>
//                 </Dialog>)
//             }
//         </React.Fragment>
//         );
//     }
// }

// export const ModalDocxDirectory = withTranslation(ModalDocxDirectoryClass);

class ModalDocxDirectoryClass extends Component {
  constructor(props) {
    super(props);
    this.ELNurl = document.getElementById("ELNname").value;
    this.state = {
      selectedDirData: props.LSDocDirectoryResponse.LSDocDirectoryLst[0],
      AddFieldStatus: false,
      SubmittedDocumentName: false,
      viewDesignType: "icon",
      Records: [],
      PrimaryRecords: [],

      // filtedNullData: props.LSDocDirectoryResponse.LSDocReportsLst.filter(item => item.fileName !== null && item.fileName !== undefined),
      Columns: [
        {
          header: this.props.t("Reports.IDS_LBL_REPORTCODE"),
          // datatype: "usergroupcode",
          datatype: "docReportsCode",
          rowselector: false,
          visible: false,
          filterable: false,
          className: "clsModulename",
        },
        {
          header: this.props.t("Reports.IDS_LBL_REPORTNAME"),
          datatype: "fileName",
          rowselector: false,
          visible: true,
          filterable: true,
          className: "clsModulename",
          width: "25%",
        },
        {
          header: this.props.t("Reports.IDS_LBL_CREATEDDATE"),
          datatype: "createdate",
          rowselector: false,
          visible: true,
          filterable: true,
          className: "clsModulename",
          width: "25%",
        },
        {
          header: this.props.t("Reports.IDS_LBL_TEMPLATE"),
          datatype: "isTemplate",
          rowselector: false,
          visible: true,
          filterable: true,
          className: "clsModulename",
          width: "25%",
        },
      ],
      filterNullDataonDirectBase: [],
    };
    this.searchChangeRef = React.createRef();
    this.inputDocumentNameRef = React.createRef();
    this.ELNurl = document.getElementById("ELNname").value;
  }

  setSelectedDirData = (selectedDirData) => {
    const { filterNullData } = this.state;
    if (filterNullData !== undefined && filterNullData !== null) {
      let filterNullDataonDirectBase = [];
      if (selectedDirData.directoryname === "Team Space") {
        filterNullDataonDirectBase = filterNullData.filter(
          (item) =>
            item.docdirectorycode === selectedDirData.docdirectorycode &&
            this.props.LstLSuserMaster.includes(item.createdBy)
        );
      } else if (selectedDirData.directoryname === "My Space") {
        filterNullDataonDirectBase = filterNullData.filter(
          (item) =>
            item.docdirectorycode === selectedDirData.docdirectorycode &&
            item.createdBy === parseInt(sessionStorage.User)
        );
      } else {
        filterNullDataonDirectBase = filterNullData.filter(
          (item) => item.docdirectorycode === selectedDirData.docdirectorycode
        );
      }
      this.setState({ selectedDirData, filterNullDataonDirectBase });
    } else {
      this.setState({ selectedDirData });
    }
  };

  DocxPresentinDir = () => {
    if (this.state.filteredData === undefined) {
      return this.props.LSDocDirectoryResponse.LSDocReportsLst.find(
        (item) =>
          item.docdirectorycode === this.state.selectedDirData.docdirectorycode
      );
    } else {
      return true;
    }
  };

  getSelectedClassDynamic = (item) => {
    let cls = "card";
    if ("docReportsCode" in item && this.props.fileRes.fileName !== undefined) {
      if (item.fileName === this.props.fileRes.fileName.replace(".docx", "")) {
        cls += " docxDirectoryDivSelected";
      }
    }
    return cls;
  };

  searchChangeEvent = () => {
    let value = this.searchChangeRef.current.value;

    if (value !== undefined && value !== "") {
      const { LSDocReportsLst, LSDocDirectoryLst } =
        this.props.LSDocDirectoryResponse;
      const { selectedDirData } = this.state;

      let filteredData = [];
      if (selectedDirData.docdirectorycode === 1) {
        filteredData = LSDocReportsLst.filter(
          (item) =>
            item.fileName !== null &&
            item.fileName.toUpperCase().includes(value.toUpperCase())
        );
      } else {
        let FilterFrom = [selectedDirData.docdirectorycode];
        let canProcced = true;
        let indexOfDocdirectory = 0;
        do {
          let currentDocdirectorycode = FilterFrom[indexOfDocdirectory];
          let tempArray = [];
          LSDocDirectoryLst.filter((item) =>
            item.parentdirectory !== null &&
              item.parentdirectory === currentDocdirectorycode
              ? tempArray.push(item)
              : false
          );
          if (tempArray.length > 0) {
            tempArray.map((item) => FilterFrom.push(item.docdirectorycode));
            // indexOfDocdirectory = FilterFrom.indexOf(currentDocdirectorycode);
            if (indexOfDocdirectory === FilterFrom.length - 1) {
              canProcced = false;
            } else {
              // currentDocdirectorycode = FilterFrom[indexOfDocdirectory + 1];
              indexOfDocdirectory++;
            }
          } else {
            // canProcced = false;
            if (indexOfDocdirectory === FilterFrom.length - 1) {
              canProcced = false;
            } else {
              indexOfDocdirectory++;
            }
          }
        } while (canProcced);
        filteredData = LSDocReportsLst.filter(
          (item) =>
            item.fileName !== null &&
            item.fileName.toUpperCase().includes(value.toUpperCase()) &&
            FilterFrom.includes(item.docdirectorycode)
        );
      }
      this.setState({ filteredData });
    } else {
      this.setState({ filteredData: undefined });
    }
  };

  newDocxOnSuccess = (response) => {
    // const response = JSON.parse(res.text);
    if (
      response.objResponse !== undefined &&
      response.objResponse.status === false
    ) {
      this.props.alert.error(
        this.props.t("Common.IDS_MSG_VALIDATION", { param: "" })
      );
    } else {
      if (response.status === "success") {
        response["shouldSaveDocx"] = false;
        // const selectedDirData = response.LSDocDirectoryLst.map(item => item.directoryname === this.state.selectedDirData.directoryname)
        this.props.alert.success(
          this.props.t("Reports.IDS_MSG_SAVEDSUCCESSFULLY")
        );
        this.props.setDocumentDirData({
          ...response,
          selectedDirData: this.state.selectedDirData,
        });
        this.setState({ SubmittedDocumentName: true });
        let passObject = {
          objsilentaudit: Silentauditobject(
            "insert",
            "reports",
            this.props.t("Reports.IDS_AUDIT_NEWCHILDDIRECTORYOFPARENT", {
              childDir: response.newDocx.fileName,
              parentDir: this.state.selectedDirData.directoryname,
            })
          ),
        };

        if (this.props.DocxDirModalType === "saveAs") {
          passObject = {
            objsilentaudit: Silentauditobject(
              "update",
              "reports",
              this.props.t("Reports.IDS_AUDIT_NEWCHILDDIRECTORYOFPARENTUPDATE", {
                childDir: response.newDocx.fileName,
                parentDir: this.state.selectedDirData.directoryname,
              })
            ),
          };

        }
        Servicecalls(
          "" + this.ELNurl + "/AuditTrail/AuditConfigurationrecord",
          passObject,
          this.onAuditValidateError.bind(this),
          this.onAuditValidateFailure.bind(this)
        );
      } else {
        if (response.status === "already exist") {
          // this.directoryNameStatusSpanRef.current.innerHTML = this.props.t("DownloadReport.ID_ALREADYEXIST") + " !";
          this.props.alert.error(
            this.props.t("Reports.ID_LBL_ALREADYEXIST") + " !"
          );
        }
      }
    }
  };

  deleteDirectoryOnSuccess = (response) => {
    //const response = JSON.parse(res.text);
    if (response.status === "success") {
      // const selectedDirData = response.LSDocDirectoryLst.map(item => item.directoryname === newDirectoryName)
      this.props.alert.success(
        this.props.t("Reports.IDS_MSG_DELETEDSUCCESSFULLY")
      );
      this.props.setDocumentDirData({
        ...response,
        selectedDirData: this.state.selectedDirData,
      });
      this.setState({ AddFieldStatus: false });
    }

  };

  updateDirectoryOnSuccess = (response) => {
    //const response = JSON.parse(res.text);
    if (response.status === "success") {
      // const selectedDirData = response.LSDocDirectoryLst.map(item => item.directoryname === newDirectoryName)
      this.props.alert.success(
        this.props.t("Reports.IDS_MSG_UPDATEDDIRECTORYNAME")
      );
      this.props.setDocumentDirData({
        ...response,
        selectedDirData: this.state.selectedDirData,
      });
      this.setState({ AddFieldStatus: false });
    }
  };

  newDirectoryOnSuccess = (response) => {

    let passObjDetAudit = store.getState().Loggeduser;
    passObjDetAudit.objsilentaudit = Silentauditobject(
      "insert",
      "reports",
      this.props.t("Reports.IDS_AUDIT_NEWCHILDDIRECTORYOFPARENT", {
        childDir: this.state.newDirectoryName,
        parentDir: this.state.selectedDirData.directoryname,
      })
    );
    Servicecalls(
      "" + this.ELNurl + "/AuditTrail/silentandmanualRecordHandler",
      passObjDetAudit,
      (res) => { },
      (res) => { }
    );
    //const response = JSON.parse(res.text);
    if (response.status === "success") {
      // const selectedDirData = response.LSDocDirectoryLst.map(item => item.directoryname === newDirectoryName)
      this.props.alert.success(
        this.props.t("Reports.IDS_MSG_SAVEDSUCCESSFULLY")
      );
      this.props.setDocumentDirData({
        ...response,
        selectedDirData: this.state.selectedDirData,
      });
      this.setState({ AddFieldStatus: false });
    } else {
      if (response.status === "already exist") {
        this.props.alert.error(
          this.props.t("Reports.ID_LBL_ALREADYEXIST") + " !"
        );
      }
    }
  };

  onAuditValidateError(res) { }

  onAuditValidateFailure(res) { }

  updateDocumentOnSuccess = (response) => {
    if (response.status === "success") {
      this.props.alert.success(
        this.props.t("Reports.IDS_MSG_UPDATEDDOCUMENTNAME")
      );
      this.props.setDocumentDirData({ ...response });
    } else {
      if (response.status === "already exist") {
        this.props.alert.error(
          this.props.t("Reports.ID_LBL_ALREADYEXIST") + " !"
        );
      }
    }
  };
  deleteDocumentOnSuccess = (response) => {
    if (response.status === "success") {
      this.props.alert.success(
        this.props.t("Reports.IDS_MSG_DELETEDSUCCESSFULLY")
      );
      this.props.setDocumentDirData({ ...response });

    } else {
    }
  };

  insertDirectoryOrDocumentInto = (NameInputRef, type) => {
    if (
      (this.props.DocxDirModalType === "saveFile" ||
        this.props.DocxDirModalType === "saveAs") &&
      type === "file"
    ) {
      const newDocumentName = NameInputRef.current.value.trim();
      if (newDocumentName !== "") {
        let rtnObj = {};
        const { selectedDirData } = this.state;
        // const {directoryname} = selectedDirData;
        const { fileRes } = this.props;
        rtnObj["fileName"] = newDocumentName;
        rtnObj["fileHashName"] =
          fileRes.hashKey.length > 36
            ? fileRes.hashKey.slice(0, 36)
            : fileRes.hashKey;
        rtnObj["docxReportFileName"] = fileRes.fileName.split(".")[0];
        rtnObj["docdirectorycode"] = selectedDirData.docdirectorycode;
        if (this.props.DocxDirModalType === "saveAs") {
          rtnObj["SaveAs"] = true;
        }
        // let passObjDet = { ...userdetails(), ...rtnObj, objsilentaudit: Silentauditobject("insert", "reports", rtnObj) };
        let Audit = false;
        let passObjDet = {
          ...userdetails(),
          ...rtnObj,
        };
        if (this.props.DocxDirModalType === "saveFile") {
          Audit = AuditTrailValidation("IDS_MDL_REPORTS", "IDS_SCN_REPORTS", "IDS_TSK_SAVE");
          passObjDet.objmanualaudit = Manualauditobject("insert", "reports");
        } else if (this.props.DocxDirModalType === "saveAs") {
          Audit = AuditTrailValidation("IDS_MDL_REPORTS", "IDS_SCN_REPORTS", "IDS_TSK_SAVEAS");
          passObjDet.objmanualaudit = Manualauditobject("update", "reports");
        }
        if (Audit) {


          $("#downloadReportsDirectoryAuditMasterModelId").modal("show");
          this.setState({
            AuditUrl: this.ELNurl + "/reports/updateReportDocxName",
            AuditObject: passObjDet,
            AuditPassfunction: this.newDocxOnSuccess,
            AuditFailfunction: () => console.error(),
          });
        } else {
          let passObjDet = {
            ...userdetails(),
            ...rtnObj,
            objsilentaudit: Silentauditobject("insert", "reports", rtnObj),
          };
          //   request
          //     .post(this.ELNurl + "/reports/updateReportDocxName")
          //     .set({
          //       Authorization: $.session.get("Token"),
          //       "X-TenantID": $.session.get("tenantid"),
          //       Accept: "application/json",
          //     })
          //     .send(passObjDet)
          //     .then((res) => {
          //       this.newDocxOnSuccess(JSON.parse(res.text));
          //     })
          //     .catch((res) => {
          //       console.error(res.text);
          //     });

          Servicecalls(
            "" + this.ELNurl + "/reports/updateReportDocxName",
            passObjDet,
            (res) => {
              this.newDocxOnSuccess(res);
            },
            (res) => {
              // console.error(res.text);
            }
          );
        }
      } else {
        this.props.alert.error(
          this.props.t("Reports.IDS_MSG_CANNOTSUBMITEMPTYVALUE")
        );

      }
    } else if (type === "directory") {
      if (NameInputRef.current !== null) {
        const newDirectoryName = NameInputRef.current.value.trim();
        this.setState({ newDirectoryName: newDirectoryName })
        if (newDirectoryName !== "") {
          let rtnObj = {};
          const { selectedDirData } = this.state;
          rtnObj["parentdirectory"] = selectedDirData.docdirectorycode;
          if (selectedDirData.directorytype === 0) {
            rtnObj["directorytype"] = 1;
          } else {
            rtnObj["directorytype"] = 2;
          }
          rtnObj["directoryname"] = newDirectoryName;
          rtnObj["status"] = 1;
          let passObjDet = {};
          let Audit = AuditTrailValidation(
            "IDS_MDL_REPORTS",
            "IDS_SCN_REPORTS",
            "IDS_TSK_DIRECTORYSAVE"
          );
          if (this.props.DocxDirModalType === "saveFile") {
            passObjDet.objmanualaudit = Manualauditobject("insert", "reports");
          }
          if (Audit) {
            let passObjDet = {
              ...userdetails(),
              ...rtnObj,


            };
            $("#downloadReportsDirectoryAuditMasterModelId").modal("show");
            this.setState({
              AuditUrl: this.ELNurl + "/reports/addDocxDirectory",
              AuditObject: passObjDet,
              AuditPassfunction: this.newDirectoryOnSuccess,
              AuditFailfunction: () => console.error(),
            });
          } else {
            let passObjDet = {
              ...userdetails(),
              ...rtnObj,
              objsilentaudit: Silentauditobject(
                "insert",
                "reports",
                
              ),
            };

            Servicecalls(
              "" + this.ELNurl + "/reports/addDocxDirectory",
              passObjDet,
              (res) => {
                this.newDirectoryOnSuccess(res);
              },
              (res) => {
                // console.error(res);
              }
            );

            // request
            //   .post(this.ELNurl + "/reports/addDocxDirectory")
            //   .set({
            //     Authorization: $.session.get("Token"),
            //     "X-TenantID": $.session.get("tenantid"),
            //     Accept: "application/json",
            //   })
            //   .send(passObjDet)
            //   .then((res) => {
            //     this.newDirectoryOnSuccess(JSON.parse(res.text));
            //   })
            //   .catch((res) => {
            //     console.error(res);
            //   });
          }
        }
      } else {
        this.props.alert.error(
          this.props.t("Reports.IDS_MSG_CANNOTSUBMITEMPTYVALUE")
        );

      }
    } else if (type === "updateDirectory") {
      const newDirectoryName = NameInputRef.fileName;
      const { selectedDirData } = this.state;
      let Audit = AuditTrailValidation("IDS_MDL_REPORTS", "IDS_SCN_REPORTS", "IDS_TSK_DIRECTORYSAVE");
      let AuditComment = this.props.t("Reports.IDS_AUDIT_NEWCHILDDIRECTORYOFPARENT", { "childDir": newDirectoryName, "parentDir": selectedDirData.directoryname });
      let rtnObj = NameInputRef;
      if (Audit) {
        let passObjDet = {
          ...userdetails(),
          rtnObj,
          objsilentaudit: Silentauditobject("update", "reports", AuditComment),
          objmanualaudit: Manualauditobject("insert", "reports"),
        };
        $("#downloadReportsDirectoryAuditMasterModelId").modal("show");
        if (rtnObj["status"] === 0) {
          this.setState({
            AuditUrl: this.ELNurl + "/reports/updateDocxDirectory",
            AuditObject: passObjDet,
            AuditPassfunction: this.deleteDirectoryOnSuccess,
            AuditFailfunction: () => console.error(),
          });
        } else {
          this.setState({
            AuditUrl: this.ELNurl + "/reports/updateDocxDirectory",
            AuditObject: passObjDet,
            AuditPassfunction: this.updateDirectoryOnSuccess,
            AuditFailfunction: () => console.error(),
          });
        }
      } else {
        let passObjDet = {
          ...userdetails(),
          rtnObj,
          objsilentaudit: Silentauditobject("update", "reports", AuditComment),
        };

        Servicecalls(
          "" + this.ELNurl + "/reports/updateDocxDirectory",
          passObjDet,
          (res) => {
            if (rtnObj["status"] === 0) {
              this.deleteDirectoryOnSuccess(res);
            } else {
              this.updateDirectoryOnSuccess(res);
            }
          },
          (res) => {
            // console.error(res);
          }
        );
      }
    } else if (type === "updateDocument") {
      const newDirectoryName = NameInputRef.fileName;
      const { selectedDirData } = this.state;
      let Audit = AuditTrailValidation("IDS_MDL_REPORTS", "IDS_SCN_REPORTS", "IDS_TSK_DIRECTORYSAVE");
      let AuditComment = this.props.t("Reports.IDS_AUDIT_NEWCHILDDIRECTORYOFPARENT", { "childDir": newDirectoryName, "parentDir": selectedDirData.directoryname });
      let AuditComment1 = this.props.t("Reports.IDS_AUDIT_DIRECTORYDELETE", {
        "directory": newDirectoryName
      });

      let rtnObj = NameInputRef;
      if (Audit) {
        let passObjDet = {
          ...userdetails(),
          rtnObj,

        };
        $("#downloadReportsDirectoryAuditMasterModelId").modal("show");
        if (rtnObj["status"] === 0) {
          passObjDet.objsilentaudit = Silentauditobject("delete", "reports", AuditComment1);

          this.setState({
            AuditUrl: this.ELNurl + "/reports/renameDeleteDocxDirectory",
            AuditObject: passObjDet,
            AuditPassfunction: this.deleteDocumentOnSuccess,
            AuditFailfunction: () => console.error(),
          });
        } else {
          this.setState({
            AuditUrl: this.ELNurl + "/reports/renameDeleteDocxDirectory",
            AuditObject: passObjDet,
            AuditPassfunction: this.updateDocumentOnSuccess,
            AuditFailfunction: () => console.error(),
          });
        }
      } else {
        let passObjDet = {
          ...userdetails(),
          rtnObj,
        }
        if (rtnObj["status"] === 0) {

          passObjDet.objsilentaudit = Silentauditobject("delete", "reports", AuditComment1);

        }
        else {

          passObjDet.objsilentaudit = Silentauditobject("update", "reports", AuditComment);

        }
        Servicecalls(
          "" + this.ELNurl + "/reports/renameDeleteDocxDirectory",
          passObjDet,
          (res) => {
            if (rtnObj["status"] === 0) {
              this.deleteDocumentOnSuccess(res);
            } else {
              this.updateDocumentOnSuccess(res);
            }
          },
          (res) => {
            // console.error(res);
          }
        );

        // request
        //   .post(this.ELNurl + "/reports/renameDeleteDocxDirectory")
        //   .set({
        //     Authorization: $.session.get("Token"),
        //     "X-TenantID": $.session.get("tenantid"),
        //     Accept: "application/json",
        //   })
        //   .send(passObjDet)
        //   .then((res) => {
        //     if (rtnObj["status"] === 0) {
        //       this.deleteDocumentOnSuccess(JSON.parse(res.text));
        //     } else {
        //       this.updateDocumentOnSuccess(JSON.parse(res.text));
        //     }
        //   })
        //   .catch((res) => {
        //     console.error(res);
        //   });
      }
    }
  };

  updateAddStatus = () => {
    // if (this.state.selectedDirData.indent < 7) {
    this.setState({ AddFieldStatus: !this.state.AddFieldStatus });
    // } else {
    //     this.props.alert.error(this.props.t("DownloadReport.ID_5LEVELSMAX"));
    // }
  };

  GridClicked = (Recordsel) => {
    this.setState({
      Records: Recordsel,
    });
  };

  GridSelected = (Recordsel, PrimaryRecordsel) => {
    this.setState({
      Records: Recordsel,
      PrimaryRecords: PrimaryRecordsel,
    });
    this.props.setOpenDocxReport(Recordsel);
  };

  sortByDataTypesAndBuildDesign = () => {
    let sevenDaysGap = new Date();
    sevenDaysGap.setDate(sevenDaysGap.getDate() - 7);
    let oneDaysGap = new Date();
    oneDaysGap.setDate(oneDaysGap.getDate() - 1);
    let returnData = [];
    let currentDateData = [];
    let lastWeekDateData = [];
    let otherDateData = [];
    const LstLSuserMaster = this.props.LstLSuserMaster || [];
    if (this.state.filteredData !== undefined) {
      let filterNullData = [];
      if (
        this.state.selectedDirData.directoryname === "Team Space" &&
        parseInt(sessionStorage.User) !== 1
      ) {
        filterNullData = this.state.filteredData.filter(
          (item) =>
            item.fileName !== null &&
            item.fileName !== undefined &&
            item.docdirectorycode ===
            this.state.selectedDirData.docdirectorycode &&
            LstLSuserMaster.includes(item.createdBy)
        );
      } else if (
        this.state.selectedDirData.directoryname === "My Space" &&
        parseInt(sessionStorage.User) !== 1
      ) {
        filterNullData = this.state.filteredData.filter(
          (item) =>
            item.fileName !== null &&
            item.fileName !== undefined &&
            item.docdirectorycode ===
            this.state.selectedDirData.docdirectorycode &&
            item.createdBy === parseInt(sessionStorage.User)
        );
      } else if (
        this.state.selectedDirData.directoryname !== "Team Space" &&
        this.state.selectedDirData.directoryname !== "My Space"
      ) {
        filterNullData = this.state.filteredData.filter(
          (item) =>
            item.fileName !== null &&
            item.fileName !== undefined &&
            item.docdirectorycode ===
            this.state.selectedDirData.docdirectorycode
        );
      }
      currentDateData = filterNullData.filter(
        (item) =>
          new Date() > new Date(item.createdate) &&
          new Date(item.createdate) > new Date(oneDaysGap)
      );
      lastWeekDateData = filterNullData.filter(
        (item) =>
          new Date(oneDaysGap) >= new Date(item.createdate) &&
          new Date(item.createdate) > new Date(sevenDaysGap)
      );
      otherDateData = filterNullData.filter(
        (item) => new Date(item.createdate) <= new Date(sevenDaysGap)
      );
    } else {
      let filterNullData = [];
      if (
        this.state.selectedDirData.directoryname === "Team Space" &&
        parseInt(sessionStorage.User) !== 1
      ) {
        filterNullData =
          this.props.LSDocDirectoryResponse.LSDocReportsLst.filter(
            (item) =>
              item.fileName !== null &&
              item.fileName !== undefined &&
              item.docdirectorycode ===
              this.state.selectedDirData.docdirectorycode &&
              LstLSuserMaster.includes(item.createdBy)
          );
      } else if (
        this.state.selectedDirData.directoryname === "My Space" &&
        parseInt(sessionStorage.User) !== 1
      ) {
        filterNullData =
          this.props.LSDocDirectoryResponse.LSDocReportsLst.filter(
            (item) =>
              item.fileName !== null &&
              item.fileName !== undefined &&
              item.docdirectorycode ===
              this.state.selectedDirData.docdirectorycode &&
              item.createdBy === parseInt(sessionStorage.User)
          );
      } else if (
        this.state.selectedDirData.directoryname !== "Team Space" &&
        this.state.selectedDirData.directoryname !== "My Space"
      ) {
        filterNullData =
          this.props.LSDocDirectoryResponse.LSDocReportsLst.filter(
            (item) =>
              item.fileName !== null &&
              item.fileName !== undefined &&
              item.docdirectorycode ===
              this.state.selectedDirData.docdirectorycode
          );
      }
      currentDateData = filterNullData.filter(
        (item) =>
          new Date() > new Date(item.createdate) &&
          new Date(item.createdate) > new Date(oneDaysGap) &&
          item.lssitemaster === Number($.session.get("Usersite"))
      );
      lastWeekDateData = filterNullData.filter(
        (item) =>
          new Date(oneDaysGap) >= new Date(item.createdate) &&
          new Date(item.createdate) > new Date(sevenDaysGap) &&
          item.lssitemaster === Number($.session.get("Usersite"))
      );
      otherDateData = filterNullData.filter(
        (item) =>
          new Date(item.createdate) <= new Date(sevenDaysGap) &&
          item.lssitemaster === Number($.session.get("Usersite"))
      );
    }
    if (this.state.viewDesignType === "icon") {
      if (currentDateData.length > 0) {
        returnData.push(
          <div
            key={1}
            style={{ width: "100%", display: "flex", flexWrap: "wrap" }}
          >
            <div
              style={{
                width: "100%",
                fontWeight: 600,
                textAlign: "left",
                padding: "5px 0px 0px 10px",
              }}
            >
              {" "}
              Today{" "}
            </div>
            {currentDateData.map((item, index) =>
              this.designDocxTiledView(item, index)
            )}
          </div>
        );
      }
      if (lastWeekDateData.length > 0) {
        returnData.push(
          <div
            key={2}
            style={{ width: "100%", display: "flex", flexWrap: "wrap" }}
          >
            <div
              style={{
                width: "100%",
                fontWeight: 600,
                textAlign: "left",
                padding: "5px 0px 0px 10px",
              }}
            >
              {" "}
              Previous Week{" "}
            </div>
            {lastWeekDateData.map((item, index) =>
              this.designDocxTiledView(item, index)
            )}
          </div>
        );
      }
      if (otherDateData.length > 0) {
        returnData.push(
          <div
            key={3}
            style={{ width: "100%", display: "flex", flexWrap: "wrap" }}
          >
            <div
              style={{
                width: "100%",
                fontWeight: 600,
                textAlign: "left",
                padding: "5px 0px 0px 10px",
              }}
            >
              {" "}
              Earlier{" "}
            </div>
            {otherDateData.map((item, index) =>
              this.designDocxTiledView(item, index)
            )}
          </div>
        );
      }
    }
    return returnData;
  };

  onKeyDownEvenTriggered = (e) => {
    if (e.keyCode === 13) {
      this.insertDirectoryOrDocumentInto(this.inputDocumentNameRef, "file");
    }
  };

  designDocxTiledView = (item, index) => {
    return (
      <div
        key={index}
        className={this.getSelectedClassDynamic(item)}
        title={item.fileName}
        style={{ width: "150px", height: "125px", margin: "15px" }}
        onClick={() => this.props.setOpenDocxReport(item)}
      >
        <div style={{ fontSize: "50px" }}>
          <i
            className="far fa-file-word"
            style={{ color: "#205aab", paddingRight: "10px" }}
            aria-hidden="true"
          ></i>
        </div>
        <div>
          {item.fileName.length > 15
            ? item.fileName.slice(0, 12) + "..."
            : item.fileName}
        </div>
        <div style={{ opacity: "0.5" }}>
          {this.props.fileRes.fileName !== undefined
            ? item.fileName === this.props.fileRes.fileName.replace(".docx", "")
              ? "[" + this.props.t("Reports.IDS_LBL_OPENDOCXDIR") + "]"
              : false
            : false}
        </div>
      </div>
    );
  };

  designDocxTitledNewView = () => {
    if (
      this.state.SubmittedDocumentName === false &&
      this.state.selectedDirData.docdirectorycode !== 1 &&
      (this.props.DocxDirModalType === "saveFile" ||
        this.props.DocxDirModalType === "saveAs")
    ) {
      return (
        <div style={{ width: "100%" }}>
          <div
            className="card"
            title={"click to add"}
            style={{
              width: "175px",
              height: "125px",
              margin: "15px",
              textAlign: "center",
            }}
          >
            <div style={{ fontSize: "45px" }}>
              <i
                className="fa fa-file-word"
                style={{ color: "#205aab", paddingRight: "10px" }}
                aria-hidden="true"
              ></i>
            </div>
            <div>
              <input
                ref={this.inputDocumentNameRef}
                onKeyDown={this.onKeyDownEvenTriggered}
              ></input>
              <i
                className="fas fa-check"
                onClick={() =>
                  this.insertDirectoryOrDocumentInto(
                    this.inputDocumentNameRef,
                    "file"
                  )
                }
                title={this.props.t("Reports.IDS_LBL_SUBMIT")}
                style={{ color: "#205aab", padding: "0px 10px" }}
                aria-hidden="true"
              ></i>
              <i
                className="fas fa-times"
                title={this.props.t("Reports.IDS_LBL_CLOSE")}
                style={{ color: "black", padding: "0px 10px" }}
                aria-hidden="true"
              ></i>
            </div>
          </div>
        </div>
      );
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.LSDocDirectoryResponse.LSDocDirectoryLst.length !==
      this.props.LSDocDirectoryResponse.LSDocDirectoryLst.length ||
      prevProps.LSDocDirectoryResponse.LSDocReportsLst.length !==
      this.props.LSDocDirectoryResponse.LSDocReportsLst.length
    ) {
      const { selectedDirData } = this.state;
      const filterNullData =
        this.props.LSDocDirectoryResponse.LSDocReportsLst.filter(
          (item) => item.fileName !== null && item.fileName !== undefined
        );
      if (filterNullData !== undefined && filterNullData !== null) {
        let filterNullDataonDirectBase = [];
        if (selectedDirData.directoryname === "Team Space") {
          filterNullDataonDirectBase = filterNullData.filter(
            (item) =>
              item.docdirectorycode === selectedDirData.docdirectorycode &&
              this.props.LstLSuserMaster.includes(item.createdBy)
          );
        } else if (selectedDirData.directoryname === "My Space") {
          filterNullDataonDirectBase = filterNullData.filter(
            (item) =>
              item.docdirectorycode === selectedDirData.docdirectorycode &&
              item.createdBy === parseInt(sessionStorage.User)
          );
        } else {
          filterNullDataonDirectBase = filterNullData.filter(
            (item) => item.docdirectorycode === selectedDirData.docdirectorycode
          );
        }
        this.setState({ filterNullDataonDirectBase });
      }
    }
  }

  componentDidMount() {
    let filterNullData =
      this.props.LSDocDirectoryResponse.LSDocReportsLst.filter(
        (item) => item.fileName !== null && item.fileName !== undefined
      );
    filterNullData = filterNullData.map((item) => {
      return {
        docReportsCode: item.docReportsCode,
        fileName: item.fileName,
        isTemplate: item.isTemplate === 1 ? true : false,
        createdate: new Date(item.createdate).toLocaleDateString(),
        status: item.status,
        docdirectorycode: item.docdirectorycode,
        createdBy: item.createdBy,
      };
    });
    this.setState({ filterNullData });
  }

  render() {
    return (
      <React.Fragment>
        <Suspense fallback={<div></div>}>
          <Audit
            AuditId="downloadReportsDirectoryAuditMasterModelId"
            AuditUrl={this.state.AuditUrl}
            AuditObject={this.state.AuditObject}
            AuditPassfunction={this.state.AuditPassfunction}
            AuditFailfunction={this.state.AuditFailfunction}
            alert={this.props.alert}
          />
        </Suspense>
        {this.props.DocxDirModalvisible &&
          this.props.LSDocDirectoryResponse !== undefined ? (
          <div style={{ display: "flex" }}>
            <div
              style={{
                padding: "0px 7px",
                width:
                  this.props.displayType === "directory&office"
                    ? "100%"
                    : "30%",
              }}
            >
              {this.props.displayType === "directory" ? (
                <div style={{ paddingTop: "6px" }}>
                  {this.props.DocxDirModalType === "saveFile" ||
                    this.props.DocxDirModalType === "saveAs" ? (
                    <span>
                      <span
                        style={{
                          fontWeight: "500",
                          textTransform: "uppercase",
                          padding: "0px 0px 10px 9px",
                          fontSize: "20px",
                        }}
                      >
                        {this.props.t("Reports.IDS_LBL_SAVE")}
                      </span>
                      <span className="p-1 float-right">
                        <button
                          className="ButtonAsAnchorCss"
                          onClick={this.updateAddStatus}
                        >
                          <i
                            className="fas fa-plus pr-1"
                            aria-hidden="true"
                          ></i>{" "}
                          {this.props.t("Reports.IDS_LBL_ADDDIRECTORY")}
                        </button>
                      </span>
                    </span>
                  ) : (
                    <h6 className="inner-head">
                      {this.props.t("Reports.IDS_LBL_OPEN")}
                    </h6>
                  )}
                </div>
              ) : (
                false
              )}
              <DocxDirDesign
                LSDocDirectoryResponse={this.props.LSDocDirectoryResponse}
                LstLSuserMaster={this.props.LstLSuserMaster}
                setSelectedData={this.setSelectedDirData}
                selectedDirData={this.state.selectedDirData || {}}
                setOpenDocxReport={this.props.setOpenDocxReport}
                DocxDirModalType={this.props.DocxDirModalType}
                fileRes={this.props.fileRes}
                displayType={this.props.displayType}
                AddFieldStatus={this.state.AddFieldStatus}
                updateAddStatus={this.updateAddStatus}
                insertDirectoryOrDocumentInto={
                  this.insertDirectoryOrDocumentInto
                }
              // setOrganiedData={this.setsetOrganiedData}
              />
            </div>
            {this.props.displayType === "directory" ? (
              <div style={{ width: "69.5%" }}>
                <div style={{ padding: "6px 0px 6px 0px" }}>
                  <span>
                    <input
                      ref={this.searchChangeRef}
                      onChange={() => this.searchChangeEvent()}
                      style={{
                        border: "unset",
                        borderBottom: "solid rgba(0,0,0,.125) 1px",
                        padding: "5px 32px 5px 10px",
                      }}
                    ></input>
                    <i
                      className="fa fa-search"
                      style={{ position: "relative", right: "21px" }}
                      aria-hidden="true"
                    ></i>
                  </span>
                  {this.props.DocxDirModalType === "saveFile" ||
                    this.props.DocxDirModalType === "saveAs" ? (
                    <span className="p-1 float-right">
                      <button
                        className="btn btn-primary-blue"
                        onClick={() => this.props.toggleDialog("", "docxdir")}
                      >
                        {this.props.t("Reports.IDS_LBL_CLOSE")}
                      </button>
                    </span>
                  ) : (
                    <span>
                      <span className="p-1 float-right">
                        <button
                          className="btn_white_bg"
                          onClick={() =>
                            this.props.updateDisplayType("directory&office")
                          }
                        >
                          {/* <i className="fa fa-chevron-right pr-1" aria-hidden="true"></i>{" "} */}
                          {/* {this.props.t("DownloadReport.ID_LESS")}*/}
                          {this.props.t("Reports.IDS_LBL_CLOSE")}
                        </button>
                      </span>
                      {this.state.viewDesignType === "icon" ? (
                        <span className="p-1 float-right">
                          <button
                            className="btn_white_bg"
                            title={this.props.t(
                              "Reports.IDS_LBL_SWITCHTOLISTVIEW"
                            )}
                            onClick={() =>
                              this.setState({ viewDesignType: "list" })
                            }
                          >
                            <i className="fa fa-list" aria-hidden="true"></i>{" "}
                          </button>
                        </span>
                      ) : (
                        <span className="p-1 float-right">
                          <button
                            className="btn_white_bg"
                            title={this.props.t(
                              "Reports.IDS_LBL_SWITCHTOICONVIEW"
                            )}
                            onClick={() =>
                              this.setState({ viewDesignType: "icon" })
                            }
                          >
                            <i
                              className="fa fa-th-large"
                              aria-hidden="true"
                            ></i>{" "}
                          </button>
                        </span>
                      )}
                    </span>
                  )}
                </div>
                <div className="documentsContainerCss">
                  {this.designDocxTitledNewView()}
                  {this.state.selectedDirData !== {} &&
                    this.state.selectedDirData !== undefined ? (
                    this.DocxPresentinDir() !== undefined ? (
                      <div
                        className="bx-shadow-square spaced"
                        style={{
                          textAlign: " center",
                        }}
                      >
                        {this.sortByDataTypesAndBuildDesign()}
                        {/* {this.state.viewDesignType === "list" ? */}
                        <span
                          hidden={
                            this.state.viewDesignType === "list" ? false : true
                          }
                        >
                          <Suspense fallback={<div></div>}>
                            <KendoGridView
                              primaryKey="docReportsCode"
                              source={this.state.filterNullDataonDirectBase}
                              // source={this.props.LSDocDirectoryResponse.LSDocReportsLst}
                              DateFrom={new Date()}
                              DateTo={new Date()}
                              onDateFromChange={null}
                              onDateToChange={null}
                              Columns={this.state.Columns}
                              DataSearchvisible={false}
                              SelectedRecords={this.GridSelected}
                              // ClickedRecord={this.GridClicked}
                              Data={this.state.filterNullData}
                              // Data={this.props.LSDocDirectoryResponse.LSDocReportsLst}
                              // TopPisiton="115px"
                              ShowDetailsVisible={false}
                            />
                          </Suspense>
                        </span>
                        {/* : false} */}
                      </div>
                    ) : (
                      <div
                        className="centered-event-message spaced"
                        style={{ height: "calc(100vh - 400px)" }}
                      >
                        <div className="centered-event-message-inner">
                          <span className="big-icon">
                            <i class="fas fa-exclamation-circle"></i>
                          </span>

                          <p>
                            {this.props.t(
                              "Reports.IDS_LBL_NODOCUMENTPRESENTINSELECTEDDIRECTORY"
                            )}{" "}
                          </p>
                        </div>
                      </div>
                    )
                  ) : (
                    false
                  )}
                </div>
              </div>
            ) : (
              false
            )}
          </div>
        ) : (
          false
        )}
      </React.Fragment>
    );
  }
}

export const ModalDocxDirectory = withAlert()(
  withTranslation(ModalDocxDirectoryClass)
);

class DocxDirDesignClass extends Component {
  constructor(props) {
    super(props);
    this.state = {
      OrderedDirData: [],
      showData: [],
      navData: [],
      directoryEdit: false,
    }; //selectedDirData: props.selectedDirData
    this.inputDirectoryNameRef = React.createRef();
    this.inputDocumentNameEditRef = React.createRef();
    this.inputDirectoryNameEditRef = React.createRef();
  }

  handleEachParent = () => {
    const { LSDocDirectoryLst, LSDocReportsLst } =
      this.props.LSDocDirectoryResponse;
    let rootDir = LSDocDirectoryLst.filter((item) => item.directorytype === 0);
    let OrderedDirData = [];
    let canProcced = true;
    // let directoryCount = 0;
    let docdirectorycodeArray = [];
    let docdirectorycodeArrayIndex = 0;
    rootDir.map((rootDirObj) => {
      // let currentDocdirectorycode = rootDirObj.docdirectorycode;
      OrderedDirData.push({
        ...rootDirObj,
        indent: 1,
        showData: [rootDirObj.docdirectorycode],
      });
      docdirectorycodeArray.push({
        docdirectorycode: rootDirObj.docdirectorycode,
        level: 1,
        directoryname: rootDirObj.directoryname,
      });
      do {
        if (docdirectorycodeArray[docdirectorycodeArrayIndex] !== undefined) {
          const currentDocdirectory =
            docdirectorycodeArray[docdirectorycodeArrayIndex];
          // let tempFilteredArray = []
          // tempFilteredArray.push(...LSDocDirectoryLst.filter(item => item.parentdirectory !== null && item.parentdirectory === currentDocdirectory.docdirectorycode))
          // tempFilteredArray.push(...LSDocReportsLst.filter(item => item.docdirectorycode === currentDocdirectory.docdirectorycode))
          let tempFilteredArray = [];
          if (parseInt(sessionStorage.User) === 1) {
            tempFilteredArray = LSDocDirectoryLst.filter(
              (item) =>
                item.parentdirectory !== null &&
                item.parentdirectory === currentDocdirectory.docdirectorycode &&
                item.directoryname !== "My Space" &&
                item.directoryname !== "Team Space"
            );
          } else {
            tempFilteredArray = LSDocDirectoryLst.filter(
              (item) =>
                item.parentdirectory !== null &&
                item.parentdirectory === currentDocdirectory.docdirectorycode
            );
          }
          let tempFilterReports = LSDocReportsLst.filter(
            (item) =>
              item.fileName !== null &&
              item.docdirectorycode === currentDocdirectory.docdirectorycode
          );
          if (currentDocdirectory.docdirectorycode === 1) {
            //tempFilteredArray.sort((objA, objB) => {if(objA.directoryname === "My Space" || objA.directoryname === "Generated Reports" || objA.directoryname === "Draft Reports"){ return -1}else{ return 1} })
            tempFilteredArray.sort((objA) => {
              if (
                objA.directoryname === "My Space" ||
                objA.directoryname === "Team Space"
              ) {
                return -1;
              } else {
                return 1;
              }
            });
          }
          if (currentDocdirectory.directoryname === "My Space") {
            tempFilterReports = tempFilterReports.filter(
              (item) => item.createdBy === parseInt(sessionStorage.User)
            );
            tempFilteredArray = tempFilteredArray.filter(
              (item) => item.createdby === parseInt(sessionStorage.User)
            );
          }
          if (currentDocdirectory.directoryname === "Team Space") {
            const LstLSuserMaster = this.props.LstLSuserMaster || [];

            if (LstLSuserMaster !== undefined && LstLSuserMaster !== null) {
              tempFilterReports = tempFilterReports.filter((item) =>
                LstLSuserMaster.includes(item.createdBy)
              );
              tempFilteredArray = tempFilteredArray.filter((item) =>
                LstLSuserMaster.includes(item.createdby)
              );
            }
          }
          if (tempFilterReports.length > 0) {
            tempFilterReports = tempFilterReports.map((item) => {
              return { ...item, indent: currentDocdirectory.level + 1 };
            });
            if (OrderedDirData.length === 1) {
              OrderedDirData = [...OrderedDirData, ...tempFilterReports];
              // docdirectorycodeArrayIndex++;
            } else {
              const indextoslice = OrderedDirData.findIndex(
                (item) =>
                  item.docdirectorycode === currentDocdirectory.docdirectorycode
              );
              if (indextoslice + 1 === OrderedDirData.length) {
                OrderedDirData = [...OrderedDirData, ...tempFilterReports];
              } else {
                OrderedDirData = [
                  ...OrderedDirData.slice(0, indextoslice + 1),
                  ...tempFilterReports,
                  ...OrderedDirData.slice(indextoslice + 1),
                ];
              }
              // docdirectorycodeArrayIndex++;
            }
          }
          if (tempFilteredArray.length === 0) {
            if (docdirectorycodeArrayIndex - 1 < docdirectorycodeArray.length) {
              docdirectorycodeArrayIndex++;
            } else {
              canProcced = false;
            }
          } else if (tempFilteredArray.length > 0) {
            const parentShowData = OrderedDirData.find(
              (item) =>
                item.docdirectorycode === currentDocdirectory.docdirectorycode
            );
            let tempArray = tempFilteredArray.map((item) => {
              docdirectorycodeArray.push({
                docdirectorycode: item.docdirectorycode,
                level: currentDocdirectory.level + 1,
                directoryname: item.directoryname,
              });
              return {
                ...item,
                indent: currentDocdirectory.level + 1,
                showData: [...parentShowData.showData, item.docdirectorycode],
              };
            });
            if (OrderedDirData.length === 1) {
              OrderedDirData = [...OrderedDirData, ...tempArray];
              docdirectorycodeArrayIndex++;
            } else {
              const indextoslice = OrderedDirData.findIndex(
                (item) =>
                  item.docdirectorycode === currentDocdirectory.docdirectorycode
              );
              if (indextoslice + 1 === OrderedDirData.length) {
                OrderedDirData = [...OrderedDirData, ...tempArray];
              } else {
                OrderedDirData = [
                  ...OrderedDirData.slice(0, indextoslice + 1),
                  ...tempArray,
                  ...OrderedDirData.slice(indextoslice + 1),
                ];
              }
              docdirectorycodeArrayIndex++;
            }
          }
        } else {
          canProcced = false;
        }
        // if(directoryCount === LSDocDirectoryLst.length){
        // canProcced = false;
        // }
      } while (canProcced);
      return false;
    });
    this.setState({
      OrderedDirData,
      showData: [rootDir[0].docdirectorycode],
      navData: [rootDir][0],
    });
    let selectedDirData =
      "showData" in this.props.selectedDirData
        ? this.props.selectedDirData
        : OrderedDirData.find(
          (item) =>
            item.docdirectorycode ===
            this.props.selectedDirData.docdirectorycode
        );
    this.loadSubDir(selectedDirData);
  };

  // indentIndexObj = {
  //     1: '5px 5px 5px 10px', 2: '5px 5px 5px 25px', 3: '5px 5px 5px 40px', 4: '5px 5px 5px 55px', 5: '5px 5px 5px 70px', 6: '5px 5px 5px 85px', 7 : '5px 5px 5px 100px'
  // }

  indentIndexObj = (indent) => {
    let initial = 0;
    initial = 15 * indent;
    return "5px 5px 8px " + initial + "px";
  };

  loadSubDir = (selectedDirData) => {
    let childLst = [];
    // if (selectedDirData.docdirectorycode === this.props.selectedDirData.docdirectorycode && selectedDirData.parentdirectory !== null) {
    //     selectedDirData = this.state.OrderedDirData.find(item => item.docdirectorycode === selectedDirData.parentdirectory);
    // }
    if (selectedDirData !== undefined) {
      if ("showData" in selectedDirData) {
        const { LSDocDirectoryLst, LSDocReportsLst } =
          this.props.LSDocDirectoryResponse;
        LSDocDirectoryLst.filter(function (item) {
          if (
            selectedDirData.showData.includes(item.parentdirectory) ||
            item.parentdirectory === null
          ) {
            childLst.push(item.docdirectorycode);
            return false;
          } else {
            return false;
          }
        });
        LSDocReportsLst.filter(function (item) {
          if (selectedDirData.showData.includes(item.docdirectorycode)) {
            childLst.push(item.docReportsCode + "Docx");
            return false;
          } else {
            return false;
          }
        });
        let navData = LSDocDirectoryLst.filter((item) =>
          selectedDirData.showData.includes(item.docdirectorycode)
        );
        this.setState({
          showData: [...selectedDirData.showData, ...childLst],
          navData,
        }); //, selectedDirData
        this.props.setSelectedData(selectedDirData);
      }
    }
  };

  getSelectedClassDynamic = (item) => {
    let cls = "";

    if ("docReportsCode" in item && this.props.fileRes.fileName !== undefined) {
      if (item.fileName === this.props.fileRes.fileName.replace(".docx", "")) {
        cls += "docxDirectoryDivSelected";
      } else {
        cls += "docxDirectoryDiv";
      }
    } else {
      cls += "docxDirectoryDiv";
    }
    return cls;
  };

  getDirectoryNameIDS = (item, selected) => {
    let IDSName = "";
    const name = item.directoryname;
    if (name === "root") {
      IDSName = <span>{this.props.t("Reports.IDS_LBL_ROOT")}</span>;
    } else if (name === "Generated Reports") {
      IDSName = (
        <span>{this.props.t("Reports.IDS_LBL_GENERATEDREPORTS")}</span>
      );
    } else if (name === "My Space") {
      IDSName = <span>{this.props.t("Reports.IDS_LBL_MYSPACE")}</span>;
    } else if (name === "Team Space") {
      IDSName = <span>{this.props.t("Reports.IDS_LBL_TEAMSPACE")}</span>;
    } else if (name === "Draft Reports") {
      IDSName = <span>{this.props.t("Reports.IDS_LBL_DRAFTREPORTS")}</span>;
    } else {
      if (selected) {
        IDSName = (
          <span>
            {name}
            <span className="float-right">
              <button className="btn_white_bg">
                <i
                  className="fas fa-pencil-alt"
                  onClick={() => this.updateDeleteDirectory(item, "edit")}
                  title={this.props.t("Reports.IDS_LBL_EDIT")}
                  aria-hidden="true"
                ></i>
              </button>
              <button className="btn_white_bg">
                <i
                  className="far fa-trash-alt"
                  onClick={() => this.updateDeleteDirectory(item, "delete")}
                  title={this.props.t("Reports.IDS_LBL_DELETE")}
                  aria-hidden="true"
                ></i>
              </button>
            </span>
          </span>
        );
      } else {
        IDSName = <span>{name}</span>;
      }
    }
    return IDSName;
  };

  getIconClassName(item, status) {
    let className = "";
    if (
      item.directoryname !== "My Space" &&
      item.directoryname !== "Team Space" &&
      status === "close"
    ) {
      className = "fas fa-folder";
    } else if (
      item.directoryname !== "My Space" &&
      item.directoryname !== "Team Space" &&
      status === "open"
    ) {
      className = "fas fa-folder-open";
    } else if (item.directoryname === "My Space") {
      className = "fa fa-briefcase";
    } else if (item.directoryname === "Team Space") {
      className = "fa fa-users";
    }
    return className;
  }

  onKeyDownEvenTriggered = (e) => {
    if (e.keyCode === 13) {
      this.props.insertDirectoryOrDocumentInto(
        this.inputDirectoryNameRef,
        "directory"
      );
    }
  };

  updateDeleteDirectory = (item, type) => {
    if (type === "edit") {
      this.setState({
        editDirectoryDirectoryname: item.directoryname,
        directoryEdit: true,
      });
      if (this.inputDirectoryNameEditRef !== null) {
        setTimeout(() => {
          this.inputDirectoryNameEditRef.current.value = item.directoryname;
        }, 500);
      }
    } else if (type === "delete") {
      const objPresent = this.state.OrderedDirData.find(
        (each) =>
          each.docdirectorycode === item.docdirectorycode &&
          each.docReportsCode !== undefined
      );
      if (objPresent !== undefined) {
        confirmAlert({
          customUI: ({ onClose }) => {
            return (
              <div className="react-confirm-alert-body">
                {this.props.t("Reports.IDS_LBL_DIRECTROEYCONTAINSFILES")}
                <div className="react-confirm-alert-button-group">
                  <button
                    onClick={() => {
                      this.updateDeleteDirectory(item, "deleteSubmit");
                      onClose();
                    }}
                  >
                    {this.props.t("Reports.IDS_LBL_OK")}
                  </button>
                  <button onClick={onClose}>
                    {this.props.t("Reports.IDS_LBL_CLOSE")}
                  </button>
                </div>
              </div>
            );
          },
        });
      } else {
        this.updateDeleteDirectory(item, "deleteSubmit");
      }
    } else if (type === "cancel") {
      this.setState({ editDirectoryDirectoryname: "", directoryEdit: false });
    } else if (type === "submit") {
      if (this.inputDirectoryNameEditRef.current.value !== "") {
        let currentSelectedDir = item;
        currentSelectedDir["directoryname"] =
          this.inputDirectoryNameEditRef.current.value;
        // delete currentSelectedDir.showData;
        // delete currentSelectedDir.indent;

        this.props.insertDirectoryOrDocumentInto(
          currentSelectedDir,
          "updateDirectory"
        );
        this.setState({ editDirectoryDirectoryname: "", directoryEdit: false });
      }
    } else if (type === "deleteSubmit") {
      let currentSelectedDir = item;
      currentSelectedDir["status"] = 0;
      // delete currentSelectedDir.showData;
      // delete currentSelectedDir.indent;

      this.props.insertDirectoryOrDocumentInto(
        currentSelectedDir,
        "updateDirectory"
      );
      const selectedDir = this.state.OrderedDirData.find(
        (each) =>
          each.docdirectorycode === item.parentdirectory &&
          item.directoryname !== undefined
      );
      this.props.setSelectedData(selectedDir);
      this.setState({ editDirectoryDirectoryname: "", directoryEdit: false });
    }
  };

  updateDeleteDocument = (item, type) => {
    if (type === "edit") {
      this.setState({
        editDocumentDocumentname: item.fileName,
        documentEdit: true,
      });
      if (this.inputDocumentNameEditRef !== null) {
        setTimeout(() => {
          this.inputDocumentNameEditRef.current.value = item.fileName;
        }, 500);
      }
    } else if (type === "submit") {
      if (this.inputDocumentNameEditRef.current.value !== "") {
        let currentSelectedDir = item;
        currentSelectedDir["fileName"] =
          this.inputDocumentNameEditRef.current.value;
        this.props.insertDirectoryOrDocumentInto(
          currentSelectedDir,
          "updateDocument"
        );
        this.setState({ editDirectoryDirectoryname: "", documentEdit: false });
      }
    } else if (type === "cancel") {
      this.setState({ editDocumentDocumentname: "", documentEdit: false });
    } else if (type === "delete") {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="react-confirm-alert-body">
              {this.props.t("Reports.IDS_LBL_AREYOUSUREYOUWANTTODELETE")}
              <div className="react-confirm-alert-button-group">
                <button
                  onClick={() => {
                    this.updateDeleteDocument(item, "deleteSubmit");
                    onClose();
                  }}
                >
                  {this.props.t("Reports.IDS_LBL_YES")}
                </button>
                <button onClick={onClose}>
                  {this.props.t("Reports.IDS_LBL_CLOSE")}
                </button>
              </div>
            </div>
          );
        },
      });
    } else if (type === "deleteSubmit") {
      let currentSelectedDoc = item;
      currentSelectedDoc["status"] = 0;
      this.props.insertDirectoryOrDocumentInto(
        currentSelectedDoc,
        "updateDocument"
      );
      // const selectedDir = this.state.OrderedDirData.find(each => each.docdirectorycode === item.parentdirectory && item.directoryname !== undefined);
      // this.props.setSelectedData(selectedDir);
      // this.setState({ editDirectoryDirectoryname: "", directoryEdit: false });
    }
  };

  componentDidMount() {
    this.handleEachParent();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.LSDocDirectoryResponse !== this.props.LSDocDirectoryResponse
    ) {
      this.handleEachParent();
    }
    if (this.props.AddFieldStatus && this.inputDirectoryNameRef !== undefined
      && this.inputDirectoryNameRef.current !== undefined && this.inputDirectoryNameRef.current !== null) {
      this.inputDirectoryNameRef.current.focus();
    }
  }

  render() {
    return (
      <React.Fragment>
        {/* <div>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            {this.state.navData.map((item, index) =>
                                (this.state.navData.length - 1) === index ?
                                    <li key={index} className="breadcrumb-item active" aria-current="page">{this.getDirectoryNameIDS(item.directoryname)}</li>
                                    : <li key={index} className="breadcrumb-item" aria-current="page"><span>{this.getDirectoryNameIDS(item.directoryname)}</span></li>
                            )}
                        </ol>
                    </nav>
                </div> */}
        <div
          className="docDirectoryDivContainer"
          style={{
            overflowY: "auto",
            height: "calc(100vh - 180px)",
            width: "100%",
          }}
        >
          {" "}
          {/* this.props.displayType === "directory" ? "82vh" : 76vh, 72vh*/}
          {this.state.OrderedDirData.map((item, index) =>
            "docReportsCode" in item &&
              this.props.displayType === "directory&office" ? (
              //files
              <div
                className={this.getSelectedClassDynamic(item)}
                key={index}
                hidden={
                  !this.state.showData.includes(item.docReportsCode + "Docx")
                }
                title={item.fileName + "." + item.extention}
                style={{ padding: this.indentIndexObj(item.indent) }}
              >
                {this.state.editDocumentDocumentname === item.fileName ? (
                  <span className="list-rg-ico-grp">
                    {item.extention === "docx" ? (
                      <i
                        className="far fa-file-word"
                        style={{ color: "#205aab", paddingRight: "10px" }}
                        aria-hidden="true"
                      ></i>
                    ) : (
                      <i
                        className="far fa-file-alt"
                        style={{ color: "#205aab", paddingRight: "10px" }}
                        aria-hidden="true"
                      ></i>
                    )}
                    <input
                      ref={this.inputDocumentNameEditRef}
                      style={{ width: "60%", color: "#90949c" }}
                    ></input>
                    <span>
                      <button className="btn_white_bg">
                        <i
                          className="fas fa-check"
                          onClick={() =>
                            this.updateDeleteDocument(item, "submit")
                          }
                          title={this.props.t("Reports.IDS_LBL_SUBMIT")}
                          aria-hidden="true"
                        ></i>
                      </button>
                      <button className="btn_white_bg">
                        <i
                          className="fas fa-times"
                          onClick={() =>
                            this.updateDeleteDocument(item, "cancel")
                          }
                          title={this.props.t("Reports.IDS_LBL_CLOSE")}
                          aria-hidden="true"
                        ></i>
                      </button>
                    </span>
                  </span>
                ) : (
                  <span className="list-rg-ico-grp">
                    <span className="text-left-icon">
                      {item.extention === "docx" ? (
                        <i
                          className="far fa-file-word"
                          style={{ color: "#205aab", paddingRight: "10px" }}
                          aria-hidden="true"
                        ></i>
                      ) : (
                        <i
                          className="far fa-file-alt"
                          style={{ color: "#205aab", paddingRight: "10px" }}
                          aria-hidden="true"
                        ></i>
                      )}
                      <span onClick={() => this.props.setOpenDocxReport(item)}>
                        {item.fileName + "." + item.extention}
                      </span>
                    </span>
                    {item.isdraft === 1 ? (
                      <span>[{this.props.t("Reports.IDS_LBL_DRAFT")}]</span>
                    ) : (
                      false
                    )}
                    <span>
                      <button className="btn_white_bg icon-md">
                        <i
                          className="fas fa-pencil-alt"
                          onClick={() =>
                            this.updateDeleteDocument(item, "edit")
                          }
                          title={this.props.t("Reports.IDS_LBL_EDIT")}
                          aria-hidden="true"
                        ></i>
                      </button>
                      <button className="btn_white_bg icon-md">
                        <i
                          className="far fa-trash-alt"
                          onClick={() =>
                            this.updateDeleteDocument(item, "delete")
                          }
                          title={this.props.t("Reports.IDS_LBL_DELETE")}
                          aria-hidden="true"
                        ></i>
                      </button>
                    </span>
                  </span>
                )}
              </div>
            ) : "directoryname" in item ? (
              //folders
              item.docdirectorycode ===
                this.props.selectedDirData.docdirectorycode ? (
                <div key={index}>
                  <div
                    className="docxDirectoryDivSelected"
                    hidden={
                      !this.state.showData.includes(item.docdirectorycode)
                    }
                    style={{ padding: this.indentIndexObj(item.indent) }}
                    title={item.directoryname}
                    onClick={() => this.loadSubDir(item)}
                  >
                    {this.state.directoryEdit ? (
                      <React.Fragment>
                        <span className="list-rg-ico-grp">
                          <i
                            className={this.getIconClassName(item, "open")}
                            style={{ color: "#205aab", paddingRight: "10px" }}
                            aria-hidden="true"
                          ></i>

                          <input
                            ref={this.inputDirectoryNameEditRef}
                            onKeyDown={this.onKeyDownEvenTriggered}
                          ></input>
                          <span>
                            <button className="btn_white_bg">
                              <i
                                className="fas fa-check"
                                onClick={() =>
                                  this.updateDeleteDirectory(item, "submit")
                                }
                                title={this.props.t("Reports.IDS_LBL_SUBMIT")}
                                aria-hidden="true"
                              ></i>
                            </button>
                            <button className="btn_white_bg">
                              <i
                                className="fas fa-times"
                                onClick={() =>
                                  this.updateDeleteDirectory(item, "cancel")
                                }
                                title={this.props.t("Reports.IDS_LBL_CLOSE")}
                                aria-hidden="true"
                              ></i>
                            </button>
                          </span>
                        </span>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <i
                          className={this.getIconClassName(item, "open")}
                          style={{ color: "#205aab", paddingRight: "10px" }}
                          aria-hidden="true"
                        ></i>
                        {this.getDirectoryNameIDS(item, true)}
                      </React.Fragment>
                    )}
                  </div>
                  {this.props.AddFieldStatus ? (
                    <div
                      className="docxDirectoryDivSelected"
                      //key={index}
                      hidden={
                        !this.state.showData.includes(item.docdirectorycode)
                      }
                      style={{ padding: this.indentIndexObj(item.indent + 1) }}
                      title={item.directoryname}
                    // onClick={() => this.loadSubDir(item)}
                    >
                      <i
                        className={this.getIconClassName(item, "close")}
                        style={{ color: "#205aab", paddingRight: "10px" }}
                        aria-hidden="true"
                      ></i>
                      <input
                        ref={this.inputDirectoryNameRef}
                        onKeyDown={this.onKeyDownEvenTriggered}
                      ></input>
                      <i
                        className="fas fa-check"
                        onClick={() =>
                          this.props.insertDirectoryOrDocumentInto(
                            this.inputDirectoryNameRef,
                            "directory"
                          )
                        }
                        title={this.props.t("Reports.IDS_LBL_SUBMIT")}
                        style={{ color: "#205aab", padding: "0px 10px" }}
                        aria-hidden="true"
                      ></i>
                      <i
                        className="fas fa-times"
                        onClick={this.props.updateAddStatus}
                        title={this.props.t("Reports.IDS_LBL_CLOSE")}
                        style={{ color: "black", padding: "0px 10px" }}
                        aria-hidden="true"
                      ></i>
                    </div>
                  ) : (
                    false
                  )}
                </div>
              ) : (
                <div
                  className="docxDirectoryDiv"
                  key={index}
                  hidden={!this.state.showData.includes(item.docdirectorycode)}
                  style={{ padding: this.indentIndexObj(item.indent) }}
                  title={item.directoryname}
                  onClick={() => this.loadSubDir(item)}
                >
                  <i
                    className={this.getIconClassName(item, "close")}
                    style={{ color: "#205aab", paddingRight: "10px" }}
                    aria-hidden="true"
                  ></i>
                  {this.getDirectoryNameIDS(item, false)}
                </div>
              )
            ) : (
              false
            )
          )}
        </div>
      </React.Fragment>
    );
  }
}
export const DocxDirDesign = withTranslation(DocxDirDesignClass);

class FormattagwindowClass extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputStatus: "",
      tagname: "",
      selectedTagName: "",
      selectedTag: {},
    };
    this.tagInputRef = React.createRef();
  }

  addTagFn = () => {
    const { kendoSpread } = this.props;
    let activesheet = kendoSpread.activeSheet()._sheetName;
    let sheets = kendoSpread.sheets();
    let sheetindex = sheets.findIndex(
      (sheet) => sheet._sheetName === activesheet
    );
    const tagtoprow = kendoSpread.activeSheet().select().topLeft.row;
    const tagleftcol = kendoSpread.activeSheet().select().topLeft.col;
    const tagbottomrow = kendoSpread.activeSheet().select().bottomRight.row;
    const tagrightcol = kendoSpread.activeSheet().select().bottomRight.col;
    const tagsheetname = activesheet;
    const tagsheetindex = sheetindex;
    const topLeft = this.getCellAddr(tagleftcol, tagtoprow);
    const rightBottom = this.getCellAddr(tagrightcol, tagbottomrow);
    let selectionAddress = "";
    if (topLeft !== rightBottom) {
      selectionAddress = topLeft + ":" + rightBottom;
    } else {
      selectionAddress = topLeft;
    }
    const tag = this.props.tags.find(
      (item) =>
        item.tagtoprow === tagtoprow &&
        item.tagleftcol === tagleftcol &&
        item.tagbottomrow === tagbottomrow &&
        item.tagrightcol === tagrightcol &&
        item.tagsheetname === activesheet
    );
    let canShow = false;
    if (tag !== undefined) {
      if ("tagname" in tag) {
        this.setState({
          inputStatus: this.props.t("Templates.Sheettemplates.IDS_MSG_TAGALREADYEXIST"),
          tagname: tag.tagname,
        });
        canShow = true;
      }
    } else {
      this.setState({ inputStatus: "", tagname: "" });
    }
    if (!canShow) {
      if (tagtoprow === tagbottomrow && tagleftcol === tagrightcol) {
        if (
          this.props.kendoSpread.activeSheet().selection().value() === null ||
          this.props.kendoSpread.activeSheet().selection().value() === ""
        ) {
          this.setState({
            inputStatus: "IDS_LBL_TAGCANNOTBEASSIGHTTOEMPTYCELL",
            tagname: "",
          });
          canShow = true;
        }
      } else {
        const rowCount = tagbottomrow - tagtoprow;
        const colCount = tagrightcol - tagleftcol;
        for (let i = 0; i <= rowCount; i++) {
          for (let j = 0; j <= colCount; j++) {
            // const value = this.props.kendoSpread.activeSheet().range(this.getCellAddr(tagleftcol+j, tagtoprow+i)).value();
            if (
              this.props.kendoSpread
                .activeSheet()
                .range(this.getCellAddr(tagleftcol + j, tagtoprow + i))
                .value() !== undefined &&
              this.props.kendoSpread
                .activeSheet()
                .range(this.getCellAddr(tagleftcol + j, tagtoprow + i))
                .value() !== null &&
              this.props.kendoSpread
                .activeSheet()
                .range(this.getCellAddr(tagleftcol + j, tagtoprow + i))
                .value() !== ""
            ) {
              i = rowCount;
              j = colCount;
              canShow = false;
            } else {
              canShow = true;
              if (i === rowCount && j === colCount) {
                this.setState({
                  inputStatus: "IDS_LBL_TAGCANNOTBEASSIGHTTOEMPTYCELL",
                  tagname: "",
                });
              }
            }
          }
        }
      }
    }
    this.tagInputRef.current.parentElement.hidden = canShow;
    if (!canShow) {
      const addObj = {
        tagtoprow,
        tagleftcol,
        tagbottomrow,
        tagrightcol,
        tagsheetname,
        tagsheetindex,
        selectionAddress,
      };
      this.setState({ addObj, selectedTag: {}, onEdit: false });
      this.tagInputRef.current.value = "";
      this.tagInputRef.current.focus();
    }
  };

  getCellAddr = (selCol, selRow) => {
    if (selCol > 25) {
      let alphasets = parseInt(selCol / 26);
      let alpha = selCol % 26;
      return (
        String.fromCharCode(64 + alphasets) +
        String.fromCharCode(65 + alpha) +
        (selRow + 1)
      );
    } else {
      return String.fromCharCode(65 + selCol) + (selRow + 1);
    }
  };

  onItemclickEvent = (item) => {
    // const topLeft = this.getCellAddr(item.tagleftcol, item.tagtoprow);
    // const rightBottom = this.getCellAddr(item.tagrightcol, item.tagbottomrow);
    // let selectionAddress = "";
    // if (topLeft !== rightBottom) {
    //     selectionAddress = topLeft + ":" + rightBottom;
    // } else {
    //     selectionAddress = topLeft
    // }
    this.setState({ selectedTagName: item.tagname });
    this.props.onTagView(item.selectionAddress);
  };

  onDeleteTag = (tagData) => {
    let objsilentaudit = onAuditHandler(
      "delete",
      "Sheet Templates",
      this.props.t("Templates.Sheettemplates.IDS_AUDIT_DELETETAG", {
        user: store.getState().Loggeduser.username,
        user2: this.props.SheetName,
        user1: tagData.tagname
      }),
    );
    let passObjDet = { objsilentaudit: objsilentaudit };
    Servicecalls(
      "" + document.getElementById("ELNname").value + "/AuditTrail/silentandmanualRecordHandler",
      passObjDet,
      this.onAuditValidateError.bind(this),
      this.onAuditValidateFailure.bind(this)
    );
    const taglst = this.props.tags.filter(
      (tag) => tagData.tagname !== tag.tagname
    );
    this.props.updateTagtoState(taglst);
    // const topLeft = this.getCellAddr(tagData.tagleftcol, tagData.tagtoprow);
    // const rightBottom = this.getCellAddr(tagData.tagrightcol, tagData.tagbottomrow);
    // let selectionAddress = "";
    // if (topLeft !== rightBottom) {
    //     selectionAddress = topLeft + ":" + rightBottom;
    // } else {
    //     selectionAddress = topLeft
    // }
    const rowCount = tagData.tagbottomrow - tagData.tagtoprow + 1;
    const colCount = tagData.tagrightcol - tagData.tagleftcol + 1;
    for (let rowIndex = 0; rowIndex < rowCount; rowIndex++) {
      for (let colIndex = 0; colIndex < colCount; colIndex++) {
        let currentRowIndex = rowIndex + tagData.tagtoprow;
        let currentColIndex = colIndex + tagData.tagleftcol;
        const SeletedAdd = this.getCellAddr(currentColIndex, currentRowIndex);
        if (
          this.props.kendoSpread
            .activeSheet()
            .range(currentRowIndex, currentColIndex)
            .tag() !== null &&
          this.props.kendoSpread
            .activeSheet()
            .range(currentRowIndex, currentColIndex)
            .tag() !== undefined
        ) {
          this.props.kendoSpread
            .activeSheet()
            .range(SeletedAdd)
            .background("#ffd9b3");
        } else {
          this.props.kendoSpread
            .activeSheet()
            .range(SeletedAdd)
            .background(null);
        }
      }
    }

  };

  onCancelButton = () => {

    this.tagInputRef.current.value = "";
    this.tagInputRef.current.parentElement.hidden = true;
    this.setState({ inputStatus: "", tagname: "" });
  };

  onEditTag = (item) => {


    this.tagInputRef.current.parentElement.hidden = false;
    this.tagInputRef.current.value = item.tagname;
    this.tagInputRef.current.focus();
    this.setState({ selectedTag: item, onEdit: true });
  };

  onKeyDownEventTrigger = (e) => {

    if (e.keyCode === 13) {
      this.submitAddTag();
    }

  };

  submitAddTag = () => {


    const tagName = this.tagInputRef.current.value;
    if (tagName !== "") {
      let tag = {};
      let canProceed = true;
      if (this.props.tags.length > 0) {
        //& this.state.onEdit === false) {
        let taglist = this.props.tags;

        if (this.state.onEdit) {
          taglist = taglist.filter((item) => item.tagsheetname !== tagName);

        }
        tag = taglist.find((item) => item.tagname === tagName);

        if (tag !== undefined) {
          if ("tagname" in tag) {
            this.setState({
              inputStatus: "IDS_MSG_TAGNAMEALREADYEXIST",
              tagname: "",
            });
            canProceed = false;
          } else {
            canProceed = true;
          }
        } else {
          canProceed = true;
        }

      }
      if (canProceed) {
        const { kendoSpread } = this.props;
        let tagData = {};
        let taglst = [];
        let selectedTag = {};
        if (this.state.onEdit === true) {
          selectedTag = this.state.selectedTag;
          const temptaglst = this.props.tags.filter(
            (item) => item.tagname !== selectedTag.tagname
          );

          let passObjDetAudit = store.getState().Loggeduser;
          passObjDetAudit.objsilentaudit = Silentauditobject(
            "update",
            "Sheet Templates",

            this.props.t("Templates.Sheettemplates.IDS_AUDIT_EDITTAG", {
              user: store.getState().Loggeduser.username,
              user3: this.props.SheetName,
              user1: this.state.selectedTag.tagname,
              user2: this.tagInputRef.current.value
            }),
          );

          Servicecalls(
            "" + document.getElementById("ELNname").value + "/AuditTrail/silentandmanualRecordHandler",
            passObjDetAudit,
            (res) => { },
            (res) => { }
          );
          tagData = { ...selectedTag, tagname: tagName };
          taglst = [tagData, ...temptaglst];


        } else {
          selectedTag = this.state.addObj;
          tagData = { ...selectedTag, tagname: tagName };
          taglst = [tagData, ...this.props.tags];
          let objsilentaudit = onAuditHandler(
            "insert",
            "Sheet Templates",
            this.props.t("Templates.Sheettemplates.IDS_AUDIT_ADDTAG", {
              user: store.getState().Loggeduser.username,
              user2: this.props.SheetName,
              user1: this.tagInputRef.current.value
            }),
          );
          let passObjDet = { objsilentaudit: objsilentaudit };
          Servicecalls(
            "" + document.getElementById("ELNname").value + "/AuditTrail/silentandmanualRecordHandler",
            passObjDet,
            this.onAuditValidateError.bind(this),
            this.onAuditValidateFailure.bind(this)
          );
        }
        if ("tagname" in tagData) {
          // taglst = [tagData, ...this.props.tags]
          this.props.updateTagtoState(taglst);
          kendoSpread
            .activeSheet()
            .range(selectedTag.selectionAddress)
            .select()
            .background("#e6ccff");
          this.tagInputRef.current.parentElement.hidden = true;
          this.tagInputRef.current.value = "";
          this.setState({ inputStatus: "", tagname: "", selectedTag });
        }
      }
    }


  };
  onAuditValidateError() { }
  onAuditValidateFailure() { }
  componentWillReceiveProps() {
    if (this.state.inputStatus !== "") {
      this.setState({ inputStatus: "", tagname: "" });
    }
  }

  render() {
    return (
      <div className={"sheet-chart-wrapper "}>
        <div className="card-inner-body bg-white">
          <div className="pane-header k-tabstrip k-header">
            <button
              type="button"
              className="btn_white_bg f-right mt-1"
              aria-label="Close"
              onClick={this.props.onClosetagClick}
            >
              <i className="fas fa-times" aria-hidden="true"></i>
            </button>
            <h4 className="pane-title">
              {this.props.t("Templates.Sheettemplates.IDS_LBL_PROPERTIES", { param: "" })}
            </h4>
          </div>
          <div className="card">
            <div>
              <div className="d-flex algin-items-center justify-content-between mb-2">
                <div>
                  <button
                    className="btn btn-primary-blue"
                    onClick={() => this.addTagFn()}
                  >
                    + {this.props.t("Templates.Sheettemplates.IDS_LBL_ADDTAG", { param: "" })}
                  </button>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="clsTagDivHr">
                <span>{this.props.t("Templates.Sheettemplates.IDS_LBL_TAGNAME", { param: "" })}</span>
              </div>
              <div className="clsTagDivHr clsTagDivtooltip" hidden={true}>
                <input
                  type="text"
                  style={{ border: "solid #ced1d4 1px", width: "100%" }}
                  autoFocus={true}
                  onKeyDown={this.onKeyDownEventTrigger}
                  ref={this.tagInputRef}
                ></input>
                <span
                  className="clsTagDivtooltiptext"
                  style={{
                    border: "solid #ced1d4 1px",
                    float: "right",
                    borderTop: "none",
                  }}
                >
                  <i
                    className="fa fa-check"
                    style={{
                      fontSize: "13px",
                      marginTop: "5px",
                      padding: "0px 8px",
                      color: "#1268e7",
                    }}
                    onClick={() => this.submitAddTag(this.state.Sheetn)}
                    aria-hidden="true"
                    title="Add"
                  >

                  </i>

                  <i
                    className="fa fa-times"
                    style={{
                      fontSize: "13px",
                      marginTop: "5px",
                      padding: "0px 8px",
                    }}
                    onClick={() => this.onCancelButton()}
                    aria-hidden="true"
                    title="Cancel"
                  ></i>
                </span>
              </div>
              <div style={{ color: "red", padding: "5px 8px" }}>
                <span>
                  {this.state.inputStatus !== ""
                    ? this.props.t("spread." + this.state.inputStatus, {
                      param: "",
                    })
                    : false}
                  {this.state.tagname !== ""
                    ? " : " + this.state.tagname
                    : false}
                </span>
              </div>

              {this.props.tags.length > 0 ? (
                //style={this.state.selectedTagName === item.tagname ? {backgroundColor: "#c8e4fb"} : {}}
                this.props.tags.map((item, index) => (
                  <div
                    key={index}
                    className="clsTagDiv tagsBlock"
                    onClick={() => this.onItemclickEvent(item)}
                  >
                    <span title={item.tagname}>{item.tagname}</span>

                    <span className="btn_white_bg">
                      <i
                        onClick={() => this.onDeleteTag(item)}
                        className="fa fa-trash"
                        aria-hidden="true"
                        title="Delete"
                      ></i>
                    </span>
                    <span className="btn_white_bg">
                      {" "}
                      <i
                        onClick={() => this.onEditTag(item)}
                        className="fa fa-pencil-alt"
                        aria-hidden="true"
                        title="Delete"
                      ></i>
                    </span>
                  </div>
                ))
              ) : (
                <div className="clsTagDiv">
                  <span>
                    {this.props.t("Templates.Sheettemplates.IDS_LBL_NOTAGSPRESENT", { param: "" })}
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export const Formattagwindow = withTranslation(FormattagwindowClass);
