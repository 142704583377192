import { css } from "@emotion/core";

const sdmsLink = false;

const override = css`
  position: fixed;
  margin: 5px;
  clear: both;
  left: 40%;

  :before {
    content: "";
    display: block;
    position: fixed;

    background-color: rgba(0, 0, 0, 0.3);
  }
`;

const defalutreduxState = {
  Menu: {
    Controlrights: [],
  },
  audit: {
    auditoption: [],
  },
  SheetCreation: {
    VersionBar: false,
    Sheetrender: true,
    Draggeddata: {},
    SpreadData: {
      menuclick: false,
      menuid: -1,
      SpreadDatasource: JSON.stringify(
        JSON.parse(
          '{"activeSheet":"Sheet1","sheets":[{"name":"Sheet1","rows":[],"columns":[],"selection":"A1:A1","activeCell":"A1:A1","frozenRows":1,"frozenColumns":0,"showGridLines":true,"gridLinesColor":null,"mergedCells":[],"hyperlinks":[],"defaultCellStyle":{"fontFamily":"Arial","fontSize":"12"},"drawings":[]}],"names":[],"columnWidth":64,"rowHeight":20}'
        )
      ),
      Sheetview: false,
      stopview: false,
    },
    sheetInfo: [],
    SheetName: "",
    SheetNameAs: "",
    IsSaveAssheet: false,
    SheetCode: 0,
    IsNewsheet: false,
    loading: false,
    MenuOption: 0,
    AuditUrl: "",
    AuditObject: [],
    AuditPassfunction: null,
    AuditFailfunction: null,
    AuditCancelfunction: undefined,
    DocumentType: 0,
    DocumentSteps: 1,
    MultiDocumentView: [{ Content: "<div></div>" }],
    MultiDocumentDetils: [{ Name: "", Description: "" }],
    MultiDocMenuoption: 0,
    DocumentContent: "<div></div>",
    Data: [],
    Sheetversion: 0,
    lstestcode: [],
    Sheetparameters: [],
    File: undefined,
    saveA: false,
    SaveEditA: false,
    SaveopenA: false,
    auditSave: false,
    ServiceToUrl: "",
    ServiceMethodType: "POST",
    ServiceHeaderobject: {},
    ServcieonSuccess: undefined,
    ServcieonFailure: undefined,
    approvelEnable: false,
    Isnameopen: false,
    savename: null,
    closenamepop: null,
    workflowexpanded: false,
    transactionexpanded: false,
    treeexpanded: false,
    workflow: false,
    transaction: false,
    version: false,
    savedisable: true,
  },
  Sheetview: {
    Draggeddata: {},
    SpreadData: {
      menuclick: false,
      menuid: -1,
      SpreadDatasource: [],
      Sheetview: true,
      stopview: false,
      IsTreeView: false,
      ViewCompleteorder: false,
      ViewCompleteorderid: -1,
      Parameters: [],
    },
    SheetName: "",
    SheetCode: 0,
    selectedfile: {},
    lastVersion: 99999,
    Testcode: "",
    stopDisabled: false,
    IsShowDesigner: false,
    ModifiedSheetdata: {},
    OrderTestcode: "",
    OrderTestName: "",
    MethodID: "",
    InstrumentID: "",
    MenuOption: 0,
    orderDetailsclassName: "clsdisplayblock",
    KenodclassName: "clsDisplaynone",
    filteredPendingorder: "",
    filteredPendingTest: "",
    filteredCompleteorder: "",
    filteredCompleteTest: "",
    Assigntestfor: 0,
    MaterialTestcode: "",
    MaterialTestName: "",
    MaterialSheetName: "",
    MaterialSheetCode: 0,
    PendingActive: "clsactivetab",
    CompletedActive: "",
    AssignedActive: "",
    MyActive: "",
    PendingPanelshow: "clsdisplayblock",
    CompletedPanelshow: "clsDisplaynone",
    pendingDateFrom: new Date().setMonth(new Date().getMonth() - 1),
    pendingDateTo: new Date().setDate(new Date().getDate()),
    CompleteDateFrom: new Date().setMonth(new Date().getMonth() - 1),
    CompleteDateTo: new Date().setDate(new Date().getDate()),
    SelectedTest: {},
    Projectcode: "",
    Projectusercode: null,
    Samplecode: "",
    FlowName: {},
    unApprovedfile: null,

    Evalsavevisb: true,
    Excelsavevisb: true,
    Researchvisible: true,
    ELNregistervis: true,
    LIMSprocessvis: true,
    compsavevisible: true,
    pendsavevisible: true,
    pendvisible: true,
    compvisible: true,
    LIMSorderVisible: true,
    ELNorderVisible: true,
    ExcelorderVisible: true,
    DocumentorderVisible: true,
    ResearchorderVisible: true,
    IsOrderLocked: 0,
    IsOrderLockedbyUser: 0,
    IsFinalworkflow: 0,
    ServiceToUrl: "",
    ServiceMethodType: "POST",
    ServiceHeaderobject: {},
    ServcieonSuccess: undefined,
    ServcieonFailure: undefined,
    Orderclosebuttonvisible: true,
    showinfo: false,
    showattachment: false,
    workflowexpanded: false,
    transactionexpanded: false,
    Islargefilesize: false,
    SearchContent: "",
    Contentsearchvalue: 1,
    CompSearchContent: "",
    ContentCompsearchvalue: 1,
    sdmsattachement: [],
    pendingorderaudit: false,
  },
  sheet: {
    ParseFields: [],
    timerID: 0,
  },
  KendoSpread: {
    Isrender: true,
    IsLoader: false,
    kendoSpread: null,
    MaterialTypeArray: [],
    MaterialCategoryArray: [],
    MaterialArray: [],
    MaterialSectionArray: [],
    InventoryArray: [],
    GradeArray: [],
    InstrumentType: [],
    InstrumentCategory: [],
    InstrumentSection: [],
    InstrumentName: [],
    MaterialInventoryTransaction: [],
    Parameter: "",
    Parametervalid: "",
    Linktosheet: false,
    Linksheetcode: {},
    LinkBatchID: {},
    LinkView: "clsDisplaynone",
    chemicalview: "clsDisplaynone",
    LinkSheetValid: "",
    LinkBatchValid: "",
    Linkedsheetvalue: [],
    LinkedViewon: -1,
    popchart: null,
    orderid: -1,
    orderDetails: {},
    RunParameters: [],
    SheetName: "",
    SheetCode: 0,
    Draggedonview: false,
    lastVersion: 99999,
    filesamplecode: 0,
    AuditUrl: "",
    AuditObject: [],
    AuditPassfunction: null,
    AuditFailfunction: null,
    AuditParameters: {},
    optionvalue: "",
    Dyndatafromsheet: "1",
    Dyndatacolumn: "1",
    Dyndatarangefrom: "1",
    Repeatfieldoncapture: false,
    Repeatview: "clsDisplaynone",
    DynGroponsheet: 0,
    Iscopyfieldopen: false,
    Currentsheetfields: [],
    Ismetavalueopen: false,
    metatags: {},
    parameterview: "clsDisplaynone",
    Optionview: "clsDisplaynone",
    Dyncomboview: "",
    Optionvalid: "",
    viewchart: false,
    charts: [],
    plates: [],
    tags: [],
    viewtags: false,
    currenttagindex: -1,
    tagCount: 0,
    currentchartindex: -1,
    chartXAxis: [],
    chartYAxis: [],
    capturex: 0,
    capturey: 0,
    currentchart: {
      // chartName: "chart",
      // Iscontrolchart: false,
      seriescount: 1,
      series: [
        {
          seriesIndex: 1,
          seriesName: "series 1",
          // seriesType: "scatter",
          mode: "line",
          // marker: { color: "red" },
          points: [],
          titleVlaue: "",
          lagendVlaue: "",
        },
      ],
    },
    addpointrow: false,
    selectedxPoint: -1,
    selectedyPoint: -1,
    currentchartview: null,
    CurrentSeriesindex: 0,
    CurrentSeriesoncobmo: 0,

    SheetDataSource: [],
    SheetValue: {},
    OrderDataSource: [],
    OrderValue: {},
    Sheetdata: {},
    SeriesType: [
      { name: "bar" },
      { name: "box" },
      { name: "choropleth" },
      { name: "histogram" },
      { name: "pointcloud" },
      { name: "scatter" },
      { name: "scattergeo" },
      { name: "scattergl" },
      { name: "scatterpolar" },
      { name: "scatterternary" },
      { name: "waterfall" },
      { name: "funnel" },
    ],
    SeriesMode: [
      { name: "donut" },
      { name: "line" },
      { name: "column" },
      { name: "area" },
      { name: "pie" },
      { name: "scatter" },
      { name: "scatterLine" }
      // { name: "text+lines" },
      // { name: "text+lines+markers" },
      // { name: "none" },
      // { name: "gauge" },
      // { name: "number" },
      // { name: "delta" },
      // { name: "number+delta" },
      // { name: "gauge+number" },
      // { name: "gauge+number+delta" },
      // { name: "gauge+delta" }
    ],
    SeriesStyle: [{ name: "normal" }, { name: "step" }, { name: "smooth" }],
    chartcolorview: false,
    ServiceToUrl: "",
    ServiceMethodType: "POST",
    ServiceHeaderobject: {},
    ServcieonSuccess: undefined,
    ServcieonFailure: undefined,
    Completedorder: false,
    processType: 0,
    Sampleselect: false,
    File: undefined,
    LsrepositoriesDropData: [],
    LsrepositoriesdataDropData: [],
    Data: [],
    Repositoryuseddata: [],
    isconsumable: false,
    consumable: 0,
    Quantityused: 0,
    Quantityrequired: 0,
    temprepositoryData: [],
    singleLsrepositoriesDropData: [],
    editrepository: false,
  },
  KendoSpreadCreate: {
    Isrender: true,
    kendoSpread: null,
    MaterialTypeArray: [],
    MaterialCategoryArray: [],
    MaterialArray: [],
    MaterialSectionArray: [],
    InventoryArray: [],
    GradeArray: [],
    InstrumentType: [],
    InstrumentCategory: [],
    InstrumentSection: [],
    InstrumentName: [],
    MaterialInventoryTransaction: [],
    Parameter: "",
    Parametervalid: "",
    Linktosheet: false,
    Linksheetcode: {},
    LinkBatchID: {},
    LinkView: "",
    LinkSheetValid: "",
    LinkBatchValid: "",
    Linkedsheetvalue: [],
    LinkedViewon: -1,
    popchart: null,
    orderid: -1,
    orderDetails: {},
    RunParameters: [],
    SheetName: "",
    SheetCode: 0,
    Draggedonview: false,
    lastVersion: 99999,
    filesamplecode: 0,
    AuditUrl: "",
    AuditObject: [],
    AuditPassfunction: null,
    AuditFailfunction: null,
    AuditParameters: {},
    optionvalue: "",
    Dyndatafromsheet: "1",
    Dyndatacolumn: "1",
    Dyndatarangefrom: "1",
    Repeatfieldoncapture: false,
    Repeatview: "",
    DynGroponsheet: 0,
    Iscopyfieldopen: false,
    Currentsheetfields: [],
    Ismetavalueopen: false,
    metatags: {},
    parameterview: "",
    Optionview: "",
    Dyncomboview: "",
    Optionvalid: "",
    viewchart: false,
    charts: [],
    plates: [],
    tags: [],
    viewtags: false,
    currenttagindex: -1,
    tagCount: 0,
    currentchartindex: -1,
    chartXAxis: [],
    chartYAxis: [],
    capturex: 0,
    capturey: 0,
    currentchart: {
      // chartName: "chart",
      // Iscontrolchart: false,
      seriescount: 1,
      series: [
        {
          seriesIndex: 1,
          seriesName: "series 1",
          // seriesType: "scatter",
          mode: "line",
          // marker: { color: "red" },
          points: [],
          titleVlaue: "",
          lagendVlaue: "",
        },
      ],
    },
    addpointrow: false,
    selectedxPoint: -1,
    selectedyPoint: -1,
    currentchartview: null,
    CurrentSeriesindex: 0,
    CurrentSeriesoncobmo: 0,

    SheetDataSource: [],
    SheetValue: {},
    OrderDataSource: [],
    OrderValue: {},
    Sheetdata: {},
    SeriesType: [
      { name: "bar" },
      { name: "box" },
      { name: "choropleth" },
      { name: "histogram" },
      { name: "pointcloud" },
      { name: "scatter" },
      { name: "scattergeo" },
      { name: "scattergl" },
      { name: "scatterpolar" },
      { name: "scatterternary" },
      { name: "waterfall" },
      { name: "funnel" },
    ],
    SeriesMode: [
      { name: "donut" },
      { name: "line" },
      { name: "column" },
      { name: "area" },
      { name: "pie" },
      { name: "scatter" },
      { name: "scatterLine" }
      // { name: "text+lines" },
      // { name: "text+lines+markers" },
      // { name: "none" },
      // { name: "gauge" },
      // { name: "number" },
      // { name: "delta" },
      // { name: "number+delta" },
      // { name: "gauge+number" },
      // { name: "gauge+number+delta" },
      // { name: "gauge+delta" }
    ],
    SeriesStyle: [{ name: "normal" }, { name: "step" }, { name: "smooth" }],
    chartcolorview: false,
    ServiceToUrl: "",
    ServiceMethodType: "POST",
    ServiceHeaderobject: {},
    ServcieonSuccess: undefined,
    ServcieonFailure: undefined,
    Completedorder: false,
    processType: 0,
    Sampleselect: false,
    File: undefined
  },
  Loggeduser: {},
  userworkflow: [],
  navigation: "methodMainMenu",
  fluidPage: false,
  userData: {
    userName: "Administrator",
    role: 1,
    domain: "LocalDB",
    language: "en",
    site: { sitecode: 1, sitename: "Default", status: 1 },
    userSite: 1,
    error: "",
  },
  hideMenu: {},
  masterData: {},
  visibility: true,
  transVisibility: true,
  fieldDetail: {},
  instrumentCategory: 2016, //primary key for 'General'
  saveAuditTrail: false,

  dataGridStatus: "",
  filterColumnName: "",
  searchColumnData: undefined,
  auditStatus: "",
  rawDataText: "",
  loginStatus: "",
  instrawdataurl: "",
  status: "",

  //status:"",
  loginUser: {
    usercode: 1,
    username: "Administrator",
    firstname: "Admin",
    lastname: "Admin",
    email: "admin@agaramtech.com",
    contactno: "4498765432",
    transstatusdetail: {
      transstatusdetailkey: 4,
      transstatus: { transstatuskey: 2, transstatusname: "Users" },
      statusmaster: {
        statuskey: 1,
        statusname: "Active",
        displayname: "Active",
      },
    },
    signImageUrl: null,
    userImageUrl: null,
    userimgname: null,
    signimgname: null,
    status: 0,
    createdby: {
      usercode: 1,
      username: "Administrator",
      firstname: "Admin",
      lastname: "Admin",
    },
    createddate: "2019-07-29T00:00:00Z",
  },
  loginSitePageList: [
    {
      pagekey: 43,
      module: {
        modulekey: 7,
        modulename: "MethodSetup",
        site: {
          sitecode: 1,
          sitename: "Default",
          status: 1,
        },
        status: 1,
      },
      pagename: "MethodSetup",
      status: 1,
    },
    {
      pagekey: 44,
      module: {
        modulekey: 7,
        modulename: "MethodSetup",
        site: {
          sitecode: 1,
          sitename: "Default",
          status: 1,
        },
        status: 1,
      },
      pagename: "MethodMaster",
      status: 1,
    },
    {
      pagekey: 45,
      module: {
        modulekey: 7,
        modulename: "MethodSetup",
        site: {
          sitecode: 1,
          sitename: "Default",
          status: 1,
        },
        status: 1,
      },
      pagename: "Delimiters",
      status: 1,
    },
    {
      pagekey: 46,
      module: {
        modulekey: 7,
        modulename: "MethodSetup",
        site: {
          sitecode: 1,
          sitename: "Default",
          status: 1,
        },
        status: 1,
      },
      pagename: "SampleSplit",
      status: 1,
    },
    {
      pagekey: 47,
      module: {
        modulekey: 7,
        modulename: "MethodSetup",
        site: {
          sitecode: 1,
          sitename: "Default",
          status: 1,
        },
        status: 1,
      },
      pagename: "ParserSetup",
      status: 1,
    },
    {
      pagekey: 48,
      module: {
        modulekey: 7,
        modulename: "MethodSetup",
        site: {
          sitecode: 1,
          sitename: "Default",
          status: 1,
        },
        status: 1,
      },
      pagename: "MethodDelimiter",
      status: 1,
    },
    {
      pagekey: 49,
      module: {
        modulekey: 7,
        modulename: "MethodSetup",
        site: {
          sitecode: 1,
          sitename: "Default",
          status: 1,
        },
        status: 1,
      },
      pagename: "GeneralField",
      status: 1,
    },
    {
      pagekey: 51,
      module: {
        modulekey: 7,
        modulename: "MethodSetup",
        site: {
          sitecode: 1,
          sitename: "Default",
          status: 1,
        },
        status: 1,
      },
      pagename: "CustomField",
      status: 1,
    },
    {
      pagekey: 52,
      module: {
        modulekey: 7,
        modulename: "MethodSetup",
        site: {
          sitecode: 1,
          sitename: "Default",
          status: 1,
        },
        status: 1,
      },
      pagename: "InstrumentCategory",
      status: 1,
    },
    {
      pagekey: 53,
      module: {
        modulekey: 7,
        modulename: "MethodSetup",
        site: {
          sitecode: 1,
          sitename: "Default",
          status: 1,
        },
        status: 1,
      },
      pagename: "InstrumentMaster",
      status: 1,
    },
  ],
  loginUserSiteRole: {
    userrolekey: 1,
    usersite: {
      usersitekey: 1,
      users: {
        usercode: 1,
        username: "Administrator",
        firstname: "Admin",
        lastname: "Admin",
        email: "admin@agaramtech.com",
      },
      site: { sitecode: 1, sitename: "Default", status: 1 },
      defaultstatus: 1,
      status: 1,
      createdby: {
        usercode: 1,
        username: "Administrator",
        firstname: "Admin",
        lastname: "Admin",
      },
      createddate: "2019-07-29T00:00:00Z",
    },
    rolepolicy: {
      rolepolicykey: 3,
      role: {
        rolekey: 7,
        rolename: "Administrator",
        description: "Administrator Role",
        site: { sitecode: 1, sitename: "Default", status: 1 },
        status: 0,
        createdby: {
          usercode: 1,
          username: "Administrator",
          firstname: "Admin",
          lastname: "Admin",
        },
        createddate: "2019-11-21T00:00:00Z",
      },
      passcodepolicy: {
        policykey: 1,
        policyname: "Administrator",
        description: "Used for Administrator",
        maxpasscode: 20,
        nooffailedattempt: 3,
      },
      status: 0,
      createdby: {
        usercode: 1,
        username: "Administrator",
        firstname: "Admin",
        lastname: "Admin",
      },
      createddate: "2019-08-19T00:00:00Z",
    },

    passcode: "A@m1n",
    expirydate: "2020-01-13T10:32:57.017Z",
    transstatusdetail: {
      transstatusdetailkey: 4,
      transstatus: { transstatuskey: 2, transstatusname: "Users" },
      statusmaster: {
        statuskey: 1,
        statusname: "Active",
        displayname: "Active",
      },
    },
    clientip: "192.168.0.147",
    defaultstatus: 1,
    status: 1,
    createdby: {
      usercode: 1,
      username: "Administrator",
      firstname: "Admin",
      lastname: "Admin",
    },
    createddate: "2019-08-28T00:00:00Z",
  },
  loginInvalidAttempts: 0,
  isnotactivelogout: false,
  instrumentidparser: [],
  profileimages: []
};

const initialreduxState = {
  Menu: {
    Controlrights: [],
  },
  audit: {
    auditoption: [],
  },
  SheetCreation: {
    VersionBar: false,
    Draggeddata: {},
    Sheetrender: true,
    SpreadData: {
      menuclick: false,
      menuid: -1,
      SpreadDatasource: JSON.stringify(
        JSON.parse(
          '{"activeSheet":"Sheet1","sheets":[{"name":"Sheet1","rows":[],"columns":[],"selection":"A1:A1","activeCell":"A1:A1","frozenRows":0,"frozenColumns":0,"showGridLines":true,"gridLinesColor":null,"mergedCells":[],"hyperlinks":[],"defaultCellStyle":{"fontFamily":"Arial","fontSize":"12"},"drawings":[]}],"names":[],"columnWidth":64,"rowHeight":20}'
        )
      ),
      Sheetview: false,
      stopview: false,
    },
    sheetInfo: [],
    SheetName: "",
    SheetNameAs: "",
    IsSaveAssheet: false,
    SheetCode: 0,
    IsNewsheet: false,
    loading: false,
    MenuOption: 0,
    AuditUrl: "",
    AuditObject: [],
    AuditPassfunction: null,
    AuditFailfunction: null,
    AuditCancelfunction: undefined,
    DocumentType: 0,
    DocumentSteps: 1,
    MultiDocumentView: [{ Content: "<div></div>" }],
    MultiDocumentDetils: [{ Name: "", Description: "" }],
    MultiDocMenuoption: 0,
    DocumentContent: "<div></div>",
    Data: [],
    Sheetversion: 0,
    lstestcode: [],
    Sheetparameters: [],
    File: undefined,
    saveA: false,
    SaveEditA: false,
    SaveopenA: false,
    auditSave: false,
    ServiceToUrl: "",
    ServiceMethodType: "POST",
    ServiceHeaderobject: {},
    ServcieonSuccess: undefined,
    ServcieonFailure: undefined,
    approvelEnable: false,
    Isnameopen: false,
    savename: null,
    closenamepop: null,
    workflowexpanded: false,
    transactionexpanded: false,
    treeexpanded: true,
    workflow: false,
    transaction: false,
    version: false,
    savedisable: true,
  },
  Sheetview: {
    Draggeddata: {},
    SpreadData: {
      menuclick: false,
      menuid: -1,
      SpreadDatasource: [],
      Sheetview: true,
      stopview: false,
      IsTreeView: false,
      ViewCompleteorder: false,
      ViewCompleteorderid: -1,
      Parameters: [],
    },
    SheetName: "",
    SheetCode: 0,
    selectedfile: {},
    lastVersion: 99999,
    Testcode: "",
    stopDisabled: false,
    IsShowDesigner: false,
    ModifiedSheetdata: {},
    OrderTestcode: "",
    OrderTestName: "",
    MethodID: "",
    InstrumentID: "",
    MenuOption: 0,
    orderDetailsclassName: "clsdisplayblock",
    KenodclassName: "clsDisplaynone",
    filteredPendingorder: "",
    filteredPendingTest: "",
    filteredCompleteorder: "",
    filteredCompleteTest: "",
    Assigntestfor: 0,
    MaterialTestcode: "",
    MaterialTestName: "",
    MaterialSheetName: "",
    MaterialSheetCode: 0,
    PendingActive: "clsactivetab",
    CompletedActive: "",
    AssignedActive: "",
    MyActive: "",
    PendingPanelshow: "clsdisplayblock",
    CompletedPanelshow: "clsDisplaynone",
    pendingDateFrom: new Date().setMonth(new Date().getMonth() - 1),
    pendingDateTo: new Date().setDate(new Date().getDate()),
    CompleteDateFrom: new Date().setMonth(new Date().getMonth() - 1),
    CompleteDateTo: new Date().setDate(new Date().getDate()),
    SelectedTest: {},
    Projectcode: "",
    Projectusercode: null,
    Samplecode: "",
    FlowName: {},
    unApprovedfile: null,

    Evalsavevisb: true,
    Excelsavevisb: true,
    Researchvisible: true,
    ELNregistervis: true,
    LIMSprocessvis: true,
    compsavevisible: true,
    pendsavevisible: true,
    pendvisible: true,
    compvisible: true,
    LIMSorderVisible: true,
    ELNorderVisible: true,
    ExcelorderVisible: true,
    DocumentorderVisible: true,
    ResearchorderVisible: true,
    IsOrderLocked: 0,
    IsOrderLockedbyUser: 0,
    IsFinalworkflow: 0,
    ServiceToUrl: "",
    ServiceMethodType: "POST",
    ServiceHeaderobject: {},
    ServcieonSuccess: undefined,
    ServcieonFailure: undefined,
    Orderclosebuttonvisible: true,
    showinfo: false,
    showattachment: false,
    workflowexpanded: false,
    transactionexpanded: false,
    Islargefilesize: false,
    SearchContent: "",
    Contentsearchvalue: 1,
    CompSearchContent: "",
    ContentCompsearchvalue: 1,
    sdmsattachement: [],
    pendingorderaudit: false,
  },
  sheet: {
    ParseFields: [],
    timerID: 0,
  },
  KendoSpread: {
    Isrender: true,
    IsLoader: false,
    kendoSpread: null,
    MaterialTypeArray: [],
    MaterialCategoryArray: [],
    MaterialArray: [],
    MaterialSectionArray: [],
    InventoryArray: [],
    GradeArray: [],
    InstrumentType: [],
    InstrumentCategory: [],
    InstrumentSection: [],
    InstrumentName: [],
    MaterialInventoryTransaction: [],
    Parameter: "",
    Parametervalid: "",
    Linktosheet: false,
    Linksheetcode: {},
    LinkBatchID: {},
    LinkView: "clsDisplaynone",
    chemicalview: "clsDisplaynone",
    LinkSheetValid: "",
    LinkBatchValid: "",
    Linkedsheetvalue: [],
    LinkedViewon: -1,
    popchart: null,
    orderid: -1,
    orderDetails: {},
    RunParameters: [],
    SheetName: "",
    SheetCode: 0,
    Draggedonview: false,
    lastVersion: 99999,
    filesamplecode: 0,
    AuditUrl: "",
    AuditObject: [],
    AuditPassfunction: null,
    AuditFailfunction: null,
    AuditParameters: {},
    optionvalue: "",
    Dyndatafromsheet: "1",
    Dyndatacolumn: "1",
    Dyndatarangefrom: "1",
    Repeatfieldoncapture: false,
    Repeatview: "clsDisplaynone",
    DynGroponsheet: 0,
    Iscopyfieldopen: false,
    Currentsheetfields: [],
    Ismetavalueopen: false,
    metatags: {},
    parameterview: "clsDisplaynone",
    Optionview: "clsDisplaynone",
    Dyncomboview: "",
    Optionvalid: "",
    viewchart: false,
    charts: [],
    plates: [],
    tags: [],
    viewtags: false,
    currenttagindex: -1,
    tagCount: 0,
    currentchartindex: -1,
    chartXAxis: [],
    chartYAxis: [],
    capturex: 0,
    capturey: 0,
    currentchart: {
      // chartName: "chart",
      // Iscontrolchart: false,
      seriescount: 1,
      series: [
        {
          seriesIndex: 1,
          seriesName: "series 1",
          // seriesType: "scatter",
          mode: "line",
          // marker: { color: "red" },
          points: [],
          titleVlaue: "",
          lagendVlaue: "",
        },
      ],
    },
    addpointrow: false,
    selectedxPoint: -1,
    selectedyPoint: -1,
    currentchartview: null,
    CurrentSeriesindex: 0,
    CurrentSeriesoncobmo: 0,

    SheetDataSource: [],
    SheetValue: {},
    OrderDataSource: [],
    OrderValue: {},
    Sheetdata: {},
    SeriesType: [
      { name: "bar" },
      { name: "box" },
      { name: "choropleth" },
      { name: "histogram" },
      { name: "pointcloud" },
      { name: "scatter" },
      { name: "scattergeo" },
      { name: "scattergl" },
      { name: "scatterpolar" },
      { name: "scatterternary" },
      { name: "waterfall" },
      { name: "funnel" },
    ],
    SeriesMode: [
      { name: "donut" },
      { name: "line" },
      { name: "column" },
      { name: "area" },
      { name: "pie" },
      { name: "scatter" },
      { name: "scatterLine" }
      // { name: "text+lines" },
      // { name: "text+lines+markers" },
      // { name: "none" },
      // { name: "gauge" },
      // { name: "number" },
      // { name: "delta" },
      // { name: "number+delta" },
      // { name: "gauge+number" },
      // { name: "gauge+number+delta" },
      // { name: "gauge+delta" }
    ],
    SeriesStyle: [{ name: "normal" }, { name: "step" }, { name: "smooth" }],
    chartcolorview: false,
    ServiceToUrl: "",
    ServiceMethodType: "POST",
    ServiceHeaderobject: {},
    ServcieonSuccess: undefined,
    ServcieonFailure: undefined,
    Completedorder: false,
    processType: 0,
    Sampleselect: false,
    File: undefined,
    LsrepositoriesDropData: [],
    LsrepositoriesdataDropData: [],
    Data: [],
    Repositoryuseddata: [],
    isconsumable: false,
    consumable: 0,
    Quantityused: 0,
    Quantityrequired: 0,
    temprepositoryData: [],
    singleLsrepositoriesDropData: [],
    editrepository: false,
  },
  KendoSpreadCreate: {
    Isrender: true,
    kendoSpread: null,
    MaterialTypeArray: [],
    MaterialCategoryArray: [],
    MaterialArray: [],
    MaterialSectionArray: [],
    InventoryArray: [],
    GradeArray: [],
    InstrumentType: [],
    InstrumentCategory: [],
    InstrumentSection: [],
    InstrumentName: [],
    MaterialInventoryTransaction: [],
    Parameter: "",
    Parametervalid: "",
    Linktosheet: false,
    Linksheetcode: {},
    LinkBatchID: {},
    LinkView: "",
    LinkSheetValid: "",
    LinkBatchValid: "",
    Linkedsheetvalue: [],
    LinkedViewon: -1,
    popchart: null,
    orderid: -1,
    orderDetails: {},
    RunParameters: [],
    SheetName: "",
    SheetCode: 0,
    Draggedonview: false,
    lastVersion: 99999,
    filesamplecode: 0,
    AuditUrl: "",
    AuditObject: [],
    AuditPassfunction: null,
    AuditFailfunction: null,
    AuditParameters: {},
    optionvalue: "",
    Dyndatafromsheet: "1",
    Dyndatacolumn: "1",
    Dyndatarangefrom: "1",
    Repeatfieldoncapture: false,
    Repeatview: "",
    DynGroponsheet: 0,
    Iscopyfieldopen: false,
    Currentsheetfields: [],
    Ismetavalueopen: false,
    metatags: {},
    parameterview: "",
    Optionview: "",
    Dyncomboview: "",
    Optionvalid: "",
    viewchart: false,
    charts: [],
    plates: [],
    tags: [],
    viewtags: false,
    currenttagindex: -1,
    tagCount: 0,
    currentchartindex: -1,
    chartXAxis: [],
    chartYAxis: [],
    capturex: 0,
    capturey: 0,
    currentchart: {
      // chartName: "chart",
      // Iscontrolchart: false,
      seriescount: 1,
      series: [
        {
          seriesIndex: 1,
          seriesName: "series 1",
          // seriesType: "scatter",
          mode: "line",
          // marker: { color: "red" },
          points: [],
          titleVlaue: "",
          lagendVlaue: "",
        },
      ],
    },
    addpointrow: false,
    selectedxPoint: -1,
    selectedyPoint: -1,
    currentchartview: null,
    CurrentSeriesindex: 0,
    CurrentSeriesoncobmo: 0,

    SheetDataSource: [],
    SheetValue: {},
    OrderDataSource: [],
    OrderValue: {},
    Sheetdata: {},
    SeriesType: [
      { name: "bar" },
      { name: "box" },
      { name: "choropleth" },
      { name: "histogram" },
      { name: "pointcloud" },
      { name: "scatter" },
      { name: "scattergeo" },
      { name: "scattergl" },
      { name: "scatterpolar" },
      { name: "scatterternary" },
      { name: "waterfall" },
      { name: "funnel" },
    ],
    SeriesMode: [
      { name: "donut" },
      { name: "line" },
      { name: "column" },
      { name: "area" },
      { name: "pie" },
      { name: "scatter" },
      { name: "scatterLine" }
      // { name: "text+lines" },
      // { name: "text+lines+markers" },
      // { name: "none" },
      // { name: "gauge" },
      // { name: "number" },
      // { name: "delta" },
      // { name: "number+delta" },
      // { name: "gauge+number" },
      // { name: "gauge+number+delta" },
      // { name: "gauge+delta" }
    ],
    SeriesStyle: [{ name: "normal" }, { name: "step" }, { name: "smooth" }],
    chartcolorview: false,
    ServiceToUrl: "",
    ServiceMethodType: "POST",
    ServiceHeaderobject: {},
    ServcieonSuccess: undefined,
    ServcieonFailure: undefined,
    Completedorder: false,
    processType: 0,
    Sampleselect: false,
    File: undefined,
  },
  // Loggeduser: {},
  // userworkflow: [],
  // navigation: "methodMainMenu",
  // fluidPage: false,
  // userData: {
  //   userName: "Administrator",
  //   role: 1,
  //   domain: "LocalDB",
  //   language: "en",
  //   site: { sitecode: 1, sitename: "Default", status: 1 },
  //   userSite: 1,
  //   error: "",
  // },
  hideMenu: {},
  masterData: {},
  visibility: true,
  transVisibility: true,
  fieldDetail: {},
  instrumentCategory: 2016, //primary key for 'General'
  saveAuditTrail: false,

  dataGridStatus: "",
  filterColumnName: "",
  searchColumnData: undefined,
  auditStatus: "",
  rawDataText: "",
  loginStatus: "",
  instrawdataurl: "",
  status: "",

  //status:"",
  loginUser: {
    usercode: 1,
    username: "Administrator",
    firstname: "Admin",
    lastname: "Admin",
    email: "admin@agaramtech.com",
    contactno: "4498765432",
    transstatusdetail: {
      transstatusdetailkey: 4,
      transstatus: { transstatuskey: 2, transstatusname: "Users" },
      statusmaster: {
        statuskey: 1,
        statusname: "Active",
        displayname: "Active",
      },
    },
    signImageUrl: null,
    userImageUrl: null,
    userimgname: null,
    signimgname: null,
    status: 0,
    createdby: {
      usercode: 1,
      username: "Administrator",
      firstname: "Admin",
      lastname: "Admin",
    },
    createddate: "2019-07-29T00:00:00Z",
  },
  loginSitePageList: [
    {
      pagekey: 43,
      module: {
        modulekey: 7,
        modulename: "MethodSetup",
        site: {
          sitecode: 1,
          sitename: "Default",
          status: 1,
        },
        status: 1,
      },
      pagename: "MethodSetup",
      status: 1,
    },
    {
      pagekey: 44,
      module: {
        modulekey: 7,
        modulename: "MethodSetup",
        site: {
          sitecode: 1,
          sitename: "Default",
          status: 1,
        },
        status: 1,
      },
      pagename: "MethodMaster",
      status: 1,
    },
    {
      pagekey: 45,
      module: {
        modulekey: 7,
        modulename: "MethodSetup",
        site: {
          sitecode: 1,
          sitename: "Default",
          status: 1,
        },
        status: 1,
      },
      pagename: "Delimiters",
      status: 1,
    },
    {
      pagekey: 46,
      module: {
        modulekey: 7,
        modulename: "MethodSetup",
        site: {
          sitecode: 1,
          sitename: "Default",
          status: 1,
        },
        status: 1,
      },
      pagename: "SampleSplit",
      status: 1,
    },
    {
      pagekey: 47,
      module: {
        modulekey: 7,
        modulename: "MethodSetup",
        site: {
          sitecode: 1,
          sitename: "Default",
          status: 1,
        },
        status: 1,
      },
      pagename: "ParserSetup",
      status: 1,
    },
    {
      pagekey: 48,
      module: {
        modulekey: 7,
        modulename: "MethodSetup",
        site: {
          sitecode: 1,
          sitename: "Default",
          status: 1,
        },
        status: 1,
      },
      pagename: "MethodDelimiter",
      status: 1,
    },
    {
      pagekey: 49,
      module: {
        modulekey: 7,
        modulename: "MethodSetup",
        site: {
          sitecode: 1,
          sitename: "Default",
          status: 1,
        },
        status: 1,
      },
      pagename: "GeneralField",
      status: 1,
    },
    {
      pagekey: 51,
      module: {
        modulekey: 7,
        modulename: "MethodSetup",
        site: {
          sitecode: 1,
          sitename: "Default",
          status: 1,
        },
        status: 1,
      },
      pagename: "CustomField",
      status: 1,
    },
    {
      pagekey: 52,
      module: {
        modulekey: 7,
        modulename: "MethodSetup",
        site: {
          sitecode: 1,
          sitename: "Default",
          status: 1,
        },
        status: 1,
      },
      pagename: "InstrumentCategory",
      status: 1,
    },
    {
      pagekey: 53,
      module: {
        modulekey: 7,
        modulename: "MethodSetup",
        site: {
          sitecode: 1,
          sitename: "Default",
          status: 1,
        },
        status: 1,
      },
      pagename: "InstrumentMaster",
      status: 1,
    },
  ],
  loginUserSiteRole: {
    userrolekey: 1,
    usersite: {
      usersitekey: 1,
      users: {
        usercode: 1,
        username: "Administrator",
        firstname: "Admin",
        lastname: "Admin",
        email: "admin@agaramtech.com",
      },
      site: { sitecode: 1, sitename: "Default", status: 1 },
      defaultstatus: 1,
      status: 1,
      createdby: {
        usercode: 1,
        username: "Administrator",
        firstname: "Admin",
        lastname: "Admin",
      },
      createddate: "2019-07-29T00:00:00Z",
    },
    rolepolicy: {
      rolepolicykey: 3,
      role: {
        rolekey: 7,
        rolename: "Administrator",
        description: "Administrator Role",
        site: { sitecode: 1, sitename: "Default", status: 1 },
        status: 0,
        createdby: {
          usercode: 1,
          username: "Administrator",
          firstname: "Admin",
          lastname: "Admin",
        },
        createddate: "2019-11-21T00:00:00Z",
      },
      passcodepolicy: {
        policykey: 1,
        policyname: "Administrator",
        description: "Used for Administrator",
        maxpasscode: 20,
        nooffailedattempt: 3,
      },
      status: 0,
      createdby: {
        usercode: 1,
        username: "Administrator",
        firstname: "Admin",
        lastname: "Admin",
      },
      createddate: "2019-08-19T00:00:00Z",
    },

    passcode: "A@m1n",
    expirydate: "2020-01-13T10:32:57.017Z",
    transstatusdetail: {
      transstatusdetailkey: 4,
      transstatus: { transstatuskey: 2, transstatusname: "Users" },
      statusmaster: {
        statuskey: 1,
        statusname: "Active",
        displayname: "Active",
      },
    },
    clientip: "192.168.0.147",
    defaultstatus: 1,
    status: 1,
    createdby: {
      usercode: 1,
      username: "Administrator",
      firstname: "Admin",
      lastname: "Admin",
    },
    createddate: "2019-08-28T00:00:00Z",
  },
  loginInvalidAttempts: 0,
  isnotactivelogout: false,
  instrumentidparser: [],
  profileimages: []
};

const sheetDefaultContent =
  '{"activeSheet":"Sheet1","sheets":[{"name":"Sheet1","rows":[],"columns":[],"selection":"A1:A1","activeCell":"A1:A1","frozenRows":0,"frozenColumns":0,"showGridLines":true,"gridLinesColor":null,"mergedCells":[],"hyperlinks":[],"defaultCellStyle":{"fontFamily":"Arial","fontSize":"12"},"drawings":[]}],"names":[],"columnWidth":64,"rowHeight":20, "images":[], "charts":[], "plates":[], "tags":[],"Batchcoordinates":{"resultdirection":1, "parameters":[]}}';

const pages = [
  { value: "home", name: "Home" },
  { value: "documents", name: "Documents" },
  { value: "dashboard", name: "Dashboard" },
  { value: "audithistory", name: "Audit history" },
  { value: "auditconfiguration", name: "Audit configuration" },
  { value: "cfrsettings", name: "CFR Settings" },
  { value: "testmaster", name: "Test master" },
  { value: "samplemaster", name: "Sample master" },
  { value: "unlockorder", name: "Unlock Order" },
  { value: "projectmaster", name: "Project master" },
  { value: "inventroy", name: "Inventroy" },
  { value: "instruments", name: "Instruments" },
  { value: "sdmsmappedinstruments", name: "SDMS Mapped Instruments" },
  { value: "delimiters", name: "delimiters" },
  { value: "methoddelimiters", name: "Method Delimiters" },
  { value: "methodmaster", name: "Method Master" },
  { value: "instrumentcategory", name: "Instrument category" },
  { value: "registertask", name: "Register task" },
  { value: "protocolorder", name: "Protocol order" },
  { value: "report", name: "Report" },
  { value: "sheetcreation", name: "Sheet creation" },
  { value: "protocols", name: "Protocols" },
  { value: "sheetmapping", name: "Sheet mapping" },
  { value: "domain", name: "Domain" },
  { value: "orderworkflow", name: "Order workflow" },
  { value: "passwordpolicy", name: "Password policy" },
  { value: "projectteam", name: "Project Team" },
  { value: "protocolworkflow", name: "Protocol workflow" },
  { value: "sheetworkflow", name: "Sheet workflow" },
  { value: "usergroup", name: "User group" },
  { value: "usermaster", name: "User master" },
  { value: "userrights", name: "User rights" },
  { value: "usersite", name: "User site" },
  { value: "registerorganisation", name: "Register organisation" },
  { value: "tenantlogin", name: "Tenant login" },
  { value: "tenantprofile", name: "Tenant profile" },
  { value: "administrator", name: "Administrator" },
  { value: "ad_organisation", name: "Administrator organisation" },
  { value: "ad_usermanagement", name: "Administrator usermanagement" },
  { value: "ad_adhelpdocument", name: "Administrator helpdocument" },
  { value: "ad_adhomedocument", name: "Administrator homedocument" },
  { value: "help", name: "Help" },
  { value: "fileviewer", name: "Fileviewer" },
  { value: "methodsetup", name: "Parser" },
  { value: "unitmaster", name: "Unitmaster" },
  { value: "sectionmaster", name: "Sectionmaster" },
  { value: "storagelocation", name: "Storagelocation" },
  { value: "materialcategory", name: "Materialcategory" },
  { value: "material", name: "Material" },
  { value: "materialinventory", name: "Materialinventory" },
  { value: "logbook", name: "Logbook" },
];

let isMultitenant = 1;// set isBuild4MsSql = true on next line if you are taken build for ms-sql server for material inventory purpose
// set isBuild4MsSql = true on next line if you are taken build for ms-sql server for material inventory purpose
const isBuild4MsSql = false;

// if build was given for demo set true otherwise its false
const isAllowCopyPaste = false;

const releaseVersion = "6.7.2";

const isParserEnabled = true;

const isReportEnabled = false;

const isFileSave4Lims = false;

const overridestylepuffloader = css`
  position: fixed;
  z-index: 99999;
  height: 2em;
  width: 2em;
  overflow: visible;
  margin: auto;
  top: 50%;
  left: 50%;
  bottom: 0;
  right: 0;
  margin-top: -50px;
  margin-left: -50px;

  :before {
    content: "";
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
  }
`;

const alphaarray = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z',
  'AA', 'AB', 'AC', 'AD', 'AE', 'AF', 'AG', 'AH', 'AI', 'AJ', 'AK', 'AL', 'AM', 'AN', 'AO', 'AP', 'AQ', 'AR', 'AS', 'AT', 'AU', 'AV'];

const lastValidationDate = {
  "static": 1,
  "label": "Last Validation Date & Time",
  "inputtype": "date",
  "displayname": {
    "en-US": "Last Validation Date & Time",
    "ru-RU": "Дата и время истечения срока действия",
    "tg-TG": "Санаи ба охир расидан ва вақт"
  }
};

const Protocolscreenenum = {
  protocoltemplate: 1,
  protocolorder: 2
}

const month = [
  // this.props.t("months.IDS_LBL_JAN", { param: "" }),
  // this.props.t("months.IDS_LBL_FEB", { param: "" }),
  // this.props.t("months.IDS_LBL_MAR", { param: "" }),
  // this.props.t("months.IDS_LBL_APR", { param: "" }),
  // this.props.t("months.IDS_LBL_MAY", { param: "" }),
  // this.props.t("months.IDS_LBL_JUN", { param: "" }),
  // this.props.t("months.IDS_LBL_JUL", { param: "" }),
  // this.props.t("months.IDS_LBL_AUG", { param: "" }),
  // this.props.t("months.IDS_LBL_SEP", { param: "" }),
  // this.props.t("months.IDS_LBL_OCT", { param: "" }),
  // this.props.t("months.IDS_LBL_NOV", { param: "" }),
  // this.props.t("months.IDS_LBL_DEC", { param: "" }),
  "JAN",
  "FEB",
  "MAR",
  "APR",
  "MAY",
  "JUN",
  "JUL",
  "AUG",
  "SEP",
  "OCT",
  "NOV",
  "DEC",
];

const staticMaterialFields = [
  { "fieldname": "Material Type", "defaultstatus": 4, },
  { "fieldname": "Material Category", "defaultstatus": 4 },
  { "fieldname": "Material Name", "defaultstatus": 4 },
  { "fieldname": "Unit", "defaultstatus": 4 },
  { "fieldname": "Section", "defaultstatus": 4 },
  { "fieldname": "Quarantine", " defaultstatus": 4 },
  // { "fieldname": "Reusable", "defaultstatus": 3 },
  { "fieldname": "Expiry Validations", "defaultstatus": 4 },
  { "fieldname": "Remarks", "defaultstatus": 4 }
]

const staticEquipFields = [
  { "fieldname": "Equipment Type", "defaultstatus": 4, },
  { "fieldname": "Equipment Category", "defaultstatus": 4 },
  { "fieldname": "Equipment", "defaultstatus": 4 },
  { "fieldname": "Equipment Code", "defaultstatus": 4 },
  { "fieldname": "Equipment Make", "defaultstatus": 4 },
  { "fieldname": "Equipment Model", "defaultstatus": 4 },
  { "fieldname": "Equipment Lot No", "defaultstatus": 4 },
  { "fieldname": "Equipment Used", "defaultstatus": 4 },
  { "fieldname": "Maintanance Time Period", "defaultstatus": 4 },
  { "fieldname": "callibaration Time Period", "defaultstatus": 4 },
  { "fieldname": "Remarks", "defaultstatus": 4 },
]

const Marvinjs_Enable = false;

export {
  lastValidationDate,
  initialreduxState,
  defalutreduxState,
  sheetDefaultContent,
  sdmsLink,
  isMultitenant,
  pages,
  override,
  releaseVersion,
  isParserEnabled,
  isReportEnabled,
  isFileSave4Lims, isBuild4MsSql, isAllowCopyPaste,
  overridestylepuffloader,
  alphaarray,
  Protocolscreenenum,
  month,
  staticMaterialFields,
  staticEquipFields,
  Marvinjs_Enable
};

