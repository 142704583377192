import React, { Component, Suspense } from "react";
import { replacestate } from "./../../../redux_store/actions/index";
import store from "./../../../redux_store/store/index";
import { withTranslation } from "react-multi-lang";
import "rc-tabs/assets/index.css";
import Tabs, { TabPane } from "rc-tabs";
import SyncLoader from "react-spinners/SyncLoader";
import { AtTabs } from "./../../../controls/custom-tabs.styles";
import {
  defalutreduxState,
  isMultitenant,
  override,
} from "./../../../custom_functions/Constance.jsx";
import {
  CF_decrypt,
  Servicecalls,
  Silentauditobject,
  updateActiveUserTime, setDateAsuserDefined, Getteamusersfromsession, Getuserprojectfromsession,
  Getuserworkflowfromsession, GetutcDate,
} from "./../../../custom_functions/Functions.jsx";
import $ from "jquery";
import "bootstrap";
import { withAlert } from "react-alert";
import { NavLink } from "react-router-dom";
import {
  Serviceuserworkflowdetails,
  Serviceprojectdetails,
  Serviceforuserrights,
  Serviceforauditconfig,
} from "./../../../custom_functions/Functions.jsx";
import {
  Modal,
  ModalBody,
} from "reactstrap";
import Service from "../../general/Service.jsx";

// import Menu from "../../Menu";
// import Protocolorder from "../../Protocolorders";
// import ApplicationHeader from "../../general/ApplicationHeader";
// import ApplicationFooter from "../../general/ApplicationFooter";
// import LoginView from "../../user_management/modules/LoginView";
// import SheetOrder from './../../orders/Sheetorder.tsx';

const createHistory = React.lazy(() => import("./../../general/History.jsx"));
const Menu = React.lazy(() => import("./../../Menu.jsx"));
const Protocolorder = React.lazy(() => import("./../../Protocolorders.jsx"));
const ApplicationHeader = React.lazy(() =>
  import("./../../general/ApplicationHeader.jsx")
);
const ApplicationFooter = React.lazy(() =>
  import("./../../general/ApplicationFooter.jsx")
);

const LoginView = React.lazy(() =>
  import("./../../../custom_modules/user_management/modules/LoginView.jsx")
);
const SheetOrder = React.lazy(() => import("./../../orders/Sheetorder.tsx"));
let daydiff = 7;
let filtertype = 2;
let diffdate = new Date().setDate(new Date().getDate() - daydiff);
const userDefinedDate = sessionStorage.userDefinedDate === "dd-MM-yyyy HH:mm:ss" || sessionStorage.userDefinedDate === "dd-MM-yy HH:mm:ss" || sessionStorage.userDefinedDate === "UTC Format" ? "YY/MM/DD HH:mm:ss" : sessionStorage.userDefinedDate;
const fromRange = filtertype === 1 ? setDateAsuserDefined(
  new Date(
    new Date().setDate(new Date().setHours(0, 0, 0, 0)),
    sessionStorage.userDefinedDate
  )
)
  : setDateAsuserDefined(
    diffdate,
    // sessionStorage.userDefinedDate
    userDefinedDate
  );

const toRange = setDateAsuserDefined(new Date(), userDefinedDate)
export const MyFolderContext = React.createContext();

class PRegistertask extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);

    this.ELNurl = document.getElementById("ELNname").value;
    let notificationorder = "";
    let isnotificationprocess = false;
    let notificationprotocolorder = "";
    let isnotificationprotocolprocess = false;
    let notificationordercode = "";
    if (
      this.props.location !== undefined &&
      this.props.location !== null &&
      this.props.location.state !== undefined &&
      this.props.location.state !== null
    ) {
      let notifiystate = JSON.parse(JSON.stringify(this.props.location.state));

      notificationorder =
        notifiystate.batchcode !== undefined ? notifiystate.batchcode : notifiystate.ordercode !== undefined ? notifiystate.ordercode : "";

      isnotificationprocess = notifiystate.isprocess
        ? notifiystate.isprocess : true;

      notificationordercode =
        notifiystate.ordercode !== undefined ? notifiystate.ordercode : "";

      notificationprotocolorder =
        notifiystate.protocolordercode !== undefined
          ? notifiystate.protocolordercode
          : "";

      isnotificationprotocolprocess = notifiystate.isprotocolprocess
        ? notifiystate.isprotocolprocess
        : false;

      setTimeout(() => {
        this.props.history.push("/registertask");
      }, 100);
    }

    this.state = {
      MenuGroup: "SheetView",
      ChildMenu: "SheetViewcss",
      PageName: this.props.t("Orders.Sheetorders.IDS_LBL_SHEETORDERS"),
      menuoption: 0,
      open: false,
      Menuselected: "",
      ordercode: undefined,
      userrightdetails: false,
      notificationorderno: notificationorder,
      isnotificationprocess: isnotificationprocess,
      notificationordercode: notificationordercode,
      // isnotificationprocess: true,
      notificationprotocolorderno: notificationprotocolorder,
      isnotificationprotocolprocess: isnotificationprotocolprocess,
      activekey: 1,
      sheetordervisible: false,
      Protocolordervisible: false,
      isopenfromlink: false,
      ServiceToUrl: "",
      ServiceMethodType: "POST",
      ServiceHeaderobject: {},
      ServcieonSuccess: undefined,
      ServcieonFailure: undefined,
      // Folders: []
    };

    let orderid = undefined;
    let batchid = undefined;

    if (
      this.props.location.hash !== undefined &&
      this.props.location.hash.length > 0
    ) {
      let Sheetrun = JSON.parse(
        JSON.stringify(
          this.props.location.hash
            .substring(2, this.props.location.hash.length - 1)
            .replaceAll("%22", '"')
        )
      );
      this.ELNurl = document.getElementById("ELNname").value;
      if (
        Sheetrun !== undefined &&
        Sheetrun.split(":").length > 1 &&
        Sheetrun.split(":")[0] === '"orderid"'
      ) {
        let ordertext = "{" + Sheetrun + "}";
        let orderdetails = JSON.parse(ordertext);

        if (isMultitenant === 1) {
          const decryptTenentID = JSON.parse(CF_decrypt(orderdetails.t));
          $.session.set("tenantdomain", decryptTenentID);
        }
        const decryptUserID = JSON.parse(CF_decrypt(orderdetails.u));
        $.session.set("User", Number(decryptUserID));

        const decryptOrderID = JSON.parse(CF_decrypt(orderdetails.orderid));

        // orderid = decryptOrderID;

        if (orderdetails.LO !== undefined && orderdetails.LO !== null) {
          const decryptOrderIDLims = JSON.parse(CF_decrypt(orderdetails.LO));
          batchid = decryptOrderIDLims;
          orderid = decryptOrderID;
        } else {
          orderid = decryptOrderID;
        }

        this.state.isloginopen = true;

        this.state.hashorderid = orderid;
        this.state.hashbatchid = batchid;
      }
    }
    else {
      // let order = orderid === undefined ? this.state.ordercode : orderid;
      // let batch = batchid === undefined ? "-1" : batchid;
      this.state.isloginopen = false;


      if (!$.session.get("usergroupname"))
        $.session.remove("User")

      // this.sheetscreen = (
      //   // <SheetView
      //   //   menuoption={this.state.menuoption}
      //   //   onprocess={this.onprocess.bind(this)}
      //   //   ordercode={order}
      //   //   batchid={batch}
      //   //   rights={this.state.userrightdetails}
      //   //ordercode={this.state.notificationorderno} rights={this.state.userrightdetails}
      //   //   notificationorder={this.state.notificationordercode}
      //   //   isnotificationprocess={this.state.isnotificationprocess}
      //   //   onresetnotification={this.onresetnotification.bind(this)}
      //   // />
      //   <MyFolderContext.Provider value={this.state.Folders}>
      //     <SheetOrder sessionvalues={{
      //       usercode: $.session.get("User"),
      //       username: $.session.get("Username"),
      //       lssitemaster: { sitecode: $.session.get("Usersite") },
      //       lsuserMaster: {
      //         usercode: $.session.get("User"),
      //         username: $.session.get("Username"),
      //       },
      //       token: $.session.get("Token"),
      //       tenant: $.session.get("tenantid")
      //     }}
      //       Folders={this.state.Folders}
      //       menuoption={this.state.menuoption}
      //       onprocess={this.onprocess.bind(this)}
      //       ordercode={this.state.ordercode}
      //       rights={this.state.userrightdetails}
      //       batchid={this.state.batch}
      //       notificationorder={this.state.notificationorderno}
      //       isnotificationprocess={this.state.isnotificationprocess}
      //       onresetnotification={this.onresetnotification.bind(this)}

      //     /></MyFolderContext.Provider>
      // );
    }

    this.MainMeunClick = this.MainMeunClick.bind(this);

    // setTimeout(() => {
    //   this.props.history.push("/registertask");
    // }, 100);

    this.protocolscreen = <div></div>;
  }

  componentWillUnmount() {
    this._isMounted = false;
    Serviceuserworkflowdetails(
      "" +
      document.getElementById("ELNname").value +
      "/Instrument/Getuserworkflow",
      { usergroupcode: $.session.get("multiusergroups") }
    );
    Serviceprojectdetails(
      "" +
      document.getElementById("ELNname").value +
      "/Instrument/Getuserprojects",
      store.getState().Loggeduser
    );
    Serviceforuserrights(
      "" +
      document.getElementById("ELNname").value +
      "/User/GetUserRightsonUser",
      {
        usercode: $.session.get("User"),
        lsusergroup: { usergroupcode: $.session.get("multiusergroups") },
      }
    );
    Serviceforauditconfig(
      "" +
      document.getElementById("ELNname").value +
      "/AuditTrail/GetAuditconfigUser",
      {
        usercode: $.session.get("User"),
        lsusergroup: { usergroupcode: $.session.get("multiusergroups") },
      }
    );
  }

  componentDidMount() {
    updateActiveUserTime();
    Serviceprojectdetails(
      "" +
      document.getElementById("ELNname").value +
      "/Instrument/Getuserprojects",
      { usercode: $.session.get("User"), lssitemaster: { sitecode: $.session.get("Usersite") } }
    );
    if (
      this.props.location.hash !== undefined &&
      this.props.location.hash.length > 0
    ) {
      let Sheetrun = JSON.parse(
        JSON.stringify(
          this.props.location.hash
            .substring(2, this.props.location.hash.length - 1)
            .replaceAll("%22", '"')
        )
      );

      if (
        this.state.notificationprotocolorderno !== "" &&
        this.state.notificationprotocolorderno !== undefined
      ) {
        this.protocolscreen = (
          <Protocolorder
            documentInfo={this.state.documentInfo}
            rights={this.state.userrightdetails}
            notificationprotocolorder={this.state.notificationprotocolorderno}
            isnotificationprotocolprocess={
              this.state.isnotificationprotocolprocess
            }
          />
        );
      }

      if (
        Sheetrun !== undefined &&
        Sheetrun.split(":").length > 1 &&
        Sheetrun.split(":")[0] === '"orderid"'
      ) {
        let ordertext = "{" + Sheetrun + "}";
        let orderdetails = JSON.parse(ordertext);

        /* linkSheetLogin(orderdetails);*/
        const decryptOrderID = JSON.parse(CF_decrypt(orderdetails.orderid));

        let orderid = undefined;
        let batchid = undefined;

        if (orderdetails.LO !== undefined && orderdetails.LO !== null) {
          const decryptOrderIDLims = JSON.parse(CF_decrypt(orderdetails.LO));
          orderid = decryptOrderID;
          batchid = decryptOrderIDLims;
        } else {
          orderid = decryptOrderID;
        }

        let order = orderid === undefined ? this.state.ordercode : orderid;
        let batch = batchid === undefined ? "-1" : batchid;

        this.setState({
          MenuGroup: "SheetView",
          PageName: this.props.t("Orders.Sheetorders.IDS_LBL_SHEETORDERS"),
          Menuselected: "SheetView_menu_group",
          ordercode: order,
          batchid: batch,
          sheetordervisible: true,
        });
      }
    }
    if (
      this.state.notificationorderno !== "" &&
      this.state.notificationorderno !== undefined
    ) {
      console.log(this.state)

      let passobj = {
        objsilentaudit: Silentauditobject(
          "process order",
          "Sheet Orders",
          this.props.t("Templates.Protocoltemplates.IDS_LBL_PROCESSORDERDASH", {
            batchid: this.props.location.state.batchid === "" ? this.state.notificationorderno : this.props.location.state.batchid,
            user2: sessionStorage.Username
          }))
      }
      Servicecalls(
        "" + this.ELNurl + "/AuditTrail/silentandmanualRecordHandler",
        passobj,
        (res) => { },
        (res) => { }
      );

      this.sheetscreen = (
        // <SheetView
        //   menuoption={this.state.menuoption}
        //   onprocess={this.onprocess.bind(this)}
        //   ordercode={this.state.ordercode}
        //   rights={this.state.userrightdetails}
        //   batchid={this.state.batch}
        //   //ordercode={this.state.notificationorderno} rights={this.state.userrightdetails}
        //   notificationorder={this.state.notificationorderno}
        //   isnotificationprocess={this.state.isnotificationprocess}
        //   onresetnotification={this.onresetnotification.bind(this)}
        // />
        <MyFolderContext.Provider value={this.state.Folders}>
          <SheetOrder sessionvalues={{
            usercode: $.session.get("User"),
            username: $.session.get("Username"),
            lssitemaster: { sitecode: $.session.get("Usersite") },
            lsuserMaster: {
              usercode: $.session.get("User"),
              username: $.session.get("Username"),
            },
            token: $.session.get("Token"),
            tenant: $.session.get("tenantid")
          }}
            openorder={this.props.location.state}
            menuoption={this.state.menuoption}
            onprocess={this.onprocess.bind(this)}
            ordercode={this.state.ordercode}
            rights={this.state.userrightdetails}
            batchid={this.state.batch}
            notificationorder={this.state.notificationorderno}
            isnotificationprocess={this.state.isnotificationprocess}
            onresetnotification={this.onresetnotification.bind(this)} /></MyFolderContext.Provider>
      );
    } else if (this.state.notificationorderno === "" ||
      this.state.notificationorderno === undefined) {
      let passObj = {
        // filetype: parseInt(type),
        fromdate: GetutcDate(new Date(fromRange)),
        todate: GetutcDate(new Date(toRange)),
        lstproject: Getuserprojectfromsession(),
        lstworkflow: Getuserworkflowfromsession(),
        // objsilentaudit: silentaudit,
        lsuserMaster: {
          usercode: parseInt(sessionStorage.User),
          username: sessionStorage.Username,
          unifieduserid: sessionStorage.unifieduserid,
          lssitemaster: { sitecode: $.session.get("Usersite") },
          ismultitenant: isMultitenant,
        },
      };
      passObj.lstuserMaster = Getteamusersfromsession();

      passObj.lsuserMaster.lsusergrouptrans = {
        usergroupcode: localStorage.getItem("multiusergroups"),
        usergroupname: localStorage.getItem("usergroupname"),
        usergroupstatus: localStorage.getItem("usergroupstatus"),
      };

      this.setState({
        ServiceToUrl: "" + this.ELNurl + "/Instrument/Getfoldersfororders",
        ServiceHeaderobject: passObj,
        ServiceMethodType: "POST",
        ServcieonSuccess: (res) => {
          this.setState({ Folders: res }, () => {
            // this.state.isloginopen = false;


            if (!$.session.get("usergroupname"))
              $.session.remove("User")

            this.sheetscreen = (
              <MyFolderContext.Provider value={this.state.Folders}>
                <SheetOrder sessionvalues={{
                  usercode: $.session.get("User"),
                  username: $.session.get("Username"),
                  lssitemaster: { sitecode: $.session.get("Usersite") },
                  lsuserMaster: {
                    usercode: $.session.get("User"),
                    username: $.session.get("Username"),
                  },
                  token: $.session.get("Token"),
                  tenant: $.session.get("tenantid")
                }}
                  Folders={this.state.Folders}
                  menuoption={this.state.menuoption}
                  onprocess={this.onprocess.bind(this)}
                  ordercode={this.state.ordercode}
                  rights={this.state.userrightdetails}
                  batchid={this.state.batch}
                  notificationorder={this.state.notificationorderno}
                  isnotificationprocess={this.state.isnotificationprocess}
                  onresetnotification={this.onresetnotification.bind(this)}

                /></MyFolderContext.Provider>
            );
            this.setState({ isloginopen: false })
          })


        },
        ServcieonFailure: (res) => { },
      });
      // Servicecalls(
      //   "" + this.ELNurl + "/Instrument/Getfoldersfororders",
      //   passObj,
      //   (res) => {
      //     this.setState({ Folders: res }, () => {
      //       // this.state.isloginopen = false;


      //       if (!$.session.get("usergroupname"))
      //         $.session.remove("User")

      //       this.sheetscreen = (
      //         <MyFolderContext.Provider value={this.state.Folders}>
      //           <SheetOrder sessionvalues={{
      //             usercode: $.session.get("User"),
      //             username: $.session.get("Username"),
      //             lssitemaster: { sitecode: $.session.get("Usersite") },
      //             lsuserMaster: {
      //               usercode: $.session.get("User"),
      //               username: $.session.get("Username"),
      //             },
      //             token: $.session.get("Token"),
      //             tenant: $.session.get("tenantid")
      //           }}
      //             Folders={this.state.Folders}
      //             menuoption={this.state.menuoption}
      //             onprocess={this.onprocess.bind(this)}
      //             ordercode={this.state.ordercode}
      //             rights={this.state.userrightdetails}
      //             batchid={this.state.batch}
      //             notificationorder={this.state.notificationorderno}
      //             isnotificationprocess={this.state.isnotificationprocess}
      //             onresetnotification={this.onresetnotification.bind(this)}

      //           /></MyFolderContext.Provider>
      //       );
      //       this.setState({ isloginopen: false })
      //     })


      //   },
      //   (res) => { }
      // );
    }

  }

  MainMeunClick(Menu) {
    let PageName = "";
    let open = false;
    if (Menu === "SheetCreation") {
      PageName = this.props.t("Templates.Sheettemplates.IDS_LBL_SHEETCREATION", {
        param: "",
      });
      open = false;
    } else if (Menu === "SheetView") {
      PageName = this.props.t("Orders.Sheetorders.IDS_LBL_SHEETORDERS");
      open = false;
    } else if (Menu === "SheetSetting") {
      PageName = this.props.t("menu.IDS_SHEETSETTING", {
        param: "",
      });
    } else if (Menu === "AuditTrail") {
      PageName = this.props.t("AuditTrail.ID_AUDITTRAIL", {
        param: "",
      });
      open = false;
    } else if (Menu === "UserManagement") {
      PageName = this.props.t("UserManagement.ID_USERMANAGEMENT", {
        param: "",
      });
      open = false;
    } else if (Menu === "Dashboard") {
      PageName = this.props.t("Dashboard.IDS_LBL_DASHBOARD", {
        param: "",
      });
      open = false;
    } else if (Menu === "Masters") {
      PageName = this.props.t("Masters.ID_MASTERS", {
        param: "",
      });
      open = false;
    } else if (Menu === "DownloadReport") {
      PageName = this.props.t("Reports.IDS_LBL_SCREENNAME", {
        param: "",
      });
      open = false;
    }

    this.setState({
      MenuGroup: Menu,
      PageName: PageName,
      open: open || true,
      Menuselected: "",
    });
  }

  ChilMenuclick(Menu) {
    this.setState({ ChildMenu: Menu });
  }

  onlogoutSuccess(response) {
    store.dispatch(replacestate(defalutreduxState));
    setTimeout(() => {
      let tenantdomain = $.session.get("tenantdomain");

      if (tenantdomain !== undefined) {
        isMultitenant === 0
          ? this.props.history.push("/")
          : this.props.history.push("/#" + tenantdomain);
        this.props.alert.success(this.props.t("Login.IDS_MSG_LOGOUT"));
      } else {
        this.props.history.push("/")
      }
    }, 100);
  }

  onlogoutFailure(response) { }

  onprocess(pagename) {
    this.setState({ PageName: pagename });
  }

  onredirectrights(objrights) {
    this.setState({
      userrightdetails: objrights,
      sheetordervisible: objrights.Sheetview,
      Protocolordervisible: objrights.ProtocolOrder,
    });
  }

  OnNotificationchange(data) {
    if (data !== null && data !== undefined) {
      let notificationorder =
        data.ordercode !== undefined ? data.ordercode : "";
      let isnotificationprocess = data.isprocess ? data.isprocess : false;

      this.setState({
        notificationorderno: notificationorder,
        isnotificationprocess: isnotificationprocess,
      });
    }
  }

  onresetnotification() {
    if (
      this.props.location !== undefined &&
      this.props.location !== null &&
      this.props.location.state !== undefined &&
      this.props.location.state !== null
    ) {
      const history = createHistory;
      if (history.location !== undefined) {
        if (history.location.state && history.location.state.ordercode) {
          let state = { ...history.location.state };
          delete state.ordercode;
          history.replace({ ...history.location, state });
        }
      }
    }

    this.setState({
      notificationorderno: undefined,
      isnotificationprocess: false,
    });
  }

  redirecttohome() {
    this.props.history.push("/Home");
  }

  ontabclick = (key) => { };

  onsearchchange(obj) {
    this.setState({
      notificationorderno: obj.batchcode,
      isnotificationprocess: obj.canuserprocess,
    });

    if (obj !== undefined && obj !== null) {

      let passorder = {
        batchcode: obj.batchcode,
        objLoggeduser: store.getState().Loggeduser,
      };

      passorder.objsilentaudit = Silentauditobject("view", "SheetView",
        this.props.t("Orders.Sheetorders.IDS_USERREQVIEW", {
          User: store.getState().Loggeduser.username, user1: obj.batchcode,
        })
      );

      Servicecalls("" + this.ELNurl + "/AuditTrail/AuditConfigurationrecord", passorder, (res) => { }, (res) => { });
    }
  }

  redirecttosheetview(e) {
    this.setState({
      MenuGroup: "SheetView",
      PageName: this.props.t("Orders.Sheetorders.IDS_LBL_SHEETORDERS"),
      Menuselected: "SheetView_menu_group",
      ordercode: this.state.hashorderid,
      batchid: this.state.hashbatchid,
      sheetordervisible: true,
      isloginopen: false
    });
    this.sheetscreen = (
      // <SheetView
      //   menuoption={this.state.menuoption}
      //   onprocess={this.onprocess.bind(this)}
      //   ordercode={this.state.hashorderid}
      //   batchid={this.state.hashbatchid}
      //   rights={this.state.userrightdetails}
      //   //ordercode={this.state.notificationorderno} rights={this.state.userrightdetails}
      //   notificationorder={this.state.notificationordercode}
      //   isnotificationprocess={this.state.isnotificationprocess}
      //   onresetnotification={this.onresetnotification.bind(this)}
      // />
      <MyFolderContext.Provider value={this.state.Folders}>
        <SheetOrder sessionvalues={{
          usercode: $.session.get("User"),
          username: $.session.get("Username"),
          lssitemaster: { sitecode: $.session.get("Usersite") },
          lsuserMaster: {
            usercode: $.session.get("User"),
            username: $.session.get("Username"),
          },
          token: $.session.get("Token"),
          tenant: $.session.get("tenantid")
        }}
          menuoption={this.state.menuoption}
          onprocess={this.onprocess.bind(this)}
          ordercode={this.state.ordercode}
          rights={this.state.userrightdetails}
          batchid={this.state.batch}
          notificationorder={this.state.notificationorderno}
          isnotificationprocess={this.state.isnotificationprocess}
          onresetnotification={this.onresetnotification.bind(this)} /></MyFolderContext.Provider>
    );

    setTimeout(() => {
      this.setState({ isopenfromlink: true });
    }, 3000);
  }

  forgotpass(e) {

  }

  NewRegistration(e) {

  }

  onbackclick(e) {

  }

  render() {
    if (!$.session.get("User")) {
      setTimeout(() => {
        let tenantdomain = $.session.get("tenantdomain");

        if (tenantdomain !== undefined) {
          isMultitenant === 0
            ? this.props.history.push("/")
            : this.props.history.push("/#" + tenantdomain);
          // this.props.alert.success(this.props.t("Login.IDS_MSG_LOGOUT"));
        } else {
          this.props.history.push("/")
        }
      }, 100);
      return <div></div>;
    }

    return (
      <div id="idElnBody">
        <Service
          Tourl={this.state.ServiceToUrl}
          MethodType={this.state.ServiceMethodType}
          onSuccess={this.state.ServcieonSuccess}
          onFailure={this.state.ServcieonFailure}
          Headerobject={this.state.ServiceHeaderobject}
          onReseturl={() => {
            this.setState({ ServiceToUrl: "" });
          }}
        ></Service>
        {!this.state.isloginopen && !this.state.isopenfromlink && <Suspense fallback={<div></div>}>
          <Menu
            sendData={this.MainMeunClick}
            childmenuclick={this.ChilMenuclick.bind(this)}
            selected={this.state.Menuselected}
            menugroup={this.state.MenuGroup}
            redirectrights={this.onredirectrights.bind(this)}
          />
        </Suspense>}
        {!this.state.isloginopen && <div id="bodycontent" className="d-flex flex-column">
          {!this.state.isopenfromlink && <Suspense fallback={<div></div>}>
            <div id="onremoveitems">
              <ApplicationHeader
                onlogoutSuccess={this.onlogoutSuccess.bind(this)}
                redirecttohome={this.redirecttohome.bind(this)}
                onlogoutFailure={this.onlogoutFailure.bind(this)}
                PageName={this.state.PageName}
                OnNotificationchange={this.OnNotificationchange.bind(this)}
                onsearchchange={this.onsearchchange.bind(this)}
              />
            </div>
          </Suspense>}
          <AtTabs>
            <Tabs
              activeKey={"1"}
              onTabClick={this.ontabclick}
              moreIcon={<span className="moreDot">...</span>}
            >
              {this.state.sheetordervisible ?
                <TabPane
                  tab={this.props.t("Orders.Sheetorders.IDS_LBL_SHEET", {
                    param: "",
                  })}
                  key="1"
                >
                  <Suspense
                    fallback={
                      <SyncLoader
                        css={override}
                        size={10}
                        color={"#123abc"}
                        margin={5}
                      />
                    }
                  >
                    {this.sheetscreen}
                    {/* <SheetView
                      menuoption={this.state.menuoption}
                      onprocess={this.onprocess.bind(this)}
                      ordercode={this.state.ordercode}
                      rights={this.state.userrightdetails}
                      //ordercode={this.state.notificationorderno} rights={this.state.userrightdetails}
                      notificationorder={this.state.notificationorderno}
                      isnotificationprocess={this.state.isnotificationprocess}
                      onresetnotification={this.onresetnotification.bind(this)}
                    /> */}
                  </Suspense>
                </TabPane>
                :
                false
              }
              {!this.state.isopenfromlink && this.state.Protocolordervisible ? (
                <TabPane
                  tab={
                    <NavLink
                      to={{ pathname: "/Protocolorder", state: {} }}
                      title="Protocol orders"
                    >
                      {this.props.t("Orders.Protocolorders.IDS_LBL_PROTOCOL", {
                        param: "",
                      })}
                    </NavLink>
                  }
                  key="2"
                ></TabPane>
              ) : (
                false
              )}
            </Tabs>
          </AtTabs>
        </div>}
        {!this.state.isloginopen && <Suspense fallback={<div></div>}>
          <ApplicationFooter
            onlogoutSuccess={this.onlogoutSuccess.bind(this)}
            onlogoutFailure={this.onlogoutFailure.bind(this)}
            onforgot={this.props.onforgot}
          />
        </Suspense>}
        {this.state.isloginopen &&
          <Modal
            isOpen={this.state.isloginopen}
            className=" modal-md smallPopup  screen-centered-scroll"
          >
            <ModalBody>
              <Suspense fallback={<div></div>}>
                <LoginView
                  OnSuccess={this.redirecttosheetview.bind(this)}
                  onforgot={this.forgotpass.bind(this)}
                  onnewregister={this.NewRegistration.bind(this)}
                  username={""}
                  siteobj={""}
                  onbackclick={this.onbackclick.bind(this)}
                  hashtenant={$.session.get("tenantdomain")}
                  selectedtenant={$.session.get("tenantdomain")}
                  fromregister={true}
                />
                {/* <Azureredirect /> */}
              </Suspense>
            </ModalBody>
          </Modal>
        }
      </div>
    );
  }
}
export default withAlert()(withTranslation(PRegistertask));
