import React from "react";
import ReactDOM from "react-dom";

import "typeface-source-sans-pro";
import "bootstrap/dist/css/bootstrap.min.css";
import '@fortawesome/fontawesome-free/css/all.min.css';
//import '@progress/kendo-theme-default/dist/all.css';
import '@progress/kendo-theme-bootstrap/dist/all.css';
import "kekule/dist/themes/default/kekule.css";
import 'hammerjs';

import PropTypes from "prop-types";
import { Provider } from "react-redux";
import rootReducer from "./../src/custom_modules/methodsetup/reducers/index";
import { createStore, applyMiddleware, compose } from "redux";
import thunk from 'redux-thunk';
import { setDefaultTranslations, setDefaultLanguage } from "react-multi-lang";
import pt from "./custom_modules/translations/pt.json";
import en from "./custom_modules/translations/en.json";
import ko from "./custom_modules/translations/ko.json";
import fr from "./custom_modules/translations/fr.json";
import "./js/lib/jquerysession.js";

import App from "./App";


// const {onlyOfficeReadOnly} = lazy(() => import('./custom_modules/DownloadReport.jsx'));


setDefaultTranslations({ pt, en, ko, fr });

if (localStorage.getItem("ElnLang") !== undefined && localStorage.getItem("ElnLang") !== null) {
  setDefaultLanguage(localStorage.getItem("ElnLang"));
} else {
  setDefaultLanguage("en");
  localStorage.setItem("ElnLang", "en");
}


// const customAlertTemplate = ({ style, options, message, close }) => (
//   <div style={style}>
//     {options.type === 'info' && '!'}
//     {options.type === 'success' && ':)'}
//     {options.type === 'error' && ':('}
//     {message}
//     <button onClick={close}>X</button>
//   </div>
// )


const Routing = ({ store }) => (
  <Provider store={store}>
    <App />
  </Provider>
);

Routing.propTypes = {
  store: PropTypes.object.isRequired
};


// Add this in node_modules/react-dom/index.js
window.React1 = require("react");

// Add this in your component file
require("react-dom");
window.React2 = require("react");

const initialState = {};

const middleware = [thunk];

const store = createStore(rootReducer, initialState,
  compose(
    applyMiddleware(...middleware),
    // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()

  ));

// var requireAuth = (store, nextState, replace) => {
//   console.log("store: ", store);
// };

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("id_container")
);
