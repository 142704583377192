import React, { useState, useCallback, Suspense } from "react";
import { NavLink } from "react-router-dom";
import PuffLoader from "react-spinners/PuffLoader";
import { css } from "@emotion/core";
import { GetutcDate, Servicecalls, Silentauditobject, AuditTrailValidation, Manualauditobject, orderType, orderTypeforprotocol } from "./Functions.jsx";
import $ from "jquery";
import Autocomplete from "react-autocomplete";
import store from "./../redux_store/store/index";

import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
// import PageLoader from "./../custom_modules/general/PageLoader.jsx";
const PageLoader = React.lazy(() =>
  import("./../custom_modules/general/PageLoader.jsx")
);
const KendoGridView = React.lazy(() =>
  import("./../controls/KendoGridView.jsx")
);

// const Audit = React.lazy(() => import("./../custom_modules/Audit.jsx"));

// import Share from "./../custom_modules/general/Share.jsx";
const Audit = React.lazy(() => import("./../custom_modules/Audit.jsx"));

const override = css`
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: visible;
  margin: auto;
  top: 50%;
  left: 50%;
  bottom: 0;
  right: 0;
  margin-top: -50px;
  margin-left: -50px;

  :before {
    content: "";
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
  }
`;

const ELNurl = document.getElementById("ELNname").value;

function Unshareorder(properties) {
  let [loading, setLoading] = useState(false);

  const sharetosuccess = useCallback(
    async (response) => {
      if (properties.passbackorder !== undefined) {
        properties.passbackorder(response);
      }
      properties.props.alert.success(properties.props.t("Orders.Sheetorders.IDS_MSG_SHEETUNSHARE"));

      let passObjDet = {
        objsilentaudit: Silentauditobject(
          "unshare",
          "Sheet Orders",
          properties.props.t("Orders.Sheetorders.IDS_AUDIT_SHEETUNSHARE", {
            screen: orderType(properties.props.dataItem.ordertype),
            user1: response.sharebatchid,
            user2: response.sharetousername,
            user3: response.sharebyusername,
          })
        ),
      };
      Servicecalls(
        "" + ELNurl + "/AuditTrail/silentandmanualRecordHandler",
        passObjDet,
        (res) => { },
        (res) => { }
      );
    },
    [properties]
  );

  const sharetofailure = useCallback(async (response) => { }, []);

  const sharebysuccess = useCallback(
    async (response) => {
      setLoading(false);
      response.unsharedon = GetutcDate(new Date());
      Servicecalls(
        "" + ELNurl + "/Instrument/Unshareorderto",
        response,
        sharetosuccess,
        sharetofailure
      );
    },
    [sharetosuccess, sharetofailure]
  );

  const sharebyfailure = useCallback(async (response) => {
    setLoading(false);
  }, []);

  const Unshareorderorder = useCallback(
    async (e) => {
      setLoading(true);
      let orderval = properties.props.dataItem;
      orderval.unsharedon = GetutcDate(new Date());

      Servicecalls(
        "" + ELNurl + "/Instrument/Unshareorderby",
        orderval,
        sharebysuccess,
        sharebyfailure
      );
    },
    [properties, sharebysuccess, sharebyfailure]
  );

  return (
    <button title={"Unshare"} className="btn " onClick={Unshareorderorder}>
      <span className="fa-stack fa-sm">
        <i className="fa fa-share-alt fa-stack-1x"></i>
        <i className="fa fa-ban fa-stack-2x "></i>
      </span>
      {loading ? (
        <PuffLoader css={override} size={100} color={"#123abc"} />
      ) : (
        ""
      )}
    </button>
  );
}

function Unshareorderorderinsidescreen(properties) {
  let [loading, setLoading] = useState(false);

  const sharetosuccess = useCallback(
    async (response) => {
      if (properties.passbackorder !== undefined) {
        properties.passbackorder(response);
      }

      let passObjDet = {
        objsilentaudit: Silentauditobject(
          "unshare",
          "Sheet Orders",
          properties.props.t("Orders.Sheetorders.IDS_AUDIT_SHEETUNSHARE", {
            screen: orderType(properties.props.dataItem.ordertype),

            user1: response.sharebatchid,
            user2: response.sharetousername,
            user3: response.sharebyusername,
          })
        ),
      };
      Servicecalls(
        "" + ELNurl + "/AuditTrail/silentandmanualRecordHandler",
        passObjDet,
        (res) => { },
        (res) => { }
      );
      properties.props.alert.success(properties.props.t("Orders.Sheetorders.IDS_MSG_SHEETUNSHARE"));
    },
    [properties]
  );

  const sharetofailure = useCallback(async (response) => { }, []);

  const sharebysuccess = useCallback(
    async (response) => {
      setLoading(false);
      response.unsharedon = GetutcDate(new Date());
      Servicecalls(
        "" + ELNurl + "/Instrument/Unshareorderto",
        response,
        sharetosuccess,
        sharetofailure
      );
    },
    [sharetosuccess, sharetofailure]
  );

  const sharebyfailure = useCallback(async (response) => {
    setLoading(false);
  }, []);

  const Unshareorderorder = useCallback(
    async (e) => {
      setLoading(true);
      let orderval = properties.order;
      orderval.unsharedon = GetutcDate(new Date());

      Servicecalls(
        "" + ELNurl + "/Instrument/Unshareorderby",
        orderval,
        sharebysuccess,
        sharebyfailure
      );
    },
    [properties, sharebysuccess, sharebyfailure]
  );

  return (
    <button title={"Unshare"} className="btn " onClick={Unshareorderorder}>
      <span className="fa-stack fa-sm">
        <i className="fa fa-share-alt fa-stack-1x"></i>
        <i className="fa fa-ban fa-stack-2x "></i>
      </span>
      {loading ? (
        <PuffLoader css={override} size={100} color={"#123abc"} />
      ) : (
        ""
      )}
    </button>
  );
}





function Shareorder(properties) {
  let [loading, setLoading] = useState(false);
  let [shareopen, setShareopen] = useState(false);
  let [users, setUsers] = useState([]);
  const [clickTimer, setClickTimer] = useState(null);
  let [username, setUsername] = useState(properties.toshare);
  let [sharerights, setSharerights] = useState("0");
  let [Auditservicecall, setAuditservicecall] = useState({
    AuditUrl: "",
    AuditObject: {},
    AuditPassfunction: undefined,
    AuditFailfunction: undefined,
    AuditCancelfunction: undefined,
  });

  const Usergetsuccess = useCallback(async (response) => {
    setUsers(response);
  }, []);

  const Usergetfaliure = useCallback(async (response) => { }, []);

  const Shareorderorder = useCallback(
    async (e) => {
      setShareopen(true);

      let objunifieduser = {
        usercode: $.session.get("User"),
        username: $.session.get("Username"),
        lssitemaster: { sitecode: $.session.get("Usersite") },
      };

      Servicecalls(
        "" + ELNurl + "/User/GetAllActiveUsers",
        objunifieduser,
        Usergetsuccess,
        Usergetfaliure
      );
    },
    [Usergetsuccess, Usergetfaliure]
  );

  const Oncloseshare = useCallback(async (e) => {
    setShareopen(false);
    setUsername("");
  }, []);

  const sharebysuccess = useCallback(
    async (response) => {
      setLoading(false);
      if (properties.passbackorder !== undefined) {
        properties.passbackorder(response);
      }

      properties.props.alert.success(properties.props.t("Orders.Sheetorders.IDS_MSG_SHEETSHARE"));
      setUsername("");

      let passObjDet = {
        objsilentaudit: Silentauditobject(
          "Share",
          "Sheet Orders",
          properties.props.t("Orders.Sheetorders.IDS_AUDIT_SHEETSHARE", {
            screen: orderType(properties.props.dataItem.filetype),
            user1: response.order.sharebatchid,
            user2: response.order.sharetousername,
            user3: response.order.sharebyusername,

          })
        ),
      };

      Servicecalls(
        "" + ELNurl + "/AuditTrail/silentandmanualRecordHandler",
        passObjDet,
        (res) => { },
        (res) => { }
      );
    },
    [properties]
  );

  const sharebyfailure = useCallback(async (response) => {
    setLoading(false);
  }, []);

  const sharesuccess = useCallback(
    async (response) => {
      setLoading(true);
      setShareopen(false);
      response.sharedon = GetutcDate(new Date());
      Servicecalls(
        "" + ELNurl + "/Instrument/Insertshareorderby",
        response,
        sharebysuccess,
        sharebyfailure
      );
    },
    [sharebysuccess, sharebyfailure]
  );

  const sharefailure = useCallback(async (response) => { }, []);


  const Onsaveshare = useCallback(
    async (e) => {
      if (username.trim() !== "") {
        let userindex = users.findIndex(
          (obj) => obj.unifieduserid === username
        );
        if (userindex !== undefined && userindex > -1) {
          // let design = auditvalidation()
          let shareobj = {
            sharetounifiedid: username,
            sharerights: sharerights,
            sharebyunifiedid: $.session.get("unifieduserid"),
            sharestatus: 1,
            // shareitemdetails: JSON.stringify(properties.props.dataItem),
            sharetousername: users[userindex].username,
            sharebyusername: $.session.get("Username"),
            sharedon: GetutcDate(new Date()),
            sharemodifiedon: GetutcDate(new Date()),
            sharebatchcode: properties.props.dataItem.batchcode,
            sharebatchid: properties.props.dataItem.batchid,
            ordertype: properties.props.dataItem.filetype,
            objLoggeduser: users[userindex],
            order: {
              batchcode: properties.props.dataItem.batchcode,
              filetype: properties.props.dataItem.filetype,
              objLoggeduser: store.getState().Loggeduser
            },
            usersharedby: { usercode: $.session.get("User") },
            usersharedon: { usercode: users[userindex].usercode }
          };
          let Audit = AuditTrailValidation(
            "IDS_MDL_ORDERS",
            "IDS_SCN_SHEETORDERS",
            "IDS_TSK_SHEETSHARE"
          );

          if (Audit) {
            shareobj.objmanualaudit = Manualauditobject("Share", "Sheet Orders");
            $("#auditTrailModalsheetview").modal("show");

            setAuditservicecall({
              AuditUrl: "" + ELNurl + "/Instrument/Insertshareorder",
              AuditObject: shareobj,
              AuditPassfunction: sharesuccess,
              AuditFailfunction: sharefailure,
              AuditCancelfunction: sharefailure,
            })

          }


          else {
            Servicecalls(
              "" + ELNurl + "/Instrument/Insertshareorder",
              shareobj,
              sharesuccess,
              sharefailure
            );
          }
        } else {
          properties.props.alert.error(properties.props.t("Header.IDS_LBL_INVALIDID"));
        }
      } else {
        properties.props.alert.error(properties.props.t("Header.IDS_LBL_SELECTUNIQID"));
      }
    },
    [username, users, properties, sharesuccess, sharefailure, sharerights]
  );

  const Share = useCallback(
    async (e) => {
      let orderval = properties.order;
      orderval.sharedon = GetutcDate(new Date());
      orderval.sharestatus = 1;
      orderval.unsharedon = null;

      Servicecalls(
        "" + ELNurl + "/Instrument/Insertshareorder",
        orderval,
        sharesuccess,
        sharefailure
      );
    },
    [properties, sharesuccess, sharefailure]
  );

  const Sharerightschanged = useCallback(async (e) => {
    setSharerights(e.currentTarget.value);
  }, []);

  const OnUserchange = useCallback(async (e) => {
    setUsername(e.target.value);
  }, []);

  const OnUserSelect = useCallback(async (val) => {
    setUsername(val);
  }, []);

  const handleclickevent = (event) => {
    if (clickTimer === null) {
      let rtfunction = properties.isshared !== undefined && properties.isshared ? Share : Shareorderorder;
      setClickTimer(window.setTimeout(() => {
        rtfunction();
        setClickTimer(null);
      }, 250));
    } else {
      window.clearTimeout(clickTimer);
      setClickTimer(null);
    }
  }
  return (
    <React.Fragment>

      <button
        title={"Share"}
        className={`
                    ${properties.isshared !== undefined && properties.isshared
            ? "btn "
            : "grid-icons"
          }
                    ${shareopen ? "btn-light-active" : ""}
                `}
        onClick={handleclickevent}
      // onClick={
      //   // properties.isshared !== undefined && properties.isshared
      //   //   ? Share
      //   //   : Shareorderorder
      // }
      >
        <i className="fa fa-share-alt sheet-share"></i>
        {loading ? (
          <PuffLoader css={override} size={100} color={"#123abc"} />
        ) : (
          ""
        )}
      </button>

      {shareopen
        ? share(
          properties,
          users,
          username,
          sharerights,
          shareopen,
          Oncloseshare,
          Onsaveshare,
          OnUserchange,
          OnUserSelect,
          Sharerightschanged, 
          Auditservicecall,
          properties.props.dataItem
        )


        : ""}
    </React.Fragment>
  );
}
const Auditcancel = () => {
  $("#auditTrailModalsheetview").modal("hide");
}
function Sharesheetorderinsidescreen(properties) {
  let [loading, setLoading] = useState(false);
  let [shareopen, setShareopen] = useState(false);
  let [users, setUsers] = useState([]);
  let [username, setUsername] = useState(properties.toshare);
  let [sharerights, setSharerights] = useState("0");
  // let Auditservicecall = useState({
  //   AuditUrl: "",
  //   AuditObject: {},
  //   AuditPassfunction: undefined,
  //   AuditFailfunction: undefined,
  //   AuditCancelfunction: undefined,
  // });
  let [Auditservicecall, setAuditservicecall] = useState({
    AuditUrl: "",
    AuditObject: {},
    AuditPassfunction: undefined,
    AuditFailfunction: undefined,
    AuditCancelfunction: undefined,
  });
  const Usergetsuccess = useCallback(async (response) => {
    setUsers(response);
  }, []);

  const Usergetfaliure = useCallback(async (response) => { }, []);

  const Shareorderorder = useCallback(
    async (e) => {
      setShareopen(true);

      let objunifieduser = {
        usercode: $.session.get("User"),
        username: $.session.get("Username"),
        lssitemaster: { sitecode: $.session.get("Usersite") },
      };

      Servicecalls(
        "" + ELNurl + "/User/GetAllActiveUsers",
        objunifieduser,
        Usergetsuccess,
        Usergetfaliure
      );
    },
    [Usergetsuccess, Usergetfaliure]
  );

  const Oncloseshare = useCallback(async (e) => {
    setShareopen(false);
    setUsername("");
  }, []);

  const sharebysuccess = useCallback(
    async (response) => {
      setLoading(false);
      if (properties.passbackorder !== undefined) {
        properties.passbackorder(response);
      }

      properties.props.alert.success(properties.props.t("Orders.Sheetorders.IDS_MSG_SHEETSHARE"));
      setUsername("");
      let ordertypename = orderType(properties.order.filetype);
      let passObjDet = {
        objsilentaudit: Silentauditobject(
          "Share",
          "Sheet Orders",
          properties.props.t("Orders.Sheetorders.IDS_AUDIT_SHEETSHARE", {
            screen: ordertypename,
            user1: response.order.sharebatchid,
            user2: response.order.sharetousername,
            user3: response.order.sharebyusername,
          })
        ),
      };

      Servicecalls(
        "" + ELNurl + "/AuditTrail/silentandmanualRecordHandler",
        passObjDet,
        (res) => { },
        (res) => { }
      );
    },
    [properties]
  );

  const sharebyfailure = useCallback(async (response) => {
    setLoading(false);
  }, []);

  const sharesuccess = useCallback(
    async (response) => {
      setLoading(true);
      setShareopen(false);
      response.sharedon = GetutcDate(new Date());
      Servicecalls(
        "" + ELNurl + "/Instrument/Insertshareorderby",
        response,
        sharebysuccess,
        sharebyfailure
      );
    },
    [sharebysuccess, sharebyfailure]
  );

  const sharefailure = useCallback(async (response) => { }, []);

  const Onsaveshare = useCallback(
    async (e) => {
      if (username.trim() !== "") {
        let userindex = users.findIndex(
          (obj) => obj.unifieduserid === username
        );
        properties.order.objLoggeduser = store.getState().Loggeduser;
        if (userindex !== undefined && userindex > -1) {
          let shareobj = {
            sharetounifiedid: username,
            sharerights: sharerights,
            sharebyunifiedid: $.session.get("unifieduserid"),
            sharestatus: 1,
            shareitemdetails: JSON.stringify(properties.order),
            sharetousername: users[userindex].username,
            sharebyusername: $.session.get("Username"),
            sharedon: GetutcDate(new Date()),
            sharemodifiedon: GetutcDate(new Date()),
            sharebatchcode: properties.order.batchcode,
            sharebatchid: properties.order.batchid,
            ordertype: properties.order.filetype,
            order: properties.order,
            usersharedby: { usercode: Number(sessionStorage.User) },
            usersharedon: { usercode: users[userindex].usercode },
          };
          let Audit = AuditTrailValidation(
            "IDS_MDL_ORDERS",
            "IDS_SCN_SHEETORDERS",
            "IDS_TSK_SHEETSHARE"
          );

          if (Audit) {
            shareobj.objmanualaudit = Manualauditobject("Share", "Sheet Orders");
            $("#auditTrailModalsheetview").modal("show");

            setAuditservicecall({
              AuditUrl: "" + ELNurl + "/Instrument/Insertshareorder",
              AuditObject: shareobj,
              AuditPassfunction: sharesuccess,
              AuditFailfunction: sharefailure,
              AuditCancelfunction: sharefailure,
            })

          } else {
            Servicecalls(
              "" + ELNurl + "/Instrument/Insertshareorder",
              shareobj,
              sharesuccess,
              sharefailure
            );
          }
        } else {
          properties.props.alert.error(properties.props.t("Header.IDS_LBL_INVALIDID"));
        }
      } else {
        properties.props.alert.error(properties.props.t("Header.IDS_LBL_SELECTUNIQID"));
      }
    },
    [username, users, properties, sharesuccess, sharefailure, sharerights]
  );

  const Share = useCallback(
    async (e) => {
      let orderval = properties.order;
      orderval.sharedon = GetutcDate(new Date());
      orderval.sharestatus = 1;
      orderval.unsharedon = null;

      Servicecalls(
        "" + ELNurl + "/Instrument/Insertshareorder",
        orderval,
        sharesuccess,
        sharefailure
      );
    },
    [properties, sharesuccess, sharefailure]
  );

  const Sharerightschanged = useCallback(async (e) => {
    setSharerights(e.currentTarget.value);
  }, []);

  const OnUserchange = useCallback(async (e) => {
    setUsername(e.target.value);
  }, []);

  const OnUserSelect = useCallback(async (val) => {
    setUsername(val);
  }, []);

  return (
    <React.Fragment>
      <button
        title={"Share"}
        className={`
                    ${properties.isshared !== undefined && properties.isshared
            ? "btn "
            : "btn_white_bg"
          }
                    ${shareopen ? "btn-light-active" : ""}
                `}
        onClick={
          properties.isshared !== undefined && properties.isshared
            ? Share
            : Shareorderorder
        }
      >
        <i className="fa fa-share-alt 2"></i>
        {loading ? (
          <PuffLoader css={override} size={100} color={"#123abc"} />
        ) : (
          ""
        )}
      </button>

      {shareopen
        ? share(
          properties,
          users,
          username,
          sharerights,
          shareopen,
          Oncloseshare,
          Onsaveshare,
          OnUserchange,
          OnUserSelect,
          Sharerightschanged,
          Auditservicecall,
          properties.order
        )
        : ""}
    </React.Fragment>
  );
}

function Shareorderforprotocol(properties) {
  let [loading, setLoading] = useState(false);
  let [shareopen, setShareopen] = useState(false);
  let [users, setUsers] = useState([]);
  let [username, setUsername] = useState(properties.toshare);
  let [sharerights, setSharerights] = useState("0");
  const [clickTimer, setClickTimer] = useState(null);
  let [Auditservicecall, setAuditservicecall] = useState({
    AuditUrl: "",
    AuditObject: {},
    AuditPassfunction: undefined,
    AuditFailfunction: undefined,
    AuditCancelfunction: undefined,
  });
  const Usergetsuccess = useCallback(async (response) => {
    setUsers(response);
  }, []);

  const Usergetfaliure = useCallback(async (response) => { }, []);

  const Shareorderorder = useCallback(
    async (e) => {
      setShareopen(true);

      let objunifieduser = {
        usercode: $.session.get("User"),
        username: $.session.get("Username"),
        lssitemaster: { sitecode: $.session.get("Usersite") },
      };

      Servicecalls(
        "" + ELNurl + "/User/GetAllActiveUsers",
        objunifieduser,
        Usergetsuccess,
        Usergetfaliure
      );
    },
    [Usergetsuccess, Usergetfaliure]
  );

  const Oncloseshare = useCallback(async (e) => {
    setShareopen(false);
    setUsername("");
  }, []);

  const sharebysuccess = useCallback(
    async (response) => {
      setLoading(false);
      if (properties.props.Getorderonsharesucess !== undefined) {
        properties.props.Getorderonsharesucess(response);
      }

      properties.props.alert.success(properties.props.t("Orders.Protocolorders.IDS_MSG_PROTOCOLSHARE"));
      setUsername("");
      let passObjDet = {
        objsilentaudit: Silentauditobject(
          "Share",
          "Protocol Orders",
          properties.props.t("Orders.Protocolorders.IDS_AUDIT_PROTOSHARE", {
            screen: orderTypeforprotocol(properties.props.dataItem.protocoltype),
            user1: response.order.shareprotoclordername,
            user2: response.order.sharetousername,
            user3: response.order.sharebyusername,
          })
        ),
      };

      Servicecalls(
        "" + ELNurl + "/AuditTrail/silentandmanualRecordHandler",
        passObjDet,
        (res) => { },
        (res) => { }
      );

      let passobj = {
        protocolordercode: properties.props.dataItem.protocolordercode,
        createby: { usercode: sessionStorage.User },
        stepstartdate: GetutcDate(new Date()),
        action: properties.props.t("Templates.Protocoltemplates.IDS_LBL_PROCESSORDER", {
          batchid: properties.props.dataItem.protoclordername,
          Processed: "Shared",
          user2: sessionStorage.Username
        }),
        viewoption: 2
      }
  
      Servicecalls(
        "" + ELNurl + "/protocol/updatetransactionhistory",
        passobj,
        (res) => { },
        (res) => { }
      );
    },
    [properties]
  );

  const sharebyfailure = useCallback(async (response) => {
    setLoading(false);
  }, []);

  const sharesuccess = useCallback(
    async (response) => {
      setLoading(true);
      setShareopen(false);
      response.sharedon = GetutcDate(new Date());
      Servicecalls(
        "" + ELNurl + "/protocol/Insertshareprotocolorderby",
        response,
        sharebysuccess,
        sharebyfailure
      );
    },
    [sharebysuccess, sharebyfailure]
  );

  const sharefailure = useCallback(async (response) => { }, []);

  const Onsaveshare = useCallback(
    async (e) => {
      if (username.trim() !== "") {
        let userindex = users.findIndex(
          (obj) => obj.unifieduserid === username
        );
        if (userindex !== undefined && userindex > -1) {
          properties.props.dataItem.objLoggeduser = store.getState().Loggeduser
          let shareobj = {
            sharetounifiedid: username,
            sharerights: sharerights,
            sharebyunifiedid: $.session.get("unifieduserid"),
            sharestatus: 1,
            shareitemdetails: JSON.stringify(properties.props.dataItem),
            sharetousername: users[userindex].username,
            objLoggeduser: users[userindex],
            sharebyusername: $.session.get("Username"),
            sharedon: GetutcDate(new Date()),
            sharemodifiedon: GetutcDate(new Date()),
            shareprotocolordercode: properties.props.dataItem.protocolordercode,
            shareprotoclordername: properties.props.dataItem.protoclordername,
            protocoltype: properties.props.dataItem.protocoltype,
            orderflag: properties.props.dataItem.orderflag,
            protocolorders: properties.props.dataItem
          };
          let Audit = AuditTrailValidation(
            "IDS_MDL_ORDERS",
            "IDS_SCN_PROTOCOLORDERS",
            "IDS_TSK_PROTOCOLSHARE"
          );

          if (Audit) {
            shareobj.objmanualaudit = Manualauditobject("Share", "Protocol Orders");
            $("#auditTrailModalsheetview").modal("show");

            setAuditservicecall({
              AuditUrl: "" + ELNurl + "/protocol/Insertshareprotocolorder",
              AuditObject: shareobj,
              AuditPassfunction: sharesuccess,
              AuditFailfunction: sharefailure,
              AuditCancelfunction: sharefailure,
            })

          }
          else {

            Servicecalls(
              "" + ELNurl + "/protocol/Insertshareprotocolorder",
              shareobj,
              sharesuccess,
              sharefailure
            );
          }
        } else {
          properties.props.alert.error(properties.props.t("Header.IDS_LBL_INVALIDID"));
        }
      } else {
        properties.props.alert.error(properties.props.t("Header.IDS_LBL_SELECTUNIQID"));
      }
    },
    [username, users, properties, sharesuccess, sharefailure, sharerights]
  );

  const Share = useCallback(
    async (e) => {
      let orderval = properties.order;
      orderval.sharedon = GetutcDate(new Date());
      orderval.sharestatus = 1;
      orderval.unsharedon = null;

      Servicecalls(
        "" + ELNurl + "/Instrument/Insertshareorder",
        orderval,
        sharesuccess,
        sharefailure
      );
    },
    [properties, sharesuccess, sharefailure]
  );

  const Sharerightschanged = useCallback(async (e) => {
    setSharerights(e.currentTarget.value);
  }, []);

  const OnUserchange = useCallback(async (e) => {
    setUsername(e.target.value);
  }, []);

  const OnUserSelect = useCallback(async (val) => {
    setUsername(val);
  }, []);
  const handleclickevent = (event) => {
    if (clickTimer === null) {
      let rtfunction = properties.isshared !== undefined && properties.isshared
        ? Share
        : Shareorderorder;
      setClickTimer(window.setTimeout(() => {
        rtfunction();
        setClickTimer(null);
      }, 250));
    } else {
      window.clearTimeout(clickTimer);
      setClickTimer(null);
    }
  }
  return (
    <React.Fragment>
      {/* <div className="tdShareButton"> */}
      <button
        title={"Share"}
        className={
          properties.isshared !== undefined && properties.isshared
            ? "btn"
            : "grid-icons"
        }
        onClick={handleclickevent}
      >
        {properties.props?.Screen_No === 2 ? <i className="fas fa-share" title="Share"></i> : <i className="fa fa-share-alt protocol-share"></i>}
        {loading ? (
          <PuffLoader css={override} size={100} color={"#123abc"} />
        ) : (
          ""
        )}
      </button>
      {shareopen
        ? share(
          properties,
          users,
          username,
          sharerights,
          shareopen,
          Oncloseshare,
          Onsaveshare,
          OnUserchange,
          OnUserSelect,
          Sharerightschanged,
          Auditservicecall,
          properties.props.dataItem
        )
        : ""}
      {/* </div> */}
    </React.Fragment>
  );
}

function share(
  properties,
  users,
  username,
  sharerights,
  shareopen,
  Oncloseshare,
  Onsaveshare,
  OnUserchange,
  OnUserSelect,
  Sharerightschanged,
  Auditservicecall,
  file
) {
  return (
    <div>
      <Modal
        isOpen={shareopen}
        backdrop={username ? "static" : true}
        toggle={Oncloseshare}
        className=" modal-md smallPopup"
      >
        <div className="modal-header">
          <h5 className="modal-title" id="staticBackdropLabel">
            {"Share with other users"}
            {/* {properties.props.t("Common.IDS_SHAREWITHUSER", { param: "" })} */}
          </h5>
          <button
            type="button"
            className="btn_white_bg"
            data-dismiss="modal"
            aria-label="Close"
            onClick={Oncloseshare}
          >
            <i className="fas fa-times" aria-hidden="true"></i>
          </button>
        </div>
        <ModalBody>
          <div className="row login-form">
            <div className="col-md-12">
              <div className="form-group cls-share">
                <label htmlFor="inputText" className="form-label ">
                  ELN unique ID
                  {/* {properties.props.t("Common.IDS_UNIQUEID", { param: "" })} */}
                </label>

                <Autocomplete
                  wrapperStyle={{ width: "100%", border: "0px" }}
                  shouldItemRender={(item, value) =>
                    item.unifieduserid !== null && value !== null
                      ? item.unifieduserid
                        .toLowerCase()
                        .indexOf(value.toLowerCase()) > -1
                      : false
                  }
                  getItemValue={(item) => item.unifieduserid}
                  items={users}
                  renderItem={(item, isHighlighted) => (
                    <div
                      key={item.centralisedusercode}
                      className={`cls_shareuser  ${isHighlighted ? "active" : ""
                        }`}
                    >
                      <p>{item.userfullname}</p>
                      <span>{item.unifieduserid}</span>
                    </div>
                  )}
                  value={username}
                  onChange={OnUserchange}
                  onSelect={OnUserSelect}
                />
              </div>
              <div className="top-main-menu d-flex align-items-center justify-content-between pb-1">
                <div className="menu-inline">
                  <ul className="list-inline">
                    <li className="list-inline-item">
                      <input
                        type="radio"
                        name="sharerights"
                        value="0"
                        checked={sharerights === "0" || file?.orderflag?.trim() === "R"}
                        onChange={Sharerightschanged}
                      />
                      <span className="ml-1">
                        {"Read"}
                        {/* {properties.props.t("Common.IDS_READ", { param: "" })} */}
                      </span>
                    </li>
                    <li className="list-inline-item ">
                      <input
                        type="radio"
                        name="sharerights"
                        value="1"
                        checked={sharerights === "1"}
                        onChange={Sharerightschanged}
                        hidden={file?.orderflag?.trim() === "R"}
                      />
                      <span className="ml-1">
                        {file?.orderflag?.trim() !== "R" && "Read/Write"}
                        {/* {properties.props.t("Common.IDS_READWRITE", {
                        param: "",
                      })} */}
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
        <div className="modal-footer">
          <button
            type="button"
            id="idSaveSheetAs"
            className="btn btn-user btn-primary-blue"
            onClick={Onsaveshare}
          >
            {"Share"}
            {/* {properties.props.t("Common.IDS_SHARE", { param: "" })} */}
          </button>
          <button
            type="button"
            className="textBtn btn_white_bg"
            onClick={Oncloseshare}
          >
            {"Close"}
            {/* {properties.props.t("Header.IDS_LBL_CLOSE", { param: "" })} */}
          </button>
        </div>
      </Modal>
      <Audit
        AuditId="auditTrailModalsheetview"
        AuditUrl={Auditservicecall.AuditUrl}
        AuditObject={Auditservicecall.AuditObject}
        AuditPassfunction={Auditservicecall.AuditPassfunction}
        AuditFailfunction={Auditservicecall.AuditFailfunction}
        AuditCancelfunction={Auditcancel}
        alert={properties.protocolorderscreen ? properties.props.alert : properties.alert}
      />
    </div>
  );
}

function Unshareforprotocolorder(properties) {
  let [loading, setLoading] = useState(false);

  const sharetosuccess = useCallback(
    async (response) => {
      if (properties.passbackorder !== undefined) {
        properties.passbackorder(response);
      }

      let passObjDet = {
        objsilentaudit: Silentauditobject(
          "unshare",
          "Protocol Templates",
          properties.t("Orders.Protocolorders.IDS_AUDIT_PROTOUNSHARE", {
            screen: orderTypeforprotocol(properties.dataItem.protocoltype),
            user1: response.shareprotoclordername,
            user2: response.sharetousername,
            user3: response.sharebyusername,
          })
        ),
      };

      Servicecalls(
        "" + ELNurl + "/AuditTrail/silentandmanualRecordHandler",
        passObjDet,
        (res) => { },
        (res) => { }
      );

      properties.alert.success(properties.t("Orders.Protocolorders.IDS_MSG_PROTOCOLUNSHARE"));
    },
    [properties]
  );

  const sharetofailure = useCallback(async (response) => { }, []);

  const sharebysuccess = useCallback(
    async (response) => {
      setLoading(false);
      response.unsharedon = GetutcDate(new Date());
      Servicecalls(
        "" + ELNurl + "/protocol/Unshareprotocolorderto",
        response,
        sharetosuccess,
        sharetofailure
      );
    },
    [sharetosuccess, sharetofailure]
  );

  const sharebyfailure = useCallback(async (response) => {
    setLoading(false);
  }, []);

  // const Unshareorderorder = useCallback(
  //   async (e) => {
  //     setLoading(true);
  //     let orderval = properties.dataItem;
  //     orderval.unsharedon = GetutcDate(new Date());

  //     Servicecalls(
  //       "" + ELNurl + "/protocol/Unshareprotocolorderby",
  //       orderval,
  //       sharebysuccess,
  //       sharebyfailure
  //     );
  //   },
  //   [properties, sharebysuccess, sharebyfailure]
  // );

  const Unshareorderorder = (e) => {
    setLoading(true);
    let orderval = properties.dataItem;
    orderval.unsharedon = GetutcDate(new Date());
    if (orderval.protocolorders !== undefined && orderval.protocolorders.sharebyme !== undefined) {
      delete orderval.sharebyme;
    }
    Servicecalls(
      "" + ELNurl + "/protocol/Unshareprotocolorderby",
      orderval,
      sharebysuccess,
      sharebyfailure
    );
  }

  return (
    <td>
      <button title={"Unshare"} className="btn " onClick={Unshareorderorder}>
        <span className="fa-stack fa-sm">
          <i className="fa fa-share-alt fa-stack-1x"></i>
          <i className="fa fa-ban fa-stack-2x "></i>
        </span>
        {loading ? (
          <PuffLoader css={override} size={100} color={"#123abc"} />
        ) : (
          ""
        )}
      </button>
    </td>
  );
}

function ShareforprotocolTemplate(properties) {
  let [loading, setLoading] = useState(false);
  let [shareopen, setShareopen] = useState(false);
  let [users, setUsers] = useState([]);
  let [username, setUsername] = useState(properties.toshare);
  let [sharerights, setSharerights] = useState("0");
  let [Auditservicecall, setAuditservicecall] = useState({
    AuditUrl: "",
    AuditObject: {},
    AuditPassfunction: undefined,
    AuditFailfunction: undefined,
    AuditCancelfunction: undefined,
  });
  const Usergetsuccess = useCallback(async (response) => {
    setUsers(response);
  }, []);

  const Usergetfaliure = useCallback(async (response) => { }, []);

  const Shareorderorder = useCallback(
    async (e) => {
      setShareopen(true);

      let objunifieduser = {
        usercode: $.session.get("User"),
        username: $.session.get("Username"),
        lssitemaster: { sitecode: $.session.get("Usersite") },
      };

      Servicecalls(
        "" + ELNurl + "/User/GetAllActiveUsers",
        objunifieduser,
        Usergetsuccess,
        Usergetfaliure
      );
    },
    [Usergetsuccess, Usergetfaliure]
  );

  const Oncloseshare = useCallback(async (e) => {
    setShareopen(false);
    setUsername("");
  }, []);

  const sharebysuccess = useCallback(
    async (response) => {
      setLoading(false);
      if (properties.passbackorder !== undefined) {
        properties.passbackorder(response);
      }
      setUsername("");
      properties.alert.success(properties.t("Templates.Protocoltemplates.IDS_MSG_PROTOCOLSHARE"));

      let passObjDet = {
        objsilentaudit: Silentauditobject(
          "Share",
          "Protocol Templates",
          properties.t("Templates.Protocoltemplates.IDS_AUDIT_PROTOCOLTEMPSHARE", {
            user1: JSON.parse(response.templateshared.shareitemdetails)
              .protocolmastername,
            user2: response.templateshared.sharetousername,
            user3: response.templateshared.sharebyusername,
          })
        ),
      };

      Servicecalls(
        "" + ELNurl + "/AuditTrail/silentandmanualRecordHandler",
        passObjDet,
        (res) => { },
        (res) => { }
      );
    },
    [properties]
  );

  const sharebyfailure = useCallback(async (response) => {
    setLoading(false);
  }, []);

  const sharesuccess = useCallback(
    async (response) => {
      setLoading(true);
      setShareopen(false);
      response.sharedon = GetutcDate(new Date());
      Servicecalls(
        "" + ELNurl + "/protocol/Insertshareprotocolby",
        response,
        sharebysuccess,
        sharebyfailure
      );
    },
    [sharebysuccess, sharebyfailure]
  );

  const sharefailure = useCallback(async (response) => { }, []);

  const Onsaveshare = useCallback(
    async (e) => {
      if (username.trim() !== "") {
        let userindex = users.findIndex(
          (obj) => obj.unifieduserid === username
        );
        if (userindex !== undefined && userindex > -1) {
          let shareobj = {
            sharetounifiedid: username,
            sharerights: sharerights,
            sharebyunifiedid: $.session.get("unifieduserid"),
            sharestatus: 1,
            shareitemdetails: JSON.stringify(properties.dataItem),
            sharetousername: users[userindex].username,
            sharebyusername: $.session.get("Username"),
            sharedon: GetutcDate(new Date()),
            sharemodifiedon: GetutcDate(new Date()),
            shareprotocolcode: properties.dataItem.protocolmastercode,
            shareprotocolname: properties.dataItem.protocolmastername,
            objLoggeduser: store.getState().Loggeduser,

            sharetousercode: { usercode: users[userindex].usercode }
            // protocoltype: properties.dataItem.protocoltype,
          };
          let Audit = AuditTrailValidation(
            "IDS_MDL_TEMPLATES",
            "IDS_SCN_PROTOCOLTEMP",
            "IDS_TSK_SHAREWITHTEAM"
          );

          if (Audit) {
            shareobj.objmanualaudit = Manualauditobject("Share", "Protocol Templates");
            $("#auditTrailModalsheetview").modal("show");

            setAuditservicecall({
              AuditUrl: "" + ELNurl + "/protocol/Insertshareprotocol",
              AuditObject: shareobj,
              AuditPassfunction: sharesuccess,
              AuditFailfunction: sharefailure,
              AuditCancelfunction: sharefailure,
            })

          }
          else {

            Servicecalls(
              "" + ELNurl + "/protocol/Insertshareprotocol",
              shareobj,
              sharesuccess,
              sharefailure
            );
          }
        } else {
          properties.props.alert.error(properties.props.t("Header.IDS_LBL_INVALIDID"));
        }
      } else {
        properties.props.alert.error(properties.props.t("Header.IDS_LBL_SELECTUNIQID"));
      }
    },
    [username, users, properties, sharesuccess, sharefailure, sharerights]
  );

  const Share = useCallback(
    async (e) => {
      let orderval = properties.order;
      orderval.sharedon = GetutcDate(new Date());
      orderval.sharestatus = 1;
      orderval.unsharedon = null;

      Servicecalls(
        "" + ELNurl + "/Instrument/Insertshareorder",
        orderval,
        sharesuccess,
        sharefailure
      );
    },
    [properties, sharesuccess, sharefailure]
  );

  const Sharerightschanged = useCallback(async (e) => {
    setSharerights(e.currentTarget.value);
  }, []);

  const OnUserchange = useCallback(async (e) => {
    setUsername(e.target.value);
  }, []);

  const OnUserSelect = useCallback(async (val) => {
    setUsername(val);
  }, []);
  let iskeyvalueavil =
    properties !== undefined &&
      properties.dataItem !== undefined &&
      properties.dataItem[properties.keyfield] !== undefined
      ? true
      : false;
  let retrunvalue = <td></td>;
  if (iskeyvalueavil) {
    retrunvalue = (
      <div>
        <button
          title={"Share"}
          className={
            properties.isshared !== undefined && properties.isshared
              ? "btn"
              : "grid-icons"
          }
          onClick={
            properties.isshared !== undefined && properties.isshared
              ? Share
              : Shareorderorder
          }
        >

          {properties.Screen_No === 1 ? <i className="fas fa-share" title="Share"></i> : <i className="fa fa-share-alt 3"></i>}

          {loading ? (
            <PuffLoader css={override} size={100} color={"#123abc"} />
          ) : (
            ""
          )}
        </button>
        {shareopen
          ? share(
            properties,
            users,
            username,
            sharerights,
            shareopen,
            Oncloseshare,
            Onsaveshare,
            OnUserchange,
            OnUserSelect,
            Sharerightschanged,
            Auditservicecall,
            properties.dataItem
          )
          : ""}
      </div>
    );
  }
  return retrunvalue;
}

function UnshareforprotocolTemplate(properties) {
  let [loading, setLoading] = useState(false);

  const sharetosuccess = useCallback(
    async (response) => {
      if (properties.passbackorder !== undefined) {
        properties.passbackorder(response);
      }

      properties.alert.success(properties.t("Templates.Protocoltemplates.IDS_MSG_PROTOCOLUNSHARE"));

      let passObjDet = {
        objsilentaudit: Silentauditobject(
          "unshare",
          "Protocol Templates",
          properties.t("Templates.Protocoltemplates.IDS_AUDIT_PROTOCOLUNSHARE", {
            user1: JSON.parse(response.shareitemdetails).protocolmastername,
            user2: response.sharetousername,
            user3: response.sharebyusername,
          })
        ),
      };

      Servicecalls(
        "" + ELNurl + "/AuditTrail/silentandmanualRecordHandler",
        passObjDet,
        (res) => { },
        (res) => { }
      );
    },
    [properties]
  );

  const sharetofailure = useCallback(async (response) => { }, []);

  const sharebysuccess = useCallback(
    async (response) => {
      setLoading(false);
      response.unsharedon = GetutcDate(new Date());
      Servicecalls(
        "" + ELNurl + "/protocol/Unshareorderto",
        response,
        sharetosuccess,
        sharetofailure
      );
    },
    [sharetosuccess, sharetofailure]
  );

  const sharebyfailure = useCallback(async (response) => {
    setLoading(false);
  }, []);

  const Unshareorderorder = useCallback(
    async (e) => {
      setLoading(true);
      let orderval = properties.dataItem;
      orderval.unsharedon = GetutcDate(new Date());

      Servicecalls(
        "" + ELNurl + "/protocol/Unshareorderby",
        orderval,
        sharebysuccess,
        sharebyfailure
      );
    },
    [properties, sharebysuccess, sharebyfailure]
  );

  return (
    <div className="unsharebtn">
      <button title={"Unshare"} className="btn " onClick={Unshareorderorder}>
        <span className="fa-stack fa-sm" title="Unshare">
          <i className="fa fa-share-alt fa-stack-1x"></i>
          <i className="fa fa-ban fa-stack-2x "></i>
        </span>
        {loading ? (
          <PuffLoader css={override} size={100} color={"#123abc"} />
        ) : (
          ""
        )}
      </button>
    </div>
  );
}

function ShareforsheetTemplate(properties) {
  let [loading, setLoading] = useState(false);
  let [shareopen, setShareopen] = useState(false);
  let [users, setUsers] = useState([]);
  let [username, setUsername] = useState(properties.toshare);
  let [sharerights, setSharerights] = useState("0");
  let [Auditservicecall, setAuditservicecall] = useState({
    AuditUrl: "",
    AuditObject: {},
    AuditPassfunction: undefined,
    AuditFailfunction: undefined,
    AuditCancelfunction: undefined,
  });
  const Usergetsuccess = useCallback(async (response) => {
    setUsers(response);
    if (response.length === 0) {
      properties.alert.error(properties.t("Common.IDS_MSG_NOUSERSACT", { param: "", }));
    }
  }, []);

  const Usergetfaliure = useCallback(async (response) => { }, []);

  const Shareorderorder = useCallback(
    async (e) => {
      setShareopen(true);

      let objunifieduser = {
        usercode: $.session.get("User"),
        username: $.session.get("Username"),
        lssitemaster: { sitecode: $.session.get("Usersite") },
      };

      Servicecalls(
        "" + ELNurl + "/User/GetAllActiveUsers",
        objunifieduser,
        Usergetsuccess,
        Usergetfaliure
      );
    },
    [Usergetsuccess, Usergetfaliure]
  );

  const Oncloseshare = useCallback(async (e) => {
    setShareopen(false);
    setUsername("");
  }, []);

  const sharebysuccess = useCallback(
    async (response) => {
      setLoading(false);
      if (properties.passbackorder !== undefined) {
        properties.passbackorder(response);
      }
      properties.alert.success(properties.props.t("Templates.Sheettemplates.IDS_MSG_SHEETSHARE"));

    },
    [properties]
  );

  const sharebyfailure = useCallback(async (response) => {
    setLoading(false);
  }, []);

  const sharesuccess = useCallback(
    async (response) => {
      setLoading(true);
      setShareopen(false);
      response.sharedon = GetutcDate(new Date());
      Servicecalls(
        "" + ELNurl + "/File/Insertsharefileby",
        response,
        sharebysuccess,
        sharebyfailure
      );
      setTimeout(() => {
        let passObjDet = {
          objsilentaudit: Silentauditobject(
            "Share",
            "Sheet Templates",
            properties.t("Templates.Sheettemplates.IDS_AUDIT_SHEETSHARE", {
              user1: response.sharefilename,
              user2: response.sharetousername,
              user3: response.sharebyusername,
            })
          ),
        };
        setUsername("");
        Servicecalls(
          "" + ELNurl + "/AuditTrail/silentandmanualRecordHandler",
          passObjDet,
          (res) => { },
          (res) => { }
        );
      }, 1000);
    },
    [properties, sharebysuccess, sharebyfailure]
  );

  const sharefailure = useCallback(async (response) => { }, []);

  const Onsaveshare = useCallback(
    async (e) => {
      if (username.trim() !== "") {
        let userindex = users.findIndex(
          (obj) => obj.unifieduserid === username
        );
        if (userindex !== undefined && userindex > -1) {
          let shareobj = {
            sharetounifiedid: username,
            sharerights: sharerights,
            sharebyunifiedid: $.session.get("unifieduserid"),
            sharestatus: 1,
            shareitemdetails: JSON.stringify(properties.dataItem),
            sharetousername: users[userindex].username,
            sharebyusername: $.session.get("Username"),
            sharedon: GetutcDate(new Date()),
            sharemodifiedon: GetutcDate(new Date()),
            sharefilecode: properties.dataItem.filecode,
            sharefilename: properties.dataItem.filenameuser,
            objLoggeduser: store.getState().Loggeduser,
            sharetousercode: { usercode: users[userindex].usercode }
          }
          let Audit = AuditTrailValidation(
            "IDS_MDL_TEMPLATES",
            "IDS_SCN_SHEETTEMPLATES",
            "IDS_TSK_SHEETTEMPSHARE"
          );

          if (Audit) {
            shareobj.objmanualaudit = Manualauditobject("Share", "Sheet Templates");
            $("#auditTrailModalsheetview").modal("show");

            setAuditservicecall({
              AuditUrl: "" + ELNurl + "/File/Insertsharefile",
              AuditObject: shareobj,
              AuditPassfunction: sharesuccess,
              AuditFailfunction: sharefailure,
              AuditCancelfunction: sharefailure,
            })

          }
          else {

            Servicecalls(
              "" + ELNurl + "/File/Insertsharefile",
              shareobj,
              sharesuccess,
              sharefailure
            );
          }
          // setUsername(users[userindex].username);
        } else {
          properties.props.alert.error(properties.props.t("Header.IDS_LBL_INVALIDID"));
        }
      } else {
        properties.props.alert.error(properties.props.t("Header.IDS_LBL_SELECTUNIQID"));
      }
    },
    [username, users, properties, sharesuccess, sharefailure, sharerights]
  );

  const Share = useCallback(
    async (e) => {
      let orderval = properties.order;
      orderval.sharedon = GetutcDate(new Date());
      orderval.sharestatus = 1;
      orderval.unsharedon = null;

      Servicecalls(
        "" + ELNurl + "/Instrument/Insertshareorder",
        orderval,
        sharesuccess,
        sharefailure
      );
    },
    [properties, sharesuccess, sharefailure]
  );

  const Sharerightschanged = useCallback(async (e) => {
    setSharerights(e.currentTarget.value);
  }, []);

  const OnUserchange = useCallback(async (e) => {
    setUsername(e.target.value);
  }, []);

  const OnUserSelect = useCallback(async (val) => {
    setUsername(val);
  }, []);

  return (
    <div>
      <button
        title={"Share"}
        className={
          properties.isshared !== undefined && properties.isshared
            ? "btn"
            : "grid-icons"
        }
        onClick={
          properties.isshared !== undefined && properties.isshared
            ? Share
            : Shareorderorder
        }
      >
        <i className="fa fa-share-alt 4"></i>
        {loading ? (
          <PuffLoader css={override} size={100} color={"#123abc"} />
        ) : (
          ""
        )}
      </button>
      {shareopen
        ? share(
          properties,
          users,
          username,
          sharerights,
          shareopen,
          Oncloseshare,
          Onsaveshare,
          OnUserchange,
          OnUserSelect,
          Sharerightschanged,
          Auditservicecall,
          properties.dataItem
        )
        : ""}
    </div>
  );
}

function UnshareforsheetTemplate(properties) {
  let [loading, setLoading] = useState(false);

  const sharetosuccess = useCallback(
    async (response) => {
      if (properties.Getorderonunshare !== undefined) {
        properties.Getorderonunshare(response);
      }
      properties.alert.success(properties.props.t("Templates.Sheettemplates.IDS_MSG_SHEETUNSHARE"));

      let passObjDet = {
        objsilentaudit: Silentauditobject(
          "unshare",
          "Sheet Templates",
          properties.t("Templates.Sheettemplates.IDS_AUDIT_SHEETUNSHARE", {
            user1: response.sharefilename,
            user2: response.sharetousername,
            user3: response.sharebyusername,
          })
        ),
      };

      Servicecalls(
        "" + ELNurl + "/AuditTrail/silentandmanualRecordHandler",
        passObjDet,
        (res) => { },
        (res) => { }
      );
    },
    [properties]
  );

  const sharetofailure = useCallback(async (response) => { }, []);

  const sharebysuccess = useCallback(
    async (response) => {
      setLoading(false);
      response.unsharedon = GetutcDate(new Date());
      Servicecalls(
        "" + ELNurl + "/File/Unsharefileto",
        response,
        sharetosuccess,
        sharetofailure
      );
    },
    [sharetosuccess, sharetofailure]
  );

  const sharebyfailure = useCallback(async (response) => {
    setLoading(false);
  }, []);

  const Unshareorderorder = useCallback(
    async (e) => {
      setLoading(true);
      let orderval = properties.dataItem;
      orderval.unsharedon = GetutcDate(new Date());

      Servicecalls(
        "" + ELNurl + "/File/Unsharefileby",
        orderval,
        sharebysuccess,
        sharebyfailure
      );
    },
    [properties, sharebysuccess, sharebyfailure]
  );

  return (
    <button title={"Unshare"} className="btn " onClick={Unshareorderorder}>
      <span className="fa-stack fa-sm">
        <i className="fa fa-share-alt fa-stack-1x"></i>
        <i className="fa fa-ban fa-stack-2x "></i>
      </span>
      {loading ? (
        <PuffLoader css={override} size={100} color={"#123abc"} />
      ) : (
        ""
      )}
    </button>
  );
}

function Unsharelistview(props) {
  let [isOpen, setIsOpen] = useState(props.isOpen);

  const GetUnshareOrder = (item) => {
    props.onorderunshare(item);
  }

  let Columns = [
    {
      header: "Share To",
      datatype: "sharetousername",
      rowselector: false,
      visible: true,
      filterable: false,
      className: "clsModulename",
      width: "140px",
    },
    {
      header: "Shared On",
      datatype: "sharedonDate",
      rowselector: false,
      visible: true,
      filterable: true,
      className: "clsModulename",
      width: "200px",
    },
    {
      header: "open",
      datatype: "",
      rowselector: false,
      celltype: props.screen === "Sheet" ? "orderunsharedbyme" : "protocolorderunshareaction",
      visible: true,
      filterable: false,
      className: "clsSelect",
      width: "200px",
      passbackorder: GetUnshareOrder,
    }
  ]

  const Oncloseunshare = (e) => {
    setIsOpen(false);
    props.closeunsharelist();
  }
  let orderid = props.screen === "Sheet" ? props.shareitem !== undefined ? props.shareitem.batchid : "" : props.screen === "Protocol" ? props.shareitem !== undefined ? props.shareitem.protoclordername : "" : ""
  return (<Modal isOpen={isOpen}>
    <ModalHeader>
      <h5 className="modal-title" id="staticBackdropLabel">
        {/* {"Unshare Order " + props.shareitem.batchid} */}
        {"Unshare Order " + orderid}
      </h5>
      {/* <button
        type="button"
        className="close"
        data-dismiss="modal"
        aria-label="Close"
        onClick={Oncloseunshare}
      >
        <span aria-hidden="true">&times;</span>
      </button>*/}
    </ModalHeader>
    <ModalBody>
      <Suspense fallback={<div></div>}>
        <KendoGridView
          primaryKey={props.screen === "Sheet" ? "sharedbycode" : "sharedbytoprotocolordercode"}
          source={props.shareitem.sharebyme}
          Columns={Columns}
        />

      </Suspense>


    </ModalBody>
    <ModalFooter>
      <button
        type="button"
        className="textBtn btn_white_bg"
        onClick={Oncloseunshare}
      >
        {"Close"}
      </button>
    </ModalFooter>
  </Modal>

  )
}

class Downloadfile extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      loader: <span></span>,

    };

    this.downloadfiles = this.downloadfiles.bind(this);
  }

  downloadfiles = (event) => {

    fetch(this.props.url + "/" + this.props.file.uuid, {
      method: "GET",
      headers: new Headers({
        Authorization: $.session.get("Token"),
        "X-TenantID": $.session.get("tenantid"),
        Accept: "application/json",
        "Content-Type": "application/json",
      }),
    }).then((response) => {
      const filename = this.props.file.filename
      response.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = filename;
        a.click();
        this.setState({ loader: <span></span> });
      });
    });
    this.setState({
      loader: <Suspense fallback={<div></div>}> <PageLoader /></Suspense>
    });

  }

  render() {
    return (<button
      className="btn_white_bg"
      onClick={this.downloadfiles}
    >
      <i
        className="fas fa-cloud-download-alt"
      ></i>
      {this.state.loader}
    </button>);
  }
}

function Deletefile(props) {
  const ondeletefile = (e) => {
    props.ondeletefile(props.file);
  }

  return (<button
    className="btn_white_bg"
    onClick={ondeletefile}
  >
    <i
      className="fas fa-trash-alt"
    ></i>
  </button>)
}

function Viewfile(props) {
  return (<NavLink
    className="btn_white_bg ml-1"
    target="_blank"
    onClick={() => {
      let file = props.file;
      file.fileurl = props.url;
      file.tenantid = $.session.get("tenantid");
      file.token = $.session.get("Token");
      localStorage.setItem("fileobj", JSON.stringify(file));
      // sessionStorage.removeItem("__lock");
    }}
    to={{
      pathname: "/fileviewer",
      params: props.file,
    }}
  >
    <i className="fas fa-eye"></i>
  </NavLink>)
}

export {
  Unshareorder,
  Shareorder,
  Shareorderforprotocol,
  ShareforprotocolTemplate,
  UnshareforprotocolTemplate,
  Unshareforprotocolorder,
  ShareforsheetTemplate,
  UnshareforsheetTemplate,
  Sharesheetorderinsidescreen,
  Unshareorderorderinsidescreen,
  Unsharelistview,
  Downloadfile,
  Deletefile,
  Viewfile
};
