import React, { Suspense } from "react";
import { withTranslation } from "react-multi-lang";
import { Modal, ModalBody, Popover, PopoverBody } from "reactstrap";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import { Collapse, CardBody, Card, CardTitle } from "reactstrap";
import Userdetail from "./../custom_modules/general/Userdetail.jsx";
import store from "../redux_store/store/index.js";
import {
  convertUTCDateToLocalDate,
  Servicecalls,
  Silentauditobject,
  convertdoubledigitdate,
  convertdoubledigittime,
  orderTypeforprotocol,
  orderType, ProtocolHistory, toDateFilter
} from "./../custom_functions/Functions.jsx";
import { isMultitenant } from "./../custom_functions/Constance.jsx";
// import { ProtocolHistory } from "../custom_modules/protocolorders/ProtocolFunctions.jsx";

class CustomDetailviewCell extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      versionexpanded: true,
      transactionexpanded: true,
      workflowexpanded: false,
      versionexpandgetdata: false,
      activeTab: 1,
    };
    this.month = this.props.details.month;
    this.ELNurl = document.getElementById("ELNname").value;

    this.imageurl = "";
    if (isMultitenant === 1) {
      this.imageurl = "/User/Cloudprofile/";
    } else {
      this.imageurl = "/User/profile/";
    }
  }
  toggle = (targetName) => {
    if (!this.state[targetName]) {
      this.setState({
        ...this.state,
        [targetName]: {
          tooltipOpen: true,
        },
      });
    } else {
      this.setState({
        ...this.state,
        [targetName]: {
          tooltipOpen: !this.state[targetName].tooltipOpen,
        },
      });
    }

    this.setState({
      transactionexpanded: false,
      workflowexpanded: false,
    });
  };

  isToolTipOpen = (targetName) => {
    return this.state[targetName] ? this.state[targetName].tooltipOpen : false;
  };

  formattransactiondetails() {
    var detail = [];
    if (this.props.dataItem !== undefined) {
      let d = null;
      let day = null;
      let dayname = null;
      let time = null;
      if (
        this.props.dataItem.modifiedlist !== undefined &&
        this.props.dataItem.modifiedlist !== null
      ) {
        for (
          let index = 0;
          index < this.props.dataItem.modifiedlist.length;
          index++
        ) {
          if (
            this.props.dataItem.modifiedby !== undefined &&
            this.props.dataItem.modifiedby !== null
          ) {
            // d = new Date(
            //   this.props.dataItem.modifiedlist[index].sheetmodifiedDate
            // );

            let localdate = convertUTCDateToLocalDate(
              new Date(
                this.props.dataItem.modifiedlist[index].sheetmodifiedDate
              )
            );

            d = new Date(localdate);

            day = d.getDate();
            dayname = this.month[d.getMonth()];
            // time = d.getHours() + ":" + d.getMinutes() + ":" + d.getSeconds();
            day = convertdoubledigitdate(day);
            time = convertdoubledigittime(
              d.getHours(),
              d.getMinutes(),
              d.getSeconds()
            );
            let year = d.getFullYear();
            // let gmt =d.toString();
            // gmt=gmt.slice(25,34);

            let imgurl = this.ELNurl + this.imageurl + "-1";
            if (
              this.props.dataItem.modifiedlist[index].modifiedby !== null &&
              this.props.dataItem.modifiedlist[index].modifiedby !== undefined
            ) {
              imgurl =
                this.ELNurl +
                this.imageurl +
                this.props.dataItem.modifiedlist[index].modifiedby.usercode;
            }
            detail.push(
              <li
                key={this.props.dataItem.filecode + "TransDetails_" + index}
                className="list-group-item"
                aria-disabled="true"
              >
                <div className="row no-gutters align-items-center">
                  <div className="col-2">
                    <div className="calender-list small">
                      <span className="d-flex eln-stamp">{dayname}</span>
                      <span className="d-flex eln-date">{day}</span>
                      <span className="d-flex eln-stamp">{year}</span>
                      <span className="d-flex eln-stamp ">{time}</span>
                      {/* <span className="d-flex eln-stamp">{gmt}</span> */}
                    </div>
                  </div>
                  <div className="col-10 mnuContainer2  userDetails">
                    <Userdetail
                      index={"Trans2-" + index}
                      imgurl={imgurl}
                      user={this.props.dataItem.modifiedlist[index].modifiedby}
                    />
                    <p>
                      {this.props.dataItem.modifiedlist[index].sheetcomment}
                    </p>
                  </div>
                </div>
              </li>
            );
          }
        }
      }
      // if (
      //   this.props.dataItem.lsfileversion !== undefined &&
      //   this.props.dataItem.lsfileversion !== null &&
      //   this.props.dataItem.lsfileversion.length > 1
      // ) {
      //   for (
      //     let index = 0;
      //     index < this.props.dataItem.lsfileversion.length;
      //     index++
      //   ) {
      //     if (
      //       this.props.dataItem.lsfileversion[index].modifiedby !== undefined &&
      //       this.props.dataItem.lsfileversion[index].modifiedby !== null
      //     ) {
      //       // d = new Date(this.props.dataItem.lsfileversion[index].modifieddate);
      //       let localdate = convertUTCDateToLocalDate(
      //         new Date(this.props.dataItem.lsfileversion[index].modifieddate)
      //       );

      //       d = new Date(localdate);
      //       day = d.getDate();
      //       dayname = this.month[d.getMonth()];
      //       // time = d.getHours() + ":" + d.getMinutes() + ":" + d.getSeconds();
      //       day = convertdoubledigitdate(day);
      //       time = convertdoubledigittime(
      //         d.getHours(),
      //         d.getMinutes(),
      //         d.getSeconds()
      //       );
      //       let year = d.getFullYear();
      //       // let gmt =d.toString();
      //       // gmt=gmt.slice(25,34);
      //       let imgurl = this.ELNurl + this.imageurl + "-1";
      //       if (
      //         this.props.dataItem.lsfileversion[index].modifiedby !== null &&
      //         this.props.dataItem.lsfileversion[index].modifiedby !== undefined
      //       ) {
      //         imgurl =
      //           this.ELNurl +
      //           this.imageurl +
      //           this.props.dataItem.lsfileversion[index].modifiedby.usercode;
      //       }
      //       detail.push(
      //         <li
      //           key={this.props.dataItem.filecode + "Trans_" + index}
      //           className="list-group-item"
      //           aria-disabled="true"
      //         >
      //           <div className="row no-gutters align-items-center">
      //             <div className="col-2">
      //               <div className="calender-list small">
      //                 <span className="d-flex eln-stamp">{dayname}</span>
      //                 <span className="d-flex eln-date">{day}</span>
      //                 <span className="d-flex eln-stamp">{year}</span>
      //                 <span className="d-flex eln-stamp">{time}</span>
      //                 {/* <span className="d-flex eln-stamp">{gmt}</span> */}
      //               </div>
      //             </div>
      //             <div className="col-10 mnuContainer2  userDetails">
      //               <Userdetail
      //                 index={"Trans-" + index}
      //                 imgurl={imgurl}
      //                 user={this.props.dataItem.lsfileversion[index].modifiedby}
      //               />
      //               <p>
      //                 {this.props.t("Orders.Protocolorders.IDS_LBL_MODIFIEDF", {
      //                   user2:
      //                     this.props.dataItem.lsfileversion[index].modifiedby
      //                       .username,
      //                   user1:
      //                     this.props.dataItem.lsfileversion[index].versionname,
      //                 })}
      //               </p>
      //             </div>
      //           </div>
      //         </li>
      //       );
      //     }
      //   }
      // }

      let localdate = convertUTCDateToLocalDate(
        new Date(this.props.dataItem.createdate)
      );

      d = new Date(localdate);

      // d = new Date(this.props.dataItem.createdate);
      day = d.getDate();
      dayname = this.month[d.getMonth()];
      // time = d.getHours() + ":" + d.getMinutes() + ":" + d.getSeconds();
      day = convertdoubledigitdate(day);
      time = convertdoubledigittime(
        d.getHours(),
        d.getMinutes(),
        d.getSeconds()
      );
      let year = d.getFullYear();
      // let gmt =d.toString();
      // gmt=gmt.slice(25,34);
      let imgurl = this.ELNurl + this.imageurl + "-1";
      if (
        this.props.dataItem.createby !== null &&
        this.props.dataItem.createby !== undefined
      ) {
        imgurl =
          this.ELNurl + this.imageurl + this.props.dataItem.createby.usercode;
      }
      if (
        this.props.dataItem !== undefined &&
        this.props.dataItem[this.props.keyfield] !== undefined
      ) {
        detail.push(
          <li
            key={this.props.dataItem.filecode + "Trans_-2"}
            className="list-group-item"
            aria-disabled="true"
          >
            <div className="row no-gutters align-items-center">
              <div className="col-2">
                <div className="calender-list small">
                  <span className="d-flex eln-stamp">{dayname}</span>
                  <span className="d-flex eln-date">{day}</span>
                  <span className="d-flex eln-stamp">{year}</span>
                  <span className="d-flex eln-stamp ">{time}</span>
                  {/* <span className="d-flex eln-stamp">{gmt}</span> */}
                </div>
              </div>
              <div className="col-10 mnuContainer2  userDetails">
                <Userdetail
                  index={"Trans3--2"}
                  imgurl={imgurl}
                  user={this.props.dataItem.createby}
                />
                <p>
                  {this.props.t("Templates.Sheettemplates.IDS_LBL_CREATEDF", {
                    user1: this.props.dataItem.createby.username,
                    user2: this.props.dataItem.filenameuser,
                  })}
                </p>
              </div>
            </div>
          </li>
        );
      }
    }

    let detailsvalue = (
      <ul className="list-group list-group-flush">{detail}</ul>
    );
    return detailsvalue;
  }

  // componentWillReceiveProps(nextProps) {
  //   if (
  //     nextProps.dataItem.lsfileversion === undefined ||
  //     nextProps.dataItem.lsfileversion === null
  //   ) {
  //     if (
  //       nextProps.dataItem.filecode !== undefined &&
  //       nextProps.dataItem.filecode !== null
  //     ) {
  //       let passObjDetFile = { filecode: nextProps.dataItem.filecode };
  //       Servicecalls(
  //         "" + this.ELNurl + "/File/Getfileversions",
  //         passObjDetFile,
  //         (res) => {

  //           if (res !== undefined && res !== null) {
  //             nextProps.dataItem.lsfileversion = res;
  //           }
  //         },
  //         (res) => { }
  //       );
  //     }
  //   }

  //   if (
  //     nextProps.dataItem.lssheetworkflowhistory === undefined ||
  //     nextProps.dataItem.lssheetworkflowhistory === null
  //   ) {
  //     if (
  //       nextProps.dataItem.filecode !== undefined &&
  //       nextProps.dataItem.filecode !== null
  //     ) {
  //       let passObjDetFile = { filecode: nextProps.dataItem.filecode };

  //       Servicecalls(
  //         "" + this.ELNurl + "/File/Getfileworkflowhistory",
  //         passObjDetFile,
  //         (res) => {
  //           if (res !== undefined && res !== null) {
  //             nextProps.dataItem.lssheetworkflowhistory = res;
  //           }
  //         },
  //         (res) => { }
  //       );
  //     }
  //   }
  // }

  // componentDidMount() {
  //   if (
  //     this.props.dataItem.lsfileversion === undefined ||
  //     this.props.dataItem.lsfileversion === null
  //   ) {
  //     if (
  //       this.props.dataItem.filecode !== undefined &&
  //       this.props.dataItem.filecode !== null
  //     ) {
  //       let passObjDetFile = { filecode: this.props.dataItem.filecode };
  //       Servicecalls(
  //         "" + this.ELNurl + "/File/Getfileversions",
  //         passObjDetFile,
  //         (res) => {

  //           if (res !== undefined && res !== null) {
  //             this.props.dataItem.lsfileversion = res;
  //           }
  //         },
  //         (res) => { }
  //       );
  //     }
  //   }

  //   if (
  //     this.props.dataItem.lssheetworkflowhistory === undefined ||
  //     this.props.dataItem.lssheetworkflowhistory === null
  //   ) {
  //     if (
  //       this.props.dataItem.filecode !== undefined &&
  //       this.props.dataItem.filecode !== null
  //     ) {
  //       let passObjDetFile = { filecode: this.props.dataItem.filecode };

  //       Servicecalls(
  //         "" + this.ELNurl + "/File/Getfileworkflowhistory",
  //         passObjDetFile,
  //         (res) => {
  //           if (res !== undefined && res !== null) {
  //             this.props.dataItem.lssheetworkflowhistory = res;
  //           }
  //         },
  //         (res) => { }
  //       );
  //     }
  //   }
  // }
  formatversiondetails() {
    let passObjDet = {};

    if (
      this.props.dataItem.lsfileversion === undefined ||
      this.props.dataItem.lsfileversion === null
    ) {
      if (
        this.props.dataItem.filecode !== undefined &&
        this.props.dataItem.filecode !== null
      ) {
        let passObjDetFile = { filecode: this.props.dataItem.filecode };
        Servicecalls(
          "" + this.ELNurl + "/File/Getfileversions",
          passObjDetFile,
          (res) => {
            if (res !== undefined && res !== null) {
              this.props.dataItem.lsfileversion = res;
            }
          },
          (res) => { }
        );
      }
    }

    if (
      this.props.dataItem.lssheetworkflowhistory === undefined ||
      this.props.dataItem.lssheetworkflowhistory === null
    ) {
      if (
        this.props.dataItem.filecode !== undefined &&
        this.props.dataItem.filecode !== null
      ) {
        let passObjDetFile = { filecode: this.props.dataItem.filecode };

        Servicecalls(
          "" + this.ELNurl + "/File/Getfileworkflowhistory",
          passObjDetFile,
          (res) => {
            if (res !== undefined && res !== null) {
              this.props.dataItem.lssheetworkflowhistory = res;
            }
          },
          (res) => { }
        );
      }
    }

    var detail = [];
    if (this.props.dataItem !== undefined) {
      if (this.state.version === true) {
        passObjDet = {
          objsilentaudit: Silentauditobject(
            "view",
            "Sheet Orders",
            this.props.t("compareObjs.IDS_VERSIONVIEW", {
              user: store.getState().Loggeduser.username,
            })
          ),
        };
      }
      if (this.state.workflow === true) {
        passObjDet = {
          objsilentaudit: Silentauditobject(
            "view",
            "Sheet Orders",
            this.props.t("compareObjs.IDS_WORKFLOWVIEW", {
              user: store.getState().Loggeduser.username,
            })
          ),
        };
      }
      if (this.state.transaction === true) {
        passObjDet = {
          objsilentaudit: Silentauditobject(
            "view",
            "Sheet Orders",
            this.props.t("compareObjs.IDS_TRANSVIEW", {
              user: store.getState().Loggeduser.username,

            })
          ),
        };
      }
      if (
        this.state.version === true ||
        this.state.transaction === true ||
        this.state.workflow === true
      ) {
        Servicecalls(
          "" + this.ELNurl + "/AuditTrail/AuditConfigurationrecord",
          passObjDet,
          (res) => {
            if (res !== undefined && res !== null) {
              this.setState({
                version: false,
                workflow: false,
                transaction: false,
              });
            }
          },
          (res) => { }
        );
      }


      if (
        this.props.dataItem.lsfileversion !== undefined &&
        this.props.dataItem.lsfileversion !== null &&
        this.props.dataItem.lsfileversion.length > 0
      ) {
        for (
          let index = 0;
          index < this.props.dataItem.lsfileversion.length;
          index++
        ) {
          if (
            this.props.dataItem.lsfileversion[index].modifiedby !== undefined &&
            this.props.dataItem.lsfileversion[index].modifiedby !== null && this.props.dataItem.lsfileversion[index].versionname !== "1"
          ) {
            // d = new Date(this.props.dataItem.lsfileversion[index].modifieddate);

            let localdate = convertUTCDateToLocalDate(
              new Date(this.props.dataItem.lsfileversion[index].modifieddate)
            );

            let d = new Date(localdate);

            let day = d.getDate();
            let dayname = this.month[d.getMonth()];
            // time = d.getHours() + ":" + d.getMinutes() + ":" + d.getSeconds();
            day = convertdoubledigitdate(day);
            let time = convertdoubledigittime(
              d.getHours(),
              d.getMinutes(),
              d.getSeconds()
            );
            let year = d.getFullYear();
            // let gmt =d.toString();
            // gmt=gmt.slice(25,34);

            let imgurl = this.ELNurl + this.imageurl + "-1";
            if (
              this.props.dataItem.lsfileversion[index].modifiedby !== null &&
              this.props.dataItem.lsfileversion[index].modifiedby !== undefined
            ) {
              imgurl =
                this.ELNurl +
                this.imageurl +
                this.props.dataItem.lsfileversion[index].modifiedby.usercode;
            }
            detail.push(
              <li
                key={this.props.dataItem.filecode + "Trans_" + index}
                className="list-group-item"
                aria-disabled="true"
              >
                <div className="row no-gutters align-items-center">
                  <div className="col-2">
                    <div className="calender-list  small">
                      <span className="d-flex  eln-stamp">{dayname}</span>
                      <span className="d-flex eln-date">{day}</span>
                      <span className="d-flex eln-stamp">{year}</span>
                      <span className="d-flex  eln-stamp">{time}</span>
                      {/* <span className="d-flex eln-stamp">{gmt}</span> */}
                    </div>
                  </div>
                  <div className="col-10 mnuContainer2 userDetails">
                    <Userdetail
                      index={"Trans-" + index}
                      imgurl={imgurl}
                      user={this.props.dataItem.lsfileversion[index].modifiedby}
                    />
                    <p>
                      {this.props.t("Orders.Protocolorders.IDS_LBL_MODIFIEDF", {
                        user2:
                          this.props.dataItem.lsfileversion[index].modifiedby
                            .username,
                        user1:
                          this.props.dataItem.lsfileversion[index].versionname,
                      })}
                    </p>
                  </div>
                </div>
              </li>
            );
          }
        }
      } else {
        if (
          this.props.dataItem.createby !== undefined &&
          this.props.dataItem.createby !== null
        ) {
          // d = new Date(this.props.dataItem.createdate);

          let localdate = convertUTCDateToLocalDate(
            new Date(this.props.dataItem.createdate)
          );

          let d = new Date(localdate);

          let day = d.getDate();
          let dayname = this.month[d.getMonth()];
          // time = d.getHours() + ":" + d.getMinutes() + ":" + d.getSeconds();
          day = convertdoubledigitdate(day);
          let time = convertdoubledigittime(
            d.getHours(),
            d.getMinutes(),
            d.getSeconds()
          );
          let year = d.getFullYear();
          // let gmt =d.toString();
          // gmt=gmt.slice(25,34);

          let imgurl = this.ELNurl + this.imageurl + "-1";
          if (
            this.props.dataItem.createby !== null &&
            this.props.dataItem.createby !== undefined
          ) {
            imgurl =
              this.ELNurl +
              this.imageurl +
              this.props.dataItem.createby.usercode;
          }
          detail.push(
            <li
              key={this.props.dataItem.filecode + "Trans_"}
              className="list-group-item"
              aria-disabled="true"
            >
              <div className="row no-gutters align-items-center">
                <div className="col-2">
                  <div className="calender-list  small">
                    <span className="d-flex eln-stamp">{dayname}</span>
                    <span className="d-flex eln-date">{day}</span>
                    <span className="d-flex eln-stamp">{year}</span>
                    <span className="d-flex eln-stamp">{time}</span>
                    {/* <span className="d-flex eln-stamp">{gmt}</span> */}
                  </div>
                </div>
                <div className="col-10 mnuContainer2 userDetails">
                  <Userdetail
                    index={"Trans-"}
                    imgurl={imgurl}
                    user={this.props.dataItem.createby}
                  />
                  <p>
                    {this.props.t("Orders.Protocolorders.IDS_LBL_CREATEDFILE", {
                      user2: this.props.dataItem.createby.username,
                      user1: this.props.dataItem.filenameuser,
                    })}
                  </p>
                </div>
              </div>
            </li>
          );
        }
        //       detail.push(<li className="list-group-item" aria-disabled="true">
        //           {this.props.t("SheetCreation.IDS_NOTVERSIONED", {
        //              param: "",
        //        })}
        //   </li>);
      }
    }

    if (
      this.props.dataItem.lsfileversion !== undefined &&
      this.props.dataItem.lsfileversion !== null &&
      this.props.dataItem.lsfileversion.length > 0
    ) {

      let d = null;
      let day = null;
      let dayname = null;
      let time = null;
      let index = -1;
      if (index === -1) {
        if (
          this.props.dataItem.createby !== undefined &&
          this.props.dataItem.createby !== null
        ) {
          //  d = convertUTCDateToLocalDate(GetutcDate(new Date(this.props.dataItem.createdate)));
          // d = new Date(this.props.dataItem.createdate);

          let localdate = convertUTCDateToLocalDate(
            new Date(this.props.dataItem.createdate)
          );

          d = new Date(localdate);

          day = d.getDate();
          dayname = this.month[d.getMonth()];
          // time = d.getHours() + ":" + d.getMinutes() + ":" + d.getSeconds();
          day = convertdoubledigitdate(day);
          time = convertdoubledigittime(
            d.getHours(),
            d.getMinutes(),
            d.getSeconds()
          );
          let year = d.getFullYear();
          // let gmt =d.toString();
          // gmt=gmt.slice(25,34);

          let imgurl = this.ELNurl + this.imageurl + "-1";
          if (
            this.props.dataItem.createby !== null &&
            this.props.dataItem.createby !== undefined
          ) {
            imgurl =
              this.ELNurl +
              this.imageurl +
              this.props.dataItem.createby.usercode;
          }
          detail.push(
            <li
              key={this.props.dataItem.filecode + "Trans_" + index}
              className="list-group-item"
              aria-disabled="true"
            >
              <div className="row no-gutters align-items-center">
                <div className="col-2">
                  <div className="calender-list  small">
                    <span className="d-flex eln-stamp">{dayname}</span>
                    <span className="d-flex eln-date">{day}</span>
                    <span className="d-flex eln-stamp">{year}</span>
                    <span className="d-flex eln-stamp">{time}</span>
                    {/* <span className="d-flex eln-stamp">{gmt}</span> */}
                  </div>
                </div>
                <div className="col-10 mnuContainer2 userDetails">
                  {/* <Suspense fallback={<div></div>}> */}
                  <Userdetail
                    index={"Trans-" + index}
                    imgurl={imgurl}
                    user={this.props.dataItem.createby}
                  />
                  {/* </Suspense> */}
                  <p>
                    {this.props.t("Orders.Protocolorders.IDS_LBL_CREATEDFILE", {
                      user2: this.props.dataItem.createby.username,
                      user1: this.props.dataItem.filenameuser,
                    })}
                  </p>
                </div>
              </div>
            </li>
          );
        }
      }
    }

    let detailsvalue = (
      <ul className="list-group list-group-flush">{detail}</ul>
    );
    return detailsvalue;
  }

  formatworkflowdetails(lsorderworkflowhistory, expanded) {
    var detail = [];
    let imgurl;
    if (lsorderworkflowhistory !== undefined) {
      // for (let index = 0; index < lsorderworkflowhistory.length; index++) {
      for (let index = lsorderworkflowhistory.length - 1; index >= 0; index--) {
        let localDate = convertUTCDateToLocalDate(new Date(lsorderworkflowhistory[index].createdate));

        let d = new Date(localDate);

        let day = d.getDate();
        let dayname = this.month[d.getMonth()];

        day = convertdoubledigitdate(day);
        let time = convertdoubledigittime(d.getHours(), d.getMinutes(), d.getSeconds());
        let year = d.getFullYear();
        imgurl = this.ELNurl + this.imageurl + "-1";
        let imgdetails = [];
        imgdetails.push(imgurl);
        if (
          lsorderworkflowhistory[index].createby !== null &&
          lsorderworkflowhistory[index].createby !== undefined
        ) {
          imgurl = this.ELNurl + this.imageurl + lsorderworkflowhistory[index].createby.usercode;
          imgdetails.push(imgurl);
        }
        detail.push(
          <li
            className="list-group-item"
            aria-disabled="true"
            key={
              lsorderworkflowhistory[index].historycode !== undefined ? lsorderworkflowhistory[index].historycode : index
            }
          >
            <div className="row no-gutters align-items-center">
              <div className="col-2">
                <div className="calender-list small">
                  <span className="d-flex eln-stamp">{dayname}</span>
                  <span className="d-flex eln-date">{day}</span>
                  <span className="d-flex eln-stamp">{year}</span>
                  <span className="d-flex eln-stamp">{time}</span>
                </div>
              </div>
              <div className="col-10 mnuContainer2 userDetails">
                {/* <Suspense fallback={<div></div>}>
                  <Userdetail
                    index={"CreateWKFlow-" + index}
                    imgurl={imgurl}
                    user={lsorderworkflowhistory[index].createby}
                  />
                </Suspense> */}
                <p>
                  {lsorderworkflowhistory[index].action}

                  <div className="userComts">
                    <b>{this.props.t("Templates.Sheettemplates.IDS_LBL_COMMENT", { param: "" })} : </b>{" "}
                    {lsorderworkflowhistory[index].comment}
                  </div>
                </p>
              </div>
            </div>
          </li>
        );
      }
    }
    let detailsvalue = (
      <ul className="list-group list-group-flush">{detail}</ul>
    );
    return detailsvalue;
  }

  handleTabClick = (tabNumber) => {
    // Function to handle tab click and update the activeTab state
    this.setState({ activeTab: tabNumber });
  };


  onClose(e) {

    this.setState({ customSheetView: false })

  }
  Sheetinfo(e) {

  }
  render() {

    const { activeTab } = this.state;
    const props = {
      t: (key, options) => {/* Your translation logic here */},
    };

    return (
      this.props.dataItem[this.props.keyfield] !== undefined &&
      <div>
        <button
          id={`user-${this.props.dataItem[this.props.keyfield]}`}
          className="grid-icons"
          alt="user"
          data-placement="top"
          title={this.props.t("Templates.Sheettemplates.IDS_LBL_HISTORY")} //"History"
          // onClick={this.Sheetinfo.bind(this)}
          onClick={() => {

            if (
              this.props.dataItem.filecode !== undefined &&
              this.props.dataItem.filecode !== null
            ) {
              let passObjDetFile = { filecode: this.props.dataItem.filecode };
              Servicecalls(
                "" + this.ELNurl + "/File/Getfileversions",
                passObjDetFile,
                (res) => {

                  if (res !== undefined && res !== null) {
                    this.props.dataItem.lsfileversion = res;
                    if (this.props.dataItem.lsfileversion !== undefined) {
                      if (
                        this.props.dataItem.lssheetworkflowhistory === undefined ||
                        this.props.dataItem.lssheetworkflowhistory === null
                      ) {
                        if (
                          this.props.dataItem.filecode !== undefined &&
                          this.props.dataItem.filecode !== null
                        ) {
                          let passObjDetFile = { filecode: this.props.dataItem.filecode };

                          Servicecalls(
                            "" + this.ELNurl + "/File/Getfileworkflowhistory",
                            passObjDetFile,
                            (res) => {
                              if (res !== undefined && res !== null) {
                                this.props.dataItem.lssheetworkflowhistory = res;
                              }
                            },
                            (res) => { }
                          );
                        }
                      }
                      let passObjDet = {
                        objsilentaudit: Silentauditobject(
                          "view",
                          "Sheet Templates",
                          this.props.t("Templates.Sheettemplates.IDS_AUDIT_SHEETINFOOPENED", {
                            user1: this.props.dataItem.filenameuser,
                            user: store.getState().Loggeduser.username
                          })
                        ),
                      };
                      Servicecalls(
                        "" + this.ELNurl + "/AuditTrail/silentandmanualRecordHandler",
                        passObjDet,
                        (res) => { },
                        (res) => { }
                      );

                    }

                  }
                },
                (res) => { }
              );
              setTimeout(() => {
                this.setState({ customSheetView: true })
              }, 100);

            }



          }}


        >
          <i className="far fa-eye 1" ></i>
        </button>
        {this.state.customSheetView &&
          <Modal
            isOpen={this.state.customSheetView}
            backdrop={true}
            toggle={() => { this.setState({ customSheetView: !this.state.customSheetView }) }}
            className=" modal-md smallPopup protocol-history sheet-history"
          >
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel"> {this.props.t("Templates.Sheettemplates.IDS_LBL_TEMPLATEHISTORY")}
              </h5>
              <button
                type="button"
                className="btn_white_bg"
                data-dismiss="modal"
                aria-label="Close"
                onClick={this.onClose.bind(this)}
              >
                <i className="fas fa-times" aria-hidden="true"></i>
              </button>
            </div>
            <ModalBody>
              {/* <OverlayScrollbarsComponent>
                <div className="popup-group-list full-width popup-lg-container">

                  <Card className="listed-user-details no-border-card">
                    <CardBody>
                      <CardTitle>
                        {this.props.t("Templates.Protocoltemplates.IDS_LBL_VERSIONDETAL", {
                          param: "",
                        })}
                        <button
                          className="btn float-right"
                          onClick={() => {
                            this.setState({
                              versionexpanded: !this.state.versionexpanded
                            });
                          }}
                        >
                          <i
                            className={
                              this.state.versionexpanded
                                ? "fa fa-angle-up"
                                : "fa fa-angle-down"
                            }
                            aria-hidden="true"
                          ></i>
                        </button>
                      </CardTitle>
                      <Collapse isOpen={this.state.versionexpanded}>
                        {this.formatversiondetails()}
                      </Collapse>
                    </CardBody>
                    <CardBody>
                      <CardTitle>
                        {this.props.t("Orders.Protocolorders.IDS_LBL_TRANS", {
                          param: "",
                        })}
                        <button
                          className="btn float-right"
                          onClick={() => {
                            this.setState({
                              transactionexpanded: !this.state.transactionexpanded
                              // transaction: !this.state.transactionexpanded,
                            });
                          }}
                        >
                          <i
                            className={
                              this.state.transactionexpanded
                                ? "fa fa-angle-up"
                                : "fa fa-angle-down"
                            }
                            aria-hidden="true"
                          ></i>
                        </button>
                      </CardTitle>
                      <Collapse isOpen={this.state.transactionexpanded}>
                        {this.formattransactiondetails()}
                      </Collapse>
                    </CardBody>
                    {this.props.dataItem.lssheetworkflowhistory !== undefined &&
                      this.props.dataItem.lssheetworkflowhistory !== null &&
                      this.props.dataItem.lssheetworkflowhistory.length > 0 ? (
                      <CardBody>
                        <CardTitle>
                          {this.props.t("Orders.Protocolorders.IDS_LBL_WORKF", {
                            param: "",
                          })}
                          <button
                            className="btn float-right"
                            onClick={() => {
                              this.setState({
                                workflowexpanded: !this.state.workflowexpanded
                              });
                            }}
                          >
                            <i
                              className={
                                this.state.workflowexpanded
                                  ? "fa fa-angle-up"
                                  : "fa fa-angle-down"
                              }
                              aria-hidden="true"
                            ></i>
                          </button>
                        </CardTitle>
                        <Collapse isOpen={this.state.workflowexpanded}>
                          {this.formatworkflowdetails(
                            this.props.dataItem.lssheetworkflowhistory,
                            this.props
                          )}
                        </Collapse>
                      </CardBody>
                    ) : (
                      <CardBody>
                        <CardTitle>
                          {this.props.t("Orders.Protocolorders.IDS_LBL_WORKF", {
                            param: "",
                          })}
                          <button
                            className="btn float-right"
                            onClick={() => {
                              this.setState({
                                workflowexpanded: !this.state.workflowexpanded,
                              });
                            }}
                          >
                            <i
                              className={
                                this.state.workflowexpanded
                                  ? "fa fa-angle-up"
                                  : "fa fa-angle-down"
                              }
                              aria-hidden="true"
                            ></i>
                          </button>
                        </CardTitle>
                        <Collapse isOpen={this.state.workflowexpanded}>
                          <ul className="list-group list-group-flush">
                            <li
                              className="list-group-item"
                              aria-disabled="true"
                            >
                              <p>
                                {this.props.t("Templates.Protocoltemplates.IDS_LBL_NOTRANC", {
                                  param: "",
                                })}
                              </p>
                            </li>
                          </ul>
                        </Collapse>
                      </CardBody>
                    )}
                  </Card>
                </div>
              </OverlayScrollbarsComponent> */}

                                <div>
                                    <ul className="nav nav-tabs">
                                      <li className={`nav-item ${activeTab === 1 ? 'active' : ''}`}>
                                        <a
                                          className="nav-link"
                                          onClick={() => this.handleTabClick(1)}
                                          href="#tab1"
                                        >
                                          {this.props.t("Templates.Sheettemplates.IDS_LBL_VERSIONHIS")}
                                        </a>
                                       </li>
                                        <li className={`nav-item ${activeTab === 2 ? 'active' : ''}`}>
                                          <a
                                            className="nav-link"
                                            onClick={() => this.handleTabClick(2)}
                                            href="#tab2"
                                          >
                                           {this.props.t("Templates.Sheettemplates.IDS_LBL_TRANS")}
                                          </a>
                                        </li>
                                        <li className={`nav-item ${activeTab === 3 ? 'active' : ''}`}>
                                          <a
                                            className="nav-link"
                                            onClick={() => this.handleTabClick(3)}
                                            href="#tab3"
                                          >
                                           {this.props.t("Templates.Sheettemplates.IDS_LBL_WORKF")}
                                          </a>
                                        </li>
                                      </ul>

                                  <div className="tab-content">
                                    <div
                                      className={`tab-pane ${activeTab === 1 ? 'active' : ''}`}
                                      id="tab1"
                                    >
                                          <div className="popup-group-list-inner" >
                                          {this.formatversiondetails()}
                                          </div>
                                    </div>
                                    <div
                                      className={`tab-pane ${activeTab === 2 ? 'active' : ''}`}
                                      id="tab2"
                                    >
                                          <div className="popup-group-list-inner" >
                                            {this.formattransactiondetails()}
                                          </div>
                                    </div>
                                    <div
                                      className={`tab-pane ${activeTab === 3 ? 'active' : ''}`}
                                      id="tab3"
                                    >
                                          <div className="popup-group-list-inner">
                                          {this.formatworkflowdetails(
                                            this.props.dataItem.lssheetworkflowhistory,
                                            this.props
                                          )}
                                          </div>
                                    </div>
                                  </div>
                                </div>

            </ModalBody>
            {/* <div className="modal-footer">
              <button
                type="button"
                className="textBtn btn_white_bg"
                onClick={this.onClose.bind(this)}
              > {this.props.t("Templates.Sheettemplates.IDS_LBL_CLOSE", {
                param: "",
              })}
              </button>
            </div> */}
          </Modal>
        }

      </div>
    );
  }
}
export default withTranslation(CustomDetailviewCell);

class CustomUsergroupviewCell extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.ELNurl = document.getElementById("ELNname").value;
  }
  isToolTipOpen = (targetName) => {
    return this.state[targetName] ? this.state[targetName].tooltipOpen : false;
  };
  toggle = (targetName) => {
    if (!this.state[targetName]) {
      this.setState({
        ...this.state,
        [targetName]: {
          tooltipOpen: true,
        },
      });
    } else {
      this.setState({
        ...this.state,
        [targetName]: {
          tooltipOpen: !this.state[targetName].tooltipOpen,
        },
      });
    }

    this.setState({
      transactionexpanded: false,
      workflowexpanded: false,
    });
  };
  formatdefaultusergroup(multiusergroupcode, lsmultisites) {
    var detail = [];
    if (multiusergroupcode !== undefined && multiusergroupcode !== null) {
      var listofusergroup = multiusergroupcode;

      var filterdefaulusergroup = [];

      filterdefaulusergroup = listofusergroup.filter(
        (items) => items.defaultusergroup === 1
      );

      if (filterdefaulusergroup.length === 1) {

        const siteNames = lsmultisites.flatMap(item =>
          item?.lssiteMaster?.sitecode === filterdefaulusergroup[0]?.lsusergroup?.lssitemaster ? [item.lssiteMaster.sitename] : []
        );



        detail.push(
          <li
            className="list-group-item list-group-item-action "
            aria-disabled="true"
            key={filterdefaulusergroup[0].lsusergroup.multiusergroupcode !== undefined ? filterdefaulusergroup[0].lsusergroup.multiusergroupcode : "nodefaultgroupval"}
          >
            <p>{filterdefaulusergroup[0].lsusergroup.usergroupname + "-" + (siteNames.length > 0 ? siteNames[0] : "")}</p>{" "}
          </li>
        );
      } else {
        detail.push(
          <li className="list-group-item" aria-disabled="true" key="nodefaultgroup">
            <p>{"No Default Usergroup"}</p>
          </li>
        );
      }

      // }
    }
    let detailsvalue = (
      <ul className="list-group list-group-flush">{detail}</ul>
    );
    return detailsvalue;
  }
  closeusergroupinformation() {
    this.setState({ customUsergroup: false })

  }

  formatusergroup(multiusergroupcode, lsmultisites) {
    var detail = [];
    if (multiusergroupcode !== undefined && multiusergroupcode !== null) {
      for (let index = 0; index < multiusergroupcode.length; index++) {
        // let d = new Date(lsorderworkflowhistory[index].createdate);
        // let day = d.getDate();
        // let dayname = this.month[d.getMonth()];
        // let time = d.getHours() + ":" + d.getMinutes() + ":" + d.getSeconds();
        // let imgurl = this.ELNurl + this.imageurl + "-1";
        // if (lsorderworkflowhistory[index].createby !== null && lsorderworkflowhistory[index].createby !== undefined) {
        //     imgurl = this.ELNurl + this.imageurl + lsorderworkflowhistory[index].createby.usercode;
        // }
        const siteNames = lsmultisites.flatMap(item =>
          item?.lssiteMaster?.sitecode === multiusergroupcode[index]?.lsusergroup?.lssitemaster ? [item.lssiteMaster.sitename] : []
        );
        detail.push(
          <React.Fragment key={"multigroupindex_" + index}>
            {multiusergroupcode[index].defaultusergroup !== 1 ? (
              <li
                className="list-group-item list-group-item-action"
                aria-disabled="true"
                key={multiusergroupcode[index].multiusergroupcode}
              >
                <p>{multiusergroupcode[index].lsusergroup.usergroupname + "-" + (siteNames.length > 0 ? siteNames[0] : "")}</p>

              </li>
            ) : (
              ""
            )}
          </React.Fragment>
        );
      }
    }
    let detailsvalue = (
      <ul className="list-group list-group-flush">{detail}</ul>
    );
    return detailsvalue;
  }

  render() {
    return (
      this.props.dataItem[this.props.keyfield] !== undefined ? (
        <div className="column">
          <button
            id={`user-${this.props.dataItem[this.props.keyfield]}`}
            className="grid-icons mg-t-half mg-l-1"
            alt="user"
            data-placement="top"
            title={this.props.t("Setup.Usermaster.IDS_LBL_USERGROUP")}
            onClick={() => {
              this.setState({ customUsergroup: true })
              let passObjDet = {
                objsilentaudit: Silentauditobject(
                  "view",
                  "usermaster",
                  this.props.t("Setup.Usermaster.IDS_AUDIT_USERMASTERINFOOPEN", {
                    user1: this.props.dataItem.username,
                    user: store.getState().Loggeduser.username,
                  })

                ),
              };
              Servicecalls(
                "" + this.ELNurl + "/AuditTrail/silentandmanualRecordHandler",
                passObjDet,
                (res) => { },
                (res) => { }
              );
            }}
          >
            <i className="far fa-eye 2"></i>
          </button>
          {this.state.customUsergroup ?
            <Modal
              isOpen={this.state.customUsergroup}
              backdrop={true}
              toggle={() => {
                this.setState({ customUsergroup: !this.state.customUsergroup })

              }}

              className="screen-centered-scroll v-centerd modal-width"
            >
              <ModalBody>
                {/*     <Card>
                                <CardBody>
                                    <CardTitle>
                                        <b> */}
                <div className="popup-group-list  full-width popup-lg-container">
                  {/* <h6>
                  {this.props.t("Master_UserMaster.ID_DEFAULTUSERGROUP", {
                    param: "",
                  })}
                </h6> */}
                  <h6>
                    {this.props.t("Setup.Usermaster.IDS_LBL_USERGROUPS", {
                      param: "",
                    })}
                  </h6>
                  {/* <button className="btn float-right" onClick={() => { */}
                  {/* // this.setState({ versionexpanded: !this.state.versionexpanded, version: !this.state.versionexpanded }); */}
                  {/* }}> */}
                  {/* <i className={this.state.versionexpanded ? "fa fa-angle-up" : "fa fa-angle-down"} aria-hidden="true"></i> */}
                  {/* <i className="fa fa-angle-up" aria-hidden="true"></i> */}
                  {/* </button> */}
                  {/* <hr></hr> */}
                  {/* </CardTitle> */}
                  {/* <Collapse isOpen={this.state.versionexpanded}> */}
                  {/* <Collapse isOpen="true"> */}
                  <div className="popup-group-list-inner">
                    {this.formatdefaultusergroup(
                      this.props.dataItem.multiusergroupcode, this.props.dataItem.lsmultisites
                    )}
                  </div>

                  {/* </Collapse> */}

                  {/* </CardBody> */}

                  {/* <CardBody>
                                    <CardTitle>
                                        <b> */}
                  {/* <h6>
                  {this.props.t("Master_UserMaster.ID_ALTERNATIVEUSERGROUP", {
                    param: "",
                  })}
                </h6> */}
                  {/* </b> */}
                  {/* <button className="btn float-right" onClick={() => { */}
                  {/* // this.setState({ versionexpanded: !this.state.versionexpanded, version: !this.state.versionexpanded }); */}
                  {/* }}> */}
                  {/* <i className={this.state.versionexpanded ? "fa fa-angle-up" : "fa fa-angle-down"} aria-hidden="true"></i> */}
                  {/* <i className="fa fa-angle-up" aria-hidden="true"></i> */}
                  {/* </button> */}
                  {/* <hr></hr> */}
                  {/* </CardTitle> */}
                  {/* <Collapse isOpen={this.state.versionexpanded}> */}
                  {/* <Collapse isOpen="true"> */}
                  <div className="popup-group-list-inner">
                    {this.formatusergroup(
                      this.props.dataItem.multiusergroupcode, this.props.dataItem.lsmultisites
                    )}
                  </div>
                  {/* </Collapse> */}

                  {/* </CardBody>

                            </Card> */}
                </div>
              </ModalBody>
              <div className="modal-footer">
                <button
                  type="button"
                  className="textBtn btn_white_bg"

                  onClick={this.closeusergroupinformation.bind(this)}

                > {this.props.t("Setup.Usermaster.IDS_LBL_CLOSE")}
                </button>
              </div>
            </Modal>
            : ""
          }

          {/* <Popover
              placement="bottom"
              trigger="hover"
              isOpen={this.isToolTipOpen(
                `user-${this.props.dataItem[this.props.keyfield]}`
              )}
              target={`user-${this.props.dataItem[this.props.keyfield]}`}
              toggle={() =>
                this.toggle(`user-${this.props.dataItem[this.props.keyfield]}`)
              }
            >
              <PopoverBody className="no-pad">
                <OverlayScrollbarsComponent
                  options={{ scrollbars: { autoHide: "scroll" } }}
                  style={{ maxHeight: "40vh" }}
                >
                  <div className="popup-group-list">
                    <h6>
                      {this.props.t("Master_UserMaster.ID_USERGROUPS", {
                        param: "",
                      })}
                    </h6>
                    <div className="popup-group-list-inner">
                      {this.formatdefaultusergroup(
                        this.props.dataItem.multiusergroupcode
                      )}
                    </div>

                    <div className="popup-group-list-inner">
                      {this.formatusergroup(
                        this.props.dataItem.multiusergroupcode
                      )}
                    </div>
                  </div>
                </OverlayScrollbarsComponent>
              </PopoverBody>
            </Popover> */}
        </div>
      ) : (
        ""
      )
    );
  }
}
const CustomUsergroupviewCells = withTranslation(CustomUsergroupviewCell);
// export default withTranslation(CustomUsergroupviewCell);



class Formatprotocolversion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Data: [],
      inventryexpand: true,
      inventryhistoryexpand: false,
      userMasterScreen: true,
      ServiceToUrl: "",
      ServiceMethodType: "POST",
      ServiceHeaderobject: {},
      ServcieonSuccess: undefined,
      ServcieonFailure: undefined,
    }
    this.ELNurl = document.getElementById("ELNname").value;

    this.month = [
      this.props.t("months.IDS_LBL_JAN", { param: "" }),
      this.props.t("months.IDS_LBL_FEB", { param: "" }),
      this.props.t("months.IDS_LBL_MAR", { param: "" }),
      this.props.t("months.IDS_LBL_APR", { param: "" }),
      this.props.t("months.IDS_LBL_MAY", { param: "" }),
      this.props.t("months.IDS_LBL_JUN", { param: "" }),
      this.props.t("months.IDS_LBL_JUL", { param: "" }),
      this.props.t("months.IDS_LBL_AUG", { param: "" }),
      this.props.t("months.IDS_LBL_SEP", { param: "" }),
      this.props.t("months.IDS_LBL_OCT", { param: "" }),
      this.props.t("months.IDS_LBL_NOV", { param: "" }),
      this.props.t("months.IDS_LBL_DEC", { param: "" }),
    ];

  }

  render() {
    let d = null;
    let day = null;
    let dayname = null;
    let time = null;
    let localDate = convertUTCDateToLocalDate(
      new Date(this.props.dataItem.createdate ? this.props.dataItem.createdate : this.props.dataItem.protocolmodifiedDate)
    );
    d = new Date(
      localDate
    );
    day = d.getDate();
    dayname = this.month[d.getMonth()];
    // time = d.getHours() + ":" + d.getMinutes() + ":" + d.getSeconds();
    day = convertdoubledigitdate(day);
    time = convertdoubledigittime(d.getHours(), d.getMinutes(), d.getSeconds());
    let year = d.getFullYear();
    let imgurl = this.ELNurl + this.imageurl + "-1";
    if (
      this.props.dataItem
        .createdbyusername !== null &&
      this.props.dataItem
        .createdbyusername !== undefined
    ) {
      imgurl =
        this.ELNurl +
        this.imageurl +
        this.props.dataItem
          .createdby;
    }
    return (<div>
      <li
        className="list-group-item"
        aria-disabled="true"
      >
        <div className="row no-gutters align-items-center">
          <div className="col-2">
            <div className="calender-list">
              <span className="d-flex eln-stamp">{dayname}</span>
              <span className="d-flex eln-date">{day}</span>
              <span className="d-flex eln-stamp">{year}</span>
              <span className="d-flex eln-stamp">{time}</span>
            </div>
          </div>
          <div className="col-10 mnuContainer2 userDetails">
            <Userdetail
              index={"Trans1-" + this.props.index}
              imgurl={imgurl}
              user={{
                usercode: this.props.dataItem
                  .createdby, username: this.props.dataItem.createdbyusername
              }

              }
            />

            <div className="col-12">


              <div className="container my-4">
                {this.props.dataItem.versionname === "1" ?
                  this.props.t(
                    "Templates.Protocoltemplates.IDS_LBL_CREATEDFILE",
                    {
                      user2: this.props.dataItem.createdbyusername,
                      user1: this.props.dataItem.versionname,
                    }
                  )
                  :
                  this.props.t("Templates.Protocoltemplates.IDS_LBL_MODIFIEDF", {
                    user2: this.props.dataItem.createdbyusername,
                    user1: this.props.dataItem.versionname,
                  })
                }


              </div>
            </div>
          </div>
        </div>
      </li>
    </div>);
  }

}

const Formatprotocolversioncell = withTranslation(Formatprotocolversion);

class FormatResultUsedDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Data: [],
      inventryexpand: true,
      inventryhistoryexpand: false,
      userMasterScreen: true,
      ServiceToUrl: "",
      ServiceMethodType: "POST",
      ServiceHeaderobject: {},
      ServcieonSuccess: undefined,
      ServcieonFailure: undefined,
    }
    this.ELNurl = document.getElementById("ELNname").value;

    this.month = [
      this.props.t("months.IDS_LBL_JAN", { param: "" }),
      this.props.t("months.IDS_LBL_FEB", { param: "" }),
      this.props.t("months.IDS_LBL_MAR", { param: "" }),
      this.props.t("months.IDS_LBL_APR", { param: "" }),
      this.props.t("months.IDS_LBL_MAY", { param: "" }),
      this.props.t("months.IDS_LBL_JUN", { param: "" }),
      this.props.t("months.IDS_LBL_JUL", { param: "" }),
      this.props.t("months.IDS_LBL_AUG", { param: "" }),
      this.props.t("months.IDS_LBL_SEP", { param: "" }),
      this.props.t("months.IDS_LBL_OCT", { param: "" }),
      this.props.t("months.IDS_LBL_NOV", { param: "" }),
      this.props.t("months.IDS_LBL_DEC", { param: "" }),
    ];

  }

  render() {
    let d = null;
    let day = null;
    let dayname = null;
    let time = null;
    let localDate = convertUTCDateToLocalDate(new Date(this.props.dataItem.createddate));
    d = new Date(localDate);
    day = d.getDate();

    dayname = this.month[d.getMonth()];
    // time = d.getHours() + ":" + d.getMinutes() + ":" + d.getSeconds();
    day = convertdoubledigitdate(day);
    time = convertdoubledigittime(d.getHours(), d.getMinutes(), d.getSeconds());
    let year = d.getFullYear();

    let Isreturned_Data = this.props.dataItem?.jsondata?.includes("returned") || false;

    return (
      <li className="list-group-item" aria-disabled="true" >
        <div className="row no-gutters align-items-center">
          <div className="col-2">
            <div className="calender-list">
              <span className="d-flex eln-stamp">{dayname}</span>
              <span className="d-flex eln-date">{day}</span>
              <span className="d-flex eln-stamp">{year}</span>
              <span className="d-flex eln-stamp">{time}</span>
            </div>
          </div>
          <div className="col-10 mnuContainer2 userDetails">
            <Userdetail index={"Trans-" + -1} user={this.props.dataItem.createdbyusercode} />
            <div className="col-12">
              <div className="container my-4">
                {this.props.t(Isreturned_Data ? "Inventory.IDS_MSG_RESULTDISPLAYRETURNED" : "Inventory.IDS_MSG_RESULTDISPLAY", {
                  param2: this.props.dataItem.createdbyusercode.username,
                  param1: this.props.dataItem.nqtyused,
                  param6: this.props.dataItem.resultedUnit,
                  param3: this.props.dataItem.displayscreen,
                  param4: this.props.dataItem.nqtyleft,
                  param5: this.props.dataItem.batchid,
                })}
              </div>
            </div>
          </div>
        </div>
      </li>
    );
  }
}
const FormatResultUsedDetailscell = withTranslation(FormatResultUsedDetails);

class FormatResultEquipmentDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Data: [],
      inventryexpand: true,
      inventryhistoryexpand: false,
      userMasterScreen: true,
      ServiceToUrl: "",
      ServiceMethodType: "POST",
      ServiceHeaderobject: {},
      ServcieonSuccess: undefined,
      ServcieonFailure: undefined,
    }
    this.ELNurl = document.getElementById("ELNname").value;

    this.month = [
      this.props.t("months.IDS_LBL_JAN", { param: "" }),
      this.props.t("months.IDS_LBL_FEB", { param: "" }),
      this.props.t("months.IDS_LBL_MAR", { param: "" }),
      this.props.t("months.IDS_LBL_APR", { param: "" }),
      this.props.t("months.IDS_LBL_MAY", { param: "" }),
      this.props.t("months.IDS_LBL_JUN", { param: "" }),
      this.props.t("months.IDS_LBL_JUL", { param: "" }),
      this.props.t("months.IDS_LBL_AUG", { param: "" }),
      this.props.t("months.IDS_LBL_SEP", { param: "" }),
      this.props.t("months.IDS_LBL_OCT", { param: "" }),
      this.props.t("months.IDS_LBL_NOV", { param: "" }),
      this.props.t("months.IDS_LBL_DEC", { param: "" }),
    ];

  }

  render() {
    let d = null;
    let day = null;
    let dayname = null;
    let time = null;
    let localDate = convertUTCDateToLocalDate(new Date(this.props.dataItem.createddate));
    d = new Date(localDate);
    day = d.getDate();

    dayname = this.month[d.getMonth()];
    day = convertdoubledigitdate(day);
    time = convertdoubledigittime(d.getHours(), d.getMinutes(), d.getSeconds());
    let year = d.getFullYear();

    return (
      <li className="list-group-item" aria-disabled="true" >
        <div className="row no-gutters align-items-center">
          <div className="col-2">
            <div className="calender-list">
              <span className="d-flex eln-stamp">{dayname}</span>
              <span className="d-flex eln-date">{day}</span>
              <span className="d-flex eln-stamp">{year}</span>
              <span className="d-flex eln-stamp">{time}</span>
            </div>
          </div>
          <div className="col-10 mnuContainer2 userDetails">
            <Userdetail index={"Trans-" + -1} user={this.props.dataItem.createdby} />
            <div className="col-12">
              <div className="container my-4">
                {this.props.dataItem?.historytype !== undefined ? (this.props.dataItem?.historytype === 1 ? 
                this.props.t("Inventory.IDS_MSG_EQUIPCALLIB", {
                  param1: this.props.dataItem.createdby.username,
                  param2: convertUTCDateToLocalDate(new Date(this.props.dataItem.callibrationdate))
                }) : this.props.t("Inventory.IDS_MSG_EQUIPMAINTAIN", {
                  param1: this.props.dataItem.createdby.username,
                  param2: convertUTCDateToLocalDate(new Date(this.props.dataItem.manintanancedate))
                })): this.props.t("Inventory.IDS_MSG_RESULTEQUIPMENTDISPLAY", {
                  param1: this.props.dataItem.createdby.username,
                  param2: this.props.dataItem.displayscreen,
                  param3: this.props.dataItem.batchid,
                })}
              </div>
            </div>
          </div>
        </div>
      </li>
    );
  }
}
const FormatResultUsedEquipment = withTranslation(FormatResultEquipmentDetails);

class Formatprotocoltransaction extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Data: [],
      inventryexpand: true,
      inventryhistoryexpand: false,
      userMasterScreen: true,
      ServiceToUrl: "",
      ServiceMethodType: "POST",
      ServiceHeaderobject: {},
      ServcieonSuccess: undefined,
      ServcieonFailure: undefined,
    };
    this.ELNurl = document.getElementById("ELNname").value;

    this.month = [
      this.props.t("months.IDS_LBL_JAN", { param: "" }),
      this.props.t("months.IDS_LBL_FEB", { param: "" }),
      this.props.t("months.IDS_LBL_MAR", { param: "" }),
      this.props.t("months.IDS_LBL_APR", { param: "" }),
      this.props.t("months.IDS_LBL_MAY", { param: "" }),
      this.props.t("months.IDS_LBL_JUN", { param: "" }),
      this.props.t("months.IDS_LBL_JUL", { param: "" }),
      this.props.t("months.IDS_LBL_AUG", { param: "" }),
      this.props.t("months.IDS_LBL_SEP", { param: "" }),
      this.props.t("months.IDS_LBL_OCT", { param: "" }),
      this.props.t("months.IDS_LBL_NOV", { param: "" }),
      this.props.t("months.IDS_LBL_DEC", { param: "" }),
    ];

  }
  render() {
    let d = null;
    let day = null;
    let dayname = null;
    let time = null;
    let year = null;
    let imgurl = this.ELNurl + this.imageurl + "-1";

    if (this.props.dataItem !== undefined) {
      // let d = null;
      // let day = null;
      // let dayname = null;
      // let time = null;
      if (
        this.props.dataItem !== undefined &&
        this.props.dataItem !== null
      ) {

        if (
          this.props.dataItem.protocolmodifiedDate !== undefined &&
          this.props.dataItem.protocolmodifiedDate !== null
        ) {

          let localdate = convertUTCDateToLocalDate(
            new Date(
              this.props.dataItem.protocolmodifiedDate
            )
          );
          d = new Date(localdate);
          // d = new Date(
          //   this.state.selectProtocolMaster.modifiedlist[
          //     index
          //   ].protocolmodifiedDate
          // );
          day = d.getDate();
          dayname = this.month[d.getMonth()];
          // time = d.getHours() + ":" + d.getMinutes() + ":" + d.getSeconds();
          day = convertdoubledigitdate(day);
          time = convertdoubledigittime(d.getHours(), d.getMinutes(), d.getSeconds());
          year = d.getFullYear();
          let imgurl = this.ELNurl + this.imageurl + "-1";
          if (
            this.props.dataItem.protocolmodifiedDate !== null &&
            this.props.dataItem.protocolmodifiedDate !== undefined
          ) {
            imgurl =
              this.ELNurl +
              this.imageurl +
              this.props.dataItem.modifiedby
                .usercode;
          }
        }
      }
    }
    return (
      <div>
        <li
          className="list-group-item"
          aria-disabled="true"
        >
          <div className="row no-gutters align-items-center">
            <div className="col-2">
              <div className="calender-list">
                <span className="d-flex eln-stamp">{dayname}</span>
                <span className="d-flex eln-date">{day}</span>
                <span className="d-flex eln-stamp">{year}</span>
                <span className="d-flex eln-stamp">{time}</span>
              </div>
            </div>
            <div className="col-10 mnuContainer2 userDetails">
              <Suspense fallback={<div></div>}>
                <Userdetail
                  index={"Trans2-" + this.props.index}
                  imgurl={imgurl}
                  user={{ usercode: this.props.dataItem.createdby.usercode, username: this.props.dataItem.createdby.username }}
                />
              </Suspense>
              <div className="container my-4">
                {
                  this.props.dataItem
                    .protocolcomment
                }
              </div>
              {/* <p>
                {this.props.t("SheetCreation.IDS_CREATEDF", {
                  user1: this.state.selectProtocolMaster.createdbyusername,
                  user2: this.state.protocolrecord.protocolmastername,
                })}
              </p> */}
            </div>
          </div>
        </li>
      </div>
    );

  }
}

const Formatprotocoltransactioncell = withTranslation(Formatprotocoltransaction);

class Formatprotocolordertransaction extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Data: [],
      inventryexpand: true,
      inventryhistoryexpand: false,
      userMasterScreen: true,
      ServiceToUrl: "",
      ServiceMethodType: "POST",
      ServiceHeaderobject: {},
      ServcieonSuccess: undefined,
      ServcieonFailure: undefined,
    };
    this.ELNurl = document.getElementById("ELNname").value;

    this.month = [
      this.props.t("months.IDS_LBL_JAN", { param: "" }),
      this.props.t("months.IDS_LBL_FEB", { param: "" }),
      this.props.t("months.IDS_LBL_MAR", { param: "" }),
      this.props.t("months.IDS_LBL_APR", { param: "" }),
      this.props.t("months.IDS_LBL_MAY", { param: "" }),
      this.props.t("months.IDS_LBL_JUN", { param: "" }),
      this.props.t("months.IDS_LBL_JUL", { param: "" }),
      this.props.t("months.IDS_LBL_AUG", { param: "" }),
      this.props.t("months.IDS_LBL_SEP", { param: "" }),
      this.props.t("months.IDS_LBL_OCT", { param: "" }),
      this.props.t("months.IDS_LBL_NOV", { param: "" }),
      this.props.t("months.IDS_LBL_DEC", { param: "" }),
    ];

  }
  render() {
    let d = null;
    let day = null;
    let dayname = null;
    let time = null;
    let year = null;
    let imgurl = this.ELNurl + this.imageurl + "-1";

    if (this.props.dataItem !== undefined) {
      if (
        this.props.dataItem !== undefined &&
        this.props.dataItem !== null
      ) {

        if (
          this.props.dataItem?.stepstartdate !== undefined &&
          this.props.dataItem?.stepstartdate !== null ||
          this.props.dataItem?.stependdate !== undefined &&
          this.props.dataItem?.stependdate !== null
        ) {

          let localdate = convertUTCDateToLocalDate(
            new Date(
              this.props.dataItem.stepstartdate !== undefined ?
                this.props.dataItem.stepstartdate : this.props.dataItem.stependdate
            )
          );
          d = new Date(localdate);
          day = d.getDate();
          dayname = this.month[d.getMonth()];
          day = convertdoubledigitdate(day);
          time = convertdoubledigittime(d.getHours(), d.getMinutes(), d.getSeconds());
          year = d.getFullYear();
          let imgurl = this.ELNurl + this.imageurl + "-1";
          if (
            this.props.dataItem.createby.createby !== null &&
            this.props.dataItem.createby.createby !== undefined
          ) {
            imgurl =
              this.ELNurl +
              this.imageurl +
              this.props.dataItem.createby.createby.usercode;
          }
        }
      }
    }
    return (
      <div>
        <li
          className="list-group-item"
          aria-disabled="true"
        >
          <div className="row no-gutters align-items-center">
            <div className="col-2">
              <div className="calender-list">
                <span className="d-flex eln-stamp">{dayname}</span>
                <span className="d-flex eln-date">{day}</span>
                <span className="d-flex eln-stamp">{year}</span>
                <span className="d-flex eln-stamp">{time}</span>
              </div>
            </div>
            <div className="col-10 mnuContainer2 userDetails">
              <Suspense fallback={<div></div>}>
                <Userdetail
                  index={"Trans3--2"}
                  imgurl={imgurl}
                  user={this.props.dataItem.createby}
                />
              </Suspense>
              <div className="container my-4">
                {
                  this.props.dataItem.action
                }
              </div>

            </div>
          </div>
        </li>
      </div>
    );

  }
}

const Formatprotocolordertransactioncell = withTranslation(Formatprotocolordertransaction);

class Formatprotocolversiondetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Data: [],
      inventryexpand: true,
      inventryhistoryexpand: false,
      userMasterScreen: true,
      ServiceToUrl: "",
      ServiceMethodType: "POST",
      ServiceHeaderobject: {},
      ServcieonSuccess: undefined,
      ServcieonFailure: undefined,
    };
    this.ELNurl = document.getElementById("ELNname").value;

    this.month = [
      this.props.t("months.IDS_LBL_JAN", { param: "" }),
      this.props.t("months.IDS_LBL_FEB", { param: "" }),
      this.props.t("months.IDS_LBL_MAR", { param: "" }),
      this.props.t("months.IDS_LBL_APR", { param: "" }),
      this.props.t("months.IDS_LBL_MAY", { param: "" }),
      this.props.t("months.IDS_LBL_JUN", { param: "" }),
      this.props.t("months.IDS_LBL_JUL", { param: "" }),
      this.props.t("months.IDS_LBL_AUG", { param: "" }),
      this.props.t("months.IDS_LBL_SEP", { param: "" }),
      this.props.t("months.IDS_LBL_OCT", { param: "" }),
      this.props.t("months.IDS_LBL_NOV", { param: "" }),
      this.props.t("months.IDS_LBL_DEC", { param: "" }),
    ];

  }
  render() {
    let d = null;
    let day = null;
    let dayname = null;
    let time = null;
    let year = null;
    let imgurl = this.ELNurl + this.imageurl + "-1";

    if (this.props.dataItem !== undefined) {
      if (
        this.props.dataItem !== undefined &&
        this.props.dataItem !== null
      ) {

        if (
          this.props.dataItem.createdbyusername !== undefined &&
          this.props.dataItem.createdbyusername !== null
        ) {
          if (
            this.props.dataItem.createdate !== undefined
          ) {
            let localdate = convertUTCDateToLocalDate(
              new Date(this.props.dataItem.createdate)
            );
            d = new Date(localdate);
          } else {
            d = new Date();
          }
          day = d.getDate();
          dayname = this.month[d.getMonth()];
          day = convertdoubledigitdate(day);
          time = convertdoubledigittime(
            d.getHours(),
            d.getMinutes(),
            d.getSeconds()
          );
          let year = d.getFullYear();
          let imgurl = this.ELNurl + this.imageurl + "-1";
          if (
            this.props.dataItem.createdbyusername !==
            null &&
            this.props.dataItem.createdbyusername !==
            undefined
          ) {
            imgurl =
              this.ELNurl +
              this.imageurl +
              this.props.dataItem.createdby;
          }
        }
      }
      return (
        <div>
          <li
            className="list-group-item"
            aria-disabled="true"
          >
            <div className="row no-gutters align-items-center">
              <div className="col-2">
                <div className="calender-list">
                  <span className="d-flex eln-stamp">{dayname}</span>
                  <span className="d-flex eln-date">{day}</span>
                  <span className="d-flex eln-stamp">{year}</span>
                  <span className="d-flex eln-stamp">{time}</span>
                </div>
              </div>
              <div className="col-10 mnuContainer2 userDetails">
                <Suspense fallback={<div></div>}>
                  <Userdetail
                    index={"Trans1-" + this.props.index}
                    imgurl={imgurl}
                    user={{ usercode: this.props.dataItem.createdby, username: this.props.dataItem.createdbyusername }}
                  />
                </Suspense>
                <div className="container my-4">
                  {
                    this.props.dataItem.versionno !== 1 ?
                      this.props.t("Orders.Protocolorders.IDS_LBL_MODIFIEDF", {
                        user2:
                          this.props.dataItem.createdbyusername,
                        user1:
                          this.props.dataItem.versionno,
                      })
                      :
                      this.props.t("Orders.Protocolorders.IDS_LBL_CREATEDFILE", {
                        user2:
                          this.props.dataItem.createdbyusername,
                        user1:
                          this.props.dataItem.versionno,
                      })
                  }
                </div>

              </div>
            </div>
          </li>
        </div>
      );

    }
  }
}

const Formatprotocolversiondetailscell = withTranslation(Formatprotocolversiondetails);

class Custominventry extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Data: [],
      inventryexpand: true,
      inventryhistoryexpand: false,
      userMasterScreen: true,
      ServiceToUrl: "",
      ServiceMethodType: "POST",
      ServiceHeaderobject: {},
      ServcieonSuccess: undefined,
      ServcieonFailure: undefined,
    };
    // this.month = this.props.details.month;
    this.ELNurl = document.getElementById("ELNname").value;

    this.imageurl = "";
    if (isMultitenant === 1) {
      this.imageurl = "/User/Cloudprofile/";
    } else {
      this.imageurl = "/User/profile/";
    }

    this.month = [
      this.props.t("months.IDS_LBL_JAN", { param: "" }),
      this.props.t("months.IDS_LBL_FEB", { param: "" }),
      this.props.t("months.IDS_LBL_MAR", { param: "" }),
      this.props.t("months.IDS_LBL_APR", { param: "" }),
      this.props.t("months.IDS_LBL_MAY", { param: "" }),
      this.props.t("months.IDS_LBL_JUN", { param: "" }),
      this.props.t("months.IDS_LBL_JUL", { param: "" }),
      this.props.t("months.IDS_LBL_AUG", { param: "" }),
      this.props.t("months.IDS_LBL_SEP", { param: "" }),
      this.props.t("months.IDS_LBL_OCT", { param: "" }),
      this.props.t("months.IDS_LBL_NOV", { param: "" }),
      this.props.t("months.IDS_LBL_DEC", { param: "" }),
    ];
  }
  isToolTipOpen = (targetName) => {
    return this.state[targetName] ? this.state[targetName].tooltipOpen : false;
  };
  toggle = (targetName) => {
    if (!this.state[targetName]) {
      this.setState({
        ...this.state,
        [targetName]: {
          tooltipOpen: true,
        },
      });
    } else {
      this.setState({
        ...this.state,
        [targetName]: {
          tooltipOpen: !this.state[targetName].tooltipOpen,
        },
      });
    }

    this.setState({
      transactionexpanded: false,
      workflowexpanded: false,
      inventryexpand: false,
      inventryhistoryexpand: false,
    });
  };

  formatversiondetails() {
    var detail = [];

    if (
      this.state.Data !== undefined &&
      this.state.Data !== null &&
      this.state.Data.length !== 0
    ) {
      let d = null;
      let day = null;
      let dayname = null;
      let time = null;

      // if (this.props.dataItem.lsfileversion !== undefined && this.props.dataItem.lsfileversion !== null && this.props.dataItem.lsfileversion.length > 0) {

      for (let index = 0; index < this.state.Data.length; index++) {
        let createby = {
          usercode: this.state.Data[index].usercode,
          username: this.state.Data[index].createdbyusername,
        };
        // if (index === 0) {
        if (
          this.state.Data[index].usercode !== undefined &&
          this.state.Data[index].createdbyusername !== null
        ) {
          let localdate = convertUTCDateToLocalDate(
            new Date(this.state.Data[index].createddate)
          );

          d = new Date(localdate);
          day = d.getDate();
          dayname = this.month[d.getMonth()];
          // time = d.getHours() + ":" + d.getMinutes() + ":" + d.getSeconds();
          day = convertdoubledigitdate(day);
          time = convertdoubledigittime(
            d.getHours(),
            d.getMinutes(),
            d.getSeconds()
          );
          let year = d.getFullYear();
          // let gmt =d.toString();
          // gmt=gmt.slice(25,34);

          let imgurl = this.ELNurl + this.imageurl + "-1";
          if (
            this.state.Data[index].usercode !== null &&
            this.state.Data[index].createdbyusername !== undefined
          ) {
            imgurl =
              this.ELNurl + this.imageurl + this.state.Data[index].usercode;
          }



          detail.push(
            <li
              key={this.props.dataItem.repositorydatacode + "Trans_" + index}
              className="list-group-item"
              aria-disabled="true"
            >
              <div className="row no-gutters align-items-center">
                <div className="col-2">
                  <div className="calender-list small">
                    <span className="d-flex eln-stamp">{dayname}</span>
                    <span className="d-flex eln-date">{day}</span>
                    <span className="d-flex eln-stamp">{year}</span>
                    <span className="d-flex eln-stamp">{time}</span>
                    {/* <span className="d-flex eln-stamp">{gmt}</span> */}
                  </div>
                </div>
                <div className="col-10 mnuContainer2 userDetails">
                  <Userdetail
                    index={"Trans-" + index}
                    imgurl={imgurl}
                    user={createby}
                  />
                  <p>
                    {
                      this.state.Data[index].historydetails !== undefined ?
                        this.state.Data[index].historydetails :
                        this.state.Data[index].protocolsampleusedDetail !== undefined ?
                          this.state.Data[index].protocolsampleusedDetail :
                          this.state.Data[index].logbooksampleusedDetail !== undefined ?
                            this.state.Data[index].logbooksampleusedDetail : ""
                    }


                  </p>
                </div>
              </div>
            </li>
          );

          // detail.push(
          //   <li
          //     key={this.props.dataItem.repositorydatacode + "Trans_" + index}
          //     className="list-group-item"
          //     aria-disabled="true"
          //   >
          //     <div className="row no-gutters align-items-center">
          //       <div className="col-2">
          //         <div className="calender-list small">
          //           <span className="d-flex eln-stamp">{dayname}</span>
          //           <span className="d-flex eln-date">{day}</span>
          //           <span className="d-flex eln-stamp">{time}</span>
          //         </div>
          //       </div>
          //       <div className="col-10 mnuContainer2 userDetails">
          //         <Userdetail
          //           index={"Trans-" + index}
          //           imgurl={imgurl}
          //           user={createby}
          //         />
          //         {/*  <div className="row">
          //                       <div className="col-md-4">
          //                           <div className="p-2">
          //                               <b>Details</b>
          //                           </div>

          //                       </div>

          //                       <div className="col-md-6">
          //                           <div className="p-2">
          //                               {this.state.Data[index].historydetails}
          //                           </div>

          //                       </div>
          //                   </div>

          //                   <div className="row">
          //                       <div className="col-md-4">
          //                           <div className="p-2">
          //                               <b>Screen Name</b>
          //                           </div>

          //                       </div>

          //                       <div className="col-md-6">
          //                           <div className="p-2">
          //                               {this.state.Data[index].screenmodule}
          //                           </div>

          //                       </div>
          //                   </div> */}
          //         {/* <p>{this.state.Data[index].historydetails}</p> */}
          //         <div class="">
          //           {this.state.Data[index].historydetails}
          //         </div>
          //         {/* <div className="col">
          //                       <div className="col-12">
          //                           {this.state.Data[index].screenmodule}

          //                       </div>
          //                   </div> */}
          //       </div>{" "}
          //     </div>
          //   </li>
          // );
        }

        // }
      }

      //       detail.push(<li className="list-group-item" aria-disabled="true">
      //           {this.props.t("SheetCreation.IDS_NOTVERSIONED", {
      //              param: "",
      //        })}
      //   </li>);
    } else {
      detail.push(
        <ul className="list-group list-group-flush">
          <li className="list-group-item" aria-disabled="true">
            <p>
              {this.props.t("Masters.Inventory.IDS_LBL_NOINVENTRYDETAAILS", {
                param: "",
              })}
            </p>
          </li>
        </ul>
      );
    }

    let detailsvalue = (
      <ul className="list-group list-group-flush">{detail}</ul>
    );
    return detailsvalue;
  }

  async onfinddataclick(e) {

    // if (!this.state.inventryexpand) {
    let passobject = {
      repositorycode: this.props.dataItem.repositorycode,
      repositorydatacode: this.props.dataItem.repositorydatacode,
    };

    Servicecalls(
      "" + this.ELNurl + "/Master/getinventoryhistory",
      passobject,
      (res) => {
        this.onRepositorySuccess(res);
      },
      (res) => { }
    );
  }

  onRepositorySuccess(res) {
    this.setState({
      Data: res
      // inventryexpand: !this.state.inventryexpand,
      // inventryhistoryexpand: !this.state.inventryhistoryexpand,
    });
  }

  // onRepositoryFailure(responce) {

  // }

  render() {
    return (
      this.props.dataItem[this.props.keyfield] !== undefined ? (
        <React.Fragment>

          <button
            id={`user-${this.props.dataItem[this.props.keyfield]}`}
            className="grid-icons mg-t-1"
            alt="user"
            data-placement="top"
            title={this.props.t("Templates.Sheettemplates.IDS_LBL_HISTORY")}
            onClick={() => { this.setState({ customInventry: true }); this.onfinddataclick(); }}

          >
            <i className="far fa-eye 3"></i>

          </button>
          {this.state.customInventry ?
            <Modal
              isOpen={this.state.customInventry}
              backdrop={true}
              toggle={() => { this.setState({ customInventry: !this.state.customInventry }) }}
              className=" modal-md smallPopup"
            >
              <div className="modal-header">
                <h5 className="modal-title" id="staticBackdropLabel">
                  {/* {"Share with other users"} */}
                </h5>
                <button
                  type="button"
                  className="btn_white_bg"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => { this.setState({ customInventry: false }) }}
                >
                  <i className="fas fa-times" aria-hidden="true"></i>
                </button>
              </div>
              <ModalBody>
                <OverlayScrollbarsComponent
                  options={{ scrollbars: { autoHide: "scroll" } }}
                >
                  <div className="popup-group-list full-width popup-lg-container">
                    <Card className="listed-user-details no-border-card">
                      <CardBody>
                        <CardTitle>
                          {this.props.t("Masters.Inventory.IDS_LBL_INVENTRYHISTORY", {
                            param: "",
                          })}
                          {/* <button className="btn float-right" onClick={this.onfinddataclick.bind(this)}

                                                    > */}
                          <button
                            className="btn float-right"
                            onClick={() => {
                              this.setState({
                                inventryexpand: !this.state.inventryexpand,
                              });
                            }}
                          >
                            <i
                              className={
                                this.state.inventryexpand
                                  ? "fa fa-angle-up"
                                  : "fa fa-angle-down"
                              }
                              aria-hidden="true"
                            ></i>
                            {/* <i className="fa fa-angle-up" aria-hidden="true"></i> */}
                          </button>
                        </CardTitle>
                        <Collapse isOpen={this.state.inventryexpand}>
                          {this.formatversiondetails()}
                        </Collapse>
                      </CardBody>
                    </Card>

                  </div>
                </OverlayScrollbarsComponent>
              </ModalBody>
              <div className="modal-footer">
                <button
                  type="button"
                  className="textBtn btn_white_bg"
                  onClick={() => this.setState({ customInventry: false })}
                > Close
                </button>
              </div>
            </Modal>
            : ""
          }


          {/* <Popover
              placement="bottom"
              trigger="hover"
              className="popoverContainer"
              id="popover-trigger-focus"
              isOpen={this.isToolTipOpen(
                `user-${this.props.dataItem[this.props.keyfield]}`
              )}
              target={`user-${this.props.dataItem[this.props.keyfield]}`}
              toggle={() =>
                this.toggle(`user-${this.props.dataItem[this.props.keyfield]}`)
              }
            >
              <PopoverBody className="no-padding">
                <OverlayScrollbarsComponent style={{ maxHeight: "400px" }}>
                  <Card className="box-md listed-user-details">
                    <CardBody>
                      <CardTitle>
                        {this.props.t("SheetCreation.IDS_INVENTRYHISTORY", {
                          param: "",
                        })}
                        <button
                          className="btn float-right"
                          onClick={() => {
                            this.setState({
                              inventryexpand: !this.state.inventryexpand,
                            });
                            this.onfinddataclick();
                          }}
                        >
                          <i
                            className={
                              this.state.inventryhistoryexpand
                                ? "fa fa-angle-up"
                                : "fa fa-angle-down"
                            }
                            aria-hidden="true"
                          ></i>
                        </button>
                      </CardTitle>
                      {this.state.inventryhistoryexpand ? (
                        <Collapse isOpen={this.state.inventryhistoryexpand}>
                          {this.state.inventryhistoryexpand &&
                            this.formatversiondetails()}
                        </Collapse>
                      ) : (
                        ""
                      )}
                    </CardBody>
                  </Card>
                </OverlayScrollbarsComponent>
              </PopoverBody>
            </Popover> */}
        </React.Fragment>
      ) : (
        ""
      )
    );
  }
}

const Custominventrycells = withTranslation(Custominventry);

class Customprotocolorder extends React.Component {
  constructor(props) {
    super(props);
    let filterdate = new Date();
    this.state = {
      Data: [],
      transactionhistory: {},
      inventryexpand: true,
      workflowexpanded: true,
      userMasterScreen: true,
      ServiceToUrl: "",
      ServiceMethodType: "POST",
      ServiceHeaderobject: {},
      ServcieonSuccess: undefined,
      ServcieonFailure: undefined,
      versionexpanded: true,
      versionlist: undefined,
      protocolstephistory: false,
      clickTimer: null,
      OnKendoDateFrom: new Date(filterdate.setDate(filterdate.getDate() - 7)),
      OnKendoDateTo: new Date(),
      transactionscolumn: [
        {
          header: "Transaction History",
          datatype: "protocolorderstephistorycode",
          rowselector: false,
          visible: false,
          doFilter: true,
          sortable: false,
          resizable: false,
          celltype: "formatprotocolordertransactiondetails",
          className: "simple-grid-view",
          width: "100px"
        },
      ],
      Versioncolumns: [
        {
          header: "Version History",
          datatype: "protocolorderversioncode",
          rowselector: false,
          visible: false,
          doFilter: true,
          sortable: false,
          resizable: false,
          celltype: "formatprotocolorderversiondetails",
          className: "simple-grid-view",
          width: "100px"
        }
      ],
      protocoltransactionhistory: undefined,
      FilterRecord: {
        sFromDate:
          (parseInt(new Date().getMonth()) + 1).toString() +
          "/" +
          new Date().getDate().toString() +
          "/" +
          new Date().getFullYear().toString(),
        sToDate:
          (parseInt(new Date().getMonth()) + 1).toString() +
          "/" +
          new Date().getDate().toString() +
          "/" +
          new Date().getFullYear().toString(),

      },
    };

    this.ELNurl = document.getElementById("ELNname").value;

    this.imageurl = "";
    if (isMultitenant === 1) {
      this.imageurl = "/User/Cloudprofile/";
    } else {
      this.imageurl = "/User/profile/";
    }

    this.month = [
      this.props.t("months.IDS_LBL_JAN", { param: "" }),
      this.props.t("months.IDS_LBL_FEB", { param: "" }),
      this.props.t("months.IDS_LBL_MAR", { param: "" }),
      this.props.t("months.IDS_LBL_APR", { param: "" }),
      this.props.t("months.IDS_LBL_MAY", { param: "" }),
      this.props.t("months.IDS_LBL_JUN", { param: "" }),
      this.props.t("months.IDS_LBL_JUL", { param: "" }),
      this.props.t("months.IDS_LBL_AUG", { param: "" }),
      this.props.t("months.IDS_LBL_SEP", { param: "" }),
      this.props.t("months.IDS_LBL_OCT", { param: "" }),
      this.props.t("months.IDS_LBL_NOV", { param: "" }),
      this.props.t("months.IDS_LBL_DEC", { param: "" }),
    ];
  }
  isToolTipOpen = (targetName) => {
    return this.state[targetName] ? this.state[targetName].tooltipOpen : false;
  };

  componentDidMount() {
    if (this.props.dataItem !== undefined) {
      let passobject = {
        usercode: this.props.dataItem.createby,
      };

      Servicecalls(
        "" + this.ELNurl + "/User/getUserOnCode",
        passobject,
        this.onRepositorySuccess.bind(this),
        this.onRepositoryFailure.bind(this)
      );
      const toDate = new Date();
      const fromDate = new Date(toDate);
      fromDate.setHours(0, 0, 0, 0);
      let passobjecthistory = {
        protocolordercode: this.props.dataItem.protocolordercode,
        fromdate: fromDate,
        todate: toDate,
      };
      Servicecalls(
        "" + this.ELNurl + "/protocol/getProtocolOrderworkflowhistoryList",
        passobjecthistory,
        this.ongetProtocolOrderworkflowhistoryListSuccess.bind(this),
        this.ongetProtocolOrderworkflowhistoryListFailure.bind(this)
      );

      let passobject1 = {
        protocolordercode: this.props.dataItem.protocolordercode,
        fromdate: fromDate,
        todate: toDate,
      };
      Servicecalls(
        "" + this.ELNurl + "/protocol/getprotocolstephistory",
        passobject1,
        this.ongetprotocolstephistorySuccess.bind(this),
        this.ongetProtocolOrderworkflowhistoryListFailure.bind(this)
      );
    }

  }

  ongetprotocolstephistorySuccess(responce) {

    // this.state.protocolorderstephistory.push(responce);
    const arrayToSort = responce.sort((a, b) => b.protocolorderstephistorycode - a.protocolorderstephistorycode);

    this.setState({
      protocolorderstephistory: responce,
      protocolstephistory:
        !this.state.protocolstephistory,
      protocoltransactionhistory: arrayToSort
    });
  }

  toggle = (targetName) => {
    if (!this.state[targetName]) {
      this.setState({
        ...this.state,
        [targetName]: {
          tooltipOpen: true,
        },
      });
    } else {
      this.setState({
        ...this.state,
        [targetName]: {
          tooltipOpen: !this.state[targetName].tooltipOpen,
        },
      });
    }

    this.setState({
      transactionexpanded: false,
      workflowexpanded: false,
    });
  };

  formatworrkflowhistorydetails() {
    var detail = [];

    if (this.state.Data.length !== 0) {
      let d = null;
      let day = null;
      let dayname = null;
      let time = null;
      for (let index = this.state.Data.length - 1; index >= 0; index--) {
        // for (let index = 0; index < this.state.Data.length; index++) {
        let createby = {
          usercode: this.state.Data[index].createby.usercode,
          username: this.state.Data[index].createby.username,
        };

        if (
          this.state.Data[index].createby.usercode !== undefined &&
          this.state.Data[index].createby.username !== null
        ) {
          let localdate = convertUTCDateToLocalDate(
            new Date(this.state.Data[index].createdate)
          );
          d = new Date(localdate);
          day = d.getDate();
          dayname = this.month[d.getMonth()];
          // time = d.getHours() + ":" + d.getMinutes() + ":" + d.getSeconds();
          day = convertdoubledigitdate(day);
          time = convertdoubledigittime(
            d.getHours(),
            d.getMinutes(),
            d.getSeconds()
          );
          let year = d.getFullYear();
          // let gmt =d.toString();
          // gmt=gmt.slice(25,34);

          let imgurl = this.ELNurl + this.imageurl + "-1";
          if (
            this.state.Data[index].createby.usercode !== null &&
            this.state.Data[index].username !== undefined
          ) {
            imgurl =
              this.ELNurl +
              this.imageurl +
              this.state.Data[index].createby.usercode;
          }
          detail.push(
            <li
              key={this.props.dataItem.repositorydatacode + "Trans_" + index}
              className="list-group-item"
              aria-disabled="true"
            >
              <div className="row no-gutters align-items-center">
                <div className="col-2">
                  <div className="calender-list small">
                    <span className="d-flex eln-stamp">{dayname}</span>
                    <span className="d-flex eln-date">{day}</span>
                    <span className="d-flex eln-stamp">{year}</span>
                    <span className="d-flex eln-stamp">{time}</span>
                    {/* <span className="d-flex eln-stamp">{gmt}</span> */}
                  </div>
                </div>
                <div className="col-10 mnuContainer2 userDetails">
                  <Userdetail
                    index={"Trans-" + index}
                    imgurl={imgurl}
                    user={createby}
                  />
                  <p>
                    {this.state.Data[index].action}
                    <div className="userComts">
                      <b>
                        {this.props.t("Orders.Protocolorders.IDS_LBL_COMMENT", { param: "" })} :
                      </b>{" "}
                      {this.state.Data[index].comment}
                    </div>
                  </p>
                </div>
              </div>
            </li>
          );
        }
      }
    } else {
      detail.push(
        <ul className="list-group list-group-flush">
          <li className="list-group-item" aria-disabled="true">
            <p>
              {this.props.t("Orders.Protocolorders.IDS_LBL_NOWORKF", {
                param: "",
              })}
            </p>
          </li>
        </ul>
      );
    }

    let detailsvalue = (
      <ul className="list-group list-group-flush">{detail}</ul>
    );
    return detailsvalue;
  }

  onfindversionclick(e) {
    // if (this.state.versionexpanded) {
    //   this.setState({
    //     versionexpanded: !this.state.versionexpanded,
    //   });
    // } else {
    const toDate = new Date();
    const fromDate = new Date(toDate);
    fromDate.setHours(0, 0, 0, 0);
    let passobject = {
      protocolordercode: this.props.dataItem.protocolordercode,
      fromdate: fromDate,
      todate: toDate
    };
    Servicecalls(
      "" + this.ELNurl + "/protocol/GetProtocolorderVersionDetails",
      passobject,
      this.onGetProtocolVersionDetailsSuccess.bind(this),
      this.onGetProtocolVersionDetailsFailure.bind(this)
    );
    // }
  }
  onGetProtocolVersionDetailsSuccess = (response) => {
    this.setState({
      // versionlist: response.lsprotocolorderversion,
      versiondata: response.lsprotocolorderversion.reverse()
      // versionexpanded: !this.state.versionexpanded,
    });
  };
  onGetProtocolVersionDetailsFailure(res) { }

  formatprotocolorderversiondetails() {
    var detail = [];
    if (this.state.versionlist !== undefined) {
      let d = null;
      let day = null;
      let dayname = null;
      let time = null;

      if (
        this.props.dataItem !== undefined &&
        this.props.dataItem !== null
        // this.props.dataItem.length > 0
      ) {
        for (let index = this.state.versionlist.length - 1; index >= 0; index--) {
          if (
            this.state.versionlist[index].createdbyusername !== undefined &&
            this.state.versionlist[index].createdbyusername !== null
          ) {
            if (this.state.versionlist[index].createdate !== undefined) {
              let localdate = convertUTCDateToLocalDate(
                new Date(this.state.versionlist[index].createdate)
              );
              d = new Date(localdate);
            } else {
              d = new Date();
            }

            day = d.getDate();
            dayname = this.month[d.getMonth()];
            // time = d.getHours() + ":" + d.getMinutes() + ":" + d.getSeconds();
            day = convertdoubledigitdate(day);
            time = convertdoubledigittime(
              d.getHours(),
              d.getMinutes(),
              d.getSeconds()
            );
            let year = d.getFullYear();
            // let gmt =d.toString();
            // gmt=gmt.slice(25,34);

            let imgurl = this.ELNurl + this.imageurl + "-1";
            if (
              this.state.versionlist[index].createdbyusername !== null &&
              this.state.versionlist[index].createdbyusername !== undefined
            ) {
              imgurl =
                this.ELNurl +
                this.imageurl +
                this.state.versionlist[index].createdby;
            }
            detail.push(
              <li
                key={this.props.dataItem.protocolordercode + "Trans_" + index}
                className="list-group-item"
                aria-disabled="true"
              >
                <div className="row no-gutters align-items-center">
                  <div className="col-2">
                    <div className="calender-list">
                      <span className="d-flex eln-stamp">{dayname}</span>
                      <span className="d-flex eln-date">{day}</span>
                      <span className="d-flex eln-stamp">{year}</span>
                      <span className="d-flex eln-stamp ">{time}</span>
                      {/* <span className="d-flex eln-stamp">{gmt}</span> */}
                    </div>
                  </div>
                  <div className="col-10 mnuContainer2 userDetails">
                    <Userdetail
                      index={"Trans-" + index}
                      imgurl={imgurl}
                      user={this.state.versionlist[index].createdby}
                    />

                    <div className="col-12">
                      {this.props.t("Orders.Protocolorders.IDS_LBL_MODIFIEDF", {
                        user2: this.state.versionlist[index].createdbyusername,
                        user1: this.state.versionlist[index].versionno,
                      })}
                    </div>
                  </div>
                </div>
              </li>
            );
          }
          // if (index === 0) {
          //   if (
          //     this.props.dataItem.createby !== undefined &&
          //     this.props.dataItem.createby !== null &&
          //     this.state.transactionhistory !== undefined
          //   ) {
          //     let createby = {
          //       usercode: this.props.dataItem.createby,
          //       username: this.state.transactionhistory.username,
          //     };
          //     let localdate = convertUTCDateToLocalDate(
          //       new Date(this.props.dataItem.createdtimestamp)
          //     );
          //     d = new Date(localdate);
          //     day = d.getDate();
          //     dayname = this.month[d.getMonth()];
          //     // time = d.getHours() + ":" + d.getMinutes() + ":" + d.getSeconds();
          //     day = convertdoubledigitdate(day);
          //     time = convertdoubledigittime(
          //       d.getHours(),
          //       d.getMinutes(),
          //       d.getSeconds()
          //     );
          //     let year = d.getFullYear();
          //     // let gmt =d.toString();
          //     // gmt=gmt.slice(25,34);

          //     let imgurl = this.ELNurl + this.imageurl + "-1";
          //     if (
          //       this.props.dataItem.createby !== null &&
          //       this.state.transactionhistory.username !== undefined
          //     ) {
          //       imgurl =
          //         this.ELNurl + this.imageurl + this.props.dataItem.createby;
          //     }
          //     detail.push(
          //       <li
          //         key={this.props.dataItem.protocolordercode + "Trans_" + 1}
          //         className="list-group-item"
          //         aria-disabled="true"
          //       >
          //         <div className="row no-gutters align-items-center">
          //           <div className="col-2">
          //             <div className="calender-list small">
          //               <span className="d-flex eln-stamp">{dayname}</span>
          //               <span className="d-flex eln-date">{day}</span>
          //               <span className="d-flex eln-stamp">{year}</span>
          //               <span className="d-flex eln-stamp">{time}</span>
          //               {/* <span className="d-flex eln-stamp">{gmt}</span> */}
          //             </div>
          //           </div>
          //           <div className="col-10 mnuContainer2 userDetails">
          //             <Userdetail
          //               index={"Trans-" + 1}
          //               imgurl={imgurl}
          //               user={createby}
          //             />
          //             <p>
          //               {this.props.t("Orders.Protocolorders.IDS_LBL_CREATEDFILE", {
          //                 user2: createby.username,
          //                 user1: this.props.dataItem.protoclordername,
          //               })}
          //             </p>
          //           </div>{" "}
          //         </div>
          //       </li>
          //     );
          //   }
          // }
        }
      } else {
        if (
          this.state.selectProtocolMaster.createdby !== undefined &&
          this.state.selectProtocolMaster.createdby !== null
        ) {
          if (this.state.selectProtocolMaster.createdate !== undefined) {
            let localdate = convertUTCDateToLocalDate(
              new Date(this.state.selectProtocolMaster.createdate)
            );
            d = new Date(localdate);
          } else {
            d = new Date();
          }

          // d = new Date(this.state.selectProtocolMaster.createdate);
          day = d.getDate();
          dayname = this.month[d.getMonth()];
          // time = d.getHours() + ":" + d.getMinutes() + ":" + d.getSeconds();
          day = convertdoubledigitdate(day);
          time = convertdoubledigittime(
            d.getHours(),
            d.getMinutes(),
            d.getSeconds()
          );
          let year = d.getFullYear();
          // let gmt =d.toString();
          // gmt=gmt.slice(25,34);

          let imgurl = this.ELNurl + this.imageurl + "-1";
          if (
            this.state.selectProtocolMaster.createdby !== null &&
            this.state.selectProtocolMaster.createdby !== undefined
          ) {
            imgurl =
              this.ELNurl +
              this.imageurl +
              this.state.selectProtocolMaster.createdby;
          }
          detail.push(
            <li
              key={
                this.state.selectProtocolMaster.protocolmastercode + "Trans_"
              }
              className="list-group-item"
              aria-disabled="true"
            >
              <div className="row no-gutters align-items-center">
                <div className="col-2">
                  <div className="calender-list">
                    <span className="d-flex eln-stamp">{dayname}</span>
                    <span className="d-flex eln-date">{day}</span>
                    <span className="d-flex eln-stamp">{year}</span>
                    <span className="d-flex eln-stamp">{time}</span>
                    {/* <span className="d-flex eln-stamp">{gmt}</span> */}
                  </div>
                </div>
                <div className="col-10 mnuContainer2 userDetails">
                  <Userdetail
                    index={"Trans-"}
                    imgurl={imgurl}
                    user={this.state.selectProtocolMaster.createdby}
                  />

                  <div className="col-12">
                    {this.props.t("Orders.Protocolorders.IDS_LBL_CREATEDFILE", {
                      user2: this.state.selectProtocolMaster.createdbyusername,
                      user1: this.state.selectProtocolMaster.protocolmastername,
                    })}
                  </div>
                </div>
              </div>
            </li>
          );
        }
        //       detail.push(<li className="list-group-item" aria-disabled="true">
        //           {this.props.t("SheetCreation.IDS_NOTVERSIONED", {
        //              param: "",
        //        })}
        //   </li>);
      }
    }

    let detailsvalue = (
      <ul className="list-group list-group-flush">{detail}</ul>
    );
    return detailsvalue;
  }
  formatprotocolorderstepdetatails() {
    var detail = [];
    if (this.state.protocolorderstephistory !== undefined && Object.entries(this.state.protocolorderstephistory).length !== 0) {
      // if (this.state.workflowData !== undefined) {
      for (let index = 0; index < this.state.protocolorderstephistory.length; index++) {

        let localdate = convertUTCDateToLocalDate(
          new Date(
            this.state.protocolorderstephistory[index].stepstartdate !== undefined ? this.state.protocolorderstephistory[index].stepstartdate : this.state.protocolorderstephistory[index].stependdate
          )
        );
        let d = new Date(localdate);

        // let d = new Date(this.state.workflowData[index].createdate);
        let day = d.getDate();
        let dayname = this.month[d.getMonth()];
        // let time = d.getHours() + ":" + d.getMinutes() + ":" + d.getSeconds();
        let time = convertdoubledigittime(
          d.getHours(),
          d.getMinutes(),
          d.getSeconds()
        );
        let year = d.getFullYear();
        let imgurl = this.ELNurl + this.imageurl + "-1";
        if (
          this.state.protocolorderstephistory[index].createby !== null &&
          this.state.protocolorderstephistory[index].createby !== undefined
        ) {
          imgurl =
            this.ELNurl +
            this.imageurl +
            this.state.protocolorderstephistory[index].createby.usercode;
        }
        detail.push(
          <li
            className="list-group-item"
            aria-disabled="true"
            key={
              this.state.protocolorderstephistory[index].protocolorderstephistorycode !== undefined
                ? this.state.protocolorderstephistory[index].protocolorderstephistorycode
                : index
            }
          >
            <div className="row no-gutters align-items-center">
              <div className="col-2">
                <div className="calender-list">
                  <span className="d-flex eln-stamp">{dayname}</span>
                  <span className="d-flex eln-date">{day}</span>
                  <span className="d-flex eln-stamp">{year}</span>
                  <span className="d-flex eln-stamp">{time}</span>
                </div>
              </div>
              <div className="col-10 mnuContainer2 userDetails">

                <Userdetail
                  index={"CreateWKFlow-" + index}
                  imgurl={imgurl}
                  user={this.state.protocolorderstephistory[index].createby}
                />


                <p>
                  {" "}
                  {this.state.protocolorderstephistory[index].action}
                  {/* <div className="userComts">
                    <b>{this.props.t("Orders.Protocolorders.IDS_LBL_COMMENT", { param: "" })} : </b>{" "}
                    {this.state.workflowData[index].comment}
                  </div> */}
                </p>
              </div>
            </div>
          </li>
        );
      }
    } else {
      detail.push(
        <ul className="list-group list-group-flush">
          <li className="list-group-item" aria-disabled="true">
            <p>
              {this.props.t("Orders.Protocolorders.IDS_LBL_NOWORKF", {
                param: "",
              })}
            </p>
          </li>
        </ul>
      );
    }
    let detailsvalue = (
      <ul className="list-group list-group-flush">{detail}</ul>
    );
    return detailsvalue;
  }
  formatprotocolordertransactiondetails() {
    var detail = [];


    if (this.state.protocolorderstephistory !== undefined && Object.entries(this.state.protocolorderstephistory).length !== 0) {
      // if (this.state.workflowData !== undefined) {
      for (let index = 0; index < this.state.protocolorderstephistory.length; index++) {
        // for (let index = this.state.protocolorderstephistory.length - 1; index > 0; index--) {

        let localdate = convertUTCDateToLocalDate(
          new Date(
            this.state.protocolorderstephistory[index].stepstartdate !== undefined ? this.state.protocolorderstephistory[index].stepstartdate : this.state.protocolorderstephistory[index].stependdate
          )
        );
        let d = new Date(localdate);

        // let d = new Date(this.state.workflowData[index].createdate);
        let day = d.getDate();
        let dayname = this.month[d.getMonth()];
        // let time = d.getHours() + ":" + d.getMinutes() + ":" + d.getSeconds();
        let time = convertdoubledigittime(
          d.getHours(),
          d.getMinutes(),
          d.getSeconds()
        );
        let year = d.getFullYear();
        let imgurl = this.ELNurl + this.imageurl + "-1";
        if (
          this.state.protocolorderstephistory[index].createby !== null &&
          this.state.protocolorderstephistory[index].createby !== undefined
        ) {
          imgurl =
            this.ELNurl +
            this.imageurl +
            this.state.protocolorderstephistory[index].createby.usercode;
        }
        detail.push(
          <li
            className="list-group-item"
            aria-disabled="true"
            key={
              this.state.protocolorderstephistory[index].protocolorderstephistorycode !== undefined
                ? this.state.protocolorderstephistory[index].protocolorderstephistorycode
                : index
            }
          >
            <div className="row no-gutters align-items-center">
              <div className="col-2">
                <div className="calender-list">
                  <span className="d-flex eln-stamp">{dayname}</span>
                  <span className="d-flex eln-date">{day}</span>
                  <span className="d-flex eln-stamp">{year}</span>
                  <span className="d-flex eln-stamp">{time}</span>
                </div>
              </div>
              <div className="col-10 mnuContainer2 userDetails">

                <Userdetail
                  index={"CreateWKFlow-" + index}
                  imgurl={imgurl}
                  user={this.state.protocolorderstephistory[index].createby}
                />


                <p>
                  {" "}
                  {this.state.protocolorderstephistory[index].action}

                </p>
              </div>
            </div>
          </li>
        );
      }
    }

    if (this.state.transactionhistory !== "") {
      let d = null;
      let day = null;
      let dayname = null;
      let time = null;

      let createby = {
        usercode: this.state.transactionhistory.usercode,
        username: this.state.transactionhistory.username,
      };

      if (
        this.state.transactionhistory.usercode !== undefined &&
        this.state.transactionhistory.username !== null
      ) {
        let localdate = convertUTCDateToLocalDate(
          new Date(this.props.dataItem.createdtimestamp)
        );
        d = new Date(localdate);
        day = d.getDate();
        dayname = this.month[d.getMonth()];
        // time = d.getHours() + ":" + d.getMinutes() + ":" + d.getSeconds();
        day = convertdoubledigitdate(day);
        time = convertdoubledigittime(
          d.getHours(),
          d.getMinutes(),
          d.getSeconds()
        );
        let year = d.getFullYear();
        // let gmt =d.toString();
        // gmt=gmt.slice(25,34);

        let imgurl = this.ELNurl + this.imageurl + "-1";
        if (
          this.state.transactionhistory.usercode !== null &&
          this.state.transactionhistory.username !== undefined
        ) {
          imgurl =
            this.ELNurl +
            this.imageurl +
            this.state.transactionhistory.usercode;
        }
        detail.push(
          <li
            key={this.props.dataItem.protocolordercode + "Trans_" + 1}
            className="list-group-item"
            aria-disabled="true"
          >
            <div className="row no-gutters align-items-center">
              <div className="col-2">
                <div className="calender-list small">
                  <span className="d-flex eln-stamp">{dayname}</span>
                  <span className="d-flex eln-date">{day}</span>
                  <span className="d-flex eln-stamp">{year}</span>
                  <span className="d-flex eln-stamp">{time}</span>
                  {/* <span className="d-flex eln-stamp">{gmt}</span> */}
                </div>
              </div>
              <div className="col-10 mnuContainer2 userDetails">
                <Userdetail
                  index={"Trans-" + 1}
                  imgurl={imgurl}
                  user={createby}
                />
                <p>
                  {this.props.t("Orders.Protocolorders.IDS_LBL_CREATEDFILE", {
                    user2: createby.username,
                    user1: this.props.dataItem.protoclordername,
                  })}
                </p>
              </div>{" "}
            </div>
          </li>
        );
      }
    } else {
      detail.push(
        <ul className="list-group list-group-flush">
          <li className="list-group-item" aria-disabled="true">
            <p>
              {this.props.t("Masters.Inventory.IDS_LBL_NOINVENTRYDETAAILS", {
                param: "",
              })}
            </p>
          </li>
        </ul>
      );
    }

    let detailsvalue = (
      <ul className="list-group list-group-flush">{detail}</ul>
    );
    return detailsvalue;
  }

  async onfinddataworkflowclick(e) {
    const toDate = new Date();
    const fromDate = new Date(toDate);
    fromDate.setHours(0, 0, 0, 0);
    let passobject = {
      protocolordercode: this.props.dataItem.protocolordercode,
      fromdate: fromDate,
      todate: toDate,
    };
    Servicecalls(
      "" + this.ELNurl + "/protocol/getProtocolOrderworkflowhistoryList",
      passobject,
      this.ongetProtocolOrderworkflowhistoryListSuccess.bind(this),
      this.ongetProtocolOrderworkflowhistoryListFailure.bind(this)
    );
  }

  ongetProtocolOrderworkflowhistoryListSuccess(response) {
    this.setState({
      Data: response.sort((a, b) => b.createdate - a.createdate)
      // workflowexpanded: !this.state.workflowexpanded
      // workflowehistoryxpanded: !this.state.workflowehistoryxpanded,
    });
  }

  ongetProtocolOrderworkflowhistoryListFailure(res) { }

  async onfinddataclick(e) {

    let passObjDetAudit = store.getState().Loggeduser;

    if (this.props.dataItem.filefor === "EPO") {
      passObjDetAudit.objsilentaudit = Silentauditobject(
        "view",
        "Protocol Orders",
        this.props.t("Orders.Protocolorders.IDS_AUDIT_TRANSHISTORYPROTOCOL", {
          screen: orderTypeforprotocol(this.props.dataItem.protocoltype),
          orderid: this.props.dataItem.protoclordername,
          user: store.getState().Loggeduser.username,
          screenName: "Pending work"

        })
      );
    }
    else if (this.props.dataItem.filefor === "ECO") {
      passObjDetAudit.objsilentaudit = Silentauditobject(
        "view",
        "Protocol Orders",
        this.props.t("Orders.Protocolorders.IDS_AUDIT_TRANSHISTORYPROTOCOL", {
          screen: orderTypeforprotocol(this.props.dataItem.protocoltype),
          orderid: this.props.dataItem.protoclordername,
          user: store.getState().Loggeduser.username,
          screenName: "Completed work"

        })
      );
    }
    Servicecalls(
      "" + this.ELNurl + "/AuditTrail/silentandmanualRecordHandler",
      passObjDetAudit,
      (res) => { },
      (res) => { }
    );

    // if (this.state.inventryexpand && this.state.inventryhistoryexpand) {
    //   this.setState({
    //     inventryexpand: !this.state.inventryexpand,
    //     inventryhistoryexpand: !this.state.inventryhistoryexpand,
    //   });
    // } else {
    let passobject = {
      usercode: this.props.dataItem.createby !== undefined ? this.props.dataItem.createby : sessionStorage.User,
    };

    Servicecalls(
      "" + this.ELNurl + "/User/getUserOnCode",
      passobject,
      this.onRepositorySuccess.bind(this),
      this.onRepositoryFailure.bind(this)
    );

    // this.setState({
    //   inventryexpand: !this.state.inventryexpand
    // inventryhistoryexpand: !this.state.inventryhistoryexpand,
    // });
    // }
  }
  onRepositoryFailure(res) { }
  onRepositorySuccess(response) {
    this.setState({
      transactionhistory: response,
      inventryexpand: !this.state.inventryexpand
      // inventryhistoryexpand: !this.state.inventryhistoryexpand,
    });
  }

  onClose(e) {
    this.setState({ protocolView: false })

    let ordertypename = this.props.dataItem.protocoltype !== undefined ? this.props.dataItem.protocoltype === 1 ? "ELN Protocol order" :
      this.props.dataItem.protocoltype === 2 ? "Dynamic Protocol order" : "" : ""
    this.setState({ ordertypename: ordertypename })

  }
  handleclickevent = (event) => {
    if (this.state.clickTimer === null) {
      const timer = window.setTimeout(() => {
        this.setState({ protocolView: true }); this.onfinddataclick(); this.onfinddataworkflowclick(); this.onfindversionclick()
        console.log("single clicked");
        this.setState({ clickTimer: null });
      }, 250);
      this.setState({ clickTimer: timer });
    } else {
      window.clearTimeout(this.state.clickTimer);
      this.setState({ sheetView: false, clickTimer: null });
    }
  }

  handleChange = (event) => {
    this.TemplateHistroyService(event);
  };

  handleTabClick = (tab) => {
    this.setState({
      activeTab: tab
    })
  };

  TemplateHistroyService = (event, fdate, tdate) => {
    let id = event.id;
    let passObjDet = {
      protocolordercode: this.props.dataItem.protocolordercode,
    };

    if (id === 1) {
      const toDate = new Date();
      const fromDate = new Date(toDate);
      fromDate.setHours(0, 0, 0, 0);
      passObjDet.fromdate = fromDate;
      passObjDet.todate = toDateFilter(toDate);
    } else if (id === 2) {
      const toDate = new Date();
      const fromDate = new Date(toDate);
      fromDate.setDate(fromDate.getDate() - 7);
      fromDate.setHours(0, 0, 0, 0);
      passObjDet.fromdate = fromDate;
      passObjDet.todate = toDateFilter(toDate);
    } else if (id === 3) {
      const toDate = new Date();
      const fromDate = new Date(toDate);
      fromDate.setDate(fromDate.getDate() - 30);
      fromDate.setHours(0, 0, 0, 0);
      passObjDet.fromdate = fromDate;
      passObjDet.todate = toDateFilter(toDate);
    } else if (id === 4) {
      if (fdate && tdate) {
        const toDate = tdate;
        toDate.setHours(new Date().getHours());
        const fromDate = fdate;
        passObjDet.fromdate = fromDate;
        passObjDet.todate = toDateFilter(toDate);
      } else {
        const toDate = new Date();
        const fromDate = new Date(toDate);
        fromDate.setDate(fromDate.getDate() - 7);
        fromDate.setHours(0, 0, 0, 0);
        passObjDet.fromdate = fromDate;
        passObjDet.todate = toDateFilter(toDate);


        let KendoDateFrom =
          (parseInt(fromDate.getMonth()) + 1).toString() +
          "/" +
          fromDate.getDate().toString() +
          "/" +
          fromDate.getFullYear().toString();
        let KendoDateTo =
          (parseInt(toDate.getMonth()) + 1).toString() +
          "/" +
          toDate.getDate().toString() +
          "/" +
          toDate.getFullYear().toString();
        this.setState({
          OnKendoDateFrom: KendoDateFrom,
          OnKendoDateTo: KendoDateTo,
        })
      }
    }

    Servicecalls(
      "" + this.ELNurl + "/protocol/getProtocolOrderworkflowhistoryList",
      passObjDet,
      (response) => {
        this.setState({
          selecteddatevalue: event,
          Data: response.sort((a, b) => a.historycode - b.historycode)
        });
      },
      (res) => { }
    );

    Servicecalls(
      "" + this.ELNurl + "/protocol/GetProtocolorderVersionDetails",
      passObjDet,
      (response) => {
        this.setState({
          selecteddatevalue: event,
          // versionlist: response.lsprotocolorderversion,
          versiondata: response.lsprotocolorderversion.reverse(),

        });
      },
      (res) => { }
    );

    Servicecalls(
      "" + this.ELNurl + "/protocol/getprotocolstephistory",
      passObjDet,
      (responce) => {
        const arrayToSort = responce.sort((a, b) => b.protocolorderstephistorycode - a.protocolorderstephistorycode);
        this.setState({
          protocolorderstephistory: responce,
          protocoltransactionhistory: arrayToSort
        });
      },
      (res) => { }

    );


  };

  OnHistoryfromDatachange = (DateValue) => {
    let FilterData = this.state.FilterRecord;

    let KendoDateFrom =
      (parseInt(new Date(DateValue).getMonth()) + 1).toString() +
      "/" +
      new Date(DateValue).getDate().toString() +
      "/" +
      new Date(DateValue).getFullYear().toString();

    this.setState({
      fdate: DateValue,
      FilterRecord: FilterData,
      OnKendoDateFrom: KendoDateFrom,
    });

    this.TemplateHistroyService(
      {
        id: 4,
        type: "Custom Date",
      },
      DateValue,
      new Date(this.state.OnKendoDateTo)
    );
  };
  OnHistorytoDatachange = (DateValue) => {
    let FilterData = this.state.FilterRecord;
    let KendoDateTo =
      (parseInt(new Date(DateValue).getMonth()) + 1).toString() +
      "/" +
      new Date(DateValue).getDate().toString() +
      "/" +
      new Date(DateValue).getFullYear().toString();

    this.setState({
      tdate: DateValue,
      FilterRecord: FilterData,
      OnKendoDateTo: KendoDateTo,
    });
    this.TemplateHistroyService(
      {
        id: 4,
        type: "Custom Date",
      },
      new Date(this.state.OnKendoDateFrom),
      DateValue
    );
  };

  ondatefilterreset = () => {
    const toDate = new Date();
    const fromDate = new Date(toDate);
    fromDate.setDate(fromDate.getDate() - 7);
    fromDate.setHours(0, 0, 0, 0);
    let KendoDateFrom =
      (parseInt(fromDate.getMonth()) + 1).toString() +
      "/" +
      fromDate.getDate().toString() +
      "/" +
      fromDate.getFullYear().toString();
    let KendoDateTo =
      (parseInt(toDate.getMonth()) + 1).toString() +
      "/" +
      toDate.getDate().toString() +
      "/" +
      toDate.getFullYear().toString();

    this.TemplateHistroyService({
      id: 4,
      type: "Custom Date",
    });

    this.setState({
      OnKendoDateFrom: KendoDateFrom,
      OnKendoDateTo: KendoDateTo,
    });
  };

  onClose = () => {
    this.setState({ protocolView: false });
  };
  render() {
    return (
      this.props.dataItem[this.props.keyfield] !== undefined ? (
        <React.Fragment>

          <button
            id={`user-${this.props.dataItem[this.props.keyfield]}`}
            className="grid-icons"
            alt="user"
            data-placement="top"
            title="Transaction"
            onClick={this.handleclickevent.bind(this)}
          // onClick={() => { this.setState({ protocolView: true }); this.onfinddataclick(); this.onfinddataworkflowclick(); this.onfindversionclick() }}
          >
            <i className="far fa-eye protocol-eye"></i>
          </button>

          {this.state.protocolView ? (
            <ProtocolHistory
              screentype={2}
              screen={"protocolordereyeview"}
              versioncolumn={this.state.Versioncolumns}
              transactionscolumn={this.state.transactionscolumn}
              isexpand={this.state.protocolView}
              state={this.state}
              props={this.props}
              getThis={() => this}
              onClose={this.onClose.bind(this)}
              handleChange={this.handleChange.bind(this)}
              handleTabClick={this.handleTabClick.bind(this)}
              OnHistoryfromDatachange={this.OnHistoryfromDatachange.bind(this)}
              OnHistorytoDatachange={this.OnHistorytoDatachange.bind(this)}
              ondatefilterreset={this.ondatefilterreset.bind(this)}
            />
          ) : (
            ""
          )}


        </React.Fragment>
      ) : (
        ""
      )
    );
  }
}
const Customprotocolordercells = withTranslation(Customprotocolorder);

class Customprotocol extends React.Component {


  constructor(props) {
    super(props);
    let filterdate = new Date();
    this.state = {
      Data: [],
      inventryexpand: true,
      workflowexpanded: true,
      workflowehistoryxpanded: false,
      inventryhistoryexpand: false,
      verexpanded: false,
      versionexpanded: true,
      userMasterScreen: true,
      ServiceToUrl: "",
      ServiceMethodType: "POST",
      ServiceHeaderobject: {},
      ServcieonSuccess: undefined,
      ServcieonFailure: undefined,
      columns: [
        {
          header: "Version History",
          datatype: "protocolversioncode",
          rowselector: false,
          visible: false,
          doFilter: true,
          sortable: false,
          resizable: false,
          celltype: "formatversiondetails",
          className: "simple-grid-view",
          width: "100px"
        }
      ],
      transactionscolumn: [
        {
          header: "Transaction History",
          datatype: "protocolmastercode",
          rowselector: false,
          visible: false,
          doFilter: true,
          sortable: false,
          resizable: false,
          celltype: "formattransactiondetails",
          className: "simple-grid-view",
          width: "100px"
        },
      ],
      OnKendoDateFrom: new Date(filterdate.setDate(filterdate.getDate() - 7)),
      OnKendoDateTo: new Date(),
      transactionhistory: undefined
    };

    this.ELNurl = document.getElementById("ELNname").value;

    this.imageurl = "";
    if (isMultitenant === 1) {
      this.imageurl = "/User/Cloudprofile/";
    } else {
      this.imageurl = "/User/profile/";
    }

    this.month = [
      this.props.t("months.IDS_LBL_JAN", { param: "" }),
      this.props.t("months.IDS_LBL_FEB", { param: "" }),
      this.props.t("months.IDS_LBL_MAR", { param: "" }),
      this.props.t("months.IDS_LBL_APR", { param: "" }),
      this.props.t("months.IDS_LBL_MAY", { param: "" }),
      this.props.t("months.IDS_LBL_JUN", { param: "" }),
      this.props.t("months.IDS_LBL_JUL", { param: "" }),
      this.props.t("months.IDS_LBL_AUG", { param: "" }),
      this.props.t("months.IDS_LBL_SEP", { param: "" }),
      this.props.t("months.IDS_LBL_OCT", { param: "" }),
      this.props.t("months.IDS_LBL_NOV", { param: "" }),
      this.props.t("months.IDS_LBL_DEC", { param: "" }),
    ];
  }
  isToolTipOpen = (targetName) => {
    return this.state[targetName] ? this.state[targetName].tooltipOpen : false;
  };
  toggle = (targetName) => {
    if (!this.state[targetName]) {
      this.setState({
        ...this.state,
        [targetName]: {
          tooltipOpen: true,
        },
      });
    } else {
      this.setState({
        ...this.state,
        [targetName]: {
          tooltipOpen: !this.state[targetName].tooltipOpen,
        },
      });
    }

    this.setState({
      transactionexpanded: false,
      workflowexpanded: false,
    });
  };

  formatProtocolTemplateWorkflowHistoryDetails() {
    var detail = [];

    if (this.state.Data.length !== 0) {
      let d = null;
      let day = null;
      let dayname = null;
      let time = null;

      for (let index = this.state.Data.length - 1;
        index >= 0;
        index--) {
        let createby = {
          usercode: this.state.Data[index].createby.usercode,
          username: this.state.Data[index].createby.username,
        };

        if (
          this.state.Data[index].createby.usercode !== undefined &&
          this.state.Data[index].createby.username !== null
        ) {
          let localdate = convertUTCDateToLocalDate(
            new Date(this.state.Data[index].createdate)
          );
          d = new Date(localdate);
          day = d.getDate();
          dayname = this.month[d.getMonth()];
          // time = d.getHours() + ":" + d.getMinutes() + ":" + d.getSeconds();
          day = convertdoubledigitdate(day);
          time = convertdoubledigittime(
            d.getHours(),
            d.getMinutes(),
            d.getSeconds()
          );
          let year = d.getFullYear();
          // let gmt =d.toString();
          // gmt=gmt.slice(25,34);

          let imgurl = this.ELNurl + this.imageurl + "-1";
          if (
            this.state.Data[index].createby.usercode !== null &&
            this.state.Data[index].username !== undefined
          ) {
            imgurl =
              this.ELNurl +
              this.imageurl +
              this.state.Data[index].createby.usercode;
          }
          detail.push(
            <li
              key={this.props.dataItem.protocolmastercode + "Trans_" + index}
              className="list-group-item"
              aria-disabled="true"
            >
              <div className="row no-gutters align-items-center">
                <div className="col-2">
                  <div className="calender-list small">
                    <span className="d-flex eln-stamp">{dayname}</span>
                    <span className="d-flex eln-date">{day}</span>
                    <span className="d-flex eln-stamp">{year}</span>
                    <span className="d-flex eln-stamp">{time}</span>
                    {/* <span className="d-flex eln-stamp">{gmt}</span> */}
                  </div>
                </div>
                <div className="col-10 mnuContainer2 userDetails">
                  <Userdetail
                    index={"Trans-" + index}
                    imgurl={imgurl}
                    user={createby}
                  />
                  <p>
                    {this.state.Data[index].action}
                    <div className="userComts">
                      <b>
                        {this.props.t("Orders.Protocolorders.IDS_LBL_COMMENT", { param: "" })} :
                      </b>{" "}
                      {this.state.Data[index].comment}
                    </div>
                  </p>
                </div>
              </div>
            </li>
          );
        }
      }
    } else {
      detail.push(
        <ul className="list-group list-group-flush">
          <li className="list-group-item" aria-disabled="true">
            <p>
              {this.props.t("Orders.Protocolorders.IDS_LBL_NOWORKF", {
                param: "",
              })}
            </p>
          </li>
        </ul>
      );
    }

    let detailsvalue = (
      <ul className="list-group list-group-flush">{detail}</ul>
    );
    return detailsvalue;
  }
  onCloseinfo() {
    this.setState({ customProtocol: false })
    // let passObjDet = {
    //   objsilentaudit: Silentauditobject(
    //     "close",
    //     "Protocol Templates",
    //     this.props.t("Templates.Protocoltemplates.IDS_AUDIT_PROTOCOLINFOCLOSED", {
    //       user1: this.props.dataItem.protocolmastername,
    //       user: store.getState().Loggeduser.username,
    //     })

    //   ),
    // };
    // Servicecalls(
    //   "" + this.ELNurl + "/AuditTrail/silentandmanualRecordHandler",
    //   passObjDet,
    //   (res) => { },
    //   (res) => { }
    // );

  }
  formatProtocolTemplateVersionDetails() {
    var detail = [];
    if (this.state.selectProtocolMaster !== undefined) {
      let d = null;
      let day = null;
      let dayname = null;
      let time = null;

      if (
        this.state.selectProtocolMaster !== undefined &&
        this.state.selectProtocolMaster !== null &&
        this.state.selectProtocolMaster.length > 0
      ) {
        for (
          let index = this.state.selectProtocolMaster.length - 1;
          index >= 0;
          index--
        ) {
          if (
            this.state.selectProtocolMaster[index].createdbyusername !== undefined &&
            this.state.selectProtocolMaster[index].createdbyusername !== null &&
            this.state.selectProtocolMaster[index].versionno !== 1
          ) {
            if (
              this.state.selectProtocolMaster[index].createdate !== undefined
            ) {
              let localdate = convertUTCDateToLocalDate(
                new Date(this.state.selectProtocolMaster[index].createdate)
              );
              d = new Date(localdate);
            } else {
              d = new Date();
            }

            day = d.getDate();
            dayname = this.month[d.getMonth()];
            // time = d.getHours() + ":" + d.getMinutes() + ":" + d.getSeconds();
            day = convertdoubledigitdate(day);
            time = convertdoubledigittime(
              d.getHours(),
              d.getMinutes(),
              d.getSeconds()
            );
            let year = d.getFullYear();
            // let gmt =d.toString();
            // gmt=gmt.slice(25,34);

            let imgurl = this.ELNurl + this.imageurl + "-1";
            if (
              this.state.selectProtocolMaster[index].createdbyusername !==
              null &&
              this.state.selectProtocolMaster[index].createdbyusername !==
              undefined
            ) {
              imgurl =
                this.ELNurl +
                this.imageurl +
                this.state.selectProtocolMaster[index].createdby;
            }
            detail.push(
              <li
                key={
                  this.state.selectProtocolMaster.protocolmastercode +
                  "Trans_" +
                  index
                }
                className="list-group-item"
                aria-disabled="true"
              >
                <div className="row no-gutters align-items-center">
                  <div className="col-2">
                    <div className="calender-list">
                      <span className="d-flex eln-stamp">{dayname}</span>
                      <span className="d-flex eln-date">{day}</span>
                      <span className="d-flex eln-stamp">{year}</span>
                      <span className="d-flex eln-stamp">{time}</span>
                      {/* <span className="d-flex eln-stamp">{gmt}</span> */}
                    </div>
                  </div>
                  <div className="col-10 mnuContainer2 userDetails">
                    <Userdetail
                      index={"Trans-" + index}
                      imgurl={imgurl}
                      user={this.state.selectProtocolMaster[index].createdby}
                    />

                    <div className="col-12">
                      {this.props.t("Orders.Protocolorders.IDS_LBL_MODIFIEDF", {
                        user2:
                          this.state.selectProtocolMaster[index]
                            .createdbyusername,
                        user1:
                          this.state.selectProtocolMaster[index].versionname,
                      })}
                    </div>
                  </div>
                </div>
              </li>
            );
          }
          if (index === 0) {
            if (
              this.props.dataItem.createdby !== undefined &&
              this.props.dataItem.createdby !== null
            ) {
              let createby = {
                usercode: this.props.dataItem.usercode,
                username: this.props.dataItem.username,
              };
              let localdate = convertUTCDateToLocalDate(
                new Date(this.props.dataItem.createdate)
              );
              d = new Date(localdate);
              day = d.getDate();
              dayname = this.month[d.getMonth()];
              // time = d.getHours() + ":" + d.getMinutes() + ":" + d.getSeconds();
              day = convertdoubledigitdate(day);
              time = convertdoubledigittime(d.getHours(), d.getMinutes(), d.getSeconds());
              let year = d.getFullYear();
              // let gmt =d.toString();
              // gmt=gmt.slice(25,34);

              let imgurl = this.ELNurl + this.imageurl + "-1";
              if (
                this.state.transactionhistory.usercode !== null &&
                this.state.transactionhistory.username !== undefined
              ) {
                imgurl =
                  this.ELNurl +
                  this.imageurl +
                  this.state.transactionhistory.usercode;
              }
              detail.push(
                <li
                  key={this.props.dataItem.protocolmastercode + "Trans_" + 1}
                  className="list-group-item"
                  aria-disabled="true"
                >
                  <div className="row no-gutters align-items-center">
                    <div className="col-2">
                      <div className="calender-list small">
                        <span className="d-flex eln-stamp">{dayname}</span>
                        <span className="d-flex eln-date">{day}</span>
                        <span className="d-flex eln-stamp">{year}</span>
                        <span className="d-flex eln-stamp">{time}</span>
                        {/* <span className="d-flex eln-stamp">{gmt}</span> */}
                      </div>
                    </div>
                    <div className="col-10 mnuContainer2 userDetails">
                      <Userdetail
                        index={"Trans-" + 1}
                        imgurl={imgurl}
                        user={createby}
                      />
                      <p>
                        {this.props.t("Orders.Protocolorders.IDS_LBL_CREATEDFILE", {
                          user2: this.props.dataItem.createdbyusername,
                          user1: this.props.dataItem.protocolmastername,
                        })}
                      </p>
                    </div>{" "}
                  </div>
                </li>
              );
            }
          }

        }
      } else {
        if (
          this.state.selectProtocolMaster.createdby !== undefined &&
          this.state.selectProtocolMaster.createdby !== null
        ) {
          if (this.state.selectProtocolMaster.createdate !== undefined) {
            let localdate = convertUTCDateToLocalDate(
              new Date(this.state.selectProtocolMaster.createdate)
            );
            d = new Date(localdate);
          } else {
            d = new Date();
          }

          // d = new Date(this.state.selectProtocolMaster.createdate);
          day = d.getDate();
          dayname = this.month[d.getMonth()];
          // time = d.getHours() + ":" + d.getMinutes() + ":" + d.getSeconds();
          day = convertdoubledigitdate(day);
          time = convertdoubledigittime(
            d.getHours(),
            d.getMinutes(),
            d.getSeconds()
          );
          let year = d.getFullYear();
          // let gmt =d.toString();
          // gmt=gmt.slice(25,34);

          let imgurl = this.ELNurl + this.imageurl + "-1";
          if (
            this.state.selectProtocolMaster.createdby !== null &&
            this.state.selectProtocolMaster.createdby !== undefined
          ) {
            imgurl =
              this.ELNurl +
              this.imageurl +
              this.state.selectProtocolMaster.createdby;
          }
          detail.push(
            <li
              key={
                this.state.selectProtocolMaster.protocolmastercode + "Trans_"
              }
              className="list-group-item"
              aria-disabled="true"
            >
              <div className="row no-gutters align-items-center">
                <div className="col-2">
                  <div className="calender-list">
                    <span className="d-flex eln-stamp">{dayname}</span>
                    <span className="d-flex eln-date">{day}</span>
                    <span className="d-flex eln-stamp">{year}</span>
                    <span className="d-flex eln-stamp">{time}</span>
                    {/* <span className="d-flex eln-stamp">{gmt}</span> */}
                  </div>
                </div>
                <div className="col-10 mnuContainer2 userDetails">
                  <Userdetail
                    index={"Trans-"}
                    imgurl={imgurl}
                    user={this.state.selectProtocolMaster.createdby}
                  />

                  <div className="col-12">
                    {this.props.t("Orders.Protocolorders.IDS_LBL_CREATEDFILE", {
                      user2: this.state.selectProtocolMaster.createdbyusername,
                      user1: this.state.selectProtocolMaster.protocolmastername,
                    })}
                  </div>
                </div>
              </div>
            </li>
          );
        }
        //       detail.push(<li className="list-group-item" aria-disabled="true">
        //           {this.props.t("SheetCreation.IDS_NOTVERSIONED", {
        //              param: "",
        //        })}
        //   </li>);
      }
    }

    let detailsvalue = (
      <ul className="list-group list-group-flush">{detail}</ul>
    );
    return detailsvalue;
  }

  formatProtocolTemplateTransactionDetails() {
    var detail = [];

    if (this.props.dataItem !== "") {
      let d = null;
      let day = null;
      let dayname = null;
      let time = null;

      if (
        this.state.selectProtocolMaster !== undefined &&
        this.state.selectProtocolMaster !== null
      ) {
        if (
          this.state.selectProtocolMaster.modifiedlist !== undefined &&
          this.state.selectProtocolMaster.modifiedlist !== null
        ) {
          for (
            let index = this.state.selectProtocolMaster.modifiedlist.length - 1;
            index >= 0;
            index--
          ) {
            if (
              this.state.selectProtocolMaster[0].createdate !== undefined &&
              this.state.selectProtocolMaster[0].createdate !== null
            ) {

              let localdate = convertUTCDateToLocalDate(
                new Date(this.state.selectProtocolMaster.modifiedlist[index].protocolmodifiedDate)
              );
              d = new Date(localdate);
              // d = new Date(
              //   this.state.selectProtocolMaster.modifiedlist[
              //     index
              //   ].protocolmodifiedDate
              // );
              day = d.getDate();
              dayname = this.month[d.getMonth()];
              // time = d.getHours() + ":" + d.getMinutes() + ":" + d.getSeconds();
              day = convertdoubledigitdate(day);
              time = convertdoubledigittime(d.getHours(), d.getMinutes(), d.getSeconds());
              let year = d.getFullYear();
              let imgurl = this.ELNurl + this.imageurl + "-1";
              if (
                this.state.selectProtocolMaster[0].createdate !== null &&
                this.state.selectProtocolMaster[0].createdate !== undefined
              ) {
                imgurl =
                  this.ELNurl +
                  this.imageurl +
                  this.state.selectProtocolMaster.modifiedlist[index].modifiedby
                    .usercode;
              }
              detail.push(
                <li
                  key={
                    this.state.selectProtocolMaster[0].protocolmastercode +
                    "TransDetails_" +
                    index
                  }
                  className="list-group-item"
                  aria-disabled="true"
                >
                  <div className="row no-gutters align-items-center">
                    <div className="col-2">
                      <div className="calender-list">
                        <span className="d-flex eln-stamp">{dayname}</span>
                        <span className="d-flex eln-date">{day}</span>
                        <span className="d-flex eln-stamp">{year}</span>
                        <span className="d-flex eln-stamp">{time}</span>
                      </div>
                    </div>
                    <div className="col-10 mnuContainer2 userDetails">
                      <Suspense fallback={<div></div>}>
                        <Userdetail
                          index={"Trans2-" + index}
                          imgurl={imgurl}
                          user={
                            this.state.selectProtocolMaster.modifiedlist[index]
                              .modifiedby
                          }
                        />
                      </Suspense>

                      <div class="container my-4">
                        {
                          this.state.selectProtocolMaster.modifiedlist[index]
                            .protocolcomment
                        }
                      </div>
                    </div>
                  </div>
                </li>
              );
            }
          }
        }
      }
      let createby = {
        usercode: this.state.transactionhistory.usercode,
        username: this.state.transactionhistory.username,
      };

      if (
        this.state.transactionhistory.usercode !== undefined &&
        this.state.transactionhistory.username !== null
      ) {
        let localdate = convertUTCDateToLocalDate(
          new Date(this.props.dataItem.createdate)
        );
        d = new Date(localdate);
        day = d.getDate();
        dayname = this.month[d.getMonth()];
        // time = d.getHours() + ":" + d.getMinutes() + ":" + d.getSeconds();
        day = convertdoubledigitdate(day);
        time = convertdoubledigittime(
          d.getHours(),
          d.getMinutes(),
          d.getSeconds()
        );
        let year = d.getFullYear();
        // let gmt =d.toString();
        // gmt=gmt.slice(25,34);

        let imgurl = this.ELNurl + this.imageurl + "-1";
        if (
          this.state.transactionhistory.usercode !== null &&
          this.state.transactionhistory.username !== undefined
        ) {
          imgurl =
            this.ELNurl +
            this.imageurl +
            this.state.transactionhistory.usercode;
        }
        detail.push(
          <li
            key={this.props.dataItem.protocolmastercode + "Trans_" + 1}
            className="list-group-item"
            aria-disabled="true"
          >
            <div className="row no-gutters align-items-center">
              <div className="col-2">
                <div className="calender-list small">
                  <span className="d-flex eln-stamp">{dayname}</span>
                  <span className="d-flex eln-date">{day}</span>
                  <span className="d-flex eln-stamp">{year}</span>
                  <span className="d-flex eln-stamp">{time}</span>
                  {/* <span className="d-flex eln-stamp">{gmt}</span> */}
                </div>
              </div>
              <div className="col-10 mnuContainer2 userDetails">
                <Userdetail
                  index={"Trans-" + 1}
                  imgurl={imgurl}
                  user={createby}
                />
                <p>
                  {this.props.t("Templates.Protocoltemplates.IDS_LBL_CREATEDF", {
                    user2: this.props.dataItem.protocolmastername,
                    user1: this.props.dataItem.createdbyusername,
                  })}
                </p>
              </div>{" "}
            </div>
          </li>
        );
      }
    } else {
      detail.push(
        <ul className="list-group list-group-flush">
          <li className="list-group-item" aria-disabled="true">
            <p>
              {this.props.t("Masters.Inventory.IDS_LBL_NOINVENTRYDETAAILS", {
                param: "",
              })
              }

            </p>
          </li>
        </ul>
      );
    }

    let detailsvalue = (
      <ul className="list-group list-group-flush">{detail}</ul>
    );
    return detailsvalue;
  }



  ongetProtocolOrderworkflowhistoryListSuccess(response) {
    this.setState({
      Data: response.lsprotocolworkflowhistory
      // workflowexpanded: !this.state.workflowexpanded,
      // workflowehistoryxpanded: !this.state.workflowehistoryxpanded,
    });
  }

  ongetProtocolOrderworkflowhistoryListSuccess1(response) {
    let addedprotocol = response.lsprotocolversion;
    addedprotocol.createby = response.createby;
    addedprotocol.lsprotocolworkflowhistory = response.lsprotocolworkflowhistory;
    addedprotocol.modifiedlist = response.modifiedlist;
    this.setState({
      selectProtocolMaster: addedprotocol,
      //   // verexpanded: !this.state.verexpanded,
      //   // versionexpanded: !this.state.versionexpanded,
    });
  }
  ongetProtocolOrderworkflowhistoryListFailure1(res) { }

  ongetProtocolOrderworkflowhistoryListFailure(res) { }


  onRepositoryFailure(res) { }
  onRepositorySuccess(response) {
    this.setState({
      transactionhistory: response
    });
  }
  OnClickHistory() {
    this.setState({ customProtocol: true });
    this.TemplateHistroyService({
      id: 1,
      type: "Current Date"
    });
  }
  OnHistoryfromDatachange(DateValue) {

    let FilterData = this.state.FilterRecord;

    let KendoDateFrom =
      (parseInt(new Date(DateValue).getMonth()) + 1).toString() +
      "/" +
      new Date(DateValue).getDate().toString() +
      "/" +
      new Date(DateValue).getFullYear().toString();

    this.setState({
      fdate: DateValue,
      FilterRecord: FilterData,
      OnKendoDateFrom: KendoDateFrom,
    });

    this.TemplateHistroyService({
      id: 4,
      type: "Custom Date"
    }, DateValue, new Date(this.state.OnKendoDateTo));
  }
  OnHistorytoDatachange(DateValue) {

    let FilterData = this.state.FilterRecord;
    let KendoDateTo =
      (parseInt(new Date(DateValue).getMonth()) + 1).toString() +
      "/" +
      new Date(DateValue).getDate().toString() +
      "/" +
      new Date(DateValue).getFullYear().toString();

    this.setState({
      tdate: DateValue,
      FilterRecord: FilterData,
      OnKendoDateTo: KendoDateTo,
    });
    this.TemplateHistroyService({
      id: 4,
      type: "Custom Date"
    }, new Date(this.state.OnKendoDateFrom), DateValue);
  }

  ondatefilterreset = () => {
    const toDate = new Date();
    const fromDate = new Date(toDate);
    fromDate.setDate(fromDate.getDate() - 7);
    fromDate.setHours(0, 0, 0, 0)
    let KendoDateFrom =
      (parseInt(fromDate.getMonth()) + 1).toString() +
      "/" +
      fromDate.getDate().toString() +
      "/" +
      fromDate.getFullYear().toString();
    let KendoDateTo =
      (parseInt(toDate.getMonth()) + 1).toString() +
      "/" +
      toDate.getDate().toString() +
      "/" +
      toDate.getFullYear().toString();

    this.TemplateHistroyService({
      id: 4,
      type: "Custom Date"
    });

    this.setState({
      OnKendoDateFrom: KendoDateFrom,
      OnKendoDateTo: KendoDateTo,
    })
  }
  TemplateHistroyService(event, fdate, tdate) {
    let id = event.id
    let passObjDet = {
      protocolmastercode: this.props.dataItem.protocolmastercode
    };

    if (id === 1) {
      const toDate = new Date();
      const fromDate = new Date(toDate);
      fromDate.setHours(0, 0, 0, 0)
      passObjDet.fromdate = fromDate;
      passObjDet.todate = toDateFilter(toDate);
    } else if (id === 2) {
      const toDate = new Date();
      const fromDate = new Date(toDate);
      fromDate.setDate(fromDate.getDate() - 7);
      fromDate.setHours(0, 0, 0, 0)
      passObjDet.fromdate = fromDate;
      passObjDet.todate = toDateFilter(toDate);
    } else if (id === 3) {
      const toDate = new Date();
      const fromDate = new Date(toDate);
      fromDate.setDate(fromDate.getDate() - 30);
      fromDate.setHours(0, 0, 0, 0)
      passObjDet.fromdate = fromDate;
      passObjDet.todate = toDateFilter(toDate);
    } else if (id === 4) {
      if (fdate && tdate) {
        const toDate = tdate;
        toDate.setHours(new Date().getHours());
        const fromDate = fdate;
        fromDate.setHours(0, 0, 0, 0)
        passObjDet.fromdate = fromDate;
        passObjDet.todate = toDateFilter(toDate);
      } else {
        const toDate = new Date();
        const fromDate = new Date(toDate);
        fromDate.setDate(fromDate.getDate() - 7);
        fromDate.setHours(0, 0, 0, 0)
        passObjDet.fromdate = fromDate;
        passObjDet.todate = toDateFilter(toDate);

        let KendoDateFrom =
          (parseInt(fromDate.getMonth()) + 1).toString() +
          "/" +
          fromDate.getDate().toString() +
          "/" +
          fromDate.getFullYear().toString();
        let KendoDateTo =
          (parseInt(toDate.getMonth()) + 1).toString() +
          "/" +
          toDate.getDate().toString() +
          "/" +
          toDate.getFullYear().toString();
        this.setState({
          OnKendoDateFrom: KendoDateFrom,
          OnKendoDateTo: KendoDateTo,
        })
      }
    }

    Servicecalls(
      "" + this.ELNurl + "/protocol/GetProtocolTransactionDetails",
      passObjDet,
      (respone) => {
        let transaction_histtory;
        let version = respone.lsprotocolversion.reverse()
        if (respone.lsprotocoltransaction) {
          transaction_histtory = [...[{
            createdby: respone.createby,
            protocolmodifiedDate: respone.lsprotocoltransaction.createdate,
            modifiedby: respone.lsprotocoltransaction.createdbyusername,
            protocolcomment: this.props.t("Templates.Protocoltemplates.IDS_LBL_CREATEDF", {
              user1: respone.lsprotocoltransaction.createdbyusername,
              user2: respone.lsprotocoltransaction.protocolmastername,
            })
          }]]
        } else {
          transaction_histtory = [];
        }
        let lsprotocolworkflowhistory = respone.lsprotocolworkflowhistory;
        this.setState({
          Data: respone.lsprotocolworkflowhistory,
          versiondata: version,
          selecteddatevalue: event,
          transactionhistory: transaction_histtory,
          lsprotocolworkflowhistory: lsprotocolworkflowhistory
        });

      },
      (res) => {

      }
    );

    setTimeout(() => {
      let passObjDet = {
        objsilentaudit: Silentauditobject(
          "view",
          "Protocol Templates",
          this.props.t("Templates.Protocoltemplates.IDS_AUDIT_PROTOCOLINFOOPENED", {
            user1: this.props.dataItem.protocolmastername,
            user: store.getState().Loggeduser.username,
          })

        ),
      };
      Servicecalls(
        "" + this.ELNurl + "/AuditTrail/silentandmanualRecordHandler",
        passObjDet,
        (res) => { },
        (res) => { }
      );
    }, 50);

  }



  handleTabClick = (tab) => {
    this.setState({
      activeTab: tab
    })
  };

  onClose() {
    this.setState({ customProtocol: false });
  }

  handleChange = (event) => {
    this.TemplateHistroyService(event);
  };

  render() {
    return (
      this.props.dataItem[this.props.keyfield] !== undefined ? (
        <React.Fragment>
          <button
            id={`user-${this.props.dataItem[this.props.keyfield]}`}
            className="grid-icons"
            alt="user"
            data-placement="top"
            title={this.props.t("Templates.Sheettemplates.IDS_LBL_HISTORY")}
            onClick={this.OnClickHistory.bind(this)}

          >
            <i className="far fa-eye 5"></i>
          </button>
          {this.state.customProtocol &&
            <Suspense fallback={<div></div>}>
              <ProtocolHistory
                screentype={1}
                screen={"protocoleye"}
                versioncolumn={this.state.columns}
                transactionscolumn={this.state.transactionscolumn}
                isexpand={this.state.customProtocol}
                state={this.state}
                props={this.props}
                getThis={() => this}
                onClose={this.onClose.bind(this)}
                handleChange={this.handleChange.bind(this)}
                handleTabClick={this.handleTabClick.bind(this)}
                OnHistoryfromDatachange={this.OnHistoryfromDatachange.bind(this)}
                OnHistorytoDatachange={this.OnHistorytoDatachange.bind(this)}
                ondatefilterreset={this.ondatefilterreset.bind(this)}
              />
            </Suspense>}
        </React.Fragment>
      ) : (
        ""
      )
    );
  }
}
const Customprotocolcells = withTranslation(Customprotocol);

class Customsheetorder extends React.Component {

  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      Data: [],
      transactionhistory: {},
      inventryexpand: true,
      workflowexpanded: true,
      userMasterScreen: true,
      ServiceToUrl: "",
      ServiceMethodType: "POST",
      ServiceHeaderobject: {},
      ServcieonSuccess: undefined,
      ServcieonFailure: undefined,
      clickTimer: null,
      activeTab: 1,
    };

    this.ELNurl = document.getElementById("ELNname").value;

    this.imageurl = "";
    if (isMultitenant === 1) {
      this.imageurl = "/User/Cloudprofile/";
    } else {
      this.imageurl = "/User/profile/";
    }

    this.month = [
      this.props.t("months.IDS_LBL_JAN", { param: "" }),
      this.props.t("months.IDS_LBL_FEB", { param: "" }),
      this.props.t("months.IDS_LBL_MAR", { param: "" }),
      this.props.t("months.IDS_LBL_APR", { param: "" }),
      this.props.t("months.IDS_LBL_MAY", { param: "" }),
      this.props.t("months.IDS_LBL_JUN", { param: "" }),
      this.props.t("months.IDS_LBL_JUL", { param: "" }),
      this.props.t("months.IDS_LBL_AUG", { param: "" }),
      this.props.t("months.IDS_LBL_SEP", { param: "" }),
      this.props.t("months.IDS_LBL_OCT", { param: "" }),
      this.props.t("months.IDS_LBL_NOV", { param: "" }),
      this.props.t("months.IDS_LBL_DEC", { param: "" }),
    ];
  }

  componentDidMount() {
    this._isMounted = true;
  }

  ongetProtocolOrderworkflowhistoryListFailure = (res) => { }
  ongetprotocolstephistorySuccess(responce) {
    responce.sort((a, b) => a.stepstartdate - b.stepstartdate);
    this.setState({
      sheetranshistory: responce,
    })
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  isToolTipOpen = (targetName) => {
    return this.state[targetName] ? this.state[targetName].tooltipOpen : false;
  };

  toggle = (targetName) => {
    if (!this._isMounted) return;

    if (!this.state[targetName]) {
      this.setState({
        ...this.state,
        [targetName]: {
          tooltipOpen: true,
        },
      });
    } else {
      this.setState({
        ...this.state,
        [targetName]: {
          tooltipOpen: !this.state[targetName].tooltipOpen,
        },
      });
    }

    this.setState({
      transactionexpanded: false,
      workflowexpanded: false,
    });
  };

  formatsheetorderworkflowhistorydetails() {
    var detail = [];

    if (this.state.Data.length !== 0) {
      let d = null;
      let day = null;
      let dayname = null;
      let time = null;

      for (let index = this.state.Data.length - 1; index >= 0; index--) {
        let createby = {
          usercode: this.state.Data[index].createby.usercode,
          username: this.state.Data[index].createby.username,
        };

        if (
          this.state.Data[index].createby.usercode !== undefined &&
          this.state.Data[index].createby.username !== null
        ) {
          let localdate = convertUTCDateToLocalDate(
            new Date(this.state.Data[index].createdate)
          );

          d = new Date(localdate);
          day = d.getDate();
          dayname = this.month[d.getMonth()];
          let year = d.getFullYear();
          // let gmt =d.toString();
          // gmt=gmt.slice(25,34);

          // time = d.getHours() + ":" + d.getMinutes() + ":" + d.getSeconds();
          day = convertdoubledigitdate(day);
          time = convertdoubledigittime(
            d.getHours(),
            d.getMinutes(),
            d.getSeconds()
          );
          let imgurl = this.ELNurl + this.imageurl + "-1";
          if (
            this.state.Data[index].createby.usercode !== null &&
            this.state.Data[index].username !== undefined
          ) {
            imgurl =
              this.ELNurl +
              this.imageurl +
              this.state.Data[index].createby.usercode;
          }
          detail.push(
            <li key={index} className="list-group-item" aria-disabled="true">
              <div className="row no-gutters align-items-center">
                <div className="col-2">
                  <div className="calender-list small">
                    <span className="d-flex eln-stamp">{dayname}</span>
                    <span className="d-flex eln-date">{day}</span>
                    <span className="d-flex eln-stamp">{year}</span>
                    <span className="d-flex eln-stamp">{time}</span>
                    {/* <span className="d-flex eln-stamp">{gmt}</span> */}
                  </div>
                </div>
                <div className="col-10 mnuContainer2 userDetails">
                  <Userdetail
                    index={"Trans-" + index}
                    imgurl={imgurl}
                    user={createby}
                  />
                  <p>
                    {this.state.Data[index].action}
                    <div className="userComts">
                      <b>
                        {this.props.t("Orders.Protocolorders.IDS_LBL_COMMENT", { param: "" })} :
                      </b>{" "}
                      {this.state.Data[index].comment}
                    </div>
                  </p>
                </div>
              </div>
            </li>
          );
        }
      }
    } else {
      detail.push(
        <ul className="list-group list-group-flush" key="noworkflow">
          <li className="list-group-item" aria-disabled="true">
            <p>
              {this.props.t("Orders.Protocolorders.IDS_LBL_NOWORKF", {
                param: "",
              })}
            </p>
          </li>
        </ul>
      );
    }

    let detailsvalue = (
      <ul className="list-group list-group-flush">{detail}</ul>
    );
    return detailsvalue;
  }

  formatsheetorderversiondetails() {

    let filecode = this.props.props.dataItem.lssamplefile.filesamplecode

    if (filecode !== undefined && filecode !== null) {
      let passObjDetFile = { filesamplecode: filecode };
      Servicecalls(
        "" + this.ELNurl + "/Instrument/GetSheetorderversions",
        passObjDetFile,
        (res) => {
          if (res !== undefined && res !== null) {
            let temp = res.lsorderversion.sort((a, b) => b.createdate - a.createdate);
            this.setState({
              lstorderversion: temp
            });
          }
        },
        (res) => { }
      );
    }


    var detail = [];
    if (this.state.lstorderversion !== undefined) {
      let d = null;
      let day = null;
      let dayname = null;
      let time = null;

      if (
        this.state.lstorderversion !== undefined && this.state.lstorderversion !== null && this.state.lstorderversion.length > 0
      ) {
        for (let index = 0; index < this.state.lstorderversion.length; index++) {

          let localdate;

          if (this.state.lstorderversion[index].modifieddate !== undefined && this.state.lstorderversion[index].modifieddate !== undefined) {
            localdate = convertUTCDateToLocalDate(new Date(this.state.lstorderversion[index].modifieddate));
          } else {
            localdate = convertUTCDateToLocalDate(new Date(this.state.lstorderversion[index].createdate));
          }

          d = new Date(localdate);
          let createby;

          if (this.state.lstorderversion[index].modifiedby !== undefined && this.state.lstorderversion[index].modifiedby.usercode !== undefined) {
            createby = {
              usercode: this.state.lstorderversion[index].modifiedby.usercode,
              username: this.state.lstorderversion[index].modifiedby.username
            };
          } else if (this.state.lstorderversion[index].createbyuser !== undefined && this.state.lstorderversion[index].createbyuser.usercode !== undefined) {
            createby = {
              usercode: this.state.lstorderversion[index].createbyuser.usercode,
              username: this.state.lstorderversion[index].createbyuser.username
            };
          }

          day = d.getDate();
          dayname = this.month[d.getMonth()];
          day = convertdoubledigitdate(day);
          time = convertdoubledigittime(d.getHours(), d.getMinutes(), d.getSeconds());
          let year = d.getFullYear();
          // let gmt =d.toString();
          // gmt=gmt.slice(25,34);
          let imgurl = this.ELNurl + this.imageurl + "-1";

          if (this.state.lstorderversion[index].modifiedby !== undefined && this.state.lstorderversion[index].modifiedby.usercode !== undefined) {
            imgurl = this.ELNurl + this.imageurl + this.state.lstorderversion[index].modifiedby.usercode;
          } else if (this.state.lstorderversion[index].createbyuser !== undefined && this.state.lstorderversion[index].createbyuser.usercode !== undefined) {
            imgurl = this.ELNurl + this.imageurl + this.state.lstorderversion[index].createbyuser.usercode;
          }

          let content = null;
          if (createby) {
            if (Number(this.state.lstorderversion[index].versionno) === 1) {
              content = "Version " + this.props.t("Orders.Protocolorders.IDS_LBL_CREATEDFILE", { user2: createby.username, user1: this.state.lstorderversion[index].versionno, })
            }
            else {
              content = this.props.t("Orders.Protocolorders.IDS_LBL_MODIFIEDF", { user2: createby.username, user1: this.state.lstorderversion[index].versionno, })
            }
          }

          detail.push(
            <li
              key={this.props.props.dataItem.batchid + "Trans_" + index}
              className="list-group-item"
              aria-disabled="true"
            >
              <div className="row no-gutters align-items-center">
                <div className="col-2">
                  <div className="calender-list small">
                    <span className="d-flex eln-stamp">{dayname}</span>
                    <span className="d-flex eln-date">{day}</span>
                    <span className="d-flex eln-stamp">{year}</span>
                    <span className="d-flex eln-stamp">{time}</span>
                    {/* <span className="d-flex eln-stamp">{time}</span> */}
                  </div>
                </div>
                <div className="col-10 mnuContainer2 userDetails">
                  {this._isMounted && <Userdetail
                    index={"Trans_" + index}
                    imgurl={imgurl}
                    user={createby}
                  />}
                  <p>
                    {content}
                  </p>
                </div>{" "}
              </div>
            </li>
          );
          // }
        }
      }
    }

    let detailsvalue = (
      <ul className="list-group list-group-flush">{detail}</ul>
    );
    return detailsvalue;
  }

  formatsheetordertranshistorydetails() {
    var detail = [];

    if (this.state.sheetranshistory !== undefined && Object.entries(this.state.sheetranshistory).length !== 0) {
      // if (this.state.workflowData !== undefined) {
      for (let index = this.state.sheetranshistory.length - 1; index >= 0; index--) {

        let localdate = convertUTCDateToLocalDate(
          new Date(
            this.state.sheetranshistory[index].stepstartdate !== undefined ? this.state.sheetranshistory[index].stepstartdate : this.state.sheetranshistory[index].stependdate
          )
        );
        let d = new Date(localdate);

        // let d = new Date(this.state.workflowData[index].createdate);
        let day = d.getDate();
        let dayname = this.month[d.getMonth()];
        // let time = d.getHours() + ":" + d.getMinutes() + ":" + d.getSeconds();
        let time = convertdoubledigittime(
          d.getHours(),
          d.getMinutes(),
          d.getSeconds()
        );
        let year = d.getFullYear();
        let imgurl = this.ELNurl + this.imageurl + "-1";
        if (
          this.state.sheetranshistory[index].createby !== null &&
          this.state.sheetranshistory[index].createby !== undefined
        ) {
          imgurl =
            this.ELNurl +
            this.imageurl +
            this.state.sheetranshistory[index].createby.usercode;
        }
        detail.push(
          <li
            className="list-group-item"
            aria-disabled="true"
            key={
              this.state.sheetranshistory[index].batchcode !== undefined
                ? this.state.sheetranshistory[index].batchcode
                : index
            }
          >
            <div className="row no-gutters align-items-center">
              <div className="col-2">
                <div className="calender-list">
                  <span className="d-flex eln-stamp">{dayname}</span>
                  <span className="d-flex eln-date">{day}</span>
                  <span className="d-flex eln-stamp">{year}</span>
                  <span className="d-flex eln-stamp">{time}</span>
                </div>
              </div>
              <div className="col-10 mnuContainer2 userDetails">

                <Userdetail
                  index={"CreateWKFlow-" + index}
                  imgurl={imgurl}
                  user={this.state.sheetranshistory[index].createby}
                />


                <p>
                  {" "}
                  {this.state.sheetranshistory[index].action}
                  {/* <div className="userComts">
                    <b>{this.props.t("Orders.Protocolorders.IDS_LBL_COMMENT", { param: "" })} : </b>{" "}
                    {this.state.workflowData[index].comment}
                  </div> */}
                </p>
              </div>
            </div>
          </li>
        );
      }
    }

    if (this.state.transactionhistory !== "") {
      let d = null;
      let day = null;
      let dayname = null;
      let time = null;

      let createby = {
        usercode: this.props.props.dataItem.lsuserMaster.usercode,
        username: this.props.props.dataItem.lsuserMaster.username,
      };

      if (
        this.state.transactionhistory.usercode !== undefined &&
        this.state.transactionhistory.username !== null
      ) {

        let localdate = convertUTCDateToLocalDate(new Date(this.props.props.dataItem.createdtimestamp));

        d = new Date(localdate);

        day = d.getDate();
        dayname = this.month[d.getMonth()];
        day = convertdoubledigitdate(day);
        time = convertdoubledigittime(d.getHours(), d.getMinutes(), d.getSeconds());
        let year = d.getFullYear();
        // let gmt =d.toString();
        // gmt=gmt.slice(25,34);

        let imgurl = this.ELNurl + this.imageurl + "-1";
        if (
          this.state.transactionhistory.usercode !== null &&
          this.state.transactionhistory.username !== undefined
        ) {
          imgurl =
            this.ELNurl +
            this.imageurl +
            this.state.transactionhistory.usercode;
        }
        detail.push(
          <li
            key={this.props.props.dataItem.batchcode + "Trans_" + 1}
            className="list-group-item"
            aria-disabled="true"
          >
            <div className="row no-gutters align-items-center">
              <div className="col-2">
                <div className="calender-list small">
                  <span className="d-flex eln-stamp">{dayname}</span>
                  <span className="d-flex eln-date">{day}</span>
                  <span className="d-flex eln-stamp">{year}</span>
                  <span className="d-flex eln-stamp">{time}</span>
                  {/* <span className="d-flex eln-stamp">{gmt}</span> */}
                </div>
              </div>
              <div className="col-10 mnuContainer2 userDetails">
                <Userdetail
                  index={"Trans-" + 1}
                  imgurl={imgurl}
                  user={createby}
                />

                <p>
                  {this.props.t("Orders.Protocolorders.IDS_LBL_CREATEDFILE", {
                    user2: this.props.props.dataItem.lsuserMaster.username,

                    user1: this.props.props.dataItem.batchid,
                  })}
                </p>
              </div>
            </div>
          </li>
        );
      }
    } else {
      detail.push(
        <ul className="list-group list-group-flush">
          <li className="list-group-item" aria-disabled="true">
            <p>
              {this.props.t("Masters.Inventory.IDS_LBL_NOINVENTRYDETAAILS", {
                param: "",
              })}
            </p>
          </li>
        </ul>
      );
    }

    let detailsvalue = (
      <ul className="list-group list-group-flush">{detail}</ul>
    );
    return detailsvalue;
  }

  async onfinddataworkflowclick(e) {
    let passobject = this.props.props.dataItem;

    if (passobject.createdtimestamp !== undefined) {
      if (
        typeof passobject.createdtimestamp === "string" ||
        passobject.createdtimestamp instanceof String
      ) {
        passobject.createdtimestamp = new Date(passobject.createdtimestamp);
      }

      if (
        typeof passobject.completedtimestamp === "string" ||
        passobject.completedtimestamp instanceof String
      ) {
        passobject.completedtimestamp = new Date(passobject.completedtimestamp);
      }
    }

    Servicecalls(
      "" + this.ELNurl + "/Instrument/updateVersionandWorkflowhistory",
      passobject,
      this.ongetOrderworkflowhistoryListSuccess.bind(this),
      this.ongetOrderworkflowhistoryListFailure.bind(this)
    );
  }

  ongetOrderworkflowhistoryListSuccess(response) {
    if (response.lsorderworkflowhistory !== undefined) {
      response.lsorderworkflowhistory.sort((a, b) => parseFloat(a.historycode) - parseFloat(b.historycode));
    }
    this.setState({
      Data: response.lsorderworkflowhistory
      // workflowexpanded: !this.state.workflowexpanded,
    });
  }

  ongetOrderworkflowhistoryListFailure(res) { }

  async onfinddataclick(e) {

    let passObjDetAudit = store.getState().Loggeduser;
    passObjDetAudit.objsilentaudit = Silentauditobject(
      "view",
      "Sheet Orders",
      this.props.t("Orders.Sheetorders.IDS_AUDIT_TRANSHISTORYSHEET", {
        screen: orderType(this.props.props.dataItem.filetype),
        user1: store.getState().Loggeduser.username,
        orderid: this.props.props.dataItem.batchid,
      })
    );

    Servicecalls(
      "" + this.ELNurl + "/AuditTrail/silentandmanualRecordHandler",
      passObjDetAudit,
      (res) => { },
      (res) => { }
    );
    let passobject = {
      usercode: this.props.props.dataItem.lsuserMaster.usercode,
    };

    Servicecalls(
      "" + this.ELNurl + "/User/getUserOnCode",
      passobject,
      this.onRepositorySuccess.bind(this),
      this.onRepositoryFailure.bind(this)
    );


    let passobject1 = {
      batchcode: this.props.props.dataItem.batchcode,
    };
    Servicecalls(
      "" + this.ELNurl + "/protocol/getprotocolstephistory",
      passobject1,
      this.ongetprotocolstephistorySuccess.bind(this),
      this.ongetProtocolOrderworkflowhistoryListFailure.bind(this)
    );
  }
  // sathish kumar added
  // async onfinddataclick(e) {
  //   let passobject = {
  //     filesamplecode: this.props.props.dataItem.lssamplefile.filesamplecode,
  //   };
  //   Servicecalls(
  //     "" + this.ELNurl + "/Instrument/getlsorderfileversion",
  //     passobject,
  //     this.onRepositorySuccess.bind(this),
  //     this.onRepositoryFailure.bind(this)
  //   );
  // }

  onRepositoryFailure(res) { }

  onRepositorySuccess(response) {
    this.setState({
      transactionhistory: response
      // inventryexpand: !this.state.inventryexpand
    });
  }
  onClose(e) {
    this.setState({ sheetView: false })

    let screenName = this.props.props.dataItem.filetype !== undefined ? this.props.props.dataItem.filetype === 2 ? "ELN Order" :
      this.props.props.dataItem.filetypee === 3 ? "Research Activity order" :
        this.props.props.dataItem.filetype === 4 ? "Manage excel" :
          this.props.props.dataItem.filetype === 5 ? "Sheet Validation" : "" : "";
    this.setState({ screenName: screenName });
  }

  handleclickevent = (event) => {
    if (this.state.clickTimer === null) {
      const timer = window.setTimeout(() => {
        this.setState({ sheetView: true }); this.onfinddataclick(); this.onfinddataworkflowclick();
        console.log("single clicked");
        this.setState({ clickTimer: null });
      }, 250);
      this.setState({ clickTimer: timer });
    } else {
      window.clearTimeout(this.state.clickTimer);
      this.setState({ sheetView: false, clickTimer: null });
    }
  }

  handleTabClick = (tabNumber) => {
    // Function to handle tab click and update the activeTab state
    this.setState({ activeTab: tabNumber });
  };


  render() {
    const { activeTab } = this.state;
    const props = {
      t: (key, options) => {/* Your translation logic here */},
    };

    return (
      <div className="openEyeBtn">
        <button
          id={`user-${this.props.props.dataItem[this.props.keyfield]}`}
          className="grid-icons"
          alt="user"
          data-placement="top"
          title="Transaction"
          // onDoubleClick={this.handleSheetViewChange}
          onClick={this.handleclickevent.bind(this)}

        >
          <i className="far fa-eye sheet-eye"></i>
        </button>
        {this.state.sheetView ?
          <Modal
            isOpen={this.state.sheetView}
            backdrop={true}
            toggle={() => { this.setState({ sheetView: !this.state.sheetView }) }}
            className=" modal-md smallPopup protocol-history sheet-history"
          >
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">
                {this.props.t("Orders.Sheetorders.IDS_LBL_ORDERHISTORY", {
                  param: "",
                  // Order: this.props.props.dataItem.batchid,
                })}
              </h5>
              <button
                type="button"
                className="btn_white_bg"
                data-dismiss="modal"
                aria-label="Close"
                onClick={this.onClose.bind(this)}
              >
                <i className="fas fa-times" aria-hidden="true"></i>
              </button>
            </div>
            <ModalBody>
              <div>
                <ul className="nav nav-tabs">
                  <li className={`nav-item ${activeTab === 1 ? 'active' : ''}`}>
                    <a
                      className="nav-link"
                      onClick={() => this.handleTabClick(1)}
                      href="#tab1"
                    >
                      {this.props.t("Templates.Sheettemplates.IDS_LBL_VERSIONHIS")}
                    </a>
                  </li>
                  <li className={`nav-item ${activeTab === 2 ? 'active' : ''}`}>
                    <a
                      className="nav-link"
                      onClick={() => this.handleTabClick(2)}
                      href="#tab2"
                    >
                      {this.props.t("Templates.Sheettemplates.IDS_LBL_TRANS")}
                    </a>
                  </li>
                  <li className={`nav-item ${activeTab === 3 ? 'active' : ''}`}>
                    <a
                      className="nav-link"
                      onClick={() => this.handleTabClick(3)}
                      href="#tab3"
                    >
                      {this.props.t("Templates.Sheettemplates.IDS_LBL_WORKF")} 
                    </a>
                  </li>
                </ul>

                <div className="tab-content">
                  <div
                    className={`tab-pane ${activeTab === 1 ? 'active' : ''}`}
                    id="tab1"
                  >
                        <div className="popup-group-list-inner" >
                          {this.formatsheetorderversiondetails()}
                        </div>
                  </div>
                  <div
                    className={`tab-pane ${activeTab === 2 ? 'active' : ''}`}
                    id="tab2"
                  >
                        <div className="popup-group-list-inner" >
                          {this.formatsheetordertranshistorydetails()}
                        </div>
                  </div>
                  <div
                    className={`tab-pane ${activeTab === 3 ? 'active' : ''}`}
                    id="tab3"
                  >
                        <div
                      className="popup-group-list-inner"
                    >
                      {this.formatsheetorderworkflowhistorydetails()}
                    </div>
                  </div>
                </div>
              </div>
              {/* <OverlayScrollbarsComponent
                options={{ scrollbars: { autoHide: "scroll" } }}
              >
                 <div className="popup-group-list full-width popup-lg-container">
                  <h6 className="icon-head">
                    {this.props.t("Orders.Protocolorders.IDS_LBL_VERSIONHIS", {
                      param: "",
                    })}
                    <button
                      className="btn float-right"
                      onClick={() => {
                        this.setState({
                          sheetorderversionexpanded: !this.state.sheetorderversionexpanded,
                        });
                      }}
                    >
                      <i
                        className={
                          this.state.sheetorderversionexpanded
                            ? "fa fa-angle-up"
                            : "fa fa-angle-down"
                        }
                        aria-hidden="true"
                      ></i>
                    </button>
                  </h6>
                  {this.state.sheetorderversionexpanded ? (
                    <div
                      className="popup-group-list-inner"
                    >
                      {this.formatsheetorderversiondetails()}
                    </div>
                  ) : (
                    ""
                  )}

                  <h6 className="icon-head">
                    {this.props.t("Orders.Protocolorders.IDS_LBL_TRANS", {
                      param: "",
                    })}
                    <button
                      className="btn float-right"
                      onClick={() => {
                        this.setState({
                          inventryexpand: !this.state.inventryexpand,
                        });

                      }}
                    >
                      <i
                        className={
                          this.state.inventryexpand
                            ? "fa fa-angle-up"
                            : "fa fa-angle-down"
                        }
                        aria-hidden="true"
                      ></i>
                    </button>
                  </h6>

                  {this.state.inventryexpand ? (
                    <div
                      className="popup-group-list-inner"
                    >
                      {this.formatsheetordertranshistorydetails()}
                    </div>
                  ) : (
                    ""
                  )}
                  <h6 className="icon-head">
                    {this.props.t("Orders.Protocolorders.IDS_LBL_WORKF", {
                      param: "",
                    })}
                    <button
                      className="btn float-right"
                      onClick={() => {
                        this.setState({
                          workflowexpanded: !this.state.workflowexpanded,
                        });

                      }}
                    >
                      <i
                        className={
                          this.state.workflowexpanded
                            ? "fa fa-angle-up"
                            : "fa fa-angle-down"
                        }
                        aria-hidden="true"
                      ></i>
                    </button>
                  </h6>

                  {this.state.workflowexpanded ? (
                    <div
                      className="popup-group-list-inner"
                    >
                      {this.formatsheetorderworkflowhistorydetails()}
                    </div>
                  ) : (
                    ""
                  )}

                </div> 
              </OverlayScrollbarsComponent> */}
            </ModalBody>
            {/* <div className="modal-footer">
              <button
                type="button"
                className="textBtn btn_white_bg"
                onClick={this.onClose.bind(this)}
              > Close
              </button>
            </div> */}
          </Modal>
          : ""
        }
        {/* <Popover
          placement="right"
          trigger="hover"
          className="popoverContainer"
          id="popover-trigger-focus"
          isOpen={this.isToolTipOpen(
            `user-${this.props.props.dataItem[this.props.keyfield]}`
          )}
          target={`user-${this.props.props.dataItem[this.props.keyfield]}`}
          toggle={() =>
            this.toggle(
              `user-${this.props.props.dataItem[this.props.keyfield]}`
            )
          }
        >
          <PopoverBody className="no-pad">
            <OverlayScrollbarsComponent
              style={{ maxHeight: "300px" }}
              options={{ scrollbars: { autoHide: "scroll" } }}
            >
              <div className="popup-group-list">
                <h6 className="icon-head">
                  {this.props.t("renderfunction.IDS_TRANS", {
                    param: "",
                  })}
                  <button
                    className="btn float-right"
                    onClick={() => {
                      this.setState({
                        inventryexpand: !this.state.inventryexpand,
                      });
                      this.onfinddataclick();
                    }}
                  >
                    <i
                      className={
                        this.state.inventryexpand
                          ? "fa fa-angle-up"
                          : "fa fa-angle-down"
                      }
                      aria-hidden="true"
                    ></i>
                  </button>
                </h6>

                {this.state.inventryhistoryexpand ? (
                  <div
                    className="popup-group-list-inner"
                    isOpen={this.state.inventryhistoryexpand}
                  >
                    {this.state.inventryhistoryexpand &&
                      this.formatversiondetails()}
                  </div>
                ) : (
                  ""
                )}
                <h6 className="icon-head">
                  {this.props.t("renderfunction.IDS_WORKF", {
                    param: "",
                  })}
                  <button
                    className="btn float-right"
                    onClick={() => {
                      this.setState({
                        workflowexpanded: !this.state.workflowexpanded,
                      });
                      this.onfinddataworkflowclick();
                    }}
                  >
                    <i
                      className={
                        this.state.workflowexpanded
                          ? "fa fa-angle-up"
                          : "fa fa-angle-down"
                      }
                      aria-hidden="true"
                    ></i>
                  </button>
                </h6>

                {this.state.workflowehistoryxpanded ? (
                  <div
                    className="popup-group-list-inner"
                    isOpen={this.state.workflowehistoryxpanded}
                  >
                    {this.state.workflowehistoryxpanded
                      ? this.formatworrkflowhistorydetails()
                      : ""}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </OverlayScrollbarsComponent>
          </PopoverBody>
        </Popover> */}
      </div>
    );
  }
}
const Customordercells = withTranslation(Customsheetorder);

class Customcompleteorder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Data: [],
      transactionhistory: {},
      inventryexpand: false,
      workflowexpanded: false,
      workflowehistoryxpanded: false,
      inventryhistoryexpand: false,
      userMasterScreen: true,
      ServiceToUrl: "",
      ServiceMethodType: "POST",
      ServiceHeaderobject: {},
      ServcieonSuccess: undefined,
      ServcieonFailure: undefined,
    };
    this.props = props;
    this.ELNurl = document.getElementById("ELNname").value;

    this.imageurl = "";
    if (isMultitenant === 1) {
      this.imageurl = "/User/Cloudprofile/";
    } else {
      this.imageurl = "/User/profile/";
    }

    this.month = [
      this.props.t("months.IDS_LBL_JAN", { param: "" }),
      this.props.t("months.IDS_LBL_FEB", { param: "" }),
      this.props.t("months.IDS_LBL_MAR", { param: "" }),
      this.props.t("months.IDS_LBL_APR", { param: "" }),
      this.props.t("months.IDS_LBL_MAY", { param: "" }),
      this.props.t("months.IDS_LBL_JUN", { param: "" }),
      this.props.t("months.IDS_LBL_JUL", { param: "" }),
      this.props.t("months.IDS_LBL_AUG", { param: "" }),
      this.props.t("months.IDS_LBL_SEP", { param: "" }),
      this.props.t("months.IDS_LBL_OCT", { param: "" }),
      this.props.t("months.IDS_LBL_NOV", { param: "" }),
      this.props.t("months.IDS_LBL_DEC", { param: "" }),
    ];
  }

  isToolTipOpen = (targetName) => {
    return this.state[targetName] ? this.state[targetName].tooltipOpen : false;
  };

  toggle = (targetName) => {
    if (!this.state[targetName]) {
      this.setState({
        ...this.state,
        [targetName]: {
          tooltipOpen: true,
        },
      });
    } else {
      this.setState({
        ...this.state,
        [targetName]: {
          tooltipOpen: !this.state[targetName].tooltipOpen,
        },
      });
    }

    this.setState({
      transactionexpanded: false,
      workflowexpanded: false,
    });
  };

  formatworrkflowhistorydetails() {
    var detail = [];

    if (this.state.Data.length !== 0) {
      let d = null;
      let day = null;
      let dayname = null;
      let time = null;

      for (let index = 0; index < this.state.Data.length; index++) {
        let createby = {
          usercode: this.state.Data[index].createby.usercode,
          username: this.state.Data[index].createby.username,
        };

        if (
          this.state.Data[index].createby.usercode !== undefined &&
          this.state.Data[index].createby.username !== null
        ) {
          let localdate = convertUTCDateToLocalDate(
            new Date(this.state.Data[index].createdate)
          );
          d = new Date(localdate);
          day = d.getDate();
          dayname = this.month[d.getMonth()];
          // time = d.getHours() + ":" + d.getMinutes() + ":" + d.getSeconds();
          day = convertdoubledigitdate(day);
          time = convertdoubledigittime(
            d.getHours(),
            d.getMinutes(),
            d.getSeconds()
          );
          let year = d.getFullYear();
          // let gmt =d.toString();
          // gmt=gmt.slice(25,34);

          let imgurl = this.ELNurl + this.imageurl + "-1";
          if (
            this.state.Data[index].createby.usercode !== null &&
            this.state.Data[index].username !== undefined
          ) {
            imgurl =
              this.ELNurl +
              this.imageurl +
              this.state.Data[index].createby.usercode;
          }
          detail.push(
            <li
              key={this.props.dataItem.repositorydatacode + "Trans_" + index}
              className="list-group-item"
              aria-disabled="true"
            >
              <div className="row no-gutters align-items-center">
                <div className="col-2">
                  <div className="calender-list small">
                    <span className="d-flex eln-stamp">{dayname}</span>
                    <span className="d-flex eln-date">{day}</span>
                    <span className="d-flex eln-stamp">{year}</span>
                    <span className="d-flex eln-stamp">{time}</span>
                    {/* <span className="d-flex eln-stamp">{gmt}</span> */}
                  </div>
                </div>
                <div className="col-10 mnuContainer2 userDetails">
                  <Userdetail
                    index={"Trans-" + index}
                    imgurl={imgurl}
                    user={createby}
                  />
                  <p>
                    {this.state.Data[index].action}
                    <div className="userComts">
                      <b>
                        {this.props.t("Orders.Protocolorders.IDS_LBL_COMMENT", { param: "" })} :
                      </b>{" "}
                      {this.state.Data[index].comment}
                    </div>
                  </p>
                </div>
              </div>
            </li>
          );
        }
      }
    } else {
      detail.push(
        <ul className="list-group list-group-flush">
          <li className="list-group-item" aria-disabled="true">
            <p>
              {this.props.t("Orders.Protocolorders.IDS_LBL_NOWORKF", {
                param: "",
              })}
            </p>
          </li>
        </ul>
      );
    }

    let detailsvalue = (
      <ul className="list-group list-group-flush">{detail}</ul>
    );
    return detailsvalue;
  }

  formatversiondetails() {
    var detail = [];

    if (this.state.transactionhistory !== "") {
      let d = null;
      let day = null;
      let dayname = null;
      let time = null;

      let createby = {
        usercode: this.state.transactionhistory.usercode,
        username: this.state.transactionhistory.username,
      };

      if (
        this.state.transactionhistory.usercode !== undefined &&
        this.state.transactionhistory.username !== null
      ) {
        let localdate = convertUTCDateToLocalDate(
          new Date(this.state.transactionhistory.createddate)
        );
        d = new Date(localdate);
        day = d.getDate();
        dayname = this.month[d.getMonth()];
        // time = d.getHours() + ":" + d.getMinutes() + ":" + d.getSeconds();
        day = convertdoubledigitdate(day);
        time = convertdoubledigittime(
          d.getHours(),
          d.getMinutes(),
          d.getSeconds()
        );
        let year = d.getFullYear();
        // let gmt =d.toString();
        // gmt=gmt.slice(25,34);

        let imgurl = this.ELNurl + this.imageurl + "-1";
        if (
          this.state.transactionhistory.usercode !== null &&
          this.state.transactionhistory.username !== undefined
        ) {
          imgurl =
            this.ELNurl +
            this.imageurl +
            this.state.transactionhistory.usercode;
        }
        detail.push(
          <li
            key={this.props.dataItem.batchcode + "Trans_" + 1}
            className="list-group-item"
            aria-disabled="true"
          >
            <div className="row no-gutters align-items-center">
              <div className="col-2">
                <div className="calender-list small">
                  <span className="d-flex eln-stamp">{dayname}</span>
                  <span className="d-flex eln-date">{day}</span>
                  <span className="d-flex eln-stamp">{year}</span>
                  <span className="d-flex eln-stamp">{time}</span>
                  {/* <span className="d-flex eln-stamp">{gmt}</span> */}
                </div>
              </div>
              <div className="col-10 mnuContainer2 userDetails">
                <Userdetail
                  index={"Trans-" + 1}
                  imgurl={imgurl}
                  user={createby}
                />
                <p>
                  {this.props.t("Orders.Protocolorders.IDS_LBL_CREATEDFILE", {
                    user2: this.props.dataItem.lsuserMaster.username,
                    user1: this.props.dataItem.batchid,
                  })}
                </p>
              </div>{" "}
            </div>
          </li>
        );
      }
    } else {
      detail.push(
        <ul className="list-group list-group-flush">
          <li className="list-group-item" aria-disabled="true">
            <p>
              {this.props.t("Masters.Inventory.IDS_LBL_NOINVENTRYDETAAILS", {
                param: "",
              })}
            </p>
          </li>
        </ul>
      );
    }

    let detailsvalue = (
      <ul className="list-group list-group-flush">{detail}</ul>
    );
    return detailsvalue;
  }

  async onfinddataworkflowclick(e) {
    let passobject = this.props.dataItem;

    if (passobject.completedtimestamp !== undefined) {
      if (
        typeof passobject.createdtimestamp === "string" ||
        passobject.createdtimestamp instanceof String
      ) {
        passobject.createdtimestamp = new Date(passobject.createdtimestamp);
      }

      if (
        typeof passobject.completedtimestamp === "string" ||
        passobject.completedtimestamp instanceof String
      ) {
        passobject.completedtimestamp = new Date(passobject.completedtimestamp);
      }
    }

    Servicecalls(
      "" + this.ELNurl + "/Instrument/updateVersionandWorkflowhistory",
      passobject,
      this.ongetOrderworkflowhistoryListSuccess.bind(this),
      this.ongetOrderworkflowhistoryListFailure.bind(this)
    );
  }

  ongetOrderworkflowhistoryListSuccess(response) {
    this.setState({
      Data: response.lsorderworkflowhistory,
      workflowexpanded: !this.state.workflowexpanded,
      workflowehistoryxpanded: !this.state.workflowehistoryxpanded,
    });
  }

  ongetOrderworkflowhistoryListFailure(res) { }

  async onfinddataclick(e) {

    let passobject = {
      usercode: this.props.dataItem.lsuserMaster.usercode,
    };

    Servicecalls(
      "" + this.ELNurl + "/User/getUserOnCode",
      passobject,
      this.onRepositorySuccess.bind(this),
      this.onRepositoryFailure.bind(this)
    );
  }

  onRepositoryFailure(res) { }

  onRepositorySuccess(response) {
    this.setState({
      transactionhistory: response,
      inventryexpand: !this.state.inventryexpand,
      inventryhistoryexpand: !this.state.inventryhistoryexpand,
    });
  }

  render() {
    return (this.props.dataItem[this.props.keyfield] !== undefined ? (
      <div className="openEyeIcon">
        <button
          id={`user-${this.props.dataItem[this.props.keyfield]}`}
          className="btn_white_bg"
          alt="user"
          data-placement="top"
          title=""
        >
          <i className="far fa-eye 4"></i>
        </button>
        <Popover
          placement="bottom"
          trigger="hover"
          className="popoverContainer"
          id="popover-trigger-focus"
          isOpen={this.isToolTipOpen(
            `user-${this.props.dataItem[this.props.keyfield]}`
          )}
          target={`user-${this.props.dataItem[this.props.keyfield]}`}
          toggle={() =>
            this.toggle(`user-${this.props.dataItem[this.props.keyfield]}`)
          }
        >
          <PopoverBody className="no-pad">
            <OverlayScrollbarsComponent
              style={{ maxHeight: "300px" }}
              options={{ scrollbars: { autoHide: "scroll" } }}
            >
              <div className="popup-group-list">
                <h6 className="icon-head">
                  {this.props.t("Orders.Protocolorders.IDS_LBL_TRANS", {
                    param: "",
                  })}
                  <button
                    className="btn float-right"
                    onClick={() => {
                      this.setState({
                        inventryexpand: !this.state.inventryexpand,
                      });
                      this.onfinddataclick();
                    }}
                  >
                    <i
                      className={
                        this.state.inventryexpand
                          ? "fa fa-angle-up"
                          : "fa fa-angle-down"
                      }
                      aria-hidden="true"
                    ></i>
                  </button>
                </h6>

                {this.state.inventryhistoryexpand ? (
                  <div
                    className="popup-group-list-inner"
                    isOpen={this.state.inventryhistoryexpand}
                  >
                    {this.state.inventryhistoryexpand &&
                      this.formatversiondetails()}
                  </div>
                ) : (
                  ""
                )}
                <h6 className="icon-head">
                  {this.props.t("Orders.Protocolorders.IDS_LBL_WORKF", {
                    param: "",
                  })}
                  <button
                    className="btn float-right"
                    onClick={() => {
                      this.setState({
                        workflowexpanded: !this.state.workflowexpanded,
                      });
                      this.onfinddataworkflowclick();
                    }}
                  >
                    <i
                      className={
                        this.state.workflowexpanded
                          ? "fa fa-angle-up"
                          : "fa fa-angle-down"
                      }
                      aria-hidden="true"
                    ></i>
                  </button>
                </h6>

                {this.state.workflowehistoryxpanded ? (
                  <div
                    className="popup-group-list-inner"
                    isOpen={this.state.workflowehistoryxpanded}
                  >
                    {this.state.workflowehistoryxpanded &&
                      this.formatworrkflowhistorydetails()}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </OverlayScrollbarsComponent>
          </PopoverBody>
        </Popover>
      </div>
    ) : (
      ""
    )
    );
  }
}
const Customcompletecell = withTranslation(Customcompleteorder);

export {
  CustomUsergroupviewCells,
  Custominventrycells,
  Customprotocolordercells,
  Customprotocolcells,
  Customordercells,
  Customcompletecell,
  Formatprotocolversioncell,
  Formatprotocoltransactioncell,
  FormatResultUsedDetailscell,
  Formatprotocolordertransactioncell,
  Formatprotocolversiondetailscell,
  FormatResultUsedEquipment
};
