import { combineReducers } from 'redux';
import LoginReducer from './LoginReducer';
import { FormReducer } from "./FormReducer";
import { DataReducer } from "./DataReducer";
import { TreeReducer } from "./TreeReducer";

export default combineReducers({
    Login: LoginReducer,
    Forms: FormReducer,
    Data: DataReducer,
    Tree: TreeReducer,
});