import { ActionTypes } from "../actions/ActionTypes";

const intialState = {
  navigation: 1,
  language: 'en-US',
  isFluid: false,
  dateFormat: "dd/MM/yyyy",
  dateTimeFormat: "dd/MM/yyyy HH:mm:ss",
  userInfo: { userName: "admin", password: "123", comments: "" }
  // subElements: {dropZoneFiles: []}
};

export const FormReducer = (state = intialState, { type, payload }) => {
  switch (type) {
    case ActionTypes.DEFAULT_RETURN:
      return { ...state, ...payload };
    case ActionTypes.FETCH_FORM:
      return { ...state, ...payload };
    case ActionTypes.CHANGE_INPUT:
      return { ...state, ...payload };
    case ActionTypes.UPDATE_FORMKEY:
      return {
        ...state,
        navigation: {
          ...state.navigation,
          formkey: payload
        }
      }
    default:
      return state;
  }
};