


/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import { useEffect } from "react";
import {
  Grid,
  // GridCellProps,
  GridColumn as Column,
  GridRowClickEvent,
  GridRowDoubleClickEvent,
  GridSortChangeEvent,
  // GRID_COL_INDEX_ATTRIBUTE,
  GridColumnMenuSort,
  GridColumnMenuFilter,
  GridDataStateChangeEvent,
  GridPageChangeEvent,
  GridGroupChangeEvent,
  GridExpandChangeEvent,
} from "@progress/kendo-react-grid";
import {
  // useTableKeyboardNavigation,
  setExpandedState,
  setGroupIds
} from "@progress/kendo-react-data-tools";
// import { useInternationalization } from "@progress/kendo-react-intl";
import { classNames } from "@progress/kendo-react-common";
import {
  // convertDateFormat,
  getName,
  // formatBytes,
} from "../helpers/helperMethods";
import {
  ColumnMenu,
} from "./columnMenu.jsx";
// import { SplitBtnItems } from "../interfaces/FileManagerModels";
import { Servicecalls, sortDataByDate } from "../custom_functions/Functions";
import {
  process,
  //  State 
} from "@progress/kendo-data-query";
// import { PendingViewCell1 } from "./../controls/columnMenu.jsx";
import { Checkbox } from "@progress/kendo-react-inputs";
const PendingViewCell = React.lazy(() => import('./../controls/PendingViewCell.jsx'));
const PendingprotocolorderViewCell = React.lazy(() => import('./../controls/PendingprotocolorderViewCell.jsx'));
// const ColumnMenu = React.lazy(() => import('./columnMenu.jsx').then(({ ColumnMenu }) => ({ default: ColumnMenu })),
// );
let ELNurl = (document.getElementById("ELNname") as HTMLInputElement).value;
export const DateCreatedCell = (props: any) => {
  // const intl = useInternationalization();

  const handleClick = (e: any) => {
    switch (e.detail) {
      case 1:
        props.onSelectionChange(props, { skip: props.skiptake.skip, take: props.skiptake.take, group: props.skiptake.group, collapsedIds: props.skiptake.collapsedIds });
        break;
      case 2:
        props.onDoubleClick(props, { skip: props.skiptake.skip, take: props.skiptake.take, group: props.skiptake.group, collapsedIds: props.skiptake.collapsedIds });
        break;
      case 3:

        break;
    }
  };

  const field: string = props.field || "";
  const date: Date = props.dataItem[field];
  // const navigationAttributes = useTableKeyboardNavigation(props.id);

  return (
    <td
    // {...{ [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex }}
    // {...navigationAttributes}
    >
      <span style={{ width: "100%" }} onClick={handleClick}> <>{date !== undefined && date !== null ? date : ""}</> </span>

    </td>
  );
};

// const SizeCell = (props: GridCellProps) => {
//   const size: number = props.dataItem[props.field || ""];
//   const navigationAttributes = useTableKeyboardNavigation(props.id);

//   return (
//     <td
//       {...{ [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex }}
//       {...navigationAttributes}
//     >
//       {size === null ? "" : formatBytes(size)}
//     </td>
//   );
// };

export const NameCell = (props: any) => {
  // const navigationAttributes = useTableKeyboardNavigation(props.id);
  const name =
    props.dataItem.order !== undefined && props.field != null
      ? props.dataItem.order[props.field]
      : getName(props.dataItem.path);
  const [clickTimer, setClickTimer] = React.useState<number | null>(null);
  const handlorderselect = (event: any, item: any) => {
    props.onMultiOrderSelect(event, item);
  };

  const handlefileselect = (event: any, item: any) => {
    props.onMultiFileSelect(event, item);
  };

  const handlefolderselect = (event: any, item: any) => {
    props.onMultiFolderSelect(event, item);
  };


  // const handleClick = (e: any) => {

  // console.log(e.detail);
  // switch (e.detail) {
  //   case 2:
  //     props.onDoubleClick(props, { skip: props.skiptake.skip, take: props.skiptake.take, group: props.skiptake.group, collapsedIds: props.skiptake.collapsedIds });
  //     break;
  //   case 1:
  // props.onSelectionChange(props, { skip: props.skiptake.skip, take: props.skiptake.take, group: props.skiptake.group, collapsedIds: props.skiptake.collapsedIds }, e.detail);
  //     break;
  //   case 3:

  //     break;
  // }
  // };
  const handleClick = (e: any) => {
    console.log("trime", clickTimer);
    if (clickTimer === null) {
      // first click
      setClickTimer(window.setTimeout(() => {
        props.onSelectionChange(props, { skip: props.skiptake.skip, take: props.skiptake.take, group: props.skiptake.group, collapsedIds: props.skiptake.collapsedIds }, e.detail);
        console.log("single clicked");
        setClickTimer(null);
      }, 250)); // adjust delay as needed
    } else {
      // second click
      window.clearTimeout(clickTimer);
      setClickTimer(null);
      handleDoubleClick(e);
    }
  }

  const handleDoubleClick = (e: any) => {
    props.onDoubleClick(props, { skip: props.skiptake.skip, take: props.skiptake.take, group: props.skiptake.group, collapsedIds: props.skiptake.collapsedIds });
    console.log("double clicked");
    // handle double click event
  }

  const checkboxenableordersable = (item: any) => {
    let check = false;
    if (props.screen === "Sheet") {
      check =
        props.multiSelectedOrders !== undefined &&
          props.multiSelectedOrders.findIndex(
            (obj: { batchcode: any }) => obj.batchcode === item.order.batchcode
          ) > -1
          ? true
          : false;
    } else if (props.screen === "Protocol") {
      check =
        props.multiSelectedOrders !== undefined &&
          props.multiSelectedOrders.findIndex(
            (obj: { protocolordercode: any }) =>
              obj.protocolordercode === item.order.protocolordercode
          ) > -1
          ? true
          : false;
    }
    return check;
  };

  const checkboxenablefilesable = (item: any) => {
    let check = false;
    if (props.screen === "Sheet") {
      check =
        props.multiSelectedFiles !== undefined &&
          props.multiSelectedFiles.findIndex(
            (obj: { folderfilecode: any }) => obj.folderfilecode === item.file.folderfilecode
          ) > -1
          ? true
          : false;
    } else if (props.screen === "Protocol") {
      check =
        props.multiSelectedFiles !== undefined &&
          props.multiSelectedFiles.findIndex(
            (obj: { folderfilecode: any }) =>
              obj.folderfilecode === item.file.folderfilecode
          ) > -1
          ? true
          : false;
    }
    return check;
  };

  const checkboxenableforfolders = (item: any) => {
    let check = false;
    check =
      props.multiSelectedFolders !== undefined &&
        props.multiSelectedFolders.findIndex(
          (obj: { directorycode: any }) => obj.directorycode === item.directorycode
        ) > -1
        ? true
        : false;
    return check;
  };


  return (
    <td
      // {...{ [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex }}
      // {...navigationAttributes}
      onClick={handleClick}
      style={{ color: props.dataItem.icon ? props.dataItem.icon.color : "" }}
    >
      <div style={{ width: "100%" }}
        onClick={handleClick}
      >
        {props.isMultiSelectOrder && props.dataItem.order !== undefined && props.dataItem.filefor !== undefined && props.dataItem.filefor === "DR" && (
          <Checkbox
            onChange={(event) => handlorderselect(event, props.dataItem.order)}
            checked={checkboxenableordersable(props.dataItem)}
          ></Checkbox>
        )}
        {props.isMultiSelectFile && props.dataItem.file !== undefined && props.dataItem.filefor !== undefined && (
          <Checkbox
            onChange={(event) => handlefileselect(event, props.dataItem.file)}
            checked={checkboxenablefilesable(props.dataItem)}
          ></Checkbox>
        )}

        {props.isMultiSelectFolders && props.dataItem.filefor !== undefined && props.dataItem.filefor === "DR" && props.dataItem.order === undefined && (
          <Checkbox
            onChange={(event) => handlefolderselect(event, props.dataItem)}
            checked={checkboxenableforfolders(props.dataItem)}
          ></Checkbox>
        )}
        <span>

          <span
            className={classNames(
              "k-icon",
              props.dataItem.icon ? props.dataItem.icon.iconClass : ""
            )}
            style={{
              color: props.dataItem.icon ? props.dataItem.icon.color : "",
            }}
          />
          {props.dataItem.order !== undefined &&
            props.dataItem.order.lockeduser !== undefined &&
            sessionStorage.getItem("User") !== null &&
            props.dataItem.order.lockeduser !== null &&
            props.dataItem.order.lockeduser.toString() !==
            sessionStorage.getItem("User") && (
              <span
                className={classNames("k-i-lockorder")}
                style={{
                  color: props.dataItem.icon ? props.dataItem.icon.color : "",
                }}
              />
            )}
          {props.dataItem.order !== undefined &&
            props.dataItem.order.assignedto !== undefined &&
            props.dataItem.order.assignedto.usercode !== null &&
            sessionStorage.getItem("User") !== null &&
            props.dataItem.order.assignedto.usercode.toString() ===
            sessionStorage.getItem("User") && (
              <span
                className={classNames("k-i-assigntome")}
                style={{
                  color: props.dataItem.icon ? props.dataItem.icon.color : "",
                }}
              />
            )}
          {props.dataItem.order !== undefined &&
            props.dataItem.order.assignedto !== undefined &&
            props.dataItem.order.assignedto.usercode !== null &&
            props.dataItem.order.lsuserMaster !== undefined &&
            props.dataItem.order.lsuserMaster.usercode !== null &&
            sessionStorage.getItem("User") !== null &&
            props.dataItem.order.assignedto.usercode.toString() !==
            sessionStorage.getItem("User") &&
            props.dataItem.order.lsuserMaster.usercode.toString() ===
            sessionStorage.getItem("User") && (
              <span
                className={classNames("k-i-assignbyme")}
                style={{
                  color: props.dataItem.icon ? props.dataItem.icon.color : "",
                }}
              />
            )}
          {props.dataItem.order !== undefined &&
            props.dataItem.order.sharebyme !== undefined &&
            props.dataItem.order.sharebyme !== null &&
            props.dataItem.order.sharebyme.length > 0 && (
              <span
                className={classNames("k-i-sharebyme")}
                style={{
                  color: props.dataItem.icon ? props.dataItem.icon.color : "",
                }}
              />
            )}

          {props.dataItem.order !== undefined &&
            props.dataItem.order.sharetome !== undefined &&
            props.dataItem.order.sharetome !== null &&
            props.dataItem.order.sharetome.length > 0 && (
              <span
                className={classNames("k-i-sharetome")}
                style={{
                  color: props.dataItem.icon ? props.dataItem.icon.color : "",
                }}
              />
            )}
        </span>
        {name}
      </div>
    </td>
  );
};




export const GridView = (props: any) => {
  // const [isColumnselection, setIsColumnselection] =
  //   React.useState<boolean>(false);
  // let sheetcolumns = undefined;
  if (
    localStorage.getItem("elnsheetcolumns") !== undefined &&
    localStorage.getItem("elnsheetcolumns") !== null && props.screen === "Sheet"
  ) {
    // let sheetcolumnsval: any = localStorage.getItem("elnsheetcolumns");
    // sheetcolumns = JSON.parse(sheetcolumnsval);
  } else if (localStorage.getItem("elnprotocolcolumns") !== undefined &&
    localStorage.getItem("elnprotocolcolumns") !== null && props.screen === "Protocol") {
    // let sheetcolumnsval: any = localStorage.getItem("elnprotocolcolumns");
    // sheetcolumns = JSON.parse(sheetcolumnsval);
  }

  const initialPagerType = "numeric";
  let itemperpage = 10;
  if (
    localStorage.getItem("Elnitemperpage") !== undefined &&
    localStorage.getItem("Elnitemperpage") !== null
  ) {
    let sheetcolumnsval: any = localStorage.getItem("Elnitemperpage");
    itemperpage = JSON.parse(sheetcolumnsval);
  }

  const [columns] = React.useState<any[]>(props.columns);
  // const [splitBtnItems, setsplitBtnItems] = React.useState<SplitBtnItems[]>(
  //   props.splitItems
  // );
  // const [splitBtnItems] = React.useState<SplitBtnItems[]>(
  //   props.splitItems
  // );

  const [gridgroupcode, setGridgroupcode] = React.useState<any>(undefined);

  const createDataState = (skip: number, take: number, dataState: any, collapsedIds: any, group: any) => {
    skip = dataState && dataState.source && skip > dataState.source.length ? 0 : skip;
    let data = dataState.source;
    dataState.take = take;
    dataState.skip = skip;
    dataState.group = group;
    dataState.collapsedIds = collapsedIds;
    let griddata = process(data, dataState);
    setGroupIds({ data: griddata.data, group: dataState.group });
    const newData = setExpandedState({ data: griddata.data, collapsedIds: collapsedIds });
    if (props.sort && props.sort[0] && (props.sort[0].field === "dateCreated")) {
      const sortedGridData = sortDataByDate(props.data.data, props.sort[0].dir);
      dataState.source = sortedGridData;
      griddata = process(sortedGridData, dataState);
    }
    return {
      data: newData,
      dataState: dataState,
      total: griddata.total,
      skip: skip,
      take: take,
      group: group,
      pageSize: take,
      collapsedIds: collapsedIds,
      pageable: {
        buttonCount: 5,
        info: true,
        type: initialPagerType,
        pageSizes: [5, 10, 20, 30, 40, 60, 80, 100],
        previousNext: true,
      }
    };
  };

  let dataset: any = {};
  dataset.source = props.data !== undefined && props.data !== null && props.data.data !== undefined ? props.data.data : [];
  let skip = props.skiptake !== undefined ? props.skiptake.skip : 0;
  let take = props.skiptake !== undefined ? props.skiptake.take : itemperpage;
  let group = props.skiptake !== undefined ? props.skiptake.group : undefined;
  let collapsedIds = props.skiptake !== undefined ? props.skiptake.collapsedIds : [];
  const [griddata, setGriddata] = React.useState<any>(createDataState(skip, take, dataset, collapsedIds, group));

  // let initialState = createDataState({
  //   // take: 8,
  //   skip: 0,

  // });
  // const [Data, setData] = React.useState<any>(initialState.Data.data);
  // const [dataState, setDataState] = React.useState<State>(
  //   initialState.dataState
  // );
  // const [total, seTotal] = React.useState<number>(initialState.Data.total);

  // const [columns, setColumns] = React.useState<any[]>([
  //   {
  //     field: "batchid",
  //     title: "Order ID",
  //     show: true,
  //     default: true,
  //     cell: NameCell,
  //   },
  //   {
  //     field: "dateCreated",
  //     title: "Date Created",
  //     show: true,
  //     default: true,
  //     cell: DateCreatedCell,
  //   },
  //   {
  //     field: "filename",
  //     title: "Sheet",
  //     show:
  //       sheetcolumns !== undefined &&
  //       sheetcolumns.find((obj: string) => obj === "filename")
  //         ? true
  //         : false,
  //     default: false,
  //   },
  //   {
  //     field: "projectname",
  //     title: "Project",
  //     show:
  //       sheetcolumns !== undefined &&
  //       sheetcolumns.find((obj: string) => obj === "projectname")
  //         ? true
  //         : false,
  //     default: false,
  //   },
  //   {
  //     field: "samplename",
  //     title: "Sample",
  //     show:
  //       sheetcolumns !== undefined &&
  //       sheetcolumns.find((obj: string) => obj === "samplename")
  //         ? true
  //         : false,
  //     default: false,
  //   },
  //   {
  //     field: "repositoryitemname",
  //     title: "Inventory Name",
  //     show:
  //       sheetcolumns !== undefined &&
  //       sheetcolumns.find((obj: string) => obj === "repositoryitemname")
  //         ? true
  //         : false,
  //     default: false,
  //   },
  //   {
  //     field: "keyword",
  //     title: "keyword",
  //     show:
  //       sheetcolumns !== undefined &&
  //       sheetcolumns.find((obj: string) => obj === "keyword")
  //         ? true
  //         : false,
  //     default: false,
  //   },
  //   {
  //     field: props.props.t("SheetView.ID_PROCESSORDER"),
  //     title: "Action",
  //     width: "150px",
  //     show: true,
  //     default: false,
  //     cell: MyCustomCellAction,
  //     headerCell: "actionheader",
  //   },
  // ]);

  const RowRender = (trElement: any, dataItem: any) => {
    const trProps = {
      onContextMenu: (event: any) => {
        event.preventDefault();
        handleContextMenu(event, dataItem);
      },
    };
    return React.cloneElement(
      trElement,
      { ...trProps },
      trElement.props.children
    );
  };

  const handleOnSortChange = (event: GridSortChangeEvent) => {
    props.onSortChange({
      sort: event.sort,
      event: event,

    }, { skip: griddata.skip, take: griddata.take, group: griddata.group, collapsedIds: griddata.collapsedIds });
    console.log("hitted-1");
  };

  const handleSelectionChange = (event: GridRowClickEvent) => {
    props.onSelectionChange(event, { skip: griddata.skip, take: griddata.take, group: griddata.group, collapsedIds: griddata.collapsedIds });
    console.log("hitted-new");
  };

  const handleMultipleSelection = (event: any) => {
    props.onMultipleSelection(event, { skip: griddata.skip, take: griddata.take, group: griddata.group, collapsedIds: griddata.collapsedIds });
    console.log("hitted-2");
  };

  const handleDoubleClick = (event: GridRowDoubleClickEvent) => {
    props.onDoubleClick(event, { skip: griddata.skip, take: griddata.take, group: griddata.group, collapsedIds: griddata.collapsedIds });
    console.log("hitted-3");
  };

  const handleContextMenu = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    dataItem: any
  ) => {
    props.onContextMenu({
      dataItem: dataItem.dataItem,
      event: event,
    }, { skip: griddata.skip, take: griddata.take, group: griddata.group, collapsedIds: griddata.collapsedIds });
    console.log("hitted-4");
  };

  const isOrderdisplay =
    props.data !== undefined &&
    props.data !== null &&
    props.data.data !== undefined &&
    props.data.data.find((obj: any) => obj.order !== undefined || obj.file !== undefined);

  // const onColumnSelectClick = (event: any) => {
  //   setIsColumnselection(!isColumnselection);
  // };

  // const onColumnSelectonchange = (event: any) => {
  //   let ischecked = event.target.checked;
  //   setColumns(
  //     columns.map((obj) => {
  //       if (obj.field === event.target.value) {
  //         obj.show = ischecked;
  //         if (ischecked) {
  //           splitBtnItems.push({ text: obj.title, value: obj.field });
  //         } else {
  //           const indexOfObject = splitBtnItems.findIndex((object) => {
  //             return object.value === obj.field;
  //           });
  //           if (indexOfObject !== -1) {
  //             splitBtnItems.splice(indexOfObject, 1);
  //           }
  //         }
  //       }
  //       return obj;
  //     })
  //   );
  //   if (props.screen === "Sheet") {
  //     if (
  //       localStorage.getItem("elnsheetcolumns") !== undefined &&
  //       localStorage.getItem("elnsheetcolumns") !== null
  //     ) {
  //       let sheetcolumnsval: any = localStorage.getItem("elnsheetcolumns");
  //       let sheetcolumns = JSON.parse(sheetcolumnsval);
  //       if (ischecked) {
  //         sheetcolumns.push(event.target.value);
  //         localStorage.setItem("elnsheetcolumns", JSON.stringify(sheetcolumns));
  //       } else {
  //         sheetcolumns = sheetcolumns.filter(
  //           (e: string) => e !== event.target.value
  //         );
  //         localStorage.setItem("elnsheetcolumns", JSON.stringify(sheetcolumns));
  //       }
  //     } else if (ischecked) {
  //       localStorage.setItem(
  //         "elnsheetcolumns",
  //         JSON.stringify([event.target.value])
  //       );
  //     }
  //   } else {

  //   }
  // };

  // const oncolumnselectionblur = (event: any) => {
  //   setIsColumnselection(false);
  // };

  // const headercolumn = (props: any) => {
  //   return (
  //     <div onMouseLeave={oncolumnselectionblur}>
  //       {" "}
  //       <span
  //         className="k-icon k-i-more-vertical cls-showhidecolumn"
  //         onClick={onColumnSelectClick}
  //       ></span>
  //       {isColumnselection && (
  //         <div className="column-menu-body shadow-outlet-black">
  //           <ul>
  //             {columns.map((column, i) => {
  //               return (
  //                 !column.default &&
  //                 props.field !== column.field && (
  //                   <li key={column.field}>
  //                     <label>
  //                       <input
  //                         type="checkbox"
  //                         checked={column.show}
  //                         value={column.field}
  //                         onChange={onColumnSelectonchange}
  //                       />
  //                       {column.title}
  //                     </label>{" "}
  //                   </li>
  //                 )
  //               );
  //             })}
  //           </ul>
  //         </div>
  //       )}
  //     </div>
  //   );
  // };

  const columnProps = (field: any, orginaldata: any, primaryKey: any, header: any) => {
    // if (!this.props.hideColumnFilter) {

    return {
      // field: field,
      columnMenu: (props: any) => (
        // <React.Suspense fallback={<div></div>}>
        <ColumnMenu
          {...props}
          field={field}
          data={props.data}
          // onReset={this.onReset}
          // onColumnsSubmit={this.onColumnsSubmit}
          primaryKey={primaryKey}
        />
        // </React.Suspense>
      ),
      headerClassName: isColumnActive(field, griddata.dataState) ? "active" : "",
    };
    console.log("hitted-5");
  }


  const isColumnActive = (field: any, dataState: any) => {
    return (
      GridColumnMenuFilter.active(field, dataState.filter) ||
      GridColumnMenuSort.active(field, dataState.sort)
    );
    console.log("hitted-6");
  };


  const dataStateChange = (event: GridDataStateChangeEvent) => {
    let dataset: any = event.dataState;
    dataset.source = props.data.data;
    if (dataset.filter === null) {
      dataset.sort[0] = {
        dir: "desc",
        field: "path"
      }
    }
    setGriddata(createDataState(griddata.skip, griddata.take, dataset, griddata.collapsedIds, dataset.group));
    // let updatedState = createDataState(event.dataState);
    // setData(updatedState.Data);
    // setDataState(updatedState.dataState);
    console.log("hitted-7");
  };

  const pageChange = (event: GridPageChangeEvent) => {
    setGriddata(createDataState(event.page.skip, event.page.take, griddata.dataState, griddata.collapsedIds, griddata.group));
    localStorage.setItem("Elnitemperpage", event.page.take.toString());
    props.updateskiptake({ skip: event.page.skip, take: event.page.take, group: griddata.group, collapsedIds: griddata.collapsedIds });
  }

  const onGroupChange = (event: GridGroupChangeEvent) => {
    let dataset = griddata.dataState;
    dataset.group = event.group;
    setGriddata(createDataState(griddata.skip, griddata.take, dataset, griddata.collapsedIds, event.group));

    if (props.name !== undefined) {
      let passObjDet = {};
      if (gridgroupcode !== undefined) {
        passObjDet = {
          usergroupedcolcode: gridgroupcode,
          gridname: props.name,
          gridcolumns: JSON.stringify(event.group),
          usercode: sessionStorage.getItem("User"),
          sitecode: sessionStorage.getItem("Usersite"),
        };
      } else {
        passObjDet = {
          gridname: props.name,
          gridcolumns: JSON.stringify(event.group),
          usercode: sessionStorage.getItem("User"),
          sitecode: sessionStorage.getItem("Usersite"),
        };
      }

      Servicecalls(
        "" + ELNurl + "/User/setGroupedcolumn",
        passObjDet, onSetgroupsuccess, onSetgroupfailure
      );
    }
  }

  const onSetgroupsuccess = (response: any) => {
    if (response !== null && response !== undefined) {
      let dataset = griddata.dataState;
      dataset.group = JSON.parse(response.gridcolumns);
      setGriddata(createDataState(griddata.skip, griddata.take, dataset, griddata.collapsedIds, dataset.group));
      setGridgroupcode(response.usergroupedcolcode);
      props.updateskiptake({ skip: griddata.skip, take: griddata.take, group: dataset.group, collapsedIds: griddata.collapsedIds });
    }
  }

  const onSetgroupfailure = (response: any) => {

  }

  const onExpandChange = (event: GridExpandChangeEvent) => {
    const item = event.dataItem;
    item.expanded = false;
    if (item.groupId) {
      let collapsedState: any = griddata.collapsedIds;
      const collapsedIds = !event.value
        ? [...collapsedState, item.groupId]
        : collapsedState.filter(
          (groupId: any) => groupId !== item.groupId
        );

      setGriddata(createDataState(griddata.skip, griddata.take, griddata.dataState, collapsedIds, griddata.group));

    }
    console.log("hitted-9");
  }

  useEffect(() => {
    let skip = props.skiptake !== undefined ? props.skiptake.skip : 0;
    let take = props.skiptake !== undefined ? props.skiptake.take : itemperpage;
    let group = props.skiptake !== undefined ? props.skiptake.group : undefined;
    let collapsedIds = props.skiptake !== undefined ? props.skiptake.collapsedIds : [];
    if (griddata.dataState.filter !== undefined) {
      dataset.filter = griddata.dataState.filter
    }
    setGriddata(createDataState(skip, take, dataset, collapsedIds, group));

    if (gridgroupcode === undefined) {
      let passObjDet = {
        gridname: props.name,
        usercode: sessionStorage.getItem("User"),
        sitecode: sessionStorage.getItem("Usersite"),
      };

      Servicecalls(
        "" + ELNurl + "/User/getGroupedcolumn",
        passObjDet, ongetgroupsuccess, ongetgroupfailure
      );
    }
  }, [props.data]);

  const ongetgroupsuccess = (response: any) => {
    if (response !== null && response !== undefined) {

      let dataset = griddata.dataState;
      dataset.group = JSON.parse(response.gridcolumns);
      setGriddata(createDataState(griddata.skip, griddata.take, dataset, griddata.collapsedIds, dataset.group));
      setGridgroupcode(response.usergroupedcolcode);
      props.updateskiptake({ skip: griddata.skip, take: griddata.take, group: dataset.group, collapsedIds: griddata.collapsedIds });
    }
  }

  const ongetgroupfailure = (response: any) => { }

  const onOrderShared = props.onOrderShared;
  const openunsharelist = props.openunsharelist;
  const filedownloadurl = props.filedownloadurl !== undefined ? props.filedownloadurl : "";
  const ondeletefile = props.ondeletefile !== undefined ? props.ondeletefile : "";
  const onOrderOpen = props.onOrderOpen
  const oncancelledorderevent = props.oncancelledorderevent !== undefined ? props.oncancelledorderevent : ""
  const screen = props.screen;
  const MyCustomCellAction = (props: any) => (
    screen === "Sheet" ?
      <React.Suspense fallback={<div></div>}>
        <PendingViewCell
          {...props}
          keyfield={"batchcode"}
          // onclick={this.onbuttonchecked}
          passbackorder={onOrderShared}
          openunsharelist={openunsharelist}
          filedownloadurl={filedownloadurl}
          ondeletefile={ondeletefile}
          onorderopen={onOrderOpen}
          oncancelledorderevent={oncancelledorderevent}
        />
      </React.Suspense>
      : <React.Suspense fallback={<div></div>}>
        <PendingprotocolorderViewCell
          {...props}
          keyfield={"protocolordercode"}
          // onclick={this.onbuttonchecked}
          passbackorder={onOrderShared}
          openunsharelist={openunsharelist}
          filedownloadurl={filedownloadurl}
          ondeletefile={ondeletefile}
          onorderopen={onOrderOpen}
          oncancelledorderevent={oncancelledorderevent}
        />
      </React.Suspense>

  );




  return (
    <div className="at-list-table">
      <Grid
        // data={props.data}
        data={griddata.data}
        {...griddata.dataState}
        rowRender={RowRender}
        className={"k-filemanager-grid k-grid-display-block k-editable"}
        style={{ height: "400px" }}
        navigatable={true}
        sortable={{
          allowUnsort: false,
        }}
        onGroupChange={onGroupChange}
        onExpandChange={onExpandChange}
        expandField="expanded"
        reorderable={true}
        groupable={props.name !== undefined ? true : false}
        onPageChange={pageChange}
        pageable={griddata.pageable}
        pageSize={griddata.pageSize}
        sort={props.sort}
        selectedField={"selected"}
        selectable={{ enabled: true, cell: false, drag: true, mode: "multiple" }}
        dataItemKey={props.dataItemKey}
        onRowClick={handleSelectionChange}
        // onSelectionChange={handleMultipleSelection}
        onSortChange={handleOnSortChange}
        onRowDoubleClick={handleDoubleClick}
        onDataStateChange={dataStateChange}
        total={griddata.total}
      >
        {
          columns.map((column, i) => {
            if (
              column.show &&
              (column.showtoall || (!column.showtoall && isOrderdisplay)) &&
              column.headerCell &&
              column.cell
            ) {
              if (column.headerCell === "actionheader") {
                return (
                  <Column
                    key={i}
                    field={column.field}
                    title={column.title}
                    // {...columnProps(
                    //   column.field,
                    //   props.data,
                    //   props.dataItemKey,
                    //   column.title
                    // )}
                    // headerCell={headercolumn}
                    cell={MyCustomCellAction}
                    width={column.width}
                  // columnMenu={(props: any) => (
                  //   <ColumnMenu
                  //     {...props}
                  //     field={column.field}
                  //     data={props.data}
                  //     // onReset={this.onReset}
                  //     // onColumnsSubmit={this.onColumnsSubmit}
                  //     primaryKey={props.dataItemKey}
                  //     title={column.title}
                  //   // headerClassName= {this.isColumnActive(field, this.state.dataState) ? "active" : ""}
                  //   />
                  // )}

                  />
                );
              }
              return (
                <Column
                  key={i}
                  field={column.field}
                  title={column.title}
                  width={column.width}
                  {...columnProps(
                    column.field,
                    props.data,
                    props.dataItemKey,
                    column.title
                  )}
                  headerCell={column.headerCell}
                  cell={(data: any) => <column.cell {...data} {...props} />}

                />
              );
            } else if (
              column.show &&
              (column.showtoall || (!column.showtoall && isOrderdisplay)) &&
              column.headerCell
            ) {
              if (column.headerCell === "actionheader") {
                return (
                  <Column
                    key={i}
                    field={column.field}
                    title={column.title}
                    {...columnProps(
                      column.field,
                      props.data,
                      props.dataItemKey,
                      column.title
                    )}
                    // headerCell={headercolumn}
                    cell={MyCustomCellAction}
                    width={column.width}
                  />
                );
              }
              return (
                <Column
                  key={i}
                  field={column.field}
                  title={column.title}
                  headerCell={column.headerCell}
                  width={column.width}
                  {...columnProps(
                    column.field,
                    props.data,
                    props.dataItemKey,
                    column.title
                  )}
                />
              );
            } else if (
              column.show &&
              (column.showtoall || (!column.showtoall && isOrderdisplay)) &&
              column.cell && isOrderdisplay !== undefined
            ) {
              // if (isOrderdisplay !== undefined && isOrderdisplay.orderflag.trim() !== 'R' && column.field !== 'dateCompleted') {
              return (
                <Column
                  key={i}
                  field={column.field}
                  title={column.title}
                  width={column.width}
                  cell={(data: any) => <column.cell {...data} {...props} />}
                  {...columnProps(
                    column.field,
                    props.data,
                    props.dataItemKey,
                    column.title
                  )}
                />
              );
              // } else if (isOrderdisplay !== undefined && isOrderdisplay.orderflag.trim() === 'R') {
              //   return (
              //     <Column
              //       key={i}
              //       field={column.field}
              //       title={column.title}
              //       width={column.width}
              //       cell={(data: any) => <column.cell {...data} {...props} />}
              //       {...columnProps(
              //         column.field,
              //         props.data,
              //         props.dataItemKey,
              //         column.title
              //       )}
              //     />
              //   );
              // }
            } else if (
              column.show &&
              (column.showtoall || (!column.showtoall && isOrderdisplay))
            ) {
              if (column.cell !== undefined) {
                return <Column key={i} field={column.field} title={column.title} width={column.width}
                  cell={(data: any) => <column.cell {...data} {...props} />}
                  {...columnProps(
                    column.field,
                    props.data,
                    props.dataItemKey,
                    column.title
                  )} />;
              }
              else {
                return <Column key={i} field={column.field} title={column.title} width={column.width}
                  {...columnProps(
                    column.field,
                    props.data,
                    props.dataItemKey,
                    column.title
                  )} />;
              }

            }; return true;
          })}
      </Grid>
    </div>
  );
};
function moment(dateString: string | null) {
  throw new Error("Function not implemented.");
}

