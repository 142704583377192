import React, { Component } from "react";
import { withTranslation } from "react-multi-lang";
import { css } from "@emotion/core";
import PuffLoader from "react-spinners/PuffLoader";
import $ from "jquery";
import { formatdata } from "./../../custom_functions/Functions.jsx";
import pako, { gzip } from 'pako';
const axios = require('axios');

const override = css`
    position: fixed;
    z-index: 999;
    height: 2em;
    width: 2em;
    overflow: visible;
    margin: auto;
    top: 50%;
    left: 50%;
    bottom: 0;
    right: 0;
    margin-top: -50px;
    margin-left: -50px;

    :before {
        content: '';
        display: block;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,0.3);
    }
`;


class Service extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            IsLoader: false,
            Tourl: this.props.Tourl,
            MethodType: this.props.MethodType

        }

    }

    closeModel() {
        this.setState({ IsLoader: false });
    }

    async UNSAFE_componentWillReceiveProps(nextProps) {
        if (!this._isMounted)
            return;
        if (nextProps !== undefined && nextProps.Tourl !== undefined && nextProps.Tourl !== "") {

            let headerdata = {
                'Authorization': $.session.get("Token"), 'X-TenantID': nextProps.ServiceTenant !== undefined ?
                    nextProps.ServiceTenant : $.session.get("tenantid"),
                Accept: 'application/json',
                "Content-Type": 'application/json',
                "Content-Encoding": 'gzip',
                "responseType": 'arraybuffer'
            };
            let data = await gzip(JSON.stringify(nextProps.Headerobject));

            if (nextProps.contenttype !== undefined) {
                headerdata = {
                    'Authorization': $.session.get("Token"), 'X-TenantID': nextProps.ServiceTenant !== undefined ?
                        nextProps.ServiceTenant : $.session.get("tenantid"),
                    Accept: 'application/json',
                    "responseType": 'arraybuffer'
                };

                data = nextProps.Headerobject;
            }

            if (nextProps.Needprogress !== undefined && nextProps.Needprogress === false) {
                if (nextProps.MethodType === "POST") {
                    // request
                    //     .post(nextProps.Tourl)
                    //     .send(await gzip(JSON.stringify(nextProps.Headerobject)))
                    //     .set({
                    //         'Authorization': $.session.get("Token"), 'X-TenantID': nextProps.ServiceTenant !== undefined ?
                    //             nextProps.ServiceTenant : $.session.get("tenantid"),
                    //         Accept: 'application/json',
                    //         "Content-Type": 'application/json',
                    //         "Content-Encoding": 'gzip'
                    //     })
                    //     // .set('Content-Encoding', 'gzip')
                    //     .then((res) => {
                    //         if (this._isMounted) {
                    //             nextProps.onSuccess(formatdata(JSON.parse(res.text)));
                    //         }
                    //     })
                    //     .catch((error) => {
                    //         nextProps.onFailure(error);
                    //     });
                    axios.post(nextProps.Tourl, data, {
                        headers: headerdata,
                        responseType: 'arraybuffer'
                    }).then(response => {
                        if (this._isMounted) {
                            const compressedData = new Uint8Array(response.data);
                            const decompressedData = pako.inflate(compressedData, { to: 'string' });
                            nextProps.onSuccess(formatdata(JSON.parse(decompressedData)), nextProps.Headerobject);
                        }
                    }).catch((error) => {
                        nextProps.onFailure(error);
                    });
                }
                else {
                    // request
                    //     .get(nextProps.Tourl)
                    //     .send({})
                    //     .set({
                    //         'Authorization': $.session.get("Token"), 'X-TenantID': nextProps.ServiceTenant !== undefined ?
                    //             nextProps.ServiceTenant : $.session.get("tenantid"),
                    //         Accept: 'application/json'
                    //     })
                    //     // .set('Content-Encoding', 'gzip')
                    //     .then((res) => {
                    //         if (this._isMounted) {
                    //             nextProps.onSuccess(formatdata(JSON.parse(res.text)));
                    //         }
                    //     })
                    //     .catch((error) => {
                    //         nextProps.onFailure(error);
                    //     });
                    axios.get(nextProps.Tourl, {
                        headers: headerdata,
                        responseType: 'arraybuffer'
                    }).then(response => {
                        if (this._isMounted) {
                            const compressedData = new Uint8Array(response.data);
                            const decompressedData = pako.inflate(compressedData, { to: 'string' });
                            nextProps.onSuccess(JSON.parse(decompressedData), nextProps.Headerobject);
                        }
                    }).catch((error) => {
                        nextProps.onFailure(error);
                    });
                }
            }
            else {
                this.setState({ IsLoader: true });
                if (nextProps.MethodType === "POST") {
                    // request
                    //     .post(nextProps.Tourl)
                    //     .send(await gzip(JSON.stringify(nextProps.Headerobject)))
                    //     .set({
                    //         'Authorization': $.session.get("Token"), 'X-TenantID': nextProps.ServiceTenant !== undefined ?
                    //             nextProps.ServiceTenant : $.session.get("tenantid"),
                    //         Accept: 'application/json',
                    //         "Content-Type": 'application/json',
                    //         "Content-Encoding": 'gzip'
                    //     })
                    //     // .set('Content-Encoding', 'gzip')
                    //     .then((res) => {
                    //         if (this._isMounted) {
                    //             setTimeout(this.closeModel.bind(this), 10);
                    //             nextProps.onSuccess(formatdata(JSON.parse(res.text)));
                    //         }
                    //     })
                    //     .catch((error) => {
                    //         setTimeout(this.closeModel.bind(this), 10);
                    //         nextProps.onFailure(error);
                    //     });

                    axios.post(nextProps.Tourl, data, {
                        headers: headerdata,
                        responseType: 'arraybuffer'
                    }).then(response => {
                        if (this._isMounted) {
                            const compressedData = new Uint8Array(response.data);
                            const decompressedData = pako.inflate(compressedData, { to: 'string' });
                            setTimeout(this.closeModel.bind(this), 10);
                            nextProps.onSuccess(formatdata(JSON.parse(decompressedData)), nextProps.Headerobject);
                        }
                    }).catch((error) => {
                        setTimeout(this.closeModel.bind(this), 10);
                        nextProps.onFailure(error);
                    });
                }
                else {
                    // request.set({ 'X-TenantID': 'ELN1' });
                    // request
                    //     .get(nextProps.Tourl)
                    //     .send({})
                    //     .set({
                    //         'Authorization': $.session.get("Token"), 'X-TenantID': nextProps.ServiceTenant !== undefined ?
                    //             nextProps.ServiceTenant : $.session.get("tenantid"), Accept: 'application/json'
                    //     })
                    //     .set('Content-Encoding', 'gzip')
                    //     .then((res) => {
                    //         if (this._isMounted) {
                    //             setTimeout(this.closeModel.bind(this), 10);
                    //             nextProps.onSuccess(formatdata(JSON.parse(res.text)));
                    //         }
                    //     })
                    //     .catch((error) => {
                    //         setTimeout(this.closeModel.bind(this), 10);
                    //         nextProps.onFailure(error);
                    //     });

                    axios.get(nextProps.Tourl, {
                        headers: headerdata,
                        responseType: 'arraybuffer'
                    }).then(response => {
                        if (this._isMounted) {
                            const compressedData = new Uint8Array(response.data);
                            const decompressedData = pako.inflate(compressedData, { to: 'string' });
                            setTimeout(this.closeModel.bind(this), 10);
                            // nextProps.onSuccess(formatdata(response.data), nextProps.Headerobject);
                            nextProps.onSuccess(formatdata(JSON.parse(decompressedData)), nextProps.Headerobject);
                        }
                    }).catch((error) => {
                        setTimeout(this.closeModel.bind(this), 10);
                        nextProps.onFailure(error);
                    });
                }
            }
            this.props.onReseturl();
        }
    }

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {

        let display = "clsDisplaynone";
        if (this.state.IsLoader) {
            display = "clsdisplayblock";
        }
        return (<div className={"cls-loader " + display}>
            {/* <div className={"row justify-content-center align-items-center "}>
                <Loader type="Puff" color="#00BFFF" width={180} height={180} />
            </div>
            <div className="clear"></div> */}
            <PuffLoader
                css={override}
                size={100}
                color={"#123abc"}
            />
        </div>)
    }
}
export default withTranslation(Service);